export const MSG_todayFeatureTitle = {
  id: "13d90aad-5ab4-4848-a1d5-c4ffe888b160",
  description: "The label for the 'Today' section of the mobile app.  This is essentially the dashboard, but is the summary of what they need to do today.",
  defaultMessage: "Today"
};

export const MSG_metricsFeatureTitle = {
  id: "4671115c-bc81-41d8-80ee-9b35cf117e5e",
  description: "The metrics are data about the company that show how the company is doing.  They are charts and tables with numbers and graphs.",
  defaultMessage: "Metrics"
};

export const MSG_dashboardFeatureTitle = {
  id: "d763ac24-ad34-4f32-9989-98db84233f01",
  description: "The label for the 'Dashboard' feature that shows the dashboard on the web app.  This is a page that shows summaries, charts, and other important information at a glance.",
  defaultMessage: "Dashboard"
};

export const MSG_clientsFeatureTitle = {
  id: "fc1e6bc4-5845-45aa-9483-68654531bbae",
  description: "The label for the 'Clients' feature.  This is where they can get a list of all clients and search.",
  defaultMessage: "Clients"
};

export const MSG_searchClientsTitle = {
  id: "74ac0ae2-ede5-43b1-8b87-5b3a0e7da2ab",
  description: "The button that lets them search clients by name, email, etc.",
  defaultMessage: "Search Clients"
};

export const MSG_pianosFeatureTitle = {
  id: "b38d3db6-b020-4317-87d4-440608f116c7",
  description: "The label for the 'Pianos' feature.  This is where they can get a list of all pianos and search.",
  defaultMessage: "Pianos"
};

export const MSG_searchPianosTitle = {
  id: "e6c71181-0d2c-4bf8-8422-bc0f88b44a22",
  description: "The button that lets them search pianos by make, model, etc.",
  defaultMessage: "Search Pianos"
};

export const MSG_uploadedTitle = {
  id: "358f1604-da30-4607-a623-b054cf9fd149",
  description: "A word that means the file was uploaded.",
  defaultMessage: "Uploaded"
};


export const MSG_calendarFeatureTitle = {
  id: "32653224-1432-4ffa-ac65-a8e9ed52d11a",
  description: "The label for the 'Calendar' feature.  This is where they can see all their appointments and other events on the calendar.",
  defaultMessage: "Calendar"
};

export const MSG_estimatesFeatureTitle = {
  id: "2c642159-64d8-4227-838e-4130a71bcf29",
  description: "The label for the 'Estimates' feature.  This is where they can get a list of all estimates and search.",
  defaultMessage: "Estimates"
};

export const MSG_searchEstimatesTitle = {
  id: "1d6f0a7b-1881-4247-868b-6d89a0bed7e6",
  description: "The button that lets them search estimates by name, number, etc.",
  defaultMessage: "Search Estimates"
};

export const MSG_reservationsFeatureTitle = {
  id: "86a81e56-f5bb-4686-8410-786ba046d803",
  description: "The label for the 'Reservations' feature.  This is where they can get a list of all reservations that need attention.",
  defaultMessage: "Reservations"
};

export const MSG_uncompletedAppointmentsFeatureTitle = {
  id: "2cc8d686-9de8-4742-8e10-0fce96c60856",
  description: "The label for the 'Reservations' feature.  This is where they can get a list of all appointments that have not yet been marked as complete.",
  defaultMessage: "Uncompleted Appointments"
};

export const MSG_callCenterFeatureTitle = {
  id: "0fb34fcd-4b8f-49b4-b25e-c53be9278ba9",
  description: "The label for the 'Call Center' feature.  This is where they can see a list of all the phone calls that need to be made.",
  defaultMessage: "Call Center"
};

export const MSG_remindersFeatureTitle = {
  id: "40c2ede6-7f3c-4df9-bdb5-189cc532c799",
  description: "The title for the 'Reminders' feature.  This is where they can see a list of all the reminders that are scheduled to go out.",
  defaultMessage: "Reminders"
};

export const MSG_messagesFeatureTitle = {
  id: "c3253084-e23c-4c01-a143-8770d25d7384",
  description: "The title for the 'Messages' feature.  This is where they can get a list of all messages (text and email) that have been sent, and search them.",
  defaultMessage: "Messages"
};

export const MSG_customTemplatesFeatureTitle = {
  id: "76aa974d-e9ba-457c-92c4-265895d03f18",
  description: "The title for the 'Custom Templates' feature.  This is where they can get a list of all message templates (text and email) that have been sent, and search them.",
  defaultMessage: "Custom Templates"
};

export const MSG_oneOffMessagesFeatureTitle = {
  id: "2e46c905-e2a8-4f0e-83c3-ee2c4f9110bd",
  description: "The title for the 'One-Off Messages' feature.  This is where they can change the one-off messages that Gazelle sends periodically.  These are messages that are not repeating reminders, but are sent in response to specific actions in Gazelle (e.g. scheduling an appointment)",
  defaultMessage: "One-Off Messages"
};

export const MSG_settingsFeatureTitle = {
  id: "cd8151c3-7dbc-4d1e-a8eb-c0864616250e",
  description: "The label for the 'Settings' feature.  This is where they can get a list of all settings that can be configured.",
  defaultMessage: "Settings"
};

export const MSG_okTitle = {
  id: "c49b8fe5-0509-4075-a1cc-239bdc5777ed",
  description: "The label for a button that confirms an action, or acknowledges that they saw a message.",
  defaultMessage: "OK",
};

export const MSG_cancelTitle = {
  id: "918f4802-e412-4ded-8206-6efc70f71d77",
  description: "The label for a button that cancels an action.",
  defaultMessage: "Cancel",
};

export const MSG_sendTitle = {
  id: "3fe0af2d-50da-4a9d-bc7a-d69af189da72",
  description: "The label for a button that sends an email",
  defaultMessage: "Send"
};

export const MSG_sendLabel = {
  id: "dcc9fe73-9963-4a73-8d4f-f2650c388b98",
  description: "A label (verb) for when a message is scheduled to be sent.  It will read like 'Send: Jan 1, 2019' or 'Send: Immediately'",
  defaultMessage: "Send",
};

export const MSG_passwordLabel = {
  id: "43f9fd54-a8ad-487d-9c10-ce9837f6d31e",
  defaultMessage: "Password"
};

export const MSG_logoutTitle = {
  id: "7f5506fd-5d22-4cee-bc0b-2763d1ae44d6",
  description: "The label for the button that logs them out of the application.",
  defaultMessage: "Logout",
};

export const MSG_showEverythingTitle = {
  id: "168708bf-f2a4-48dd-a534-5d807fa7a8fc",
  defaultMessage: "Show Everything",
};

export const MSG_askAQuestionTitle = {
  id: "96844a33-1122-40ed-a2b4-5c630aaf16c0",
  description: "The button they can press to submit a question about an estimate.",
  defaultMessage: "Ask A Question"
};

export const MSG_whatIsThis = {
  id: "a93bf934-d268-4403-b61f-fb14ec9bdd24",
  description: "This is a common label that we put on buttons that provide help.  You can click on the button and it shows help about whatever page you are viewing.",
  defaultMessage: "What is this?",
};

export const MSG_archivedTitle = {
  id: "c78e5806-a7f8-452b-9981-0d9236ce9958",
  description: "Short text describing that an invoice is archived.  This is in title case.",
  defaultMessage: "Archived",
};

export const MSG_archivedLabel = {
  id: "83790094-de33-4a98-b92a-e58de852cc0b",
  description: "Short text describing that an invoice is archived.  This is in label case.",
  defaultMessage: "Archived",
};

export const MSG_archivedStatusLabel = {
  id: "243eea25-e474-41c6-a981-7574d6b8f5fb",
  description: "Short text describing archival status of something such as an invoice.  This is in label case",
  defaultMessage: "Archived status",
};

export const MSG_archivedStatusTitle = {
  id: "368423a4-8543-442a-8c1d-0c6620ddf176",
  description: "Short text describing archival status of something such as an invoice.  This is in title case",
  defaultMessage: "Archived Status",
};

export const MSG_archiveTitle = {
  id: "31c6f689-285e-41ad-9771-c0b1bd356ce5",
  description: "A short text describing the archive action for invoices (verb).",
  defaultMessage: "Archive",
};

export const MSG_unarchiveTitle = {
  id: "b7ceaceb-3948-4bce-8f7e-216d1d921d7b",
  description: "A short text describing the ability to unarchive an invoice; a verb (e.g. make it not archived).",
  defaultMessage: "Unarchive",
};

export const MSG_otherTitle = {
  id: "cb850d63-e253-4d1b-9a6f-1b55ab33ebb4",
  description: "A very short word or abbreviation used in a list when all the other options do not apply.",
  defaultMessage: "Other"
};

export const MSG_othersTitle = {
  id: "799d11ca-a93f-444e-9b18-9930d78ca0dd",
  description: "A label that is above the list of people that don't fit into any category.",
  defaultMessage: "Others",
};

export const MSG_pendingTitle = {
  id: "ae5ea044-aaa3-42e1-b0c1-d3b10142473e",
  description: "A label to indicate that the status of something that is pending (e.g. it hasn't happened yet but will soon)",
  defaultMessage: "Pending"
};

export const MSG_yesTitle = {
  id: "b603f085-4873-47fa-9e04-d8d78144eb1b",
  description: "The word 'yes', as in an positive action: 'Yes, do this'",
  defaultMessage: "Yes",
};

export const MSG_noTitle = {
  id: "6ea8326f-50a7-4556-8695-e649d50e0c4a",
  description: "The word 'no' as in the negative action: 'No, do not do this'.",
  defaultMessage: "No",
};

export const MSG_maybeTitle = {
  id: "4e44d9d8-01bc-40ba-9d28-5c9db0c1d992",
  description: "The word 'maybe'.  This is used when an item may or may not be recommended depending on the circumstances.  Example:  Is this recommended?  Maybe.",
  defaultMessage: "Maybe"
};


export const MSG_requiredTitle = {
  id: "9b10f719-b564-4e73-81cd-244d75be9f63",
  description: "The label when a form field is required.",
  defaultMessage: "Required",
};

export const MSG_optionalTitle = {
  id: "589f6643-2243-4bcd-9899-53f7e3416d41",
  description: "The label when a form field is optional.",
  defaultMessage: "Optional",
};

export const MSG_mergeCodesTitle = {
  id: "810eda61-bd89-4e15-a955-7b81f6f5fc04",
  description: "Merge codes are codes that can be inserted into a template and will be replaced with values later.  For example, {{client.first_name}} is a merge code that will be replaced with the client's first name when the template is used.",
  defaultMessage: "Merge Codes",
};

export const MSG_insertMergeCodesTitle = {
  id: "fffe8443-85b3-460b-8fd5-ebee868d96f4",
  description: "The label for a button that displays the list of merge codes that the user can select.",
  defaultMessage: "Insert Merge Codes",
};

export const MSG_saveTitle = {
  id: "0553dd67-79e7-4c8f-9de0-343ec0cf91a9",
  description: "The label for a button that saves a form to the database.",
  defaultMessage: "Save",
};

export const MSG_saveAndCloseTitle = {
  id: "2bc710d8-8c76-48a1-a002-6ec9ba05b121",
  defaultMessage: "Save & Close",
};

export const MSG_saveNumTitle = {
  id: "dd9d43ce-6382-4f50-b8e6-0334f2d6a753",
  description: "This is a label for save button that also confirms the quantity of an item that will be saved.  e.g. 'Save 1,259'.",
  defaultMessage: "Save {num}"
};


export const MSG_savingLabel = {
  id: "a8f60ae7-7cb8-43fc-881e-4226cd88a954",
  description: "A label that is displayed while a record is being saved.",
  defaultMessage: "Saving",
};

export const MSG_deleteTitle = {
  id: "3b2c6818-5fd4-4663-8d92-3afae96d0b15",
  description: "The label for a button that deletes a group.",
  defaultMessage: "Delete",
};

export const MSG_deletingLabel = {
  id: "6b5be5ce-37d2-49a6-b9f0-66572deac615",
  description: "A label that is displayed while a record is being deleted.",
  defaultMessage: "Deleting",
};

export const MSG_downloadTitle = {
  id: "36712f30-bcfb-4956-ba21-af20c71e2c4e",
  description: "A label for a button that downloads something",
  defaultMessage: "Download"
};


export const MSG_nextTitle = {
  id: "49852e98-1993-4235-976a-3635544fd812",
  description: "The label for a button that moves to the next step in a multi-step process.",
  defaultMessage: "Next",
};

export const MSG_previousTitle = {
  id: "2eb41144-1976-475c-9f5e-08264f3f5b4e",
  description: "The label for a button that moves back to the previous step in a multi-step process.",
  defaultMessage: "Previous"
};


export const MSG_closeTitle = {
  id: "ba238d99-9548-4a39-a23b-3a25e3781a71",
  description: "The label for a button that closes a popup window.",
  defaultMessage: "Close",
};

export const MSG_changeTitle = {
  id: "35a5ed48-88af-4971-b837-7163d4be6ff9",
  description: "The label for a button that lets the user change something.",
  defaultMessage: "Change",
};

export const MSG_resetTitle = {
  id: "2a7e49b2-dbab-480c-ae63-f85226d0da8d",
  description: "The label for a button that resets something back to its initial state.",
  defaultMessage: "Reset"
};

export const MSG_loading = {
  id: "dfd00748-5135-4109-8557-e40da72c108e",
  description: "What to display when data is being loaded from the server.",
  defaultMessage: "Loading",
};

export const MSG_or = {
  id: "47191775-d9b5-48b5-9552-99646ef7e632",
  description: "This is often used between two user interface elements.  For example: [Client Selector] or [New Client].",
  defaultMessage: "or",
};

export const MSG_allTechniciansLabel = {
  id: "c4b5e8f9-a3c5-4907-8492-23eabb85a254",
  description: "A label for options where everyone in the company who is a technician can be scheduled.",
  defaultMessage: "All technicians",
};

export const MSG_defaultTitle = {
  id: "0be3f220-a1f8-4853-868b-c1006623e3c4",
  description: "A label default options.",
  defaultMessage: "Default",
};

export const MSG_unspecifiedTitle = {
  id: "72092ab6-7f3d-4771-90fc-d43dd2031f4a",
  description: "Used in place of a value when they specifically told us that the client did not know the value.",
  defaultMessage: "Unspecified"
};

export const MSG_unknownTitle = {
  id: "6c89161f-ccb0-4576-ae74-698dbef2b0d1",
  description: "Used in place of a generic unknown value.  This is in title case.",
  defaultMessage: "Unknown"
};

export const MSG_unknownLabel = {
  id: "a90f66e1-a80c-4a34-bf9a-bfe8409aff2d",
  description: "Used in place of a generic unknown value.  This is in label case.",
  defaultMessage: "Unknown"
};

export const MSG_unknownCityLabel = {
  id: "bc081d8c-8dd9-4b74-b83a-caa99a056433",
  description: "Used in place of a city name when the city is not known.",
  defaultMessage: "Unknown city"
};

export const MSG_unknownAddressLabel = {
  id: "6125f53e-a17d-4341-a72d-d4fe6476e071",
  description: "Used in place of a address when the address is not known.",
  defaultMessage: "Unknown address"
};

export const MSG_unknownPostalCodeLabel = {
  id: "e52b564f-2b56-4097-9823-9717ba99f046",
  description: "Used in place of a postal code when the postal code is not known.",
  defaultMessage: "Unknown postal code"
};

export const MSG_unknownPhoneLabel = {
  id: "fdaa03d9-6990-4d08-9c24-db973fbb0a66",
  description: "Used in place of a phone number when the phone number is not known.",
  defaultMessage: "Unknown phone number"
};

export const MSG_unknownEmailLabel = {
  id: "2c46aa77-1422-48e7-86eb-18f0851c95f4",
  description: "Used in place of an email when the email is not known.",
  defaultMessage: "Unknown email"
};

export const MSG_statusLabel = {
  id: "94661146-bb5c-4806-98c1-dd04193badad",
  description: "A word describing the status of a piano, invoice, etc.  This is in title label case.",
  defaultMessage: "Status",
};

export const MSG_statusTitle = {
  id: "af3cc0ac-7239-4fcb-919e-7c9f48b0e12b",
  description: "A word describing the status of a piano, invoice, etc.  This is in title case",
  defaultMessage: "Status",
};

export const MSG_activeTitle = {
  id: "a1f7f42c-4bcb-484b-8a62-46ffc4e67d85",
  description: "The client or piano status: Active.",
  defaultMessage: "Active",
};

export const MSG_inactiveTitle = {
  id: "b9f578d3-dec5-4580-bd5d-ebe3ecd850c0",
  description: "The client or piano status: Inactive.",
  defaultMessage: "Inactive"
};

export const MSG_newTitle = {
  id: '0f095c4b-1559-475a-9352-fbdf893ac63d',
  description: 'The word for "new" as in "Create a NEW event".  This is in title case.',
  defaultMessage: 'New',
};

export const MSG_noneTitle = {
  id: '7ea6e9a2-67c9-4867-ac18-4fc224c4a44a',
  description: 'The word "none", when it is used in a list of items to select and the option is to select none of the options.  This is in title case.',
  defaultMessage: 'None',
};

export const MSG_noneLabel = {
  id: "db92dde3-87dd-4a94-9f5b-321e81a43f16",
  description: 'The word "none", used when a list is expected but there are no items to display.  This is in label case.',
  defaultMessage: 'None',
};

export const MSG_allTitle = {
  id: "c7e9c395-a576-49c4-8870-7a0f64c9152d",
  description: "The word 'all', used when all items in a list are selected.  This is in title case.",
  defaultMessage: "All"
};

export const MSG_allLabel = {
  id: "e4892945-edfc-496f-8530-c7b02bd2e5e8",
  description: "The word 'all', used when all items in a list are selected and all of the options should apply.  This is in label case.",
  defaultMessage: "All"
};

export const MSG_neverLabel = {
  id: '8cbc9aae-065f-4f07-aec3-eade8f5d3d57',
  description: 'The word "never", used in cases like "Piano last tuned: Never".  This is in label case.',
  defaultMessage: 'Never',
};

export const MSG_removeTitle = {
  id: 'ad308285-3ce7-4c00-b1a3-3480b9b5ee3a',
  description: 'The word "remove", used when an item can be removed from a list.  This is in title case.',
  defaultMessage: 'Remove',
};

export const MSG_undoTitle = {
  id: '75451d6c-2f68-4903-92b2-8eaab7a368ca',
  description: 'The word "undo", as in "undo" an action, or revert it to the previous state.',
  defaultMessage: 'Undo',
};

export const MSG_addTitle = {
  id: "0e573216-3732-4751-a243-d4d2fbe71f0f",
  description: "A short button label used in multiple situations.  For example, to add an estimate to an invoice, add a note to the client's timeline, etc.",
  defaultMessage: "Add",
};

export const MSG_pauseTitle = {
  id: "a381d94d-0b70-4adf-be6e-d9be0b53853d",
  description: "This is the label for when they temporarily stop reminders from going to a client.  This is an action verb.",
  defaultMessage: "Pause",
};

export const MSG_unpauseTitle = {
  id: "5f41fe47-d3ea-4bc2-85c2-3ac043e19d98",
  description: "This is the label for when they disable the temporarily stop reminders from going to a client.  This is an action verb.",
  defaultMessage: "Unpause",
};

export const MSG_pausedTitle = {
  id: "1df9a1d7-5d34-448e-bab5-4200f49d1ac7",
  description: "A label to display if a client has been paused and is not receiving any reminders.",
  defaultMessage: "Paused"
};

export const MSG_pausedLabel = {
  id: "27296d01-37e7-4ca1-af71-5cafab687228",
  description: "A label for an option where all contact wi th this client has been disabled.  We call this pausing the client.",
  defaultMessage: "Paused"
};

export const MSG_notPausedLabel = {
  id: "ecdfcbfc-ebd8-4112-ab4a-466dda174a82",
  description: "A label for an option where all contact with this client has not been disabled.  We call this pausing the client.",
  defaultMessage: "Not paused"
};

export const MSG_pausedStatusLabel = {
  id: "1f781428-5da0-40e7-852c-a9650973fbce",
  description: "This is the label for a button that lets them filter clients by whether they have had all contact paused or not.",
  defaultMessage: "Paused status"
};


export const MSG_editTitle = {
  id: "5ed7d474-ea93-4940-9ddc-fa1134e8a26a",
  defaultMessage: "Edit",
};

export const MSG_printTitle = {
  id: "eecfd304-f460-4a07-a433-871565a515e2",
  description: "This is the label for when they print something out (an invoice for example).  This is used as a verb like 'print your invoice'.",
  defaultMessage: "Print",
};

export const MSG_sending = {
  id: "d75ce7d0-9536-48cc-ad49-8022aea6d5e9",
  description: "This is displayed while a message is being sent.",
  defaultMessage: "Sending...",
};

export const MSG_uncommonActionsTitle = {
  id: "d7eb7e3e-fe0f-461e-a767-526262496192",
  description: "This is the heading on top of a list of actions that rarely need to be taken.",
  defaultMessage: "Uncommon Actions",
};

export const MSG_addAsNewClientRecord = {
  id: "1656ad1c-938f-412a-8f9c-183cdfe94c61",
  defaultMessage: "Add as a new client record",
};

export const MSG_createAsNewClientRecord = {
  id: "9c21b822-af3e-47d0-9274-7d86abeb82bd",
  defaultMessage: "Create {name} as a new client record",
};

export const MSG_selectDifferentClient = {
  id: "aef4090b-2143-44ed-9924-7c263b416339",
  description: "This is the label for a button that lets them select a different client for a reservation.",
  defaultMessage: "Select a different client"
};


export const MSG_whatWouldYouLikeToDo = {
  id: "ed2acbd4-3c50-48cb-a3c3-f51f84793559",
  defaultMessage: "What would you like to do?",
};

export const MSG_areYouSure = {
  id: "c9694d60-efbc-41c4-84b6-438528ef159d",
  description: "The message to confirm if they want to do an action or not.  The options will be Yes or No.",
  defaultMessage: "Are you sure?",
};

export const MSG_cannotBeUndone = {
  id: "20c8b80e-1695-4f74-9879-bea8d81201ee",
  description: "The message for a confirmation popup.  This is intended to be intimidating to let them know the action would be dangerous.",
  defaultMessage: "WARNING: This cannot be undone.",
};

export const MSG_durationLabel = {
  id: "7343193b-2efe-4841-a006-bfb06b160ff6",
  description: "The label for how long an appointment last.  This is in label case.",
  defaultMessage: "Duration",
};

export const MSG_durationTitle = {
  id: "ee9d84e7-a35e-42e4-b35d-847c1b0828f3",
  description: "The label for how long an appointment last.  This is in title case.",
  defaultMessage: "Duration",
};

export const MSG_hoursLabel = {
  id: "d2763615-22f8-44c9-936f-9f5f332ae885",
  description: "The label where they can add how many hours they worked on a project.",
  defaultMessage: "Hours"
};

export const MSG_costLabel = {
  id: "5f5293a3-7c9b-4e5e-9582-fad1d90c5cae",
  description: "The label for how much a service costs.",
  defaultMessage: "Cost",
};

export const MSG_costWithValueLabel = {
  id: "fa547c16-9cf9-4cae-a49f-c40844567623",
  description: "This displays the total cost (amount of money) of all the selected services for a single piano.",
  defaultMessage: "Cost: {cost}"
};

export const MSG_amountLabel = {
  id: "d2ea359e-28e0-4870-aca7-615cbe6fa67b",
  description: "The label for the amount of money that is due on an invoice or for a service",
  defaultMessage: "Amount",
};

export const MSG_companyTitle = {
  id: "6a426cb0-e4e9-40d7-847e-5976555fe8be",
  description: "The word 'company' which refers to a business or organization",
  defaultMessage: "Company"
};

export const MSG_companyLabel = {
  id: "a6ed5461-55d2-41f1-9eec-e51b34344744",
  description: "The word 'company' which refers to a business or organization",
  defaultMessage: "Company"
};

export const MSG_eachAmountLabel = {
  id: "c05febe0-6b1f-4606-9f8d-ea15dad91c92",
  description: "The label for the amount of money for one item",
  defaultMessage: "Each amount",
};

export const MSG_mileageRateLabel = {
  id: "cb9296bd-deaf-454b-a783-9d07b31810ae",
  description: "The label for the amount of money charged for each mile traveled",
  defaultMessage: "Mileage rate",
};

export const MSG_notesTitle = {
  id: "4af906af-7727-45e8-87a5-968fee4c0aef",
  description: "The word 'Notes', referring to a memo or text notes.  This is in title case.  Sometimes this is used for invoice notes, other times it is used for notes that the technician has written to themselves.",
  defaultMessage: "Notes",
};

export const MSG_notesLabel = {
  id: "6614726e-0150-47ac-b536-3e8a7fa8f2e5",
  description: "The word 'Notes', referring to a memo or text notes.  This is in label case.  Sometimes this is used for invoice notes, other times it is used for notes that the technician has written to themselves.",
  defaultMessage: "Notes",
};

export const MSG_notesLabelWithColon = {
  id: "988ff256-d36b-4479-b028-7d7497e494f8",
  description: "The word 'Notes' followed by a colon, which is then followed by some notes.  This is in label case.  This refers to a memo or text notes.  Sometimes this is used for invoice notes, other times it is used for notes that the technician has written to themselves.",
  defaultMessage: "Notes:",
};

export const MSG_callTitle = {
  id: "8b50c277-df82-44ab-9bd0-c0a8946e64e8",
  description: "A label (verb) to indicate that they need to make a phone call to this person.  e.g. 'Call: Jan 1, 2019'",
  defaultMessage: "Call"
};

export const MSG_dueLabel = {
  id: "76e24054-433b-499e-adf3-5f27cca28932",
  description: "A label for when a phone call is due to be made.  It will read like 'Due: Jan 1, 2019' or 'Due: Immediately'",
  defaultMessage: "Due",
};

export const MSG_immediatelyLabel = {
  id: "8b3b19f6-1b3e-4463-84b4-b48e8fa6f7d0",
  description: "Instead of showing a date/time when a message will be sent, if it is scheduled to go out right away, this will be displayed.  It will read like 'Send: Immediately'",
  defaultMessage: "Immediately",
};

export const MSG_hideAdvancedOptionsTitle = {
  id: "92bc92f4-c7de-4854-a17e-fc5d8ba0bb36",
  defaultMessage: "Show Less"
};

export const MSG_showAdvancedOptionsTitle = {
  id: "72ddaedb-fcbf-42c5-a423-7b676573c2fd",
  defaultMessage: "Show More"
};

export const MSG_disconnectTitle = {
  id: "379ddfb8-2ffd-4142-889e-bfa790c7d762",
  description: "A button label",
  defaultMessage: "Disconnect",
};

export const MSG_haveQuestionsLabel = {
  id: "e9b91417-67f9-445f-a7e2-4e56f99872be",
  defaultMessage: "Have questions?",
};

export const MSG_customFieldsTitle = {
  id: "0c26e063-331a-4531-9d5f-15ea25f46d93",
    description: "These are fields that can hold whatever data the user wants.",
    defaultMessage: "Custom Fields",
};

export const MSG_customFieldNumberTitle = {
  id: "db4a9a06-6beb-4c31-a3ae-9742addb56a2",
    description: "A title for and editor where the user will edit a custom field such as 'Custom Field 1', 'Custom Field 2', etc",
    defaultMessage: "Custom Field {num}"
};

export const MSG_customFieldNumberLabel = {
  id: "bf3e89dd-d22a-42a2-a1b8-f12395d897ae",
    description: "A label for 'Custom Field 1', 'Custom Field 2', etc",
    defaultMessage: "Custom field {num}"
};

export const MSG_doneTitle = {
  id: "432d31ca-13d3-4ecb-b67c-5d6376da359f",
  description: "The label for when an item is complete",
  defaultMessage: "Done"
};

export const MSG_requestAccessTotal = {
  id: "c5143bb1-0f26-4610-a16c-48b842f02a95",
    description: "This is the label for a screen where the user can ask another user to give them access to their data.",
    defaultMessage: "Request Access",
};

export const MSG_resendInvitationTitle = {
  id: "1c3ed270-2688-4a00-bea7-c105266fb342",
  defaultMessage: "Resend Invitation"
};

export const MSG_invitationSentLabel = {
  id: "56f1ba7e-db18-478a-967f-37b732a33383",
  defaultMessage: "Invitation sent"
};

export const MSG_invitationEmailSent = {
  id: "99d11b49-6602-4d44-baab-cfec3c38f082",
  defaultMessage: "The invitation email has been sent."
};

export const MSG_authenticateTitle = {
  id: "c872a6cf-c483-49a8-b7de-4085b6de11a8",
  defaultMessage: "Authenticate"
};

export const MSG_privacyEnabledNo = {
  id: "570f3a05-557a-4651-917a-71382a3d314a",
  defaultMessage: "Privacy Enabled: No"
};

export const MSG_privacyEnabledPartial = {
  id: "b22365ab-1e22-4207-83d0-da2c55978e18",
  defaultMessage: "Privacy Enabled: Partial"
};

export const MSG_privacyEnabledYes = {
  id: "fa0475b0-4ff2-487e-a8d7-c90d4d89714b",
  defaultMessage: "Privacy Enabled: Yes"
};

export const MSG_directAuthenticationLabel = {
  id: "c65a2f63-925c-49bd-90c7-afb47119e8ab",
  description: "A short phrase indicating that they will be asked for a username and a password to authenticate directly with a service.",
  defaultMessage: "Direct authentication",
};

export const MSG_iAmOwnerOfAnotherGazelleAccount = {
  id: "6c8393fd-3135-4b2c-b128-481d80064e89",
  defaultMessage: "e.g. I am the owner of another Gazelle account."
};

export const MSG_tokenBasedAuthenticationLabel = {
  id: "1b53a16d-f67f-49d5-9290-56caac0d249c",
  description: "A type of authentication where the user will be sent a unique, one time token that can be used to authenticate something.",
  defaultMessage: "Token based authentication"
};

export const MSG_privateTitle = {
  id: "f60ce277-7c99-4a28-b897-de8004d95ca2",
  description: "A short word used as a badge to indicate something is private.",
  defaultMessage: "Private",
};

export const MSG_privacyTitle = {
  id: "e8d1df03-4f96-4aaf-95e3-b5bc697de873",
  description: "A heading for a section where the user can modify privacy related settings.",
  defaultMessage: "Privacy",
};

export const MSG_queuedForProcessingLabel = {
  id: "726e574f-38ef-4d27-976d-ffa430f60b71",
  description: "Some tasks will go into queue to be processed in the background.  This is a short message that is shown while the task is queued for background processing.",
  defaultMessage: "Queued for processing..."
};

export const MSG_inProgressLabel = {
  id: "5fadeb59-e32d-4bb2-a418-bf6c06df8871",
  defaultMessage: "In progress...",
};

export const MSG_mapTitle = {
  id: "171ca313-3b6b-430f-82a1-2e56603f11fb",
  description: "The label for a button that pulls up a map with all their appointments for the day.  This should be a short word, there is not much room.",
  defaultMessage: "Map"
};

export const MSG_directionsTitle = {
  id: "5ac47107-4c68-4444-83c4-151b6f2e8c39",
  description: "The label for a button that pulls up driving directions to their next appointment.  This should be a short word, there is not much room.",
  defaultMessage: "Directions"
};

export const MSG_createTitle = {
  id: "797f9f0a-fdbd-408d-b1e8-c43f0f3f3005",
  defaultMessage: "Create",
};

export const MSG_displayNameLabel = {
  id: "235f2158-2582-4a23-8912-6558050db55d",
  defaultMessage: "Display name",
};

export const MSG_sortingInstructionsGroup = {
  id: "92830bca-2f52-4e67-8d91-ee5b7ba7c62a",
  defaultMessage: "Tap and hold to change the order of a group."
};

export const MSG_sortTitle = {
  id: "cea69652-cf73-46b0-a4f0-f94bd360f779",
  description: "The label for a button that lets them sort a list.",
  defaultMessage: "Sort"
};

export const MSG_sortGroupsTitle = {
  id: "0680eca3-d94f-47c0-9cc8-593e9e13968d",
  description: "The label for a button that lets them sort a list.",
  defaultMessage: "Sort Groups"
};

export const MSG_sortItemsTitle = {
  id: "29894f56-3abb-481b-9c8b-c3be9ea4f407",
  description: "The label for a button that lets them sort a list.",
  defaultMessage: "Sort Items"
};

export const MSG_choosePhotoTitle = {
  id: "5ea08365-b286-4eac-b56b-38aad83f54c7",
  defaultMessage: "Choose Photo",
};

export const MSG_noResultsFromSearch = {
  id: "ed48d431-170e-4aed-810a-82c509b92d5a",
  defaultMessage: "Your search returned 0 results."
};

export const MSG_noResults = {
  id: "bfc747bc-d285-4a8d-842f-7862a7e5a9db",
  description: "",
  defaultMessage: "No results"
};


export const MSG_notFoundTitle = {
  id: "313d1aef-df2b-472d-9df7-66a451d89271",
  description: "This is the title displayed when a record was requested but could not be found (i.e. a 404 error)",
  defaultMessage: "Not Found"
};

export const MSG_searchLabel = {
  id: "eee09d72-b622-438f-afce-b2138d3c210c",
  description: "The label for a search input box",
  defaultMessage: "Search"
};

export const MSG_searchTitle = {
  id: "d50a3027-bac9-49e3-bd5d-71a673097bdc",
  description: "The title for a dialog box where a search is performed",
  defaultMessage: "Search"
};

export const MSG_selectOneTitle = {
  id: "9a669507-b56f-4036-b5af-d9a985010219",
  description: "A short title that is displayed when the user must choose one of several things.",
  defaultMessage: "Select One",
};

export const MSG_nameLabel = {
  id: "bd32da33-a5e4-470e-a0ff-75297ab4c45b",
  description: "This is a label for a name of a thing.  It could be a client name, an item name, a piano name, etc.",
  defaultMessage: "Name",
};

export const MSG_nameTitle = {
  id: "951641ba-0410-46fa-9da7-ee4d9f5c88da",
  description: "This is a label for a name of a thing in title case.  It could be a client name, an item name, a piano name, etc.",
  defaultMessage: "Name",
};

export const MSG_preferencesTitle = {
  id: "f7fd1e99-ff3a-48fc-abc8-606ea870fad0",
  description: "A sidebar label for the third step of the scheduler prompting them to choose a preferred date and preferred technician.  Horizontal space is very limited, so keep this as short as possible.",
  defaultMessage: "Preferences",
};

export const MSG_areYouSureCancelAndLose = {
  id: "4c148e65-f7ad-4860-b04e-509fd37f9cd9",
  defaultMessage: "Are you sure you want to cancel and lose your unsaved changes?",
};

export const MSG_exitWithoutSaving = {
  id: "aec4a854-4731-4bee-9943-e3e43691a8a4",
  defaultMessage: "Exiting without saving will discard your changes.  Are you sure you want to do this?",
};

export const MSG_learnMoreTitle = {
  id: "bc4e9755-b143-400c-8c71-71294893f64c",
  description: "A button they can click to learn more information about a topic.",
  defaultMessage: "Learn More",
};

export const MSG_moreTitle = {
  id: "6fcfefdb-bfd5-482f-944a-837a20352286",
  description: "This is used for a button where there are more actions that could be performed, and the button reveals a list of more actions.  This should be a very short, single word.  There is not much space on this button.",
  defaultMessage: "More"
};

export const MSG_changeStatusTitle = {
  id: "2a439717-aa77-4101-bbda-1dc6c4e0e2b8",
  description: "The button that lets them change the status of an item",
  defaultMessage: "Change Status",
};

export const MSG_changeStatusLabel = {
  id: "ce1e7119-895f-4c25-ab45-60c8f030d364",
  description: "A label for an action that lets them change the status of an item (label case)",
  defaultMessage: "Change status"
};

export const MSG_exportToCsvLabel = {
  id: "9474d8a5-886e-4a2c-ae6e-0a8791af45b1",
  description: "",
  defaultMessage: "Export to CSV"
};


export const MSG_filterTitle = {
  id: "15cc0bde-5860-41e4-a3f7-ba38bf95b172",
  description: "The button that lets them filter the list to hide certain items.",
  defaultMessage: "Filter",
};

export const MSG_filtersTitle = {
  id: "d9c63566-12c4-4c54-aac2-14bf7ebd57b5",
  description: "A button that lets the user select filters to apply to a list.",
  defaultMessage: "Filters",
};

export const MSG_hideTitle = {
  id: "966819d0-91d8-45b0-8d1b-720927588955",
  defaultMessage: "Hide",
};

export const MSG_selectAllTitle = {
  id: "95f718ca-edad-42fe-9aab-cf6252a29113",
  description: "This is the label on a button that will select everything in a list.",
  defaultMessage: "Select All",
};

export const MSG_selectNoneTitle = {
  id: "5df2dd99-e952-4fc6-bccf-de7b0b44b660",
  description: "This is a label for a button that will un-select everything in a list.",
  defaultMessage: "Select None"
};


export const MSG_onLabel = {
  id: "daeace6a-e81d-4762-9287-4121cc0cefa6",
  description: "Displayed when a setting is turned 'on' or 'enabled'",
  defaultMessage: "On"
};

export const MSG_offLabel = {
  id: "1a7458db-d84b-44e3-9bad-da264c3ae407",
  description: "Displayed when a setting is turned 'off' or 'disabled'",
  defaultMessage: "Off"
};

export const MSG_enabledLabel = {
  id: "0dd2f5ce-a73f-44d2-92d7-e69a5980bad4",
  description: "The label to show when something is turned on (or enabled)",
  defaultMessage: "Enabled",
};

export const MSG_disabledLabel = {
  id: "63d283ab-ba21-407b-a80e-958f6ba4fc53",
  description: "The label to show when something is turned off (or disabled)",
  defaultMessage: "Disabled",
};

export const MSG_sendEmailTitle = {
  id: "b26d23ff-6f98-47ca-84dc-3016735e53f3",
  description: "This is the label on a button to send an email.",
  defaultMessage: "Send Email",
};

export const MSG_sendEmailLabel = {
  id: "7bb3a08e-6d88-4784-b6e5-e0d4ffd4d308",
  description: "This is the label on a button to send an email.",
  defaultMessage: "Send email",
};

export const MSG_previewEmailTitle = {
  id: "f8fc7794-56cf-48d1-a157-20529410c8ba",
  description: "This is the label on a button to preview what an email will look like when it is sent.",
  defaultMessage: "Preview Email",
};

export const MSG_copyTitle = {
  id: "b9d25612-d935-4127-964d-99aad104816d",
  description: "The word 'copy', as in copy and paste.",
  defaultMessage: "Copy"
};

export const MSG_copyLinkTitle = {
  id: "5f3b7e34-8b09-499b-b3e7-42f07df7db44",
  description: "The button label that lets them copy a link to a website into their copy/paste buffer",
  defaultMessage: "Copy Link"
};

export const MSG_copyToClipboardTitle = {
  id: "b5d6517f-645f-4c8c-8973-bf9015ff77f5",
  description: "The word 'copy', as in copy and paste in title case.",
  defaultMessage: "Copy to Clipboard",
};

export const MSG_copyToClipboardLabel = {
  id: "20b09f3f-7d6f-4379-800b-b567fcd998c2",
  description: "The word 'copy', as in copy and paste in label case.",
  defaultMessage: "Copy to clipboard",
};

export const MSG_copiedToClipboardTitle = {
  id: "a9ca3902-3153-4bc1-865a-c9869503c725",
  description: "",
  defaultMessage: "Copied To Clipboard"
};

export const MSG_copiedToClipboardLabel = {
  id: "544265de-9cb9-4e30-a223-28de9b78eebb",
  description: "",
  defaultMessage: "Copied To clipboard"
};

export const MSG_copyAddressToClipboardLabel = {
  id: "ceb55b1d-875d-4944-a63d-0276f8447875",
  defaultMessage: "Copy address to clipboard",
};

export const MSG_copyEmailToClipboardLabel = {
  id: "aff779dc-35cd-4a97-ac05-b6598871e1a6",
  defaultMessage: "Copy email address to clipboard",
};

export const MSG_copyPhoneToClipboardLabel = {
  id: "5c9a66ba-ed57-4ec2-95a2-aede4e6213f3",
  defaultMessage: "Copy phone number to clipboard",
};

export const MSG_copyNameToClipboardLabel = {
  id: "390ca865-e3aa-46a9-abae-715095b4511a",
  defaultMessage: "Copy name to clipboard",
};

export const MSG_copiedLabel = {
  id: "7ca72ab5-0d68-45fb-8b72-989c9c644d2a",
  description: "The word 'copied'.  Used when something has been copied to the clipboard.",
  defaultMessage: "Copied"
};

export const MSG_warningTitle = {
  id: "dafa9623-45e0-482b-abb3-8f7f08145845",
  description: "A title for a warning message",
  defaultMessage: "Warning"
};

export const MSG_noticeTitle = {
  id: "5534dd02-9dd6-49e1-9990-b4c9e89dd333",
  description: "This is a generic header for a message we need to bring to their attention.",
  defaultMessage: "Notice"
};
export const MSG_explanationLabel = {
  id: "362dc930-0e76-4412-9af0-ebf48641b48a",
  defaultMessage: "Explanation"
};

export const MSG_ignoreTitle = {
  id: "d7b0599c-e770-4485-abe0-95fd9b684aee",
  defaultMessage: "Ignore",
};

export const MSG_indexOfNumberLabel = {
  id: "04e7e5c7-2a71-4b0f-9160-9651de5432cf",
  description: "This is used for paginating through a list.  It will show the current index of the list and the total number of items.  For example, '3 of 5', '2 of 10', etc.",
  defaultMessage: "{index} of {total}",
};

export const MSG_chooseAnActionTitle = {
  id: "263c0663-f16d-48cf-ae5d-a6d544ade95e",
  defaultMessage: "Choose an Action",
};

export const MSG_addEmailTitle = {
  id: "87495dd8-eab9-48db-883d-d381959f933d",
  description: "A short label for adding an email address to something.",
  defaultMessage: "Add Email",
};

export const MSG_editEmailTitle = {
  id: "e71437fb-1fb5-4afa-bd34-8695da9502a6",
  description: "A short label for editing an email address.",
  defaultMessage: "Edit Email",
};

export const MSG_showMoreFieldsTitle = {
  id: "e01ce9b6-5464-4e0d-aedf-1577e6669c95",
  description: "The label for a button that expands a form to show more form fields",
  defaultMessage: "Show More Fields",
};

export const MSG_showFewerFieldsTitle = {
  id: "58ee8164-4f8b-46d2-a8f8-baf27c3fdd80",
  description: "The label for a button that shrinks a form to show fewer form fields",
  defaultMessage: "Show Fewer Fields",
};

export const MSG_recentChangesTitle = {
  id: "48f9bdd5-7f85-4031-8d6f-4440564e8420",
  description: "This is a title used when we show what has changed since the last mobile app version.",
  defaultMessage: "Recent Changes",
};

export const MSG_createdTitle = {
  id: "51e351c2-8947-4c90-8e23-938dbd98f3c0",
  description: "The label for the date an item was created.",
  defaultMessage: "Created"
};

export const MSG_permissionDeniedTitle = {
  id: "30e65ef7-dd02-4c24-a0db-af6a295a5fe2",
  description: "Error message when permissions has not been granted to do an action",
  defaultMessage: "Permission Denied"
};

export const MSG_uploadingTitle = {
  id: "1aea06d5-12b0-4ceb-b39c-030baf4c036d",
  description: "The label to show when we are uploading something to the server.",
  defaultMessage: "Uploading"
};

export const MSG_shareTitle = {
  id: "ab021831-7adb-4be6-a14f-e1c70ad30960",
  description: "The label for the button that lets them share a file with someone",
  defaultMessage: "Share"
};

export const MSG_dismissTitle = {
  id: "8bf057ac-2722-455d-87a0-ab11745a2901",
  description: "The label for the action that lets them dismiss an alert message and make it go away.",
  defaultMessage: "Dismiss",
};

export const MSG_plusMoreRefineSearchLabel = {
  id: "e1241440-505c-4645-8add-36120bb62bae",
  description: "This is what is shown after a list when there were too many results to show.",
  defaultMessage: "+{count} more (refine your search)"
};

export const MSG_notNowTitle = {
  id: "32c13010-106a-41bf-8113-563e65c8a4fc",
  description: "The option where they choose to not reload right now.",
  defaultMessage: "Not Now"
};

export const MSG_showMoreTitle = {
  id: "ad0e4be8-bcc9-4b7e-af50-7438fc17b345",
  description: "This is the label for a button that shows more options",
  defaultMessage: "Show More"
};

export const MSG_reloadTitle = {
  id: "c19d75b8-2f8c-401a-93f1-1c20f23f2699",
  description: "This is the label for a button that reloads a page.",
  defaultMessage: "Reload"
};

export const MSG_detailsLabel = {
  id: "277e95cb-d841-4069-8a9c-de8760b74875",
  defaultMessage: "Details",
};

export const MSG_showDetailsLabel = {
  id: "81524695-ea71-4b39-909e-95dfce1ebbef",
  defaultMessage: "Show details",
};

export const MSG_showDetailsTitle = {
  id: "b235dcf1-f9b7-4234-8251-73d768864458",
  defaultMessage: "Show Details",
};

export const MSG_instructionsLabel = {
  id: "df55cb6a-a596-4a3b-b868-2b6ef3c9b435",
  description: "The label in front of a set of instructions on how to do something.",
  defaultMessage: "Instructions",
};

export const MSG_sortingItemsInstructions = {
  id: "03f43a18-c476-4c29-b43e-1078f125bf72",
  defaultMessage: "Tap and hold to change the order of an item.  Tap the 'Move' button to move this item to a different group.",
};

export const MSG_groupLabel = {
  id: "aca8b48c-b508-4fb0-ac4d-569f457934be",
  description: "The label for a group of items",
  defaultMessage: "Group",
};

export const MSG_moveTitle = {
  id: "086b466f-d4e0-46a2-96c9-216f34c7dc21",
  description: "The label for a button where they can move an item from one group to another.",
  defaultMessage: "Move",
};

export const MSG_moveItemToGroupTitle = {
  id: "9040d136-fd76-4d24-a0f7-01023a97583d",
  defaultMessage: "Move Item To Group",
};

export const MSG_contactGazelleSupportTitle = {
  id: "e2ed3ee5-6472-40b1-b4a5-9f1f1bb761e3",
  description: "The label for the button that lets them send us an email for support.",
  defaultMessage: "Contact Gazelle Support",
};

export const MSG_dateAndTimeTitle = {
  id: "b87b7295-061d-40e4-9427-5fbb46c1dc22",
  defaultMessage: "Date & Time",
};

export const MSG_nextDateLabel = {
  id: "628aca29-37e7-4ac0-9516-2505f3f90b46",
  defaultMessage: "Next: {date}",
};

export const MSG_noTechniciansAvailableTitle = {
  id: "8dccf71f-182f-4040-b975-d9c3d14ef68d",
  description: "A short header above the error message when no technicians are available to perform the services they requested.",
  defaultMessage: "No Technicians Available",
};

export const MSG_noTechniciansAvaialbleMessage = {
  id: "5fbaa828-e0b1-4aa3-99d5-488b1c9d94b6",
  description: "An error message when no technicians are available to perform the services they requested.",
  defaultMessage: "The combination of services you requested cannot be handled by our online scheduling system.  We should still be able to accommodate you, so please call us at {phone} to continue with scheduling.",
};

export const MSG_preferredDateTitle = {
  id: "f4ae8d44-9456-430c-b37f-974ef56a3c29",
  description: "A header displayed above the date picker widget that lets them choose their preferred appointment date.",
  defaultMessage: "Preferred Date",
};

export const MSG_anUnexpectedErrorOccurredColonLabel = {
  id: "4f425301-1b8b-4e0b-a819-bef565ebb264",
  defaultMessage: "An unexpected error occurred:",
};

export const MSG_nameAndPreferencesTitle = {
  id: "1f517188-cb27-4925-a5d6-7cfdf73943f7",
  defaultMessage: "Name & Preferences",
};

export const MSG_newAddressTitle = {
  id: "a5ee6ef0-ab9f-4e52-85a4-08500993e706",
  description: "The button for creating a new address.",
  defaultMessage: "New Address",
};

export const MSG_editAddressTitle = {
  id: "73c7450c-a110-4a48-9022-cd399c503be7",
  defaultMessage: "Edit Address",
};

export const MSG_whyQuestionLabel = {
  id: "184f4a56-7d73-426f-8007-687679d31bd2",
  description: "The label asking for a reason for something, for example the reason they are marking a client record as inactive.",
  defaultMessage: "Why?",
};

export const MSG_pleaseCallOrEmail = {
  id: "e8e4b883-4a3b-4bc3-9028-f98181366a4b",
  defaultMessage: "Please call us at {phone} or email us at {email}.",
};

export const MSG_pleaseCall = {
  id: "4d194a81-6fd6-417a-8408-bdae489012a3",
  defaultMessage: "Please call us at {phone}.",
};

export const MSG_pleaseEmail = {
  id: "2046797b-3f53-4bc1-8c8f-716f89e6b4b5",
  defaultMessage: "Please email us at {email}.",
};

export const MSG_contactUsTitle = {
  id: "d98ec361-be17-4b78-820b-540e11313ce3",
  description: "The label for a button that when tapped will show details on how to contact the technician (phone and email).",
  defaultMessage: "Contact Us",
};

export const MSG_callUsTitle = {
  id: "4a9b9944-af0d-4f5a-8c1c-8860257533b0",
  description: "A label for the button to call the technician.",
  defaultMessage: "Call Us",
};

export const MSG_problemSavingAddressTitle = {
  id: "a725e19a-eb53-4125-a918-610b57901c45",
  defaultMessage: "There was a problem saving the address:",
};

export const MSG_editNameTitle = {
  id: "902c2bba-689a-4678-a71d-22430ae69623",
  defaultMessage: "Edit Name",
};

export const MSG_problemSavingNameLabel = {
  id: "850bc3ae-7bfc-43c8-9578-5045af4a1654",
  defaultMessage: "There was a problem saving the name:",
};

export const MSG_problemSavingPhoneNumberLabel = {
  id: "dd660b72-8784-4269-87fd-32c73484d27b",
  defaultMessage: "There was a problem saving the phone number:",
};

export const MSG_pastTitle = {
  id: "350ca9af-8867-45ae-a9e2-232a20a52b2f",
  description: "When something is in the past (as opposed to the future)",
  defaultMessage: "Past",
};

export const MSG_futureTitle = {
  id: "d139ee9e-e394-40a6-9968-b27f8a81f3ea",
  description: "When a timeline entry is in the future, not the past.",
  defaultMessage: "Future",
};

export const MSG_showAllLabel = {
  id: "c2eb11e4-5580-4dd3-842c-ffd5aea53e9a",
  description: "This is the label for a filter that can be applied to show only certain things on the timeline.",
  defaultMessage: "Clear all filters",
};

export const MSG_editNotesTitle = {
  id: "055ed236-3c9a-48fc-8656-a3993c3b1a73",
  description: "A button title that lets them edit the notes written about something.",
  defaultMessage: "Edit Notes"
};

export const MSG_resetAddressTitle = {
  id: "2122d5b7-3a53-487e-9c50-6b9539daea82",
  description: "Text that is displayed on a button to reset an address entry back to blank.",
  defaultMessage: "Reset Address",
};

export const MSG_manuallyEnterAddressLabel = {
  id: "999483c4-0676-4b04-ac82-956e4df288ed",
  description: "Label for a button that is tapped to edit an address by hand.",
  defaultMessage: "Manually enter address",
};

export const MSG_copyrightByGazelleLabel = {
  id: "c294f29b-7393-4659-85bb-44d55f7642fc",
  description: "This is our legal copyright notice.  The copyright symbol is provided as a variable {copyright}.",
  defaultMessage: "{copyright} {year} by Gazelle, LLC",
};

export const MSG_loggedInAsLabel = {
  id: "6b91b296-37eb-4104-8159-a773eb77f15f",
  defaultMessage: "Logged in as",
};

export const MSG_withBufferMinutesLabel = {
  id: "ced2a116-5a02-4977-8584-07cf75fd45e7",
  description: "This is added to the end of a time slot to indicate that the time slot was created including a buffer time automatically.  For example: '7:00 PM - 8:15 PM (with 15m buffer)'.  The value 'minutes' will be formatted for you already like '5m' or '1h 45m'.",
  defaultMessage: "(with {minutes} buffer)",
};

export const MSG_takeScreenshotTitle = {
  id: "bdcc24da-60f6-410d-b0fe-51abf3fe9c38",
  description: "The button that lets them take a picture of their current screen and attach it to a support request.",
  defaultMessage: "Take Screenshot",
};

export const MSG_findClientsTitle = {
  id: "6c39804d-7b55-4ae0-9da0-55bdb52eef00",
  description: "This is the title of a small button used to search for client records.  Keep this as short as possible.",
  defaultMessage: "Find Clients",
};

export const MSG_moreActionsTitle = {
  id: "f3077b68-dbe3-4d58-8b2a-476d785c6521",
  description: "The title of the popup window that displays to show more actions that can be performed.",
  defaultMessage: "More Actions",
};

export const MSG_noTranslationsLabel = {
  id: "6d89f5e2-f00e-45db-a82e-c8accc60e860",
  description: "This is shown when no translation has been created for a message into a specific language.",
  defaultMessage: "No translation",
};

export const MSG_justGettingStartedWithGazelle = {
  id: "903fa874-5f28-4a82-b4bd-398134eda6c3",
  defaultMessage: "Just getting started with Gazelle?  We can import your existing information for free.",
};

export const MSG_howCanIImportMyData = {
  id: "f51afec8-d420-4f50-b266-ca35761649eb",
  defaultMessage: "How can I import my data?",
};

export const MSG_noInternetConnectionLabel = {
  id: "0788a343-7ae0-486d-bd34-635631360f4e",
  defaultMessage: "No internet connection",
};

export const MSG_optionsTitle = {
  id: "30c4bf31-fab4-4339-8c6d-14e5d057f652",
  defaultMessage: "Options",
};

export const MSG_deviceUnableToReachInternet = {
  id: "381f0f65-51e2-4624-8f38-e446faab6e31",
  defaultMessage: "Your device is unable to reach the Internet.  To restore full functionality in the Gazelle app, please connect to wifi or re-establish your cellular connection.",
};

export const MSG_retryTitle = {
  id: "62e0dbec-59ea-4b27-a79e-a22d99061b12",
  defaultMessage: "Retry",
};

export const MSG_problemUploadingScreenshots = {
  id: "d03dff29-8216-4c44-9e43-eb16e982bd7d",
  defaultMessage: "There was a problem uploading the attached screenshots.  Please try again, or remove the screenshots.",
};

export const MSG_problemSendingSupportRequest = {
  id: "d5c2824b-9a64-4642-9fee-bc95db208abf",
  defaultMessage: "There was a problem sending the support request.  Please try again, or email us directly at support@gazelleapp.io.",
};

export const MSG_includeDeviceInformationLabel = {
  id: "19cbd216-090c-4095-bd81-a615c871abb3",
  defaultMessage: "Include device information",
};

export const MSG_deviceInformationExplanation = {
  id: "9b49b44a-b593-42ff-8077-f6b2790627f7",
  defaultMessage: "If enabled, this will send us some helpful information about this device.  While this is not required, it does often help us diagnose the cause of problems more quickly.",
};

export const MSG_loginTitle = {
  id: "7decf443-40a6-45b0-8104-1a20bc93c960",
  defaultMessage: "Login",
};

export const MSG_gazelleDescriptionAndUrl = {
  id: "7733a222-25be-42af-b859-f32ed23c84f7",
  defaultMessage: "Gazelle is the all-in-one business automation platform for piano technicians.  Find out more at:",
};

export const MSG_findAnAddressLabel = {
  id: "9e629d6a-ee1d-4f5f-8538-29fff8cd25ef",
  defaultMessage: "Find an address",
};

export const MSG_dateAtTime = {
  id: "ab8e7c11-65ca-401f-a1a6-18861650d514",
  description: "Sometimes we need to simply display a date and a time for something.",
  defaultMessage: "{date} at {time}"
};

export const MSG_howDoesThisWork = {
  id: "e2ea35b2-fab7-4901-b2d8-15ff84edf74f",
  description: "",
  defaultMessage: "How does this work?"
};

export const MSG_commentLabel = {
  id: "33f55c5c-bd04-4300-b995-84c0f2842eb1",
  description: "The label displayed above a comment that a user has made on a client's timeline (or anywhere else).",
  defaultMessage: "Comment"
};

export const MSG_helpTitle = {
  id: "94c4b17e-0482-4a40-8416-1d48f7555b38",
  description: "The label for the button where they can get help and support",
  defaultMessage: "Help"
};

export const MSG_dateCreatedLabel = {
  id: "24adeb61-8186-4a7f-812a-5cefcecccd40",
  description: "A label for a date that an item was created.",
  defaultMessage: "Date created"
};

export const MSG_sortByLabel = {
  id: "831ae206-2121-4e6b-9800-d88acdbfefb3",
  description: "The label for the option to sort a list",
  defaultMessage: "Sort by"
};

export const MSG_dateOccurredTitle = {
  id: "92772583-4a91-49a6-b416-d63acd19c400",
  description: "A label for the date something occurred",
  defaultMessage: "Date Occurred"
};

export const MSG_dateOccurredAfterLabel = {
  id: "e9e36fbd-9fe9-4dd1-9e53-626fb81b63c2",
  description: "A label for the date something occurred",
  defaultMessage: "Occurred after (or on)"
};

export const MSG_dateOccurredBeforeLabel = {
  id: "c8d255e8-09f9-4c66-a279-5ae170a25197",
  description: "A label for the date something occurred",
  defaultMessage: "Occurred before (or on)"
};

export const MSG_anEmailAddressIsRequiredExplanation = {
  id: "c34373af-d58f-4a11-a264-ae79851e66ba",
  description: "A message that is displayed when the user has not supplied a required email address.",
  defaultMessage: "An email address is required."
};

export const MSG_goToSettingsTitle = {
  id: "45abf85b-5826-4799-a7c7-f503d3f27d46",
  description: "",
  defaultMessage: "Go to Settings"
};

export const MSG_minutesLabel = {
  id: "0cab95c3-0f22-47f4-bff5-20a5ad221036",
  description: "The word 'minutes' that will be used like this:  '5 minutes'",
  defaultMessage: "minutes"
};

export const MSG_includeTitle = {
  id: "647c3781-434f-47ce-8723-df82e96068a4",
  description: "This is the title of a button that lets them include something in a list.",
  defaultMessage: "Include"
};
