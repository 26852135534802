/**
 * Calling this in a componentDidMount() function in a component will scroll
 * the page to the top.  Any app can have a main-scroll-container id element
 * if it uses fixed layouts.  Otherwise, the entire page will get scrolled.
 */
function scrollTop() {
  window.scrollTo(0, 0);
  let el = document.getElementById('main-scroll-container');
  if (el) {
    el.scrollTop = 0;
  }
}

export { scrollTop };