import {
  FilterType,
  IFilterConfigProps,
  IFilterHandler,
  IFilterLabelProps,
  IFilterTypeConfig,
  IRadioFilter
} from "./types";
import { faList } from "@fortawesome/pro-solid-svg-icons";

export const GenericRadioFilterHandler: IFilterHandler = {
  type: FilterType.RADIO,

  getIcon: () => faList,

  getQueryVariables: (filter: IRadioFilter, config: IFilterTypeConfig) => {
    return {[config.queryArgumentKey]: filter.value};
  },

  isValid: (filter: IRadioFilter) => {
    return true;
  },

  defaultValue: (config: IFilterTypeConfig) => {
    return {
      key: config.key,
      type: FilterType.RADIO,
      value: config.defaultValue
    };
  },

  LabelComponent: (props: IFilterLabelProps) => {
    throw "Expected RadioFilterHandler to implement LabelConfig.";
  },

  ConfigComponent: (props: IFilterConfigProps) => {
    throw "Expected RadioFilterHandler to implement ComponentConfig.";
  }
};
