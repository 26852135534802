import * as React from "react";
import { FunctionComponent } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { User } from "../../models";
import { SELECTION_BASE } from "../../enums";

export enum FilterType {
  DATE = 'DATE',
  BOOLEAN = 'BOOLEAN',
  SELECT = 'SELECT',
  RADIO = 'RADIO',
  CURRENCY = 'CURRENCY',
  NUMBER = 'NUMBER',
  EXACT_STRING = 'EXACT_STRING',
}

export interface IFilterSet {
  label: string;
  filterState: IFilterState
}

export interface IFilterTypeConfig {
  key: string;
  type: FilterType;
  selectorLabel: string | React.ReactElement;
  buttonLabel: string | React.ReactElement;
  negativeButtonLabel?: string | React.ReactElement; // used by the boolean filter
  dateAfterOrOnLabel?: string | React.ReactElement;
  dateBeforeOrOnLabel?: string | React.ReactElement;
  invertBoolean?: boolean;
  noFormatting?: boolean;
  queryArgumentKey: string;
  icon?: IconProp;
  options?: {label: string, value: any, specialStyle?: 'tag'}[];
  defaultValue?: any;
  getQueryVariables?: (filter: AnyFilter) => {[key: string]: any};
}

export interface IDateFilter {
  key: string;
  type: FilterType.DATE,
  value: {lte?: string, gte?: string}
}

export interface IBooleanFilter {
  key: string;
  type: FilterType.BOOLEAN,
  value: boolean,
}

export interface ISelectFilter {
  key: string;
  type: FilterType.SELECT,
  value: (string | number)[];
}

export interface IRadioFilter {
  key: string;
  type: FilterType.RADIO,
  value: string | number;
}

export interface ICurrencyFilter {
  key: string;
  type: FilterType.CURRENCY,
  value: {lte?: number, gte?: number}
}

export interface INumberFilter {
  key: string;
  type: FilterType.NUMBER,
  value: {lte?: number, gte?: number}
}

export interface IExactStringFilter {
  key: string;
  type: FilterType.EXACT_STRING,
  value: string,
}

export type AnyFilter = IDateFilter | IBooleanFilter | ISelectFilter | INumberFilter | ICurrencyFilter | IExactStringFilter | IRadioFilter;

export interface IFilterState {
  searchString: string;
  filters: AnyFilter[];
  sortBy?: string | string[];
}

export interface ISelectionState {
  base: SELECTION_BASE,
  ids: string[]
}


/////////////////////////////////////////////////////////////

export interface IFilterLabelProps {
  config: IFilterTypeConfig,
  filter: AnyFilter;
  buttonSize: 'normal' | 'small',
  textSize: 'normal' | 'small' | 'large' | 'xsmall' | 'xxsmall' | 'xlarge' | 'xxlarge';
  iconSize: number,
}

export interface IFilterConfigProps {
  config: IFilterTypeConfig,
  filter: AnyFilter;
  close: () => any;
  onChange?: (value: AnyFilter) => any;
  user?: User;
}

export interface IFilterHandler {
  type: FilterType;
  getIcon: () => IconProp | string;
  getQueryVariables: (filter: AnyFilter, config: IFilterTypeConfig) => {[key: string]: any};
  isValid: (filter: AnyFilter) => boolean;
  defaultValue: (config: IFilterTypeConfig) => AnyFilter;
  LabelComponent: FunctionComponent<IFilterLabelProps>;
  ConfigComponent: FunctionComponent<IFilterConfigProps>;
}
