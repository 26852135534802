import * as React from 'react';
import { DateSlots, Slot } from '@core';
import { observer } from 'mobx-react';
import * as $ from 'jquery';

import { DateSlotsContainer } from "./DateSlotsContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/pro-solid-svg-icons";

interface IOtherDateSlotsProps {
  otherDateSlots: DateSlots[],
  onClick: (slot: Slot) => void,
}

const OtherDateSlots = observer(class OtherDateSlots extends React.Component<IOtherDateSlotsProps> {
  maskEl: Element;

  scrollMask = (multiplier: number) => {
    const scrollWidth = multiplier * (this.maskEl.clientWidth * 0.9);
    if (typeof this.maskEl.scrollBy !== 'undefined') {
      // Use the browser's native scrollBy method if it exists.  This is available
      // on most modern browsers, and it provides a smoother scroll experience.
      this.maskEl.scrollBy(scrollWidth, 0);
    } else {
      // Using jQuery for maximum cross-browser compatibility since we are using
      // several things here that are different across browsers.
      const currentPos = $(this.maskEl).scrollLeft();
      $(this.maskEl).animate({scrollLeft: currentPos + scrollWidth}, 250);
    }
  };

  render() {

    return (
        <div className="other-date-slots-wrapper">
          <div className="other-date-slots-mask" ref={el => this.maskEl = el}>
            {this.props.otherDateSlots.map((dateSlots, i) => (
              <DateSlotsContainer key={i}
                                  dateSlots={dateSlots}
                                  onClick={this.props.onClick} />
            ))}
          </div>

          <div className="other-date-slots-shadow" />

          <div className="other-date-slots-more-left pagination-button"
               onClick={() => this.scrollMask(-1)}>
            <FontAwesomeIcon icon={faAngleLeft} />
          </div>

          <div className="other-date-slots-more-right pagination-button"
               onClick={() => this.scrollMask(1)}>
            <FontAwesomeIcon icon={faAngleRight} />
          </div>
        </div>
    );
  }
});

export { OtherDateSlots };
