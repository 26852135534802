import { EVENT_RECURRENCE_TYPE } from "../enums";
import { formatMessage } from "./intl";
import { MSG_eventRecurrenceTypeMonthly, MSG_eventRecurrenceTypeWeekly } from "../strings";

// Use this if you want to iterate over all the types (e.g. for a <select>)
const SortedEventRecurrenceTypes = [
  EVENT_RECURRENCE_TYPE.WEEKLY,
  EVENT_RECURRENCE_TYPE.MONTHLY,
];

const EventRecurrenceTypeInfo: {[key: string]: {type: EVENT_RECURRENCE_TYPE, getLabel: () => string}} = {
  [EVENT_RECURRENCE_TYPE.WEEKLY]: {
    type: EVENT_RECURRENCE_TYPE.WEEKLY,
    getLabel: () => formatMessage(MSG_eventRecurrenceTypeWeekly),
  },
  [EVENT_RECURRENCE_TYPE.MONTHLY]: {
    type: EVENT_RECURRENCE_TYPE.MONTHLY,
    getLabel: () => formatMessage(MSG_eventRecurrenceTypeMonthly),
  },
};

export { EventRecurrenceTypeInfo, SortedEventRecurrenceTypes };
