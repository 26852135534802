import { IDashboardTimeframe } from "../interfaces";
import { formatMessage } from "./intl";
import {
  MSG_dashboardLast365DaysLabel, MSG_dashboardLastMonthLabel,
  MSG_dashboardLastQuarterLabel,
  MSG_dashboardLastYearLabel,
  MSG_dashboardMonthToDateLabel,
  MSG_dashboardQuarterToDateLabel,
  MSG_dashboardYearToDateLabel
} from "../strings";

const moment = require('moment-timezone');

/**
 * The way the timeframe ids work is the first two segments say what the start timeframe
 * is, and the last two say what the end timeframe is.
 *
 * So for example:  "day:-356:day:-1" (e.g. the last 365 days from yesterday)
 *   startOn = moment().add(-365, 'day').startOf('day')
 *   endOn = moment().add(-1, 'day').endOf('day')
 *
 * Another example: "year:0:day:-1"  (e.g. year-to-date as of yesterday)
 *   startOn = moment().add(0, 'year').startOf('year')
 *   endOn = moment().add(-1, 'day').endOf('day')
 *
 * Another example: "quarter:-1:quarter-1" (e.g. last quarter in its entirety)
 *   startOn = moment().add(-1, 'quarter').startOf('quarter')
 *   endOn = moment().add(-1, 'quarter').endOf('quarter')
 */
export function getDashboardTimeframes(): IDashboardTimeframe[] {
  return [
    {id: 'day:-365:day:-1', label: formatMessage(MSG_dashboardLast365DaysLabel)},
    {id: 'year:0:day:-1', label: formatMessage(MSG_dashboardYearToDateLabel)},
    {id: 'quarter:0:day:-1', label: formatMessage(MSG_dashboardQuarterToDateLabel)},
    {id: 'month:0:day:-1', label: formatMessage(MSG_dashboardMonthToDateLabel)},
    {id: 'year:-1:year:-1', label: formatMessage(MSG_dashboardLastYearLabel)},
    {id: 'quarter:-1:quarter:-1', label: formatMessage(MSG_dashboardLastQuarterLabel)},
    {id: 'month:-1:month:-1', label: formatMessage(MSG_dashboardLastMonthLabel)},
  ];
}

export function getDashboardTimeframeDates(tfid: string): {startOn: string, endOn: string, prevStartOn: string, prevEndOn: string} {
  const parts = tfid.split(":");
  const startPeriod = parts[0];
  const startOffset = parseInt(parts[1]);
  const endPeriod = parts[2];
  const endOffset = parseInt(parts[3]);

  const startOn = moment().add(startOffset, startPeriod).startOf(startPeriod);
  const endOn = moment().add(endOffset, endPeriod).endOf(endPeriod);
  return {
    startOn: startOn.format('YYYY-MM-DD'),
    endOn: endOn.format('YYYY-MM-DD'),
    prevStartOn: moment(startOn).subtract(1, 'year').format('YYYY-MM-DD'),
    prevEndOn: moment(endOn).subtract(1, 'year').format('YYYY-MM-DD'),
  };
}

export function weekOfMonth(input = moment()) {
  const firstDayOfMonth = input.clone().startOf('month');
  const firstDayOfWeek = firstDayOfMonth.clone().startOf('week');

  const offset = firstDayOfMonth.diff(firstDayOfWeek, 'days');

  return Math.ceil((input.date() + offset) / 7);
}

export function firstWeekOfMonth(input = moment()) {
  return weekOfMonth(input) === 1;
}

export function lastWeekOfMonth(input = moment()) {
  const lastDayOfMonth = input.clone().endOf('month');
  return weekOfMonth(lastDayOfMonth) === weekOfMonth(input);
}

export function getMidpoint(data: number[]): number {
  return Math.ceil(data.length/2);
}

export function getMedian(data: number[]): number {
  return data[getMidpoint(data)];
}

// This throws out upper outliers and returns the max value in the remaining set
export function getMaxValue(data: number[]) {
  if (!data) return 0;

  data.sort((a, b) => { return parseInt(a as any) - parseInt(b as any); });
  if (data.length === 0) return null;
  if (data.length < 2) return data[data.length - 1];
  const midpoint = getMidpoint(data);
  let q1 = data.slice(0, midpoint - 1);
  let q3 = data.slice(midpoint + 1);
  let iqr = getMedian(q3) - getMedian(q1);

  // console.log('Source Data:', JSON.stringify(data));
  // console.log('First Half:', JSON.stringify(q1));
  // console.log('Q1:', getMedian(q1));
  // console.log('Second Half:', JSON.stringify(q3));
  // console.log('Q3:', getMedian(q3));
  // console.log('IQR:', iqr);
  // console.log('IQR * 1.5:', iqr * 1.5);

  return Math.min(getMedian(data) + iqr * 1.5, Math.max(...data));
}
