import * as React from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';
import { observer } from 'mobx-react';
import { makeObservable, observable } from "mobx";
import { scrollTop, StepContent, StepFooter } from '@core';

import { PianoWrapper } from '@client_scheduler/models/piano_wrapper';

import { PianoForm } from './PianoForm';
import { PianoList } from './PianoList';
import { MSG_addAnotherPianoTitle, MSG_cancelTitle, MSG_nextTitle, MSG_saveTitle } from "@gazelle/shared/strings";
import { formatMessage } from "@gazelle/shared/utils";
import { rootStoreInstance as rootStore } from "@client_scheduler/modules/root";

interface IStep2PianosProps {}

const Step2Pianos = observer(class Step2Pianos extends React.Component<IStep2PianosProps> {
  editing: boolean = false;
  editingPianoWrapper: PianoWrapper = null;

  constructor(props: IStep2PianosProps) {
    super(props);

    makeObservable(this, {
      editing: observable,
      editingPianoWrapper: observable
    });
  }

  componentDidMount() {
    scrollTop();
  }

  toggleEditing = () => {
    const { pianoStore } = rootStore;
    if (pianoStore.editingPianoWrapper) {
      pianoStore.stopEditing();
    } else {
      pianoStore.startEditing();
    }
  };

  handleEdit = (pianoWrapper: PianoWrapper) => {
    rootStore.pianoStore.startEditing(pianoWrapper);
  };

  handleRemove = (pianoWrapper: PianoWrapper) => {
    rootStore.pianoStore.remove(pianoWrapper);
  };

  handleClear = (pianoWrapper: PianoWrapper) => {
    pianoWrapper.clear();
  };

  handleNew = () => {
    rootStore.pianoStore.startEditing();
  };

  handleSave = () => {
    rootStore.pianoStore.saveEditingWrapper();
  };

  handleNextButtonClicked = () => {
    if (rootStore.isStepComplete[2]) {
      // if the user has a preferred technician but that technician is not able to perform the selected services
      // then we want to select the "any technician" option.
      if (rootStore.prefStore.clientPreferredTechnicianId && rootStore.prefStore.availableUsers.filter(user => user.id === rootStore.prefStore.clientPreferredTechnicianId).length === 0) {
        rootStore.prefStore.setPreferredUser(null, true);
      }
      rootStore.unlockStep(3);
      rootStore.setCurrentStep(3);
    }
  };

  render() {
    const { pianoStore, pianoStore: { pianoWrappers }} = rootStore;
    const nextButtonText = formatMessage(MSG_nextTitle);
    const newPianoButtonText = formatMessage(MSG_addAnotherPianoTitle);

    return (
      <div>
        <StepContent className="step2-pianos-container" minHeight={rootStore.minHeight}>
          {pianoStore.maxNumPianos > 1
            ? <PianoList onEdit={this.handleEdit}
                         onRemove={this.handleRemove}
            />
            : <PianoForm pianoWrapper={pianoWrappers[0]}
                         onClear={this.handleClear} />}
        </StepContent>
        <StepFooter
          rootStore={rootStore}
          leftSide={
            <div>
              <Button size="lg"
                      id="add-piano"
                      className="d-none d-md-inline-block"
                      color="primary"
                      onClick={this.handleNew}>
                {newPianoButtonText}
              </Button>
              <Button className="d-md-none"
                      color="primary"
                      onClick={this.handleNew}>
                {newPianoButtonText}
              </Button>
            </div>
          }
          rightSide={
            <div>
              <Button size="lg"
                      id="next-button"
                      className="d-none d-md-inline-block"
                      color="primary"
                      disabled={!rootStore.isStepComplete[2]}
                      onClick={this.handleNextButtonClicked}>
                {nextButtonText}
              </Button>
              <Button className="d-md-none"
                      id="next-button"
                      color="primary"
                      disabled={!rootStore.isStepComplete[2]}
                      onClick={this.handleNextButtonClicked}>
                {nextButtonText}
              </Button>
            </div>
          }/>

        <Modal size="lg"
               isOpen={!!pianoStore.editingPianoWrapper}
               toggle={this.toggleEditing}>
          <ModalBody>
            <PianoForm
              onClear={this.handleClear}
              pianoWrapper={pianoStore.editingPianoWrapper}
              footer={(
                <div>
                  <Button className="cancel-button" color="secondary" onClick={this.toggleEditing}>
                    {formatMessage(MSG_cancelTitle)}
                  </Button>
                  {' '}
                  <Button className="save-button" color="primary" onClick={this.handleSave}>
                    {formatMessage(MSG_saveTitle)}
                  </Button>
                </div>
              )} />
          </ModalBody>
        </Modal>

      </div>
    );
  }
});

export { Step2Pianos };
