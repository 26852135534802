import * as React from "react";
import { REMINDER_TYPE, TIMEFRAME_DATA_TYPE, USER_ACCESS_LEVEL } from "../enums";
import { getDashboardTimeframeDates } from "./dashboard_timeframe_info";
import { DashboardMetricsTimeframeSummaryStore } from "../stores/dashboard_metrics_timeframe_summary_store";
import { DashboardMetricsTimeframeDataStore } from "../stores/dashboard_metrics_timeframe_data_store";
import { RootStore } from "../stores/root_store";
import { IDashboardTimeframeData } from "../interfaces";
import { useRefFn } from "../hooks/useRefFn";
import { formatMessage } from "./intl";
import {
  MSG_appointmentsFeatureTitle,
  MSG_clientsFeatureTitle,
  MSG_dashboardMetricAppointmentsPercentGrowthExplanation,
  MSG_dashboardMetricAppointmentsPercentNegativeGrowthExplanation,
  MSG_dashboardMetricClientsActive,
  MSG_dashboardMetricClientsActiveExplanation,
  MSG_dashboardMetricClientsCreated,
  MSG_dashboardMetricClientsCreatedExplanation,
  MSG_dashboardMetricClientsInactive,
  MSG_dashboardMetricClientsInactiveExplanation,
  MSG_dashboardMetricClientsNew,
  MSG_dashboardMetricClientsNewExplanation,
  MSG_dashboardMetricClientsPercentGrowthExplanation,
  MSG_dashboardMetricClientsPercentNegativeGrowthExplanation,
  MSG_dashboardMetricClientsProspect,
  MSG_dashboardMetricClientsProspectExplanation,
  MSG_dashboardMetricCurrentlyDue,
  MSG_dashboardMetricCurrentlyDueExplanation,
  MSG_dashboardMetricEstimatesCreated,
  MSG_dashboardMetricEstimatesCreatedExplanation,
  MSG_dashboardMetricEstimatesCurrentlyUnexpired,
  MSG_dashboardMetricEstimatesCurrentlyUnexpiredExplanation,
  MSG_dashboardMetricEstimatesValueConverted,
  MSG_dashboardMetricEstimatesValueConvertedExplanation,
  MSG_dashboardMetricInvoicesCreated,
  MSG_dashboardMetricInvoicesCreatedExplanation,
  MSG_dashboardMetricInvoicesSynced,
  MSG_dashboardMetricInvoicesSyncedExplanation,
  MSG_dashboardMetricPaymentsReceived,
  MSG_dashboardMetricPaymentsReceivedExplanation,
  MSG_dashboardMetricPercentGrowth,
  MSG_dashboardMetricPianosActive,
  MSG_dashboardMetricPianosActiveExplanation,
  MSG_dashboardMetricPianosAppointmentsPercentTypeExplanation,
  MSG_dashboardMetricPianosCreated,
  MSG_dashboardMetricPianosCreatedExplanation,
  MSG_dashboardMetricPianosCreatedPercentTypeExplanation,
  MSG_dashboardMetricPianosInactive,
  MSG_dashboardMetricPianosInactiveExplanation,
  MSG_dashboardMetricPianosPercentType,
  MSG_dashboardMetricRemindersPercentType,
  MSG_dashboardMetricRemindersPercentTypeExplanation,
  MSG_dashboardMetricRemindersSent,
  MSG_dashboardMetricRemindersSentExplanation,
  MSG_dashboardMetricTotalAppointments,
  MSG_dashboardMetricTotalAppointmentsExplanation,
  MSG_dashboardMetricTotalClientsScheduled,
  MSG_dashboardMetricTotalClientsScheduledExplanation,
  MSG_dashboardMetricTotalConvertedEstimates,
  MSG_dashboardMetricTotalConvertedEstimatesExplanation,
  MSG_dashboardMetricTotalInvoiced,
  MSG_dashboardMetricTotalInvoicedExplanation,
  MSG_dashboardMetricTotalNoShows,
  MSG_dashboardMetricTotalNoShowsExplanation,
  MSG_dashboardMetricUnknownPercentGrowthExplanation,
  MSG_dashboardMetricValueEstimated,
  MSG_dashboardMetricValueEstimatedExplanation,
  MSG_estimatesFeatureTitle,
  MSG_invoicesTitle,
  MSG_pianosFeatureTitle,
  MSG_remindersFeatureTitle,
  MSG_unknownLabel
} from "../strings";
import { formatDate } from "./time";
import { formatCurrency, formatNumber, formatPercent } from "./numbers";
import { BLUE_COLOR, GREEN_COLOR, ORANGE_COLOR, RED_COLOR, TEAL_COLOR } from "../colors";
import {
  faCalendarAlt,
  faClipboardList,
  faClock,
  faFileInvoiceDollar,
  faPiano,
  faUser
} from "@fortawesome/pro-solid-svg-icons";
import { PianoTypeInfo } from "./piano_type_info";
import { ReminderTypeInfo } from "./reminder_type_info";
import { MessageDescriptor } from "react-intl";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

const moment = require('moment-timezone');

export interface IDashboardMetrics {
  timeframe: string;
  setTimeframe: (timeframe: string) => any;
  timeframeType: TIMEFRAME_DATA_TYPE;
  setTimeframeType: (type: TIMEFRAME_DATA_TYPE) => any;
  color: string;
  minDays:  number;
  startOn: string,
  endOn: string,
  prevStartOn: string;
  prevEndOn: string;
  heatmapStore: DashboardMetricsTimeframeDataStore;
  summaryStore: DashboardMetricsTimeframeSummaryStore;
  heatmapData: IDashboardTimeframeData[],
  sections: React.ReactElement[][],
  refreshHistoricalData: () => Promise<any>;
}

export function useDashboardMetrics(
  rootStore: RootStore,
  visible: boolean,
  calculateMinDuration: () => number,
  renderValuesSection: (key: string, label: string, color: string, icon: IconProp, active: boolean, onClick: () => any, lines: React.ReactElement[]) => React.ReactElement,
  renderValueLine: (children: any, wit: string) => React.ReactElement,
  renderValue: (value: string) => React.ReactElement,
  renderFormattedMessage: (msg: MessageDescriptor, values: any) => React.ReactElement // this is required because the <FormattedMessage/> component must be rendered inside an IntlProvider context
): IDashboardMetrics {
  const [timeframe, setTimeframe] = React.useState<string>('day:-365:day:-1');
  const [timeframeType, setTimeframeType] = React.useState<TIMEFRAME_DATA_TYPE>(rootStore.user.canViewCompanyMetrics ? TIMEFRAME_DATA_TYPE.CLIENTS_CREATED : TIMEFRAME_DATA_TYPE.ESTIMATES_CREATED);
  const [color, setColor] = React.useState<string>(null);
  const [minDays, setMinDays] = React.useState<number>(365);
  const [heatmapData, setHeatmapData] = React.useState<IDashboardTimeframeData[]>(null);
  const heatmapStoreRef = useRefFn<DashboardMetricsTimeframeDataStore>(() => {
    return new DashboardMetricsTimeframeDataStore(rootStore, timeframeType, startOn, endOn, userId);
  });
  const summaryStoreRef = useRefFn<DashboardMetricsTimeframeSummaryStore>(() => {
    return new DashboardMetricsTimeframeSummaryStore(rootStore, startOn, endOn, prevStartOn, prevEndOn, userId);
  });

  const {startOn, endOn, prevStartOn, prevEndOn} = getDashboardTimeframeDates(timeframe);
  const userId = rootStore.user.accessLevel === USER_ACCESS_LEVEL.TECHNICIAN ? rootStore.user.id : null;

  React.useEffect(() => {
    if (!visible) return; // optimization so we don't fetch data if not visible
    summaryStoreRef.current.setTimeframe(startOn, endOn, prevStartOn, prevEndOn, userId);
    void summaryStoreRef.current.fetch();
    setTimeframeType(timeframeType); // just to make linting happy
  }, [startOn, endOn, userId, visible]);

  React.useEffect(() => {
    if (!visible) return; // optimization so we don't fetch data if not visible
    heatmapStoreRef.current.setTimeframe(startOn, endOn, timeframeType, userId);
    heatmapStoreRef.current.fetch()
      .then(() => {
        setColor(heatmapStoreRef.current.color);
        setHeatmapData(heatmapStoreRef.current.fetchedTimeframeData);
      })
      .catch((e) => {
        // no op
      });
  }, [startOn, endOn, userId, timeframeType, visible]);

  // periodically recalculate the minimum size of the heatmap in order to
  // make sure it looks nice proportionally as the page changes size.
  React.useEffect(() => {
    let interval = setInterval(() => {
      setMinDays(calculateMinDuration());
    }, 500);
    return () => {
      clearInterval(interval);
    };
  }, []);

  async function refreshHistoricalData() {
    return Promise.all([
      summaryStoreRef.current.fetch(),
      heatmapStoreRef.current.fetch(),
    ]);
  }

  const fetchedData = summaryStoreRef.current?.fetchedData?.historicalTimeframe;
  const prevClientsCreated = summaryStoreRef.current?.previousClientsCreated;
  const prevAppointments = summaryStoreRef.current?.previousAppointments;

  let clientsGrowth = null;
  if (prevClientsCreated > 0) {
    clientsGrowth = fetchedData?.clientsCreated?.totalCount / prevClientsCreated * 100 - 100;
  }
  // This is a manual fix for when customers import data.  It looks like they have a massive
  // growth.  Or if they imported last year, it looks like they had a massive shrink.  This
  // isn't a perfect solution, but it catches most cases and looks better anyway.
  if (clientsGrowth > 200 || clientsGrowth < -200) {
    clientsGrowth = null;
  }

  let appointmentsGrowth = null;
  if (prevAppointments > 0) {
    appointmentsGrowth = fetchedData?.appointments?.totalCount / prevAppointments * 100 - 100;
  }
  // This is a manual fix for when customers import data.  It looks like they have a massive
  // growth.  Or if they imported last year, it looks like they had a massive shrink.  This
  // isn't a perfect solution, but it catches most cases and looks better anyway.
  if (appointmentsGrowth > 200 || appointmentsGrowth < -200) {
    appointmentsGrowth = null;
  }

  let clientsSection, pianosSection, appointmentsSection, remindersSection, estimatesSection, invoicesSection = null;
  const formattedStartDate = formatDate(moment(startOn));
  const formattedEndDate = formatDate(moment(endOn));
  const formattedPrevStartDate = formatDate(moment(prevStartOn));
  const formattedPrevEndDate = formatDate(moment(prevEndOn));
  let sections: React.ReactElement[][] = [];

  if (summaryStoreRef.current?.isDataLoaded && heatmapStoreRef.current?.isDataLoaded) {
    let values: React.ReactElement[] = [];
    if (rootStore.user.canViewCompanyMetrics) {
      // ***************************************************************
      // CLIENTS SECTION
      // ***************************************************************
      values = [];
      values.push(renderValueLine(
        renderFormattedMessage(
          MSG_dashboardMetricClientsCreated,
          {num: renderValue(formatNumber(fetchedData.clientsCreated.totalCount))}),
        formatMessage(MSG_dashboardMetricClientsCreatedExplanation, {
          num: formatNumber(fetchedData.clientsCreated.totalCount),
          startDate: formattedStartDate,
          endDate: formattedEndDate
        })
      ));
      values.push(renderValueLine(
        renderFormattedMessage(
          MSG_dashboardMetricClientsActive,
          {num: renderValue(formatNumber(fetchedData.clientsCreated.currentlyActive))}),
        formatMessage(MSG_dashboardMetricClientsActiveExplanation, {
          numCreated: formatNumber(fetchedData.clientsCreated.totalCount),
          num: formatNumber(fetchedData.clientsCreated.currentlyActive),
          startDate: formattedStartDate,
          endDate: formattedEndDate
        })
      ));
      values.push(renderValueLine(
        renderFormattedMessage(
          MSG_dashboardMetricClientsProspect,
          {num: renderValue(formatNumber(fetchedData.clientsCreated.currentlyProspect))}),
        formatMessage(MSG_dashboardMetricClientsProspectExplanation, {
          numCreated: formatNumber(fetchedData.clientsCreated.totalCount),
          num: formatNumber(fetchedData.clientsCreated.currentlyProspect),
          startDate: formattedStartDate,
          endDate: formattedEndDate
        })
      ));
      values.push(renderValueLine(
        renderFormattedMessage(
          MSG_dashboardMetricClientsNew,
          {num: renderValue(formatNumber(fetchedData.clientsCreated.currentlyNew))}),
        formatMessage(MSG_dashboardMetricClientsNewExplanation, {
          numCreated: formatNumber(fetchedData.clientsCreated.totalCount),
          num: formatNumber(fetchedData.clientsCreated.currentlyNew),
          startDate: formattedStartDate,
          endDate: formattedEndDate
        })
      ));
      values.push(renderValueLine(
        renderFormattedMessage(
          MSG_dashboardMetricClientsInactive,
          {num: renderValue(formatNumber(fetchedData.clientsCreated.currentlyInactive))}),
        formatMessage(MSG_dashboardMetricClientsInactiveExplanation, {
          numCreated: formatNumber(fetchedData.clientsCreated.totalCount),
          num: formatNumber(fetchedData.clientsCreated.currentlyInactive),
          startDate: formattedStartDate,
          endDate: formattedEndDate
        })
      ));
      if (clientsGrowth) {
        values.push(renderValueLine(
          renderFormattedMessage(
            MSG_dashboardMetricPercentGrowth,
            {
              percent: renderValue(formatPercent(clientsGrowth, 1, 0))
            }),
          formatMessage(clientsGrowth >= 0 ? /*ALLOW NAKED*/ MSG_dashboardMetricClientsPercentGrowthExplanation : /*ALLOW NAKED*/ MSG_dashboardMetricClientsPercentNegativeGrowthExplanation, {
            percent: formatPercent(Math.abs(clientsGrowth), 1, 0),
            startDate: formattedPrevStartDate,
            endDate: formattedPrevEndDate
          })
        ));
      } else {
        values.push(renderValueLine(
          renderFormattedMessage(
            MSG_dashboardMetricPercentGrowth,
            {
              percent: formatMessage(MSG_unknownLabel)
            }),
          formatMessage(MSG_dashboardMetricUnknownPercentGrowthExplanation, {
            startDate: formattedPrevStartDate,
            endDate: formattedPrevEndDate
          })
        ));
      }
      clientsSection = renderValuesSection(
        "clients",
        formatMessage(MSG_clientsFeatureTitle),
        GREEN_COLOR,
        faUser,
        timeframeType === TIMEFRAME_DATA_TYPE.CLIENTS_CREATED,
        () => setTimeframeType(TIMEFRAME_DATA_TYPE.CLIENTS_CREATED),
        values,
      );

      // ***************************************************************
      // PIANOS SECTION
      // ***************************************************************
      values = [];
      values.push(renderValueLine(
        renderFormattedMessage(
          MSG_dashboardMetricPianosCreated,
          {num: renderValue(formatNumber(fetchedData.pianosCreated.totalCount))}),
        formatMessage(MSG_dashboardMetricPianosCreatedExplanation, {
          num: formatNumber(fetchedData.pianosCreated.totalCount),
          startDate: formattedStartDate,
          endDate: formattedEndDate
        })
      ));
      values.push(renderValueLine(
        renderFormattedMessage(
          MSG_dashboardMetricPianosActive,
          {num: renderValue(formatNumber(fetchedData.pianosCreated.currentlyActive))}),
        formatMessage(MSG_dashboardMetricPianosActiveExplanation, {
          num: formatNumber(fetchedData.pianosCreated.currentlyActive),
          numCreated: formatNumber(fetchedData.pianosCreated.totalCount),
          startDate: formattedStartDate,
          endDate: formattedEndDate
        })
      ));
      values.push(renderValueLine(
        renderFormattedMessage(
          MSG_dashboardMetricPianosInactive,
          {num: renderValue(formatNumber(fetchedData.pianosCreated.currentlyInactive + fetchedData.pianosCreated.currentlyUnderRestoration + fetchedData.pianosCreated.currentlyInTemporaryStorage))}),
        formatMessage(MSG_dashboardMetricPianosInactiveExplanation, {
          num: formatNumber(fetchedData.pianosCreated.currentlyInactive + fetchedData.pianosCreated.currentlyUnderRestoration + fetchedData.pianosCreated.currentlyInTemporaryStorage),
          numCreated: formatNumber(fetchedData.pianosCreated.totalCount),
          startDate: formattedStartDate,
          endDate: formattedEndDate
        })
      ));
      fetchedData.pianosCreated.types
        .slice(0, 3)
        .forEach((t: any) => {
          values.push(renderValueLine(
            renderFormattedMessage(
              MSG_dashboardMetricPianosPercentType,
              {
                percent: renderValue(formatPercent(t.count / fetchedData.pianosCreated.totalCount * 100, 1, 0)),
                type: PianoTypeInfo[t.type].getLabel().toLocaleLowerCase(),
                startDate: formattedStartDate,
                endDate: formattedEndDate
              }),
            formatMessage(MSG_dashboardMetricPianosCreatedPercentTypeExplanation, {
              numCreated: formatNumber(fetchedData.pianosCreated.totalCount),
              percent: formatPercent(t.count / fetchedData.pianosCreated.totalCount * 100, 1, 0),
              type: PianoTypeInfo[t.type].getLabel().toLocaleLowerCase(),
              startDate: formattedStartDate,
              endDate: formattedEndDate
            })
          ));
        });
      pianosSection = renderValuesSection(
        "pianos",
        formatMessage(MSG_pianosFeatureTitle),
        BLUE_COLOR,
        faPiano,
        timeframeType === TIMEFRAME_DATA_TYPE.PIANOS_CREATED,
        () => setTimeframeType(TIMEFRAME_DATA_TYPE.PIANOS_CREATED),
        values,
      );
    }

    // ***************************************************************
    // APPOINTMENTS SECTION
    // ***************************************************************
    values = [];
    values.push(renderValueLine(
      renderFormattedMessage(
        MSG_dashboardMetricTotalAppointments,
        {num: renderValue(formatNumber(fetchedData.appointments.totalCount))}),
      formatMessage(MSG_dashboardMetricTotalAppointmentsExplanation, {
        num: formatNumber(fetchedData.appointments.totalCount),
        startDate: formattedStartDate,
        endDate: formattedEndDate
      })
    ));
    values.push(renderValueLine(
      renderFormattedMessage(
        MSG_dashboardMetricTotalClientsScheduled,
        {num: renderValue(formatNumber(fetchedData.appointments.clientsScheduledCount))}),
      formatMessage(MSG_dashboardMetricTotalClientsScheduledExplanation, {
        num: formatNumber(fetchedData.appointments.clientsScheduledCount),
        startDate: formattedStartDate,
        endDate: formattedEndDate
      })
    ));
    values.push(renderValueLine(
      renderFormattedMessage(
        MSG_dashboardMetricTotalNoShows,
        {num: renderValue(formatNumber(fetchedData.appointments.noShowCount))}),
      formatMessage(MSG_dashboardMetricTotalNoShowsExplanation, {
        num: formatNumber(fetchedData.appointments.noShowCount),
        startDate: formattedStartDate,
        endDate: formattedEndDate
      })
    ));
    fetchedData.appointments.pianoTypesServicedCounts.slice(0, 3).forEach((t: any) => {
      values.push(renderValueLine(
        renderFormattedMessage(
          MSG_dashboardMetricPianosPercentType,
          {
            percent:
              renderValue(formatPercent(fetchedData.appointments.pianosServicedCount ? t.count / fetchedData.appointments.pianosServicedCount * 100 : 0, 1, 0)),
            type: PianoTypeInfo[t.type].getLabel().toLocaleLowerCase()
          }),
        formatMessage(MSG_dashboardMetricPianosAppointmentsPercentTypeExplanation, {
          numServiced: formatNumber(fetchedData.appointments.pianosServicedCount),
          percent: formatPercent(fetchedData.appointments.pianosServicedCount ? t.count / fetchedData.appointments.pianosServicedCount * 100 : 0, 1, 0),
          type: PianoTypeInfo[t.type].getLabel().toLocaleLowerCase(),
          startDate: formattedStartDate,
          endDate: formattedEndDate
        })
      ));
    });
    if (appointmentsGrowth) {
      values.push(renderValueLine(
        renderFormattedMessage(
          MSG_dashboardMetricPercentGrowth,
          {
            percent: renderValue(formatPercent(appointmentsGrowth, 1, 0))
          }),
        formatMessage(appointmentsGrowth >= 0 ? /*ALLOW NAKED*/ MSG_dashboardMetricAppointmentsPercentGrowthExplanation : /*ALLOW NAKED*/ MSG_dashboardMetricAppointmentsPercentNegativeGrowthExplanation, {
          percent: formatPercent(Math.abs(appointmentsGrowth), 1, 0),
          startDate: formattedPrevStartDate,
          endDate: formattedPrevEndDate
        })
      ));
    } else {
      values.push(renderValueLine(
        renderFormattedMessage(
          MSG_dashboardMetricPercentGrowth,
          {
            percent: formatMessage(MSG_unknownLabel)
          }),
        formatMessage(MSG_dashboardMetricUnknownPercentGrowthExplanation, {
          startDate: formattedPrevStartDate,
          endDate: formattedPrevEndDate
        })
      ));
    }
    appointmentsSection = renderValuesSection(
      "appointments",
      formatMessage(MSG_appointmentsFeatureTitle),
      RED_COLOR,
      faCalendarAlt,
      timeframeType === TIMEFRAME_DATA_TYPE.APPOINTMENTS,
      () => setTimeframeType(TIMEFRAME_DATA_TYPE.APPOINTMENTS),
      values,
    );

    // ***************************************************************
    // REMINDERS SECTION
    // ***************************************************************
    if (rootStore.user.canViewCompanyMetrics) {
      values = [];
      values.push(renderValueLine(
        renderFormattedMessage(
          MSG_dashboardMetricRemindersSent,
          {num: renderValue(formatNumber(fetchedData.reminders.totalCount))}),
        formatMessage(MSG_dashboardMetricRemindersSentExplanation, {
          num: formatNumber(fetchedData.reminders.totalCount),
          startDate: formattedStartDate,
          endDate: formattedEndDate
        })
      ));
      values.push(renderValueLine(
        renderFormattedMessage(
          MSG_dashboardMetricRemindersPercentType,
          {
            percent: renderValue(formatPercent(fetchedData.reminders.totalCount ? fetchedData.reminders.emailCount / fetchedData.reminders.totalCount * 100 : 0, 1, 0)),
            type: ReminderTypeInfo[REMINDER_TYPE.EMAIL].getLabel().toLocaleLowerCase()
          }),
        formatMessage(MSG_dashboardMetricRemindersPercentTypeExplanation, {
          numSent: formatNumber(fetchedData.reminders.totalCount),
          percent: formatPercent(fetchedData.reminders.totalCount ? fetchedData.reminders.emailCount / fetchedData.reminders.totalCount * 100 : 0, 1, 0),
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          type: ReminderTypeInfo[REMINDER_TYPE.EMAIL].getLabel().toLocaleLowerCase()
        })
      ));
      values.push(renderValueLine(
        renderFormattedMessage(
          MSG_dashboardMetricRemindersPercentType,
          {
            percent: renderValue(formatPercent(fetchedData.reminders.totalCount ? fetchedData.reminders.smsCount / fetchedData.reminders.totalCount * 100 : 0, 1, 0)),
            type: ReminderTypeInfo[REMINDER_TYPE.SMS].getLabel().toLocaleLowerCase()
          }),
        formatMessage(MSG_dashboardMetricRemindersPercentTypeExplanation, {
          numSent: formatNumber(fetchedData.reminders.totalCount),
          percent: formatPercent(fetchedData.reminders.totalCount ? fetchedData.reminders.smsCount / fetchedData.reminders.totalCount * 100 : 0, 1, 0),
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          type: ReminderTypeInfo[REMINDER_TYPE.SMS].getLabel().toLocaleLowerCase()
        })
      ));
      values.push(renderValueLine(
        renderFormattedMessage(
          MSG_dashboardMetricRemindersPercentType,
          {
            percent: renderValue(formatPercent(fetchedData.reminders.totalCount ? fetchedData.reminders.phoneCount / fetchedData.reminders.totalCount * 100 : 0, 1, 0)),
            type: ReminderTypeInfo[REMINDER_TYPE.CALL].getLabel().toLocaleLowerCase()
          }),
        formatMessage(MSG_dashboardMetricRemindersPercentTypeExplanation, {
          numSent: formatNumber(fetchedData.reminders.totalCount),
          percent: formatPercent(fetchedData.reminders.totalCount ? fetchedData.reminders.phoneCount / fetchedData.reminders.totalCount * 100 : 0, 1, 0),
          startDate: formattedStartDate,
          endDate: formattedEndDate,
          type: ReminderTypeInfo[REMINDER_TYPE.CALL].getLabel().toLocaleLowerCase()
        })
      ));
      remindersSection = renderValuesSection(
        "reminders",
        formatMessage(MSG_remindersFeatureTitle),
        RED_COLOR,
        faClock,
        timeframeType === TIMEFRAME_DATA_TYPE.REMINDERS,
        () => setTimeframeType(TIMEFRAME_DATA_TYPE.REMINDERS),
        values,
      );
    }

    // ***************************************************************
    // ESTIMATES SECTION
    // ***************************************************************
    values = [];
    values.push(renderValueLine(
      renderFormattedMessage(
        MSG_dashboardMetricEstimatesCreated,
        {num: renderValue(formatNumber(fetchedData.estimatesCreated.totalCount))}),
      formatMessage(MSG_dashboardMetricEstimatesCreatedExplanation, {
        num: formatNumber(fetchedData.estimatesCreated.totalCount),
        startDate: formattedStartDate,
        endDate: formattedEndDate
      })
    ));
    fetchedData.estimatesCreated.estimatedTotal.map((estimatedTotal, i) => {
      return values.push(renderValueLine(
        renderFormattedMessage(
          MSG_dashboardMetricValueEstimated,
          {amount: renderValue(formatCurrency(estimatedTotal.value, null, null, estimatedTotal.currency))}),
        formatMessage(MSG_dashboardMetricValueEstimatedExplanation, {
          amount: formatCurrency(estimatedTotal.value, null, null, estimatedTotal.currency),
          startDate: formattedStartDate,
          endDate: formattedEndDate
        })
      ));
    });
    values.push(renderValueLine(
      renderFormattedMessage(
        MSG_dashboardMetricTotalConvertedEstimates,
        {num: renderValue(formatNumber(fetchedData.estimatesCreated.convertedCount))}),
      formatMessage(MSG_dashboardMetricTotalConvertedEstimatesExplanation, {
        num: formatNumber(fetchedData.estimatesCreated.convertedCount),
        numCreated: formatNumber(fetchedData.estimatesCreated.totalCount),
        startDate: formattedStartDate,
        endDate: formattedEndDate
      })
    ));
    fetchedData.estimatesCreated.convertedInvoiceTotal.map((convertedTotal, i) => {
      return values.push(renderValueLine(
        renderFormattedMessage(
          MSG_dashboardMetricEstimatesValueConverted,
          {amount: renderValue(formatCurrency(convertedTotal.value, null, null, convertedTotal.currency))}),
        formatMessage(MSG_dashboardMetricEstimatesValueConvertedExplanation, {
          amount: formatCurrency(convertedTotal.value, null, null, convertedTotal.currency),
          startDate: formattedStartDate,
          endDate: formattedEndDate
        })
      ));
    });
    values.push(renderValueLine(
      renderFormattedMessage(
        MSG_dashboardMetricEstimatesCurrentlyUnexpired,
        {num: renderValue(formatNumber(fetchedData.estimatesCreated.currentlyUnexpiredCount))}),
      formatMessage(MSG_dashboardMetricEstimatesCurrentlyUnexpiredExplanation, {
        num: formatNumber(fetchedData.estimatesCreated.currentlyUnexpiredCount),
        numCreated: formatNumber(fetchedData.estimatesCreated.totalCount),
        startDate: formattedStartDate,
        endDate: formattedEndDate
      })
    ));
    estimatesSection = renderValuesSection(
      "estimates",
      formatMessage(MSG_estimatesFeatureTitle),
      ORANGE_COLOR,
      faClipboardList,
      timeframeType === TIMEFRAME_DATA_TYPE.ESTIMATES_CREATED,
      () => setTimeframeType(TIMEFRAME_DATA_TYPE.ESTIMATES_CREATED),
      values,
    );

    // ***************************************************************
    // INVOICES SECTION
    // ***************************************************************
    values = [];
    values.push(renderValueLine(
      renderFormattedMessage(
        MSG_dashboardMetricInvoicesCreated,
        {num: renderValue(formatNumber(fetchedData.invoicesCreated.totalCount))}),
      formatMessage(MSG_dashboardMetricInvoicesCreatedExplanation, {
        num: formatNumber(fetchedData.invoicesCreated.totalCount),
        startDate: formattedStartDate,
        endDate: formattedEndDate
      })
    ));
    fetchedData.invoicesCreated.invoicedTotal.map((invoicedTotal, i) => {
      values.push(renderValueLine(
        renderFormattedMessage(
          MSG_dashboardMetricTotalInvoiced,
          {amount: renderValue(formatCurrency(invoicedTotal.value, null, null, invoicedTotal.currency))}),
        formatMessage(MSG_dashboardMetricTotalInvoicedExplanation, {
          amount: formatCurrency(invoicedTotal.value, null, null, invoicedTotal.currency),
          startDate: formattedStartDate,
          endDate: formattedEndDate
        })
      ));
    });
    fetchedData.invoicesCreated.paymentsTotal.map((paymentsTotal, i) => {
      values.push(renderValueLine(
        renderFormattedMessage(
          MSG_dashboardMetricPaymentsReceived,
          {amount: renderValue(formatCurrency(paymentsTotal.value, null, null, paymentsTotal.currency))}),
        formatMessage(MSG_dashboardMetricPaymentsReceivedExplanation, {
          amount: formatCurrency(paymentsTotal.value, null, null, paymentsTotal.currency),
          startDate: formattedStartDate,
          endDate: formattedEndDate
        })
      ));
    });
    fetchedData.invoicesCreated.currentlyDueTotal.map((currentlyDue, i) => {
      values.push(renderValueLine(
        renderFormattedMessage(
          MSG_dashboardMetricCurrentlyDue,
          {amount: renderValue(formatCurrency(currentlyDue.value, null, null, currentlyDue.currency))}),
        formatMessage(MSG_dashboardMetricCurrentlyDueExplanation, {
          amount: formatCurrency(currentlyDue.value, null, null, currentlyDue.currency),
          startDate: formattedStartDate,
          endDate: formattedEndDate
        })
      ));
    });
    values.push(renderValueLine(
      renderFormattedMessage(
        MSG_dashboardMetricInvoicesSynced,
        {num: renderValue(formatNumber(fetchedData.invoicesCreated.invoicesQuickbooksSyncedCount))}),
      formatMessage(MSG_dashboardMetricInvoicesSyncedExplanation, {
        num: formatNumber(fetchedData.invoicesCreated.invoicesQuickbooksSyncedCount),
        startDate: formattedStartDate,
        endDate: formattedEndDate
      })
    ));
    invoicesSection = renderValuesSection(
      "invoices",
      formatMessage(MSG_invoicesTitle),
      TEAL_COLOR,
      faFileInvoiceDollar,
      timeframeType === TIMEFRAME_DATA_TYPE.INVOICES_CREATED,
      () => setTimeframeType(TIMEFRAME_DATA_TYPE.INVOICES_CREATED),
      values,
    );

    if (!rootStore.user.canViewCompanyMetrics) {
      sections = [[estimatesSection, invoicesSection, appointmentsSection]];
    } else {
      sections = [[clientsSection, pianosSection, appointmentsSection], [remindersSection, estimatesSection, invoicesSection]];
    }
  }

  return {
    timeframe, setTimeframe,
    timeframeType, setTimeframeType,
    color,
    minDays,
    startOn, endOn, prevStartOn, prevEndOn,
    heatmapStore: heatmapStoreRef.current,
    summaryStore: summaryStoreRef.current,
    heatmapData,
    sections,
    refreshHistoricalData
  };
}
