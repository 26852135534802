import { makeObservable, observable } from 'mobx';

class ContactEmail {
  id: string = null;
  isDefault: boolean = null;
  email: string = null;

  constructor(attrs: any) {
    makeObservable(this, {
      id: observable,
      isDefault: observable,
      email: observable
    });

    this.id = attrs.id;
    this.isDefault = attrs.isDefault;
    this.email = attrs.email;
  }
}

const CONTACT_EMAIL_ALL_GQL_QUERY_FIELDS = `
  id isDefault
  email
`;

export { ContactEmail, CONTACT_EMAIL_ALL_GQL_QUERY_FIELDS };
