import { WEEKDAYS } from "../enums";

const moment = require('moment-timezone');

function getWeekdayLabel(wday: number) {
  return moment().weekday(wday).format('dddd');
}

function getWeekdayShortLabel(wday: number) {
  return moment().weekday(wday).format('ddd');
}

export const WeekdayTypeInfo: {[key: string]: {getLabel: () => string, getShortLabel: () => string, wday: number}} = {
  [WEEKDAYS.SUN]: {getLabel: () => getWeekdayLabel(0), getShortLabel: () => getWeekdayShortLabel(0), wday: 0},
  [WEEKDAYS.MON]: {getLabel: () => getWeekdayLabel(1), getShortLabel: () => getWeekdayShortLabel(1), wday: 1},
  [WEEKDAYS.TUE]: {getLabel: () => getWeekdayLabel(2), getShortLabel: () => getWeekdayShortLabel(2), wday: 2},
  [WEEKDAYS.WED]: {getLabel: () => getWeekdayLabel(3), getShortLabel: () => getWeekdayShortLabel(3), wday: 3},
  [WEEKDAYS.THU]: {getLabel: () => getWeekdayLabel(4), getShortLabel: () => getWeekdayShortLabel(4), wday: 4},
  [WEEKDAYS.FRI]: {getLabel: () => getWeekdayLabel(5), getShortLabel: () => getWeekdayShortLabel(5), wday: 5},
  [WEEKDAYS.SAT]: {getLabel: () => getWeekdayLabel(6), getShortLabel: () => getWeekdayShortLabel(6), wday: 6},
};

export const WeekdayNumberInfo: WEEKDAYS[] = [
  WEEKDAYS.SUN,
  WEEKDAYS.MON,
  WEEKDAYS.TUE,
  WEEKDAYS.WED,
  WEEKDAYS.THU,
  WEEKDAYS.FRI,
  WEEKDAYS.SAT,
];
