import { makeObservable, observable } from 'mobx';
import { Address } from './address';

class ContactAddress extends Address {
  id: string = null;
  type: string = null;

  constructor(attrs: any) {
    super(attrs);

    makeObservable(this, {
      id: observable,
      type: observable
    });

    this.id = attrs.id;
    this.type = attrs.type;
  }
}

const CONTACT_ADDRESS_ALL_GQL_QUERY_FIELDS = `
  id type
  address1 address2 city state zip
`;

export { ContactAddress, CONTACT_ADDRESS_ALL_GQL_QUERY_FIELDS };
