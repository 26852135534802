import { IDateFormat, IParsedTime, ITimeFormat } from "../interfaces";
import { Moment } from "moment-timezone";
import { formatMessage, getLocaleStoreInstance, toMomentLocale } from "./intl";
// load all locales for future compatibility when we add more locales
// so we don't have to do a mobile build.  We can just push new format
// strings from the server.
import 'moment/min/locales';
import {
  MSG_durationHoursLong,
  MSG_durationHoursShort,
  MSG_durationMinutesLong,
  MSG_durationMinutesShort,
  MSG_todayLabel,
  MSG_yesterdayLabel
} from "../strings";

const moment = require("moment-timezone");

// On page load, let's cache the local computer's timezone offset.
// We will use this later to determine whether or not to show a timezone
// when formatting time strings.
let localTimezoneName = moment.tz.guess();
function setLocalTimezoneName(str: string) {
  localTimezoneName = str;
  moment.tz.setDefault(str);
}

function convertNumberTimeToStringTime(time: number): string {
  let hours = Math.floor(time / 100);
  let minutes = time % 100;
  let minutesStr = minutes < 10 ? '0' + minutes : minutes;
  return `${hours}:${minutesStr}`;
}

function parseTime(time: string): IParsedTime {
  let parts = time.split(':');
  return {h: parseInt(parts[0]), m: parseInt(parts[1])};
}

// This takes a moment (that might be in some user timezone) and returns a Date object
// which will be in the browser's timezone.  But it keeps the date the same.  This is
// really only useful when passing to the date picker which requires a JavaScript date
// object, but our Timezones don't match up necessarily with the browser's timezones.
// So we hack it and pass it a Date object in the browser's time zone, but just set the
// date to the correct date.
function getMomentDateAsJavascriptDate(m: Moment) {
  if (!m) return null;
  return new Date(m.year(), m.month(), m.date());
}
function getJavascriptDateAsMomentDate(d: Date) {
  if (!d) return null;
  return moment().startOf('day').year(d.getFullYear()).month(d.getMonth()).date(d.getDate());
}

interface IFormatTimeOptions {
  hideMinutes?: boolean;
  hideAmPm?: boolean;
  timeFormatLocaleOverride?: string;
  timeFormatterOverride?: ITimeFormat;
}

function formatTime(m: Moment, options: IFormatTimeOptions = {}) {
  const locale = options.timeFormatLocaleOverride || getLocaleStoreInstance().localization.timeFormatLocale;
  const formatter = options.timeFormatterOverride || getLocaleStoreInstance().localization.timeFormat;
  const format = m.tz() !== localTimezoneName ? formatter.timezoneTime : formatter.time;
  let str = m.locale(toMomentLocale(locale)).format(format);

  if (options.hideMinutes) {
    str = str.replace(/:\d{2}/, '');
  }

  if (options.hideAmPm) {
    str = str.replace(/\s*[AP]M/i, '');
  }

  return str;
}

interface IFormatDateTimeOptions {
  weekday?: boolean;
  dateFormatterOverride?: IDateFormat;
  dateTimeSeparatorOverride?: string;
  timeFormatterOverride?: ITimeFormat;
  hideTimeIfMidnight?: boolean;
}

function formatDateTime(m: Moment, options: IFormatDateTimeOptions = {}) {
  if (options.hideTimeIfMidnight && m.hour() === 0 && m.minute() === 0 && m.second() === 0) {
    return formatDate(m, options);
  }

  const dateFormat = (options.dateFormatterOverride || getLocaleStoreInstance().localization.dateFormat)[options?.weekday ? 'weekdayDate' : 'date'];
  const separator = options.dateTimeSeparatorOverride || getLocaleStoreInstance().localization.dateTimeSeparator;
  const timeFormatter = options.timeFormatterOverride || getLocaleStoreInstance().localization.timeFormat;
  const timeFormat = m.tz() !== localTimezoneName ? timeFormatter.timezoneTime : timeFormatter.time;
  return `${m.format(dateFormat)}${separator}${m.format(timeFormat)}`;
}

interface IFormatDate {
  weekday?: boolean;
  relative?: boolean;
  dateFormatterOverride?: IDateFormat;
  dateFormatLocaleOverride?: string;
}

function formatDate(m: Moment, options: IFormatDate = {}) {
  const dateFormatter = options.dateFormatterOverride || getLocaleStoreInstance().localization.dateFormat;
  const dateFormatLocale = options.dateFormatLocaleOverride || getLocaleStoreInstance().localization.dateFormatLocale;

  let str;
  if (options.relative) {
    if (m.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')) {
      str = getLocaleStoreInstance().formatMessage(MSG_todayLabel);
    } else if (m.format('YYYY-MM-DD') === moment().subtract(1, 'day').format('YYYY-MM-DD')) {
      str = getLocaleStoreInstance().formatMessage(MSG_yesterdayLabel);
    } else {
      str = m.locale(toMomentLocale(dateFormatLocale)).fromNow();
    }
  } else if (options.weekday) {
    str = m.locale(toMomentLocale(dateFormatLocale)).format(dateFormatter.weekdayDate);
  } else {
    str = m.locale(toMomentLocale(dateFormatLocale)).format(dateFormatter.date);
  }
  return str;
}

/**
 * Turns an integer of hours/minutes into an abbreviated human-readable string.
 * Example:
 *   <GazelleDuration minutes={75}/>
 *   => "1h 15m"
 *
 * @param props.hours (optional) - You can specify the number of hours manually
 *    as an integer, or just use the props.minutes to specify the entire time
 *    span.
 * @param props.minutes (required) - The number of minutes.  This is usually
 *    the only property used, so it can be >60 like 125.
 */

function formatDuration(duration: number, options: {expanded?: boolean, onlyHours?: boolean, onlyMinutes?: boolean, locale?: string} = {}) {
  let minutes = duration % 60;
  let hours = Math.floor(duration / 60);

  if (hours && minutes && !options.onlyHours && !options.onlyMinutes) {
    if (options.expanded) {
      return `${formatMessage(MSG_durationHoursLong, {hours: hours}, options.locale)}, ${formatMessage(MSG_durationMinutesLong, {minutes: minutes}, options.locale)}`;
    } else {
      return `${formatMessage(MSG_durationHoursShort, {hours: hours}, options.locale)} ${formatMessage(MSG_durationMinutesShort, {minutes: minutes}, options.locale)}`;
    }
  } else if ((hours && !options.onlyMinutes) || options.onlyHours) {
    if (options.expanded) {
      return formatMessage(MSG_durationHoursLong, {hours: hours}, options.locale);
    } else {
      return formatMessage(MSG_durationHoursShort, {hours: hours}, options.locale);
    }
  } else {
    if (options.expanded) {
      return formatMessage(MSG_durationMinutesLong, {minutes: minutes}, options.locale);
    } else {
      return formatMessage(MSG_durationMinutesShort, {minutes: minutes}, options.locale);
    }
  }
}

export { convertNumberTimeToStringTime, parseTime, formatTime, formatDateTime, formatDate, formatDuration, setLocalTimezoneName, getMomentDateAsJavascriptDate, getJavascriptDateAsMomentDate };

