import { defineMessages, MessageDescriptor } from 'react-intl';
import * as moment from "moment-timezone";
import { CurrentLocalizationStore } from "../stores/current_localization_store";

let localeStoreInstance: CurrentLocalizationStore = null;
function getLocaleStoreInstance(): CurrentLocalizationStore { return localeStoreInstance; }
function registerLocaleStoreInstance(l: CurrentLocalizationStore) { localeStoreInstance = l; }

function formatMessage(messageDescriptor: MessageDescriptor, values?: {[key: string]: any} | string, locale?: string): string {
  if (typeof values === 'string') {
    locale = values;
    values = undefined;
  }

  if (locale) {
    return getLocaleStoreInstance().formatMessageInLocale(locale, messageDescriptor, values as any);
  } else {
    return getLocaleStoreInstance().formatMessage(messageDescriptor, values as any);
  }
}

// This function reformats en_US to en-us as moment requires.
const toMomentLocale = (locale: string) => {
  let newLocale = locale.replace('_', '-').toLowerCase();
  let tryLocales = [newLocale, newLocale.split('-')[0]];
  for (let i = 0; i < tryLocales.length; i++) {
    if (moment.locales().indexOf(tryLocales[i]) >= 0) return tryLocales[i];
  }
  return 'en-us';
};

function getFlagEmoji(locale: string) {
  let countryCode = locale.split("_").map(l => l.toLowerCase())[1];
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map(char =>  127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
}

export {
  defineMessages, formatMessage, getLocaleStoreInstance, registerLocaleStoreInstance,
  toMomentLocale, getFlagEmoji
};
