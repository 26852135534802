import * as React from 'react';
import { observer } from 'mobx-react';
import { Button } from 'reactstrap';
import { actionLogger, getUrlToken, Privacy } from "@core";
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft, faCaretRight } from "@fortawesome/pro-solid-svg-icons";
import {
  MSG_gdprAgree,
  MSG_gdprAgreementParagraph1,
  MSG_gdprAgreementParagraph2,
  MSG_gdprAgreementParagraph3,
  MSG_gdprDoNotAgree,
  MSG_privacyPolicyTitle
} from "@gazelle/shared/strings";
import { formatMessage } from "@gazelle/shared/utils";
import { rootStoreInstance } from "@client_scheduler/modules/root";

interface IGdprComplianceProps {
}

const GdprCompliance = observer(class GdprCompliance extends React.Component<IGdprComplianceProps> {
  componentDidMount() {
    actionLogger.debug('gdprCompliance:shown');
  }

  onContinue = () => {
    rootStoreInstance.isGdprConsentAgreed = true;
    actionLogger.debug('gdprCompliance:accepted');
  };

  onBack = () => {
    actionLogger.debug('gdprCompliance:declined');
    if (rootStoreInstance.companyWebsite) {
      location.href = rootStoreInstance.companyWebsite;
    } else {
      window.history.back();
    }
  };

  render() {
    const {companyName, companyEmail, companyPhoneNumber} = rootStoreInstance;

    return (
      <div className="gdpr-compliance-container mobile-content">
        <div className="gdpr-compliance">

          <Privacy forceHorizontal style={{marginBottom: '1rem'}} gdpr={true}/>

          <div className="content">
            <p>{formatMessage(MSG_gdprAgreementParagraph1, {
              name: companyName,
              phone: companyPhoneNumber
            })}</p>

            <p>{formatMessage(MSG_gdprAgreementParagraph2, {
              name: companyName,
              phone: companyPhoneNumber,
              email: companyEmail
            })}</p>

            <p>
              {/*
                NOTE: We are using the non-standard <FormattedMessage /> component here because
                this needs to contain embedded links, and formatMessage() does not let you do that.
               */}
              <FormattedMessage
                {...MSG_gdprAgreementParagraph3}
                values={{
                  privacyPolicy: (
                    <a target="_blank" href={`/legal/${getUrlToken()}/privacy_policy`}>
                      {formatMessage(MSG_privacyPolicyTitle)}
                    </a>
                  )
                }}
              />
            </p>

            <div className="text-center">
              <Button color="secondary" onClick={this.onBack} className="mt-3">
                <FontAwesomeIcon icon={faCaretLeft}/>{' '}
                {formatMessage(MSG_gdprDoNotAgree)}
              </Button>
              {' '}
              <Button color="primary" onClick={this.onContinue} className="mt-3">
                {formatMessage(MSG_gdprAgree)}
                {' '}<FontAwesomeIcon icon={faCaretRight}/>
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
});

export { GdprCompliance };
