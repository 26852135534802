import { computed, makeObservable, observable, ObservableMap } from 'mobx';
import { getLocaleStoreInstance } from "@gazelle/shared/utils";

class I18nString {
  values: ObservableMap<string> = null;
  facingType: 'user' | 'client' = null;

  constructor(attrs: {[key: string]: string} | I18nString = {}, facingType: 'user' | 'client' = 'client') {
    makeObservable(this, {
      values: observable,
      defaultValue: computed
    });

    this.values = new ObservableMap<string>();
    // NOTE: We don't really have user-facing I18nStrings yet.  This is just here as a placeholder
    // for when/if we eventually do that.  In that case, I18nString constructors will need to be
    // refactored to pass in 'user' for user-facing I18nStrings.  We may never need that.
    this.facingType = 'client';
    this.assign(attrs);
  }

  get defaultValue() {
    const getForSimilarLocale = (desiredLocale: string) => {
      if (!desiredLocale) return null;
      const desiredLanguage = desiredLocale.split(/_/)[0];
      const localeMatchesForLanguage = Array.from(this.values.keys()).filter(k => {
        let lang = k.split(/_/)[0];
        if (lang) return lang === desiredLanguage && this.values.get(k);
        return false;
      });
      if (localeMatchesForLanguage.length > 0) return this.get(localeMatchesForLanguage[0]);
      return null;
    };

    let locale = getLocaleStoreInstance().localization.locale;
    // If there is a string match for the current locale, return it.
    let str = this.get(locale);
    if (str) return str;

    // If there is a non empty string match for the same language as the current locale, return it.
    str = getForSimilarLocale(locale);
    if (str) return str;

    // Otherwise if there is a string match for the client's or user's locale, return it.
    locale = this.facingType === 'client' ? getLocaleStoreInstance().clientDefaultLocale : getLocaleStoreInstance().userDefaultLocale;
    str = this.get(locale);
    if (str) return str;

    // If there is a non empty string match for the same language as the client's or user's locale, return it.
    if (locale) {
      str = getForSimilarLocale(locale);
      if (str) return str;
    }

    return this.get('en_US');
  }

  set(locale: string, value: string) {
    this.values.set(locale, value);
  }

  get(locale: string) {
    return this.values.get(locale);
  }

  assign(attrs: {[key: string]: string} | I18nString = {}) {
    getLocaleStoreInstance().getSupportedLocaleInfoList().forEach(localeInfo => {
      let value;
      if (attrs instanceof I18nString) {
        value = (attrs as I18nString).get(localeInfo.locale);
      } else {
        value = attrs[localeInfo.locale];
      }

      if (value !== null) {
        this.values.set(localeInfo.locale, value);
      } else {
        this.values.delete(localeInfo.locale);
      }
    });
  }

  toObject() {
    let obj: {[key: string]: string} = {};
    this.values.forEach((val, key) => {
      if (val) {
        obj[key] = val;
      }
    });
    return obj;
  }
}

export { I18nString };
