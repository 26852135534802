import * as React from 'react';
import { PropsWithChildren } from 'react';
import { FormGroup } from 'reactstrap';
import { mutationErrorsFor, MutationFieldError } from '../graphql/mutation_errors';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";

interface IFormGroupWithErrorsProps extends PropsWithChildren {
  mutationErrors?: MutationFieldError[],
  mutationKey: string,
}

class FormGroupWithErrors extends React.Component<IFormGroupWithErrorsProps> {
  render() {
    let messages = mutationErrorsFor(this.props.mutationKey, this.props.mutationErrors);
    if (messages.length === 0) {
      return (
        <FormGroup>
          {this.props.children}
        </FormGroup>
      );
    } else {
      return (
        <FormGroup className="form-group-with-errors">
          {this.props.children}
          <div className="form-errors">
            {messages.map((msg, i) => (
              <div key={i} className="form-error-message">
                <FontAwesomeIcon icon={faExclamationTriangle}/>{' '}
                <span className="error-message">{msg}</span>
              </div>
            ))}
          </div>
        </FormGroup>
      );
    }
  }
}

export { FormGroupWithErrors };
