import * as React from 'react';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';

import { NavigationSteps } from './NavigationSteps';
import { StepSelector } from './StepSelector';
import { ErrorBoundary, MobileClientHeader } from '@core';
import { GdprCompliance } from "@client_scheduler/modules/root/components/GdprCompliance";
import {
  MSG_onlineSchedulingTitle,
  MSG_onlineSchedulingWithCompanyNameTitle,
  MSG_schedulerGenericError
} from "@gazelle/shared/strings";
import { formatMessage } from "@gazelle/shared/utils";
import { rootStoreInstance } from "@client_scheduler/modules/root";

interface IMobileLayoutProps {}

const MobileLayout = (observer(class MobileLayout extends React.Component<IMobileLayoutProps> {
  languageMenuOpen: boolean = null;

  constructor(props: IMobileLayoutProps) {
    super(props);

    makeObservable(this, {
      languageMenuOpen: observable
    });

    rootStoreInstance.isMobile = true;
  }

  render() {
    const {
      companyName, companyPhoneNumber, companyEmail,
      isGdprRequired, isGdprConsentAgreed,
      companyLogo: { exists: companyLogoExists, url: companyLogoUrl },
      companySettings: { headerLayout: companyHeaderLayout }
    } = rootStoreInstance;

    document.title = formatMessage(MSG_onlineSchedulingWithCompanyNameTitle, {companyName: companyName});

    return (
      <div className="mobile-container" id="main-scroll-container">
        <MobileClientHeader
          companyLogoExists={companyLogoExists}
          companyLogoUrl={companyLogoUrl}
          companyName={companyName}
          companyPhoneNumber={companyPhoneNumber}
          companyEmail={companyEmail}
          companyHeaderLayout={companyHeaderLayout}
          appName={formatMessage(MSG_onlineSchedulingTitle)}
          SidebarComponent={NavigationSteps}
        />

        <ErrorBoundary extraMessage={formatMessage(MSG_schedulerGenericError, {phone: companyPhoneNumber})}>
          {isGdprRequired && !isGdprConsentAgreed
            ? <GdprCompliance/>
            : <StepSelector/>}
        </ErrorBoundary>
      </div>
    );
  }
}));

export { MobileLayout };
