import * as React from 'react';
import Linkify from 'react-linkify';

interface ISimpleFormatProps {
  text?: string,
  message?: React.ReactElement | string
}

const componentDecorator = (href: string, text: string, key: string) => (
  <a href={href} key={key} target="_blank" rel="noopener noreferrer">
    {text}
  </a>
);

class SimpleFormat extends React.Component<ISimpleFormatProps> {
  render() {
    return (
      <div className="simple-format">
        <Linkify componentDecorator={componentDecorator}>
          {this.props.text &&
            this.props.text.split(/ *\n */).map((item, key) => (
              item.replace(/\s+/g, '') ? (
                <div key={key}>{item}</div>
              ) : (
                <br key={key}/>
              )
            ))}
          {this.props.message}
        </Linkify>
      </div>
    );
  }
}

export { SimpleFormat };
