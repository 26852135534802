import * as React from 'react';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { Button } from 'reactstrap';
import * as bowser from 'bowser';

import { CompanyNameHeader } from "@core/components/CompanyNameHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone, faPrint } from "@fortawesome/pro-solid-svg-icons";
import { Spinner } from "@core";
import { LanguageSelector } from "@core/components/LanguageSelector";
import { formatMessage } from "@gazelle/shared/utils";
import { MSG_printTitle } from "@gazelle/shared/strings";

interface IDesktopClientHeaderProps {
  appName: string;
  hasSidebar: boolean;
  companyLogoExists: boolean;
  companyLogoUrl: string;
  companyName: string;
  companyPhoneNumber: string;
  companyEmail: string;
  companyHeaderLayout?: string;
  rightComponent?: React.ReactElement;
  printable?: boolean;
  printableUrl?: string;
  hideLanguageSelector?: boolean;
}

const DesktopClientHeader = observer(
  class DesktopClientHeader extends React.Component<IDesktopClientHeaderProps> {
    printing: boolean = false;

    handlePrint = () => {
      if (this.props.printableUrl) {
        if (bowser.chrome || bowser.safari) {
          // Chrome and Safari let you print an iframe.  Firefox and IE/Edge do not.
          // In FF/IE/Edge, just load the PDF in a new  window.
          this.printing = true;
          let iframe = document.createElement('iframe');
          document.body.appendChild(iframe);
          iframe.style.display = 'none';
          iframe.src = this.props.printableUrl;
          iframe.onload = () => {
            setTimeout(() => {
              iframe.focus();
              iframe.contentWindow.print();
              this.printing = false;
            }, 1);
          };
        } else {
          window.open(this.props.printableUrl);
        }
      } else {
        window.print();
      }
    };

    constructor(props: IDesktopClientHeaderProps) {
      super(props);

      makeObservable(this, {
        printing: observable
      });
    }

    render() {
      const { hasSidebar, appName, companyLogoExists, companyLogoUrl, companyName,
        companyPhoneNumber, companyEmail, companyHeaderLayout } = this.props;

      return (
        <div className="desktop-header d-print-none">
          <div className="container">
            {hasSidebar &&
              <div className="sidebar-filler"/>}

            <CompanyNameHeader
              layout={companyHeaderLayout}
              appName={appName}
              companyName={companyName}
              logoExists={companyLogoExists}
              logoUrl={companyLogoUrl}/>

            <div className="contact-container">
              <div><FontAwesomeIcon icon={faPhone}/>&nbsp;{companyPhoneNumber}</div>
              <div><FontAwesomeIcon icon={faEnvelope}/>&nbsp;{companyEmail}</div>
            </div>

            {!this.props.hideLanguageSelector && <LanguageSelector/>}

            {this.props.printable && (
              <div className="print-button-container">
                <Button color="primary" outline onClick={this.handlePrint} disabled={this.printing}>
                  {this.printing
                    ? <Spinner/>
                    : <FontAwesomeIcon icon={faPrint}/>}
                  {' '}
                  {formatMessage(MSG_printTitle)}
                </Button>
              </div>
            )}

            {this.props.rightComponent}
          </div>
        </div>
      );
    }
  }
);

export { DesktopClientHeader };
