import { makeObservable, observable } from 'mobx';
import { Piano, PIANO_IDENTITY_GQL_QUERY_FIELDS } from '../models/piano';

class InvoiceItemTax {
  taxId: string = null;
  rate: number = null;
  name: string = null;
  total: number = null;

  constructor(attrs: any) {
    makeObservable(this, {
      taxId: observable,
      rate: observable,
      name: observable,
      total: observable
    });

    this.taxId = attrs.taxId;
    this.rate = attrs.rate;
    this.name = attrs.name;
    this.total = attrs.total;
  }
}

class InvoiceItem {
  id: string = null;
  type: string = null;
  description: string = null;
  amount: number = null;
  quantity: number = null;
  taxable: boolean = null;
  subTotal: number = null;
  taxTotal: number = null;
  total: number = null;
  sequenceNumber: number = null;
  taxes: InvoiceItemTax[] = null;
  piano: Piano = null;

  constructor(attrs: any) {
    makeObservable(this, {
      id: observable,
      type: observable,
      description: observable,
      amount: observable,
      quantity: observable,
      taxable: observable,
      subTotal: observable,
      taxTotal: observable,
      total: observable,
      sequenceNumber: observable,
      taxes: observable,
      piano: observable
    });

    this.id = attrs.id;
    this.type = attrs.type;
    this.description = attrs.description;
    this.amount = attrs.amount;
    this.quantity = attrs.quantity;
    this.taxable = attrs.taxable;
    this.subTotal = attrs.subTotal;
    this.taxTotal = attrs.taxTotal;
    this.total = attrs.total;
    this.sequenceNumber = attrs.sequenceNumber;
    this.taxes = [];
    if (attrs.allTaxes) {
      for (let tax of attrs.allTaxes) {
        this.taxes.push(new InvoiceItemTax(tax));
      }
    }
    if (attrs.piano) {
      this.piano = new Piano(attrs.piano);
    }
  }
}

const INVOICE_ITEM_ALL_GQL_QUERY_FIELDS = `
  id type description amount quantity taxable
  subTotal taxTotal total sequenceNumber
  allTaxes { taxId rate name total }
  piano { ${PIANO_IDENTITY_GQL_QUERY_FIELDS} }
`;

export { InvoiceItem, INVOICE_ITEM_ALL_GQL_QUERY_FIELDS };
