import * as React from 'react';
import * as moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { observer } from 'mobx-react';
import { Badge, Button, Card, CardBody, Col, Row } from 'reactstrap';

import { PianoWrapper } from '../../../models/piano_wrapper';
import { rootStoreInstance } from "../../root";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faCircle } from "@fortawesome/pro-solid-svg-icons";
import {
  MSG_durationAndCostShortLabel,
  MSG_durationWithValueLabel,
  MSG_editAndAddServicesTitle,
  MSG_editAndChangeServicesTitle,
  MSG_nextTuningDueColonLabel,
  MSG_noServicesSelectedLabel,
  MSG_removePianoTitle,
  MSG_servicesSummary,
  MSG_unknownLabel
} from "@gazelle/shared/strings";
import { formatCurrency, formatDuration, formatMessage } from "@gazelle/shared/utils";
import { GazelleDate } from "@gazelle/shared/components/GazelleDateTime";

interface IPianoListItemProps {
  pianoWrapper: PianoWrapper,
  onEdit: (pianoWrapper: PianoWrapper) => void,
  onRemove: (pianoWrapper: PianoWrapper) => void,
}

const PianoListItem = observer(class PianoListItem extends React.Component<IPianoListItemProps> {
  handleEdit = () => {
    this.props.onEdit(this.props.pianoWrapper);
  };

  handleRemove = () => {
    this.props.onRemove(this.props.pianoWrapper);
  };

  render() {
    const { pianoWrapper } = this.props;
    const showCosts = rootStoreInstance.companySettings.showCosts;

    let dueDate;
    if (pianoWrapper.piano.calculatedNextService) {
      if (pianoWrapper.piano.calculatedNextService.isAfter(moment().add(1, 'month'))) {
        dueDate = <GazelleDate moment={pianoWrapper.piano.calculatedNextService} />;
      } else {
        let color = "warning";
        if (pianoWrapper.piano.calculatedNextService.isBefore()) {
          color = "danger";
        }
        dueDate = (
          <Badge color={color}>
            <GazelleDate moment={pianoWrapper.piano.calculatedNextService} />
          </Badge>
        );
      }
    } else {
      dueDate = formatMessage(MSG_unknownLabel);
    }

    return (
      <Card className="mb-3 piano-list-item">
        <CardBody className={pianoWrapper.numServices < 1 ? 'text-muted' : ''}>
          <Row>
            <Col xs="auto" className="pe-0" onClick={this.handleEdit}>
              {pianoWrapper.numServices > 0
                ? <FontAwesomeIcon icon={faCheckCircle}/>
                : <span style={{opacity: 0.3}}><FontAwesomeIcon icon={faCircle}/></span>}
            </Col>
            <Col>

              <div className="fw-bold">{pianoWrapper.piano.name}</div>
              <div>
                {pianoWrapper.services.size > 0
                  ? (
                    <React.Fragment>
                      {pianoWrapper.defaultService.name.defaultValue}
                      {pianoWrapper.services.size - 1 > 0 && ' ' + formatMessage(MSG_servicesSummary, {numServices: pianoWrapper.services.size - 1})}
                    </React.Fragment>
                  ) : (
                    pianoWrapper.estimateItems.size > 0 ? null : formatMessage(MSG_noServicesSelectedLabel)
                  )}
              </div>
              {pianoWrapper.numServices > 0 &&
                <div>
                  {showCosts
                    ? formatMessage(MSG_durationAndCostShortLabel, {
                        duration: formatDuration(pianoWrapper.totalDuration),
                        cost: formatCurrency(pianoWrapper.totalAmount)
                      })
                    : formatMessage(MSG_durationWithValueLabel, {
                        duration: formatDuration(pianoWrapper.totalDuration),
                      })}
                </div>}

              <div>
                {/*
                  This is intentionally left as <FormattedMessage/> because dueDate can be a component
                  with some color for highlighting, and formatMessage() does not handle this.
                */}
                <FormattedMessage
                  {...MSG_nextTuningDueColonLabel}
                  values={{
                    date: dueDate
                  }} />
              </div>

            </Col>
            <Col xs={12} lg="auto" className="text-end">
              <div className="d-lg-none"><br /></div>
              {pianoWrapper.numServices > 0
                ? <Button color="primary" size="sm" onClick={this.handleEdit}>
                    {formatMessage(MSG_editAndChangeServicesTitle)}
                  </Button>
                : <Button color="primary" size="sm" onClick={this.handleEdit}>
                    {formatMessage(MSG_editAndAddServicesTitle)}
                  </Button>}
              <br/>
              {!pianoWrapper.piano.id && pianoWrapper.services.size === 0 &&
                <Button size="sm" color="link" onClick={this.handleRemove}>
                  {formatMessage(MSG_removePianoTitle)}
                </Button>}
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
});

export { PianoListItem };
