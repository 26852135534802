import { INVOICE_STATUS } from "../enums";
import { formatMessage } from "./intl";
import {
  MSG_invoiceStatusCanceled,
  MSG_invoiceStatusDeleted,
  MSG_invoiceStatusDraft,
  MSG_invoiceStatusOpen,
  MSG_invoiceStatusOverdue,
  MSG_invoiceStatusPaid
} from "../strings";

// Use this if you want to iterate over all the status (e.g. for a <select>)
const SortedInvoiceStatuses = [
  INVOICE_STATUS.DRAFT,
  INVOICE_STATUS.OPEN,
  INVOICE_STATUS.OVERDUE,
  INVOICE_STATUS.PAID,
  INVOICE_STATUS.DELETED,
  INVOICE_STATUS.CANCELED,
];

const InvoiceStatusInfo: {[key: string]: {type: INVOICE_STATUS, getLabel: (locale?: string) => string}} = {
  [INVOICE_STATUS.DRAFT]: {
    type: INVOICE_STATUS.DRAFT,
    getLabel: (locale?: string) => formatMessage(MSG_invoiceStatusDraft, {}, locale),
  },
  [INVOICE_STATUS.OPEN]: {
    type: INVOICE_STATUS.OPEN,
    getLabel: (locale?: string) => formatMessage(MSG_invoiceStatusOpen, {}, locale),
  },
  [INVOICE_STATUS.OVERDUE]: {
    type: INVOICE_STATUS.OVERDUE,
    getLabel: (locale?: string) => formatMessage(MSG_invoiceStatusOverdue, {}, locale),
  },
  [INVOICE_STATUS.PAID]: {
    type: INVOICE_STATUS.PAID,
    getLabel: (locale?: string) => formatMessage(MSG_invoiceStatusPaid, {}, locale),
  },
  [INVOICE_STATUS.DELETED]: {
    type: INVOICE_STATUS.DELETED,
    getLabel: (locale?: string) => formatMessage(MSG_invoiceStatusDeleted, {}, locale),
  },
  [INVOICE_STATUS.CANCELED]: {
    type: INVOICE_STATUS.CANCELED,
    getLabel: (locale?: string) => formatMessage(MSG_invoiceStatusCanceled, {}, locale),
  },
};

export { SortedInvoiceStatuses, InvoiceStatusInfo };
