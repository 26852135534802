import {
  FilterType,
  IFilterConfigProps,
  IFilterHandler,
  IFilterLabelProps,
  IFilterTypeConfig,
  ISelectFilter
} from "./types";
import { faList } from "@fortawesome/pro-solid-svg-icons";

export const GenericSelectFilterHandler: IFilterHandler = {
  type: FilterType.SELECT,

  getIcon: () => faList,

  getQueryVariables: (filter: ISelectFilter, config: IFilterTypeConfig) => {
    if (config.getQueryVariables) {
      return config.getQueryVariables(filter);
    } else {
      if (filter.value && filter.value.length > 0) {
        return {[config.queryArgumentKey]: filter.value};
      } else {
        return {};
      }
    }
  },

  isValid: (filter: ISelectFilter) => {
    return true;
  },

  defaultValue: (config: IFilterTypeConfig) => {
    let defaultValue = [];
    if (typeof config.defaultValue !== 'undefined') {
      defaultValue = config.defaultValue;
    }
    return {
      key: config.key,
      type: FilterType.SELECT,
      value: defaultValue
    };
  },

  LabelComponent: (props: IFilterLabelProps) => {
    throw "Expected SelectFilterHandler to implement LabelConfig.";
  },

  ConfigComponent: (props: IFilterConfigProps) => {
    throw "Expected SelectFilterHandler to implement ComponentConfig.";
  }
};
