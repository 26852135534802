import { formatMessage } from "./intl";
import { CALENDAR_TITLE_MODE } from "../enums";
import {
  MSG_calendarTitleTypeCity,
  MSG_calendarTitleTypeName,
  MSG_calendarTitleTypeNameCity,
  MSG_calendarTitleTypeNamePostalCode,
  MSG_calendarTitleTypePianos,
  MSG_calendarTitleTypePostalCode,
  MSG_calendarTitleTypeTitle,
  MSG_calendarTitleTypeTitleCity,
  MSG_calendarTitleTypeTitlePostalCode
} from "../strings";

const SortedCalendarTitleModes = [
  CALENDAR_TITLE_MODE.TITLE,
  CALENDAR_TITLE_MODE.TITLE_CITY,
  CALENDAR_TITLE_MODE.TITLE_POSTAL_CODE,
  CALENDAR_TITLE_MODE.NAME,
  CALENDAR_TITLE_MODE.NAME_POSTAL_CODE,
  CALENDAR_TITLE_MODE.NAME_CITY,
  CALENDAR_TITLE_MODE.POSTAL_CODE,
  CALENDAR_TITLE_MODE.CITY,
  CALENDAR_TITLE_MODE.PIANOS,
];

const CalendarTitleModeInfo: {[key: string]: {type: CALENDAR_TITLE_MODE, getLabel: () => string}} = {
  [CALENDAR_TITLE_MODE.TITLE]: {type: CALENDAR_TITLE_MODE.TITLE, getLabel: () => formatMessage(MSG_calendarTitleTypeTitle)},
  [CALENDAR_TITLE_MODE.TITLE_CITY]: {type: CALENDAR_TITLE_MODE.TITLE_CITY, getLabel: () => formatMessage(MSG_calendarTitleTypeTitleCity)},
  [CALENDAR_TITLE_MODE.TITLE_POSTAL_CODE]: {type: CALENDAR_TITLE_MODE.TITLE_POSTAL_CODE, getLabel: () => formatMessage(MSG_calendarTitleTypeTitlePostalCode)},
  [CALENDAR_TITLE_MODE.NAME]: {type: CALENDAR_TITLE_MODE.NAME, getLabel: () => formatMessage(MSG_calendarTitleTypeName)},
  [CALENDAR_TITLE_MODE.NAME_POSTAL_CODE]: {type: CALENDAR_TITLE_MODE.NAME_POSTAL_CODE, getLabel: () => formatMessage(MSG_calendarTitleTypeNamePostalCode)},
  [CALENDAR_TITLE_MODE.NAME_CITY]: {type: CALENDAR_TITLE_MODE.NAME_CITY, getLabel: () => formatMessage(MSG_calendarTitleTypeNameCity)},
  [CALENDAR_TITLE_MODE.POSTAL_CODE]: {type: CALENDAR_TITLE_MODE.POSTAL_CODE, getLabel: () => formatMessage(MSG_calendarTitleTypePostalCode)},
  [CALENDAR_TITLE_MODE.CITY]: {type: CALENDAR_TITLE_MODE.CITY, getLabel: () => formatMessage(MSG_calendarTitleTypeCity)},
  [CALENDAR_TITLE_MODE.PIANOS]: {type: CALENDAR_TITLE_MODE.PIANOS, getLabel: () => formatMessage(MSG_calendarTitleTypePianos)},
};

export { CalendarTitleModeInfo, SortedCalendarTitleModes };
