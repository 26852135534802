import * as React from 'react';
import { EstimateTierItem } from "@gazelle/shared/models";
import { PianoWrapper } from "@client_scheduler/models/piano_wrapper";
import { EstimateTierItemRow } from "@client_scheduler/modules/step2_pianos/components/EstimateTierItemRow";

interface IProps {
  groupName: any;
  items: EstimateTierItem[];
  isFirstGroup: boolean;
  pianoWrapper: PianoWrapper;
}

interface IState {
}

class EstimateTierGroupRow extends React.PureComponent<IProps, IState> {
  render() {
    return (
      <div className="mt-2 mb-2">
        {this.props.groupName && <div className="fw-bold">{this.props.groupName}</div>}
        <div className="ps-4">
          {this.props.items.map(item => (
            <EstimateTierItemRow key={item.id} item={item} pianoWrapper={this.props.pianoWrapper}/>
          ))}
        </div>
      </div>
    );
  }
}

export { EstimateTierGroupRow };
