import * as bowser from 'bowser';
import * as md5 from 'md5';
import { graphqlRequest } from '@core';

class LoggedActionContext {
  clientId?: string;
  eventId?: string;
  invoiceId?: string;
}

let context: {
  appName: string;
  urlToken: string;
  browserInfo: string;
  fingerprint: string;
  clientId: string;
  eventId: string;
  invoiceId: string;
} = {
  appName: null,
  urlToken: null,
  browserInfo: [bowser.name, bowser.version, bowser.osname, bowser.osversion].join(', '),
  fingerprint: md5('' + Math.random()),
  clientId: null,
  eventId: null,
  invoiceId: null,
};

const windowAny: any = window;
if (windowAny.Raven) {
  windowAny.Raven.setUserContext({fingerprint: context.fingerprint});
}

const actionLogger = {
  debug: function logAction(key: string, data: {[key: string]: any} = null) {
    console.log('Logging[debug]:', key);
    try {
      void graphqlRequest(`
        mutation ($timestamp: Int!, $key: String!, $appName: String!, $urlToken: String!, $fingerprint: String!, $browserInfo: String!, $clientId: String, $eventId: String, $invoiceId: String, $data: String) {
          logAction(timestamp: $timestamp, key: $key, appName: $appName, urlToken: $urlToken, fingerprint: $fingerprint, browserInfo: $browserInfo, clientId: $clientId, eventId: $eventId, invoiceId: $invoiceId, data: $data) {
            isLogged
          }
        }`, {
          timestamp: new Date().getTime(),
          key: key,
          appName: context.appName,
          urlToken: context.urlToken,
          fingerprint: context.fingerprint,
          browserInfo: context.browserInfo,
          clientId: context.clientId,
          eventId: context.eventId,
          invoiceId: context.invoiceId,
          data: data ? JSON.stringify(data) : null,
        });
    } catch (e) {
      console.log('Could not log:', key);
    }
  },
};

function setLoggedActionContext(appName: string, urlToken: string, ctx: LoggedActionContext) {
  context.appName = appName;
  context.urlToken = urlToken;
  if (ctx.clientId) context.clientId = ctx.clientId;
  if (ctx.eventId) context.eventId = ctx.eventId;
  if (ctx.invoiceId) context.invoiceId = ctx.invoiceId;
}

export { actionLogger, setLoggedActionContext };
