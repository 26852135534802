/**
 * This is a place for shared functions that are browser-only.  This is for functions that would
 * throw errors if tried to load in mobile.  It's pulled out separately from utils so it can
 * be excluded from mobile.
 */

import * as Cookies from 'js-cookie';

function getBrowserLanguageCode(): string {
  let cookieLocale = Cookies.get('locale');
  if (cookieLocale) return cookieLocale;

  if (navigator && navigator.language && (typeof navigator.language === 'string')) {
    return navigator.language.replace("-", "_");
  }
  return null;
}

/**
 * This will take a partial url (like "www.google.com") and turn it into a full url with
 * protocol like ("https://www.google.com").  It does not assume https, but rather assumes
 * http on the assumption that not every piano tuner website will have https yet, and those
 * that require it will probably have forwarding.  This is the safest assumption for now
 * until https truly is everywhere.
 *
 * @param str
 */
function getFullUrl(str: string) {
  if (!str) return ;

  if (str.match(/^https?:\/\//i)) return str;

  // In our user testing, this was a typo I saw more than once, using one slash instead of two.
  if (str.match(/^https?:\/\w/i)) return str.replace(/:\//, '://');

  return "http://" + str;
}

export { getFullUrl, getBrowserLanguageCode };
