export const MSG_mutationFailureMessage = {
  id: "67071245-076a-471f-a288-45767eb742fd",
  description: "A generic error message.",
  defaultMessage: "The requested action could not be completed.",
};

export const MSG_errorRetrievingData = {
  id: "e9cabcda-700f-4f34-b405-c7be01eed208",
  description: "An error message that is displayed when something goes wrong retrieving information from a server.",
  defaultMessage: "Error retrieving data",
};

export const MSG_errorDeletingData = {
  id: "f0037692-8b3a-4808-9fca-f9bdc916cd1c",
  description: "An message that is displayed when an error occurs while deleting something from a server.",
  defaultMessage: "Error deleting data",
};

export const MSG_errorOccurredWhileSaving = {
  id: "1e055c00-3fbe-49fe-a6e2-ecf5e874603b",
  description: "A title that is displayed above an error message when the user saves.",
  defaultMessage: "An error occurred while saving.",
};

export const MSG_errorOccurredWhileSending = {
  id: "23027d3b-02c0-4c2b-b061-3d2316899c06",
  description: "A title that is displayed above an error message when something goes wrong sending an message.",
  defaultMessage: "An error occurred while sending.",
};

export const MSG_errorOccurredWhileProcessing = {
  id: "4a36d91b-796f-4a61-9448-209142ad00fb",
  description: "A generic message that is displayed when something went wrong during an operation with the server.",
  defaultMessage: "An error occurred while processing.",
};

export const MSG_unexpectedError = {
  id: "219cc67e-e0e1-4f0d-affc-44b1b5e7dd2f",
  defaultMessage: "An unexpected error occurred.",
};

export const MSG_accessDeniedTitle = {
  id: "2d1214c4-f856-4873-a2c6-0a796b4d48e5",
  description: "This is the title displayed when the user is denied access to a feature",
  defaultMessage: "Access Denied"
};

export const MSG_accessDeniedMessage = {
  id: "2799308f-c2ba-4000-a8f8-f70f83ae8cf3",
  description: "This is the explanation displayed when the user is denied access.",
  defaultMessage: "Your current permissions do not permit access to this feature.  Please contact your company administrator if you have questions."
};

export const MSG_noMobilePhone = {
  id: "673d65a0-f216-4cb4-8957-0574438dc9f0",
  description: "An error message displayed in situations where a client's mobile phone number is expected but is unavailable.",
  defaultMessage: "No confirmed mobile phone number detected.",
};

export const MSG_noDefaultPhoneNumber = {
  id: "29e346e3-6304-4914-8420-4af84acf8b97",
  description: "An error message displayed in situations where a client's default phone number is expected but is unavailable.",
  defaultMessage: "No default phone number found for the client.",
};

export const MSG_noDefaultEmail = {
  id: "05abc8f2-3144-4d84-979d-a15b8073072a",
  description: "An error message displayed in situations where a client's default email address is expected but is unavailable.",
  defaultMessage: "No default email address found for the client.",
};

export const MSG_companyNotFoundTitle = {
  id: "295a7741-0cc4-452e-9151-6839cd0b1e38",
  description: "A heading for the box containing the error saying that the company could not be found.",
  defaultMessage: "Company Not Found",
};

export const MSG_companyNotFoundMessage = {
  id: "497f99c6-6b1f-4846-a17a-f569c3ff0d15",
  description: "A message for when an invalid URL was given and the company could not be found.",
  defaultMessage: "We could not find the piano service company you were looking for.  Please go back and make sure you copied the link correctly.",
};

export const MSG_scheduledMaintenanceTitle = {
  id: "f17bdef6-2b37-4052-9ed2-fbe2a50c1886",
  description: "A heading to indicate that the website is temporarily down for scheduled maintenance.",
  defaultMessage: "Scheduled Maintenance",
};

export const MSG_scheduledMaintenanceMessage = {
  id: "8c1646d6-7c4b-40eb-9b19-b124de58673f",
  description: "A message to show when the website is temporarily down for scheduled maintenance.  This is shown when we need to take the site down to perform upgrades or other maintenance-related reasons.  We usually have the site back up very quickly.",
  defaultMessage: "We are currently performing scheduled maintenance on this website.  Please check back in a few minutes.  We apologize for the inconvenience.",
};

export const MSG_temporarySystemErrorTitle = {
  id: "87ccf98f-1cfb-48ff-bf52-db3a7da67538",
  description: "A heading for the box containing the error saying that the server is not responding.",
  defaultMessage: "Temporary System Error",
};

export const MSG_temporarySystemErrorMessage = {
  id: "cd91d2bb-0e32-4565-b540-b079527a5937",
  description: "A message for when the server is not responding.",
  defaultMessage: "There seems to be a temporary system outage.  Please check back later and we should have everything fixed up.  If not, please contact your technician and let them know about the problem.",
};

export const MSG_unexpectedErrorTitle = {
  id: "e34430f0-ea82-4ef8-a14d-d434b565797b",
  description: "A heading for the box cointaining an unexpected and unhandled error.",
  defaultMessage: "Unexpected Error",
};

export const MSG_unexpectedErrorMessage = {
  id: "e285c922-e63f-4a4d-b161-7f82abd7da6b",
  description: "A generic error message when an unexpected and unhandled error occurs.",
  defaultMessage: "An unexpected error has occurred.  Details: {errorMessage}",
};

export const MSG_maintenanceTitle = {
  id: "b2b9438a-bd33-44aa-8f35-4ad46d531c87",
  description: "This is the title displayed when Gazelle's servers are under temporary maintenance.",
  defaultMessage: "Temporary Maintenance Mode"
};

export const MSG_maintenanceMessage = {
  id: "2b42f7bc-3482-4bff-aea7-f239c19c6a0a",
  description: "This is a notice displayed when Gazelle's servers are down for temporary maintenance.",
  defaultMessage: "Our servers are temporarily under maintenance.  Please try again in a few minutes."
};

export const MSG_serverErrorTitle = {
  id: "054a43f5-abf4-4cf4-af32-72986c4e4d06",
  description: "This is the title for an error displayed when an unexpected server error occurred.",
  defaultMessage: "Server Error"
};

export const MSG_serverErrorMessage = {
  id: "ed5600d0-ee44-4a23-a106-5ee2472d3ab9",
  description: "This is the explanation displayed when an unexpected server error occurred.",
  defaultMessage: "An error occurred on the server.  Gazelle has been notified of the error.  If it persists, please contact us for assistance."
};

export const MSG_notFoundMessage = {
  id: "f21f3bfd-e29e-4722-8b68-76a17cb079ae",
  description: "This is the explanation displayed when a record was requested but could not be found",
  defaultMessage: "The data you requested could not be found."
};

export const MSG_networkErrorTitle = {
  id: "603792a0-007e-4b30-9eab-e902821c12a0",
  description: "This is the title displayed when there was a network connection problem.",
  defaultMessage: "Network Error"
};

export const MSG_networkErrorMessage = {
  id: "6e4c575b-b6aa-470f-b002-10d44cee21b3",
  description: "This is the explanation displayed when there was a problem connecting to our servers.",
  defaultMessage: "There was a problem connecting to the Gazelle servers.  Try again in a few minutes."
};

export const MSG_unknownErrorTitle = {
  id: "98d512bb-441d-4d0d-8f9e-ab3b395e58e4",
  description: "This is the title displayed when an unknown and unexpected error occurred",
  defaultMessage: "Unknown Error"
};

export const MSG_unknownErrorMessage = {
  id: "92cfdc34-667a-4c76-bcf5-fb31c1d1f073",
  description: "This is the explanation displayed when an unknown and unexpected error occurred",
  defaultMessage: "An unknown and unexpected error occurred.  Please contact us if the error persists."
};

export const MSG_genericOperationFailureMessage = {
  id: "e0774413-7414-462b-bf98-fe2f8ef6b11c",
  defaultMessage: "Please try your operation again.  If the error persists, please contact {email} and provide the text of this error message, along with a description of what you were trying to do.  Thanks!"
};

export const MSG_formSaveError = {
  id: "457a77bf-4eb6-497a-b9ea-57bdb0f54de3",
  defaultMessage: "An unexpected error occurred while saving this event.  We have been notified of the error and will be looking into it.",
};

export const MSG_errorsTryingToSave = {
  id: "7cf9a0c7-fdf6-4db0-bbd7-90cd0e79554e",
  defaultMessage: "{numErrors, plural, one {There was an error while trying to save.} other {There were some errors while trying to save.}}",
};

export const MSG_firstErrorMessageWithMore = {
  id: "9157a589-598c-4fa5-a395-923152d50e80",
  description: "This shows the first error message, and a count of how many other errors occurred.",
  defaultMessage: "{numOtherMessages, plural, one {and 1 more error} other {and # more errors}}"
};

export const MSG_errorLaunchingPhoneTitle = {
  id: '80782473-f4a9-40a7-bb38-a247d0ecf240',
  description: 'The title of the error when the phone app could not be launched for some reason.',
  defaultMessage: 'Error Launching Phone'
};

export const MSG_errorLaunchingPhoneMessage = {
  id: '93fcaa67-fe8f-45eb-9603-3412b0ef73d6',
  description: 'The error message when the phone app could not be launched',
  defaultMessage: 'There was a problem launching the phone, or the number {phoneNumber} was not recognized by your device.  The error message from your device was: {errorMessage}.'
};

export const MSG_textingErrorTitle = {
  id: '42e841e4-d29f-4f79-b23e-f941faf4b2bb',
  description: 'The title of the error when the texting (sms) app could not be launched for some reason.',
  defaultMessage: 'Texting Error'
};

export const MSG_textingErrorMessage = {
  id: '78d2592c-06fe-4a23-bed4-cef8dc4d91c0',
  description: 'The error message when the texting (sms) app could not be launched',
  defaultMessage: 'There was a problem opening your texting app, or {phoneNumber} was not recognized by your device.  The error message from your device was: {errorMessage}.'
};

export const MSG_emailErrorTitle = {
  id: '9eac6e47-8193-4eb1-825f-b4bea40d9f72',
  description: 'The title of the error when the email app could not be launched.',
  defaultMessage: 'Email Error'
};

export const MSG_emailErrorMessage = {
  id: 'e049bcd4-ffb2-4404-b42b-87b9789d98c5',
  description: 'The error message when the email app could not be launched',
  defaultMessage: 'There was a problem opening your email app, or {emailAddress} was not recognized by your device as an email address.  The error message from your device was: {errorMessage}.'
};
