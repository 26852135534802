export const MSG_monthTitle = {
  id: 'b4aa0ed5-34b1-459b-96c3-a4da814d01fd',
  description: 'The label for the button that switches to the Month view on the calendar.',
  defaultMessage: 'Month',
};

export const MSG_weekTitle = {
  id: 'f5d4a5c5-3247-4bdd-b7ae-60ff94bdfa14',
  description: 'The label for the button that switches to the Week view on the calendar',
  defaultMessage: 'Week',
};

export const MSG_dayTitle = {
  id: '9dbb28f6-8fef-434e-892b-0ea278ebf320',
  description: 'The label for the button that switches to the Day view on the calendar',
  defaultMessage: 'Day',
};

export const MSG_fourDaysTitle = {
  id: '53e43b9e-3063-43c6-a534-82287b76bc6c',
  description: 'The label for the button that switches to the 4-Day view on the calendar',
  defaultMessage: '4 Days',
};

export const MSG_todayLabel = {
  id: "8ef2b36d-9c4d-4ed8-bddb-7d4917bbfb6f",
  description: "The label for the button that jumps to 'today' on the calendar",
  defaultMessage: "Today"
};

export const MSG_tomorrowLabel = {
  id: "372f2bb9-7a13-4a3f-a947-393eb5ec4cf9",
  description: "The label for the button that jumps to 'tomorrow' on the calendar",
  defaultMessage: "Tomorrow"
};

export const MSG_yesterdayLabel = {
  id: "d171c3c1-1fc3-4ff8-8794-54c313723eb1",
  description: "The label for the button that jumps to 'yesterday' on the calendar",
  defaultMessage: "Yesterday"
};

export const MSG_completeTitle = {
  id: '6a5c843f-2bb4-49f0-b125-abc64fe2a94e',
  description: 'The word "complete", used as an action label when they can mark an appointment as complete (or, finished).',
  defaultMessage: 'Complete',
};

export const MSG_uncompleteTitle = {
  id: '7088f4d5-18f2-4b2d-95af-caf1f3938126',
  description: 'The word "uncomplete", used as an action label when they can undo having marked an appointment as complete (or, finished).',
  defaultMessage: 'Uncomplete',
};

export const MSG_markAsUncompletedQuestion = {
  id: "422fd400-4f6f-4a22-a95c-25fb7165189a",
  description: "",
  defaultMessage: "Mark this appointment as uncompleted?"
};

export const MSG_completeAppointmentTitle = {
  id: "520d8126-43fe-4deb-bbf3-cad62448ef9c",
  description: "This is used as an action label when they can mark an appointment as complete (or, finished).",
  defaultMessage: "Complete Appointment",
};

export const MSG_uncompleteAppointmentTitle = {
  id: "dae276ff-f137-4b38-9fe2-4cf63915ee56",
  description: "This is used as an action label when they can undo making an appointment complete (e.g. uncomplete the appointment, or marking it as not having been completed).",
  defaultMessage: "Uncomplete Appointment",
};

export const MSG_confirmTitle = {
  id: "fb0ce27d-4ea4-4b2a-a024-ade0b118a8fb",
  description: "The label for the button that confirms that all their information is correct, and confirms the appointment.",
  defaultMessage: "Confirm",
};

export const MSG_confirmEventAndSendEmailLabel = {
  id: "52fd4040-c98d-49be-b704-7fdddb1eb356",
  description: "This is the label for the button that lets them mark an event as 'confirmed' and sends an email to the client.  That is, the client has confirmed that they are available on that date.",
  defaultMessage: "Confirm (send email)",
};

export const MSG_unconfirmTitle = {
  id: "44c57ce3-ba6f-4086-9ef3-07e33a83de16",
  description: "This is the label for the button that undoes the confirmation action, essentially marking the event as not confirmed again.  This is in title case.",
  defaultMessage: "Unconfirm",
};

export const MSG_unconfirmLabel = {
  id: "2272a44e-b8cc-495a-826d-74790dfb9d08",
  description: "This is the label for the checkbox that undoes the confirmation action, essentially marking the event as not confirmed again.  This is in label case.",
  defaultMessage: "Unconfirm",
};

export const MSG_unconfirmExplanation = {
  id: "b442f3f0-d78a-4c80-9502-f2df71246ac6",
  description: "An explanation of what the Unconfirm button does.",
  defaultMessage: "This will reset the confirmation state of an event that has previously been confirmed.  The client will not be notified, and the event will go back to its previous unconfirmed status.",
};

export const MSG_confirmedOnDateByClient = {
  id: "465b55d7-93e8-4597-a9a4-030e335b9159",
  defaultMessage: "This appointment was confirmed on {date} at {time} by the client.",
};

export const MSG_manuallyConfirmedOnDate = {
  id: "76ec6b28-8194-4112-ba6d-bf32ee08abc6",
  defaultMessage: "This appointment was manually confirmed on {date} at {time}.",
};

export const MSG_confirmEventAndSendEmailExplanation = {
  id: "d75ce8f7-fe9a-48a3-931d-ffae86f75c4b",
  description: "",
  defaultMessage: "This will mark the appointment as confirmed and send an email to the client letting them know it has been confirmed.",
};

export const MSG_confirmEventNoEmailLabel = {
  id: "5b8c44e3-60da-457c-9239-db9e629a32b7",
  description: "This is the label for the button that lets them mark an event as 'confirmed', but it does not send an email.",
  defaultMessage: "Confirm (no email)",
};

export const MSG_confirmEventNoEmailExplanation = {
  id: "0fa4ced6-8d97-42fb-9ec5-b65e9589e03c",
  description: "",
  defaultMessage: "This will mark the appointment as confirmed, but will NOT send an email to the client.",
};

export const MSG_confirmAppointmentTitle = {
  id: "d804bbf3-b1da-46a5-b95d-19d94057231b",
  description: "A button that lets them confirm that an appointment is still happening",
  defaultMessage: "Confirm Appointment"
};

export const MSG_unconfirmAppointmentTitle = {
  id: "4d834936-ef45-4ba6-bdfb-4e700c09055e",
  description: "A button that lets them unconfirm that an appointment that was previously confirmed.  Unconfirmed means that the appointment has not been confirmed by the client",
  defaultMessage: "Unconfirm Appointment"
};

export const MSG_appointmentTitle = {
  id: "f7aa0eb7-6a17-4316-86a5-c8d45d0ded4c",
  description: "The type of calendar event called an 'appointment' (i.e. an event scheduled with a client)",
  defaultMessage: "Appointment"
};

export const MSG_newAppointmentTitle = {
  id: "4a24fd20-c4ba-4fce-9b6c-43d649225a65",
  description: "A label for a menu item for generating a new appointment.",
  defaultMessage: "New Appointment",
};

export const MSG_sharingRequestSent = {
  id: "c2d67e0f-a2bd-40a2-b009-cb89cd8d689a",
  defaultMessage: "Your request to a share calendar has been sent."
};

export const MSG_requestAccessExplanation = {
  id: "c47a6a06-de44-4990-a33e-7cfb408b4ae1",
  defaultMessage: "To request access to another Gazelle user's calendar, fill out this information and we will send an email to them requesting that they grant you access."
};

export const MSG_selectAtLeastOneTypeOfEvent = {
  id: "cf90b37e-e60d-4c58-8662-239abe17352d",
  description: "",
  defaultMessage: "Please select at least one type of event to export."
};

export const MSG_shareCalendarTitle = {
  id: "6a26cef2-73ac-46b8-9dc4-d9c54057ee1f",
  defaultMessage: "Share Calendar",
};

export const MSG_calendarSharingForTitle = {
  id: "8bee2d7b-77c9-4c35-92b6-3002d3f90e19",
  description: "",
  defaultMessage: "Gazelle Calendar Sharing For:",
};

export const MSG_sharingYourGazelleCalendarTitle = {
  id: "59499e58-63c5-489d-bc5a-15028595e256",
  description: "",
  defaultMessage: "Sharing Your Gazelle Calendar",
};

export const MSG_enableSharingTitle = {
  id: "c7835614-791f-4a74-87fe-ffdb9592baa8",
  description: "The button that lets them enable sharing of their calendar.",
  defaultMessage: "Enable Sharing",
};

export const MSG_sharingEnabled = {
  id: "55221268-5bef-448d-9cbd-49e2ba292cf1",
  description: "The button that lets them disable sharing of their calendar.",
  defaultMessage: "Sharing: Enabled",
};

export const MSG_calendarNotShared = {
  id: "bf73f59b-6f96-4dbd-baab-25e671e435f0",
  description: "",
  defaultMessage: "This calendar has not been shared with any other Gazelle users.",
};

export const MSG_changePrivacySettingsTitle = {
  id: "3702c1f3-47d2-45d1-b9ac-e381a8e820cc",
  defaultMessage: "Change Privacy Settings",
};

export const MSG_changeSharedEventTypesTitle = {
  id: "5efcd0d3-e1ff-478f-b742-5b6d3e986842",
  defaultMessage: "Change Shared Event Types",
};

export const MSG_directCalendarIntegrationTitle = {
  id: "33223662-c062-466d-9d49-1f7c48ba00a3",
  defaultMessage: "Direct Calendar Integration"
};

export const MSG_directCalendarIntegrationExplanation = {
  id: "5240a467-5720-47aa-9bba-7601f4122be4",
  defaultMessage: "You are currently logged in as {userName} with {companyName}.  To link your calendar from another Gazelle account, please provide the email and password for that other account."
};

export const MSG_gazelleCalendarIntegrationHelpText = {
  id: "70812ed1-3b5d-4b75-bb62-af04c6f246ac",
  defaultMessage: "This lets you pull in events from another Gazelle calendar.  Which method would you like to use to set up this sharing link?",
};

export const MSG_sharedCalendarEventTypeSelectionLabel = {
  id: "9035d161-dd19-4e54-a44b-8d0fc98850f9",
  defaultMessage: "Which types of events would you like to include when sharing your calendar with {userName} at {companyName}?"
};

export const MSG_sharedCalendarEventTypeSelectionEmailLabel = {
  id: "b4867dda-db34-4e66-b2f0-f2f30cb67b5e",
  defaultMessage: "Which types of events would you like to include when sharing your calendar with {emailAddress}?"
};

export const MSG_iWantAccessToAnotherUsersCalendar = {
  id: "2bc43f35-4e4e-43c2-9c9d-9f0b0a400325",
  defaultMessage: "e.g. I collaborate with a Gazelle user from another company and need to see their availability on my calendar."
};

export const MSG_sharingWithOtherGazelleUsersTitle = {
  id: "c48c6512-1199-4925-b3a1-9b951119c002",
  defaultMessage: "Sharing With Other Gazelle Users"
};

export const MSG_sharingWithUserAtCompany = {
  id: "51249759-574a-45a6-8be4-9d6742637f5b",
  description: "This will read like: Sharing with John Doe at Pianos-R-Us",
  defaultMessage: "Sharing with {name} at {company}"
};

export const MSG_userIsSharingWithUserAtCompany = {
  id: "ee4fd371-4d87-44a4-89e2-a0c1adee807b",
  description: "This will read like: John Doe is sharing with Jane Doe at Pianos-R-Us",
  defaultMessage: "{personOne} is sharing with {personTwo} at {company}"
};

export const MSG_shareWithAnotherGazelleUserTitle = {
  id: "cbd2b74e-af03-47c3-be14-f06d1c60a146",
  description: "This is the label on a button.  Our styling capitalizes words on buttons so please capitalize each word appropriately.",
  defaultMessage: "Share With Another Gazelle User",
};

export const MSG_userShareWithAnotherGazelleUser = {
  id: "dbdb3253-967a-408e-bdc4-bb4795bef714",
  description: "This is the label on a button.  Our styling capitalizes words on buttons so please capitalize each word appropriately.",
  defaultMessage: "Share This Calendar ({name}) With Other Gazelle Users",
};

export const MSG_directlyShareGazelleCalendarHelpText = {
  id: "8bb59f38-75cf-4d83-8292-57c9e5e25db9",
  defaultMessage: "You can directly share your Gazelle calendar with other Gazelle users.  Let us know their email address and we will send them an invitation to view your calendar.",
};

export const MSG_directlyShareUsersGazelleCalendarHelpText = {
  id: "59a13503-40b9-4fbd-88eb-e5642940a2e3",
  defaultMessage: "You can directly share the calendar belonging to {name} with other Gazelle users.  Let us know their email address and we will send them an invitation to view your calendar.",
};

export const MSG_disableSharedCalendarConfirmLine1 = {
  id: "5394c615-e215-420f-b0bc-4e3cb85470d9",
  defaultMessage: "WARNING: This will delete your shared calendar connection with {userName} at {companyName}.  They will no longer be able to see events from your calendar."
};

export const MSG_disableSharedCalendarEmailConfirmLine1 = {
  id: "66995bfa-cbfb-441f-b0cd-04aaf6a502b5",
  defaultMessage: "WARNING: This will delete your shared calendar invitation with {emailAddress}.  They will not be able to accept the invitation."
};

export const MSG_disableSharedCalendarConfirmLine2 = {
  id: "295cf352-30cb-4ee4-8e47-45a46d0cf37d",
  defaultMessage: "Are you sure you want to delete this shared calendar?"
};

export const MSG_deleteRepeatingEventConfirmation = {
  id: "94468075-a1c7-434f-a043-77864d204311",
  defaultMessage: "Are you sure you want to delete this event and all future events in this sequence?",
};

export const MSG_deleteRepeatingEventFutureStatement = {
  id: "baf0bd71-f58b-4340-97f2-670298bb309d",
  defaultMessage: "This will also delete all future events in this repeating sequence."
};

export const MSG_deleteRepeatingEventOneStatement = {
  id: "88a1ea22-8720-4936-b84f-e0d8f2bcfbc5",
  defaultMessage: "This will only delete this one event in this repeating sequence."
};

export const MSG_deleteEventConfirmation = {
  id: "09f87999-192c-4a3f-b755-61e692e23cb0",
  defaultMessage: "Are you sure you want to delete this event?",
};

export const MSG_cancelEventConfirmation = {
  id: "dc555e6e-c989-4bf1-aa52-bdec18e34359",
  defaultMessage: "Are you sure you want to cancel this appointment?",
};

export const MSG_howMuchInformationToShare = {
  id: "21085c44-aa6a-4e1b-8459-136d5e89d25c",
  defaultMessage: "How much information would you like to share with {userName} at {companyName} when sharing your calendar events?",
};

export const MSG_howMuchInformationToShareEmail = {
  id: "bd3a6354-1ee7-4096-aa70-3e716dfd8117",
  defaultMessage: "How much information would you like to share with {emailAddress} when sharing your calendar events?",
};

export const MSG_sharedCalendarPrivacyShareNothing = {
  id: "e0cb57aa-4189-4763-b3cc-ec14434b0548",
  defaultMessage: "Only show the date and time.  Label all events as just 'Busy'."
};

export const MSG_sharedCalendarPrivacyShareTitleAndNotes = {
  id: "4322147e-ae40-442b-a9ff-aea6e852d1a5",
  defaultMessage: "Show the date and time along with the event title and notes.  Do not share any additional contact or piano information."
};

export const MSG_sharedCalendarPrivacyShareEverything = {
  id: "5e746fbf-4c9e-45da-b454-088555d28042",
  defaultMessage: "Show the date and time, the event title and notes, and also share client contact information and details about the pianos to be serviced."
};

export const MSG_icsExportLabel = {
  id: "9c3c82d9-fc76-4e0e-b1f1-8d91d71018f2",
  description: "ICS is a file format for calendar exporting.  This is a label on the section that lets them configure their export settings.",
  defaultMessage: "ICS calendar export"
};

export const MSG_icsExportTitle = {
  id: "1c959963-bbd9-4d66-90cd-3fae1d08fbe1",
  description: "ICS is a file format for calendar exporting.  This is a label on the section that lets them configure their export settings.",
  defaultMessage: "ICS Calendar Export"
};

export const MSG_exportEventDetails = {
  id: "a2dde48f-7418-4400-af91-93e3a5ebad3b",
  defaultMessage: "Would you like to show the details of your events, including all notes, client, and piano details, etc.?"
};

export const MSG_exportEventDetailsYesLabel = {
  id: "2641466a-5c20-49f7-a157-c10be2ef10ee",
  defaultMessage: "Yes; export all details"
};

export const MSG_exportEventDetailsNoLabel = {
  id: "650ceefd-d715-4e13-b1a9-73bfeab5e057",
  defaultMessage: "No; only show the word 'Busy' for all events"
};

export const MSG_privacyShowDetails = {
  id: "84b04423-92a1-4563-99e8-703c16798f57",
  description: "This is a label for a radio button where they modify the privacy settings on their calendar.",
  defaultMessage: "Show the details for these events on my Gazelle calendar.",
};

export const MSG_privacyHideDetails = {
  id: "54479ac6-ef78-470e-9f8c-7f2ab5dc380b",
  description: "This is a label for a radio button where they modify the privacy settings on their calendar.",
  defaultMessage: "Hide the details for these events on my Gazelle calendar.  The word 'Busy' will be used for the title of all events.",
};

export const MSG_eventTypeSelection = {
  id: "982d106e-6c9c-4f5d-850f-084d9b1fb880",
  defaultMessage: "Which types of events would you like to include when exporting your calendar?"
};

export const MSG_eventTypes = {
  id: "17986eda-b33d-464f-9e4d-34f357805f40",
  description: "Shown before we list the types of events that can be shared.",
  defaultMessage: "Event Types:"
};

export const MSG_eventTypesNoColonTitle = {
  id: "4fd448b8-6864-4578-b8b7-16cf5bbcd1dc",
  description: "A title for a modal dialog box that pops up where they can modify the event type being shared.",
  defaultMessage: "Event Types"
};

export const MSG_calendarSettingsTitle = {
  id: "961de93f-5864-461b-8c28-550533136939",
  defaultMessage: "Calendar Settings",
};

export const MSG_removingCalendarLabel = {
  id: "c7ee9e6d-3a7c-4c15-9969-5b3433830f3e",
  description: "A status message that is showing while an integration with a third party is being removed.",
  defaultMessage: "Removing calendar...",
};

export const MSG_calendarBackgroundProcessingQueue = {
  id: "45170f43-4ab7-4549-9701-afe149419fa2",
  defaultMessage: "Your calendar has been added to the queue for syncing and will be synced in the next few minutes."
};

export const MSG_remoteCalendarLabel = {
  id: "36aec869-4ebb-4d83-8d9d-ae1a96445f6e",
  description: "A label to show that an event on their calendar came from syncing with a remote calendar.",
  defaultMessage: "Synced From: {calendarName}"
};

export const MSG_editCalendarDetailsTitle = {
  id: "d62021e1-60bb-469b-9962-101acc514319",
  defaultMessage: "Edit Calendar Details",
};

export const MSG_calendarUrlLabel = {
  id: "1c1490c0-9adf-42b1-9446-082ae133f39b",
  defaultMessage: "Calendar URL",
};

export const MSG_icsCalendarUrlHelp = {
  id: "c9dbc661-a3fa-4758-a8da-7e3b7aadc00a",
  defaultMessage: "This should be the URL to the .ics calendar you wish to integrate into Gazelle."
};

export const MSG_syncTheseCalendarsTitle = {
  id: "f52ca3c0-9406-4dc8-b6e3-733752d0add9",
  description: "Sync is a shortened term for synchronize.",
  defaultMessage: "Sync These Calendars:",
};

export const MSG_syncTheseCalendarsLabel = {
  id: "c71fb9fb-db79-4b63-b8ed-863b340fc720",
  description: "Sync is a shortened term for synchronize.",
  defaultMessage: "Sync these calendars:",
};

export const MSG_integrationDeleteConfirmation = {
  id: "f2a7d9f4-eeb7-4165-ad6f-78dd3bfecaa1",
  defaultMessage: "This will permanently delete the integration and its synced events on your Gazelle calendar.",
};

export const MSG_calendarNameNote = {
  id: "19314f7b-6114-42ea-b0bb-acc97c2f7228",
  defaultMessage: 'This calendar is known as "{name}" on your Google Calendar.',
};

export const MSG_shouldSyncedEventsBlock = {
  id: "d341524d-6f1c-492f-9b6e-9648307a64c6",
  defaultMessage: "Should events from this calendar affect your scheduling availability?",
};

export const MSG_impactsAvailabilityYes = {
  id: "86bef6ea-f4f2-4e1f-a802-e91b70211293",
  defaultMessage: "Yes; do not let clients schedule appointments over events from this calendar.",
};

export const MSG_impactsAvailabilityNo = {
  id: "82855c43-f1ad-4e77-acc5-72052984f950",
  defaultMessage: "No; show the events on my calendar but allow clients to still schedule appointments during these events.",
};

export const MSG_calendarIntegrationsGetStarted = {
  id: "927bb66c-1e66-4a17-8216-834917d8ccea",
  defaultMessage: "Get started by choosing an external calendar provider and configure the integration."
};

export const MSG_selectACalendarProviderTitle = {
  id: "8935c9ce-c51c-4bb6-810f-f926386be661",
  defaultMessage: "Select A Calendar Provider"
};

export const MSG_fromALinkLabel = {
  id: "2c9e3552-f488-4602-a6a0-702f1f476506",
  description: "This is a label for a radio button where they can choose to import an external ICS calendar from a URL.",
  defaultMessage: "From a link (e.g. Apple Calendar, or any ICS calendar link)",
};

export const MSG_anotherGazelleCalendarLabel = {
  id: "6a5955ca-171f-443a-a31f-57bc08c63054",
  description: "A calendar belonging to a different Gazelle user.",
  defaultMessage: "Another Gazelle user's calendar"
};

export const MSG_calendarDisplayNameLabel = {
  id: "bdc95982-31f3-400c-9d25-6ac4f1efa6ce",
  defaultMessage: "Calendar display name",
};

export const MSG_eventHasBeenDeleted = {
  id: "dec40140-c73c-4aad-a381-69d0ef752c33",
  defaultMessage: "This event has been deleted."
};

export const MSG_showUsersTitle = {
  id: "87b6718e-365d-433f-87f8-39a40629caae",
  description: "The label for the button that lets them select which users are visible on the calendar",
  defaultMessage: "Show Users"
};

export const MSG_sharingTitle = {
  id: "c2093033-747d-44e8-a553-7e9118bc59a5",
  description: "The label for the button that lets them edit their preferences on how data is shared",
  defaultMessage: "Sharing"
};

export const MSG_shareYourCalendarsTitle = {
  id: "9d5830ca-eb3e-4f6e-8183-9c50d4e39ae5",
  description: "The is the header for the section there they can share their Gazelle calendars with other applications.",
  defaultMessage: "Share Your Calendars"
};

export const MSG_disableSharingTitle = {
  id: "5479bee0-58ae-48ba-a561-8360069dc7ed",
  description: "The label for a button that lets them turn off sharing of their calendar",
  defaultMessage: "Disable Sharing"
};

export const MSG_calendarExportLinkExplanation = {
  id: "f286cd62-390a-4f30-b202-2088f95c96d6",
  defaultMessage: "Push the events and details of your Gazelle calendar to any third-party calendar that supports the iCal format (such as Google Calendar, your iPhone/iPad/Mac, and Outlook). Changes to your Gazelle calendar will be updated automatically and available offline via your outside calendar.  Creating a subscribed calendar allows you to view (but not edit) all your Gazelle events in a 3rd party app."
};

export const MSG_calendarExportLinkWarning = {
  id: "60dbaecd-de63-4adf-b3d1-71bee012e1c7",
  defaultMessage: "SECURITY NOTICE: Only people (or programs) with whom you share this link will be able see your calendar. If your link is compromised you can turn it off and back on to generate a new link. However, you will need to update all your 3rd party calendars with the new link."
};

export const MSG_calendarSharingLinkExplanation = {
  id: "5ec81a1e-6cb0-45ce-98e5-0b5c1bb9bd84",
  defaultMessage: "Connect your Gazelle calendar with other Gazelle users, subcontract your services to outside companies, and easily manage your availability across multiple Gazelle accounts."
};

export const MSG_calendarSharingLinkWarning = {
  id: "a7f2381b-844c-428f-9a2a-5d5f6de406ef",
  defaultMessage: "SECURITY NOTICE: You can enable privacy to only show the details of your events as “Busy” and disable this link at any time."
};

export const MSG_toggleCalendarSharingWarning = {
  id: "36a56ec2-0656-4438-b41d-e4700ecaf569",
  defaultMessage: "WARNING: This will delete your shared calendar link and will cause any third-party calendars you have configured to stop working.  If you re-enable later, you will get a different link and need to reconfigure your 3rd-party calendars.  Are you sure you want to disable this shared calendar?"
};

export const MSG_eventTitleDisplayLabel = {
  id: "4978afad-1b36-483e-a11a-b1c7033d2ac4",
  description: "The label for the calendar setting that lets them configure what is displayed for appointments on the calendar.  Normally just the appointment title is shown, but they can configure it to show other things (like the client name, city, etc).",
  defaultMessage: "Event title display"
};

export const MSG_xsmallLabel = {
  id: "33a29c10-ddc3-4475-b37f-96704f67dc2d",
  description: "The label for an extra small font size option",
  defaultMessage: "Extra small",
};

export const MSG_smallLabel = {
  id: "61706709-30b6-41d3-84f0-1f78667487dc",
  description: "The label for a small font size option",
  defaultMessage: "Small",
};

export const MSG_normalLabel = {
  id: "3714ed01-319b-4375-8a6c-16ff58654335",
  description: "The label for a normal font size option",
  defaultMessage: "Normal",
};

export const MSG_largeLabel = {
  id: "0a61651d-2831-4569-bcd0-7c4f2b1a536e",
  description: "The label for a large font size option.",
  defaultMessage: "Large",
};

export const MSG_xlargeLabel = {
  id: "f4fa7656-f8e2-4dbe-b4ae-f8295162da49",
  description: "The label for an extra-large font size option.",
  defaultMessage: "Extra large",
};

export const MSG_calendarSettingAvailabilityLabel = {
  id: "42264ce2-7c95-40ce-b45d-12f2226e3b84",
  description: "A checkbox of whether or not to show a user's scheduling availability on the calendar",
  defaultMessage: "Show availability on calendar background",
};

export const MSG_calendarSettingAvailabilityExplanation = {
  id: "06b88ceb-a8df-440d-8392-b63c49d87c00",
  description: "The {schedulingRulesLink} has the text 'Scheduling Rules' (translated elsewhere).  It links to their scheduling rules.  Treat it as the text 'Scheduling Rules'.",
  defaultMessage: "A user's scheduling rules will appear on their calendar as a shaded background color. Note: this will only display when viewing a single user's calendar and not when overlaying multiple calendars.",
};

export const MSG_calendarSettingConfirmationsLabel = {
  id: "76ffebb8-cae3-4089-a02c-448f8a1864a5",
  description: "A checkbox of whether or not to show a notice for upcoming appointments that have not yet been confirmed by the client.",
  defaultMessage: "Show confirmation warnings",
};

export const MSG_calendarSettingConfirmationsExplanation = {
  id: "169664c4-725c-452c-b9d4-16517d3657fb",
  defaultMessage: "If you have appointment confirmations enabled, this will display a notice on the calendar if an appointment has not yet been confirmed.",
};

export const MSG_calendarSettingShowNonschedulableUsersLabel = {
  id: "4966d38e-815a-45a6-b905-32829e2b550d",
  description: "A checkbox of whether or not to show users who cannot be scheduled on the calendar.",
  defaultMessage: "Show non-schedulable users",
};

export const MSG_calendarSettingShowNonschedulableUsersExplanation = {
  id: "3c1684de-6768-4da4-aec8-60fc8a22dce8",
  defaultMessage: "If this is checked then all users will be shown on the calendar.  If this is unchecked then only users who can be scheduled for appointments will be visible.  This means assistants and any technicians who are not schedulable will be hidden.",
};

export const MSG_calendarSettingPromptToScheduleAfterCompletion = {
  id: "a6a5fe38-04ce-47ff-ae76-35aa2489bba3",
  description: "A checkbox that lets them decide if they should receive a prompt to schedule the next appointment after they mark a past appointment as compelted.",
  defaultMessage: "Ask to schedule after completing an appointment"
};

export const MSG_calendarSettingPromptToScheduleAfterCompletionExplanation = {
  id: "7d03fc0e-f081-4c08-a783-2412a265c210",
  description: "",
  defaultMessage: "If this is checked, Gazelle will ask you if you want to schedule the next appointment every time you mark a past appointment as completed."
};

export const MSG_calendarSettingMakeCompletedAndPastEventsDimmer = {
  id: "92da6920-2c40-442d-8930-cfd08b29475d",
  description: "A checkbox that lets them decide if past and completed appointments should be dimmed on the calendar.",
  defaultMessage: "Make past and completed events dimmer"
};

export const MSG_calendarSettingMakeCompletedAndPastEventsDimmerExplanation = {
  id: "88141333-d16f-476c-974e-d387583fa133",
  description: "",
  defaultMessage: "If this is checked, both past and completed appointments will be dimmed on the calendar."
};

export const MSG_calendarSettingSendConfirmationLabel = {
  id: "76a06e2a-1ad6-4af8-803a-127941578770",
  description: "A checkbox of whether or not to send by default a confirmation message to clients when an appointment is scheduled.",
  defaultMessage: "Send appointment confirmation by default",
};

export const MSG_calendarSettingSendConfirmationExplanation = {
  id: "5aa81d3c-9f0d-4671-9795-3e24297bcda8",
  defaultMessage: "If this is checked then by default, a one-off message will be sent to your client when scheduling a new appointment.  This can be overridden when creating each appointment, but this checkbox sets the default setting.",
};

export const MSG_dayViewTitle = {
  id: "562b32ef-c03f-4ca5-9c1b-6bd9feb5b30b",
  description: "The 'Day' view of the calendar where events are shown in hourly blocks of time",
  defaultMessage: "Day"
};

export const MSG_itineraryViewTitle = {
  id: "c9c0941f-af3c-463e-b0f8-be0cc2e22c26",
  description: "The 'itinerary' view of the calendar where events are listed",
  defaultMessage: "Itinerary"
};

export const MSG_mapViewTitle = {
  id: "a58db1c3-c2d1-42a7-b969-53d346dc9cb7",
  description: "The 'map' view of the calendar where the appointments for a day are shown on a map with a driving route drawn between them.",
  defaultMessage: "Map"
};

export const MSG_deleteAppointmentTitle = {
  id: "7e93078c-694f-4146-9337-3714cfdb5c5c",
  defaultMessage: "Delete Appointment",
};

export const MSG_permanentlyDeleteAppointmentQuestion = {
  id: "e2db6fbd-acef-429a-8478-e9d45cfa9368",
  defaultMessage: "Permanently delete the appointment?",
};

export const MSG_deletePersonalEventTitle = {
  id: "e8c7433d-6207-4cb3-bbfd-07d369a7555b",
  defaultMessage: "Delete Personal Event",
};

export const MSG_permanentlyDeletePersonalEventQuestion = {
  id: "be75edb0-d1fe-4c1d-b545-bbfcf55bfde5",
  defaultMessage: "Permanently delete the personal event?",
};

export const MSG_deleteMemoTitle = {
  id: "e2bf0578-e246-49cb-9f7c-bed3e43a2e72",
  defaultMessage: "Delete Memo",
};

export const MSG_cancelAppointmentTitle = {
  id: "a8f0afd4-5919-48ed-b6ee-93784acaf4a8",
  defaultMessage: "Cancel Appointment",
};

export const MSG_uncancelTitle = {
  id: "0e98143b-9336-4bcd-8f13-30733f3af385",
  defaultMessage: "Uncancel",
};

export const MSG_uncancelAppointmentTitle = {
  id: "69404f78-132c-411b-af7a-b329661e47e9",
  defaultMessage: "Uncancel Appointment",
};

export const MSG_permanentlyDeleteMemoQuestion = {
  id: "0e1258b7-b4a6-4121-ab32-3a345ccca05c",
  defaultMessage: "Permanently delete the memo?",
};

export const MSG_repeatingDeleteFutureLabel = {
  id: "f9bcba40-32e4-4b87-b734-536d545221f5",
  defaultMessage: "Remove this and all future events in the sequence",
};

export const MSG_repeatingDeleteCurrentLabel = {
  id: "51ab2624-33d2-4705-999d-43a2d4809aef",
  defaultMessage: "Remove only this event (do not remove anything else in the sequence)",
};

export const MSG_newEventTypeTitle = {
  id: "1bd5bb5b-8a10-48c0-9ff3-ce8ee20a3dbf",
  description: "This is the title for the screen that lets them create a new event.  There are several different event types, so this could read like 'New Appointment' or 'New Personal Event', etc.",
  defaultMessage: "New {eventType}",
};

export const MSG_editEventTypeTitle = {
  id: "5808289e-8df7-466a-9de5-c5066f00dde0",
  description: "This is the title for the screen that lets them edit an event.  There are several different event types, so this could read like 'Edit Appointment' or 'Edit Personal Event', etc.",
  defaultMessage: "Edit {eventType}",
};

export const MSG_untitledEvent = {
  id: "75c9d4aa-c287-4c5f-a830-9cd44650e1af",
  defaultMessage: "Untitled Event",
};

export const MSG_unconfirmedExplanation = {
  id: "89913360-d2fb-48f8-8222-56420a4d2b44",
  defaultMessage: 'This client is on the "{name}" Reminder which has confirmations enabled.  This means Gazelle will try to contact the client to confirm the date and time of this appointment.',
};

export const MSG_unconfirmedExplanationFuture = {
  id: "e470d7ce-4952-4df6-b3ff-ffef8ad5e9b7",
  defaultMessage: "When their appointment gets closer, Gazelle will automatically send them a reminder to confirm.",
};

export const MSG_unconfirmedExplanationNear = {
  id: "f1b1f163-18f9-45f7-9d4b-7ff9b02abda9",
  description: "A message to indicate that the client has not yet responded to our confirmation requests.",
  defaultMessage: "So far this client has not yet responded.",
};

export const MSG_thisIsATuningLabel = {
  id: "1a660749-740c-43e5-b527-1f1a5931400f",
  description: "This is the label for a checkbox when they add a piano record to an appointment event.  This checkbox indicates that this piano is going to be tuned (as opposed to any other type of service call).  A tuning appointment is treated specially in Gazelle.",
  defaultMessage: "This is a tuning for this piano",
};

export const MSG_thisIsATuningExplanation = {
  id: "56cb4a6a-5551-48ab-9f5a-7d7f4bfe8b98",
  defaultMessage: "Appointments in Gazelle are tied to piano records.  In order to determine the reminder schedule, you need to indicate if this appointment would be considered a tuning for this piano.  If so, when this appointment is complete, the reminder schedule for this piano will be reset automatically."
};

export const MSG_partOfRepeatingEvent = {
  id: "9ac29d12-80f6-4ead-ba9b-e8c0bdd822c1",
  defaultMessage: "This is part of a repeating event. What do you want to change?",
};

export const MSG_repeatingChangeOnlyThis = {
  id: "d3e6bec4-8554-4cef-8ac1-41cfa1ceb241",
  defaultMessage: "Only change this event (do not change anything else in the sequence)",
};

export const MSG_repeatingChangeFuture = {
  id: "4900c85c-1b66-473c-9d43-65b9292303d7",
  defaultMessage: "Change this and all future events in the sequence",
};

export const MSG_repeatsLabel = {
  id: "85e085e0-edda-45e7-b69c-ed5f12fbf59a",
  description: "The label for the checkbox when an event repeats.",
  defaultMessage: "Repeats",
};

export const MSG_onlyNewEventsCanRepeat = {
  id: "f343909a-c753-4993-9b70-48f1c0400eb1",
  defaultMessage: "Only new events can be made repeating.  This cannot be changed once an event is created.  To create a repeating event, remove this one and create a new one that is marked as repeating.",
};

export const MSG_repeatEveryLabel = {
  id: "650b2815-d07b-4e25-a9b1-aa2f392b4a3b",
  description: "Used in the phrase 'Every X months'.",
  defaultMessage: "Every",
};

export const MSG_numMonthsSuffix = {
  id: "ec8f9f41-12fc-4f5b-9eca-63a2b24d4f93",
  description: "Used in the phrase 'Every X months'.",
  defaultMessage: "{numMonths, plural, one {month} other {months}}",
};

export const MSG_numMonthsWithNumberLabel = {
  id: '6f2d84b1-7367-4437-8a70-032a9da840c5',
  description: 'A pluralized version of the word "month", with the number itself.',
  defaultMessage: '{numMonths, plural, one {1 month} other {# months}}',
};

export const MSG_numWeeksSuffix = {
  id: "cea579a2-b228-4038-90c5-2df20bc7ce98",
  description: "Used in the phrase 'Every X weeks'.",
  defaultMessage: "{numWeeks, plural, one {week} other {weeks}}",
};

export const MSG_lastDayExplanation = {
  id: "08f91234-11c0-4fa9-bf12-4b3c5289ca31",
  defaultMessage: "If you choose 29, 30, or 31, it will be interpreted as 'last day of the month' for months that do not have that date.",
};

export const MSG_weekOrdinals = {
  id: "8815cbe0-73d8-4584-b471-6cb187a2ceae",
  description: "The ordinals when the repeating week in a month.  For example 1st week of the month, 2nd week of the month, etc.  This will only ever be numbers 1 to 4 or -1.  We also need to include 'Last' in here, so set -1 (or other) to be the translation as Last, meaning the last week of the month.",
  defaultMessage: "{weekNum, plural, =1 {1st} =2 {2nd} =3 {3rd} =4 {4th} =5 {5th} =-1 {Last} =-2 {2nd to last} =-3 {3rd to last} =-4 {4th to last} other {#th}}",
};

export const MSG_onTheseWeekdaysWithColonLabel = {
  id: "2f088a1c-fe6c-48a2-9a03-6b8c06c689c3",
  defaultMessage: "On these weekdays:",
};

export const MSG_endingWithColonLabel = {
  id: "3902f657-1d84-43e5-84e8-0c186f43589c",
  description: "The label for the last date in a repeating sequence of events.  For example:  'Ending: Aug 1, 2019'",
  defaultMessage: "Ending:",
};

export const MSG_repeatAfterLabel = {
  id: "44d5ff2f-e028-4bfa-9756-3d838147aba3",
  description: "Part of the phrase: 'After X occurrences', where X is a text input box.",
  defaultMessage: "After",
};

export const MSG_repeatOccurrencesSuffix = {
  id: "dffa5967-4cbc-42ae-98eb-31be871a2a54",
  description: "Part of the phrase: 'After X occurrences', where X is a text input box.",
  defaultMessage: "occurrences",
};

export const MSG_onDateLabel = {
  id: "3a5a49a7-b503-4089-a28d-0812d712e97d",
  description: "Part of the phrase: 'On X', where X is date input field.",
  defaultMessage: "On",
};

export const MSG_frequencyLabel = {
  id: "6c5f02f7-9e40-4204-9447-28e751a64168",
  description: "The label for where they choose how often an event repeats",
  defaultMessage: "Frequency"
};

export const MSG_eventUserTitle = {
  id: "bf6503e2-67f4-4c60-be11-6756efa05be0",
  description: "The label for a user of Gazelle",
  defaultMessage: "User"
};

export const MSG_eventTitleTitle = {
  id: "55fe2e82-1edb-4cc6-8efb-9c0a8d06f665",
  description: "The label for an event title (e.g. the title of the event, or what is displayed on the calendar)",
  defaultMessage: "Title"
};

export const MSG_allDayEventToggleLabel = {
  id: "0940660c-cf34-41bd-b0f9-179028287124",
  defaultMessage: "This is an all-day or multi-day event"
};

export const MSG_allDayTitle = {
  id: "e4d82750-2d31-4acd-9fb6-e0837a6fa5d4",
  description: "The label for an event that lasts all day.",
  defaultMessage: "All Day"
};


export const MSG_eventStartTitle = {
  id: "ae07461d-51e0-4654-9f55-4c5d52adf812",
  description: "A word that indicates the beginning date of a multi-day event.",
  defaultMessage: "Start",
};

export const MSG_eventEndTitle = {
  id: "0a04c5d4-7498-4161-8dcf-7a5af328d191",
  description: "A word that indicates the end date of a multi-day event.",
  defaultMessage: "End"
};

export const MSG_addTimeTitle = {
  id: "14e7b3fe-4864-4074-b953-844986f08503",
  description: "Normally an all-day event does not have a start time associated with it (just a date).  This button lets them add a start time to an all-day event.",
  defaultMessage: "Add Time",
};

export const MSG_showTimezoneLabel = {
  id: "4b6decae-f90f-48c0-80dd-1bd69e2d6d4a",
  description: "This shows the timezone that an event is scheduled in.  It will look like 'Timezone: US/Eastern'.",
  defaultMessage: "Timezone: {timezone}",
};

export const MSG_endsAtLabel = {
  id: "0e5b22f0-51e3-4ce6-a87d-c57f079398d3",
  description: "The time that the event ends.",
  defaultMessage: "Ends at {endTime}",
};

export const MSG_createdWithBuffer = {
  id: "31267458-488f-48b9-b3ae-afb31476f1c2",
  description: "Some events are created with some extra time added automatically.  This is called the buffer time.  This is a notice that lets them know the event was created with buffer time added.",
  defaultMessage: "This event was created with a {bufferMins}-minute buffer.",
};

export const MSG_reservationCreatedWithBuffer = {
  id: "3b62e5c2-e08f-401b-ac72-f61da1a8df7b",
  description: "Some reservations are created with some extra time added automatically.  This is called the buffer time.  This is a notice that lets them know the event was created with buffer time added.",
  defaultMessage: "This reservation was created with a {bufferMins}-minute buffer.",
};

export const MSG_bufferTimeTitle = {
  id: "0a1dfee5-d28c-476f-afb3-f5ab8aa201f5",
  description: "Buffer time is time automatically added to an appointment to give some extra time for unplanned things, and to leave space between appointments.",
  defaultMessage: "Buffer Time"
};

export const MSG_removeBufferTimeConfirmationQuestion = {
  id: "9dba7e41-fd11-4184-9eef-0324bcbdfd36",
  description: "",
  defaultMessage: "Are you sure you want to remove the buffer time?"
};

export const MSG_bufferTimeExplanation = {
  id: "f28b29d2-261e-4e13-8b36-8d2293d72191",
  defaultMessage: "When clients schedule their own appointments, or an appointment is scheduled using Smart Routes, a buffer time is added to the appointment to ensure you have enough time.  This can be configured in the scheduler settings section.",
};

export const MSG_durationAdjustPlusHours = {
  id: "038eaabe-da16-4cbf-8dd4-f34ed6fc3e64",
  description: "This is the label for a button that adds hours to a duration of time.  This should be a VERY short abbreviation.  In English, it is +1h for 'add 1 hour'.  Please try to keep this down to 3-4 characters.",
  defaultMessage: "+{num}h",
};

export const MSG_durationAdjustPlusMinutes = {
  id: "044e2d11-0d6a-4ad6-88dd-e25c8f0b0623",
  description: "This is the label for a button that adds minutes to a duration of time.  This should be a VERY short abbreviation.  In English, it is +5m for 'add 5 minutes'.  Please try to keep this down to 3-4 characters.",
  defaultMessage: "+{num}m",
};

export const MSG_durationAdjustMinusHours = {
  id: "4564fdbe-ed8e-44c4-bda9-dccb667ac712",
  description: "This is the label for a button that subtracts hours to a duration of time.  This should be a VERY short abbreviation.  In English, it is -1h for 'subtract 1 hour'.  Please try to keep this down to 3-4 characters.",
  defaultMessage: "-{num}h",
};

export const MSG_durationAdjustMinusMinutes = {
  id: "ef95d656-ed2d-4917-814c-82c4816d9b43",
  description: "This is the label for a button that subtract minutes to a duration of time.  This should be a VERY short abbreviation.  In English, it is -5m for 'subtract 5 minutes'.  Please try to keep this down to 3-4 characters.",
  defaultMessage: "-{num}m",
};

export const MSG_showingUserTitle = {
  id: "8e54cd9f-6ba9-43ed-bcdb-2a84749e9c8d",
  description: "The label to display to inform them of which user is being shown on the calendar.",
  defaultMessage: "Showing: {name}"
};

export const MSG_showingTitle = {
  id: "fc93d5a1-811e-4ecd-ac6a-ba8ecd28362b",
  description: "The label to display to inform them of what is being shown on the calendar.",
  defaultMessage: "Showing"
};

export const MSG_weeklyEventRepetitionFrequencyLabel = {
  id: "975d9e87-3ffa-437a-a2b3-130e86c03032",
  description: "A notice about how often an event repeats, when the frequency is weekly",
  defaultMessage: "{number, plural, one {Every week} other {Every # weeks}}",
};

export const MSG_eventRepetitionOnDaysOfWeekLabel = {
  id: "2ef3f646-bf1c-4256-931e-eb6611740bb1",
  description: "Used to describe which days of the week ane event occurs on.",
  defaultMessage: "On {days}",
};

export const MSG_monthlyEventRepetitionFrequencyLabel = {
  id: "b28a09fe-c2ff-483d-91e5-b3db8e2be458",
  description: "A notice about how often an event repeats, when the frequency is monthly",
  defaultMessage: "{number, plural, one {Every month} other {Every # months}}",
};

export const MSG_eventRepetitionDatesLabel = {
  id: '9f49734c-9292-46ba-9c2a-9d1d459ff1a3',
  description: 'The type of recurring event when it occurs on specific days of the month.',
  defaultMessage: 'On these days of the month',
};

export const MSG_eventRepetitionDatesWithColonLabel = {
  id: '16094615-7a19-4233-b341-a230df69e2eb',
  defaultMessage: 'On these days of the month:',
};

export const MSG_eventRepetitionWeekdaysLabel = {
  id: 'ccebf427-f85a-415d-9129-5d943b5c5b6a',
  description: 'The type of recurring event when it occurs on specific days of the week.',
  defaultMessage: 'On these weekdays',
};

export const MSG_eventRepetitionWeekdaysWithDatesLabel = {
  id: '3ead9cc7-ceba-432b-a238-5c5045558ae7',
  description: "Describes which days of the month something occurs on.  dates will be a phrase like '1st Tuesday and last Tuesday.'",
  defaultMessage: "On the {dates}",
};

export const MSG_eventDetailsTitle = {
  id: "11426237-7724-496f-841e-b22f8f99f4e7",
  description: "The header for the screen that shows the details about a calendar event",
  defaultMessage: "Event Details"
};

export const MSG_appointmentDetailsTitle = {
  id: "7d0d910a-a288-4d93-aff6-abea97dca16d",
  description: "The header for the screen that shows the details about an appointment (an appointment is a calendar event that is tied to a client)",
  defaultMessage: "Appointment Details"
};

export const MSG_thisEventTypeLabel = {
  id: "90cae27f-a307-45ef-8ca1-2a52f85af2b0",
  description: "This is used to indicate the current event.  It will be labeled 'This Appointment', 'This Personal Event', or 'This Memo' based on what eventType is selected.",
  defaultMessage: "This {eventType}"
};

export const MSG_eventTypeLabel = {
  id: "30f882f8-cc37-4210-9c59-6842242861c6",
  defaultMessage: "Event Type:",
};

export const MSG_eventTypeNoColonLabel = {
  id: "ffbe099c-fb6c-4caf-a14d-f9016fb0b52e",
  defaultMessage: "Event Type",
};

export const MSG_personDoesNotWantEmailsLabel = {
  id: "24ac1ca7-7755-4d87-a240-f1e031b5da4e",
  defaultMessage: "{name} does not want emails.",
};

export const MSG_contactForEventDoesNotWantEmailWarning = {
  id: "d03c22ed-f1d6-4165-a58d-5b1e5a96fd8c",
  defaultMessage: "The default contact for this event ({name}) currently has the 'wants email' flag set to no.  If you want to send a confirmation email to this person you must enable this flag first.",
};

export const MSG_noPianosSelectedForAppointment = {
  id: "1cf585de-10ef-4076-a03a-83b0e60276ad",
  defaultMessage: "No pianos have been selected for this appointment.",
};

export const MSG_whenLabel = {
  id: "b904886d-2e0f-4950-98ce-e13f85c80dee",
  description: "The label for where they choose when an event repeats (e.g. either on specific days of the month, or days of the week)",
  defaultMessage: "When?",
};

export const MSG_noClientSelectedLabel = {
  id: "8ff8b12b-d20a-466d-93a5-7f8c74f92f2a",
  defaultMessage: "No client has been selected",
};

export const MSG_changeToName = {
  id: "550a1329-3b0c-4a9c-a88c-285fe96b189f",
  description: "This lets them change the selected technician",
  defaultMessage: "Change to {name}",
};

export const MSG_tapOneToContinueLabel = {
  id: "51a8851f-2ceb-4e5b-9d4e-985f3ee16b9e",
  description: "This message is instructions on top of a list of maps.  They can tap on one to choose it and continue to the next step.",
  defaultMessage: "Tap one to continue",
};

export const MSG_tapAndHoldForOptionsLabel = {
  id: "e8125e90-5ddb-42cd-aa8e-4d83f33babe4",
  description: "This explains to them that they can tap and hold (e.g. long-press) on one of the maps to get more options",
  defaultMessage: "Tap and hold for options",
};

export const MSG_datesFoundPluralizedLabel = {
  id: "b439c077-96de-4f2b-9782-27880edc6f75",
  description: "This is a pluralized explanation of how many options (dates) the search found when searching their calendar.",
  defaultMessage: "{numDates, plural, =1 {1 date found} other {# dates found}}",
};

export const MSG_showingNOfTotalLabel = {
  id: "7935db42-7b70-4a57-98f3-847413aca0d7",
  description: "An indication of how many results are shown on a page.  It will read like: Showing 3 of 20.",
  defaultMessage: "Showing {numShown} of {total}.",
};

export const MSG_otherCalendarSettingsTitle = {
  id: "edcae403-71aa-4c1e-9f23-3d64ba77f626",
  defaultMessage: "Other Calendar Settings",
};

export const MSG_exportEventsWithDetailsExposedExplanation = {
  id: "0a0ab37c-9c13-47df-afc5-b60b62437aa0",
  defaultMessage: "Export events with all details exposed (client contact info, pianos, etc).  If disabled, only the word 'Busy' will be shown.",
};

export const MSG_exportAppointmentsLabel = {
  id: "f53bfb54-e3ce-418e-b7ab-4e30a3137922",
  defaultMessage: "Export appointments",
};

export const MSG_exportPersonalEventsLabel = {
  id: "0b554488-7dc0-4d07-9cca-5209eac7bd9e",
  defaultMessage: "Export personal events",
};

export const MSG_exportMemosLabel = {
  id: "cd66aedf-4190-4b9e-92cc-505ae5f80b94",
  defaultMessage: "Export Memos",
};

export const MSG_forAppointmentDateLabel = {
  id: "3ee6153d-0883-4304-803a-fe6cbc5d93a2",
  defaultMessage: "For appointment: {dateTime}",
};

export const MSG_scheduledDateAndTimeLabel = {
  id: "922132f4-fdf4-41de-b1be-a527d728761f",
  defaultMessage: "Scheduled: {dateTime}",
};

export const MSG_newPersonalEventTitle = {
  id: "7d4ff092-14e9-4d69-9282-41f4e16507b2",
  description: "A button to create a new personal event",
  defaultMessage: "New Personal Event",
};

export const MSG_newMemoTitle = {
  id: "7fa73cd1-268b-462b-953c-32c5303380f4",
  description: "A button to create a new memo event on their calendar",
  defaultMessage: "New Memo",
};

export const MSG_unexpectedErrorRetrievingItinerary = {
  id: "2512b333-8f34-4f1e-8e63-0f3a6eaff862",
  defaultMessage: "There was an unexpected error retrieving your itinerary for this day.",
};

export const MSG_noRoutesToDisplay = {
  id: "a0340059-d2dc-437a-8caf-8136afb410b2",
  description: "",
  defaultMessage: "There are no routes to display."
};

export const MSG_couldNotGetRoute = {
  id: "2fb47e04-7156-4cc8-ba40-a456636c07eb",
  description: "",
  defaultMessage: "Could not retrieve the driving route for this date."
};

export const MSG_confirmedAtDateTimeLabel = {
  id: "70c2c0bc-fe89-433f-98c9-1fa808e98907",
  defaultMessage: "Confirmed on {date} at {time}",
};

export const MSG_confirmedAtDateTimeByClientLabel = {
  id: "a2d3f057-eb93-4f31-85d7-e9b39e8d3f04",
  description: "This indicates that the client confirmed this appointment on a date/time.",
  defaultMessage: "Confirmed on {date} at {time} by client",
};

export const MSG_manuallyConfirmedAtDateTimeLabel = {
  id: "694e17b5-00db-4f48-bf4e-9f891777088a",
  description: "This indicates that the client did not confirm this appointment themselves, but it was manually confirmed by someone in the company on this date/time.",
  defaultMessage: "Manually confirmed on {date} at {time}",
};

export const MSG_customTitle = {
  id: "32a17d51-2bf4-4d31-b5a9-75678aea6478",
  description: "The label when a custom view type is shown, other than month, day, week, e.g. a 9-day slice.",
  defaultMessage: "Custom",
};

export const MSG_defaultViewModeLabel = {
  id: "29213c0c-1e72-445b-9124-46e009e9386a",
  defaultMessage: "Default view mode",
};

export const MSG_calendarEventFontSizeLabel = {
  id: "5bbc9331-fd2c-4e6b-ad17-3542f727c48e",
  defaultMessage: "Calendar event font size",
};

export const MSG_newEventTitle = {
  id: "31106f95-d75e-42f6-854e-2c8891f03501",
  defaultMessage: "New Event",
};

export const MSG_shortcutsWithColonLabel = {
  id: "1886b5bf-bddd-4e07-8601-f4dd90a8b688",
  description: "The word 'shortcuts' in english.  This is the label for a pro tip, or a hint about a shortcut they can use.",
  defaultMessage: "Shortcuts:",
};

export const MSG_scrollShortcutExplanation = {
  id: "0dee68fb-a15b-4486-bd8a-d871d301cae4",
  defaultMessage: "SHIFT+scroll, or press the arrow keys to change months.",
};

export const MSG_sendAConfirmationEmailLabel = {
  id: "14e7a990-bd5b-4632-b17e-330509008cc1",
  defaultMessage: "Send a confirmation email",
};

export const MSG_changingTypeFromAppointmentNotRecommended = {
  id: "74948876-8878-4dbc-b2b2-a0d5aebb8ad7",
  defaultMessage: "Changing the event type from an appointment is not usually recommended.  The client may start receiving reminders again if their piano is still due for service.",
};

export const MSG_toBetweenDates = {
  id: "80858393-f3d1-4569-98cf-95a22824c0b0",
  description: "This goes between two dates.  Like:  '2018-03-01 to 2018-03-04'.",
  defaultMessage: "to",
};

export const MSG_forBeforeDuration = {
  id: "b6047021-0a64-4a2f-9949-44b0b750a0f6",
  description: "This is used to indicate a duration of time.  It is put between the date/time element and the duration selector.  It will read like this:  '2018-03-01 11:30 AM for 3h'.",
  defaultMessage: "for",
};

export const MSG_youSelectedDateTime = {
  id: "afe0d0bf-f410-43bb-96b7-f5720c48aa12",
  defaultMessage: "You selected {dateTime}",
};

export const MSG_scheduleAnAppointmentTitle = {
  id: "7e9dcb20-058a-4088-88ed-5e5a139f9c8f",
  defaultMessage: "Schedule an Appointment",
};

export const MSG_scheduleAnAppointmentExplanation = {
  id: "9144d760-60df-40c9-88f8-4cafd8369f4e",
  description: "This is the description under 'Schedule an Appointment'.",
  defaultMessage: "Pick a client, a service, and a date. Gazelle will display possible routes or let you choose a specific day.",
};

export const MSG_newPersonalEventExplanation = {
  id: "f7a42be6-a986-44eb-963f-757b7525be72",
  description: "This is the description under 'New Personal Event'.",
  defaultMessage: "Block off time on your calendar and provide a location so Gazelle can schedule appointments nearby.",
};

export const MSG_newMemoExplanation = {
  id: "ac8e344b-9127-4912-9304-2e04cbfb7e35",
  description: "This is the description under 'New Memo'.",
  defaultMessage: "Leave yourself a note on your calendar (e.g. a birthday). They will not affect your scheduling.",
};

export const MSG_eventLocationExplanation = {
  id: "55f747f8-4c44-4bde-bf51-7435fde3a28e",
  defaultMessage: "Setting the address of this event allows Gazelle to optimize your calendar by booking appointments nearby, saving you drive time.",
};

export const MSG_miscServicesDescriptionTitle = {
  id: "8676ff07-a605-45ca-bf95-5592cd37815e",
  defaultMessage: "Misc. Services Description",
};

export const MSG_editingContactOnExistingClientNotSupported = {
  id: "80dfe246-2ff3-47a9-a45d-783a04295661",
  defaultMessage: "Editing the contact information for an existing client is not yet supported when scheduling a new appointment.  Please find the client record and update it first, then schedule this appointment.",
};

export const MSG_noAppointments = {
  id: "b52ef4be-d10f-4583-bfbe-2874a731810e",
  description: "",
  defaultMessage: "There are no appointments on this date."
};

export const MSG_calendarShortcutsTitle = {
  id: "8bbe4cd6-8a3b-42bb-b176-e7b338de161d",
  description: "A heading for a dialog box that lists keyboard shortcuts.",
  defaultMessage: "Calendar Shortcuts"
};

export const MSG_searchForOpeningsTitle = {
  id: "9147260a-6555-42f1-acda-a3407ec9818b",
  description: "The title of a keyboard shortcut that will search for openings on a calendar.",
  defaultMessage: "Search For Openings"
};

export const MSG_monthViewTitle = {
  id: "bfccb678-078d-4d21-b0d9-e8032af32700",
  description: "The title of a keyboard shortcut that will change the calendar to show a full month at a time.",
  defaultMessage: "Month View"
};

export const MSG_weekViewTitle = {
  id: "27b24221-2870-4197-9f32-f54bc82ba6f9",
  description: "The title of a keyboard shortcut that will change the calendar to show a week at a time.",
  defaultMessage: "Week View"
};

export const MSG_dayViewFullTitle = {
  id: "112dd290-7679-41db-b6a1-16e0e8d8361d",
  description: "The title of a keyboard shortcut that will change the calendar to show a single day at a time.",
  defaultMessage: "Day View"
};

export const MSG_fourDayViewTitle = {
  id: "6e6b21a5-2e7c-4817-bf74-7711493aaf64",
  description: "The title of a keyboard shortcut that will change the calendar to show four days at a time.",
  defaultMessage: "4-Day View"
};

export const MSG_jumpToTodayTitle = {
  id: "2ccc60a5-61e8-4dfd-85c0-bf51aed42694",
  description: "The title of a keyboard shortcut that will navigate the calendar to today.",
  defaultMessage: "Jump To Today"
};

export const MSG_previousTimePeriodTitle = {
  id: "ae2afa4c-7456-462f-9cf7-c076a4b565e8",
  description: "The title of a keyboard shortcut that will navigate the calendar to the previous month/day/week depending on which view is currently in use.",
  defaultMessage: "Previous Month/Day/Week"
};

export const MSG_nextTimePeriodTitle = {
  id: "5b9b102d-b44c-4c5c-b0ea-ee65c04ebd5a",
  description: "The title of a keyboard shortcut that will navigate the calendar to the next month/day/week depending on which view is currently in use.",
  defaultMessage: "Next Month/Day/Week"
};

export const MSG_pressShiftToJumpExplanation = {
  id: "46a3af01-de5d-45f8-958d-9ad75a6db0cd",
  defaultMessage: "Press <code>SHIFT</code> along with arrow keys to jump in larger intervals"
};

export const MSG_showTheseShortcutsTitle = {
  id: "5c04b7a7-7788-4015-8f24-7bbd5eac486c",
  defaultMessage: "Show These Shortcuts"
};

export const MSG_shiftClickEventJumpsExplanation = {
  id: "113fde33-e29f-4f99-b43e-a4ffdbbd6c3a",
  defaultMessage: "<code>SHIFT+CLICK</code> on an event in the calendar jumps directly to editing, instead of first showing the event summary."
};

export const MSG_clickOnDateInMiniCalendarExplanation = {
  id: "62e2e503-7b8e-4156-b1b0-ed56f2011ec8",
  defaultMessage: "<code>CLICK</code> on a date in the mini-calendar in the top-left corner, then <code>SHIFT+CLICK</code> on another date to display events in an arbitrary date range (i.e. 3 days or 2 weeks, etc)."
};

export const MSG_shiftClickNewEventButtonExplanation = {
  id: "670ce727-908d-4b12-9b7d-147fa9318a07",
  defaultMessage: "<code>SHIFT+CLICK</code> on the \"New Event\" button, or on any blank space on your calendar, to jump directly to searching for openings."
};

export const MSG_shiftClickOnArrowButtonsExplanation = {
  id: "8050d7db-5cd3-4a10-9f05-84b94f4d56b3",
  defaultMessage: "<code>SHIFT+CLICK</code> on the arrow buttons in the calendar header to jump in larger intervals (e.g. jump 1 year instead of 1 month, on the month view)."
};

export const MSG_shiftScrollOnMonthViewExplanation = {
  id: "ac9ef4ef-531f-4603-aad4-915817172891",
  defaultMessage: "<code>SHIFT+SCROLL</code> on the month view will go to the next or previous month."
};

export const MSG_createdViaSchedulerVersionLabel = {
  id: "c7319d17-8d9a-46c2-bc66-0cf719bf6d8d",
  defaultMessage: "Created via Smart Routes v{version}",
};

export const MSG_cloneEventTitle = {
  id: "6fe03e94-4520-4176-ae1d-1a7752e26e52",
  description: "The label for a button that creates a copy of an event.",
  defaultMessage: "Clone"
};