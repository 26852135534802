import * as React from "react";
import { PropsWithChildren } from "react";

interface IProps extends PropsWithChildren {}

class StepHeader extends React.Component<IProps> {
  render() {
    return (
      <div className="step-header">
        <div className="step-header-content">
          {this.props.children}
        </div>
      </div>
    );
  }
}

export { StepHeader };
