import { compact } from 'lodash';
import { Moment } from 'moment-timezone';

const moment = require('moment-timezone');

export function calculateServiceDateAttrs(attrs: {eventLastService: Moment, manualLastService: Moment, serviceIntervalMonths: number}) {
  // the new calculatedLastService is the max non null value between piano.eventLastService and manualLastService
  let calculatedLastService = compact([attrs.eventLastService, attrs.manualLastService]).reduce((prev: Moment, current: Moment) => {
    if (!current) return prev;
    if (!prev) return current;
    return (current.isAfter(prev)) ? current : prev;
  }, null);
  let calculatedNextService: string = null;
  if (calculatedLastService && attrs.serviceIntervalMonths) {
    calculatedNextService = moment(calculatedLastService).add(attrs.serviceIntervalMonths, 'months').format('Y-MM-DD');
  }
  return {
    manualLastService: attrs.manualLastService ? attrs.manualLastService.format('Y-MM-DD') : null,
    calculatedLastService: calculatedLastService ? calculatedLastService.format('Y-MM-DD') : null,
    calculatedNextService: calculatedNextService
  };
}
