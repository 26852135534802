import {
  FilterType,
  IBooleanFilter,
  ICurrencyFilter,
  IFilterConfigProps,
  IFilterHandler,
  IFilterLabelProps,
  IFilterTypeConfig
} from "./types";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";

export const GenericBooleanFilterHandler: IFilterHandler = {
  type: FilterType.BOOLEAN,

  getIcon: () => faCheckCircle,

  getQueryVariables: (filter: ICurrencyFilter, config: IFilterTypeConfig) => {
    if (typeof filter.value === 'boolean') {
      const val = config.invertBoolean ? !filter.value : filter.value;
      return {[config.queryArgumentKey]: val};
    } else {
      return {};
    }
  },

  isValid: (filter: IBooleanFilter) => {
    return true;
  },

  defaultValue: (config: IFilterTypeConfig) => {
    let defaultValue = true;
    if (typeof config.defaultValue !== 'undefined') {
      defaultValue = config.defaultValue;
    }
    return {
      key: config.key,
      type: FilterType.BOOLEAN,
      value: defaultValue
    };
  },

  LabelComponent: (props: IFilterLabelProps) => {
    throw "Expected BooleanFilterHandler to implement LabelConfig.";
  },

  ConfigComponent: (props: IFilterConfigProps) => {
    throw "Expected BooleanFilterHandler to implement ComponentConfig.";
  }
};
