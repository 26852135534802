import { computed, makeObservable, observable } from 'mobx';

class ContactPhone {
  id: string;
  isDefault: boolean;
  type: string;
  phoneNumber: string;
  extension: string;

  constructor(attrs: any) {
    this.id = attrs.id;
    this.isDefault = attrs.isDefault;
    this.type = attrs.type;
    this.phoneNumber = attrs.phoneNumber;
    this.extension = attrs.extension;

    makeObservable(this, {
      id: observable,
      isDefault: observable,
      type: observable,
      phoneNumber: observable,
      extension: observable,
      displayNumber: computed
    });
  }

  get displayNumber() {
    let str = this.phoneNumber || '';
    if (this.extension) str += ` x${this.extension}`;
    return str;
  }
}

const CONTACT_PHONE_ALL_GQL_QUERY_FIELDS = `
  id isDefault type
  phoneNumber extension
`;

export { ContactPhone, CONTACT_PHONE_ALL_GQL_QUERY_FIELDS };
