export const MSG_availabilityModalTitle = {
  id: "00dca876-9baa-49f1-8c59-6456eb167123",
  description: "This is a title for a popup modal that displays when the technician is available for scheduling on a particular date.",
  defaultMessage: "Availability for {date}"
};

export const MSG_noAvailability = {
  id: "f04288bb-4e70-4917-8c11-341524221c50",
  description: "",
  defaultMessage: "{name} is not available for scheduling on {date}."
};

export const MSG_cannotEditAvailabilityInPast = {
  id: "575c35a2-9335-4c04-a053-6dfb9934590b",
  description: "",
  defaultMessage: "Availability cannot be edited for dates in the past."
};


export const MSG_addAvailabilityTitle = {
  id: "71603e74-fcb4-4cb6-bfbb-b34230575344",
  description: "",
  defaultMessage: "Add Availability"
};


export const MSG_noInactiveAvailability = {
  id: "432ba45b-f0e9-4c47-8a31-a70470bafc7b",
  description: "",
  defaultMessage: "There are no inactive availabilities spanning {date} that could be included on this date."
};


export const MSG_includeDayInAnotherAvailabilityLabel = {
  id: "87e623d7-7149-4628-bfc4-1319b03a1d9d",
  description: "",
  defaultMessage: "Include {date} in an existing availability"
};

export const MSG_includeDateTitle = {
  id: "3e88f8c8-9f5b-47a1-bc72-2a24bf7d8fa2",
  description: "",
  defaultMessage: "Include {date}"
};

export const MSG_createNewAvailabilityLabel = {
  id: "102fbc8a-1711-4b7a-9441-8ee549e51124",
  description: "",
  defaultMessage: "Create a new availability"
};

export const MSG_deleteAvailabilityModalTitle = {
  id: "4778fbe7-96e1-4b78-ac58-cbe7cb57baa9",
  description: "",
  defaultMessage: "Delete Availability for {date}"
};

export const MSG_deleteAvailabilityModalExplanation = {
  id: "cebdea11-5ccc-47f7-acc3-5bb9fbd10e52",
  description: "",
  defaultMessage: "Do you want to delete this availability just for {date} or for all dates (e.g. permanently remove it from your scheduling rules)?"
};

export const MSG_deleteAvailabilityReallyDelete = {
  id: "791da776-9e47-4290-9838-498f09cb07ee",
  description: "",
  defaultMessage: "For all dates"
};

export const MSG_deleteAvailabilityOnlyThisDate = {
  id: "39ddf2a6-fff2-40f1-bea5-67d7669cdcae",
  description: "",
  defaultMessage: "Only for {date}"
};

export const MSG_deleteAvailabilityReallyDeleteConfirmation = {
  id: "cc1ddb11-1e73-4f7a-b1cb-7b3137bd093a",
  description: "",
  defaultMessage: "This will remove this availability and all its associated settings from your scheduling rules for all dates, not just the selected date.  Are you sure you want to permanently remove this availability from your scheduling rules?"
};

export const MSG_inactiveAvailabilitiesForDateExplanation = {
  id: "9a651c62-668b-42e3-968f-3f1965292f9a",
  description: "",
  defaultMessage: "This is a list of availabilities that have a start and end date that includes {date}."
};

export const MSG_inactiveAvailabilitiesTitle = {
  id: "a3d5a701-3e62-4096-9bd1-6c7b6fd6e6b6",
  description: "",
  defaultMessage: "Inactive Availabilities"
};

export const MSG_inactiveAvailabilitiesExplanation = {
  id: "adfe7f30-b080-421e-92dd-ac3317d26741",
  description: "",
  defaultMessage: "These are the availabilities whose start and end date include {date} but have an exclusion date for {date} or a recurrence rule that does not apply to this date."
};


export const MSG_alreadyActiveAvailabilitiesTitle = {
  id: "5af42705-5d83-41e4-bd29-d9a36da3f255",
  description: "",
  defaultMessage: "Already Active Availabilities"
};

export const MSG_alreadyActiveAvailabilitiesExplanation = {
  id: "f0422313-4824-4227-92ce-ca329d4376be",
  description: "",
  defaultMessage: "These are the availabilities whose start and end date include {date} AND who have a recurrence rule that applies to this date or an inclusion date that includes {date}."
};

export const MSG_otherAvailabilitiesTitle = {
  id: "0fc1ba8f-647a-40fe-8675-9eed1bd866b7",
  description: "",
  defaultMessage: "Other Availabilities"
};

export const MSG_otherAvailabilitiesExplanation = {
  id: "6ee144c5-73c0-4dca-8258-0354938f1759",
  description: "When setting availability from the calendar modal these are instructions to add an availability that do not include the date selected.",
  defaultMessage: "These are availabilities whose start and end dates do not include {date}. After including this date you will be prompted to edit this availability’s settings to accommodate this date. "
};

export const MSG_removeAvailabilityForDateLabel = {
  id: "75b6ae13-6174-4d73-8f31-abdd9f0beb31",
  description: "This is a button label that removes an availability for a particular date.",
  defaultMessage: "Only remove for {date}"
};

export const MSG_removeAvailabilityForDateConfirmation = {
  id: "7b99897b-a4bb-45e5-ae77-6b46008676c0",
  description: "",
  defaultMessage: "Are you sure you want to exclude this availability for {date}?"
};

export const MSG_removeAvailabilityStartingDateLabel = {
  id: "2f27a3ba-586b-45e1-8958-4928cbb26841",
  description: "This is a button label that removes an availability for a particular date and all subsequent dates.  It should be made clear that {date} will be removed as well as all subsequent dates.",
  defaultMessage: "Remove for {date} and following dates."
};

export const MSG_removeAvailabilityStartingDateConfirmation = {
  id: "42eec3ce-21e8-4397-80c1-b16f33925a9e",
  description: "",
  defaultMessage: "Are you sure you want to remove this availability for {date} and all subsequent dates?"
};


export const MSG_removeAvailabilityCompletelyLabel = {
  id: "cacfdc41-6fac-4e9b-b6b9-f575cabf5b91",
  description: "This is a button label that removes an availability for all dates.",
  defaultMessage: "Delete this availability from settings (for all dates)"
};

export const MSG_removeAvailabilityCompletelyConfirmation = {
  id: "3136e3fe-a93e-40ea-8cab-b48a6bfda5c2",
  description: "",
  defaultMessage: "Are you sure you want to completely delete this availability for all dates?  This will remove this availability and all its associated settings from your scheduling rules for all dates, not just the selected date."
};

export const MSG_availabilityIncludeExceptionForDate = {
  id: "ba9bf439-70d4-4bdf-a930-abc5966d21f9",
  description: "",
  defaultMessage: "An exception was added to include {date}."
};

export const MSG_availabilityExcludeExceptionForDate = {
  id: "a169fb57-2a1d-4e36-a9d3-73569bf9f632",
  description: "",
  defaultMessage: "An exception was added to exclude {date}."
};

export const MSG_startConfiguringAvailabilityHelp = {
  id: "d13e2e28-e2c6-44fa-9704-4843724cbd37",
  description: "",
  defaultMessage: "Use this icon to configure availability for {name}."
};

export const MSG_availabilityActive = {
  id: "1e347550-a180-4589-84af-d11194677bd7",
  description: "A label that says a specific availability is active on a date.",
  defaultMessage: "Active"
};

export const MSG_availabilityInactiveReasonBeforeStartDate = {
  id: "0c647e6a-40ed-4d0b-bf87-eb819e9a33e2",
  description: "",
  defaultMessage: "This availability is not active for {date} because the availability starts after this date.",
};

export const MSG_availabilityInactiveReasonAfterEndDate = {
  id: "ee822330-7f3a-443c-b7e3-f476f28aecfd",
  description: "",
  defaultMessage: "This availability is not active for {date} because the availability ends before this date.",
};

export const MSG_availabilityInactiveReasonExcludedDate = {
  id: "5458fe7a-680b-4303-90ad-63b2169ee5dc",
  description: "",
  defaultMessage: "This availability is not active for {date} because it has been excluded.",
};

export const MSG_availabilityInactiveReasonOtherExclusiveAvailability = {
  id: "f9c67339-851b-4191-bcf4-44007911c735",
  description: "",
  defaultMessage: "This availability is not active for {date} because there is another availability on this date which is exclusive.",
};

export const MSG_availabilityInactiveReasonNotInRecurrenceRule = {
  id: "5af2dee2-6fcd-480a-8746-68ca3abb80ee",
  description: "",
  defaultMessage: "This availability is not active because {date} is not included in the recurrence rule.",
};

export const MSG_indefinitelyTitle = {
  id: "239e7736-5e79-4a00-b4e7-67718a7cf50f",
  description: "A term that means there is no specific end date.  This is used to show a date range that has no specific end date:  e.g. 'Jan 1, 2024 - Indefinitely'",
  defaultMessage: "Indefinitely"
};

export const MSG_availabilityInPastNotShown = {
  id: "dd4bed16-1759-41ca-b386-29921e407d15",
  description: "",
  defaultMessage: "Availabilities are not shown for dates in the past."
};

export const MSG_cannotIncludeAvailabilityBecauseExclusive = {
  id: "bc2f88db-05d1-4098-964c-ea9a5ab6ca11",
  description: "",
  defaultMessage: "This availability cannot be included on this date because an exclusive availability is already active."
};
