import { action, computed, makeObservable, observable } from 'mobx';
import * as moment from 'moment';
import { IStoreOptions } from '../root/root_store';
import { Piano } from '@core';
import { PianoWrapper } from '../../models/piano_wrapper';
import { RootStore } from "../root";

class PianoStore {
  private rootStore: RootStore;
  pianoWrappers: PianoWrapper[] = null;
  editingPianoWrapper: PianoWrapper = null;
  originalPianoWrapper: PianoWrapper = null;
  maxNumPianos = 0;

  constructor() {
    this.pianoWrappers = [new PianoWrapper(new Piano())];

    makeObservable(this, {
      pianoWrappers: observable,
      editingPianoWrapper: observable,
      originalPianoWrapper: observable,
      maxNumPianos: observable,
      initializeData: action,
      reset: action,
      add: action,
      startEditing: action,
      stopEditing: action,
      saveEditingWrapper: action,
      setPianoWrapperDefaults: action,
      remove: action,
      selectedPianoWrappers: computed,
      unselectedPianoWrappers: computed,
      numServices: computed,
      totalDuration: computed,
      totalAmount: computed,
      isComplete: computed
    });
  }

  initializeData(rootStore: RootStore, options: IStoreOptions) {
    this.rootStore = rootStore;

    if (options.useTestData) {
      this.reset();
      this.add(new Piano({
        make: 'Steinway',
        kind: 'GRAND',
        model: 'D'
      }));
    } else {
      // If we don't know who the client is, or they don't have any pianos
      // on file, create a blank one for them to start with.
      if (this.pianoWrappers.length === 0) {
        this.add(new Piano({
          kind: 'UNKNOWN'
        }));
      }
    }

    if (!this.rootStore.estimateTier) {
      // Iterate over all the pianos and for any that are due (or we don't know
      // when they were last tuned), select default services for all of them.
      let tmpWrappers: PianoWrapper[] = [];
      this.pianoWrappers.forEach(pianoWrapper => {
        let piano = pianoWrapper.piano;
        if (!piano.calculatedNextService || piano.calculatedNextService.isBefore(moment().add(1, 'month'))) {
          tmpWrappers.push(pianoWrapper);
        }
      });
      if (tmpWrappers.length === 0 && this.pianoWrappers.length > 0) {
        tmpWrappers.push(this.pianoWrappers[0]);
      }
      tmpWrappers.forEach(function (wrapper) {
        rootStore.masterServiceItems.forEach((item) => {
          if (item.isDefault) {
            wrapper.set(item);
          }
        });
      });
    }
  }

  reset(): void {
    this.pianoWrappers.length = 0;
  }

  add(piano: Piano): PianoWrapper {
    let w = new PianoWrapper(piano);
    this.pianoWrappers.push(w);
    if (this.maxNumPianos < this.pianoWrappers.length) {
      this.maxNumPianos = this.pianoWrappers.length;
    }
    this.setPianoWrapperDefaults(w);
    return w;
  }

  // To edit a PianoWrapper with the modal, we want to edit a clone of the
  // original.  That way they can hit "cancel" and all their changes go away.
  // Otherwise if they edit the original, their changes are saved immediately
  // and it feels weird in the UI.  So we keep a pointer to the original one
  // so we can copy the changed attributes back when we save.
  startEditing(pianoWrapper?: PianoWrapper) {
    this.editingPianoWrapper = new PianoWrapper(new Piano({}));
    if (pianoWrapper) {
      this.originalPianoWrapper = pianoWrapper;
      this.editingPianoWrapper.clone(pianoWrapper);
    } else {
      this.setPianoWrapperDefaults(this.editingPianoWrapper);
    }
  }

  // A null editingPianoWrapper indicates that we are NOT editing a piano.
  // And just to be clean, lets null out the original pointer too.
  stopEditing() {
    this.originalPianoWrapper = this.editingPianoWrapper = null;
  }

  // If we are editing an existing PianoWrapper, we will have a pointer stored
  // in this.originalPianoWrapper.  If that's the case, update the original one
  // with the changes from this.editingPianoWrapper.  Otherwise, we are editing
  // a new PianoWrapper, so just push it onto the array.
  //
  // In either case, reset the editing status at the end.
  saveEditingWrapper() {
    if (this.originalPianoWrapper) {
      this.originalPianoWrapper.clone(this.editingPianoWrapper);
    } else {
      this.pianoWrappers.push(this.editingPianoWrapper);
      if (this.maxNumPianos < this.pianoWrappers.length) {
        this.maxNumPianos = this.pianoWrappers.length;
      }
    }
    this.stopEditing();
  }

  setPianoWrapperDefaults(pianoWrapper: PianoWrapper) {
    if (this.rootStore) {
      this.rootStore.masterServiceItems.forEach((item) => {
        if (item.isDefault) {
          pianoWrapper.set(item);
        }
      });
    }
  }

  remove(pianoWrapper: PianoWrapper): void {
    const i = this.pianoWrappers.indexOf(pianoWrapper);
    this.pianoWrappers.splice(i, 1);
  }

  get selectedPianoWrappers(): PianoWrapper[] {
    return this.pianoWrappers.filter((pw) => pw.numServices > 0);
  }

  get unselectedPianoWrappers(): PianoWrapper[] {
    return this.pianoWrappers.filter((pw) => pw.numServices === 0);
  }

  get numServices() {
    let count = 0;
    this.pianoWrappers.forEach((wrapper) => {
      count += wrapper.numServices;
    });
    return count;
  }

  get totalDuration() {
    let duration = 0;
    this.pianoWrappers.forEach((wrapper) => {
      duration += wrapper.totalDuration;
    });
    return duration;
  }

  get totalAmount() {
    let amount = 0;
    this.pianoWrappers.forEach((wrapper) => {
      amount += wrapper.totalAmount;
    });
    return amount;
  }

  get isComplete(): boolean {
    return this.numServices > 0;
  }
}

export { PianoStore };
