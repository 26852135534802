import * as React from "react";
import { Moment } from 'moment-timezone';
import { formatDate, formatDateTime, formatDuration, formatTime, } from "../utils/time";
import { getLocaleStoreInstance, toMomentLocale } from "../utils/intl";
import { Localization } from "../models";
import { IDateFormat, ITimeFormat } from "../interfaces";

const Wrapper = (props: any) => <span>{props.children}</span>;

interface IGazelleDateProps {
  moment: Moment,
  weekday?: boolean,
  relative?: boolean,
  dateFormatterOverride?: IDateFormat;
  dateFormatLocaleOverride?: string;
}
export const GazelleDate = (props: IGazelleDateProps) => {
  const Container = getLocaleStoreInstance().getTextComponent() || Wrapper;
  props.moment.locale(toMomentLocale(props.dateFormatLocaleOverride || getLocaleStoreInstance().localization.locale));
  let str = formatDate(props.moment, props);
  if (props.relative) {
    return <Container title={str}>{props.moment.fromNow()}</Container>;
  } else {
    return <Container>{str}</Container>;
  }
};

interface IGazelleTimeProps {
  moment: Moment,
  hideMinutes?: boolean,
  hideAmPm?: boolean,
  relative?: boolean,
  timeFormatLocaleOverride?: string;
  timeFormatterOverride?: ITimeFormat;
}
export const GazelleTime = (props: IGazelleTimeProps) => {
  const Container = getLocaleStoreInstance().getTextComponent() || Wrapper;
  props.moment.locale(toMomentLocale(props.timeFormatLocaleOverride || getLocaleStoreInstance().localization.locale));
  let str = formatTime(props.moment, props);
  if (props.relative) {
    return <Container title={str}>{props.moment.fromNow()}</Container>;
  } else {
    return <Container>{str}</Container>;
  }
};

interface IGazelleDateTimeProps {
  moment: Moment;
  relative?: boolean;
  weekday?: boolean;
  dateFormatterOverride?: IDateFormat;
  dateTimeSeparatorOverride?: string;
  timeFormatterOverride?: ITimeFormat;
  dateFormatLocaleOverride?: string;
}
export const GazelleDateTime = (props: IGazelleDateTimeProps) => {
  const Container = getLocaleStoreInstance().getTextComponent() || Wrapper;
  props.moment.locale(toMomentLocale(props.dateFormatLocaleOverride || getLocaleStoreInstance().localization.locale));
  let str = formatDateTime(props.moment, props);
  if (props.relative) {
    return <Container title={str}>{props.moment.fromNow()}</Container>;
  } else {
    return <Container>{str}</Container>;
  }
};

interface IGazelleDurationProps {
  minutes: number,
  hours?: number,
  expanded?: boolean,
  onlyHours?: boolean,
  onlyMinutes?: boolean,
  localization?: Localization;
}
export const GazelleDuration = (props: IGazelleDurationProps) => {
  const Container = getLocaleStoreInstance().getTextComponent() || Wrapper;
  let minutes = props.minutes % 60;
  let hours = props.hours || 0;
  hours += Math.floor(props.minutes / 60);

  return <Container>{formatDuration((hours * 60) + minutes, {expanded: props.expanded, onlyHours: props.onlyHours, onlyMinutes: props.onlyMinutes, locale: props.localization?.locale})}</Container>;
};
