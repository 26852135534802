import { action, computed, makeObservable, observable } from 'mobx';
import { ContactInfo } from '@core';

import { IStoreOptions } from '../root/root_store';
import { RootStore } from "../root";

class ContactStore {
  private rootStore: RootStore;

  contactInfo: ContactInfo;
  notes: string = '';

  constructor() {
    this.contactInfo = new ContactInfo();
    makeObservable(this, {
      contactInfo: observable,
      notes: observable,
      isComplete: computed,
      initializeData: action,
      setContactInfo: action
    });
  }

  get isComplete() {
    return false;
  }

  initializeData(rootStore: RootStore, options: IStoreOptions) {
    this.rootStore = rootStore;

    if (options.useTestData) {
      this.contactInfo.firstName = 'Malcolm';
      this.contactInfo.lastName = 'Reynolds';
      this.contactInfo.phoneNumber = '365-567-1212';
      this.contactInfo.phoneType = 'MOBILE';
      this.contactInfo.email = 'tightpants@firefly.com';
      this.contactInfo.smsOptIn = true;
    } else {
      // no-op
    }
  }

  setContactInfo(rootStore: RootStore, client: any) {
    if (rootStore && !this.rootStore) this.rootStore = rootStore;
    if (!client || !client.defaultContact) {
      if (rootStore.companySettings.smsEnabled && !rootStore.companySettings.isSmsOptIn) {
        this.contactInfo.smsOptIn = true;
      }
      return;
    }

    if (client.defaultContact) {
      this.contactInfo.firstName = client.defaultContact.firstName;
      this.contactInfo.lastName = client.defaultContact.lastName;
      if (client.defaultContact.defaultPhone) {
        this.contactInfo.phoneNumber = client.defaultContact.defaultPhone.phoneNumber;
        this.contactInfo.phoneType = client.defaultContact.defaultPhone.type;
      }
      if (client.defaultContact.defaultEmail) {
        this.contactInfo.email = client.defaultContact.defaultEmail.email;
      }
      // If the company uses opt-in behavior, set it to the defaultContact's current wantsText value.
      // Otherwise, default it to opt-out behavior by setting the smsOptIn value to true (meaning the
      // box is checked by default).
      if (rootStore.companySettings.smsEnabled) {
        if (this.rootStore.companySettings.isSmsOptIn) {
          this.contactInfo.smsOptIn = !!client.defaultContact.wantsText;
        } else {
          this.contactInfo.smsOptIn = true;
        }
      }
    }
  }
}

export { ContactStore };
