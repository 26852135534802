export const MSG_subtotalLabel = {
  id: "56e6b73e-ab77-44d1-8a12-33e4de1dca3f",
  description: "The subtotal of an invoice (the sum of all the invoice items before taxes).",
  defaultMessage: "Subtotal",
};

export const MSG_subtotalLabelShort = {
  id: "3735c2bf-70a7-4413-b06b-f98bdb7d3c69",
  description: "The subtotal of an invoice (the sum of all the invoice items before taxes).  This is a shorter version of the label, if needed for the language.  It should be no more than 8 characters long.",
  defaultMessage: "Subtotal"
};


export const MSG_taxesLabel = {
  id: "273dbcbf-4166-4a9a-923f-0fd7e96a539f",
  description: "A label for the taxes on an invoice.",
  defaultMessage: "Taxes",
};

export const MSG_taxesLabelShort = {
  id: "2692ee5b-e0ce-45d1-8f6a-b83a9456ec86",
  description: "A label for the taxes on an invoice.  This is a shorter version of the label, if needed for the language.  It should be no more than 8 characters long.",
  defaultMessage: "Taxes"
};


export const MSG_itemTotalLabel = {
  id: "bc04ed81-79ca-4fbc-8d15-cfccd039dd7c",
  description: "The final total cost of a single line item on an estimate (subtotal + taxes).",
  defaultMessage: "Item Total",
};

export const MSG_lineTotalLabel = {
  id: "205a8347-bfe5-4603-bea6-bbaa80a72269",
  description: "The label displayed above the total for a single line item on the invoice.  This sums up the quantity x amount + taxes.",
  defaultMessage: "Line Total"
};

export const MSG_lineTotalLabelShort = {
  id: "e523b738-61c4-49cd-ba06-54310f13df00",
  description: "The label displayed above the total for a single line item on the invoice.  This sums up the quantity x amount + taxes.  This is a shorter version of the label, if needed for the language.  It should be no more than 8 characters long.",
  defaultMessage: "Line Total"
};



export const MSG_atAmount = {
  id: "10381753-efd2-4c63-b55c-287359728ecf",
  description: "An abbreviated label that gets added after the number of miles or kilometers on an invoice to indicate the mileage rate.",
  defaultMessage: "at {amount}/{unit}"
};

export const MSG_atEach = {
  id: "cfc5e29b-b461-448d-949b-edf99886573a",
  description: "An abbreviated label that gets added after the number of miles or kilometers on an invoice to indicate the mileage rate.",
  defaultMessage: "at {amount} each"
};

export const MSG_atHour = {
  id: "236df315-a1d5-43f3-888f-670723497c3c",
  description: "An abbreviated label that gets added after the number of hours on an invoice to indicate the hourly rate.  In English we are abbreviating 'per hour' with '/hr'.  Please use an abbreviation if possible, this should be kept as short as possible.",
  defaultMessage: "at {amount}/hr"
};

export const MSG_pricePerMile = {
  id: "90e55230-1e2e-4f4b-baa9-f9e8b9c5b68e",
  description: "This is distance in miles",
  defaultMessage: "{quantity, plural, one {1 mile} other {# miles}}"
};

export const MSG_pricePerKilometer = {
  id: "abf8c415-a695-4b2c-8ad1-14d2c4388f74",
  description: "This is distance in kilometers.  In English we are abbreviating it because 'kilometers' is too long for this space.  You can do the same if it makes sense.",
  defaultMessage: "{quantity, plural, one {1 km} other {# km}}"
};

export const MSG_pricePerUnit = {
  id: "d7ba1fe5-728c-4dd3-9c1e-580a5a417ea1",
  description: "Here, 'unit' is a generic term for a thing they were invoiced for.",
  defaultMessage: "{quantity, plural, one {1 unit} other {# units}}"
};

export const MSG_pricePerHour = {
  id: "a5168666-beb8-412e-8dac-e04071432c9c",
  description: "",
  defaultMessage: "{quantity, plural, one {1 hour} other {# hours}}"
};

export const MSG_miscItemsTitle = {
  id: "fdbf3dfe-7c91-427e-a6ac-bd19dda2fb25",
  description: "A group name called 'Miscellaneous Items'.  This is a group of estimate items that don't really fit into another group.",
  defaultMessage: "Misc. Items"
};

export const MSG_amountDueLabel = {
  id: "408ebb9d-1e4d-41ec-b757-ee36984afdf8",
  description: "The the amount that is due to be paid on this invoice.  This is in label case.",
  defaultMessage: "Amount due"
};

export const MSG_amountDueTitle = {
  id: "10629249-a316-49d0-b39d-cf543899d2c2",
  description: "The the amount that is due to be paid on this invoice.  This is in title case.",
  defaultMessage: "Amount Due"
};

export const MSG_taxRegionLabel = {
  id: "8a1d4908-6d71-4478-ad69-f6e3f1061f4e",
  description: "We can split out taxes by region.  If they have done this for this invoice or estimate, then this label shows the amount of tax for a specific region.",
  defaultMessage: "Tax ({region})"
};

export const MSG_taxLabel = {
  id: "64d61bd1-c769-483d-9399-d0be53cb60e4",
  description: "A term for the taxable portion of an invoice item.",
  defaultMessage: "Tax",
};

export const MSG_tipLabel = {
  id: "14043b8a-1fd8-4484-8322-d40a55c525c3",
  description: "When paying an invoice, a tip is any extra money given to the service provider as a reward (or thank you) for the service.",
  defaultMessage: "Tip"
};

export const MSG_totalLabel = {
  id: "cc317188-a194-4ff2-9543-af23cb39bc90",
  description: "The total amount of the invoice or estimate after taxes are applied.  This is in label case.",
  defaultMessage: "Total"
};

export const MSG_totalTitle = {
  id: "5f05dddf-2a72-4646-ab23-df5a0a3e2276",
  description: "The total amount of the invoice or estimate after taxes are applied.  This is in title case.",
  defaultMessage: "Total"
};

export const MSG_totalColonLabel = {
  id: "894a1b6f-99ef-4949-b18d-65c973db1a25",
  description: "The total amount in a single line with a label",
  defaultMessage: "Total: {amount}"
};

export const MSG_dueColonLabel = {
  id: "50b1fcb3-1d9e-42a3-ab41-bd1632eb2e12",
  description: "The due date for an invoice on a single line with a label",
  defaultMessage: "Due: {dueDate}"
};



export const MSG_paidLabel = {
  id: "89de6ddc-9c82-43fd-a652-7dccac01acfd",
  description: "The amount that has been paid on this invoice.  This is in label case.",
  defaultMessage: "Paid"
};

export const MSG_paidTitle = {
  id: "e6e30850-5597-442f-9eb6-ff4e6931fe91",
  description: "The amount that has been paid on this invoice.  This is in title case.",
  defaultMessage: "Paid"
};

export const MSG_hourlyRateLabel = {
  id: "73f8d3b7-0a5c-46ed-a33e-7f24abf80024",
  description: "The label for the amount of money charged for one hour of work",
  defaultMessage: "Hourly rate",
};

export const MSG_hourlyRateExplanation = {
  id: "fc0a96fa-80ed-401e-9b5a-a340b628a814",
  description: "",
  defaultMessage: "Your company's hourly rate is a global setting that applies to all users who add hourly items to estimates and/or invoices. Changing this will also change it on your company profile and will only apply to future estimates and invoices."
};

export const MSG_addItemTitle = {
  id: "ad83a67c-8595-4b62-9296-e86b5876bb3a",
  description: "Displayed on a button to add an item to an invoice.",
  defaultMessage: "Add Item",
};

export const MSG_addItemsTitle = {
  id: "b85be3a2-2654-4d83-9f1e-f7d641e50c9a",
  description: "Displayed on a button to add multiple items to an invoice.",
  defaultMessage: "Add Items",
};

export const MSG_addNumItemsTitle = {
  id: "e85efdbf-049f-4410-8116-82074c4f7664",
  description: "",
  defaultMessage: "{numItems, plural, one {Add 1 Item} other {Add # Items}}"
};

export const MSG_addNumTitle = {
  id: "18f1e99c-cfb6-4123-9f9a-5109785679b3",
  description: "A shorter version of 'add # items' but without the items at the end.",
  defaultMessage: "Add {numItems}"
};

export const MSG_addItemForThisPianoTitle = {
  id: "fcaf6271-f113-4f61-8de4-e7169fdc6769",
  description: "Displayed on a button to add an item to an invoice.",
  defaultMessage: "Add Item For This Piano",
};

export const MSG_addNewItemTitle = {
  id: "2d20a9c7-4f98-40dd-8695-874206b73809",
  defaultMessage: "Add a New Item",
};

export const MSG_createdByTitle = {
  id: "3d1a1222-b572-4f9b-9e7b-ebb63c97e1fc",
  description: "This is a column header in a table that lists estimates.  The data in this particular column has the name of the person who created the estimate.",
  defaultMessage: "Created By",
};

export const MSG_createdByLabel = {
  id: "dcb532df-3558-4260-a019-aa4a006bae48",
  description: "This is a label for who created an invoice or estimate.",
  defaultMessage: "Created by"
};

export const MSG_createdDateTitle = {
  id: "9fcaacde-1fc5-4d77-998b-d39fe9f5d052",
  description: "The date that something was created.  This is in title case.",
  defaultMessage: "Created Date"
};

export const MSG_createdByUserLabel = {
  id: "3e0567c1-9d44-4a2c-89fc-fd545c002801",
  description: "The name of a user that created something (such as an estimate, invoice, etc).",
  defaultMessage: "Created by {name}"
};

export const MSG_noEmailAddressesMessage = {
  id: "9384a776-02bd-454c-90e8-3493c3e03528",
  defaultMessage: "Either this client has no contacts with email addresses or all of those contacts are marked as not wanting emails.",
};

export const MSG_previewTitle = {
  id: "8aab97cc-d055-4da9-a70e-652fc5eeda89",
  description: "A very short label for a button to preview something (an invoice, estimate, etc).",
  defaultMessage: "Preview",
};

export const MSG_attachInvoicePdfToEmailLabel = {
  id: "53a1d372-5b22-403b-ac6e-c45cb132dc61",
  description: "This is the label on a toggle switch that determines if an invoice email includes a PDF attachment or not.",
  defaultMessage: "Attach invoice PDF to the email",
};

export const MSG_attachEstimatePdfToEmailLabel = {
  id: "ef8b6b36-b72f-4939-90e3-c81cc554759c",
  description: "This is the label on a toggle switch that determines if an estimate email includes a PDF attachment or not.",
  defaultMessage: "Attach estimate PDF to the email",
};

export const MSG_noTaxesMatchingFilter = {
  id: "b5c01a06-6beb-4937-91ab-1cd13ec5a8a5",
  defaultMessage: "No taxes match this search criteria.",
};

export const MSG_noTaxesConfigured = {
  id: "3bc0426f-2203-4696-82c4-614a874d6c92",
  defaultMessage: "No taxes have been configured.  You can configure taxes in your company settings.",
};

export const MSG_addTaxesPlaceholder = {
  id: "7b06ced5-af1c-439b-9908-df08630ff81a",
  defaultMessage: "Add taxes..."
};

export const MSG_copiedLinkExplanationInvoice = {
  id: "ba5fa7df-50b7-45e8-9cf4-a6bd456d06f6",
  description: "",
  defaultMessage: "A link to this invoice has been copied.  You can share it with your client."
};

export const MSG_copiedLinkExplanationEstimate = {
  id: "b443f3a3-01ba-4083-a0c0-2f88231b7267",
  description: "",
  defaultMessage: "A link to this estimate has been copied.  You can share it with your client."
};

export const MSG_noEmailAddressesTitle = {
  id: "c441d30c-8c28-4d79-ab32-8fd3e67ad8ef",
  defaultMessage: "No Email Addresses",
};
