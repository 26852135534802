import { makeObservable, observable } from 'mobx';
import { Currency } from "@gazelle/shared/models";
import { INVOICE_PAYMENT_STATUS, INVOICE_PAYMENT_TYPE } from '@gazelle/shared/enums';
import * as moment from 'moment-timezone';

class InvoicePayment {
  id: string = null;
  type: INVOICE_PAYMENT_TYPE = null;
  status: INVOICE_PAYMENT_STATUS = null;
  failureReason: string = null;
  amount: number = null;
  currency: Currency = null;
  notes: string = null;
  tipTotal: number = null;
  paidAt: moment.Moment = null;
  createdAt: moment.Moment = null;
  paymentDetails: string = null;

  constructor(attrs: any) {
    makeObservable(this, {
      id: observable,
      type: observable,
      status: observable,
      failureReason: observable,
      amount: observable,
      currency: observable,
      notes: observable,
      tipTotal: observable,
      paidAt: observable,
      createdAt: observable,
      paymentDetails: observable
    });

    this.id = attrs.id;
    this.type = attrs.type;
    this.status = attrs.status;
    this.failureReason = attrs.failureReason;
    this.amount = attrs.amount;
    this.currency = attrs.currency as Currency;
    this.notes = attrs.notes;
    this.tipTotal = attrs.tipTotal;
    this.paidAt = moment(attrs.paidAt);
    this.createdAt = moment(attrs.createdAt);
    this.paymentDetails = attrs.paymentDetails;
  }
}

const INVOICE_PAYMENT_ALL_GQL_QUERY_FIELDS = `
  id type status failureReason amount currency { code symbol label divisor decimalDigits } notes tipTotal paidAt paymentDetails
`;

export { InvoicePayment, INVOICE_PAYMENT_ALL_GQL_QUERY_FIELDS };
