import { EVENT_RECURRENCE_MONTHLY_TYPE } from "../enums";
import { formatMessage } from "./intl";
import { MSG_eventRepetitionDatesLabel, MSG_eventRepetitionWeekdaysLabel } from "../strings";

// Use this if you want to iterate over all the types (e.g. for a <select>)
const SortedEventRecurrenceMonthlyTypes = [
  EVENT_RECURRENCE_MONTHLY_TYPE.DATES,
  EVENT_RECURRENCE_MONTHLY_TYPE.WEEKDAYS,
];

const EventRecurrenceMonthlyTypeInfo: {[key: string]: {type: EVENT_RECURRENCE_MONTHLY_TYPE, getLabel: () => string}} = {
  [EVENT_RECURRENCE_MONTHLY_TYPE.DATES]: {
    type: EVENT_RECURRENCE_MONTHLY_TYPE.DATES,
    getLabel: () => formatMessage(MSG_eventRepetitionDatesLabel),
  },
  [EVENT_RECURRENCE_MONTHLY_TYPE.WEEKDAYS]: {
    type: EVENT_RECURRENCE_MONTHLY_TYPE.WEEKDAYS,
    getLabel: () => formatMessage(MSG_eventRepetitionWeekdaysLabel),
  },
};

export { EventRecurrenceMonthlyTypeInfo, SortedEventRecurrenceMonthlyTypes };
