import { RootStore } from "./root_store";
import { action, makeObservable, observable } from "mobx";
import { PrivateDashboardMetricsTimeframeSummaryQuery } from "../graphql/private/dashboard";
import { Moment } from 'moment-timezone';
import { IDashboardMetricsData } from "../interfaces";
import { PIANO_TYPE } from "../enums";

const moment = require('moment-timezone');

// This fetches just the summary data from the historicalTimeframe
// section of the dashboardMetrics query.  It does not get the
// timeframeData or live metrics.
export class DashboardMetricsTimeframeSummaryStore {
  rootStore: RootStore;
  startOn: Moment;
  endOn: Moment;
  prevStartOn: Moment;
  prevEndOn: Moment;
  userId?: string;

  isDataLoaded: boolean = false;
  isFetching: boolean = false;
  fetchedData: IDashboardMetricsData;
  previousClientsCreated: number = 0;
  previousAppointments: number = 0;

  constructor(rootStore: RootStore, startOn: string, endOn: string, prevStartOn: string, prevEndOn: string, userId?: string) {
    this.rootStore = rootStore;
    this.setTimeframe(startOn, endOn, prevStartOn, prevEndOn, userId);
    this.fetchedData = {};
    makeObservable(this, {
      startOn: observable,
      endOn: observable,
      prevStartOn: observable,
      prevEndOn: observable,
      userId: observable,
      isDataLoaded: observable,
      isFetching: observable,
      fetchedData: observable,
      previousClientsCreated: observable,
      previousAppointments: observable,
      setTimeframe: action,
    });
  }

  setTimeframe(s: string, e: string, ps: string, pe: string, uid?: string) {
    this.startOn = moment(s);
    this.endOn = moment(e);
    this.prevStartOn = moment(ps);
    this.prevEndOn = moment(pe);
    this.userId = uid;
  }

  async fetch() {
    this.isFetching = true;

    const response = await this.rootStore.apolloClient.query({
      query: PrivateDashboardMetricsTimeframeSummaryQuery,
      fetchPolicy: 'network-only',
      variables: {
        startOn: this.startOn.format('YYYY-MM-DD'),
        endOn: this.endOn.format('YYYY-MM-DD'),
        prevStartOn: this.prevStartOn.format('YYYY-MM-DD'),
        prevEndOn: this.prevEndOn.format('YYYY-MM-DD'),
        userId: this.userId
      }
    });

    const data = response.data?.dashboardMetrics;
    let newFetchedData: IDashboardMetricsData = {};

    // HISTORICAL TIMEFRAME
    if (data.historicalTimeframe) {
      newFetchedData.historicalTimeframe = {};
      if (data.historicalTimeframe.appointments) {
        this.previousAppointments = data.previousTimeframe.appointments.totalCount;
        newFetchedData.historicalTimeframe.appointments = {
          clientsScheduledCount: data.historicalTimeframe.appointments.clientsScheduledCount,
          totalCount: data.historicalTimeframe.appointments.totalCount,
          noShowCount: data.historicalTimeframe.appointments.noShowCount,
          pianosServicedCount: data.historicalTimeframe.appointments.pianosServicedCount,
          pianoTypesServicedCounts: data.historicalTimeframe.appointments.pianoTypesServicedCounts
            .map((d: any) => ({type: d.type, count: d.count}))
            .sort((a: any, b: any) => b.count - a.count),
        };
      }
      if (data.historicalTimeframe.clientsCreated) {
        this.previousClientsCreated = data.previousTimeframe.clientsCreated.totalCount;
        newFetchedData.historicalTimeframe.clientsCreated = {
          totalCount: data.historicalTimeframe.clientsCreated.totalCount,
          currentlyActive: data.historicalTimeframe.clientsCreated.currentlyActive,
          currentlyInactive: data.historicalTimeframe.clientsCreated.currentlyInactive,
          currentlyNew: data.historicalTimeframe.clientsCreated.currentlyNew,
          currentlyProspect: data.historicalTimeframe.clientsCreated.currentlyProspect,
        };
      }
      if (data.historicalTimeframe.estimatesCreated) {
        newFetchedData.historicalTimeframe.estimatesCreated = {
          totalCount: data.historicalTimeframe.estimatesCreated.totalCount,
          convertedCount: data.historicalTimeframe.estimatesCreated.convertedCount,
          currentlyUnexpiredCount: data.historicalTimeframe.estimatesCreated.currentlyUnexpiredCount,
          convertedInvoiceTotal: data.historicalTimeframe.estimatesCreated.convertedInvoiceTotal
            .map((d: any) => ({currency: d.currency, value: d.value}))
            .sort((a: any, b: any) => b.value - a.value),
          estimatedTotal: data.historicalTimeframe.estimatesCreated.estimatedTotal
            .map((d: any) => ({currency: d.currency, value: d.value}))
            .sort((a: any, b: any) => b.value - a.value),
        };
      }
      if (data.historicalTimeframe.invoicesCreated) {
        newFetchedData.historicalTimeframe.invoicesCreated = {
          totalCount: data.historicalTimeframe.invoicesCreated.totalCount,
          invoicesQuickbooksSyncedCount: data.historicalTimeframe.invoicesCreated.invoicesQuickbooksSyncedCount,
          paymentsCount: data.historicalTimeframe.invoicesCreated.paymentsCount,
          currentlyDueTotal: data.historicalTimeframe.invoicesCreated.currentlyDueTotal
            .map((d: any) => ({currency: d.currency, value: d.value}))
            .sort((a: any, b: any) => b.value - a.value),
          invoicedTotal: data.historicalTimeframe.invoicesCreated.invoicedTotal
            .map((d: any) => ({currency: d.currency, value: d.value}))
            .sort((a: any, b: any) => b.value - a.value),
          paymentsTotal: data.historicalTimeframe.invoicesCreated.paymentsTotal
            .map((d: any) => ({currency: d.currency, value: d.value}))
            .sort((a: any, b: any) => b.value - a.value),
        };
      }
      if (data.historicalTimeframe.pianosCreated) {
        newFetchedData.historicalTimeframe.pianosCreated = {
          averageAge: data.historicalTimeframe.pianosCreated.averageAge,
          totalCount: data.historicalTimeframe.pianosCreated.totalCount,
          currentlyActive: data.historicalTimeframe.pianosCreated.currentlyActive,
          currentlyInactive: data.historicalTimeframe.pianosCreated.currentlyInactive,
          currentlyInTemporaryStorage: data.historicalTimeframe.pianosCreated.currentlyInTemporaryStorage,
          currentlyUnderRestoration: data.historicalTimeframe.pianosCreated.currentlyUnderRestoration,
          types: data.historicalTimeframe.pianosCreated.types
            .filter((d: any) => d.type !== PIANO_TYPE.UNKNOWN)
            .map((d: any) => ({type: d.type, count: d.count}))
            .sort((a: any, b: any) => b.count - a.count),
        };
      }
      if (data.historicalTimeframe.reminders) {
        newFetchedData.historicalTimeframe.reminders = {
          totalCount: data.historicalTimeframe.reminders.emailCount + data.historicalTimeframe.reminders.phoneCount + data.historicalTimeframe.reminders.smsCount,
          emailCount: data.historicalTimeframe.reminders.emailCount,
          phoneCount: data.historicalTimeframe.reminders.phoneCount,
          smsCount: data.historicalTimeframe.reminders.smsCount,
        };
      }

      if (data.historicalTimeframe.pianosDue) {
        newFetchedData.historicalTimeframe.pianosDue = data.historicalTimeframe.pianosDue
          .map((d: any) => ({date: d.date, count: d.count, scheduledCount: d.scheduledCount}))
          .sort((a: any, b: any) => a.date > b.date ? -1 : 1);
      }
    }

    this.fetchedData = newFetchedData;
    this.isFetching = false;
    this.isDataLoaded = true;
  }
}
