import { REMINDER_TYPE } from "../enums";
import { formatMessage } from "./intl";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faComments, faEnvelope, faPhone } from "@fortawesome/pro-solid-svg-icons";
import { GREEN_COLOR, ORANGE_COLOR, TEAL_COLOR } from "../colors";
import {
  MSG_reminderTypeCall,
  MSG_reminderTypeCallShort,
  MSG_reminderTypeEmail,
  MSG_reminderTypeEmailShort,
  MSG_reminderTypeSms,
  MSG_reminderTypeSmsShort
} from "../strings";

const SortedReminderTypes = [
  REMINDER_TYPE.EMAIL,
  REMINDER_TYPE.SMS,
  REMINDER_TYPE.CALL,
];

const ReminderTypeInfo: {[key: string]: {type: REMINDER_TYPE, getLabel: () => string, getShortLabel: () => string, color: string, icon: IconProp}} = {
  [REMINDER_TYPE.EMAIL]: {
    type: REMINDER_TYPE.EMAIL,
    getLabel: () => formatMessage(MSG_reminderTypeEmail),
    getShortLabel: () => formatMessage(MSG_reminderTypeEmailShort),
    icon: faEnvelope,
    color: GREEN_COLOR,
  },
  [REMINDER_TYPE.SMS]: {
    type: REMINDER_TYPE.SMS,
    getLabel: () => formatMessage(MSG_reminderTypeSms),
    getShortLabel: () => formatMessage(MSG_reminderTypeSmsShort),
    icon: faComments,
    color: TEAL_COLOR,
  },
  [REMINDER_TYPE.CALL]: {
    type: REMINDER_TYPE.CALL,
    getLabel: () => formatMessage(MSG_reminderTypeCall),
    getShortLabel: () => formatMessage(MSG_reminderTypeCallShort),
    icon: faPhone,
    color: ORANGE_COLOR,
  },
};

export { SortedReminderTypes, ReminderTypeInfo };
