import { action, computed, makeObservable, ObservableMap } from 'mobx';
import { rootStoreInstance } from '@client_scheduler/modules/root';
import { MasterServiceItem, Piano } from '@core';
import * as moment from "moment-timezone";
import { EstimateTierItem } from "@gazelle/shared/models";

class PianoWrapper {
  piano: Piano;
  services: ObservableMap<string, string>;
  estimateItems: ObservableMap<string, EstimateTierItem>;

  constructor(piano: Piano) {
    this.piano = piano;
    this.services = new ObservableMap<string, string>();
    this.estimateItems = new ObservableMap<string, EstimateTierItem>();

    makeObservable(this, {
      clear: action,
      clone: action,
      set: action,
      unset: action,
      defaultService: computed,
      numServices: computed,
      totalDuration: computed,
      totalAmount: computed
    });
  }

  clear() {
    this.services.clear();
    this.estimateItems.clear();
  }

  clone(srcWrapper: PianoWrapper) {
    this.piano.id = srcWrapper.piano.id;
    this.piano.type = srcWrapper.piano.type;
    this.piano.make = srcWrapper.piano.make;
    this.piano.model = srcWrapper.piano.model;
    this.piano.location = srcWrapper.piano.location;
    this.piano.year = srcWrapper.piano.year;

    if (srcWrapper.piano.calculatedLastService) {
      this.piano.calculatedLastService = moment(srcWrapper.piano.calculatedLastService);
    } else {
      this.piano.calculatedLastService = null;
    }

    if (srcWrapper.piano.calculatedNextService) {
      this.piano.calculatedNextService = moment(srcWrapper.piano.calculatedNextService);
    } else {
      this.piano.calculatedNextService = null;
    }

    this.services.clear();
    srcWrapper.services.forEach((value, key) => {
      this.services.set(key, value);
    });

    this.estimateItems.clear();
    srcWrapper.estimateItems.forEach((value, key) => {
      this.estimateItems.set(key, value);
    });
  }

  set(item: MasterServiceItem | EstimateTierItem) {
    // If isMultiChoice is false, we have a radio button situation.  Those
    // are indicated by using the group.id as the key, and saying which item
    // in that group was selected.  Otherwise, we index by the item id
    if (item instanceof MasterServiceItem) {
      if (item.masterServiceGroup && !item.masterServiceGroup.isMultiChoice) {
        this.services.set(item.masterServiceGroup.id, item.id);
      } else {
        this.services.set(item.id, item.id);
      }
    } else {
      this.estimateItems.set(item.id, item);
    }
  }

  unset(item: MasterServiceItem | EstimateTierItem) {
    if (item instanceof MasterServiceItem) {
      this.services.delete(item.id);
    } else {
      this.estimateItems.delete(item.id);
    }
  }

  get defaultService(): MasterServiceItem {
    let item: MasterServiceItem = null;
    this.services.forEach((value) => {
      let thisItem = rootStoreInstance.masterServiceItems.get(value);
      if (!item || (!item.isTuning && thisItem.isTuning)) {
        item = thisItem;
      }
    });
    return item;
  }

  get numServices() {
    return Array.from(this.services.keys()).length + Array.from(this.estimateItems.keys()).length;
  }

  get totalDuration() {
    let duration: number = 0;
    this.services.forEach((itemId: string) => {
      duration += rootStoreInstance.masterServiceItems.get(itemId).duration;
    });
    this.estimateItems.forEach(item => {
      duration += Math.round(item.duration * (item.quantity / 100));
    });
    return duration;
  }

  get totalAmount() {
    let amount: number = 0;
    this.services.forEach((itemId: string) => {
      const item = rootStoreInstance.masterServiceItems.get(itemId);
      if (item.type === 'LABOR_HOURLY') {
        amount += ((item.duration / 60) * item.amount);
      } else {
        amount += item.amount;
      }
    });
    this.estimateItems.forEach(item => {
      amount += item.total;
    });
    return amount;
  }
}

export { PianoWrapper };
