import { EstimateTierItem, InvoiceItem, Localization, MasterServiceItem, ServiceLibraryItem } from "../models";
import { formatMessage, getLocaleStoreInstance } from "./intl";
import {
  MSG_atAmount,
  MSG_atEach,
  MSG_atHour,
  MSG_noTimeAdded,
  MSG_pricePerHour,
  MSG_pricePerKilometer,
  MSG_pricePerMile,
  MSG_pricePerUnit
} from "../strings";
import { formatCurrency } from "./numbers";
import { INVOICE_ITEM_TYPE, MASTER_SERVICE_ITEM_TYPE } from "../enums";
import * as React from "react";
import { GazelleDuration } from "../components/GazelleDateTime";

export function formatItemQuantity(item: InvoiceItem | EstimateTierItem | MasterServiceItem | ServiceLibraryItem, options?: {summarize?: boolean, localization?: Localization}) {
  const localization = options?.localization || null;
  const summarize = options?.summarize || false;
  const distanceUnit: string = 'mi';
  let str = "";

  let quantity: number = null;
  let amount: number = null;
  let type: MASTER_SERVICE_ITEM_TYPE | INVOICE_ITEM_TYPE = null;

  if (item instanceof EstimateTierItem) {
    amount = item.amount;
    type = item.type;
    if (item.type === MASTER_SERVICE_ITEM_TYPE.LABOR_HOURLY) {
      quantity = item.quantity * (item.duration / 60);
    } else {
      quantity = item.quantity;
    }
  } else if (item instanceof MasterServiceItem) {
    amount = item.amount;
    type = item.type;
    if (item.type === MASTER_SERVICE_ITEM_TYPE.LABOR_HOURLY) {
      quantity = (item.duration / 60) * 100;
    } else {
      quantity = 100;
    }
  } else if (item instanceof ServiceLibraryItem) {
    type = item.type;
    if (item.type === MASTER_SERVICE_ITEM_TYPE.LABOR_HOURLY) {
      quantity = (item.duration / 60) * 100;
    } else {
      quantity = 100;
    }
  } else if (item instanceof InvoiceItem) {
    type = item.type;
    amount = item.amount;
    quantity = item.quantity;
  } else {
    return ''; // should never get here.
  }

  if (['HOURLY', 'LABOR_HOURLY'].indexOf(type) >= 0) {
    str = formatMessage(MSG_pricePerHour, {quantity: quantity / 100});
    if (!summarize && amount !== null && amount !== undefined) {
      str += ' ' + formatMessage(MSG_atHour, {amount: formatCurrency(amount)}, localization?.locale);
    }
  } else if (['MILEAGE'].indexOf(type) >= 0) {
    if (distanceUnit === 'mi') str = formatMessage(MSG_pricePerMile, {quantity: quantity / 100}, localization?.locale);
    if (distanceUnit === 'km') str = formatMessage(MSG_pricePerKilometer, {quantity: quantity / 100}, localization?.locale);
    if (!summarize && amount !== null && amount !== undefined) {
      str += ' ' + formatMessage(MSG_atAmount, {unit: distanceUnit, amount: formatCurrency(amount, localization?.numberFormat, localization?.currencyFormat)}, localization?.locale);
    }
  } else if (['EXPENSE', 'LABOR_FIXED_RATE', 'OTHER'].indexOf(type) >= 0) {
    str = formatMessage(MSG_pricePerUnit, {quantity: quantity / 100}, localization?.locale);
    if (!summarize && amount !== null && amount !== undefined) {
      str += ' ' + formatMessage(MSG_atEach, {amount: formatCurrency(amount, localization?.numberFormat, localization?.currencyFormat)}, localization?.locale);
    }
  }

  return str;
}

interface IItemQuantityProps {
  localization?: Localization;
  item: EstimateTierItem | MasterServiceItem | ServiceLibraryItem;
  textContainerProps?: any;
  textContainer?: any;
  blockWrapper?: any;
  showNoDuration?: boolean;
  hideDuration?: boolean;
  singleLine?: boolean;
}
const Wrapper = (props: any) => <span>{props.children}</span>;
const BlockWrapper = (props: any) => <div className="text-small text-muted">{props.children}</div>;
export class ItemQuantity extends React.Component<IItemQuantityProps> {
  render() {
    const localization = this.props.localization;
    const item = this.props.item;

    let duration: number;
    if (item instanceof EstimateTierItem) {
      if (item.type === MASTER_SERVICE_ITEM_TYPE.LABOR_HOURLY) {
        duration = null;
      } else {
        duration = item.duration;
      }
    } else if (item instanceof MasterServiceItem) {
      duration = item.duration;
    } else {
      duration = item.duration;
    }

    const Container = this.props.textContainer || getLocaleStoreInstance().getTextComponent() || Wrapper;
    const BlockContainer = this.props.blockWrapper || getLocaleStoreInstance().getTextComponent() || BlockWrapper;

    let durationEl: any = formatMessage(MSG_noTimeAdded, localization?.locale);
    if (duration) {
      durationEl = <GazelleDuration minutes={duration} expanded localization={localization}/>;
    }
    if (this.props.hideDuration) {
      durationEl = null;
    }

    return (
      <>
        <BlockContainer>
          <Container fontSize={15} className="text-nowrap" {...this.props.textContainerProps}>
            {formatItemQuantity(item, {localization})}

            {durationEl && this.props.singleLine && item.type !== MASTER_SERVICE_ITEM_TYPE.LABOR_HOURLY && (
              <React.Fragment>
                {' — '}{durationEl}
              </React.Fragment>)}
          </Container>
        </BlockContainer>

        {durationEl && !this.props.singleLine && item.type !== MASTER_SERVICE_ITEM_TYPE.LABOR_HOURLY && (
          <BlockContainer>
            <Container {...this.props.textContainerProps}>
              {durationEl}
            </Container>
          </BlockContainer>)}
      </>
    );
  }
}
