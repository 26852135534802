import * as React from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { MSG_okTitle } from "@gazelle/shared/strings";
import { formatMessage } from "@gazelle/shared/utils";
import { PropsWithChildren } from "react";

interface IAlertModalProps extends PropsWithChildren {
  size?: string;
  isOpen: boolean;
  toggle: () => void;
}
class AlertModal extends React.Component<IAlertModalProps> {

  render() {
    return (
      <Modal size={this.props.size || 'sm'}
             isOpen={this.props.isOpen}
             toggle={this.props.toggle}>
        <ModalBody>
          {this.props.children}
        </ModalBody>
        <ModalFooter>
          <Button onClick={this.props.toggle} color="success" autoFocus>
            {formatMessage(MSG_okTitle)}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export { AlertModal };
