import { IAddressParts } from "@gazelle/shared/interfaces";

class Company implements IAddressParts {
  id: string;
  name: string;
  phoneNumber: string;
  email: string;
  website: string;

  phoneCountryCode: string;
  headerLayout: string;

  logoExists: boolean;
  logoUrl: string;

  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;

  isGdprRequired: boolean;
  settingsStripePaymentsAccountId: string | null;
  stripePublishableKey: string;
  tipsEnabled: boolean;
  tipsPublicGuiAutoselect: boolean;
  publicPrivacyPolicy: string;
  publicTermsOfService: string;

  constructor(attrs: any) {
    this.id = attrs.id;
    this.name = attrs.name;
    this.email = attrs.email;
    this.phoneNumber = attrs.phoneNumber;
    this.website = attrs.website;

    this.address1 = attrs.address1;
    this.address2 = attrs.address2;
    this.city = attrs.city;
    this.state = attrs.state;
    this.zip = attrs.zip;

    this.logoExists = attrs.logo.exists;
    this.logoUrl = attrs.logo.url;

    this.phoneCountryCode = attrs.settings.phoneCountryCode;
    this.headerLayout = attrs.settings.publicStyle.headerLayout;

    this.publicPrivacyPolicy = attrs.settings.publicPrivacyPolicy;
    this.publicTermsOfService = attrs.settings.publicTermsOfService;

    this.tipsEnabled = !!attrs.settings.invoices.tipsEnabled;
    this.tipsPublicGuiAutoselect = !!attrs.settings.invoices.tipsPublicGuiAutoselect;
    this.settingsStripePaymentsAccountId = attrs.settings.stripePayments.accountId;
    this.stripePublishableKey = attrs.settings.stripePayments.stripePublishableKey;

    this.isGdprRequired = attrs.isGdprRequired;
  }
}

const COMPANY_GQL_QUERY_FIELDS = `
  id name phoneNumber email website
  logo {exists url}
  address1 address2 city state zip
  isGdprRequired
  settings {
    phoneCountryCode
    publicPrivacyPolicy publicTermsOfService
    publicStyle {headerLayout}
    defaultClientLocalization {
      locale dateFormatLocale timeFormatLocale
      numberFormat currencyFormat firstDayOfWeek
    }
    defaultCurrency { code symbol label divisor decimalDigits }
    invoices { tipsEnabled tipsPublicGuiAutoselect }
    stripePayments { accountId stripePublishableKey }
  }
`;

const COMPANY_LOCALIZATION_FIELDS = `
  allLocalizations {
    id isUserDefault isClientDefault
    locale dateFormatLocale timeFormatLocale
    numberFormat currencyFormat firstDayOfWeek
    dateFormat {
      date { moment }
      weekdayDate { moment }
      monthYear { moment }
      dateTimeSeparator
    }
    timeFormat {
      time { moment }
      timezoneTime { moment }
    }
  }
  allSupportedLocales {
    locale label defaultNumberFormat defaultCurrencyFormat defaultFirstDayOfWeek isLocaleDefault
    monthYearFormat { moment }
    dateFormat { moment }
    weekdayDateFormat { moment }
    timeFormat { moment }
    timezoneTimeFormat { moment }
    dateTimeSeparator
  }
`;

export { Company, COMPANY_GQL_QUERY_FIELDS, COMPANY_LOCALIZATION_FIELDS };
