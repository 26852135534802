export const MSG_invoicesTitle = {
  id: "bf2730a7-6be8-4560-b4eb-9ab5dce74979",
  description: "A title for a list of invoices.",
  defaultMessage: "Invoices"
};

export const MSG_invoicesLabel = {
  id: "151465e5-1733-439c-9700-578b4484ea0b",
  description: "A label for a list of invoices",
  defaultMessage: "Invoices"
};

export const MSG_searchInvoicesTitle = {
  id: "3044a752-441a-4eeb-b6bf-2b1c29aaa17c",
  description: "The button that lets them search invoices by name, number, etc.",
  defaultMessage: "Search Invoices"
};

export const MSG_invoicesWithCompanyNameTitle = {
  id: '3310d77d-2b2f-4ca7-bb62-0dcbf3f1db5b',
  description: 'The page title displayed in the browser tab or window header.  This should have the company name first, followed by "Invoice 123"',
  defaultMessage: '{companyName} - Invoice {num}'
};

export const MSG_invoiceTitle = {
  id: "12032ab4-5d93-455d-b5d3-f82516108cce",
  defaultMessage: "Invoice",
};

export const MSG_invoiceDetailsTitle = {
  id: "7f6544b3-c93f-467e-9e44-02c64ccbd67e",
  defaultMessage: "Invoice Details",
};

export const MSG_invoiceNumberTitle = {
  id: "806d1195-4723-4cfb-aa10-06779a62131d",
  description: "The title for the screen where they can view or edit an invoice.  The title will include the invoice number.",
  defaultMessage: "Invoice #{invoiceNumber}",
};

export const MSG_newInvoiceTitle = {
  id: "94677527-b378-4405-be3f-e7de65a92d56",
  defaultMessage: "New Invoice"
};

export const MSG_payNowTitle = {
  id: "736f486a-6848-4970-9a42-0ade189631a9",
  description: "A button label that is pressed to pay an invoice.",
  defaultMessage: "Pay Now",
};

export const MSG_continueToPayTitle = {
  id: "48c9e624-6ec1-487d-b9cc-eec8613fce4f",
  description: "A button title on a dialog box where they are asked a few questions then continue on to collect payment for an invoice.",
  defaultMessage: "Continue To Pay",
};

export const MSG_saveAndProcessPaymentTitle = {
  id: "a27b6df4-63bf-4799-8abc-33305929f1c1",
  description: "The title for a button that saves an invoice and then will prompt them to process a credit card payment",
  defaultMessage: "Save & Process Payment",
};

export const MSG_receiptEmailLabel = {
  id: "dbd7c7cf-9d8d-4c88-8bfb-4fb75c536649",
  description: "A short heading for a modal dialog box where the user chooses which email address to send a payment receipt to.",
  defaultMessage: "Receipt email",
};

export const MSG_billedToLabelWithColon = {
  id: "29d464e7-b33e-4c3b-af79-df697bc8d3d7",
  description: "This is a label that is used when an invoice has an alternate billing contact.  For example, this would be used if the invoice is being paid for by someone else.",
  defaultMessage: "Billed to:",
};

export const MSG_viewInvoiceNumberTitle = {
  id: "cc5abe81-c833-4c4c-9bac-0c23e345e2b5",
  description: "A label for a button that lets them view a specific invoice number.  Reads like 'View Invoice #1234'.",
  defaultMessage: "View Invoice #{invoiceNumber}"
};

export const MSG_servicesFromInvoiceNumber = {
  id: "346a9524-fbcb-4612-8d0c-f585c33885b2",
  description: "",
  defaultMessage: "This service history was automatically generated from invoice #{invoiceNumber}."
};


export const MSG_invoiceNumberLabel = {
  id: "f3c1fd51-9e79-4dd5-83e5-3985b6d86501",
  defaultMessage: "Invoice number",
};

export const MSG_invoiceNumberTitleShort = {
  id: "eb63a43f-b79c-48b4-a636-a8dc49a09c8f",
  description: "A short label for the invoice number.  This is in title case.",
  defaultMessage: "Number",
};

export const MSG_invoiceDateLabel = {
  id: "41333bd9-86ca-409a-8358-1d84a72f38df",
  description: "The date that the invoice was issued in label case.",
  defaultMessage: "Invoice date"
};

export const MSG_invoiceDateTitle = {
  id: "02730a1f-ad58-40fe-951c-409736f5445e",
  description: "The date that the invoice was issued in title case.",
  defaultMessage: "Invoice Date"
};

export const MSG_invoicedAfterOrOnLabel = {
  id: "4a76c019-af9f-4b47-a451-8f00b4c60334",
  description: "A label for selecting invoices that were invoiced after or on a certain date.",
  defaultMessage: "Invoiced after (or on)"
};

export const MSG_invoicedBeforeOrOnLabel = {
  id: "a83ed2d0-e359-4f21-a6d4-65c96cb296f8",
  description: "A label for selecting invoices that were invoiced before or on a certain date.",
  defaultMessage: "Invoiced before (or on)"
};

export const MSG_invoiceDueDateLabel = {
  id: "c521807f-e0c4-4acd-b982-ec7859315d38",
  description: "The date that an invoice is due to be paid in label case.",
  defaultMessage: "Due date"
};

export const MSG_invoiceDueDateTitle = {
  id: "01f9d28c-8eea-45d3-8544-2629c814fe29",
  description: "The date that an invoice is due to be paid in title case.",
  defaultMessage: "Due Date"
};

export const MSG_invoiceDueAfterOrOnLabel = {
  id: "dfcccefc-448f-48e6-8535-122bba33f52f",
  description: "A label for selecting invoices that are due after or on a certain date.",
  defaultMessage: "Due after (or on)"
};

export const MSG_invoiceDueBeforeOrOnLabel = {
  id: "d6930c5e-b804-4f24-b662-018be56d0715",
  description: "A label for selecting invoices that are due before or on a certain date.",
  defaultMessage: "Due before (or on)"
};

export const MSG_paymentHistoryTitle = {
  id: "d58d85ce-5f44-4e24-8f00-725f42f4e20c",
  description: "The history of payments on an invoice.",
  defaultMessage: "Payment History"
};

export const MSG_addTipLabel = {
  id: "33b494ce-0c9f-4815-8df6-5efd241d8e34",
  description: "When paying an invoice, the tip amount is any extra money given to the service provider as a reward (or thank you) for the service.",
  defaultMessage: "Add tip"
};

export const MSG_otherTipAmountLabel = {
  id: "7d44cd9d-9d99-4204-9851-b44e3ad97cae",
  description: "When paying an invoice, the tip amount is any extra money given to the service provider as a reward (or thank you) for the service.",
  defaultMessage: "Other tip amount"
};

export const MSG_includesTipAmount = {
  id: "f4306feb-80dc-48ea-99b2-a131c91c765b",
  description: "When paying an invoice, the tip amount is any extra money given to the service provider as a reward (or thank you) for the service.",
  defaultMessage: "Includes {tipAmount} tip.",
};

export const MSG_paymentDetails = {
  id: 'b7194911-e249-4431-beab-5e9186e15e88',
  description: "A message displayed for a payment.  paymentAmount is the amount paid (including currency symbols) and paymentDetails will be a note about how they paid.",
  defaultMessage: "Payment of {paymentAmount} via {paymentDetails} on {paymentDate}."
};

export const MSG_paymentDetailsNoDate = {
  id: '054f79e8-9875-4839-9c48-edb0d8f9460c',
  description: "A message displayed for a payment.  paymentAmount is the amount paid (including currency symbols) and paymentDetails will be a note about how they paid.",
  defaultMessage: "Payment of {paymentAmount} via {paymentDetails}."
};

export const MSG_paidDetails = {
  id: '6bd43611-6fc3-41e6-9dca-782454f3bbf7',
  description: "A message displayed for successful payments.  paymentAmount is the amount paid (including currency symbols), paymentType will be something like Cash, Check, or Credit Card, and paymentDate will be the date of the payment.",
  defaultMessage: "Paid {paymentAmount} by {paymentType} on {paymentDate}."
};

export const MSG_paidDetailsNoDate = {
  id: 'e80f4f99-b006-444e-8123-156dbaabfd82',
  description: "A message displayed for successful payments.  paymentAmount is the amount paid (including currency symbols) and paymentType will be something like Cash, Check, or Credit Card.",
  defaultMessage: "Paid {paymentAmount} by {paymentType}."
};

export const MSG_paymentFailureReason = {
  id: 'd4a26b29-d8cf-4818-b865-d7df7226bb7f',
  description: "A message displayed for failed payments.  failureReason will be replaced with the reason for the failure.",
  defaultMessage: "Failure reason: {failureReason}"
};

export const MSG_onlinePaymentsDisabled = {
  id: "de224c73-6ad5-4257-8c6f-3309aec5bdff",
  defaultMessage: "Online payments are temporarily disabled at this time."
};

export const MSG_paymentsWhatsNextExplanation = {
  id: "78259aa4-d757-4b01-b1b4-d7deac8f47ca",
  defaultMessage: "Simply send an invoice or collect payment using your Gazelle app.  All customers (including past invoices that are still unpaid) will now see a \"Pay Now\" button on their invoice."
};

export const MSG_teamMemberPaymentDefaultTitle = {
  id: "32aef114-bb2a-41f8-8a21-a9a8c4cbfa96",
  description: "The default option for how your team members will pay",
  defaultMessage: "Team Member Payment Default"
};

export const MSG_teamMemberPaymentDefaultExplanation = {
  id: "10dfa28f-44ee-4764-9e95-d62ce9d64d00",
  defaultMessage: "This default payment method will be selected each time one of your team members enters a payment on an invoice. They will always be given the option to select a different payment type."
};

export const MSG_clientPaymentOptionsTitle = {
  id: "1c04af33-1d1f-44e0-8def-a25ac008365c",
  description: "The default option for how clients will pay",
  defaultMessage: "Client Payment Options"
};

export const MSG_clientPaymentOptionsExplanation = {
  id: "c5607a7a-52f7-4387-b92e-5c082cd7adbb",
  defaultMessage: "This option determines whether or not your clients can pay online for new invoices by default.  This can be changed on each individual invoice."
};

export const MSG_youreAllSet = {
  id: "4e9b635e-bade-409c-b294-77e6dc61f09d",
  description: "This is a status message letting them know that everything is configured properly and they're ready to start using payment processing.",
  defaultMessage: "You're all set!",
};

export const MSG_editInvoiceTitle = {
  id: "386c7921-be62-4459-9248-bd56933ea12c",
  defaultMessage: "Edit Invoice"
};

export const MSG_editInvoiceNumberTitle = {
  id: "476f6219-2a87-4483-8323-99ca577b57d4",
  description: "A short title for editing an invoice.  In English we are using the # symbol to mean number.  It would look like 'Edit Invoice #1032' and would be read as 'Edit Invoice Number 1032'.",
  defaultMessage: "Edit Invoice #{invoiceNumber}",
};

export const MSG_editInvoiceDetailsTitle = {
  id: "6a7dd104-be98-44bd-9359-4cf11908c3db",
  description: "",
  defaultMessage: "Edit Invoice Details",
};

export const MSG_addInvoiceTitle = {
  id: "6c738921-be62-4459-9248-9bd5633ea12c",
  defaultMessage: "Add Invoice"
};

export const MSG_acceptAllPaymentMethodsLabel = {
  id: "2d8010a2-99b2-4399-b8ea-26a9b064a4b0",
  defaultMessage: "All payment methods are accepted for this invoice."
};

export const MSG_acceptElectronicPayment = {
  id: "3357a2da-688c-47bc-8976-01859ccbb933",
  defaultMessage: "Accept online payments through Stripe for this invoice?"
};

export const MSG_deletePaymentAreYouSure = {
  id: "15259daf-d9b5-4659-b54f-9daff298aa77",
  defaultMessage: "Are you sure you want to permanently delete the record of this payment?",
};

export const MSG_deletePaymentInQuickBooksOnlineAreYouSure = {
  id: "21482817-82b9-4c24-8235-5a87301fa3b1",
  defaultMessage: "This payment has been synced to QuickBooks Online.  Deleting it from Gazelle will also immediately delete the QuickBooks Online Payment.  This action is permanent and cannot be undone.  Are you sure you want to delete this payment?"
};

export const MSG_deletePaymentTitle = {
  id: "30666136-b11e-4cf1-bd12-f798ea4d2231",
  description: "A label to remove an invoice payment.",
  defaultMessage: "Delete Payment",
};

export const MSG_enterPaymentAmount = {
  id: "5ad463e1-2cbc-452e-89ed-5473fd04c60c",
  description: "An error message that is displayed to the user if they try to add an invoice payment without entering an amount.",
  defaultMessage: "Enter an amount before saving a payment.",
};

export const MSG_addItemsToInvoice = {
  id: "4b5b7b03-2375-4101-b632-efd7ca45471a",
  description: "",
  defaultMessage: "Add items to this invoice.",
};

export const MSG_cloneLastInvoiceTitle = {
  id: "52a14f6c-1a77-4a78-81cb-3b07b84c6a12",
  defaultMessage: "Clone Last Invoice",
};

export const MSG_cloneLastInvoiceFrom = {
  id: "60cb53d4-f4c3-46bb-9173-5386123b25df",
  description: "This is a shorthand notation for displaying when an invoice was sent, how much it was for, and its status (e.g. open, paid, etc)",
  defaultMessage: "From {date} for {amount} ({status})"
};

export const MSG_confirmProcessPayment = {
  id: "329b754d-b931-44a9-9ba6-afee918b756e",
  defaultMessage: "You indicated that you wanted to process a card payment.  Do you want to process and record this payment of {paymentAmount} now?",
};

export const MSG_enterPaymentDetailsTitle = {
  id: "1953e85f-0c5b-4cd5-834e-f4022516982f",
  description: "A button label on a button that lets the user enter credit card or bank account information for paying an invoice.",
  defaultMessage: "Enter Payment Details",
};

export const MSG_receiptTitle = {
  id: "d6dd5632-8cb8-48bf-93e0-7e4614c90de2",
  description: "A receipt is an acknowledgement that something has been paid and will be emailed to the person paying an invoice.",
  defaultMessage: "Receipt",
};

export const MSG_collectPaymentTitle = {
  id: "d1506ab0-f0af-4ca0-9c58-ab69d9ebed2b",
  defaultMessage: "Collect Payment",
};

export const MSG_wouldYouLikeToCollectPayment = {
  id: "86df1bcb-9dbc-48ca-8240-93e9dd046e6a",
  description: "Electronic payment mean credit cards, debit cards, bank transfers, etc.",
  defaultMessage: "Would you like to collect electronic payment now?",
};

export const MSG_paymentPendingLabel = {
  id: "ed331c58-665a-4127-8a5b-801c93a54024",
  defaultMessage: "Payment pending",
};


export const MSG_promptedForPaymentAfterCompletion = {
  id: "84c67732-6e15-481c-b2e6-5966a1b1c5e1",
  defaultMessage: "You will be prompted for payment information once you finish completing this appointment.",
};

export const MSG_promptedForPaymentAfterSaving = {
  id: "39b0a868-9d12-4216-9d4c-dcedcea85f5a",
  defaultMessage: "You will be prompted for payment information once you save the invoice.",
};

export const MSG_alternateBillingTitle = {
  id: "fe2bd1d3-36a0-4216-8eb0-6d093392a10a",
  description: "This is displayed when an invoice has an alternate person who should receive the invoice.",
  defaultMessage: "Alternate Billing",
};

export const MSG_alternateBillingShortTitle = {
  id: "fd17db41-63f2-4541-ba2c-517e861e554a",
  description: "A very short abbreviation for the term \"Alternate Billing\"",
  defaultMessage: "Alt"
};

export const MSG_alternateBillingClientNameTitle = {
  id: "64d9388b-f5ae-43f2-bf69-64b4b743d8df",
  defaultMessage: "Alternate Billing {clientName}",
};

export const MSG_alternateBillingFindClientExplanation = {
  id: "68e2594a-229c-4a1c-bc2c-a2cf52c6820e",
  description: "Help text that is displayed at the top of the screen when a user is filling out an alternate recipient for an invoice.",
  defaultMessage: "Optionally populate the alternate contact information from an existing client. If you search for a client, that client's information will be filled in for the alternate billing."
};

export const MSG_netDaysLabel = {
  id: "3dfde57d-659e-4795-b9c5-62bf11a99694",
  description: "In English, if an invoice is due in 30 days, it is called 'Net 30'.  This is where that is displayed.",
  defaultMessage: "Net {days}",
};

export const MSG_netDaysDetailsLabel = {
  id: "846ff524-f2c2-4506-b2b2-fcf6b287aa28",
  description: "The number of days into the future that an invoice is due.  The value will be 10 or higher.",
  defaultMessage: "Net {days}",
};

export const MSG_netDaysExplanation = {
  id: "b2ad4cd0-7c80-464b-b16b-319b2c461284",
  description: "",
  defaultMessage: "in {days} days"
};

export const MSG_netDaysMonthsExplanation = {
  id: "8b0c9853-d7d1-4c10-8ed5-e1486f43cbce",
  description: "",
  defaultMessage: "{months, plural, one {in about 1 month} other {in about # months}}"
};

export const MSG_netDaysYearsExplanation = {
  id: "46f28964-0aba-411b-b55b-3d769641ed9c",
  description: "",
  defaultMessage: "{years, plural, one {in about 1 year} other {in about # years}}"
};

export const MSG_onInvoiceDateLabel = {
  id: "8dff6ee4-a342-4f3a-8313-c6acad1dedc6",
  description: "Text indicating an invoice is due the same day the invoice is created.",
  defaultMessage: "On invoice date",
};

export const MSG_nextDayLabel = {
  id: "e2cd9c7f-2e42-4733-a719-a5d2ed54fc3a",
  description: "Text indicating an invoice is due the day after the invoice is created.",
  defaultMessage: "Next day",
};

export const MSG_deleteInvoiceTitle = {
  id: "f521c297-8b02-4058-bceb-acf3acf73948",
  defaultMessage: "Delete Invoice",
};

export const MSG_cancelInvoiceTitle = {
  id: "37e0599a-e880-4a84-922d-274c1337120b",
  defaultMessage: "Cancel Invoice"
};

export const MSG_archiveInvoiceTitle = {
  id: "a168a09d-00ae-4ce0-b01f-b31ed9d88ead",
  defaultMessage: "Archive Invoice"
};

export const MSG_sendingDraftInvoiceNotice = {
  id: "2230dc42-b171-4e7c-87dc-9d9f631d9f7b",
  defaultMessage: "This invoice has a status of 'Draft' and cannot be sent to your client.  For your convenience, if you choose to send this invoice, it will automatically be marked as 'Open'."
};

export const MSG_customTipLabel = {
  id: "df695fb3-35c6-4e55-80e1-f6a3716f7b41",
  description: "This is a label for a place where the user can enter a custom amount for a tip.  A tip is any extra money given to the service provider as a reward (or thank you) for the service.",
  defaultMessage: "Custom tip",
};

export const MSG_amountPaidLabel = {
  id: "426cd3c7-18a5-4b16-a2bc-e846dcc79402",
  description: "The amount that was paid on a payment.  It will be followed by another line that contains the currency value so that it reads like this (across 2 lines): 'Amount paid: $100'.",
  defaultMessage: "Amount paid:",
};

export const MSG_amountToBeCharged = {
  id: "c838c8f9-cf13-4b8f-90a8-1c6018d44165",
  description: "The amount to be charged for an electronic payment.  It will be followed by another line that contains the currency value so that it reads like this (across 2 lines): 'Amount to be charged: $100'.",
  defaultMessage: "Amount to be charged:",
};

export const MSG_shortManualPaymentTitle = {
  id: "2ea4ed96-1102-4a4c-85e9-ac3eb1fd9619",
  description: "Short title for manual payment types.",
  defaultMessage: "Manual",
};

export const MSG_manualPaymentTitle = {
  id: "4f556ead-baac-47b7-b59e-3b16684c0957",
  description: "The label for the button that lets them manually record a payment that happened outside of the system",
  defaultMessage: "Manual Payment"
};

export const MSG_manualPaymentLabel = {
  id: "531f1cc4-632c-4403-a659-aebd01f222db",
  description: "The label for the input boxes where a user will manually record a payment that happened outside of the system",
  defaultMessage: "Manual payment"
};

export const MSG_quickbooksPaymentTitle = {
  id: "0c3f10d9-df5c-4b4a-af2a-8a3977210b58",
  description: "The label for when a payment was made through QuickBooks.",
  defaultMessage: "QuickBooks Payment"
};

export const MSG_stripePaymentTitle = {
  id: "fd5e013e-f22b-463d-9b02-af048d53d583",
  description: "The label for the button that lets them process a credit card (or other form) of payment using the 3rd-party payment provider Stripe.",
  defaultMessage: "Stripe Payment"
};

export const MSG_stripePaymentsLabel = {
  id: "2c9f54d2-3a1d-457c-86de-79bfc67c8b6d",
  description: "Shown when stripe payments are the default payment method.",
  defaultMessage: "Stripe payments"
};

export const MSG_stripeAccountIdLabel = {
  id: "94140903-f406-4186-bda9-d184e3b4bb2d",
  description: "A label for displaying their account ID with Stripe.",
  defaultMessage: "Stripe account ID"
};

export const MSG_noOnlinePaymentOptionLabel = {
  id: "bd28107b-7307-40b4-b260-e7cd2d59ca17",
  defaultMessage: "No Online Payment Option"
};

export const MSG_enterPaymentTitle = {
  id: "f53d6f33-2230-4a63-9af8-ee95cf8f7b15",
  description: "Text for a button label that the user will press to enter details about an invoice payment.",
  defaultMessage: "Enter Payment",
};

export const MSG_paymentTitle = {
  id: "bbcec1ed-30b1-4eb0-871c-ff48ee96c752",
  description: "A short word for an invoice payment.",
  defaultMessage: "Payment",
};

export const MSG_invoicePaymentTitle = {
  id: "9c96a049-4739-4475-b567-9d209b96d0ab",
  description: "A label for an invoice payment",
  defaultMessage: "Invoice Payment"
};

export const MSG_paymentAddedSuccessfully = {
  id: "ed744932-1013-41cd-8673-51b88fecf192",
  description: "This is displayed when a payment was successfully added to an invoice.",
  defaultMessage: "Payment successfully applied to invoice #{num}",
};

export const MSG_paymentMethodTitle = {
  id: "b03289ff-b1ad-409a-9070-9e3fbd6e67ec",
  description: "A label on a button that will be tapped to select a method of payment for an invoice.",
  defaultMessage: "Payment Method",
};

export const MSG_paymentMethodLabel = {
  id: "f2be2d37-f1bf-4735-8eea-c69d953b3111",
  description: "A label for displaying the method of payment for an invoice.",
  defaultMessage: "Payment method",
};

export const MSG_paymentPaidAtLabel = {
  id: "2fa69974-db58-44da-85ec-4a369a4d91f2",
  description: "A label for displaying the date and time a payment was paid.",
  defaultMessage: "Paid at",
};

export const MSG_createYourFirstInvoice = {
  id: "5af319d4-fc3b-4bd5-8886-4767c2f09805",
  defaultMessage: "Create your first invoice.",
};

export const MSG_invoiceTotalLabel = {
  id: "ab100c36-7fba-45cd-9c6f-65de1ebdda69",
  description: "The total monetary amount on an invoice",
  defaultMessage: "Invoice total",
};

export const MSG_invoiceItemsTitle = {
  id: "6bbc52fd-bf3c-4c7b-914c-8858e40fede0",
  description: "Plural text describing line items on an invoice.",
  defaultMessage: "Invoice Items",
};

export const MSG_summarizeAmountsPerPianoLabel = {
  id: "03c83176-c8b1-4e84-8477-9e962033523b",
  description: "A checkbox label for whether or not an invoice should summarize the amounts per piano.",
  defaultMessage: "Summarize amounts per piano",
};

export const MSG_forPianoTitle = {
  id: "0936b320-1622-4fdd-9118-fbf322b4d228",
  description: "This is a label that is shown on an invoice item that is associated with a specific piano.",
  defaultMessage: "For Piano",
};

export const MSG_removeAllItemsOnThisInvoiceTitle = {
  id: "1b12e1f3-3e50-419b-822a-d83bfc11816f",
  description: "A menu option to remove all the items from an invoice.",
  defaultMessage: "Remove All Items On This Invoice",
};

export const MSG_removeAllItemsForPianoTitle = {
  id: "07662ee2-0c2c-4c89-8642-013eaa13b25b",
  description: "A menu option to remove all the invoice items for a particular piano.",
  defaultMessage: "Remove All Items For This Piano",
};

export const MSG_removeAllMiscItemsTitle = {
  id: "261153fb-9c60-49e1-8df0-54e0e58e369f",
  description: "A menu option to remove all miscellaneous invoice items.  We consider a miscellaneous invoice item to be one that is NOT associated with a piano.",
  defaultMessage: "Remove All Misc Items",
};

export const MSG_addMiscItemTitle = {
  id: "ea6cf8c5-103d-40f9-969b-f016072012e0",
  description: "A menu option to add a miscellaneous item to an invoice.  We consider a miscellaneous invoice item to be one that is NOT associated with a piano.",
  defaultMessage: "Add Misc. Item",
};

export const MSG_addItemsForAPianoTitle = {
  id: "2dbbe9f6-b262-41ce-b4a4-4d3db666661a",
  description: "A menu option to add invoice items for a piano.",
  defaultMessage: "Add Items For a Piano",
};

export const MSG_addItemsForSpecificPianoTitle = {
  id: "2ef6e611-536c-40aa-932d-97dd9dda3b26",
  description: "A menu option to add invoice items for a specific piano.",
  defaultMessage: "Add Items For {pianoName}",
};

export const MSG_addFromEstimateTitle = {
  id: "972998eb-8d17-467d-8c72-7571d2c62e87",
  description: "A menu option to add an estimate to an invoice",
  defaultMessage: "Add From Estimate",
};

export const MSG_setDefaultTaxableHelpText = {
  id: "59f7b605-0bfe-489f-b99c-3c333dddd37c",
  description: "Help text that is displayed at the top of the screen where a user can select taxes.",
  defaultMessage: "This will change all taxable items to use the taxes you select here."
};

export const MSG_estimateInvoiceWhichOption = {
  id: "c1411a0f-b546-4b90-959d-f6fc1e1dce49",
  defaultMessage: "Which option should be used to create this invoice?"
};

export const MSG_permanentlyDeleteInvoice = {
  id: "b4a63605-68ee-4a82-8d9a-5cf62730b048",
  defaultMessage: "Permanently delete this invoice?"
};

export const MSG_permanentlyDeleteInvoiceConfirmation = {
  id: "f8296cae-9cc8-4da6-aa44-acdac6beeb9a",
  defaultMessage: "This will permanently delete this invoice.  If it has been emailed to your client and the client clicks to view the invoice the client will receive an error."
};

export const MSG_createInvoiceTitle = {
  id: "b79d9f55-83c2-4dba-95a6-fabfc56db6f5",
  defaultMessage: "Create Invoice",
};

export const MSG_sendInvoiceTitle = {
  id: "823a76c0-0a42-4df3-af92-73014d4b0ec5",
  description: "A short title for the screen where the user can send an invoice to someone.",
  defaultMessage: "Send Invoice",
};

export const MSG_invoiceHistoryTitle = {
  id: "90ff5d29-27f4-40b3-8c33-7068f41f9f6c",
  defaultMessage: "Invoice History",
};

export const MSG_removeInvoiceTitle = {
  id: "9afbdc24-b162-4f76-b42b-ec0c4f5ff564",
  defaultMessage: "Remove Invoice",
};

export const MSG_invoiceNumberAndClientTitle = {
  id: "91c3c503-6c34-436a-aed1-51f941cad631",
  defaultMessage: "Invoice #{num}, {clientName}",
};

export const MSG_invoiceError = {
  id: "721f27ff-bde5-4c3e-8227-c73f0dff76b9",
  defaultMessage: "Please call us at {phone} to get a copy of your invoice.  We apologize for the inconvenience.",
};

export const MSG_stripePaymentMethodCard = {
  id: "a38cbf0c-8302-4326-8543-963988ee0d78",
  description: "Describes a payment method by credit or debit card.",
  defaultMessage: "Cards (VISA, MasterCard, etc)"
};

export const MSG_stripePaymentMethodSepaDebit = {
  id: "0f73150e-63b2-4ec6-ab31-215b34593618",
  description: "Describes a Single Euro Payments Area (SEPA) Direct Debit, a common payment method in the European Union.",
  defaultMessage: "SEPA Direct Debit"
};

export const MSG_noHistoryEntriesForInvoice = {
  id: "30816f9f-e631-440e-a362-86b8ff01d82e",
  defaultMessage: "There are no history entries for this invoice yet.",
};

export const MSG_problemSavingThisInvoice = {
  id: "36efbd30-c4bd-4dfd-9882-532fca8addc5",
  defaultMessage: "There was a problem saving this invoice:",
};

export const MSG_noInvoiceItemsHaveBeenAddedLabel = {
  id: "14406f90-85a7-43ef-9712-32d0624bacb9",
  defaultMessage: "No invoice items have been added.",
};

export const MSG_noNotesForInvoice = {
  id: "d2377cef-d959-4417-b3cf-d99d11983747",
  description: "",
  defaultMessage: "No notes have been added to this invoice.",
};

export const MSG_noTopNotesForInvoice = {
  id: "aac4b72f-c25d-4f53-a70a-6062c6270768",
  description: "",
  defaultMessage: "No notes have been added to the top of this invoice.",
};

export const MSG_selectTaxesTitle = {
  id: "353fede1-30b3-4fce-9b47-27102edd83af",
  defaultMessage: "Select Taxes",
};

export const MSG_invoiceSearchPlaceholder = {
  id: "4ceb1055-9f2a-4cc6-b0a7-94a24d821049",
  description: "",
  defaultMessage: "Search for name, invoice number, piano, etc."
};

export const MSG_historicTaxLabel = {
  id: "b4fc7c5a-ea31-4dfd-978a-9dfa6233cfb2",
  description: "This is a label given to a tax on an invoice if the tax has changed in their company settings, but the invoice still has this old rate.",
  defaultMessage: "Historic"
};

export const MSG_historicTaxExplanation = {
  id: "58c38462-5717-400a-ab90-9aab3c5c3ef2",
  description: "",
  defaultMessage: "This tax has changed or been deleted in your invoice settings in Gazelle, but this invoice was created with this previous tax rate."
};

export const MSG_forInvoiceNumber = {
  id: "e47932c0-f2c5-48bd-9f3e-9280611fd240",
  description: "A label to say what invoice this item is related to.  Reads as: 'For invoice #123'",
  defaultMessage: "For invoice #{number}"
};

export const MSG_alreadyAddedToInvoiceTitle = {
  id: "fb85f1fa-d6c5-4ed1-b6b8-7f388f663424",
  description: "An indicator that this item has already been added to an invoice",
  defaultMessage: "Already Added To Invoice"
};

export const MSG_seeStripeForDetails = {
  id: "0de15d85-2027-4fe4-8dca-01f45a23bdc5",
  defaultMessage: "See Stripe for additional details."
};

export const MSG_showAdditionalNotes = {
  id: "d9deb578-780a-4942-9220-7cb5cd7d2ce5",
  description: "",
  defaultMessage: "Show additional notes at the top of this invoice?"
};

export const MSG_notesHeaderLabel = {
  id: "07b30505-ee6e-446c-abae-081d4c10af1d",
  description: "The label for an optional header that can go above the notes on an invoice.",
  defaultMessage: "Header (optional)"
};

export const MSG_noInvoiceRecipientsSelected = {
  id: "be2e481a-90df-49a8-8d5d-4f56a0d1faed",
  defaultMessage: "First you must select someone to send the invoice to.",
};

export const MSG_addATagToInvoiceLabel = {
  id: "f61862db-91bb-461c-b36f-bd177dc3481b",
  description: "A button that allows the user to add a tag to an invoice.",
  defaultMessage: "Enter a tag for this invoice"
};

export const MSG_paymentDateLabel = {
  id: "01e52881-d03e-4748-8ed3-a69353beeafa",
  description: "The label for the date that a payment was made.",
  defaultMessage: "Payment date"
};

export const MSG_paymentDateAfterOrOnLabel = {
  id: "38c7a6c0-4d3a-4c5f-953c-b402d32a7d41",
  description: "The label for the date that a payment was made after or on.",
  defaultMessage: "Paid after (or on)"
};

export const MSG_paymentDateBeforeOrOnLabel = {
  id: "2c2655f8-e458-4676-bf8b-67df2a0215c3",
  description: "The label for the date that a payment was made before or on.",
  defaultMessage: "Paid before (or on)"
};

export const MSG_tipIncludedLabel = {
  id: "febc604b-266a-4a5e-9a8f-9213ce8ea9a4",
  description: "The label for a tip included with an invoice payment.",
  defaultMessage: "Tip included"
};

export const MSG_tipNotIncludedLabel = {
  id: "8f55c962-3c2d-4025-a733-d20733ff9038",
  description: "The label for a tip not included with an invoice payment.",
  defaultMessage: "Tip not included"
};

export const MSG_tipTotalLabel = {
  id: "d306b8db-714c-438f-a556-fa7b15bd06c3",
  description: "The label for the total of an invoice tip.",
  defaultMessage: "Tip total"
};
