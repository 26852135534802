import * as React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerSlash } from "@fortawesome/pro-solid-svg-icons";
import { IMappingInteractiveMapByAddressProps } from "../interactive_map";
import { ILatLng } from "@gazelle/shared/interfaces";
import { MAPPING_LOCATION_TYPE } from "@gazelle/shared/enums";
import { MSG_exactLocationNotFoundShortError, MSG_noMapAvailable } from "@gazelle/shared/strings";
import { formatMessage } from "@gazelle/shared/utils";

interface IGoogleInteractiveMapContainerByAddress {
  InteractiveMapContainer: any;
  LoadingIndicator: any;
  geocode: (addressLine: string) => Promise<ILatLng>;
}

function getGoogleInteractiveMapContainerByAddress(config: IGoogleInteractiveMapContainerByAddress) {
  interface IState {
    isLoading: boolean;
    lat: number;
    lng: number;
    locationType: MAPPING_LOCATION_TYPE;
  }

  class GoogleInteractiveMapContainerByAddress extends React.Component<IMappingInteractiveMapByAddressProps, IState> {
    constructor(props: IMappingInteractiveMapByAddressProps) {
      super(props);
      this.state = {isLoading: false, lat: null, lng: null, locationType: null};
    }

    async componentDidMount() {
      if (!this.props.addressLine) return;

      this.setState({isLoading: true});

      const pos = await config.geocode(this.props.addressLine);
      if (pos) {
        this.setState({isLoading: false, lat: pos.lat, lng: pos.lng, locationType: pos.locationType});
      } else {
        this.setState({isLoading: false, lat: null, lng: null, locationType: null});
      }
    }

    render() {
      const InteractiveMapContainer = config.InteractiveMapContainer;
      const LoadingIndicator = config.LoadingIndicator;

      if (!this.props.addressLine) {
        return (
          <div className="interactive-maps-container d-flex flex-column align-items-center justify-content-center"
               style={{height: this.props.height, width: this.props.width, backgroundColor: "#f4f4f4"}}>
            <h2><FontAwesomeIcon icon={faMapMarkerSlash}/></h2>
            <div className="text-small text-muted">
              {formatMessage(MSG_noMapAvailable)}
            </div>
          </div>
        );
      } else if (this.state.isLoading) {
        return (
          <div className="interactive-maps-container d-flex flex-column align-items-center justify-content-center"
               style={{height: this.props.height, width: this.props.width, backgroundColor: "#f4f4f4"}}>
            <LoadingIndicator/>
          </div>
        );
      } else if (!this.state.lat || !this.state.lng) {
        return (
          <div className="interactive-maps-container d-flex flex-column align-items-center justify-content-center"
               style={{height: this.props.height, width: this.props.width, backgroundColor: "#f4f4f4"}}>
            <h2><FontAwesomeIcon icon={faMapMarkerSlash}/></h2>
            <div className="text-small text-muted">
              {formatMessage(MSG_exactLocationNotFoundShortError)}
            </div>
          </div>
        );
      } else {
        return (
          <InteractiveMapContainer
            height={this.props.height}
            width={this.props.width}
            lat={this.state.lat}
            lng={this.state.lng}
            locationType={this.state.locationType}/>
        );
      }
    }
  }

  return GoogleInteractiveMapContainerByAddress;
}

export { getGoogleInteractiveMapContainerByAddress };
