import * as React from 'react';
import { EstimateTier, Localization } from "@gazelle/shared/models";
import { PianoWrapper } from "@client_scheduler/models/piano_wrapper";
import { EstimateTierGroupRow } from "@client_scheduler/modules/step2_pianos/components/EstimateTierGroupRow";
import { formatMessage } from "@gazelle/shared/utils";
import { MSG_miscItemsTitle } from "@gazelle/shared/strings";

interface IProps {
  tier: EstimateTier;
  pianoWrapper: PianoWrapper
  localization?: Localization;
}

interface IState {
}

class EstimateTierList extends React.PureComponent<IProps, IState> {
  render() {
    return (
      <div className="master-service-list">
        {this.props.tier.allEstimateTierGroups.map((group, index) => (
          <EstimateTierGroupRow
            key={group.id}
            groupName={group.name}
            items={group.allEstimateTierItems}
            isFirstGroup={index === 0}
            pianoWrapper={this.props.pianoWrapper}/>
        ))}
        {this.props.tier.allUngroupedEstimateTierItems.length > 0 && (
          <EstimateTierGroupRow
            groupName={
              this.props.tier.allEstimateTierGroups.length > 0
                ? formatMessage(MSG_miscItemsTitle, {}, this.props.localization?.locale)
                : null
            }
            items={this.props.tier.allUngroupedEstimateTierItems}
            isFirstGroup={this.props.tier.allEstimateTierGroups.length === 0}
            pianoWrapper={this.props.pianoWrapper}/>
        )}
      </div>
    );
  }
}

export { EstimateTierList };
