import * as React from 'react';
import { PianoWrapper } from "@client_scheduler/models/piano_wrapper";
import { EstimateTierItem } from "@gazelle/shared/models";
import { FormGroup, Input, Label } from "reactstrap";
import { observer } from "mobx-react";
import { GazelleDuration } from "@gazelle/shared/components/GazelleDateTime";
import { GazelleCurrency } from "@gazelle/shared/components/GazelleNumber";

interface IProps {
  item: EstimateTierItem,
  pianoWrapper: PianoWrapper
}

interface IState {
}

const EstimateTierItemRow = observer(class EstimateTierItemRow extends React.Component<IProps, IState> {
  handleChange = (e: any) => {
    const { item } = this.props;
    if (e.target.checked) {
      this.props.pianoWrapper.set(item);
    } else {
      this.props.pianoWrapper.unset(item);
    }
  };

  render() {
    const item = this.props.item;
    return (
      <FormGroup check className="master-service-item-row">
        <Label check>
          <Input
            type="checkbox"
            checked={!!this.props.pianoWrapper.estimateItems.get(item.id)}
            onChange={this.handleChange}
          />
          {item.quantity / 100 !== 1 ? `${item.quantity / 100} x ` : ''}
          {item.name}

          <div className="text-small text-muted">
            {!!item.duration && <GazelleDuration minutes={item.duration * (item.quantity / 100)} />}
            {!!item.duration && <span> &mdash; </span>}
            <GazelleCurrency value={item.total}/>
            {item.description && <span> &mdash; {item.description}</span>}
          </div>
        </Label>
      </FormGroup>
    );
  }
});

export { EstimateTierItemRow };
