export enum INTERVAL_TYPE {
  MONTH = 'month',
  WEEK = 'week',
  DAY = 'day',
  FOUR_DAY = 'four_day',
  CUSTOM = 'custom',
}

export enum EVENT_TYPE {
  APPOINTMENT = 'APPOINTMENT',
  PERSONAL = 'PERSONAL',
  MEMO = 'MEMO',
  SYNCED = 'SYNCED',
}

export enum EVENT_STATUS {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  NO_SHOW = 'NO_SHOW',
  COMPLETE = 'COMPLETE',
}

export enum EVENT_CONFIRMATION_WARNING {
  NONE = 'NONE',
  NOTICE = 'NOTICE',
  CRITICAL = 'CRITICAL',
}

export enum EVENT_RECURRENCE_TYPE {
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
}

export enum EVENT_RECURRENCE_MONTHLY_TYPE {
  DATES = 'DATES',
  WEEKDAYS = 'WEEKDAYS',
}

export enum EVENT_RECURRENCE_ENDING_TYPE {
  OCCURRENCES = 'OCCURRENCES',
  DATE = 'DATE',
}

export enum EVENT_RECURRENCE_CHANGE_TYPE {
  SELF = 'SELF',
  FUTURE = 'FUTURE',
}

export enum SCHEDULED_MESSAGE_TYPE {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  CALL = 'CALL',
}

export enum APPOINTMENT_COMPLETION_RESULT_TYPE {
  COMPLETE = 'COMPLETE',
  RESCHEDULED = 'RESCHEDULED',
  NO_SHOW = 'NO_SHOW',
  CANCELED = 'CANCELED',
  OTHER = 'OTHER',
}

export enum CLIENT_STATUS_TYPE {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  PROSPECT = 'PROSPECT',
  NEW = 'NEW',
}

export enum PHONE_TYPE {
  UNKNOWN = 'UNKNOWN',
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  MOBILE = 'MOBILE',
  HOME = 'HOME',
  WORK = 'WORK',
  FAX = 'FAX',
}

export enum PHONE_CLASS {
  UNKNOWN = 'UNKNOWN',
  LANDLINE = 'LANDLINE',
  MOBILE = 'MOBILE',
  VOIP = 'VOIP',
}

export enum PIANO_TYPE {
  UNKNOWN = 'UNKNOWN',
  GRAND = 'GRAND',
  UPRIGHT = 'UPRIGHT',
  CONSOLE = 'CONSOLE',
  SPINET = 'SPINET',
  ORGAN = 'ORGAN',
  DIGITAL = 'DIGITAL',
  SQUARE_GRAND = 'SQUARE_GRAND',
  HARPSICHORD = 'HARPSICHORD',
  FORTEPIANO = 'FORTEPIANO',
  CLAVICHORD = 'CLAVICHORD',
  AUTOHARP = 'AUTOHARP',
  HYBRID = 'HYBRID',
  STUDIO = 'STUDIO',
}

export enum PIANO_STATUS {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  TEMPORARY_STORAGE = 'TEMPORARY_STORAGE',
  UNDER_RESTORATION = 'UNDER_RESTORATION',
}

export enum PIANO_SORT {
  STATUS_ASC = 'STATUS_ASC',
  STATUS_DESC = 'STATUS_DESC',
  LAST_SERVICE_ASC = 'LAST_SERVICE_ASC',
  LAST_SERVICE_DESC = 'LAST_SERVICE_DESC',
  NEXT_SERVICE_ASC = 'NEXT_SERVICE_ASC',
  NEXT_SERVICE_DESC = 'NEXT_SERVICE_DESC',
  DUE_NEAR_TODAY_ASC = 'DUE_NEAR_TODAY_ASC',
  DUE_NEAR_TODAY_DESC = 'DUE_NEAR_TODAY_DESC',
}

export enum ADDRESS_TYPE {
  STREET = 'STREET',
  MAILING = 'MAILING',
  BILLING = 'BILLING',
}

export enum SLOT_WARNING {
  OUTSIDE_AREA = 'OUTSIDE_AREA',
  NOT_FOUND_SA_CENTER = 'NOT_FOUND_SA_CENTER',
  NOT_FOUND_SA_START = 'NOT_FOUND_SA_START',
  NOT_FOUND_SA_END = 'NOT_FOUND_SA_END',
  NOT_FOUND_SELF = 'NOT_FOUND_SELF',
  NOT_FOUND_BEFORE = 'NOT_FOUND_BEFORE',
  NOT_FOUND_AFTER = 'NOT_FOUND_AFTER',
  NOT_FOUND_DRIVE_TIME = 'NOT_FOUND_DRIVE_TIME',
  NO_ROUTE = 'NO_ROUTE',
  SHORT_TERM_LIMIT = 'SHORT_TERM_LIMIT',
  LONG_TERM_LIMIT = 'LONG_TERM_LIMIT',
  NEAR_EXISTING_APPOINTMENT = 'NEAR_EXISTING_APPOINTMENT',
  TRAFFIC = 'TRAFFIC',
}

export enum ITINERARY_ITEM_TYPE {
  START = 'START',
  END = 'END',
  DOWNTIME = 'DOWNTIME',
  TRAVEL = 'TRAVEL',
  APPOINTMENT = 'APPOINTMENT',
  PERSONAL = 'PERSONAL',
  SYNCED = 'SYNCED',
  MEMO = 'MEMO',
  RESERVATION = 'RESERVATION',
}

export enum CALENDAR_VIEW_TYPE {
  MONTH = 'MONTH',
  WEEK = 'WEEK',
  DAY = 'DAY',
  FOUR_DAY = 'FOUR_DAY',
}

export enum CALENDAR_TITLE_MODE {
  TITLE = 'TITLE',
  TITLE_CITY = 'TITLE_CITY',
  TITLE_POSTAL_CODE = 'TITLE_POSTAL_CODE',
  NAME = 'NAME',
  NAME_POSTAL_CODE = 'NAME_POSTAL_CODE',
  NAME_CITY = 'NAME_CITY',
  POSTAL_CODE = 'POSTAL_CODE',
  CITY = 'CITY',
  PIANOS = 'PIANOS',
}

export enum CALENDAR_FONT_SIZE {
  XSMALL = 'XSMALL',
  SMALL = 'SMALL',
  NORMAL = 'NORMAL',
  LARGE = 'LARGE',
  XLARGE = 'XLARGE',
}

export enum MAPPING_PROVIDER_TYPE {
  GOOGLE = 'GOOGLE',
  AZURE = 'AZURE',
  MAPBOX = 'MAPBOX',
  AWS_HERE = 'AWS_HERE',
}

export enum MAPPING_LOCATION_TYPE {
  ROOFTOP = 'ROOFTOP',
  RANGE_INTERPOLATED = 'RANGE_INTERPOLATED',
  APPROXIMATE = 'APPROXIMATE',
  GEOMETRIC_CENTER = 'GEOMETRIC_CENTER',
  ADDRESS_NOT_FOUND = 'ADDRESS_NOT_FOUND',
  UNKNOWN = 'UNKNOWN'
}

export enum MASTER_SERVICE_ITEM_TYPE {
  LABOR_FIXED_RATE = 'LABOR_FIXED_RATE',
  LABOR_HOURLY = 'LABOR_HOURLY',
  EXPENSE = 'EXPENSE',
  MILEAGE = 'MILEAGE',
  OTHER = 'OTHER',
}

export enum INVOICE_SORT {
  STATUS_ASC = 'STATUS_ASC',
  STATUS_DESC = 'STATUS_DESC',
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  CREATED_AT_DESC = 'CREATED_AT_DESC',
}

export enum INVOICE_STATUS {
  DRAFT = 'DRAFT',
  OPEN = 'OPEN',
  OVERDUE = 'OVERDUE',
  PAID = 'PAID',
  DELETED = 'DELETED',
  CANCELED = 'CANCELED',
}

export enum INVOICE_ITEM_TYPE {
  LABOR_FIXED_RATE = 'LABOR_FIXED_RATE',
  LABOR_HOURLY = 'LABOR_HOURLY',
  EXPENSE = 'EXPENSE',
  MILEAGE = 'MILEAGE',
  OTHER = 'OTHER',
}

export enum INVOICE_PAYMENT_TYPE {
  CASH = "CASH",
  CHECK = "CHECK",
  CREDIT_CARD = "CREDIT_CARD",
  DEBIT_CARD = "DEBIT_CARD",
  ACH = "ACH",
  ELECTRONIC_FUNDS_TRANSFER = "ELECTRONIC_FUNDS_TRANSFER",
  OTHER = "OTHER",
  PAYPAL = "PAYPAL",
  VENMO = "VENMO",
  ZELLE = "ZELLE",
  SEPA = "SEPA",
  BANCONTACT = "BANCONTACT",
  EPS = "EPS",
  GIROPAY = "GIROPAY",
  IDEAL = "IDEAL",
  SOFORT = "SOFORT",
  P24 = "P24",
}

export enum INVOICE_PAYMENT_STATUS {
  CANCELED = "CANCELED",
  DISPUTED = "DISPUTED",
  FAILED = "FAILED",
  PROCESSING = "PROCESSING",
  REFUNDED = "REFUNDED",
  SUCCEEDED = "SUCCEEDED",
  REQUIRES_PAYMENT_METHOD = "REQUIRES_PAYMENT_METHOD",
  REQUIRES_CONFIRMATION = "REQUIRES_CONFIRMATION",
  REQUIRES_ACTION = "REQUIRES_ACTION",
  REQUIRES_CAPTURE = "REQUIRES_CAPTURE",
}

export enum STRIPE_PAYMENT_METHODS {
  CARD = "CARD",
  SEPA_DEBIT = "SEPA_DEBIT",
  BANCONTACT = "BANCONTACT",
  BACS_DEBIT = "BACS_DEBIT",
  ACH = "ACH",
  EPS = "EPS",
  GIROPAY = "GIROPAY",
  IDEAL = "IDEAL",
  SOFORT = "SOFORT",
  P24 = "P24",
}

export enum QUICKBOOKS_PAYMENT_TYPE {
  CASH = "CASH",
  CHECK = "CHECK",
  CREDIT_CARD = "CREDIT_CARD",
  OTHER = "OTHER"
}

export enum CLIENT_LOG_TYPE {
  COMMENT = 'COMMENT',
  REMINDER = 'REMINDER',
  PIANO = 'PIANO',
  ESTIMATE = 'ESTIMATE',
  CLIENT = 'CLIENT',
  INVOICE = 'INVOICE',
  SERVICE_ENTRY = 'SERVICE_ENTRY',
}

export enum CLIENT_LOG_STATUS {
  EMAILED = 'EMAILED',
  CALLED_HUMAN = 'CALLED_HUMAN',
  CALLED_MESSAGE = 'CALLED_MESSAGE',
  OTHER = 'OTHER',
  POST_CARD = 'POST_CARD',
  EMAIL = 'EMAIL',
  TEXT = 'TEXT',
}

export enum TIMELINE_ENTRY_TYPE {
  CONTACT_EMAIL_AUTOMATED = 'CONTACT_EMAIL_AUTOMATED',
  CONTACT_EMAIL_MANUAL = 'CONTACT_EMAIL_MANUAL',
  CONTACT_SMS_AUTOMATED = 'CONTACT_SMS_AUTOMATED',
  CONTACT_SMS_MANUAL = 'CONTACT_SMS_MANUAL',
  CONTACT_PHONE_AUTOMATED = 'CONTACT_PHONE_AUTOMATED',
  CONTACT_PHONE_MANUAL_TALKED = 'CONTACT_PHONE_MANUAL_TALKED',
  CONTACT_PHONE_MANUAL_MESSAGE = 'CONTACT_PHONE_MANUAL_MESSAGE',
  CONTACT_POST_AUTOMATED = 'CONTACT_POST_AUTOMATED',
  CONTACT_POST_MANUAL = 'CONTACT_POST_MANUAL',
  CONTACT_OTHER_MANUAL = 'CONTACT_OTHER_MANUAL',
  ERROR_MESSAGE = 'ERROR_MESSAGE',
  SYSTEM_MESSAGE = 'SYSTEM_MESSAGE',
  USER_COMMENT = 'USER_COMMENT',
  SERVICE_ENTRY_AUTOMATED = 'SERVICE_ENTRY_AUTOMATED',
  SERVICE_ENTRY_MANUAL = 'SERVICE_ENTRY_MANUAL',
  APPOINTMENT = 'APPOINTMENT',
  APPOINTMENT_LOG = 'APPOINTMENT_LOG',
  INVOICE = 'INVOICE',
  INVOICE_LOG = 'INVOICE_LOG',
  INVOICE_PAYMENT = 'INVOICE_PAYMENT',
  INVOICE_SYNC = 'INVOICE_SYNC',
  PIANO_MEASUREMENT = 'PIANO_MEASUREMENT',
  SCHEDULED_MESSAGE_EMAIL = 'SCHEDULED_MESSAGE_EMAIL',
  SCHEDULED_MESSAGE_SMS = 'SCHEDULED_MESSAGE_SMS',
  SCHEDULED_MESSAGE_PHONE = 'SCHEDULED_MESSAGE_PHONE',
  ESTIMATE = 'ESTIMATE',
  ESTIMATE_LOG = 'ESTIMATE_LOG',
  SYSTEM_NOTIFICATION = 'SYSTEM_NOTIFICATION',
}

export enum TIMELINE_ENTRY_RELATED_TYPE {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  ERROR_MESSAGE = 'ERROR_MESSAGE',
  EVENT = 'EVENT',
  ERROR_LOG = 'ERROR_LOG',
  INVOICE = 'INVOICE',
  INVOICE_PAYMENT = 'INVOICE_PAYMENT',
  ESTIMATE = 'ESTIMATE',
  PIANO_MEASUREMENT = 'PIANO_MEASUREMENT',
  SCHEDULED_MESSAGE = 'SCHEDULED_MESSAGE',
  CLIENT_LOG = 'CLIENT_LOG',
  SYSTEM_NOTIFICATION = 'SYSTEM_NOTIFICATION',
}

export enum EMAIL_STATUS {
  ACCEPTED = "ACCEPTED",
  BOUNCED = "BOUNCED",
  CLICKED = "CLICKED",
  COMPLAINED = "COMPLAINED",
  DELIVERED = "DELIVERED",
  DROPPED = "DROPPED",
  FAILED = "FAILED",
  OPENED = "OPENED",
  PENDING = "PENDING",
  UNSUBSCRIBED = "UNSUBSCRIBED",
  UNDELIVERED = "UNDELIVERED",
  QUEUED = "QUEUED",
  SENT = "SENT",
}

export enum SMS_STATUS {
  ACCEPTED = "ACCEPTED",
  SENT = "SENT",
  FAILED = "FAILED",
  DELIVERED = "DELIVERED",
  RECEIVED = "RECEIVED",
  QUEUED = "QUEUED",
  UNDELIVERED = "UNDELIVERED",
}

export enum MUTATION_ERROR_TYPES {
  ACCESS_DENIED = "ACCESS_DENIED",
  VALIDATION = "VALIDATION",
  NOT_FOUND = "NOT_FOUND",
  GENERID = "GENERIC",
}

export enum USER_STATUS {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum USER_ACCESS_LEVEL {
  ADMIN = "ADMIN",
  TECHNICIAN = "TECHNICIAN",
  ASSISTANT = "ASSISTANT"
}

export enum REMINDER_TYPE {
  EMAIL = "EMAIL",
  CALL = "CALL",
  SMS = "SMS",
}

export enum LIFECYCLE_TYPE {
  CLIENT = "CLIENT",
  PIANO = "PIANO",
  EVENT = "EVENT",
}

export enum LIFECYCLE_STATE {
  NO_LIFECYCLE = "no-lifecycle",

  PROSPECT = "prospect",
  NEW = "new",
  INACTIVE = "inactive",
  ACTIVE = "active",

  WITHOUT_SCHEDULING_DETAILS = "without-scheduling-details",
  TUNING_SCHEDULED = "tuning-scheduled",
  NOT_DUE = "not-due",
  COMING_DUE = "due",
  DUE = "overdue1",
  OVERDUE_STAGE_1 = "overdue2",
  OVERDUE_STAGE_2 = "overdue3",
  OVERDUE_STAGE_3 = "overdue4",

  COMPLETE = "complete",
  NO_SHOW = "no-show",
  CANCELED = "canceled",
  PAST = "past",
  TODAY = "today",
  CONFIRMED = "confirmed",
  UNCONFIRMED = "unconfirmed",
  UNCONFIRMED_CRITICAL = "unconfirmed-critical",
  FUTURE = "future",

}

export enum COMPANY_HEADER_LAYOUT_TYPE {
  SIDE_LOGO_WITH_NAME = "SIDE_LOGO_WITH_NAME",
  FULL_LOGO_WITHOUT_NAME = "FULL_LOGO_WITHOUT_NAME",
}

export enum CALENDAR_DAY_VIEW_MODE {
  ITINERARY = 'ITINERARY',
  DAY = 'DAY',
  MAP = 'MAP',
}

export enum EVENT_RESERVATION_STATUS {
  PENDING = 'PENDING',
  DECLINED = 'DECLINED',
  APPROVED = 'APPROVED'
}

export enum EVENT_CANCEL_NOTICE_STATUS {
  OPEN = 'OPEN',
  DISMISSED = 'DISMISSED',
  DELETED = 'DELETED'
}

export enum EVENT_RESERVATION_CLIENT_CONFLICT_TYPE {
  NO_CONFLICTS,
  NO_CLIENT,
  NO_CONTACT_MATCHED,
  NOT_DEFAULT_CONTACT
}

export enum EVENT_RESERVATION_EMAIL_CONFLICT_TYPE {
  NO_CONFLICTS,
  NO_CONTACT,
  NOT_FOUND,
  NOT_DEFAULT
}

export enum EVENT_RESERVATION_PHONE_CONFLICT_TYPE {
  NO_CONFLICTS,
  NO_CONTACT,
  NOT_FOUND,
  NOT_DEFAULT
}

export enum EVENT_RESERVATION_ADDRESS_CONFLICT_TYPE {
  NO_CONFLICTS,
  NO_CONTACT,
  NOT_FOUND,
  NOT_DEFAULT
}

export enum COMPANY_EMAIL_TEMPLATE_TYPE {
  INVOICE = 'INVOICE',
  SELF_SCHEDULE_CONFIRM_SUBJECT = 'SELF_SCHEDULE_CONFIRM_SUBJECT',
  SELF_SCHEDULE_CONFIRM_MESSAGE = 'SELF_SCHEDULE_CONFIRM_MESSAGE',
  RESERVATION_DECLINE_SUBJECT = 'RESERVATION_DECLINE_SUBJECT',
  RESERVATION_DECLINE_MESSAGE = 'RESERVATION_DECLINE_MESSAGE',
  APPOINTMENT_ADDED_SUBJECT = 'APPOINTMENT_ADDED_SUBJECT',
  APPOINTMENT_ADDED_MESSAGE = 'APPOINTMENT_ADDED_MESSAGE',
  APPOINTMENT_AUTO_DELETED_SUBJECT = 'APPOINTMENT_AUTO_DELETED_SUBJECT',
  APPOINTMENT_AUTO_DELETED_MESSAGE = 'APPOINTMENT_AUTO_DELETED_MESSAGE',
  APPOINTMENT_CONFIRMED_SUBJECT = 'APPOINTMENT_CONFIRMED_SUBJECT',
  APPOINTMENT_CONFIRMED_MESSAGE = 'APPOINTMENT_CONFIRMED_MESSAGE'
}

export enum DRIVE_TIME_ERROR_TYPE {
  GENERIC_ERROR = 'GENERIC_ERROR',
  NETWORK_ERROR = 'NETWORK_ERROR',
  NOT_FOUND = 'NOT_FOUND',
  ORIGIN_NOT_FOUND = 'ORIGIN_NOT_FOUND',
  DESTINATION_NOT_FOUND = 'DESTINATION_NOT_FOUND',
  NOT_ENOUGH_TIME = 'NOT_ENOUGH_TIME',
  NOT_SUPPORTED = 'NOT_SUPPORTED',
}

export enum COMPANY_PARKED_REASON {
  NOT_PARKED = "NOT_PARKED",
  PARKED_MANUALLY = 'PARKED_MANUALLY',
  CARD_EXPIRED = 'CARD_EXPIRED',
  NO_CARD = 'NO_CARD',
  NO_SUBSCRIPTION = 'NO_SUBSCRIPTION',
  UNPAID = 'UNPAID',
  UNABLE_TO_CHARGE = 'UNABLE_TO_CHARGE',
  OVER_PIANO_LIMIT = 'OVER_PIANO_LIMIT',
  UNKNOWN = 'UNKNOWN',
}

export enum NUMBER_FORMAT {
  COMMA_DECIMAL_THREE = "COMMA_DECIMAL_THREE",
  DECIMAL_COMMA_THREE = "DECIMAL_COMMA_THREE",
  SPACE_COMMA_THREE = "SPACE_COMMA_THREE",
}

export enum CURRENCY_FORMAT {
  BEFORE = "BEFORE",
  AFTER = "AFTER",
}

export enum WEEKDAYS {
  SUN = "SUN",
  MON = "MON",
  TUE = "TUE",
  WED = "WED",
  THU = "THU",
  FRI = "FRI",
  SAT = "SAT",
}

export enum RECOMMENDATION_TYPE {
  YES = "YES",
  NO = "NO",
  MAYBE = "MAYBE",
}

export enum PERFORMANCE_LEVEL_TYPE {
  CURRENT = "CURRENT",
  POTENTIAL = "POTENTIAL",
  TARGET = "TARGET",
}

export enum GAZELLE_REFERRAL_STATUS {
  PAID_OUT = "PAID_OUT",
  SENT = "SENT",
  CLICKED = "CLICKED",
  SIGNED_UP = "SIGNED_UP",
  SIGNED_UP_PAID_1 = "SIGNED_UP_PAID_1",
  SIGNED_UP_PAID_2 = "SIGNED_UP_PAID_2",
  SIGNED_UP_PAID_3 = "SIGNED_UP_PAID_3",
}

export enum SYSTEM_NOTIFICATION_ALERT_TYPE {
  INFO = "INFO",
  WARNING = "WARNING",
  ERROR = "ERROR",
}

export enum SYSTEM_NOTIFICATION_TYPE {
  BILLING = "BILLING",
  CALENDAR_INTEGRATION = "CALENDAR_INTEGRATION",
  QUICKBOOKS_INTEGRATION = "QUICKBOOKS_INTEGRATION",
  LEGAL_CONTRACT = "LEGAL_CONTRACT"
}

export enum SYSTEM_NOTIFICATION_SUB_TYPE {
  CALENDAR_LINKING = "CALENDAR_LINKING",
  FAILED_PAYMENT = "FAILED_PAYMENT",
  TRIAL_ENDING = "TRIAL_ENDING",
  AUTHORIZATION_ERROR = "AUTHORIZATION_ERROR",
  SYNC_ERROR = "SYNC_ERROR",
  NEEDS_SIGNATURE = "NEEDS_SIGNATURE"
}

export enum CALENDAR_INTEGRATION_TYPE {
  GOOGLE = "GOOGLE",
  ICS = "ICS",
  GAZELLE = "GAZELLE",
}

export enum EMAIL_SUBSCRIPTION_TYPE {
  NORMAL = 'NORMAL',
  UNSUBSCRIBE = 'UNSUBSCRIBE',
  BOUNCE = 'BOUNCE',
  SPAM_COMPLAINT = 'SPAM_COMPLAINT',
}

export enum DEFAULT_USER_PAYMENT_OPTION {
  STRIPE = 'STRIPE',
  MANUAL = 'MANUAL',
}

export enum QUICKBOOKS_BATCH_SYNC_STATUS {
  NOT_STARTED = 'NOT_STARTED',
  RUNNING_INVOICE_SYNC = 'RUNNING_INVOICE_SYNC',
  RUNNING_STRIPE_PAYOUT_SYNC = 'RUNNING_STRIPE_PAYOUT_SYNC',
  RUNNING_QBO_PAYMENT_SYNC = 'RUNNING_QBO_PAYMENT_SYNC',
  COMPLETE = 'COMPLETE',
  ERROR = 'ERROR',
  EARLY_SHUTDOWN = 'EARLY_SHUTDOWN',
}

export enum QUICKBOOKS_SYNC_STATUS {
  NOT_STARTED = 'NOT_STARTED',
  RUNNING = 'RUNNING',
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  EARLY_SHUTDOWN = 'EARLY_SHUTDOWN',
}

export enum QUICKBOOKS_SYNC_ERROR_TYPE {
  MISSING_TAX_MAPPINGS = 'MISSING_TAX_MAPPINGS',
  MISSING_ACCOUNT_MAPPINGS = 'MISSING_ACCOUNT_MAPPINGS',
  QBO_TAX_RATE_NOT_FOUND = 'QBO_TAX_RATE_NOT_FOUND',
  INVOICE_LINES_TAX_MISMATCH = 'INVOICE_LINES_TAX_MISMATCH',
  UNKNOWN = 'UNKNOWN',
}

export enum QUICKBOOKS_SYNC_NOTICE_TYPE {
  TAX_MISMATCH = 'TAX_MISMATCH',
  UNKNOWN = 'UNKNOWN',
}

export enum QUICKBOOKS_SYNC_PAYMENT_AUTHORITY {
  QUICKBOOKS = 'QUICKBOOKS',
  GAZELLE = 'GAZELLE',
}

export enum REMOTE_ACCOUNTING_TAX_MAPPING_TYPE {
  QUICKBOOKS = 'QUICKBOOKS',
}

export enum ACTIVE_OR_HISTORICAL {
  ACTIVE = 'ACTIVE',
  HISTORICAL = 'HISTORICAL'
}

export enum QUICKBOOKS_ACCOUNT_TYPE {
  UNDEPOSITED_FUNDS = 'UNDEPOSITED_FUNDS',
  BANK_ACCOUNT_REGISTER = 'BANK_ACCOUNT_REGISTER',
  STRIPE_PROCESSING_FEES = 'STRIPE_PROCESSING_FEES',
  CLEARING_ACCOUNT = 'CLEARING_ACCOUNT',
}

export enum INVOICE_PAYMENT_SOURCE {
  MANUAL = "MANUAL",
  STRIPE = "STRIPE",
  QUICKBOOKS_ONLINE = "QUICKBOOKS_ONLINE",
}

export enum LEGAL_CONTRACT_TYPE {
  EU_GDPR_DPA = "EU_GDPR_DPA",
  UK_GDPR_DPA = "UK_GDPR_DPA",
}

export enum TIMEFRAME_DATA_TYPE {
  APPOINTMENTS = "APPOINTMENTS",
  CLIENTS_CREATED = "CLIENTS_CREATED",
  ESTIMATES_CREATED = "ESTIMATES_CREATED",
  INVOICES_CREATED = "INVOICES_CREATED",
  PIANOS_CREATED = "PIANOS_CREATED",
  REMINDERS = "REMINDERS",
}

export enum TIMEFRAME {
  YEAR = "YEAR",
  QUARTER = "QUARTER",
  MONTH = "MONTH",
}

export enum SORT_DIR {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum ERROR_LOG_TYPE {
  SCHEDULED_MESSAGE = 'SCHEDULED_MESSAGE',
  EMAIL_UNSUBSCRIBE = 'EMAIL_UNSUBSCRIBE',
  EMAIL_COMPLAINT = 'EMAIL_COMPLAINT',
  BAD_EMAIL_ADDRESS = 'BAD_EMAIL_ADDRESS',
  SMS_UNSUBSCRIBE = 'SMS_UNSUBSCRIBE',
  FAILED_PAYMENT = 'FAILED_PAYMENT',
}

export enum BIG_BOY_PANTS_ROLES {
  COMPANIES = 'COMPANIES',
  IMPERSONATE = 'IMPERSONATE',
  REFERRALS = 'REFERRALS',
  REVENUE = 'REVENUE',
  REPORTS = 'REPORTS',
}

export enum DASHBOARD_VIEW {
  ITINERARY = 'ITINERARY',
  METRICS = 'METRICS',
}

export enum SELECTION_BASE {
  ALL = 'ALL',
  NONE = 'NONE',
}

export enum CONTACTS_WITH_EMAIL {
  ALL_CONTACTS_HAVE_EMAIL = 'ALL_CONTACTS_HAVE_EMAIL',
  NO_CONTACTS_HAVE_EMAIL = 'NO_CONTACTS_HAVE_EMAIL',
  PRIMARY_CONTACT_HAS_EMAIL = 'PRIMARY_CONTACT_HAS_EMAIL',
  PRIMARY_CONTACT_HAS_NO_EMAIL = 'PRIMARY_CONTACT_HAS_NO_EMAIL',
  BILLING_CONTACT_HAS_EMAIL = 'BILLING_CONTACT_HAS_EMAIL',
  BILLING_CONTACT_HAS_NO_EMAIL = 'BILLING_CONTACT_HAS_NO_EMAIL',
}

export enum CONTACTS_WITH_PHONE {
  ALL_CONTACTS_HAVE_PHONE = 'ALL_CONTACTS_HAVE_PHONE',
  NO_CONTACTS_HAVE_PHONE = 'NO_CONTACTS_HAVE_PHONE',
  PRIMARY_CONTACT_HAS_PHONE = 'PRIMARY_CONTACT_HAS_PHONE',
  PRIMARY_CONTACT_HAS_NO_PHONE = 'PRIMARY_CONTACT_HAS_NO_PHONE',
  BILLING_CONTACT_HAS_PHONE = 'BILLING_CONTACT_HAS_PHONE',
  BILLING_CONTACT_HAS_NO_PHONE = 'BILLING_CONTACT_HAS_NO_PHONE',
}

export enum CONTACTS_WITH_TEXTABLE_PHONE {
  ALL_CONTACTS_HAVE_TEXTABLE_PHONE = 'ALL_CONTACTS_HAVE_TEXTABLE_PHONE',
  NO_CONTACTS_HAVE_TEXTABLE_PHONE = 'NO_CONTACTS_HAVE_TEXTABLE_PHONE',
  PRIMARY_CONTACT_HAS_TEXTABLE_PHONE = 'PRIMARY_CONTACT_HAS_TEXTABLE_PHONE',
  PRIMARY_CONTACT_HAS_NO_TEXTABLE_PHONE = 'PRIMARY_CONTACT_HAS_NO_TEXTABLE_PHONE',
  BILLING_CONTACT_HAS_TEXTABLE_PHONE = 'BILLING_CONTACT_HAS_TEXTABLE_PHONE',
  BILLING_CONTACT_HAS_NO_TEXTABLE_PHONE = 'BILLING_CONTACT_HAS_NO_TEXTABLE_PHONE',
}

export enum PRICING_MODEL {
  JAKKLOPS = 'JAKKLOPS',
  TOMOODE = 'TOMOODE',
}

export enum PRICING_INTERVAL {
  YEAR = 'YEAR',
  MONTH = 'MONTH',
}

export enum JAKKLOPS_FEATURE {
  CORE = 'CORE',
  REMINDERS = 'REMINDERS',
  SMS = 'SMS',
  SCHEDULING = 'SCHEDULING',
  INVOICES = 'INVOICES',
  ESTIMATES = 'ESTIMATES',
  QUICKBOOKS = 'QUICKBOOKS',
  MAILCHIMP = 'MAILCHIMP',
}

export enum SUBSCRIPTION_STATUS {
  ACTIVE = "ACTIVE",
  PAST_DUE = "PAST_DUE",
  UNPAID = "UNPAID",
  CANCELED = "CANCELED",
  INCOMPLETE = "INCOMPLETE",
  INCOMPLETE_EXPIRED = "INCOMPLETE_EXPIRED",
  TRIALING = "TRIALING",
}

export enum BULK_ACTION_TYPE {
  CHANGE_CLIENT_STATUS = "CHANGE_CLIENT_STATUS",
  CHANGE_PIANO_STATUS = "CHANGE_PIANO_STATUS",
  EXPORT_CLIENTS_TO_MAILCHIMP = "EXPORT_CLIENTS_TO_MAILCHIMP",
  CSV_EXPORT = "CSV_EXPORT",
}

export enum BULK_ACTION_STATUS {
  FAILED = "FAILED",
  PARTIAL_SUCCESS = "PARTIAL_SUCCESS",
  PENDING = "PENDING",
  RUNNING = "RUNNING",
  SUCCESS = "SUCCESS",
}

export enum START_OF_DAY_TYPE {
  CLIENT = "CLIENT",
  START_OF_DAY_LOCATION = "START_OF_DAY_LOCATION",
}

export enum END_OF_DAY_TYPE {
  CLIENT = "CLIENT",
  END_OF_DAY_LOCATION = "END_OF_DAY_LOCATION",
}

export enum SCHEDULER_LOCATION_TYPE {
  ADDRESS = "ADDRESS",
  COORDINATES = "COORDINATES",
}

export enum LOCATION_TYPE {
  ADDRESS = "ADDRESS",
  COORDINATES = "COORDINATES",
}

export enum PREFERRED_SLOT_POLICIES {
  ONLY_OPEN_DAY = "ONLY_OPEN_DAY",
  ONLY_PREFERRED_SLOTS = "ONLY_PREFERRED_SLOTS",
  COMBINED = "COMBINED",
}

export enum SERVICE_AREA_ALGORITHM {
  POLYGON = "POLYGON",
  RADIAL = "RADIAL",
}

export enum SCHEDULER_TRAVEL_MODE {
  BICYCLING = "BICYCLING",
  DRIVING = "DRIVING",
  TRANSIT = "TRANSIT",
  WALKING = "WALKING",
}

export enum SCHEDULER_DISTANCE_UNIT {
  KILOMETERS = "KILOMETERS",
  MILES = "MILES",
}

export enum SCHEDULER_SHAPE_TYPE {
  CIRCLE = "CIRCLE",
  POLYGON = "POLYGON",
}

export enum SCHEDULER_SHAPE_INCLUSION_METHOD {
  INCLUDE = "INCLUDE",
  EXCLUDE = "EXCLUDE",
}

export enum SCHEDULER_SHORT_TERM_LIMIT_HOURS_TYPE {
  HARD = "HARD",
  SOFT = "SOFT",
}

export enum RESERVATION_COMPLETE_BEHAVIOR {
  NONE = "NONE",
  SHOW_LINK = "SHOW_LINK",
  REDIRECT = "REDIRECT",
}

export enum TECHNICIAN_SELECTION_BEHAVIOR {
  ANY = "ANY",
  HIDDEN = "HIDDEN",
  PREFERRED_TECHNICIAN = "PREFERRED_TECHNICIAN",
}

export enum SCHEDULER_SLOT_FILTER_REASON_TYPE {
  AVAILABILITY_TIME = "AVAILABILITY_TIME",
  DUPLICATE_STARTS_AT = "DUPLICATE_STARTS_AT",
  MAX_APPOINTMENTS_PER_DAY = "MAX_APPOINTMENTS_PER_DAY",
  NOT_ENOUGH_TRAVEL_TIME = "NOT_ENOUGH_TRAVEL_TIME",
  OUTSIDE_LONG_TERM_LIMIT = "OUTSIDE_LONG_TERM_LIMIT",
  OUTSIDE_SERVICE_AREA = "OUTSIDE_SERVICE_AREA",
  OUTSIDE_SHORT_TERM_LIMIT = "OUTSIDE_SHORT_TERM_LIMIT",
  OVERLAPS_EXISTING_SLOT = "OVERLAPS_EXISTING_SLOT",
  SELF_SCHEDULE_MAX_TRAVEL_TIMES = "SELF_SCHEDULE_MAX_TRAVEL_TIMES",
  SUFFICIENT_SLOTS_FOUND = "SUFFICIENT_SLOTS_FOUND",
  UNABLE_TO_ROUND_START_TIME = "UNABLE_TO_ROUND_START_TIME",
  VIOLATES_FLOATING_DOWNTIME_RULE = "VIOLATES_FLOATING_DOWNTIME_RULE",
}

export enum SCHEDULER_SLOT_FLAG_TYPE {
  TRAFFIC = "TRAFFIC", // this one is not from the server, but we add this check client-side.
  NEARBY_EXISTING_EVENT = "NEARBY_EXISTING_EVENT",
  OUTSIDE_SERVICE_AREA = "OUTSIDE_SERVICE_AREA",
  OUTSIDE_SHORT_TERM_LIMIT = "OUTSIDE_SHORT_TERM_LIMIT",
  OUTSIDE_LONG_TERM_LIMIT = "OUTSIDE_LONG_TERM_LIMIT",
  SELF_SCHEDULE_MAX_TRAVEL_TIMES = "SELF_SCHEDULE_MAX_TRAVEL_TIMES",
  NO_ROUTE_BEFORE = "NO_ROUTE_BEFORE",
  NO_ROUTE_AFTER = "NO_ROUTE_AFTER",
}

export enum SCHEDULER_ROUTING_PREFERENCE {
  PROXIMITY = "PROXIMITY",
  ALONG_ROUTES = "ALONG_ROUTES",
}
