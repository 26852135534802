const translationStrings: {[key: string]: any} = {
  da_DK: require('../../../strings/build/da_DK.json'),
  de_DE: require('../../../strings/build/de_DE.json'),
  el_GR: require('../../../strings/build/el_GR.json'),
  en_US: {}, // No translation.  Always fall back to defaultMessage for English.
  fr_CA: require('../../../strings/build/fr_CA.json'),
  fr_FR: require('../../../strings/build/fr_CA.json'),
  es_US: require('../../../strings/build/es_US.json'),
  es_MX: require('../../../strings/build/es_US.json'),
  es_CL: require('../../../strings/build/es_US.json'),
  is_IS: require('../../../strings/build/is_IS.json'),
  it_IT: require('../../../strings/build/it_IT.json'),
  ja_JP: require('../../../strings/build/ja_JP.json'),
  ko_KR: require('../../../strings/build/ko_KR.json'),
  nb_NO: require('../../../strings/build/nb_NO.json'),
  nl_NL: require('../../../strings/build/nl_NL.json'),
  zh_CN: require('../../../strings/build/zh_CN.json'),
  zh_TW: require('../../../strings/build/zh_TW.json'),
  pl_PL: require('../../../strings/build/pl_PL.json'),
};

export { translationStrings };
