import { computed, makeObservable, observable } from 'mobx';
import { I18nString } from "@core/models/i18n_string";

class User {
  id: string = null;
  firstName: string = null;
  lastName: string = null;
  isSchedulable: boolean = null;
  timezone: string = null;
  shortTermLimitMessage: string = null;
  shortTermLimitPolicy: string = null;
  internationalizedShortTermLimitMessage: I18nString = null;

  constructor(attrs: any) {
    makeObservable(this, {
      id: observable,
      firstName: observable,
      lastName: observable,
      isSchedulable: observable,
      timezone: observable,
      shortTermLimitMessage: observable,
      shortTermLimitPolicy: observable,
      internationalizedShortTermLimitMessage: observable,
      fullName: computed
    });

    this.id = attrs.id;
    this.firstName = attrs.firstName;
    this.lastName = attrs.lastName;
    this.isSchedulable = attrs.isSchedulable;
    this.timezone = attrs.timezone;

    if (attrs.settings) {
      this.shortTermLimitMessage = attrs.settings.selfSchedulerShortTermLimitMessage;
      this.shortTermLimitPolicy = attrs.settings.selfSchedulerShortTermLimitPolicy;
      if (attrs.settings.selfSchedulerInternationalizedShortTermLimitMessage) {
        this.internationalizedShortTermLimitMessage = new I18nString(attrs.settings.selfSchedulerInternationalizedShortTermLimitMessage);
      } else {
        this.internationalizedShortTermLimitMessage = null;
      }
    }
  }

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }
}

export { User };
