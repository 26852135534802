import * as React from 'react';
import * as moment from 'moment-timezone';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { Button, Col, Row } from 'reactstrap';
import DatePicker, { registerLocale } from 'react-datepicker';

import { LargeErrorMessage, scrollTop, StepContent, StepFooter, User } from "@core";

import { UserSelector } from './UserSelector';
import {
  formatMessage,
  getJavascriptDateAsMomentDate,
  getLocaleStoreInstance,
  getMomentDateAsJavascriptDate
} from "@gazelle/shared/utils";
import {
  MSG_nextTitle,
  MSG_noTechniciansAvaialbleMessage,
  MSG_noTechniciansAvailableTitle,
  MSG_preferredDateTitle,
  MSG_preferredTechnicianTitle
} from "@gazelle/shared/strings";
import { rootStoreInstance as rootStore } from "@client_scheduler/modules/root";

import da from 'date-fns/locale/da';
import de from 'date-fns/locale/de';
import en_US from 'date-fns/locale/en-US';
import en_NZ from 'date-fns/locale/en-NZ';
import en_GB from 'date-fns/locale/en-GB';
import en_AU from 'date-fns/locale/en-AU';
import en_CA from 'date-fns/locale/en-CA';
import el from 'date-fns/locale/el';
import fr_CA from 'date-fns/locale/fr-CA';
import fr_FR from 'date-fns/locale/fr';
import es from 'date-fns/locale/es';
import is from 'date-fns/locale/is';
import it from 'date-fns/locale/it';
import ja from 'date-fns/locale/ja';
import ko from 'date-fns/locale/ko';
import nb from 'date-fns/locale/nb';
import nl from 'date-fns/locale/nl';
import zh_CN from 'date-fns/locale/zh-CN';
import zh_TW from 'date-fns/locale/zh-TW';

registerLocale('en_US', en_US);
registerLocale('en_NZ', en_NZ);
registerLocale('en_GB', en_GB);
registerLocale('en_AU', en_AU);
registerLocale('en_CA', en_CA);
registerLocale('el_GR', el);
registerLocale('es_US', es);
registerLocale('es_CL', es);
registerLocale('es_MX', es);
registerLocale('da_DK', da);
registerLocale('de_DE', de);
registerLocale('it_IT', it);
registerLocale('is_IS', is);
registerLocale('ja_JP', ja);
registerLocale('ko_KR', ko);
registerLocale('fr_FR', fr_FR);
registerLocale('fr_CA', fr_CA);
registerLocale('nb_NO', nb);
registerLocale('nl_NL', nl);
registerLocale('zh_CN', zh_CN);
registerLocale('zh_TW', zh_TW);

interface IStep3PrefsProps {}

const Step3Prefs = observer(class Step3Prefs extends React.Component<IStep3PrefsProps> {
  monthsShown = 2;

  constructor(props: IStep3PrefsProps) {
    super(props);

    makeObservable(this, {
      monthsShown: observable
    });
  }

  componentDidMount() {
    scrollTop();
  }

  handleNextButtonClicked = () => {
    if (rootStore.isStepComplete[3]) {
      rootStore.unlockStep(4);
      rootStore.setCurrentStep(4);
      rootStore.lockStep(5);
      if (rootStore.schedulerV2Enabled) {
        void rootStore.searchStore.performV2Search();
      } else {
        void rootStore.searchStore.performSearch();
      }
    }
  };

  handleTargetDateSelected = (date: Date) => {
    rootStore.prefStore.setTargetDate(getJavascriptDateAsMomentDate(date));
  };

  handleUserToggled = (user: User, isChecked: boolean) => {
    rootStore.prefStore.setPreferredUser(user, isChecked);
  };

  render() {
    const {prefStore} = rootStore;

    // In the rare case that there are no users that perform all the services
    // they selected, just direct them to call the technician to schedule.
    if (!prefStore.availableUsers.length) {
      return (
        <div>
          <StepContent>
            <LargeErrorMessage
              title={formatMessage(MSG_noTechniciansAvailableTitle)}
              message={formatMessage(MSG_noTechniciansAvaialbleMessage, {
                phone: rootStore.companyPhoneNumber
              })}/>
          </StepContent>
          <StepFooter rootStore={rootStore}/>
        </div>
      );
    }

    return (
      <div>
        <StepContent className="step3-appointment-container">
          <Row>
            <Col xs={12} lg={rootStore.users.length > 1 && rootStore.companySettings.defaultTechnician !== 'HIDDEN' ? 6 : 12} className="text-center mb-3">
              <h4 className="mb-3">{formatMessage(MSG_preferredDateTitle)}</h4>
              <DatePicker inline
                          locale={getLocaleStoreInstance().localization.locale}
                          filterDate={date => moment(date).isAfter() || moment(date).isSame(moment(), 'day')}
                          selected={getMomentDateAsJavascriptDate(prefStore.targetDate)}
                          onChange={this.handleTargetDateSelected} />
            </Col>
            {rootStore.users.length > 1 && rootStore.companySettings.defaultTechnician !== 'HIDDEN' &&
              <Col xs={12} lg={6} className="text-center mb-3 mt-5 mt-lg-0">
                <h4 className="mb-3">{formatMessage(MSG_preferredTechnicianTitle)}</h4>

                {prefStore.availableUsers.map(user => (
                  <UserSelector key={user.id}
                                user={user}
                                isDefaultChecked={prefStore.selectedUsers.indexOf(user) >= 0}
                                onChange={this.handleUserToggled} />))}
                <UserSelector anyUser
                              isDefaultChecked={prefStore.isAnyUserSelected}
                              onChange={this.handleUserToggled} />
              </Col>}
          </Row>
        </StepContent>
        <StepFooter
          rootStore={rootStore}
          rightSide={
            <div>
              <Button size="lg"
                      id="next-button"
                      className="d-none d-md-inline-block"
                      color="primary"
                      disabled={!rootStore.isStepComplete[3]}
                      onClick={this.handleNextButtonClicked}>
                {formatMessage(MSG_nextTitle)}
              </Button>
              <Button className="d-md-none"
                      id="next-button"
                      color="primary"
                      disabled={!rootStore.isStepComplete[3]}
                      onClick={this.handleNextButtonClicked}>
                {formatMessage(MSG_nextTitle)}
              </Button>
            </div>
          }/>
      </div>
    );
  }
});

export { Step3Prefs };
