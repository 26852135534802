import * as React from 'react';
import { Spinner } from './Spinner';
import { MSG_initializingEllipsis, MSG_loadingEllipsis, MSG_verifyingEllipsis } from "@gazelle/shared/strings";
import { formatMessage } from "@gazelle/shared/utils";

interface ILoadingIndicatorProps {
  verifying?: boolean,
  initializing?: boolean,
  noPadding?: boolean,
}
class LoadingIndicator extends React.Component<ILoadingIndicatorProps> {
  render() {
    let message = formatMessage(MSG_loadingEllipsis);
    if (this.props.verifying) {
      message = formatMessage(MSG_verifyingEllipsis);
    }
    if (this.props.initializing) {
      message = formatMessage(MSG_initializingEllipsis);
    }

    let classNames = ['loading-container'];
    if (this.props.noPadding) classNames.push('without-padding');

    return (
      <div className={classNames.join(' ')}>
        <div className="spinner"><Spinner /></div>
        <div>{message}</div>
      </div>
    );
  }
}

export { LoadingIndicator };
