import { STRIPE_PAYMENT_METHODS } from "../enums";
import { formatMessage } from "./intl";
import {
  MSG_invoicePaymentTypeAch,
  MSG_invoicePaymentTypeBancontact,
  MSG_invoicePaymentTypeEps,
  MSG_invoicePaymentTypeGiropay,
  MSG_invoicePaymentTypeIdeal,
  MSG_invoicePaymentTypePrzelewy24,
  MSG_invoicePaymentTypeSofort,
  MSG_stripePaymentMethodCard,
  MSG_stripePaymentMethodSepaDebit
} from "../strings";

// Use this if you want to iterate over all the status (e.g. for a <select>)
const SortedStripePaymentMethods = [
  STRIPE_PAYMENT_METHODS.CARD,
  STRIPE_PAYMENT_METHODS.ACH,
  STRIPE_PAYMENT_METHODS.SEPA_DEBIT,
  STRIPE_PAYMENT_METHODS.BANCONTACT,
  STRIPE_PAYMENT_METHODS.EPS,
  STRIPE_PAYMENT_METHODS.GIROPAY,
  STRIPE_PAYMENT_METHODS.IDEAL,
  STRIPE_PAYMENT_METHODS.SOFORT,
  STRIPE_PAYMENT_METHODS.P24,
];

const StripePaymentMethodsInfo: {[key: string]: {type: STRIPE_PAYMENT_METHODS, getLabel: () => string, methodEnabledAccessor: string}} = {
  [STRIPE_PAYMENT_METHODS.CARD]: {
    type: STRIPE_PAYMENT_METHODS.CARD,
    getLabel: () => formatMessage(MSG_stripePaymentMethodCard),
    methodEnabledAccessor: 'isCardActive',
  },
  [STRIPE_PAYMENT_METHODS.ACH]: {
    type: STRIPE_PAYMENT_METHODS.ACH,
    getLabel: () => formatMessage(MSG_invoicePaymentTypeAch),
    methodEnabledAccessor: 'isCardActive',
  },
  [STRIPE_PAYMENT_METHODS.SEPA_DEBIT]: {
    type: STRIPE_PAYMENT_METHODS.SEPA_DEBIT,
    getLabel: () => formatMessage(MSG_stripePaymentMethodSepaDebit),
    methodEnabledAccessor: 'isSepaActive',
  },
  [STRIPE_PAYMENT_METHODS.BANCONTACT]: {
    type: STRIPE_PAYMENT_METHODS.BANCONTACT,
    getLabel: () => formatMessage(MSG_invoicePaymentTypeBancontact),
    methodEnabledAccessor: 'isBancontactActive',
  },
  [STRIPE_PAYMENT_METHODS.EPS]: {
    type: STRIPE_PAYMENT_METHODS.EPS,
    getLabel: () => formatMessage(MSG_invoicePaymentTypeEps),
    methodEnabledAccessor: 'isEpsActive',
  },
  [STRIPE_PAYMENT_METHODS.GIROPAY]: {
    type: STRIPE_PAYMENT_METHODS.GIROPAY,
    getLabel: () => formatMessage(MSG_invoicePaymentTypeGiropay),
    methodEnabledAccessor: 'isGiropayActive',
  },
  [STRIPE_PAYMENT_METHODS.IDEAL]: {
    type: STRIPE_PAYMENT_METHODS.IDEAL,
    getLabel: () => formatMessage(MSG_invoicePaymentTypeIdeal),
    methodEnabledAccessor: 'isIdealActive',
  },
  [STRIPE_PAYMENT_METHODS.SOFORT]: {
    type: STRIPE_PAYMENT_METHODS.SOFORT,
    getLabel: () => formatMessage(MSG_invoicePaymentTypeSofort),
    methodEnabledAccessor: 'isSofortActive',
  },
  [STRIPE_PAYMENT_METHODS.P24]: {
    type: STRIPE_PAYMENT_METHODS.P24,
    getLabel: () => formatMessage(MSG_invoicePaymentTypePrzelewy24),
    methodEnabledAccessor: 'isP24Active',
  },
};

export { SortedStripePaymentMethods, StripePaymentMethodsInfo };
