import { CLIENT_ALL_GQL_QUERY_FIELDS } from "@core/models/client";
import { PIANO_ALL_GQL_QUERY_FIELDS } from "@core/models/piano";
import { CONTACT_ALL_GQL_QUERY_FIELDS } from "@core/models/contact";

const ESTIMATE_ALL_GQL_QUERY_FIELDS = `
  id locale number notes expiresOn estimatedOn currentPerformanceLevel
  client { ${CLIENT_ALL_GQL_QUERY_FIELDS} }
  contact { ${CONTACT_ALL_GQL_QUERY_FIELDS} }
  pianoPhoto { id original { url  }}
  piano {
    ${PIANO_ALL_GQL_QUERY_FIELDS}
    potentialPerformanceLevel
  }

  allEstimateTiers {
    id sequenceNumber isPrimary total subtotal taxTotal targetPerformanceLevel allowSelfSchedule notes
    recommendation {
      id type name explanation
    }
    allEstimateTierGroups {
      id name sequenceNumber
      allEstimateTierItems {
        id name sequenceNumber description educationDescription quantity amount duration type externalUrl isTaxable isTuning
        total subtotal taxTotal
        masterServiceItem { id }
        allEstimateTierItemPhotos {
          id sequenceNumber pianoPhoto { id thumbnail { url } original { url }}
        }
        taxes {
          id name rate total
        }
      }
    }
    allUngroupedEstimateTierItems {
      id name sequenceNumber description educationDescription quantity amount duration type externalUrl isTaxable isTuning
      total subtotal taxTotal
      masterServiceItem { id }
      allEstimateTierItemPhotos {
        id sequenceNumber pianoPhoto { id thumbnail { url } original { url }}
      }
      taxes {
        id name rate total
      }
    }
  }
`;

export { ESTIMATE_ALL_GQL_QUERY_FIELDS };
