import { RootStore } from "../../stores";
import { FilterType, IFilterSet, IFilterTypeConfig, ISelectFilter } from "../filters";
import { formatMessage } from "../intl";
import {
  MSG_activePianoCountLabel,
  MSG_allActivePianosLastTunedAfterLabel,
  MSG_allActivePianosLastTunedBeforeLabel,
  MSG_allActivePianosLastTunedLabel,
  MSG_allActivePianosNextTuningDueAfterLabel,
  MSG_allActivePianosNextTuningDueBeforeLabel,
  MSG_allActivePianosNextTuningDueLabel,
  MSG_allContactsHaveEmails,
  MSG_allContactsHavePhones,
  MSG_allContactsHaveTextablePhones,
  MSG_allOfTheseTags,
  MSG_allPianoStatusLabel,
  MSG_allPianoStatusShortLabel,
  MSG_anyActivePianoLastTuned,
  MSG_anyActivePianoLastTunedAfterLabel,
  MSG_anyActivePianoLastTunedBeforeLabel,
  MSG_anyActivePianoNextTuningDueAfterLabel,
  MSG_anyActivePianoNextTuningDueBeforeLabel,
  MSG_anyActivePianoNextTuningDueLabel,
  MSG_anyOfTheseReferralTypesLabel,
  MSG_anyOfTheseTags,
  MSG_anyPianoStatusLabel,
  MSG_anyPianoStatusShortLabel,
  MSG_billingContactHasEmail,
  MSG_billingContactHasNoEmail,
  MSG_billingContactHasNoPhone,
  MSG_billingContactHasNoTextablePhone,
  MSG_billingContactHasPhone,
  MSG_billingContactHasTextablePhone,
  MSG_cityLabel,
  MSG_clientsNotTuned24Months,
  MSG_clientsNotTuned36Months,
  MSG_clientsNotTuned60Months,
  MSG_clientTypeLabel,
  MSG_createdAfterOrOnLabel,
  MSG_createdBeforeOrOnLabel,
  MSG_createdTitle,
  MSG_dateCreatedLabel,
  MSG_doesNotHaveBadAddressLabel,
  MSG_emailAddressesLabel,
  MSG_hasAnyAppointmentAfterLabel,
  MSG_hasAnyAppointmentBeforeLabel,
  MSG_hasAnyAppointmentLabel,
  MSG_hasBadAddressLabel,
  MSG_hasFutureAppointmentScheduledLabel,
  MSG_hasNoFutureAppointmentScheduledLabel,
  MSG_hasNoPastAppointmentScheduledLabel,
  MSG_hasNoTimelineActivityLabel,
  MSG_hasPastAppointmentScheduledLabel,
  MSG_hasTimelineActivityLabel,
  MSG_hasTuningAppointmentAfterLabel,
  MSG_hasTuningAppointmentBeforeLabel,
  MSG_hasTuningAppointmentLabel,
  MSG_nameLabel,
  MSG_noContactsHaveEmail,
  MSG_noContactsHavePhone,
  MSG_noContactsHaveTextablePhone,
  MSG_noneOfTheseTags,
  MSG_noPreferredTechnicianLabel,
  MSG_noReminderLabel,
  MSG_noTimelineActivityAfterLabel,
  MSG_noTimelineActivityBeforeLabel,
  MSG_pausedLabel,
  MSG_pausedStatusLabel,
  MSG_phoneNumbersLabel,
  MSG_postCodeLabel,
  MSG_postCodeTitle,
  MSG_preferredTechnicianLabel,
  MSG_primaryContactHasEmail,
  MSG_primaryContactHasNoEmail,
  MSG_primaryContactHasNoPhone,
  MSG_primaryContactHasNoTextablePhone,
  MSG_primaryContactHasPhone,
  MSG_primaryContactHasTextablePhone,
  MSG_primaryContactsDoesntWantEmail,
  MSG_primaryContactsDoesntWantPhoneCalls,
  MSG_primaryContactsDoesntWantSMS,
  MSG_primaryContactsWantsEmail,
  MSG_primaryContactsWantsPhoneCalls,
  MSG_primaryContactsWantsSMS,
  MSG_remindersLabel,
  MSG_stateProvinceLabel,
  MSG_statusLabel,
  MSG_textablePhoneNumbersLabel,
  MSG_timelineActivityAfterLabel,
  MSG_timelineActivityBeforeLabel
} from "../../strings";
import { ClientStatusTypeInfo, SortedClientStatusTypes } from "../client_status_type_info";
import { CLIENT_TYPES } from "../client_types";
import { faComment, faEnvelope, faPhoneAlt, faRepeatAlt, faTag, faUser } from "@fortawesome/pro-solid-svg-icons";
import {
  CONTACTS_WITH_EMAIL,
  CONTACTS_WITH_PHONE,
  CONTACTS_WITH_TEXTABLE_PHONE,
  PIANO_STATUS,
  USER_ACCESS_LEVEL
} from "../../enums";
import { PianoStatusInfo, SortedPianoStatuses } from "../piano_status_info";
import { REFERRAL_LIST } from "../referrals";

const moment = require('moment-timezone');

export function getClientFiltersConfig(rootStore: RootStore, options: {tags?: string[]} = {}): IFilterTypeConfig[] {
  return [{
    key: 'status',
    type: FilterType.SELECT,
    buttonLabel: formatMessage(MSG_statusLabel),
    selectorLabel: formatMessage(MSG_statusLabel),
    queryArgumentKey: 'status',
    options: SortedClientStatusTypes.map(t => ({
      label: ClientStatusTypeInfo[t].getLabel(),
      value: t,
    }))
  }, {
    key: 'clientType',
    type: FilterType.SELECT,
    buttonLabel: formatMessage(MSG_clientTypeLabel),
    selectorLabel: formatMessage(MSG_clientTypeLabel),
    queryArgumentKey: 'clientType',
    options: CLIENT_TYPES.map(t => ({ label: t, value: t }))
  }, {
    key: 'preferredTechnicianIds',
    type: FilterType.SELECT,
    buttonLabel: formatMessage(MSG_preferredTechnicianLabel),
    selectorLabel: formatMessage(MSG_preferredTechnicianLabel),
    queryArgumentKey: 'preferredTechnicianIds',
    icon: faUser,
    getQueryVariables: (filter: ISelectFilter) => {
      if (filter.value && filter.value.length > 0) {
        return {preferredTechnicianIds: filter.value.map(v => ({value: v}))};
      } else {
        return {preferredTechnicianIds: []};
      }
    },
    options: [
      {label: formatMessage(MSG_noPreferredTechnicianLabel), value: null},
      ...rootStore.allActiveUsers
      .filter(user => {
        return user.accessLevel !== USER_ACCESS_LEVEL.ASSISTANT;
      }).map(u => ({
        label: u.fullName,
        value: u.id
      }))
    ]
  }, {
    key: 'lifecycleIds',
    type: FilterType.SELECT,
    buttonLabel: formatMessage(MSG_remindersLabel),
    selectorLabel: formatMessage(MSG_remindersLabel),
    icon: faRepeatAlt,
    queryArgumentKey: 'lifecycleIds',
    getQueryVariables: (filter: ISelectFilter) => {
      if (filter.value && filter.value.length > 0) {
        return {lifecycleIds: filter.value.map(v => ({value: v}))};
      } else {
        return {lifecycleIds: []};
      }
    },
    options: [
      {label: formatMessage(MSG_noReminderLabel), value: null},
      ...rootStore.allLifecycles.map(l => ({
        label: l.name,
        value: l.id
      }))
    ]
  }, {
    key: 'isPaused',
    type: FilterType.BOOLEAN,
    buttonLabel: formatMessage(MSG_pausedLabel),
    selectorLabel: formatMessage(MSG_pausedStatusLabel),
    queryArgumentKey: 'isPaused'
  }, {
    key: 'hasBadAddress',
    type: FilterType.BOOLEAN,
    buttonLabel: formatMessage(MSG_hasBadAddressLabel),
    negativeButtonLabel: formatMessage(MSG_doesNotHaveBadAddressLabel),
    selectorLabel: formatMessage(MSG_hasBadAddressLabel),
    queryArgumentKey: 'hasBadAddress'
  }, {
    key: 'hasTimelineActivity',
    type: FilterType.DATE,
    buttonLabel: formatMessage(MSG_hasTimelineActivityLabel),
    selectorLabel: formatMessage(MSG_hasTimelineActivityLabel),
    dateAfterOrOnLabel: formatMessage(MSG_timelineActivityAfterLabel),
    dateBeforeOrOnLabel: formatMessage(MSG_timelineActivityBeforeLabel),
    queryArgumentKey: 'hasTimelineActivity'
  }, {
    key: 'hasNoTimelineActivity',
    type: FilterType.DATE,
    buttonLabel: formatMessage(MSG_hasNoTimelineActivityLabel),
    selectorLabel: formatMessage(MSG_hasNoTimelineActivityLabel),
    dateAfterOrOnLabel: formatMessage(MSG_noTimelineActivityAfterLabel),
    dateBeforeOrOnLabel: formatMessage(MSG_noTimelineActivityBeforeLabel),
    queryArgumentKey: 'hasNoTimelineActivity'
  }, {
    key: 'hasAppointment',
    type: FilterType.DATE,
    buttonLabel: formatMessage(MSG_hasAnyAppointmentLabel),
    selectorLabel: formatMessage(MSG_hasAnyAppointmentLabel),
    dateAfterOrOnLabel: formatMessage(MSG_hasAnyAppointmentAfterLabel),
    dateBeforeOrOnLabel: formatMessage(MSG_hasAnyAppointmentBeforeLabel),
    queryArgumentKey: 'hasAppointment'
  }, {
    key: 'hasTuningAppointment',
    type: FilterType.DATE,
    buttonLabel: formatMessage(MSG_hasTuningAppointmentLabel),
    selectorLabel: formatMessage(MSG_hasTuningAppointmentLabel),
    dateAfterOrOnLabel: formatMessage(MSG_hasTuningAppointmentAfterLabel),
    dateBeforeOrOnLabel: formatMessage(MSG_hasTuningAppointmentBeforeLabel),
    queryArgumentKey: 'hasTuningAppointment'
  }, {
    key: 'hasPastAppointmentScheduled',
    type: FilterType.BOOLEAN,
    buttonLabel: formatMessage(MSG_hasPastAppointmentScheduledLabel),
    negativeButtonLabel: formatMessage(MSG_hasNoPastAppointmentScheduledLabel),
    selectorLabel: formatMessage(MSG_hasPastAppointmentScheduledLabel),
    queryArgumentKey: 'hasPastAppointmentScheduled'
  }, {
    key: 'hasFutureAppointmentScheduled',
    type: FilterType.BOOLEAN,
    buttonLabel: formatMessage(MSG_hasFutureAppointmentScheduledLabel),
    negativeButtonLabel: formatMessage(MSG_hasNoFutureAppointmentScheduledLabel),
    selectorLabel: formatMessage(MSG_hasFutureAppointmentScheduledLabel),
    queryArgumentKey: 'hasFutureAppointmentScheduled'
  }, {
    key: 'hasPianoLastTuned',
    type: FilterType.DATE,
    buttonLabel: formatMessage(MSG_anyActivePianoLastTuned),
    selectorLabel: formatMessage(MSG_anyActivePianoLastTuned),
    dateAfterOrOnLabel: formatMessage(MSG_anyActivePianoLastTunedAfterLabel),
    dateBeforeOrOnLabel: formatMessage(MSG_anyActivePianoLastTunedBeforeLabel),
    queryArgumentKey: 'hasPianoLastTuned'
  }, {
    key: 'nextTuningDue',
    type: FilterType.DATE,
    buttonLabel: formatMessage(MSG_anyActivePianoNextTuningDueLabel),
    selectorLabel: formatMessage(MSG_anyActivePianoNextTuningDueLabel),
    dateAfterOrOnLabel: formatMessage(MSG_anyActivePianoNextTuningDueAfterLabel),
    dateBeforeOrOnLabel: formatMessage(MSG_anyActivePianoNextTuningDueBeforeLabel),
    queryArgumentKey: 'nextTuningDue',
  }, {
    key: 'allActivePianosLastTuned',
    type: FilterType.DATE,
    buttonLabel: formatMessage(MSG_allActivePianosLastTunedLabel),
    selectorLabel: formatMessage(MSG_allActivePianosLastTunedLabel),
    dateAfterOrOnLabel: formatMessage(MSG_allActivePianosLastTunedAfterLabel),
    dateBeforeOrOnLabel: formatMessage(MSG_allActivePianosLastTunedBeforeLabel),
    queryArgumentKey: 'allActivePianosLastTuned'
  }, {
    key: 'allActivePianosNextTuningDue',
    type: FilterType.DATE,
    buttonLabel: formatMessage(MSG_allActivePianosNextTuningDueLabel),
    selectorLabel: formatMessage(MSG_allActivePianosNextTuningDueLabel),
    dateAfterOrOnLabel: formatMessage(MSG_allActivePianosNextTuningDueAfterLabel),
    dateBeforeOrOnLabel: formatMessage(MSG_allActivePianosNextTuningDueBeforeLabel),
    queryArgumentKey: 'allActivePianosNextTuningDue'
  }, {
    key: 'created',
    type: FilterType.DATE,
    buttonLabel: formatMessage(MSG_createdTitle),
    selectorLabel: formatMessage(MSG_createdTitle),
    dateAfterOrOnLabel: formatMessage(MSG_createdAfterOrOnLabel),
    dateBeforeOrOnLabel: formatMessage(MSG_createdBeforeOrOnLabel),
    queryArgumentKey: 'created'
  }, {
    key: 'postCode',
    type: FilterType.EXACT_STRING,
    buttonLabel: formatMessage(MSG_postCodeTitle),
    selectorLabel: formatMessage(MSG_postCodeTitle),
    queryArgumentKey: 'postCode'
  }, {
    key: 'city',
    type: FilterType.EXACT_STRING,
    buttonLabel: formatMessage(MSG_cityLabel),
    selectorLabel: formatMessage(MSG_cityLabel),
    queryArgumentKey: 'city'
  }, {
    key: 'state',
    type: FilterType.EXACT_STRING,
    buttonLabel: formatMessage(MSG_stateProvinceLabel),
    selectorLabel: formatMessage(MSG_stateProvinceLabel),
    queryArgumentKey: 'state'
  }, {
    key: 'contactsWithEmail',
    type: FilterType.RADIO,
    buttonLabel: formatMessage(MSG_emailAddressesLabel),
    selectorLabel: formatMessage(MSG_emailAddressesLabel),
    queryArgumentKey: 'contactsWithEmail',
    icon: faEnvelope,
    defaultValue: CONTACTS_WITH_EMAIL.NO_CONTACTS_HAVE_EMAIL,
    options: [{
      label: formatMessage(MSG_noContactsHaveEmail),
      value: CONTACTS_WITH_EMAIL.NO_CONTACTS_HAVE_EMAIL,
    }, {
      label: formatMessage(MSG_allContactsHaveEmails),
      value: CONTACTS_WITH_EMAIL.ALL_CONTACTS_HAVE_EMAIL,
    }, {
      label: formatMessage(MSG_primaryContactHasNoEmail),
      value: CONTACTS_WITH_EMAIL.PRIMARY_CONTACT_HAS_NO_EMAIL,
    }, {
      label: formatMessage(MSG_primaryContactHasEmail),
      value: CONTACTS_WITH_EMAIL.PRIMARY_CONTACT_HAS_EMAIL,
    }, {
      label: formatMessage(MSG_billingContactHasNoEmail),
      value: CONTACTS_WITH_EMAIL.BILLING_CONTACT_HAS_NO_EMAIL,
    }, {
      label: formatMessage(MSG_billingContactHasEmail),
      value: CONTACTS_WITH_EMAIL.BILLING_CONTACT_HAS_EMAIL,
    }]
  }, {
    key: 'contactsWithPhone',
    type: FilterType.RADIO,
    buttonLabel: formatMessage(MSG_phoneNumbersLabel),
    selectorLabel: formatMessage(MSG_phoneNumbersLabel),
    queryArgumentKey: 'contactsWithPhone',
    icon: faPhoneAlt,
    defaultValue: CONTACTS_WITH_PHONE.NO_CONTACTS_HAVE_PHONE,
    options: [{
      label: formatMessage(MSG_noContactsHavePhone),
      value: CONTACTS_WITH_PHONE.NO_CONTACTS_HAVE_PHONE,
    }, {
      label: formatMessage(MSG_allContactsHavePhones),
      value: CONTACTS_WITH_PHONE.ALL_CONTACTS_HAVE_PHONE,
    }, {
      label: formatMessage(MSG_primaryContactHasNoPhone),
      value: CONTACTS_WITH_PHONE.PRIMARY_CONTACT_HAS_NO_PHONE,
    }, {
      label: formatMessage(MSG_primaryContactHasPhone),
      value: CONTACTS_WITH_PHONE.PRIMARY_CONTACT_HAS_PHONE,
    }, {
      label: formatMessage(MSG_billingContactHasNoPhone),
      value: CONTACTS_WITH_PHONE.BILLING_CONTACT_HAS_NO_PHONE,
    }, {
      label: formatMessage(MSG_billingContactHasPhone),
      value: CONTACTS_WITH_PHONE.BILLING_CONTACT_HAS_PHONE,
    }]
  }, {
    key: 'contactsWithTextablePhone',
    type: FilterType.RADIO,
    buttonLabel: formatMessage(MSG_textablePhoneNumbersLabel),
    selectorLabel: formatMessage(MSG_textablePhoneNumbersLabel),
    queryArgumentKey: 'contactsWithTextablePhone',
    icon: faComment,
    defaultValue: CONTACTS_WITH_TEXTABLE_PHONE.NO_CONTACTS_HAVE_TEXTABLE_PHONE,
    options: [{
      label: formatMessage(MSG_noContactsHaveTextablePhone),
      value: CONTACTS_WITH_TEXTABLE_PHONE.NO_CONTACTS_HAVE_TEXTABLE_PHONE,
    }, {
      label: formatMessage(MSG_allContactsHaveTextablePhones),
      value: CONTACTS_WITH_TEXTABLE_PHONE.ALL_CONTACTS_HAVE_TEXTABLE_PHONE,
    }, {
      label: formatMessage(MSG_primaryContactHasNoTextablePhone),
      value: CONTACTS_WITH_TEXTABLE_PHONE.PRIMARY_CONTACT_HAS_NO_TEXTABLE_PHONE,
    }, {
      label: formatMessage(MSG_primaryContactHasTextablePhone),
      value: CONTACTS_WITH_TEXTABLE_PHONE.PRIMARY_CONTACT_HAS_TEXTABLE_PHONE,
    }, {
      label: formatMessage(MSG_billingContactHasNoTextablePhone),
      value: CONTACTS_WITH_TEXTABLE_PHONE.BILLING_CONTACT_HAS_NO_TEXTABLE_PHONE,
    }, {
      label: formatMessage(MSG_billingContactHasTextablePhone),
      value: CONTACTS_WITH_TEXTABLE_PHONE.BILLING_CONTACT_HAS_TEXTABLE_PHONE,
    }]
  }, {
    key: 'anyPianoStatus',
    type: FilterType.SELECT,
    buttonLabel: formatMessage(MSG_anyPianoStatusLabel),
    selectorLabel: formatMessage(MSG_anyPianoStatusShortLabel),
    queryArgumentKey: 'anyPianoStatus',
    options: SortedPianoStatuses.map(t => ({
      label: PianoStatusInfo[t].getLabel(),
      value: t,
    }))
  }, {
    key: 'allPianoStatus',
    type: FilterType.SELECT,
    buttonLabel: formatMessage(MSG_allPianoStatusLabel),
    selectorLabel: formatMessage(MSG_allPianoStatusShortLabel),
    queryArgumentKey: 'allPianoStatus',
    options: SortedPianoStatuses.map(t => ({
      label: PianoStatusInfo[t].getLabel(),
      value: t,
    }))
  }, {
    key: 'activePianoCount',
    type: FilterType.NUMBER,
    buttonLabel: formatMessage(MSG_activePianoCountLabel),
    selectorLabel: formatMessage(MSG_activePianoCountLabel),
    queryArgumentKey: 'activePianoCount'
  }, {
    key: 'anyTags',
    icon: faTag,
    type: FilterType.SELECT,
    buttonLabel: formatMessage(MSG_anyOfTheseTags),
    selectorLabel: formatMessage(MSG_anyOfTheseTags),
    queryArgumentKey: 'anyTags',
    options: (options.tags || []).map(t => ({label: t.toLocaleUpperCase(), value: t, specialStyle: 'tag'}))
  }, {
    key: 'allTags',
    icon: faTag,
    type: FilterType.SELECT,
    buttonLabel: formatMessage(MSG_allOfTheseTags),
    selectorLabel: formatMessage(MSG_allOfTheseTags),
    queryArgumentKey: 'allTags',
    options: (options.tags || []).map(t => ({label: t.toLocaleUpperCase(), value: t, specialStyle: 'tag'}))
  }, {
    key: 'excludeTags',
    icon: faTag,
    type: FilterType.SELECT,
    buttonLabel: formatMessage(MSG_noneOfTheseTags),
    selectorLabel: formatMessage(MSG_noneOfTheseTags),
    queryArgumentKey: 'excludeTags',
    options: (options.tags || []).map(t => ({label: t.toLocaleUpperCase(), value: t, specialStyle: 'tag'}))
  }, {
    key: 'referredBy',
    type: FilterType.SELECT,
    queryArgumentKey: 'referredBy',
    selectorLabel: formatMessage(MSG_anyOfTheseReferralTypesLabel),
    buttonLabel: formatMessage(MSG_anyOfTheseReferralTypesLabel),
    options: REFERRAL_LIST.map(t => ({label: t, value: t}))
  }, {
    key: 'primaryContactWantsEmail',
    type: FilterType.BOOLEAN,
    buttonLabel: formatMessage(MSG_primaryContactsWantsEmail),
    negativeButtonLabel: formatMessage(MSG_primaryContactsDoesntWantEmail),
    selectorLabel: formatMessage(MSG_primaryContactsWantsEmail),
    queryArgumentKey: 'primaryContactWantsEmail'
  }, {
    key: 'primaryContactWantsText',
    type: FilterType.BOOLEAN,
    buttonLabel: formatMessage(MSG_primaryContactsWantsSMS),
    negativeButtonLabel: formatMessage(MSG_primaryContactsDoesntWantSMS),
    selectorLabel: formatMessage(MSG_primaryContactsWantsSMS),
    queryArgumentKey: 'primaryContactWantsText'
  }, {
    key: 'primaryContactWantsPhone',
    type: FilterType.BOOLEAN,
    buttonLabel: formatMessage(MSG_primaryContactsWantsPhoneCalls),
    negativeButtonLabel: formatMessage(MSG_primaryContactsDoesntWantPhoneCalls),
    selectorLabel: formatMessage(MSG_primaryContactsWantsPhoneCalls),
    queryArgumentKey: 'primaryContactWantsPhone'
  }];
}

export function getClientFilterSets(rootStore: RootStore): IFilterSet[] {
  return [{
    label: formatMessage(MSG_clientsNotTuned24Months),
    filterState: {
      filters: [{
        key: 'status',
        type: FilterType.SELECT,
        value: [PIANO_STATUS.ACTIVE]
      },{
        key: 'allActivePianosLastTuned',
        type: FilterType.DATE,
        value: {lte: moment().subtract(24, 'months').format('YYYY-MM-DD')}
      }, {
        key: 'hasFutureAppointmentScheduled',
        type: FilterType.BOOLEAN,
        value: false
      }],
      searchString: '',
    }
  }, {
    label: formatMessage(MSG_clientsNotTuned36Months),
    filterState: {
      filters: [{
        key: 'status',
        type: FilterType.SELECT,
        value: [PIANO_STATUS.ACTIVE]
      },{
        key: 'allActivePianosLastTuned',
        type: FilterType.DATE,
        value: {lte: moment().subtract(36, 'months').format('YYYY-MM-DD')}
      }, {
        key: 'hasFutureAppointmentScheduled',
        type: FilterType.BOOLEAN,
        value: false
      }],
      searchString: '',
    }
  }, {
    label: formatMessage(MSG_clientsNotTuned60Months),
    filterState: {
      filters: [{
        key: 'status',
        type: FilterType.SELECT,
        value: [PIANO_STATUS.ACTIVE]
      },{
        key: 'allActivePianosLastTuned',
        type: FilterType.DATE,
        value: {lte: moment().subtract(60, 'months').format('YYYY-MM-DD')}
      }, {
        key: 'hasFutureAppointmentScheduled',
        type: FilterType.BOOLEAN,
        value: false
      }],
      searchString: '',
    }
  }];
}

export function getClientSortConfig() {
  return [{
    label: formatMessage(MSG_nameLabel),
    value: ['CLIENT_NAME_ASC', 'CREATED_AT_ASC'],
    reverseValue: ['CLIENT_NAME_DESC', 'CREATED_AT_DESC'],
  }, {
    label: formatMessage(MSG_dateCreatedLabel),
    value: 'CREATED_AT_ASC',
    reverseValue: 'CREATED_AT_DESC',
    reverseDefault: true,
  }, {
    label: formatMessage(MSG_statusLabel),
    value: ['STATUS_ASC', 'CLIENT_NAME_ASC', 'CREATED_AT_ASC'],
    reverseValue: ['STATUS_DESC', 'CLIENT_NAME_DESC', 'CREATED_AT_DESC'],
  }, {
    label: formatMessage(MSG_postCodeLabel),
    value: ['POSTAL_CODE_ASC', 'CLIENT_NAME_ASC', 'CREATED_AT_ASC'],
    reverseValue: ['POSTAL_CODE_DESC', 'CLIENT_NAME_DESC', 'CREATED_AT_DESC'],
  }, {
    label: formatMessage(MSG_cityLabel),
    value: ['CITY_ASC', 'CLIENT_NAME_ASC', 'CREATED_AT_ASC'],
    reverseValue: ['CITY_DESC', 'CLIENT_NAME_DESC', 'CREATED_AT_DESC'],
  }, {
    label: formatMessage(MSG_stateProvinceLabel),
    value: ['STATE_ASC', 'CLIENT_NAME_ASC', 'CREATED_AT_ASC'],
    reverseValue: ['STATE_DESC', 'CLIENT_NAME_DESC', 'CREATED_AT_DESC'],
  }];
}

export function getClientDefaultSort() {
  return ['STATUS_ASC', 'CLIENT_NAME_ASC', 'CREATED_AT_ASC'];
}
