import {
  FilterType,
  IDateFilter,
  IFilterConfigProps,
  IFilterHandler,
  IFilterLabelProps,
  IFilterTypeConfig
} from "./types";
import { faCalendarAlt } from "@fortawesome/pro-solid-svg-icons";

const moment = require('moment-timezone');

export const GenericDateFilterHandler: IFilterHandler = {
  type: FilterType.DATE,

  getIcon: () => faCalendarAlt,

  getQueryVariables: (filter: IDateFilter, config: IFilterTypeConfig) => {
    const {gte, lte} = filter.value;
    if (gte && lte) {
      return {[config.queryArgumentKey + 'Let']: lte, [config.queryArgumentKey + 'Get']: gte};
    } else if (gte) {
      return {[config.queryArgumentKey + 'Get']: gte};
    } else if (lte) {
      return {[config.queryArgumentKey + 'Let']: lte};
    } else {
      return {};
    }
  },

  isValid: (filter: IDateFilter) => {
    const {gte, lte} = filter.value;
    if (!gte && !lte) return false;
    if (gte && lte && lte < gte) return false;
    return true;
  },

  defaultValue: (config: IFilterTypeConfig) => {
    return {
      key: config.key,
      type: FilterType.DATE,
      value: {gte: moment().startOf('year').format('YYYY-MM-DD'), lte: null}
    };
  },

  LabelComponent: (props: IFilterLabelProps) => {
    throw "Expected DateFilterHandler to implement LabelConfig.";
  },

  ConfigComponent: (props: IFilterConfigProps) => {
    throw "Expected DateFilterHandler to implement ComponentConfig.";
  }
};
