import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { SCHEDULER_TRAVEL_MODE } from "../enums";
import { formatMessage } from "./intl";
import { faBicycle, faBusSimple, faCar, faPersonWalking } from '@fortawesome/pro-solid-svg-icons';
import {
  MSG_defaultTravelModeBicyclingLabel,
  MSG_defaultTravelModeDrivingLabel,
  MSG_defaultTravelModeTransitLabel,
  MSG_defaultTravelModeWalkingLabel
} from "../strings";

// Use this if you want to iterate over all the modes (e.g. for a <select>)
const SortedEventTravelModeTypes = [
  SCHEDULER_TRAVEL_MODE.DRIVING,
  SCHEDULER_TRAVEL_MODE.BICYCLING,
  SCHEDULER_TRAVEL_MODE.TRANSIT,
  SCHEDULER_TRAVEL_MODE.WALKING,
];

const EventTravelModeTypeInfo: {[key: string]: {type: SCHEDULER_TRAVEL_MODE, getLabel: () => string, icon: IconProp}} = {
  [SCHEDULER_TRAVEL_MODE.DRIVING]: {
    type: SCHEDULER_TRAVEL_MODE.DRIVING,
    getLabel: () => formatMessage(MSG_defaultTravelModeDrivingLabel),
    icon: faCar,
  },
  [SCHEDULER_TRAVEL_MODE.BICYCLING]: {
    type: SCHEDULER_TRAVEL_MODE.BICYCLING,
    getLabel: () => formatMessage(MSG_defaultTravelModeBicyclingLabel),
    icon: faBicycle,
  },
  [SCHEDULER_TRAVEL_MODE.TRANSIT]: {
    type: SCHEDULER_TRAVEL_MODE.TRANSIT,
    getLabel: () => formatMessage(MSG_defaultTravelModeTransitLabel),
    icon: faBusSimple,
  },
  [SCHEDULER_TRAVEL_MODE.WALKING]: {
    type: SCHEDULER_TRAVEL_MODE.WALKING,
    getLabel: () => formatMessage(MSG_defaultTravelModeWalkingLabel),
    icon: faPersonWalking,
  },
};

export { EventTravelModeTypeInfo, SortedEventTravelModeTypes };
