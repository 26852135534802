export const MSG_upcomingMessagesTitle = {
  id: "838e2a42-f66b-478f-9efb-f46bc9d98076",
  description: "This is a title above a list of messages that are scheduled to go out to a client.  They may be emails, phone calls, or text messages.",
  defaultMessage: "Upcoming Messages",
};

export const MSG_phoneCallReminderExplanation = {
  id: "fcde508c-c616-47a4-9633-ecccffed24e5",
  description: "An explanation of what a phone call reminder does.",
  defaultMessage: "Phone call reminders will display in your Call Center, reminding you to call the client.  The notes are notes about why the client needs a phone call.  The client will not see these notes."
};

export const MSG_callDueImmediatelyLabel = {
  id: "d211feab-ff2c-4180-85e7-f9c5433e9e05",
  description: "The label for a toggle switch that lets them say that the scheduled phone call is due immediately.",
  defaultMessage: "Call due immediately"
};

export const MSG_upcomingRemindersTitle = {
  id: "e093921c-22c3-42e5-bdc0-905e352ca308",
  defaultMessage: "Upcoming Reminders",
};
export const MSG_upcomingRemindersCountTitle = {
  id: "e24fd508-b6c5-4510-942d-adc1715fd49c",
  defaultMessage: "{num, plural, =1 {1 Upcoming Message or Call} other {# Upcoming Messages or Calls}}",
};

export const MSG_upcomingRemindersExplanation = {
  id: "bbdb523e-7eba-4d4b-9420-d14fc16935c0",
  description: "",
  defaultMessage: "This is a list of upcoming reminders.  It shows the next email, SMS, and phone call that is scheduled.  Additional reminders may be scheduled as this client's needs change."
};


export const MSG_assignedReminderTitle = {
  id: "332667c8-f1d5-4074-b297-47384a08d151",
  description: "This is the header for where we show which Reminder they are assigned to.",
  defaultMessage: "Assigned Reminder",
};

export const MSG_noReminderLabel = {
  id: "b523fc25-b7c0-4bdc-9fc6-93b0f12a260e",
  defaultMessage: "No reminder",
};

export const MSG_noReminderAssignedLabel = {
  id: "fff4d13e-d0c7-42fd-8a3f-2c1a48ef7003",
  defaultMessage: "No reminder assigned",
};

export const MSG_namedReminderLabel = {
  id: "39e0784f-86e0-4bd3-89d9-354ad24b69d2",
  description: "This is the name of the reminder the client is assigned to.  A 'reminder' is our term for a set of scheduled reminders that the client will receive.",
  defaultMessage: "Reminder: {name}",
};

export const MSG_noRemindersScheduled = {
  id: "9b1581a5-9045-4f7c-b878-1222424bed1f",
  description: "When no reminders are scheduled to go out.",
  defaultMessage: "No Reminder messages scheduled.",
};

export const MSG_selectAReminderTitle = {
  id: "8bff4d7e-7121-4abb-af36-99ab808c7188",
  defaultMessage: "Select a Reminder",
};

export const MSG_noRemindersFoundLabel = {
  id: "e4127164-29f4-45da-82ce-018abcffd024",
  defaultMessage: "No reminders found",
};

export const MSG_cannotSendReminderEmail = {
  id: "ecf61780-8667-4237-9b96-6b7620db4369",
  description: "",
  defaultMessage: "Reminder emails will not be sent for this client.  Either no email address is on file for the primary contact, or they requested to not receive emails."
};

export const MSG_cannotSendReminderSms = {
  id: "2c27e3e4-2acf-4c51-9cb7-3f620509f799",
  description: "",
  defaultMessage: "Reminder text messages will not be sent for this client.  Either no textable phone number is on file for the primary contact, or they requested to not receive text messages.  If you recently added this phone number, it may take a few minutes before its textable status has been verified."
};


export const MSG_lifecycleStateNoLifecycle = { //'no-lifecycle'
  id: "5d6c41f7-15b5-437d-8998-da51e52bdd2e",
  description: "This is the label for being in a reminder state of 'No Reminder'",
  defaultMessage: 'No Reminder',
};
export const MSG_lifecycleStateProspectClient = { //'prospect'
  id: "69afc100-dcb2-47f1-8823-c224abbccdc3",
  description: "This is the label for being in a reminder state of 'Prospective Client'",
  defaultMessage: 'Prospective Client',
};
export const MSG_lifecycleStateNewClient = { //'new'
  id: "81bac6e6-c513-4e55-b2d6-7e533a3221a3",
  description: "This is the label for being in a reminder state of 'New Client'",
  defaultMessage: 'New Client',
};
export const MSG_lifecycleStateActiveClient = { //'active'
  id: "76fe4f7f-38ac-4ad8-b662-27211b73fca6",
  description: "This is the label for being in a reminder state of 'Active Client",
  defaultMessage: 'Active Client'
};
export const MSG_lifecycleStateWithoutSchedulingDetails = { //'without-scheduling-details'
  id: "4ba2136b-1f2c-4514-ae81-ae6a3615d248",
  description: "This is the label for being in a reminder state of 'Incomplete Piano Information'",
  defaultMessage: 'Incomplete Piano Information',
};
export const MSG_lifecycleStateTuningScheduled = { //'tuning-scheduled'
  id: "33f2f102-67cc-4427-b6aa-cdc1e213ff14",
  description: "This is the label for being in a reminder state of 'Tuning Scheduled'",
  defaultMessage: 'Tuning Scheduled',
};
export const MSG_lifecycleStateNotDue = { //'not-due'
  id: "d5235268-4ed9-4909-9f79-0b786ba13b31",
  description: "This is the label for being in a reminder state of 'Not Due for Tuning'",
  defaultMessage: 'Not Due for Tuning',
};
export const MSG_lifecycleStateComingDue = { //'due'
  id: "fa5d79d1-8c2e-4381-aeed-8a22a888a78f",
  description: "This is the label for being in a reminder state of 'Coming Due for Tuning'",
  defaultMessage: 'Coming Due for Tuning',
};
export const MSG_lifecycleStateDue = { //'overdue1'
  id: "8c7d9ca7-7bec-47dc-b6db-a6e28cefc9a2",
  description: "This is the label for being in a reminder state of 'Due for Tuning'",
  defaultMessage: 'Due for Tuning',
};
export const MSG_lifecycleStateOverdue1 = { //'overdue2'
  id: "841a5d31-616d-4848-9f86-da8e4fc8a9ac",
  description: "This is the label for being in a reminder state of 'Overdue for Tuning (Stage 1)'",
  defaultMessage: 'Overdue for Tuning (Stage 1)',
};
export const MSG_lifecycleStateOverdue2 = { //'overdue3'
  id: "d45f2676-4741-40b4-aeda-471ee0dac3d3",
  description: "This is the label for being in a reminder state of 'Overdue for Tuning (Stage 2)'",
  defaultMessage: 'Overdue for Tuning (Stage 2)',
};
export const MSG_lifecycleStateOverdue3 = { //'overdue4'
  id: "6656d7e1-f3a8-4970-baa8-26e959b89e45",
  description: "This is the label for being in a reminder state of 'Overdue for Tuning (Stage 3)",
  defaultMessage: 'Overdue for Tuning (Stage 3)'
};
export const MSG_lifecycleStateComplete = { //'complete'
  id: "14034176-0e93-4b41-a8c4-acd4d2bcca54",
  description: "This is the label for being in a reminder state of 'Appointment Complete'",
  defaultMessage: 'Appointment Complete',
};
export const MSG_lifecycleStateNoShow = { //'no-show'
  id: "7af3c34e-ffdb-43de-8c7c-3a9740899cfb",
  description: "This is the label for being in a reminder state of 'Appointment No-Show'",
  defaultMessage: 'Appointment No-Show',
};
export const MSG_lifecycleStateCanceled = { //'canceled'
  id: "b295ee53-7a13-45dd-8d2e-f348901d2c1e",
  description: "This is the label for being in a reminder state of 'Appointment Canceled'",
  defaultMessage: 'Appointment Canceled',
};
export const MSG_lifecycleStatePast = { //'past'
  id: "805f9727-40a9-4c48-b067-58d113303f63",
  description: "This is the label for being in a reminder state of 'Appointment Past'",
  defaultMessage: 'Appointment Past',
};
export const MSG_lifecycleStateToday = { //'today'
  id: "044e9ce7-77cb-433c-96c6-6c710c2e0a46",
  description: "This is the label for being in a reminder state of 'Appointment Today'",
  defaultMessage: 'Appointment Today',
};
export const MSG_lifecycleStateConfirmed = { //'confirmed'
  id: "5a34c696-a80f-4d84-b641-9e66425d6667",
  description: "This is the label for being in a reminder state of 'Appointment Confirmed'",
  defaultMessage: 'Appointment Confirmed',
};
export const MSG_lifecycleStateUnconfirmed = { //'unconfirmed'
  id: "93c87693-30f8-4cc0-8fed-e6090c89ee1b",
  description: "This is the label for being in a reminder state of 'Appointment Needs Confirmation'",
  defaultMessage: 'Appointment Needs Confirmation',
};
export const MSG_lifecycleStateUnconfirmedCritical = { //'unconfirmed-critical'
  id: "d0e76411-25cc-48c6-bc6b-d504ff9a0e0c",
  description: "This is the label for being in a reminder state of 'Appointment Needs Confirmation (Critical)'",
  defaultMessage: 'Appointment Needs Confirmation (Critical)',
};
export const MSG_lifecycleStateFuture = { //'future'
  id: "4e87c36d-cf67-4db0-aa39-19c8d5b027a4",
  description: "This is the label for being in a reminder state of 'Future Unconfirmed Appointment",
  defaultMessage: 'Future Unconfirmed Appointment'
};
export const MSG_reminderStateColon = {
  id: "78f2aad2-0846-431d-bfe9-85c3e528305c",
  description: "",
  defaultMessage: "Reminder state: {label}"
};
