import { QUICKBOOKS_PAYMENT_TYPE } from "../enums";
import { formatMessage } from "./intl";
import {
  MSG_invoicePaymentTypeCash,
  MSG_invoicePaymentTypeCheck,
  MSG_invoicePaymentTypeCreditCard,
  MSG_otherTitle,
  MSG_unknownTitle,
} from "../strings";

// Use this if you want to iterate over all the status (e.g. for a <select>)
const SortedQuickbooksPaymentType = [
  // Keep the generic payment methods up top
  QUICKBOOKS_PAYMENT_TYPE.CASH,
  QUICKBOOKS_PAYMENT_TYPE.CHECK,
  QUICKBOOKS_PAYMENT_TYPE.CREDIT_CARD,

  // Always last
  QUICKBOOKS_PAYMENT_TYPE.OTHER,
];

const QuickbooksPaymentTypeInfo: {[key: string]: {type: QUICKBOOKS_PAYMENT_TYPE, getLabel: () => string}} = {
  [QUICKBOOKS_PAYMENT_TYPE.CASH]: {
    type: QUICKBOOKS_PAYMENT_TYPE.CASH,
    getLabel: () => formatMessage(MSG_invoicePaymentTypeCash),
  },
  [QUICKBOOKS_PAYMENT_TYPE.CHECK]: {
    type: QUICKBOOKS_PAYMENT_TYPE.CHECK,
    getLabel: () => formatMessage(MSG_invoicePaymentTypeCheck),
  },
  [QUICKBOOKS_PAYMENT_TYPE.CREDIT_CARD]: {
    type: QUICKBOOKS_PAYMENT_TYPE.CREDIT_CARD,
    getLabel: () => formatMessage(MSG_invoicePaymentTypeCreditCard),
  },
  [QUICKBOOKS_PAYMENT_TYPE.OTHER]: {
    type: QUICKBOOKS_PAYMENT_TYPE.OTHER,
    getLabel: () => formatMessage(MSG_otherTitle),
  },
};

// We sometimes expand the payment type list.  This is a protection to ensure
// that old versions of the app don't crash when an unrecognized payment type
// comes through.
function safelyGetQuickbooksPaymentTypeLabel(type: QUICKBOOKS_PAYMENT_TYPE): string {
  if (QuickbooksPaymentTypeInfo[type]) {
    return QuickbooksPaymentTypeInfo[type].getLabel();
  } else {
    return formatMessage(MSG_unknownTitle);
  }
}

export { SortedQuickbooksPaymentType, QuickbooksPaymentTypeInfo, safelyGetQuickbooksPaymentTypeLabel };
