import * as React from "react";
import { CURRENCY_FORMAT, NUMBER_FORMAT } from "../enums";
// Intentionally do not import from the barrel so we don't have a require cycle.
import { formatCurrency, formatNumber } from "../utils/numbers";

interface IGazelleNumberProps {
  value: number;
  multiplier?: number;
  minimumFractionDigits?: number;
  numberFormatOverride?: NUMBER_FORMAT;
}

export const GazelleNumber = (props: IGazelleNumberProps) => {
  return (
    <>
      {formatNumber(props.value, props.multiplier || 1, props.minimumFractionDigits || null, props.numberFormatOverride || null)}
    </>
  );
};

interface IGazelleCurrencyProps {
  value: number,
  numberFormatOverride?: NUMBER_FORMAT;
  currencyFormatOverride?: CURRENCY_FORMAT;
}
export const GazelleCurrency = (props: IGazelleCurrencyProps) => {
  return (
    <>
      {formatCurrency(props.value, props.numberFormatOverride || null, props.currencyFormatOverride || null)}
    </>
  );
};
