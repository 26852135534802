import * as React from 'react';
import { observer } from 'mobx-react';
import { PianoWrapper } from '@client_scheduler/models/piano_wrapper';
import { MasterServiceGroupRow } from './MasterServiceGroupRow';
import { rootStoreInstance } from "@client_scheduler/modules/root";

interface IMasterServiceList {
  pianoWrapper: PianoWrapper
}

const MasterServiceList = observer(class MasterServiceList extends React.Component<IMasterServiceList> {
  render() {
    const {masterServiceGroups} = rootStoreInstance;

    return (
      <div className="master-service-list">
        {masterServiceGroups.map((group) => {
          return (
            <MasterServiceGroupRow
              pianoWrapper={this.props.pianoWrapper}
              key={group.id}
              group={group} />
          );
        })}
      </div>
    );
  }
});

export { MasterServiceList };
