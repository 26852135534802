import { observable, makeObservable } from 'mobx';

class ContactInfo {
  firstName: string = '';
  lastName: string = '';
  phoneNumber: string = '';
  phoneType: string = 'MOBILE';
  email: string = '';
  smsOptIn: boolean = false;

  constructor() {
    makeObservable(this, {
      firstName: observable,
      lastName: observable,
      phoneNumber: observable,
      phoneType: observable,
      email: observable,
      smsOptIn: observable,
    });
  }
}

export { ContactInfo };
