import * as React from 'react';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { LoadingIndicator } from '..';
import { ErrorContainer } from "@core/components/ErrorContainer";
import {
  MSG_noticeTitle,
  MSG_pleaseCall,
  MSG_pleaseCallOrEmail,
  MSG_pleaseEmail,
  MSG_schedulerNotAvailable
} from "@gazelle/shared/strings";
import { formatMessage } from "@gazelle/shared/utils";

interface IClientRootLayoutProps {
  isInitializing: boolean;
  displayGenericError?: React.ReactElement | string;
  MobileLayout: new (props: any) => React.Component;
  DesktopLayout: new (props: any) => React.Component;
  rootStore: {companyPhoneNumber?: string, companyEmail?: string, isEnabled?: boolean}
}

const ClientRootLayout = observer(class ClientRootLayout extends React.Component<IClientRootLayoutProps> {
  windowWidth: number = null;

  constructor(props: IClientRootLayoutProps) {
    super(props);

    makeObservable(this, {
      windowWidth: observable
    });
  }

  async componentDidMount() {
    // window.onbeforeunload = () => {
    //   return "Are you sure you want to leave?  Your appointment has not yet been reserved.";
    // };

    window.addEventListener('resize', this.handleWindowSizeChange);
    this.handleWindowSizeChange();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowSizeChange);
  }

  handleWindowSizeChange = () => {
    this.windowWidth = window.innerWidth;
  };

  render() {
    const { MobileLayout, DesktopLayout } = this.props;

    if (this.props.isInitializing) {
      return <LoadingIndicator />;
    }

    let contactMsg: any = <div/>;
    if (this.props.rootStore.companyPhoneNumber && this.props.rootStore.companyEmail) {
      contactMsg = formatMessage(MSG_pleaseCallOrEmail, {
        phone: this.props.rootStore.companyPhoneNumber,
        email: this.props.rootStore.companyEmail
      });
    } else if (this.props.rootStore.companyPhoneNumber) {
      contactMsg = formatMessage(MSG_pleaseCall, {
        phone: this.props.rootStore.companyPhoneNumber
      });
    } else if (this.props.rootStore.companyEmail) {
      contactMsg = formatMessage(MSG_pleaseEmail, {
        email: this.props.rootStore.companyEmail
      });
    }

    if (!this.props.rootStore.isEnabled) {
      return (
        <ErrorContainer
          title={formatMessage(MSG_noticeTitle)}
          message={(
            <>
              <div>{formatMessage(MSG_schedulerNotAvailable)}</div>
              <div>{contactMsg}</div>
            </>
          )}/>
      );
    }

    if (this.props.displayGenericError) {
      return (
        <ErrorContainer
          message={(
            <>
              {this.props.displayGenericError}
              &nbsp;&nbsp;
              {contactMsg}
            </>
          )}
        />
      );
    }

    return this.windowWidth < 768 ? <MobileLayout/> : <DesktopLayout/>;
  }
});

export { ClientRootLayout };
