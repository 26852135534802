import { EVENT_RECURRENCE_ENDING_TYPE } from "../enums";
import { formatMessage } from "./intl";
import { MSG_eventRecurrenceEndingTypeDate, MSG_eventRecurrenceEndingTypeOccurrences } from "../strings";

// Use this if you want to iterate over all the types (e.g. for a <select>)
const SortedEventRecurrenceEndingTypes = [
  EVENT_RECURRENCE_ENDING_TYPE.OCCURRENCES,
  EVENT_RECURRENCE_ENDING_TYPE.DATE,
];

const EventRecurrenceEndingTypeInfo: {[key: string]: {type: EVENT_RECURRENCE_ENDING_TYPE, getLabel: () => string}} = {
  [EVENT_RECURRENCE_ENDING_TYPE.OCCURRENCES]: {
    type: EVENT_RECURRENCE_ENDING_TYPE.OCCURRENCES,
    getLabel: () => formatMessage(MSG_eventRecurrenceEndingTypeOccurrences),
  },
  [EVENT_RECURRENCE_ENDING_TYPE.DATE]: {
    type: EVENT_RECURRENCE_ENDING_TYPE.DATE,
    getLabel: () => formatMessage(MSG_eventRecurrenceEndingTypeDate),
  },
};

export { EventRecurrenceEndingTypeInfo, SortedEventRecurrenceEndingTypes };
