import * as React from 'react';
import { observer } from 'mobx-react';
import { Col, Row } from 'reactstrap';

import { rootStoreInstance } from '../../root';
import { PianoWrapper } from '../../../models/piano_wrapper';
import { PianoListItem } from './PianoListItem';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/pro-solid-svg-icons";
import {
  MSG_noPianosOrServicesMessage,
  MSG_numberOfPianosLabel,
  MSG_otherPianosTitle,
  MSG_selectedPianosTitle,
  MSG_totalCostLabel,
  MSG_totalDurationWithValueLabel
} from "@gazelle/shared/strings";
import { formatCurrency, formatDuration, formatMessage, formatNumber } from "@gazelle/shared/utils";

interface IPianoListProps {
  onEdit: (pianoWrapper: PianoWrapper) => void,
  onRemove: (pianoWrapper: PianoWrapper) => void,
}

const PianoList = (observer(class PianoList extends React.Component<IPianoListProps> {
  render() {
    const { selectedPianoWrappers, unselectedPianoWrappers } = rootStoreInstance.pianoStore;
    const { pianoStore } = rootStoreInstance;
    const showCost = rootStoreInstance.companySettings.showCosts;

    return (
      <div>
        <h4>{formatMessage(MSG_selectedPianosTitle)}</h4>

        {selectedPianoWrappers.length > 0
          ? selectedPianoWrappers.map((pianoWrapper, i) => (
              <PianoListItem key={i}
                             pianoWrapper={pianoWrapper}
                             onEdit={this.props.onEdit}
                             onRemove={this.props.onRemove} />
            ))
          : (
            <p className="font-italic">{formatMessage(MSG_noPianosOrServicesMessage)}</p>
          )}

        {unselectedPianoWrappers.length > 0 &&
          <div className="mt-5">
            <h4>{formatMessage(MSG_otherPianosTitle)}</h4>
            {unselectedPianoWrappers.map((pianoWrapper, i) => (
              <PianoListItem key={i}
                             pianoWrapper={pianoWrapper}
                             onEdit={this.props.onEdit}
                             onRemove={this.props.onRemove} />
            ))}
          </div>}

        <Row className="overflowing-footer">
          <Col>
            <div className="duration-container">
              <div className="left-side d-md-none d-lg-block">
                <FontAwesomeIcon icon={faClock} />
              </div>
              <div className="right-side">
                <div>
                  {formatMessage(MSG_numberOfPianosLabel, {numPianos: formatNumber(selectedPianoWrappers.length)})}
                </div>
                <div>
                  {formatMessage(MSG_totalDurationWithValueLabel, {duration: formatDuration(pianoStore.totalDuration)})}
                </div>
                {showCost &&
                  <div>
                    {formatMessage(MSG_totalCostLabel, {totalCost: formatCurrency(pianoStore.totalAmount)})}
                  </div>}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}));

export { PianoList };
