export const MSG_yourAppointmentDetailsTitle = {
  id: "85134000-9a3f-42a6-9fd8-3e849164f490",
  description: "This is the heading above where we show their appointment date and time.",
  defaultMessage: "Your Appointment Details",
};

export const MSG_confirmationWithCompanyNameTitle = {
  id: 'a28e9581-23ec-44c9-8cd8-2ed75832b483',
  description: 'The page title displayed in the browser tab or window header.  This should have the company name first, followed by "Confirm Appointment"',
  defaultMessage: '{companyName} - Appointment Confirmation'
};

export const MSG_confirmationThankYou = {
  id: "2d67f2d1-5c84-4799-a109-f5a694128dd8",
  defaultMessage: "Thank you for confirming your appointment.",
};

export const MSG_confirmationError = {
  id: "242b6b26-ae15-4748-a40a-13f4fc8a4283",
  defaultMessage: "Please call us at {phone} to confirm your appointment.  We apologize for the inconvenience.",
};

export const MSG_unconfirmedTitle = {
  id: "34724a3d-c090-43ce-b5b6-236ae945ae1e",
  description: "A word describing when a customer has not yet confirmed that they will be available for an upcoming appointment.  This word is used as a 'badge' that is displayed next to the appointment.",
  defaultMessage: "Unconfirmed",
};

export const MSG_confirmedTitle = {
  id: "7e0e5262-ba91-4c96-9cf4-8aa789b79dc9",
  description: "When an appointment has been confirmed",
  defaultMessage: "Confirmed"
};

export const MSG_notYetConfirmedTitle = {
  id: "bbbfebe8-109b-4163-b88d-00b62919c735",
  description: "When an appointment has not yet been confirmed.",
  defaultMessage: "Not Yet Confirmed"
};

export const MSG_notYetConfirmedLabel = {
  id: "b3dc541c-7e46-456d-bea3-28abe871a666",
  defaultMessage: "Not yet confirmed",
};

export const MSG_markAsConfirmedTitle = {
  id: "bf79c601-f2cc-437e-84ed-28fd63273fa8",
  description: "The button that lets them mark an appointment as confirmed, meaning they have confirmed that the client still wants to do the appointment.",
  defaultMessage: "Mark as Confirmed",
};

export const MSG_appointmentNotConfirmed = {
  id: "dc8c8fb5-1116-4e35-a2e8-783637597577",
  defaultMessage: "This appointment has not been confirmed.",
};

export const MSG_dateLabel = {
  id: "4d553f0c-96d9-423f-8ac8-44997c57bf8c",
  description: "The label in front of the date of the appointment (i.e. Date: Feb 19, 2018)",
  defaultMessage: "Date: {date}",
};

export const MSG_dateWithColonLabel = {
  id: "1696b335-e92f-4b30-93ce-03276201bddf",
  description: "A label shown on the final confirmation page, showing the date that they selected for the appointment.",
  defaultMessage: "Date:",
};

export const MSG_timeWithColonLabel = {
  id: "03e58264-76d0-43ff-930d-65e8fb29d81f",
  description: "A label shown on the final confirmation page, showing the time that they selected for the appointment.",
  defaultMessage: "Time:",
};

export const MSG_nakedDateLabel = {
  id: "3da9ea96-50c5-4f15-9ca1-7eda97a76594",
  description: "The label in front of the date of the appointment",
  defaultMessage: "Date"
};

export const MSG_nakedTimeLabel = {
  id: "43e2ed43-1845-4ce8-9ee1-8ff030a05c7d",
  description: "The label in front of the time of the appointment",
  defaultMessage: "Time"
};


export const MSG_timeLabel = {
  id: "75ca8956-129d-40f0-827f-359367d1b332",
  description: "The label in front of the time of the appointment (i.e. Time: 10:00 AM)",
  defaultMessage: "Time: {time}",
};

export const MSG_approximateDurationLabel = {
  id: "22b133c1-0e4c-40f5-9a00-dfbb7c028088",
  description: "This is displayed after the appointment time, and is used to approximate the duration of the appointment.  Here, {duration} will be a time like '2 hours' or '1 hour, 30 minutes'.",
  defaultMessage: "Duration: about {duration}",
};

export const MSG_appointmentHasBeenCanceledLabel = {
  id: "74156d74-df7a-49fa-8b84-abf91fed8f70",
  description: "This message is displayed when a customer tries to confirm an appointment that has already been canceled.",
  defaultMessage: "This appointment has been canceled.  Contact us at {phoneNumber} with questions.",
};

export const MSG_eventNotFoundExplanation = {
  id: "24b6feaf-a576-4a03-a8dc-04fd898e2e8c",
  description: "This message is displayed to someone who is trying to confirm an appointment, but the appointment no longer exists in the system.",
  defaultMessage: "We are having difficulty finding your appointment.",
};
