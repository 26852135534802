const getUrlPath = () => {
  return location.pathname.split('/').filter((str: string) => str.length > 0);
};

const getUrlPart = (part: number): string|null => {
  const parts = getUrlPath();
  return parts[part] || null;
};

const urlContainsDebug = (): boolean => {
  return !!location.pathname.match(/debug/);
};

const getUrlToken = (): string|null => {
  return getUrlPart(1);
};

export { getUrlPath, getUrlPart, getUrlToken, urlContainsDebug };
