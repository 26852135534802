export const MSG_onlineSchedulingTitle = {
  id: "a4c718ee-eb96-4cee-a6df-2e7258522936",
  description: "This is the displayed name of our 'Online Scheduling' feature, or online scheduling system, or self-scheduler.",
  defaultMessage: "Online Scheduling"
};

export const MSG_onlineSchedulingWithCompanyNameTitle = {
  id: '3c1607a0-33b7-473b-8601-6f16d7da84ae',
  description: 'The page title displayed in the browser tab or window header.  This should have the company name first, followed by "Online Scheduling"',
  defaultMessage: '{companyName} - Online Scheduling'
};

export const MSG_appointmentConfirmationTitle = {
  id: "0449f99b-cef7-426f-a1ae-9ddcb4c86dd3",
  description: "This is the title for the screen where clients can confirm their appointment date and time.",
  defaultMessage: "Appointment Confirmation"
};

export const MSG_schedulable = {
  id: "17616d9c-2281-47c2-97f5-9b59d296d371",
  description: "The term used when a user is able to be scheduled for appointments.  It is possible to have users in the system who cannot be scheduled for appointments.  This should be a single-word term.",
  defaultMessage: "Schedulable"
};

export const MSG_selfSchedulableTitle = {
  id: "c7c39bfc-bf17-440a-9f00-b0b2e70d469d",
  description: "Self-schedule is a term we invented for the feature where clients can schedule their own appointments.  This 'self-schedulable' means that a service is able to be self-scheduled by a client.",
  defaultMessage: "Self-Schedulable",
};

export const MSG_selfSchedulerTitle = {
  id: "eafb551d-fec3-412c-9eeb-4346dfd56dd5",
  description: "This is the name of our feature where clients can schedule their own appointments.",
  defaultMessage: "Self-Scheduler"
};


export const MSG_yourSelfScheduleLinkTitle = {
  id: "4876bfe7-9482-4a3a-8f31-fe7c227b581e",
  description: "This is the header for the section where they can copy the link to their client self-scheduling interface.",
  defaultMessage: "Your Self-Schedule Link"
};

export const MSG_selfScheduleLinkExplanation = {
  id: "6fbf0e70-7ec8-44f2-bca9-a97a74e34d21",
  description: "An explanation about what the self-scheduling link is used for.",
  defaultMessage: "This is the public self-scheduling link for {companyName}. For the best results consider advertising it on your website, social media profiles, email signatures, reminder emails, voicemail, text messages, & vehicle signage. The more you promote it the more it will benefit you."
};

export const MSG_selfSchedulableCheckboxLabel = {
  id: "693f9b30-b0cc-4b72-a57c-86f4b01102f6",
  description: "This is a flag that says a service can be self-scheduled by clients.  This is a label for a checkbox.",
  defaultMessage: "This item can be self-scheduled by clients",
};

export const MSG_selfSchedulableCheckboxLabelExplanation = {
  id: "0dd3b1f8-725c-4cb0-a86b-34c953482919",
  description: "This is an explanation for the flag that says if a service can be self-scheduled by a client.",
  defaultMessage: "This flag is used to show which of your services will show up as options to your clients when they use the self-scheduler."
};

export const MSG_allowSelfScheduleEstimateOptionCheckboxLabel = {
  id: "3ba0ad56-a398-4218-a0c2-8e4395b6bd7f",
  description: "This is a checkbox flag that says if an estimate option can be self-scheduled.",
  defaultMessage: "Allow client to self-schedule this option"
};

export const MSG_allowSelfScheduleEstimateOptionCheckboxLabelExplanation = {
  id: "24925366-bae5-44b7-a715-468fc64891f1",
  description: "This is an explanation for the checkbox flag that says if an estimate option can be self-scheduled.",
  defaultMessage: "This allows all of the items specifically on this option to be self-scheduled online by the client using the \"Book Now\" button displayed when viewing this estimate."
};

export const MSG_noSelfScheduleWarning = {
  id: "100a843b-6a4f-4a44-8bdc-428d3e2518a6",
  description: "A warning that tells the user they have not allowed any of their services to be self-scheduled by their clients.",
  defaultMessage: "No self-schedulable items have been defined."
};

export const MSG_noSelfScheduleWarningExplanation = {
  id: "0928f96b-4966-4ed5-86d6-0343b50574df",
  description: "An explanation for the warning that tells them no services have been marked as self-schedulable.",
  defaultMessage: "Clients who visit your Gazelle online scheduler will not be able to schedule any appointments.  Mark some of the items in your Master Service List as self-schedulable in order to allow clients to schedule online."
};

export const MSG_anyTechnicianLabel = {
  id: "9b11c52b-f560-4544-8889-bdc3c303778f",
  description: "A label used when they can select a specific technician for a job, but they don't care which technician is selected.",
  defaultMessage: "Any technician",
};

export const MSG_selectTargetDateTitle = {
  id: "e53ef431-c8da-4c12-8ecb-18d0e11c9b4b",
  defaultMessage: "Select Target Date"
};

export const MSG_monthsFromNowLabel = {
  id: "12298e38-271c-4bdc-b9c6-79feaa47fcbe",
  defaultMessage: "{numMonths, plural, one {1 month from now} other {# months from now}}"
};

export const MSG_schedulerStep2Title = {
  id: "d58258d8-a3b3-4f96-9666-e6d70f301909",
  description: "This is the name of the second step of the scheduling workflow.  It is where they enter information about the client's pianos and they select what services will be performed during the appointment.",
  defaultMessage: "Pianos & Services",
};

export const MSG_schedulerStep3Title = {
  id: "47d48ece-4de7-4100-b790-e9701ec817ec",
  description: "This is the name of the third step of the scheduling workflow.  It is where they set some default preferences for the search.",
  defaultMessage: "Choose Technician(s)",
};

export const MSG_schedulerStep4Title = {
  id: "cc7c487c-fe4e-463b-8708-675e756b4a4c",
  description: "This is the name of the fourth step of the scheduling workflow.  We will show a progress bar to indicate that we are searching the calendar for all openings.",
  defaultMessage: "Search Calendar",
};

export const MSG_schedulerStep5Title = {
  id: "3a9f25a7-e616-412f-9873-bda32731a7c9",
  description: "This is the name of the fifth step of the scheduling workflow.  It is where they can see all the information they entered and make any changes before creating the appointment.",
  defaultMessage: "Finalize & Save",
};

export const MSG_addPianoAndServicesLabel = {
  id: "6e5e0c1e-1444-4e99-b141-934980939cf3",
  defaultMessage: "Add a piano and services"
};

export const MSG_addServicesTitle = {
  id: "49dd3623-cb41-4b57-bef8-5c2e906a3343",
  defaultMessage: "Add Services",
};

export const MSG_whichPianosWereServiced = {
  id: "a4e6ae38-fcbb-4760-b1e3-66723e04cbf0",
  defaultMessage: "Which of their pianos were serviced?",
};

export const MSG_whichPianosWillBeServiced = {
  id: "bdc489f1-1ea7-443f-9ae2-fb58bf42a60e",
  defaultMessage: "Which of their pianos will be serviced?",
};

export const MSG_searchMyCalendarTitle = {
  id: "cb135bdc-d794-4b83-a245-11db6776d5be",
  description: "This is the button label when your own calendar is to be searched.",
  defaultMessage: "Search My Calendar",
};

export const MSG_searchSomeonesCalendarTitle = {
  id: "8dad0e8e-63c7-41a3-b06a-92dfb7422420",
  description: "This is the button label when one calendar, not my own, is to be searched",
  defaultMessage: "Search {name}'s Calendar",
};

export const MSG_searchSeveralCalendarsTitle = {
  id: "10d0819a-aae9-4b04-ba53-73516f1065e8",
  description: "This is the button label when several calendars are to be searched, but not all calendars.",
  defaultMessage: "{numCalendars, plural, one {Search 1 Calendar} other {Search # Calendars}}",
};

export const MSG_searchAllCalendarsTitle = {
  id: "e88ea152-a480-4f14-9a8a-76d588919405",
  description: "This is the button label when ALL calendars are to be searched.",
  defaultMessage: "Search All Calendars",
};

export const MSG_bookDateTitle = {
  id: "f804b267-d28e-416d-9731-16c782bcf31f",
  description: "This button lets them book an appointment manually, where they enter all the details themselves rather than using our Smart Scheduling wizard.  This is the label for when the date and time are already known.",
  defaultMessage: "Book {date}",
};

export const MSG_bookSpecificTimeTitle = {
  id: "6c610b6c-feb9-4ed2-8f4c-243c75ae52ac",
  description: "This button lets them book an appointment manually, where they enter all the details themselves rather than using our Smart Scheduling wizard.  This is the label for when the date and time are NOT known yet.",
  defaultMessage: "Book a Specific Time",
};

export const MSG_bookSlotForTitle = {
  id: "2bd8faeb-2bb6-4c0a-b1c0-ddf698968746",
  description: "This is the label for the button when the book a specific appointment slot.",
  defaultMessage: "Book for {time}"
};

export const MSG_trafficSlowdownLabel = {
  id: "c4ccf7c0-d350-44fe-95f8-14cb6a7c745f",
  defaultMessage: "Estimated {numMinutes}-minute slowdown along your routes."
};

export const MSG_noTrafficSlowdownsLabel = {
  id: "83c528ce-b183-48e4-ad82-414868029965",
  defaultMessage: "No slowdowns due to traffic.",
};

export const MSG_slotOptionsForDateLabel = {
  id: "cd5eb8e8-071a-444a-8f18-de4b4b28842a",
  description: "This is the header shown on a list of time slots options that were found for a specific date.  For example. on Thursday 8:15 and 12:30 may both work, so both would be listed.",
  defaultMessage: "{numOptions, plural, one {1 option} other {# options}}"
};

export const MSG_resetClientTitle = {
  id: "67c793d1-a632-475b-b747-eea1d10d04b8",
  description: "The short label for the button to reset the client back to the default state",
  defaultMessage: "Reset Client",
};

export const MSG_resetClientSelectionTitle = {
  id: "de28dfb9-c424-4ad4-8575-83706623a591",
  description: "The longer label for the button to reset the client back to the default state",
  defaultMessage: "Reset Client Selection",
};

export const MSG_pianoServicesSummary = {
  id: "f377fd28-6c76-4a25-8158-464aba952724",
  defaultMessage: "{numServices, plural, one {1 service} other {# services}}",
};

export const MSG_noServicesSelectedLabel = {
  id: "9f5d79b6-84cd-4dc4-bb39-b3c48e94b834",
  defaultMessage: "No services selected",
};

export const MSG_appointmentPianoRemovalConfirmation = {
  id: "a52217fb-4afc-4f8b-95d6-c077451a5ed5",
  defaultMessage: "Are you sure you want to remove this piano from this appointment?",
};

export const MSG_addressRequiredForCalendarSearch = {
  id: "6417c47c-073b-410a-a48b-f7afbdcfcb3d",
  defaultMessage: "An address is needed to search your calendar and optimize your drive times.",
};

export const MSG_servicesRequiredForCalendarSearch = {
  id: "d4f4f13a-4097-44a0-bc62-a2faabe4928f",
  defaultMessage: "The duration for this event is unknown. Please add at least one service with a duration so your calendar can be searched for an opening.",
};

export const MSG_manualServiceTitle = {
  id: "592189cb-408d-4adf-9186-ecda6b1f4bed",
  description: "A label for when the user chooses to manually enter a service on an invoice that was taken on a piano.",
  defaultMessage: "Custom Service",
};

export const MSG_saveAndCloseNewClientExplanation = {
  id: "26f636d6-dfbb-4f5a-833d-57d3adfd23c7",
  defaultMessage: "Save as a new client without booking an appointment.",
};

export const MSG_totalDurationLabel = {
  id: "edfd93f2-60f0-435d-b48d-bb0eb068d52c",
  description: "The total duration of an appointment.  The duration variable will be formatted already for you.  This will read like 'Total Duration: 1h 30m'",
  defaultMessage: "Total duration: {totalDuration}"
};

export const MSG_totalCostLabel = {
  id: "7c661059-3dc4-4cec-acf0-582822eee2b5",
  description: "The total cost of an appointment.  The cost will be formatted in the local currency.  This will read like: 'Total Cost: $150.00'",
  defaultMessage: "Total cost: {totalCost}"
};

export const MSG_durationWithValueLabel = {
  id: "94cf3c72-1e6e-4eb6-ad3a-b1d7c8d3192c",
  description: "A very short summary of how long the appointment will take.  The {duration} variable will be an abbreviated duration string.  So in English when this is rendered it would look like 'Duration: 1h 30m'",
  defaultMessage: "Duration: {duration}",
};

export const MSG_totalDurationWithValueLabel = {
  id: "a323f495-801f-4a13-a191-c69a0f619664",
  description: "This displays the duration of an appointment for a set of selected services for all pianos.",
  defaultMessage: "Total duration: {duration}",
};

export const MSG_durationAndCostLabel = {
  id: "a1f8817f-8ca4-49f6-a5f6-9e343a48c960",
  description: "Duration is the amount of time an appointment could take, and cost is how much money the service will cost",
  defaultMessage: "Duration: {duration}, Cost: {cost}"
};

export const MSG_durationAndCostShortLabel = {
  id: "1f2a3d62-52ff-4ce7-88ef-df0c00f22d36",
  description: "This displays the total duration and cost of selected services for a single piano.  This is used on the list of pianos when multiple pianos exist.",
  defaultMessage: "Duration: {duration} ({cost})",
};

export const MSG_durationWithColonLabel = {
  id: "1f99dfde-8be8-4d33-bbcd-3b580bf66f8f",
  description: "A label shown on the final confirmation page, showing the duration (amount of time) of the appointment.",
  defaultMessage: "Duration:",
};

export const MSG_targetWithValueLabel = {
  id: "9239cb4c-f14d-45c5-a7bb-7f65c033a723",
  description: "A very short description of the preferred date the user selected.",
  defaultMessage: "Target: {date}",
};

export const MSG_preferredTechnicianTitle = {
  id: "73ec5804-37e1-49e6-8f73-7b724489d622",
  description: "This is an indicator that a specific user (technician) is preferred by a client, in title case",
  defaultMessage: "Preferred Technician"
};

export const MSG_preferredTechnicianLabel = {
  id: "15c79f08-5d09-488f-b900-9b68cdb255bb",
  description: "This is an indicator that a specific user (technician) is preferred by a client, in label case",
  defaultMessage: "Preferred technician"
};

export const MSG_preferredTechnicianAbbreviatedTitle = {
  id: "f7970011-862f-48ed-858f-dd542f7957d8",
  description: "This is an indicator that a specific user (technician) is preferred by a client.  This is a shortened version of 'Preferred Technician' to be used when space is tight on the screen.",
  defaultMessage: "Preferred Tech"
};

export const MSG_preferredTechnicianName = {
  id: "dc78526e-9f98-4fe3-abd3-275239539715",
  defaultMessage: "Preferred technician is {name}.",
};

export const MSG_withAnyTechnicianLabel = {
  id: "1d396fe0-c9a6-41e6-9825-97f92898fede",
  description: "Indicates that they don't care which technician they are scheduled with (for companies that have multiple technicians).",
  defaultMessage: "With any technician",
};

export const MSG_withSpecificTechniciansLabel = {
  id: "ff286c95-dd40-4d4b-883e-962d23cf8a9e",
  description: "Indicates which technician they want to have their appointment with.  This uses some shorthand if they selected more than one technician.  If only one technician is selected, it looks like 'With John Smith', but if there are more than 1, it looks like 'With John Smith +2 More'",
  defaultMessage: "{numOtherTechs, plural, =1 {+1 more} other {+# more}}",
};

export const MSG_withTechnicianLabel = {
  id: "650e6410-f75d-4fca-aeb0-4830e9434421",
  description: "Indicates which technician they selected appointment is with.  In English this would be rendered like 'With John Smith'",
  defaultMessage: "With {name}",
};

export const MSG_withWithColonLabel = {
  id: "557c4db3-c497-48c7-b659-b581645e1c76",
  description: "A label shown on the final confirmation page, showing which technician the appointment is scheduled with.",
  defaultMessage: "With:",
};

export const MSG_statusWithColonLabel = {
  id: "ee519172-f1b1-458a-b815-3f6e877272c7",
  description: "A label shown on the final confirmation page, showing the status of the appointment reservation.  This is the indication of where in the reservation process they currently are.  After this the technician will need to approve the appointment.",
  defaultMessage: "Status:",
};

export const MSG_useNameTitle = {
  id: "921d98a9-662e-4468-816e-ad45d895a966",
  description: "This is a label for a button that lets them select a specific technician to use.  For example: 'Use Bob Smith' to change the technician to Bob Smith.",
  defaultMessage: "Use {name}",
};

export const MSG_searchingYourCalendar = {
  id: "ff522160-cb40-40f9-ae02-93dc3820e83d",
  defaultMessage: "Searching your calendar for the best appointment slots..."
};

export const MSG_searchingNumCalendars = {
  id: "24863923-c7e7-4e0b-8efc-1d5c26989418",
  defaultMessage: "Searching {numCalendars} calendars for the best appointment slots..."
};

export const MSG_searchingOneCalendar = {
  id: "2db4bcd0-8914-4b0c-9dd6-e53aabeb1963",
  defaultMessage: "Searching for the best appointment slots with {name}..."
};

export const MSG_searchingAllCalendars = {
  id: "38d2e09e-0bee-46dc-b50d-046535e02868",
  defaultMessage: "Searching all calendars for the best appointment slots..."
};

export const MSG_expandingSearch1 = {
  id: "35869644-9bdd-444d-98bf-7b6104937b0c",
  description: "This is one of the messages we show when the calendar search is taking a long time because we are searching additional dates.  There are several messages we show, this is one of them we alternate between.",
  defaultMessage: "Expanding search..."
};

export const MSG_expandingSearch2 = {
  id: "9dd62191-5ee8-40d1-99da-f5d5e27857ea",
  description: "This is one of the messages we show when the calendar search is taking a long time because we are searching additional dates.  There are several messages we show, this is one of them we alternate between.",
  defaultMessage: "Expanding search again..."
};

export const MSG_expandingSearch3 = {
  id: "459147e4-6451-4ea2-b983-6eab623e3bb7",
  description: "This is one of the messages we show when the calendar search is taking a long time because we are searching additional dates.  There are several messages we show, this is one of them we alternate between.",
  defaultMessage: "Expanding search yet again..."
};

export const MSG_newExpandingSearch1 = {
  id: "6892a719-7eca-47e3-a33c-b9adb01b2028",
  description: "This is one of the messages we show when the calendar search is taking a long time because we are searching additional dates.  There are several messages we show, this is one of them we alternate between.",
  defaultMessage: "Searching for more options..."
};

export const MSG_newExpandingSearch2a = {
  id: "90885d79-9994-4eaf-b1f9-a0eba8b5d5f4",
  description: "This is one of the messages we show when the calendar search is taking a long time because we are searching additional dates.  There are several messages we show, this is one of them we alternate between.",
  defaultMessage: "This technician's calendar is really full. Searching more dates..."
};

export const MSG_newExpandingSearch2b = {
  id: "3bc45151-dcb3-4a81-8b48-0625a89b9bce",
  description: "This is one of the messages we show when the calendar search is taking a long time because we are searching additional dates.  There are several messages we show, this is one of them we alternate between.",
  defaultMessage: "At least one technician's calendar is really full. Searching more dates..."
};

export const MSG_newExpandingSearch3 = {
  id: "a9b050f5-e769-4823-9545-7592cd7a14aa",
  description: "This is one of the messages we show when the calendar search is taking a long time because we are searching additional dates.  There are several messages we show, this is one of them we alternate between.",
  defaultMessage: "Searching further into the future..."
};

export const MSG_noSlotsFound = {
  id: "645854c1-e97c-4400-acb1-46d807390b9a",
  description: "This is the header for an error message that is shown when we searched their calendar but didn't find any options available for the appointment",
  defaultMessage: "No dates found."
};

export const MSG_noSlotsFoundExplanation = {
  id: "bce8b099-5dbf-4805-b544-fdbcfa7eedfe",
  description: "This is the detailed message shown when we searched their calendar but didn't find any availability.",
  defaultMessage: "No available dates were found for this appointment.  Check to make sure you have availability and that your scheduling preferences are not too strict."
};

export const MSG_loadingEllipsis = {
  id: "c48525dc-93ce-4dbc-ad5e-6fdbca7c7437",
  description: "A message that is displayed when a spinner is shown and data is loading from the server.",
  defaultMessage: "Loading...",
};

export const MSG_verifyingEllipsis = {
  id: "8fca279b-69b4-40f2-8cbe-2ee5d6d1ff03",
  description: "A message that is displayed when a spinner is shown and the information they entered is being verified.",
  defaultMessage: "Verifying...",
};

export const MSG_initializingEllipsis = {
  id: "3e89486c-3965-4829-ac4f-522070f0b109",
  description: "A message that is displayed when a spinner is shown and some service or system is being initialized.",
  defaultMessage: "Initializing...",
};

export const MSG_loadingMapEllipsis = {
  id: "828652b2-e3a1-4a84-af3a-5a5aa65dc84a",
  description: "Displayed when we are loading a map from the network",
  defaultMessage: "Loading map..."
};

export const MSG_addressNotFound = {
  id: "5f939570-6a4d-43c7-b170-effc8ec3e7cb",
  description: "Displayed when we could not display a map because one of the addresses in the map was not found.",
  defaultMessage: "Address not found"
};

export const MSG_smartRouteChanged = {
  id: "bc9864b3-ab43-4608-bcae-a7d8f10d8203",
  defaultMessage: "You have changed something about the original booking parameters of this appointment.  If you need to make changes, it is best to go back and run the search again so Gazelle can optimize your schedule."
};

export const MSG_sendConfirmationEmailExplanation = {
  id: "ceac7a89-4f45-4524-8e42-7e1319337c71",
  defaultMessage: 'Selecting this box will send a confirmation email to the client if they have an email address on file.  The "Appointment Added To Calendar" one-off email can be edited in Settings > One-Off Emails.'
};

export const MSG_schedulerGenericError = {
  id: "1e91c5c7-2ff5-4ca9-904c-098999da4418",
  description: "This is a generic error message that shows up when a client tries to schedule an appointment but there was a problem.",
  defaultMessage: "Please call us at {phone} to continue scheduling.  We apologize for the inconvenience.",
};

export const MSG_noSelfSchedulingServicesError = {
  id: "e5bdd9ba-8b02-4bf2-94bc-beea39a7eb17",
  defaultMessage: "{companyName} does not have any self-schedulable services set up at this time.",
};

export const MSG_yourStreetAddressTitle = {
  id: "528ba02f-9d2e-403b-99d7-c3b7d97dd4b4",
  description: "The heading above the section that displays their street address.  This is the address that the technician will drive to to service their piano.",
  defaultMessage: "Your Street Address",
};

export const MSG_findYourStreetAddressTitle = {
  id: "4d04099e-4aca-4460-8213-c043412a8743",
  description: "The label for the field where users search for their address using Google's database",
  defaultMessage: "Find Your Street Address",
};

export const MSG_verifyYourStreetAddressTitle = {
  id: "df483cc9-f67a-4f5d-83d2-15aac6d9b3af",
  description: "The label that is displayed above the address that we have verified with our mapping provider, and we know its exact GPS coordinates.",
  defaultMessage: "Verify Your Street Address",
};

export const MSG_unableToFindAddressError = {
  id: "0def2825-e206-41f7-8332-9dbb6945247b",
  defaultMessage: "We were unable to find the exact location of your address.  Please verify that the address above is correct including the street address and city name.",
};

export const MSG_exactLocationNotFoundShortError = {
  id: "417f1a0f-f647-4260-a119-975c718313d4",
  defaultMessage: "The exact location could not be found."
};

export const MSG_approximateLocationLabel = {
  id: "0a42f0ad-81a9-4fc2-8ed5-5a74de8c5d8e",
  description: "This is a label that tells them the point on a map is approximate.",
  defaultMessage: "Approximate location"
};

export const MSG_approximateLocationDescription = {
  id: "1b53f1be-92c0-4ec3-9a42-7b85e9c4c6a2",
  description: "This is a description that explains why the location is approximate.",
  defaultMessage: "We were unable to find the exact rooftop address for this location. Either an incomplete address was provided, or the address could not be found. Please check for typos or verify with your client that this is the correct address. In some rare cases, this may happen because our mapping provider does not yet know about this address."
};


export const MSG_missingAddressHelp = {
  id: "120bf896-470c-4ad2-bd36-8d02f50e396e",
  description: "In this self-scheduling app, we never want a client to leave in frustration.  This is a notice displayed to potentially frustrated users who are not able to find their address using our mapping provider.  This should direct them to call the technician to continue scheduling.",
  defaultMessage: "Can't find your address?  Call us at {phone}.",
};

export const MSG_pianosAndServicesTitle = {
  id: "7fbb245f-30f3-4655-ba83-14af96d64435",
  description: "A sidebar label for the second step of the scheduler prompting them to select pianos what services they want performed.  Horizontal space is very limited, so keep this as short as possible.",
  defaultMessage: "Pianos & Services",
};

export const MSG_numPianosValues = {
  id: "9ae42c36-a8ac-4067-b05c-9c8f835997f7",
  description: "This is a short summary of the pianos that hvae been selected.",
  defaultMessage: "{numPianos, plural, one {1 piano} other {# pianos}}"
};

export const MSG_numServicesValues = {
  id: "3f8e274f-4593-49f7-a79b-b6e8e007981e",
  description: "This is a short summary of how many services have been selected.",
  defaultMessage: "{numServices, plural, one {1 service} other {# services}}"
};

export const MSG_selectAppointmentTitle = {
  id: "35312350-2c19-4df7-9ab6-18eae45e75ea",
  description: "A sidebar label for the fourth step of the scheduler where they are presented with a list of available appointment slots and they choose one.  Horizontal space is very limited, so keep this as short as possible.",
  defaultMessage: "Select Appointment"
};

export const MSG_contactDetailsTitle = {
  id: "66758d9d-37b1-49ac-bf04-5474b516886f",
  description: "A sidebar label for the fifth step of the scheduler prompting them to add contact information to their records.  Horizontal space is very limited, so keep this as short as possible.",
  defaultMessage: "Contact Details",
};

export const MSG_finishedTitle = {
  id: "a76c6d6d-11ce-488e-80af-3d122ae0beb3",
  description: "A sidebar label for the sixth step of the scheduler where the appointment is reserved and we just show a final 'Thank you' and confirmation message.  Horizontal space is very limited, so keep this as short as possible.",
  defaultMessage: "Finished",
};

export const MSG_aboutYourPianoTitle = {
  id: "3469fcf3-6605-4856-859d-725bace208de",
  description: "This is a header above the form that asks for all their piano information.",
  defaultMessage: "About Your Piano",
};

export const MSG_chooseServicesTitle = {
  id: "0508fae5-70f8-44c9-ba84-b163a745213f",
  description: "The header on top of the form that lets them choose which services they want performed on a piano.  This form shows a list of services (like Tuning, Repair, etc) and lets them check boxes to select which ones they want performed.",
  defaultMessage: "Choose Services",
};

export const MSG_addServiceTitle = {
  id: "c0bfdf21-544b-4b6d-a4f6-bda650901bc8",
  description: "The label on the button that adds a new service to an appointment for a specific piano.",
  defaultMessage: "Add Service",
};

export const MSG_removeAllServicesTitle = {
  id: "8b5c1c51-a8c6-4c02-9d3b-9b0df3d895dc",
  description: "The label on the button that removes all selected services from the piano.",
  defaultMessage: "Remove All Services",
};

export const MSG_selectedPianosTitle = {
  id: "dc7dcd98-25fd-42bd-988d-a5d86ffda756",
  description: "The header on top of the form that lists all the pianos the have selected to have serviced for this appointment.",
  defaultMessage: "Selected Pianos",
};

export const MSG_noPianosOrServicesMessage = {
  id: "4ac07d7e-1a36-44a0-bc53-4563e905398e",
  description: "This is shown when they have not added any pianos or services.",
  defaultMessage: "No pianos or services have been added.",
};

export const MSG_otherPianosTitle = {
  id: "5ece675c-e5e3-411b-b6a5-46f29f7b0a5c",
  description: "A label on top of a list of other pianos the client may have on file, but that have not been added to this appointment",
  defaultMessage: "Other Pianos",
};

export const MSG_numberOfPianosLabel = {
  id: "a63abbd2-6bd2-4862-af93-5ee613fe0d03",
  description: "This displays the total number of pianos to be serviced for this appointment.",
  defaultMessage: "Number of pianos: {numPianos}",
};

export const MSG_servicesSummary = {
  id: "103eec1b-4a5c-427a-bc6e-d3a98c04e295",
  description: "This shows a shortened list of services.  The intent is to show one service name followed by an indication of how many other services were selected.",
  defaultMessage: `{numServices, plural, one {and 1 other service} other {and # other services}}`
};

export const MSG_nextTuningDueColonLabel = {
  id: "8dcdeae6-eb7c-4fb5-b14f-d9c8673c0f55",
  description: "The date this piano is next due for service.",
  defaultMessage: "Next tuning due: {date}",
};

export const MSG_editAndChangeServicesTitle = {
  id: "823bd2a2-af5a-4b04-8828-ff2eb65c8f32",
  description: "The label on the button that lets them change existing piano information and selected services for a piano.",
  defaultMessage: "Edit & Change Services"
};

export const MSG_editAndAddServicesTitle = {
  id: "c7ea035b-df28-4972-a69b-7f1a58d46219",
  description: "The label on the button that lets them add services for an existing piano to this appointment.",
  defaultMessage: "Edit & Add Services",
};

export const MSG_removePianoTitle = {
  id: "c74b7897-159e-4df9-9983-ef2ce309d956",
  description: "The label on the button that lets them remove a piano from the list.",
  defaultMessage: "Remove Piano",
};

export const MSG_addAnotherPianoTitle = {
  id: "0a2c5ef8-ee3a-4305-a35f-bbe49007ff87",
  description: "The label for the button to add another piano.",
  defaultMessage: "Add Another Piano",
};

export const MSG_plusSlotOptions = {
  id: "351c08b5-d8a6-49aa-a2f8-0112c6549a91",
  description: "When the client is presented with a time slot for an hour (i.e. between 9am and 10am), this is a label used to indicate that more than one appointment option exists for this hour.",
  defaultMessage: "{numOptions, plural, one {+1 Option} other {+# Options}}",
};

export const MSG_numOptionsForHour   = {
  id: "41a09ef8-a4c7-4c49-af47-f7121aea280b",
  description: "This explains what the user is seeing when they click on an hour, and they are shown multiple appointment options.",
  defaultMessage: "{numOptions, plural, one {There is one option available for this hour.} other {There are # options available for this hour.}}",
};

export const MSG_searchingCalendarsForAppointments = {
  id: "f1066bd6-5684-4de0-8671-baa3f1a21e1e",
  description: "Text to display when we are searching multiple calendars for available appointment slots.",
  defaultMessage: "Searching our calendars for appointments...",
};

export const MSG_searchingForAppointments = {
  id: "63a3ebdc-d189-4dbb-af8a-2a4aab80b20d",
  description: "Text to display when we are searching one calendar for available appointment slots.",
  defaultMessage: "Searching for appointments...",
};

export const MSG_outsideServiceAreaTitle = {
  id: "87d684a8-c40d-4712-a748-c0aefe765314",
  description: "A short header to display when we detect that they are outside of our normal service area (i.e. they live too far away).",
  defaultMessage: "Outside Service Area",
};

export const MSG_outsideServiceAreaMessage = {
  id: "b2652500-6365-4a70-940c-457df28e0203",
  description: "A message that explains that they are outside of the normal service area and cannot schedule online.  We don't want to lose a possible lead though, so we encourage them to call the technician to schedule.",
  defaultMessage: "Your address seems to be outside of our normal service area.  We may still be able to schedule this appointment.  Please contact us at {phone} or {email}.",
};

export const MSG_noAppointmentSlotsFoundTitle = {
  id: "7baa3be4-c0dc-46a9-b3ff-1d095420228f",
  description: "A short header to display when no appointments were found in response to their search.",
  defaultMessage: "No Appointment Slots Found",
};

export const MSG_noAppointmentSlotsFoundMessage = {
  id: "9ef09383-26f3-4e9b-8274-af0f49234a78",
  description: "A message that explains that no results were found in response to their appointment search.",
  defaultMessage: "We may still have availability.  Please call us at {phone} to continue with scheduling.",
};

export const MSG_changePreferencesAndSearchAgainTitle = {
  id: "c70ee40f-ab0e-49eb-bf5b-3d32cab24be6",
  description: "This is the label for the button that takes them back to Step 3 (Preferences) so they can change their preferences and search again.",
  defaultMessage: "Change Preferences and Search Again",
};

export const MSG_chooseAppointmentTimeTitle = {
  id: "d2ac945d-3853-4feb-b342-25b1956769e5",
  description: "A short header displayed when they searched for availability, and we did not have availability on the exact day they requested, but we do have other options.",
  defaultMessage: "Choose an Appointment Time",
};

export const MSG_noAppointmentsOnDateMessage = {
  id: "f85f71b2-5724-4490-8ea7-aa3ff76f5e47",
  description: "A sentence displayed when they searched for availability, and we did not have openings on the exact day requested.",
  defaultMessage: "We looked but regretfully were unable to find an opening on {date}.  Here are some other openings that might work.  The times with a gold star {star} would be our first choice.",
};

export const MSG_goodNews = {
  id: "00ed9d73-71fb-45bf-84b1-1dc52d991065",
  description: "A short header displayed when they searched for availability, and we had openings on the exact day they requested.  This is just the header, there is just the large bold header.  It is described more later.",
  defaultMessage: "Good news!",
};

export const MSG_weHaveAvailability = {
  id: "35d75c0c-4769-4d4a-bc60-2d7e1e2de132",
  description: "A sentence displayed when they searched for availability, and we had openings on the exact day they requested.",
  defaultMessage: "We have availability on the date you requested.",
};

export const MSG_otherDateSlots = {
  id: "cc019a49-2791-40f1-a67e-f2ccf13c93fb",
  description: "A sentence telling them we found some other good days as well.  This lists more scheduling options, other than the featured one.",
  defaultMessage: "If you want to explore alternative dates, the times with a gold star {star} would be our first choice.",
};

export const MSG_shortTermDefaultMessage = {
  id: "ae62586c-085c-470a-be75-15a4f2d07840",
  description: "The default message when a client tries to schedule an appointment within the short term limit.",
  defaultMessage: "We have not yet opened this day for scheduling.  Please contact us at {phone} or {email} if you would like to schedule for this day.",
};

export const MSG_longTermDefaultMessage = {
  id: "747b31b0-72f4-44ef-9535-78178bd2884e",
  description: "The default message when a client tries to schedule an appointment within the long term limit.",
  defaultMessage: "We are not yet booking appointments this far in advance through this self-scheduling feature.  Please contact us at {phone} or {email} if you would like to schedule for this day.",
};

export const MSG_reserveMyAppointmentTitle = {
  id: "1e2680f2-303c-4930-9f9c-0d71a23187e9",
  description: "The label for the button that submits the reservation.",
  defaultMessage: "Reserve My Appointment",
};

export const MSG_primaryContactInfoTitle = {
  id: "8c0fa58e-5974-4b7b-a3d6-ce2489d69f45",
  description: "The header at the top of step 5 for collecting contact information about primary contact person.",
  defaultMessage: "Primary Contact Information",
};

export const MSG_notesOrSpecialRequestsLabel = {
  id: "cc7078a9-ff0e-4181-af20-3db7da3c74de",
  description: "The label for the text field asking for any notes or special requests that should be noted regarding this appointment.",
  defaultMessage: "Notes or special requests",
};

export const MSG_smsOptInFinePrint = {
  id: "78bab1cf-9c14-445c-b23a-cb4bbf5105e3",
  description: "",
  defaultMessage: "Message and data rates may apply.  Message frequency varies.  Text STOP to unsubscribe, get help at {phoneNumber}."
};

export const MSG_thankYou = {
  id: "a0f15c55-fd6e-4b87-89e2-2a672d08ca03",
  description: "A message saying 'Thank You' at the top of the final page once the appointment is confirmed.",
  defaultMessage: "Thank you!",
};

export const MSG_reviewingYourReservation = {
  id: "dbee23a8-f6e5-49f3-aadf-02c9f4cade70",
  description: "A message describing what the next steps are after the appointment reservation has been submitted.",
  defaultMessage: "We are reviewing your reservation.",
};

export const MSG_pendingApprovalTitle = {
  id: "665288c3-7c4a-402b-9d87-52609a3f122e",
  description: "The status known as 'Pending Approval' which means that we are currently waiting for a technician to approve the reservation before it is finalized.",
  defaultMessage: "Pending Approval",
};

export const MSG_returnToWebsiteTitle = {
  id: "895448fd-b19c-45f6-87c6-38e0ce20e727",
  description: "The label for a button that links the user back to the technician's website.",
  defaultMessage: "Return to Our Website",
};

export const MSG_schedulerNotAvailable = {
  id: "fceabc9c-bcee-47fd-8e0d-f4ffe5ed703c",
  defaultMessage: "Our online scheduling system is currently not available.",
};

export const MSG_slotWarningNoRoute = {
  id: "ca2f73ca-f7cd-443d-878a-a94ac1e9c51c",
  defaultMessage: "No driving directions could be found for one of the events on this day.  This route uses some estimated drive times."
};

export const MSG_slotWarningMaxTravelTime = {
  id: "347a66cf-abbb-4993-a60c-da5e5956b17a",
  description: "",
  defaultMessage: "The travel time between events surpasses the maximum travel time you have set for this service area."
};

export const MSG_slotWarningOutsideAreaMinutes = {
  id: "c517a784-253f-42a8-bd43-d83288296de5",
  defaultMessage: "This is {duration} minutes outside your service area for this date.",
};

export const MSG_slotWarningOutsideAreaMiles = {
  id: "d0ebb53b-1091-4df8-b01e-98e2f58ebe97",
  defaultMessage: "This is {distance} miles outside your service area for this date.",
};

export const MSG_slotWarningNearExistingAppointmentMinutes = {
  id: "4cccd79b-175b-493f-9191-f07e3d814646",
  defaultMessage: "This is {duration} minutes outside your service area for this date, but is near an existing appointment on your calendar.",
};

export const MSG_slotWarningNearExistingAppointmentMiles = {
  id: "54bb644b-3769-4261-afcc-03d063def0bc",
  description: "",
  defaultMessage: "This is {distance} miles outside your service area for this date, but is near an existing appointment on your calendar.",
};

export const MSG_slotWarningShortTermLimit = {
  id: "d072d36d-1fdd-4e22-97aa-39d9f6a8859c",
  defaultMessage: "This time would violate your short term limit policy.",
};

export const MSG_slotWarningLongTermLimit = {
  id: "f7aaed37-6cca-44e5-a6e3-154cc4a6bf23",
  defaultMessage: "This time would violate your long term limit policy.",
};

export const MSG_slotWarningTraffic = {
  id: "2c7809cf-c885-4801-9986-088aac9dd6bf",
  defaultMessage: "Estimated {numMinutes}-minute slowdown with traffic.",
};

export const MSG_noMapAvailable = {
  id: "890d5eac-e2a3-4b17-a5c0-74ab0e86f3a2",
  description: "",
  defaultMessage: "No map available"
};

export const MSG_clickOnOneToContinue = {
  id: "79389480-fa9c-434f-a9e4-1f1bb193fa97",
  defaultMessage: "Click on one to continue",
};

export const MSG_areYouSureSaveContactCloseAppointment = {
  id: "37270189-4981-4c1f-a029-88277a0d7bf7",
  defaultMessage: "Are you sure?  This will save the contact information as a new client record, but will close the appointment you are scheduling.",
};

export const MSG_selectedAnExtraDuration = {
  id: "6ebd2f60-22a5-4a2f-a646-fc740b9b4af8",
  description: "This is shown when they selected a time slot that is longer than how long we think it will take to complete the appointment.  Duration is like '30 minutes' or '1 hour 20 minutes'.",
  defaultMessage: "You selected an extra {duration}.",
};

export const MSG_servicesSelectedOnlyRequireDurationIncludingBuffer = {
  id: "694ca75d-87ab-487f-b6d0-0ba2a1e9ea9f",
  defaultMessage: "The services you selected only require {totalDuration} (including a buffer of {bufferDuration}), but you selected {selectedDuration}.",
};

export const MSG_servicesSelectedOnlyRequireDuration = {
  id: "5b561974-152d-4733-80a7-9b0981e815ea",
  defaultMessage: "The services you selected only require {totalDuration}, but you selected {selectedDuration}.",
};

export const MSG_servicesSelectedRequireDurationIncludingBuffer = {
  id: "a5ee5e2d-02f1-4797-abeb-e90be421ea41",
  defaultMessage: "The services you selected require {totalDuration} (including a buffer of {bufferDuration}), but you selected {selectedDuration}.",
};

export const MSG_servicesSelectedRequireDuration = {
  id: "1a2629d2-fa9d-4735-bab0-b24d3af381a2",
  defaultMessage: "The services you selected require {totalDuration}, but you selected {selectedDuration}.",
};

export const MSG_removeExtraDurationTitle = {
  id: "f763956d-5b19-46ee-9add-7433ebbcf4e8",
  defaultMessage: "Remove The Extra {duration}",
};

export const MSG_youNeedAnExtraDuration = {
  id: "dd0b4678-b085-44a5-9eaf-bf910aee0e63",
  description: "This is shown when they selected a time slot that is shorter than how long we think it will take to complete the appointment.  Duration is like '30 minutes' or '1 hour 20 minutes'.",
  defaultMessage: "You need an extra {duration}.",
};

export const MSG_addTheExtraDurationTitle = {
  id: "bfaa3d84-a7b3-4b07-8bba-b271f8f4df7a",
  defaultMessage: "Add The Extra {duration}",
};

export const MSG_cannotPerformSelectedServicesTitle = {
  id: "e1c5ea73-24ac-4ac5-be08-f3ed9a57b48e",
  description: "An indicator that this user cannot perform the selected services.",
  defaultMessage: "Cannot Perform Selected Services",
};

export const MSG_followingServicesSelectedCannotBePerformed = {
  id: "9ba8ac73-486e-4a04-9dbc-eac3897dff59",
  defaultMessage: "The following services that were selected for this appointment cannot be performed by {name}:",
};

export const MSG_someTechsCannotPerformServices = {
  id: "8e15095f-a0dc-4b7d-981d-76bf81de0735",
  description: "",
  defaultMessage: "Some technicians cannot perform the services you have selected.  They will not be included in your search."
};

export const MSG_editingClientWhileSchedulingNotSupported = {
  id: "cc43a1ab-175a-4200-ba28-5d32285c7dbd",
  defaultMessage: "Editing an existing client while scheduling is not currently supported.  To edit client information, please do it before scheduling a new appointment.  To choose a different client, use the 'Reset' button."
};

export const MSG_findExistingClientTitle = {
  id: "08ad5c4d-ab95-4a09-894e-3f1a8eac05bb",
  defaultMessage: "Find Existing Client"
};

export const MSG_iWouldLikeSmsReminders = {
  id: "9b483557-7d98-4b3b-9f22-a8f65e1f5ef1",
  defaultMessage: "I would like to receive SMS reminders.",
};

export const MSG_schedulingSettingsForUser = {
  id: "b26fbfb3-cef6-4f7e-bd7e-189679df610f",
  description: "This is a title for a section where you can set scheduling settings for a user.  Like 'Scheduling Settings for John Smith'.",
  defaultMessage: "Scheduling Settings for {name}"
};

export const MSG_schedulingSettingsForAnotherUserWarning = {
  id: "5e39b4d6-95dd-46c2-b0d8-c72b61cc9a56",
  description: "",
  defaultMessage: "You are currently editing settings for someone other than yourself."
};


export const MSG_isUserSchedulableQuestion = {
  id: "c4cf06cc-b171-4ed6-bc90-4261920c1c1d",
  description: "This is a label on top of a toggle that indicates whether a technician can be scheduled for appointments.",
  defaultMessage: "Is {name} schedulable?"
};

export const MSG_userIsSchedulableLabel = {
  id: "eb1714c8-5d02-4f55-afba-0173dc5fd822",
  description: "This is a label that indicates a technician can be scheduled for appointments.",
  defaultMessage: "{name} is schedulable"
};

export const MSG_userIsNotSchedulableLabel = {
  id: "99228a67-1126-4b18-812e-b7a1ae6c50bb",
  description: "This is a label that indicates a technician cannot be scheduled for appointments.",
  defaultMessage: "{name} is not schedulable"
};

export const MSG_showingColon = {
  id: "6e4b9416-51ea-47e3-8a4e-94cd7609489b",
  description: "A label that goes before a description of what is currently being shown.",
  defaultMessage: "Showing:"
};


export const MSG_showAllAvailabilitiesLabel = {
  id: "161e2786-b038-46c1-8e05-9a1232ebcd41",
  description: "The option to show all availabilities in the list",
  defaultMessage: "Show all"
};

export const MSG_showCurrentAvailabiltiesLabel = {
  id: "202d12d6-6497-47bd-8c1e-5ea9e9ce1560",
  description: "This will only show the currently active option",
  defaultMessage: "Show all in effect today"
};

export const MSG_showFutureAvailabiltiesLabel = {
  id: "f41fe99a-b596-44eb-9df5-27d3a06c37f1",
  description: "This will show the currently active option as well as future options",
  defaultMessage: "Show all in effect today and in the future"
};

export const MSG_showAllAvailabilitiesLinkLabel = {
  id: "ac2dc6fb-d23d-4e35-8f0e-994543d07e0b",
  description: "This is the label a the link that shows all availabilities.",
  defaultMessage: "Show all availabilities."
};

export const MSG_filterByTimeLabel = {
  id: "ce3df213-0139-4321-86d3-4a4a14613794",
  description: "This is the label for the option that filters the list of availabilities by time.",
  defaultMessage: "Filter by time"
};

export const MSG_filterByServiceAreaLabel = {
  id: "6ccf6f60-bbb7-4033-8368-dc3f29d32bda",
  description: "This is the label for the option that filters the list of availabilities by service area.",
  defaultMessage: "Filter by service area"
};

export const MSG_allServiceAreas = {
  id: "940f2441-82af-4eb8-8327-45290946ffb4",
  description: "This is the filter option that shows all service areas.",
  defaultMessage: "Show all service areas"
};

export const MSG_showOnlyServiceArea = {
  id: "2126eca2-ae6e-4511-89c9-3ce9d1c8db44",
  description: "This filter option shows only a single service area, indicated by the {name} parameter.",
  defaultMessage: "Show only for {name}"
};


export const MSG_fromDateForever = {
  id: "59408be4-63f9-41b8-a5b7-e1f192f50c84",
  description: "A date range that starts on a date and has no end date.",
  defaultMessage: "Starting {date}"
};

export const MSG_exclusiveTitle = {
  id: "8eeed2fc-6333-417d-9b74-011022195f37",
  description: "This is an indicator that an Availability is exclusive, meaning that no other Availabilities can be active while this one is active.",
  defaultMessage: "Exclusive"
};

export const MSG_noAvailabilitiesMessage = {
  id: "90e7cafb-a1c6-4828-b114-0ba41254ef7d",
  description: "",
  defaultMessage: "No availabilities have been created yet."
};

export const MSG_showingXofYAvailabilitiesMessage = {
  id: "8964649b-cd3c-4e03-81e6-63ea3e264cb4",
  description: "",
  defaultMessage: "Showing {count} of {total} availabilities."
};

export const MSG_showingXofYAvailabilitiesMessageInEffectToday = {
  id: "afca7b25-d5a4-4ebb-9a82-0a131a47b500",
  description: "",
  defaultMessage: "Showing {count} of {total} availabilities in effect today."
};


export const MSG_showingXofYAvailabilitiesMessageInEffectTodayOrFuture = {
  id: "70dde9f6-ade7-4673-95f8-e83780dc8dca",
  description: "",
  defaultMessage: "Showing {count} of {total} availabilities in effect today or in the future."
};

export const MSG_newAvailabilityTitle = {
  id: "16408c26-0024-47f1-8bd2-a5e4fb622474",
  description: "A title on a modal that lets them create a new Availability.",
  defaultMessage: "New Availability"
};

export const MSG_newAvailabilityForUserTitle = {
  id: "eae2dc63-7ff9-409d-aa28-dd56bd378123",
  description: "A title on a modal that lets them create a new Availability for anther user.",
  defaultMessage: "New Availability for {name}"
};

export const MSG_editAvailabilityTitle = {
  id: "f3414a49-f31a-4224-8255-9918f31bbed0",
  description: "The title on a modal that lets them edit an existing Availability.",
  defaultMessage: "Edit Availability"
};

export const MSG_editAvailabilityForUserTitle = {
  id: "098e109b-bb74-4cc0-8c3c-5945740c4d6c",
  description: "The title on a modal that lets them edit an existing Availability for another user.",
  defaultMessage: "Edit Availability for {name}"
};

export const MSG_visualizeAvailabilityTitle = {
  id: "c984298f-c4db-4907-bfaf-6eb41d72580a",
  description: "The title on a button that lets them visually see all their availabilities on a calendar.",
  defaultMessage: "Visualize Availability"
};

export const MSG_serviceAreaLabel = {
  id: "bc3883ab-15fc-49df-bc19-c8bec4c064d1",
  description: "A label for a form element where they can select the service area for an Availability.",
  defaultMessage: "Service area"
};

export const MSG_newServiceAreaTitle = {
  id: "e7301cfb-b7e6-4f62-b408-44e59d576160",
  description: "The label for a button that lets them create a new service area",
  defaultMessage: "New Service Area"
};

export const MSG_newBlankServiceAreaTitle = {
  id: "d70d2512-c3a0-453a-93f0-f4c9a0d01ccb",
  description: "The label for a button that lets them create a new blank service area with no pre-set rules.",
  defaultMessage: "New Blank Service Area"
};


export const MSG_newServiceAreaForUserTitle = {
  id: "f70dce16-4cb6-43e7-bbc8-23f6543325bf",
  description: "The label for a button that lets them create a new service area for another user",
  defaultMessage: "New Service Area for {name}"
};

export const MSG_editServiceAreaTitle = {
  id: "bdc4ba9c-0cfa-4e9f-b0a6-75a07995a167",
  description: "The label for a button that lets them edit an existing service area",
  defaultMessage: "Edit Service Area"
};

export const MSG_editServiceAreaForUserTitle = {
  id: "b56c0ada-8648-4f41-9777-40b00ae30f02",
  description: "The label for a button that lets them edit an existing service area for another user",
  defaultMessage: "Edit Service Area for {name}"
};

export const MSG_availabilityGroupWhenActiveLabel = {
  id: "4955282d-ba63-4760-85bc-6a3756b63712",
  description: "",
  defaultMessage: "When should this availability be active?"
};

export const MSG_availabilityGroupWhenActiveExplanation = {
  id: "605258a0-0615-4c3b-bcba-8bcc394559d9",
  description: "Instructions to help guide the user as they choose when an availability should be active",
  defaultMessage: "Configure when this Availability rule set should be applied. You can extend these rules indefinitely into the future by omitting the 'end date'; or select a specific date range to apply these rules. You can also create multiple overlapping Availability rule sets to accommodate complex scheduling scenarios."
};

export const MSG_availabilityRecurrenceExplanation = {
  id: "665bb324-b190-4ac3-93f0-36edd396a0fe",
  description: "Instructions to help guide the user as they configure repeating availability rules",
  defaultMessage: "Within the date range of this availability you can repeat availability on specific days of the week or adjust the dates these rules will be applied by using a custom schedule. NOTE: If you have an End Date selected for this availability, a repeating rule set will NOT be offered outside your date parameters."
};


export const MSG_availabilityGroupWhereStartEndLabel = {
  id: "22287d44-56e5-4b32-ad67-836ae7c17d23",
  description: "",
  defaultMessage: "Where will you start and end each day?"
};

export const MSG_availabilityGroupWhereStartEndExplanation = {
  id: "59b889aa-ee0f-4768-bc91-ab3c4bb6b7fd",
  description: "Instructions to help guide the user as they choose a subset of their availability preferences",
  defaultMessage: "Your scheduling availability rules require a 'start-of-day' and 'end-of-day' location so Gazelle can calculate the travel time to and from your first and last appointment of the day."
};

export const MSG_availabilityGroupServiceAreaLabel = {
  id: "25ec3e5f-8e11-44b8-ae95-cb291fbe2e57",
  description: "",
  defaultMessage: "Which service area should this apply to?"
};

export const MSG_availabilityGroupAdditionalPrefsLabel = {
  id: "91c00b00-0333-4999-9034-e0ab49fd4f27",
  description: "",
  defaultMessage: "Additional preferences"
};

export const MSG_availabilityGroupAdditionalPrefsExplanation = {
  id: "8ac1510f-ac86-40d7-9520-7d4426ecde15",
  description: "Instructions to help guide the user as they choose a subset of their availability preferences",
  defaultMessage: "Each availability rule has the option of setting additional constraints for how these rules will be applied. These additional configuration options allow for unique customizations to fit a variety of scheduling needs."
};

export const MSG_availabilityGroupExclusiveLabel = {
  id: "39d2d71c-3099-4fa7-bed1-89e6a50eb15e",
  description: "",
  defaultMessage: "Exclusive?"
};

export const MSG_availabilityGroupExclusiveExplanation = {
  id: "b3ad64d2-cb61-4e07-aef2-cef17ded5c03",
  description: "Instructions to help guide the user as they choose whether an availability is Exclusive or Inclusive",
  defaultMessage: "Choose 'Yes (Exclusive)' if you are scheduling a trip and you only want this availability to be active during the dates you select. Choose 'No (Inclusive)' if you want this Availability to be offered simultaneously alongside other Availability rule sets with overlapping dates."
};


export const MSG_startingLabel = {
  id: "0dad9a9b-421e-4adb-bc70-2755dac38a41",
  description: "A label for a form element where they can select the starting date for an Availability.",
  defaultMessage: "Starting"
};

export const MSG_endingLabel = {
  id: "7706678b-0098-483c-881e-4c37a694dec8",
  description: "A label for a form element where they can select the ending date for an Availability.",
  defaultMessage: "Ending"
};

export const MSG_repeatingLabel = {
  id: "25fe3a75-cb67-4754-9d13-ab357a46550f",
  description: "This is a label above a component that shows when this Availability should repeat.",
  defaultMessage: "Repeating"
};

export const MSG_availabilityEndDateBeforeOneExistingDateConfirmation = {
  id: "2920dc46-b149-4f61-a97c-bc7fb29cfffe",
  description: "",
  defaultMessage: "You have an exception on this availability for {date}.  Since this is after your new end date, it will be removed from your exceptions when you save this availability."
};

export const MSG_genericAvailabilityEndDateBeforeExistingDateConfirmation = {
  id: "548d3d58-4ffe-40d1-8ac8-73da234a6f50",
  description: "",
  defaultMessage: "You have dates that are outside of the starting and ending dates for this availability.  Those dates will be removed from your exceptions when you save this availability."
};

export const MSG_availabilityEndDateBeforeManyExistingDatesConfirmation = {
  id: "d9e3b60f-7327-4d5b-b3a8-f5f7f03b1ed5",
  description: "",
  defaultMessage: "You have exceptions on this availability for {date} and {numOtherDates} other dates.  Since they are after your new end date, they will be removed from your exceptions when you save this availability."
};

export const MSG_availabilityNoRecurrenceOrIncludeDateMessage = {
  id: "83188dcc-eba3-41a1-b8f1-88a61ce5d1a3",
  description: "",
  defaultMessage: "No slots will be offered for this availability until a repeating rule is configured or inclusion dates have been added. You can manually add inclusion dates now or from the calendar later."
};


export const MSG_excludingSpecificDatesLabel = {
  id: "8a8aa17c-de9f-466d-bba2-97e13b828de0",
  description: "",
  defaultMessage: "Exclude these specific dates"
};

export const MSG_excludingSpecificDatesTitle = {
  id: "99d80d5c-11e9-4910-8f32-707b75ccc318",
  description: "",
  defaultMessage: "Exclude These Specific Dates"
};

export const MSG_includingSpecificDatesLabel = {
  id: "aff9a81c-ebd5-461a-96b6-9a8d3d06e434",
  description: "",
  defaultMessage: "Include these specific dates"
};

export const MSG_includingSpecificDatesTitle = {
  id: "344eb724-4fbf-4b69-896a-32b4e7d8a0cd",
  description: "",
  defaultMessage: "Include These Specific Dates"
};

export const MSG_addDateTitle = {
  id: "126a7b46-9bbe-43e7-bc40-d543b61d6bcd",
  description: "The title of a button that lets them add a date to a list of dates.",
  defaultMessage: "Add Date"
};

export const MSG_clickHereToAddADate = {
  id: "4bde5a0a-473a-4422-ac0f-0303b60e3765",
  description: "",
  defaultMessage: "Click this button to add a date to this list."
};


export const MSG_plusMoreDates = {
  id: "cf797bd0-0b6b-44ab-99d6-cc77f19b4c0b",
  description: "When showing a large list of calendar dates, this is a message that indicates there are more dates that are not shown.",
  defaultMessage: "{count, plural, one {+1 more date} other {+{count} more dates}}"
};


export const MSG_startOfDayLocationLabel = {
  id: "35101970-aac5-4ef7-8045-0cbcdc567dfa",
  description: "The label for a form element where they can select the start-of-day location for an Availability.",
  defaultMessage: "Start-of-day location"
};

export const MSG_atStartOfDayLocationLabel = {
  id: "3d37a534-daa4-439a-acef-1212667e9828",
  description: "This is a label that indicates at a certain time of day the technician wants to be at their start-of-day location.",
  defaultMessage: "at start-of-day location"
};


export const MSG_endOfDayLocationLabel = {
  id: "ffa5bb27-3699-4559-82b9-1dc07566e53f",
  description: "The label for a form element where they can select the end-of-day location for an Availability.",
  defaultMessage: "End-of-day location"
};

export const MSG_atEndOfDayLocationLabel = {
  id: "2eba8455-2ea2-4e9f-94c6-ad98f8925ba9",
  description: "This is a label that indicates at a certain time of day the technician wants to be at their end-of-day location.",
  defaultMessage: "at end-of-day location"
};

export const MSG_atClientLocationLabel = {
  id: "1ec45559-25e6-41d4-a4a9-6fe3ea7d1ef6",
  description: "This is a label that indicates at a certain time of day the technician wants to be at the client's location.",
  defaultMessage: "at client's location"
};

export const MSG_copyStartOfDayLocation = {
  id: "8fb89e0f-3dd8-439a-8874-1e1779da6b5a",
  description: "",
  defaultMessage: "Copy start of day location"
};

export const MSG_copyEndOfDayLocation = {
  id: "675b9346-8a22-4451-8bfa-b4e0b47dc7c8",
  description: "",
  defaultMessage: "Copy end of day location"
};

export const MSG_hoursOfOperationLabel = {
  id: "982c115b-ca45-49a9-9726-c973039fa716",
  description: "The label for a form element where they can select the hours (e.g. start and end time) for an Availability.",
  defaultMessage: "Hours of operation"
};

export const MSG_startTimeLabel = {
  id: "a46c6bcf-db6f-4b2b-be0c-c71bbca4fd23",
  description: "The label for a form element where they can select the start time for the hours of operation for an Availability.",
  defaultMessage: "Start time"
};

export const MSG_endTimeLabel = {
  id: "824ecb7e-2276-4582-a2a1-adaa4a15eec6",
  description: "The label for a form element where they can select the end time for the hours of operation for an Availability.",
  defaultMessage: "End time"
};

export const MSG_preferredSlotsLabel = {
  id: "75c005b0-2441-443e-8d2b-6774da2a62ab",
  description: "The label for a form element where they can select the preferred time slots for an Availability (e.g. 8 AM, 12 PM, and 3 PM).",
  defaultMessage: "Preferred time slots"
};

export const MSG_preferredSlotsExplanation = {
  id: "48cc7ea6-2801-47d3-a85f-d2f1852b0d07",
  description: "Instructions to help guide the user as they configure their preferred time slot setting",
  defaultMessage: "Preferred time slots are the ideal times you want to offer a client, especially on an open day that doesn’t have anything scheduled yet."
};


export const MSG_noPreferredSlots = {
  id: "1602081e-522c-4807-8083-6dc50f867aaa",
  description: "",
  defaultMessage: "No preferred time slots have been added."
};

export const MSG_preferredSlotBeforeStartOfDay = {
  id: "9c355613-e1c2-4f05-9cd6-d492adae4d0d",
  description: "",
  defaultMessage: "This is before your start of day which is set at {startTime}."
};

export const MSG_preferredSlotAfterEndOfDay = {
  id: "44a7b11a-9e5c-4408-b552-11bcd61ebe03",
  description: "",
  defaultMessage: "This is after your end of day which is set at {endTime}."
};

export const MSG_addSlotLabel = {
  id: "24d6760e-584b-43ce-aa63-fe37a0244f7a",
  description: "The label for a button that lets them add a preferred time slot for an Availability.",
  defaultMessage: "Add time slot"
};

export const MSG_availabilityNameLabel = {
  id: "5beae35e-5dac-47ac-b873-cd8d5a7b8842",
  description: "A label for the name of an Availability.  We call it a 'label' instead of a 'name' because it's not required and we wanted to imply that it's just a label to help you identify it later.  Like 'Summer Hours' or 'Winter Hours'.",
  defaultMessage: "Label"
};

export const MSG_availabilityNameExplanation = {
  id: "50e62214-49dd-4b10-a396-1db72457f5e2",
  description: "",
  defaultMessage: "This is your internal name for this availability rule set. It is used to help you identify it later."
};

export const MSG_preferredSlotPolicyLabel = {
  id: "18dc1cd0-545e-406c-b168-be627b50490f",
  description: "This is a question asking when they want to offer their preferred time slots to clients.",
  defaultMessage: "When should we offer preferred time slots?"
};

export const MSG_preferredSlotPolicyExplanation = {
  id: "41b9595e-68b8-404b-b0bd-c56f9df50569",
  description: "Instructions to help guide the user as they configure exceptions to their preferred time slot setting",
  defaultMessage: "This setting allows you to customize how Gazelle favors your preferred time slots when we offer routes for scheduling."
};

export const MSG_adjustPreferredSlotsLabel = {
  id: "0316cc36-6c53-4397-9d77-86ec66964fc3",
  description: "This is a question for a checkbox toggle asking if they want to adjust their preferred time slots to account for travel time.",
  defaultMessage: "Allow preferred time slots to be adjusted based on travel time"
};

export const MSG_adjustPreferredSlotsExplanation = {
  id: "7bce3a7b-f483-41b0-9b75-026c39b431bd",
  description: "Instructions to help prevent the first or last preferred time slot from being dropped due to travel time limits.",
  defaultMessage: "Select this option if you want Gazelle to adjust the start time of the first & last preferred time slot of the day to account for travel time. If you don’t select this option Gazelle will sometimes drop the first & last preferred timeslot of the day due to the travel time to/from your start or end of day location."
};




export const MSG_onlyPreferredSlotsLabel = {
  id: "16879f0d-f15a-4feb-a715-301ccd99b328",
  description: "A label for the scheduling policy where only their preferred slot times are offered to clients.",
  defaultMessage: "Only ever offer preferred time slots"
};

export const MSG_onlyPreferredSlotsExplanation = {
  id: "8bcf7a2e-1277-4861-aede-62926fa4d684",
  description: "An explanation of what the 'only offer preferred slots' option does.",
  defaultMessage: "When this is selected, Gazelle will only offer your preferred time slots. Gazelle will skip any time slot that cannot accommodate travel time between appointments which can cause excess downtime in your schedule. No other times will be offered, and Smart Route optimization will not occur."
};

export const MSG_onlyOpenDaysLabel = {
  id: "67a476bf-b3ac-4023-9637-2760e6606022",
  description: "A label for the scheduling policy where preferred slot times are only offered on open days.",
  defaultMessage: "Only on open days"
};

export const MSG_onlyOpenDayExplanation = {
  id: "00786574-9e50-4cd6-a88c-136a6bac8ccc",
  description: "An explanation of what the 'only open days' preferred time slots option does.",
  defaultMessage: "When this is selected, preferred time slots will only be offered on open days. Once the first event is scheduled the day is no longer considered 'Open' and Gazelle will optimize the rest of your day by booking routes to and from existing events."
};

export const MSG_combinedLabel = {
  id: "3723b2bf-3f4b-44df-88ed-7af91e220dd9",
  description: "A label for the scheduling policy that is a combination of only-open-days and only-prefered-slots.",
  defaultMessage: "Always"
};

export const MSG_combinedExplanation = {
  id: "83a3ea5a-de08-471b-b81c-70e0e0ead371",
  description: "An explanation of what the 'Always offer preferred time slots does",
  defaultMessage: "When this is selected, your preferred time slots will always be offered to clients, but Gazelle will also offer optimized time slots.  This could cause some inefficient drive times around existing appointments."
};

export const MSG_inclusiveLabel = {
  id: "25d51a29-8c86-4662-aa77-346ee1525da7",
  description: "A label that describes if the Availability is inclusive or exclusive.  Exclusive means that only this one availability can be active at a time.",
  defaultMessage: "Inclusive"
};

export const MSG_inclusiveExplanation = {
  id: "10bebe3e-dba0-4fbc-bb92-3ec34ffb0c29",
  description: "An explanation of what an 'Inclusive' availability rule set means",
  defaultMessage: "When this is selected, this availability rule set may be active at the same time as others.  This is useful if you want to offer different time slots for overlapping service areas."
};

export const MSG_exclusiveLabel = {
  id: "2a6295fb-b0cd-445d-9a98-16d517fb277d",
  description: "A label that describes if the Availability is inclusive or exclusive.  Exclusive means that only this one availability can be active at a time.",
  defaultMessage: "Exclusive"
};

export const MSG_exclusiveExplanation = {
  id: "20425c4f-67a1-4715-a65d-12e731beb8a8",
  description: "An explanation of what an 'Exclusive' availability rule set means",
  defaultMessage: "When this is selected, during the effective dates for this availability, Gazelle will give exclusive priority over other availabilities assigned to the same service area. This means that, for this service area, during the effective dates for this availability, no other availabilities will be active at the same time."
};

export const MSG_downtimeRulesLabel = {
  id: "2281972b-d893-4fdc-b36f-b9c7878267c8",
  description: "A label for a section where they want 'downtime' (or unscheduled time) throughout the day (e.g. for a lunch break).",
  defaultMessage: "Downtime"
};

export const MSG_editDowntimeTitle = {
  id: "468fcf84-ae8d-4ee8-9d12-fa5893851851",
  description: "A title for a window where they can edit a downtime rule.",
  defaultMessage: "Edit Downtime"
};

export const MSG_downtimeRuleExplanation = {
  id: "0fec74f6-82e9-4179-9047-b97a4d774fda",
  description: "An explanation of 'Downtime' scheduling rules",
  defaultMessage: "You can schedule downtime during your day.  This is a flexible block of time that you don't want to be scheduled."
};

export const MSG_downtimeRuleExample = {
  id: "8de6dff2-0351-4813-9f43-33091eac0163",
  description: "This is an example of how a downtime rule works, written out in a sentence.",
  defaultMessage: "For example, you might want to take a 30-minute lunch break sometime between 11:00 AM and 1:00 PM; or you might want to take a siesta somewhere between 2:00-5:00pm."
};

export const MSG_noDowntimeRules = {
  id: "9ebd397e-3eff-4fb7-804b-122959a9733b",
  description: "",
  defaultMessage: "No scheduled downtime has been added."
};

export const MSG_downtimeRuleDetails = {
  id: "4431db1c-a278-4d46-9e3c-7253731bb89d",
  description: "An explanation of what a downtime rule does.  For example this would read like '60 minutes between 8:00 and 17:00'.",
  defaultMessage: "{duration} between {startTime} and {endTime}"
};

export const MSG_addDowntimeLabel = {
  id: "ae7feb8a-608b-4f77-bc30-f9c7437bbb5e",
  description: "A label for a link where they can add downtime (e.g. a lunch break) to their scheduling rules.",
  defaultMessage: "Add downtime"
};

export const MSG_maxAppointmentsPerDayLabel = {
  id: "4470f7a5-aca8-4f00-9dbd-413db9a585b8",
  description: "A label for an option to set the maximum number of appointments per day.",
  defaultMessage: "Max appointments per day"
};

export const MSG_availabilityMaxAppointmentsPerDayExplanation = {
  id: "cf3703d1-54a3-4ed2-a667-1836ff57eddd",
  description: "Instructions to help guide the user as they select their max appointments per day setting",
  defaultMessage: "Select this setting if you want to limit the maximum number of appointments per day Gazelle will offer when scheduling."
};

export const MSG_noMaximumLabel = {
  id: "12d49c62-2fb3-41aa-bae9-ed195bdf0932",
  description: "A label for a checkbox when there is no maximum number of appointments per day.",
  defaultMessage: "No maximum"
};

export const MSG_roundingMinutesLabel = {
  id: "7dbece95-3a7e-4a39-8e9e-056ee40ba247",
  description: "",
  defaultMessage: "Rounding"
};

export const MSG_roundingMinutesExplanation = {
  id: "bc16c136-1027-4962-a2e4-11b178e643f2",
  description: "",
  defaultMessage: "This is used to round appointment slots offered to clients up to the next interval.  For example, if you set this to 15 minutes, then an appointment option starting at 9:05 or 9:10 will round up to 9:15."
};

export const MSG_recurrenceRuleModalTitle = {
  id: "8ff94095-aeb5-4fb6-b5e6-152f50bbb21b",
  description: "",
  defaultMessage: "Recurrence"
};

export const MSG_rruleByWeekdays = {
  id: "1b978679-f391-42d7-a14a-166c2964fd74",
  description: "When they are setting up a recurring schedule to tell us what days they are available, this is the label that tells us they want to select weekdays (e.g. Mon-Fri).",
  defaultMessage: "By weekdays"
};

export const MSG_rruleByWeekdaysPerMonth = {
  id: "a9c389b1-1df8-41cf-afe3-d390233c2bdc",
  description: "When they are setting up a recurring schedule to tell us what days they are available, this is the label that tells us they want to select weekdays per month (e.g. the 2nd and 4th Monday of the month).",
  defaultMessage: "By weekdays per month"
};

export const MSG_rruleByDatesPerMonth = {
  id: "e4771801-c7ff-42ff-94bf-839209da8200",
  description: "When they are setting up a recurring schedule to tell us what days they are available, this is the label that tells us they want to select dates per month (e.g. the 1st and 15th of the month).",
  defaultMessage: "By dates per month"
};

export const MSG_rruleAdvanced = {
  id: "67bbc6c3-66d4-4de6-999f-9ee01d2d64c5",
  description: "When they are setting up a recurring schedule to tell us what days they are available, this is the label that tells us they want to select advanced options.",
  defaultMessage: "Advanced"
};

export const MSG_everyWeekLabel = {
  id: "f5be9d12-2d5f-4d9d-8c5e-2fe66b72701b",
  description: "The label that indicates something repeats every week.",
  defaultMessage: "Every week"
};

export const MSG_everyOtherWeekLabel = {
  id: "34cab520-3519-40a5-b494-4376677c3063",
  description: "A label that indicates something repeats every other week (e.g. in English this is another way of saying every two weeks).",
  defaultMessage: "Every other week"
};

export const MSG_everyNWeeksLabel = {
  id: "1e6e1949-7e1c-4f6d-8c70-254dfdf2d662",
  description: "The label that indicates something repeats every N weeks.",
  defaultMessage: "Every {n} weeks"
};

export const MSG_everyMonthLabel = {
  id: "f7ffe4ae-fb9e-4c67-8f91-ee9b10ae7143",
  description: "The label that indicates something repeats every month.",
  defaultMessage: "Every month"
};

export const MSG_everyOtherMonthLabel = {
  id: "631b9d7c-1a22-469f-a267-f151b878e408",
  description: "A label that indicates something repeats every other month (e.g. in English this is another way of saying every two months).",
  defaultMessage: "Every other month"
};

export const MSG_everyNMonthsLabel = {
  id: "31727d71-b081-40b4-aa8c-d1e6c519448f",
  description: "The label that indicates something repeats every N months.",
  defaultMessage: "Every {n} months"
};

export const MSG_addWeekdayOptionTitle = {
  id: "74075962-2daf-4621-9680-ffbc0aab3315",
  description: "This is a button that lets them add another weekday option to a list (e.g. 1st Monday, or last Friday).",
  defaultMessage: "Add Weekday Option"
};

export const MSG_editWeekdayOptionTitle = {
  id: "3637cf40-6151-4a5e-97c4-2fc71f02edaa",
  defaultMessage: "Edit Weekday Option",
};

export const MSG_startingOnDateLabel = {
  id: "77670dd6-6619-45c8-8311-b63a52490b7f",
  description: "",
  defaultMessage: "Starting on {date}"
};

export const MSG_untilDateLabel = {
  id: "6a646e2e-0e49-46af-93bc-4901931c1c32",
  description: "",
  defaultMessage: "until {date}"
};

export const MSG_onTheseWeekdays = {
  id: "d5e15445-203d-487e-a733-00c72847841b",
  description: "A label for a section where they want to select which weekdays they are available (e.g. Mon-Fri).",
  defaultMessage: "On these weekdays"
};

export const MSG_onTheseDaysOfTheMonthLabel = {
  id: "f3ea4bab-4609-4cab-bcf8-d437c6c80f40",
  description: "A label for a section where they want to select which days of the month they are available (e.g. the 1st and 15th of the month).",
  defaultMessage: "On these days of the month"
};

export const MSG_lastDayOfMonthLabel = {
  id: "7568c720-2fc0-4bf7-9c50-258100bbb1cf",
  description: "A selection to choose the last day of the month.",
  defaultMessage: "Last day of the month"
};

export const MSG_exampleDatesLabel = {
  id: "0901f2d7-39ea-49df-ba17-2b45b143ae9b",
  description: "",
  defaultMessage: "These are the first {numDates} dates that match your selection:"
};

export const MSG_advancedRecurrenceExplanation = {
  id: "f74e32e9-5d11-4d3a-8b1b-f972deb6f09f",
  description: "",
  defaultMessage: "You can use advanced recurrence rules to set up a schedule that repeats on a more complex pattern."
};

export const MSG_advancedRecurrenceWarning = {
  id: "954cf6c8-85e0-4e13-8379-b9acc41de412",
  description: "",
  defaultMessage: "Warning: email support@gazelleapp.io if you need help creating advanced recurrence rules. Only use this feature, if you know how to format advanced recurrence rules. "
};

export const MSG_recurrenceRuleErrorTitle = {
  id: "abf955cb-4fbc-40bd-b1f3-b36f357115bb",
  description: "This is the title of an alert that is shown when the user has configured an invalid recurrence rule for a recurring availability time window.",
  defaultMessage: "Recurrence Rule Error"
};

export const MSG_invalidRecurrenceRuleMessage = {
  id: "b591d45d-6d1a-40f7-ab6c-fca49f7078ae",
  description: "",
  defaultMessage: "The recurrence rule does not appear to be valid."
};

export const MSG_invalidRecurrenceRuleWeekdayMustBeSelectedMessage = {
  id: "50329352-03e3-475f-b0b6-7aa47d95ae77",
  description: "",
  defaultMessage: "You must select at least one weekday for a weekly recurrence."
};


export const MSG_noServiceAreasMessage = {
  id: "2fcfa7db-1068-4bd4-9519-d1eba90684fd",
  description: "",
  defaultMessage: "No service areas have been created yet."
};

export const MSG_showingXofYServiceAreasMessage = {
  id: "1c20bc63-aa56-47e6-b7f9-4a2c474b8eb1",
  description: "",
  defaultMessage: "Showing {count} of {total} service areas."
};

export const MSG_circleTitle = {
  id: "9c901ec8-433d-485b-a5c1-5097eed6202f",
  description: "A shape called a 'circle'",
  defaultMessage: "Circle"
};

export const MSG_polygonTitle = {
  id: "a13a4eaa-7d11-42ef-a4a5-8a739c9699e0",
  description: "A shape called a 'polygon'",
  defaultMessage: "Polygon"
};

export const MSG_serviceAreaAlgorithmPolygonal = {
  id: "0831086d-749c-4e83-8ec8-cfd2bf050eaf",
  description: "The name of an algorithm that defines a service area by drawing polygons and circles on a map.",
  defaultMessage: "Defined Boundaries"
};

export const MSG_serviceAreaAlgorithmRadial = {
  id: "ab023f73-3355-4c88-87aa-16097062f089",
  description: "The name of an algorithm that defines a service area by drawing a single circle on a map with a defined radius.",
  defaultMessage: "Travel Time Radius"
};

export const MSG_travelModeWalking = {
  id: "e98fecae-d9fd-491c-a8ec-0db1b42bd25e",
  description: "The name of a travel mode that indicates walking.",
  defaultMessage: "Walking"
};

export const MSG_travelModeBicycling = {
  id: "f996394d-0c26-448c-aafb-9d856b96e069",
  description: "The name of a travel mode that indicates bicycling.",
  defaultMessage: "Bicycling"
};

export const MSG_travelModeTransit = {
  id: "2dbbd8b5-8aac-4244-b7d9-b7126d9b2d94",
  description: "The name of a travel mode that indicates public transit (e.g. busses, trains, etc).",
  defaultMessage: "Transit"
};

export const MSG_travelModeDriving = {
  id: "379fad7c-c06f-4ae4-896e-aade6582ea61",
  description: "The name of a travel mode that indicates driving.",
  defaultMessage: "Driving"
};

export const MSG_serviceAreaNameLabel = {
  id: "12c3d755-9fd0-4410-a657-298f52479c2e",
  description: "The label for a field where they can enter a name for a service area.",
  defaultMessage: "Name"
};

export const MSG_serviceAreaTypeLabel = {
  id: "8901f34f-d562-4900-a737-9202112ee52f",
  description: "The label for a field where they can choose the type of service area they want to create.  For example, they can choose to create a service area by drawing polygons and circles on a map, or by drawing a single circle on a map with a defined radius.",
  defaultMessage: "Type"
};

export const MSG_serviceAreaTypeExplanation = {
  id: "0ddd6caf-d061-454e-a85d-c8951d89070e",
  description: "Explanation for what types of service area boundaries are available",
  defaultMessage: "This is how you set the boundaries of your service area.  Using the 'Defined Boundaries' option, you can draw polygons and circles on a map; or use a 'Travel time radius' option that allows you to designate a simple travel radius around an address."
};

export const MSG_serviceAreaBoundariesAndExclusionsLabel = {
  id: "5245f87d-bd08-46e3-b5be-d144c2f591de",
  description: "",
  defaultMessage: "Service area boundaries and exclusions"
};

export const MSG_serviceAreaBoundariesAndExclusionsExplanation = {
  id: "ef3578c3-2326-40fc-a570-9cf431927e3c",
  description: "A description for a subset of 'Service area settings' that can be edited for a 'defined boundary' type service area",
  defaultMessage: "Gazelle's 'Defined boundary' service areas have a set boundary (or boundaries) that define which clients live inside (or outside) of this service area. You can define a service area boundary by drawing polygons or circles on a map. These boundaries can be disconnected or contiguous. You can also create negative-service areas inside specific boundaries that prevent clients who live in certain locations from scheduling an appointment."
};

export const MSG_boundariesTitle = {
  id: "8f142efe-6664-477f-a298-c3a2711ff904",
  description: "The title that is displayed above a section where they can edit the geographic boundaries of their service area.",
  defaultMessage: "Boundaries"
};


export const MSG_serviceAreaBoundariesLabel = {
  id: "1f0c937e-896b-4b4b-9279-9eadba458fc0",
  description: "",
  defaultMessage: "Service area boundaries"
};

export const MSG_serviceAreaBoundariesExplanation = {
  id: "ea4e255e-a40f-4d9f-a1a6-224cf672d1c3",
  description: "A description for a subset of 'Service area settings' that can be edited for a 'Travel Time Radius' type service area.",
  defaultMessage: "Gazelle's 'Travel time radius' service areas create a dynamic boundary around a center location. All clients who live inside this service area must have an address within the travel radius of the center location. Individual appointment slots are still offered relative to the travel time between each appointment on your calendar, however, clients who are not within this travel time radius will be considered 'outside' this service area for scheduling purposes"
};

export const MSG_serviceAreaSettingsLabel = {
  id: "4e6a3949-afcd-4a25-a98f-757fe874d46c",
  description: "",
  defaultMessage: "Service area settings and travel time preferences"
};

export const MSG_serviceAreaSettingsExplanation = {
  id: "6d1fb005-dff6-4326-a567-055a0d51e625",
  description: "A description for a subset of 'Service area settings' that can be edited in this section",
  defaultMessage: "Each service area has a specified travel mode, travel time restrictions, appointment buffer time rules, and other settings you can adjust to change which appointment slots are offered to clients who live inside this service area. You can edit these settings here"
};

export const MSG_serviceAreaEmptyNotice = {
  id: "cc0cb091-6015-4d13-920c-8fc84cebdce9",
  description: "",
  defaultMessage: "This service area has no boundaries.  You can draw circles and polygons on the map using the {circleIcon} and {polygonIcon} tools to define the boundaries of your service area."
};

export const MSG_serviceAreasAdditionalBoundariesNotice = {
  id: "d4db43af-663d-4a21-a90f-ce5ac70f3435",
  defaultMessage: "You can draw additional circles and polygons on the map using the {circleIcon} and {polygonIcon} tools to define the boundaries of your service area."
};


export const MSG_otherSettingsLabel = {
  id: "6ad9d877-5913-41f7-8fe8-c56b560740dd",
  description: "A section header for general settings for a service area",
  defaultMessage: "Other settings"
};

export const MSG_travelTimeSettingsLabel = {
  id: "a7e1fd3d-7bdb-414d-b727-e06f6d5444f4",
  description: "A section header for where they can configure settings related to travel time for a service area",
  defaultMessage: "Travel time settings"
};


export const MSG_serviceAreaTravelModeLabel = {
  id: "cb1d35ce-0bf5-4900-97cf-4a210884d581",
  description: "The label for a field where they can choose the travel mode they want to use to define their service area.  For example, they can choose to define their service area by walking, bicycling, driving, or using public transit (e.g. busses, trains, etc).",
  defaultMessage: "Travel mode"
};

export const MSG_serviceAreaBufferTimeLabel = {
  id: "92068bdc-1e6d-4970-b554-4c8aa5ba7304",
  description: "The label for a field where they can enter a buffer time for their service area.  This is the amount of time that will be added to total duration for every appointment.  This is used to pad appointments to add some extra time.",
  defaultMessage: "Buffer time"
};

export const MSG_serviceAreaBufferTimeInMinutesLabel = {
  id: "31aad748-f4bf-4fb9-a55f-087d54a187df",
  description: "The label for a field where they can enter a buffer time for their service area.  This is the amount of time that will be added to total duration for every appointment.  This is used to pad appointments to add some extra time.",
  defaultMessage: "Buffer time (in minutes)"
};

export const MSG_serviceAreaBufferTimeExplanation = {
  id: "c59d94c6-c993-45d2-9cc5-f531a7c1decf",
  description: "Explanation of what the 'Buffer time' scheduling setting does",
  defaultMessage: "This is the amount of extra time you want added to the appointment when events are scheduled in this service area. For example: extra time for talking to the customer, creating invoices, and taking notes after an appointment. Do NOT include travel time between appointments in this setting since travel time is added separately to each appointment. Gazelle does not add buffer time to manually scheduled events which are not part of a specific service area."
};


export const MSG_serviceAreaFallbackTravelTimeLabel = {
  id: "36453ada-0ef3-45e7-a04c-ecf3280af27b",
  description: "",
  defaultMessage: "Fallback travel time"
};

export const MSG_serviceAreaFallbackTravelTimeInMinutesLabel = {
  id: "1ca588e2-2b0b-4fdb-8ac7-8d7795f9a843",
  description: "",
  defaultMessage: "Fallback travel time (in minutes)"
};

export const MSG_serviceAreaFallbackTravelTimeExplanation = {
  id: "f00b66b9-22a9-4137-b491-f53bda110152",
  description: "A description for what the 'Fallback travel time' scheduling setting does",
  defaultMessage: "If the travel time between two locations cannot be found for some reason, this fallback travel time will be used as a last resort."
};

export const MSG_serviceAreaGoodTravelTimeLabel = {
  id: "663e883f-9a07-4bf0-94f6-800567ef0838",
  description: "",
  defaultMessage: "Good travel time"
};

export const MSG_serviceAreaGoodTravelTimeInMinutesLabel = {
  id: "6274e624-71f8-4f9b-9ce2-96186dbd9f58",
  description: "",
  defaultMessage: "Good travel time (in minutes)"
};

export const MSG_serviceAreaLessThanLabel = {
  id: "32d00596-8b1c-495d-9bd2-91484367d5b9",
  description: "A label that goes before a number input form.  Will be read like: 'Less than [5 minutes]'.",
  defaultMessage: "Less than"
};

export const MSG_serviceAreaGoodTravelTimeExplanation = {
  id: "7843cc89-4e08-4cb2-8293-18764ae4690a",
  description: "A description for what the 'Good travel time' scheduling setting does",
  defaultMessage: "When Gazelle finds multiple possible routes, favor routes with travel times less than this 'Good travel time' scheduling preference."
};

export const MSG_serviceAreaOpenDayPenaltyLabel = {
  id: "43b5c032-66bd-46c5-a005-1890e0590353",
  description: "",
  defaultMessage: "Open day penalty"
};

export const MSG_serviceAreaOpenDayPenaltyTitle = {
  id: "b9e8fc49-c008-4176-b80d-b469fa900a55",
  description: "",
  defaultMessage: "Open Day Penalty"
};


export const MSG_serviceAreaOpenDayPenaltyExplanation = {
  id: "5d209c79-c18a-495b-88ff-fb81e54d53a4",
  description: "Description of what the 'Open Day Penalty' scheduling setting does",
  defaultMessage: "This scheduling preference allows you to control when Gazelle tries to fill open slots on a partially filled day vs. offering the client who is self-scheduling an OPEN DAY on your calendar. Some users want to fill OPEN DAYS even if it means this will be the only appointment on that day. Other users want Gazelle to attempt to fill partially filled days before offering OPEN DAYS even if it results in a longer drive time between appointments."
};

export const MSG_serviceAreaOpenDayNoPenalty = {
  id: "ad10949a-bfff-4e60-8679-dac5ecca1986",
  description: "",
  defaultMessage: "No penalty"
};

export const MSG_serviceAreaOpenDayNoPenaltyExplanation = {
  id: "d9b9831b-02f7-4fe5-a73a-a1958bcdc5b1",
  description: "A description of the 'No penalty' open day scheduling setting preference",
  defaultMessage: "Two clients 5 minutes apart may be booked on separate days."
};

export const MSG_serviceAreaOpenDaySlightPenalty = {
  id: "d02346f0-4772-4b5f-9398-ff64a62cf5c7",
  description: "",
  defaultMessage: "Slight penalty"
};

export const MSG_serviceAreaOpenDaySlightPenaltyExplanation = {
  id: "19a65715-0270-41be-b988-5b8247439219",
  description: "A description of the 'Slight penalty' open day scheduling setting preference",
  defaultMessage: "This will offer open days unless a very short drive time is available on another day."
};

export const MSG_serviceAreaOpenDayModeratePenalty = {
  id: "ca17531e-0255-4d3b-a52f-595557f5d0ab",
  description: "",
  defaultMessage: "Moderate penalty"
};

export const MSG_serviceAreaOpenDayModeratePenaltyExplanation = {
  id: "917fdd1a-b227-4849-972c-4cb01d76539c",
  description: "A description of the 'Moderate penalty' open day scheduling setting preference",
  defaultMessage: "This will aim for short drive times between appointments unless you have open days in the immediate future."
};

export const MSG_serviceAreaOpenDayHeavyPenalty = {
  id: "f819e054-3a28-42e9-b56e-219db3b06d26",
  description: "",
  defaultMessage: "Heavy penalty"
};

export const MSG_serviceAreaOpenDayHeavyPenaltyExplanation = {
  id: "1845ad2e-5482-4ed8-9590-e6a9700decd5",
  description: "A description of the 'Heavy penalty' open day scheduling setting preference",
  defaultMessage: "This will allow longer drive time between appointments but will allow scheduling an open day to prevent driving across your entire service area."
};

export const MSG_serviceAreaOpenDaySeverePenalty = {
  id: "68c415d8-76d6-4e71-b5af-70aecd8e1eb7",
  description: "",
  defaultMessage: "Severe penalty"
};

export const MSG_serviceAreaOpenDaySeverePenaltyExplanation = {
  id: "50d49d3e-3e97-4e5f-9a17-c945d216a64d",
  description: "A description of the 'Severe penalty' open day scheduling setting preference",
  defaultMessage: "This setting causes Gazelle to favor offering a client a slot in the coming weeks that possibly requires you to drive across your entire service area to avoid scheduling on open day tomorrow."
};

export const MSG_serviceAreaExtendedServiceAreaLabel = {
  id: "455a8379-c21e-4b7c-8657-918aa1c3cddd",
  description: "",
  defaultMessage: "Extended service area"
};

export const MSG_serviceAreaExtendedServiceAreaExplanation = {
  id: "13d0628e-668e-4b27-8bad-4285f18536ff",
  description: "A description of what the 'Extended service area' scheduling preference does",
  defaultMessage: "When this is set to 0 minutes, routes to/from clients outside this service area will be excluded as a possibility. If your extended service area has a positive value then Gazelle will only allow clients outside this service area to schedule an appointment if it is within a defined travel time radius of an appointment already on your calendar."
};

export const MSG_serviceAreaIncludeTrafficLabel = {
  id: "1c0845c7-d108-4e76-b36b-c4d67e5a225e",
  description: "",
  defaultMessage: "Use estimated traffic conditions when calculating travel times"
};

export const MSG_serviceAreaMaxTravelTimeLabel = {
  id: "787fc068-92a0-4da4-8c2e-936c16e53455",
  description: "A label for changing the maximum amount of time they are willing to travel between appointments.",
  defaultMessage: "Max travel time"
};

export const MSG_serviceAreaMaxTravelTimeTitle = {
  id: "71b14fd1-d2b4-456d-8796-104b2b373966",
  description: "The title for a modal that allows the user to change the maximum amount of time they are willing to travel between appointments.",
  defaultMessage: "Max Travel Time"
};

export const MSG_serviceAreaMaxTravelTimeExplanation = {
  id: "736a069c-70e6-4fe0-aed7-bea3f9974f27",
  description: "A description of the 'Maximum travel time' scheduling setting",
  defaultMessage: "This is the client self-scheduling maximum travel time between appointments you are willing to allow in this service area. Additionally, you can set longer maximum travel time rules for openings that are ‘Tomorrow’ (or in the near future) to help fill last minute holes in your schedule at the expense of having slightly longer travel times. This setting is a hard-limit for clients who are self-scheduling and a soft-limit with a warning when you are viewing routes in the in-app scheduler."
};

export const MSG_serviceAreaToday = {
  id: "0aacb56e-0d97-4e97-982c-cb35e90ffe8f",
  description: "This is part of an interface that lets them define a range of dates relative to today.  This one indicates a value that only applies to 'today'.",
  defaultMessage: "Today"
};

export const MSG_serviceAreaTodayToTomorrow = {
  id: "0ca38c1e-0608-4f1f-8e9e-818b7f5ece51",
  description: "This is part of an interface that lets them define a range of dates relative to today.  This one indicates a value that applies to 'today' and 'tomorrow'.",
  defaultMessage: "Today ... Tomorrow"
};

export const MSG_serviceAreaInputToFutureDay = {
  id: "bd739c1c-e5d6-4f76-9038-876afd7352f4",
  description: "This is part of an interface that lets them define a range of dates relative to today.  This one indicates a value that applies to a particular date and some number of days into the future beyond that.",
  defaultMessage: "{numDays, plural, one {{dateInput} ... +1 day} other {{dateInput} ... +# days}}"
};

export const MSG_serviceAreaInputToFuture = {
  id: "9c2e90a5-2d47-4109-ae83-feac178a8e6b",
  description: "This is part of an interface that lets them define a range of dates relative to today.  This one indicates a value that applies to a particular date and forever beyond that date.",
  defaultMessage: "{dateInput} ... Forever"
};

export const MSG_serviceAreaMaxTravelTimeForeverExplanation = {
  id: "c414b108-77f2-4145-977f-92dbf7dc3d2e",
  description: "A description of the 'Forever' option for the 'Maximum travel time' scheduling setting",
  defaultMessage: "Your current long-term limit is {numDays} days, so Gazelle will never schedule an appointment more than {numDays} days in the future."
};


export const MSG_serviceAreaDaysTitle = {
  id: "3fbd7675-9ef4-4dcf-9411-0116a3edfaa1",
  description: "A label that indicates that the user is specifying a number of days.",
  defaultMessage: "Days"
};

export const MSG_serviceAreaDaysLabel = {
  id: "50837285-43a0-4173-af99-ed3e30ad3d5f",
  description: "A label that indicates that the user is specifying a number of days.",
  defaultMessage: "days"
};

export const MSG_serviceAreaNumDaysLabel = {
  id: "c89aae8c-66e5-4bed-b1b5-60315f81b198",
  description: "",
  defaultMessage: "{numDays, plural, one {# day} other {# days}}"
};


export const MSG_serviceAreaAddRowTitle = {
  id: "c33d4ef2-4855-457e-afcc-3114a5400561",
  description: "The title of a button that allows the user to add a row to a table.",
  defaultMessage: "Add Row"
};

export const MSG_serviceAreaCenterLocationLabel = {
    id: "9e35a650-4e07-474b-adf8-8ee05261725f",
    description: "",
    defaultMessage: "Center location"
};

export const MSG_serviceAreaCenterLocationExplanation = {
  id: "ede163a4-f11c-4522-b5d7-807776cf001a",
  description: "A description for 'Center Location' of a service area with a boundary that uses a simple 'Travel time radius' around an address",
  defaultMessage: "This is the center location you want to use to define the boundaries of a 'Travel time radius' service area."
};

export const MSG_serviceAreaRadialRadiusLabel = {
  id: "f1ba9f14-34aa-44cc-b523-47124b60f8c5",
  description: "A label for defining the radius of a circle",
  defaultMessage: "Radius"
};

export const MSG_serviceAreaRadialRadiusInMinutesLabel = {
  id: "58aa4795-6715-4517-b250-cd695a94876b",
  description: "A label for defining the radius of a service area, measured in minutes of travel time from a central point.",
  defaultMessage: "Radius (in minutes)"
};

export const MSG_serviceAreaRadialRadiusExplanation = {
  id: "3e1d39ae-d9aa-4a0d-bf48-84a11ec821b8",
  description: "A description of what how the 'Radius' is applied in a Travel time radius' service area.",
  defaultMessage: "This is the travel time radius used for establishing the outer bounds of a 'travel time radius' service area."
};

export const MSG_serviceAreaRoutingPreferenceLabel = {
  id: "54254c6c-b0fb-4c7a-be0a-fcc61c73cfc3",
  description: "",
  defaultMessage: "Routing preference"
};

export const MSG_serviceAreaRoutingPreferenceTitle = {
  id: "fd54a50c-14c9-4100-8b07-ad0e07aa0a25",
  description: "",
  defaultMessage: "Routing Preference"
};


export const MSG_serviceAreaRoutingPreferenceExplanation = {
  id: "b064c0b8-b42d-4784-952a-217066ff05a3",
  description: "",
  defaultMessage: "This setting lets you control whether Gazelle will prefer to book appointments in the same proximity, or be a bit more flexible to allow booking appointments along existing routes while still optimizing your drive times."
};

export const MSG_serviceAreaRoutingPreferenceProximityRoutingLabel = {
  id: "e2c92280-48fa-427e-9c21-637fbcd138fd",
  description: "",
  defaultMessage: "Proximity routing"
};

export const MSG_serviceAreaRoutingPreferenceProximityRoutingExplanation = {
  id: "a8233441-4652-433b-997a-0e884b1fe547",
  description: "",
  defaultMessage: "This setting will favor booking appointments in a close proximity to each other, while minimizing potential openings along an existing route."
};


export const MSG_serviceAreaRoutingPreferenceAlongExistingRoutesLabel = {
  id: "df2ee391-726e-42df-96c3-b32b5d090d66",
  description: "",
  defaultMessage: "Along existing routes (recommended)"
};

export const MSG_serviceAreaRoutingPreferenceAlongExistingRoutesExplanation = {
  id: "ca6493a9-4402-4537-bc87-8dd8206708c8",
  description: "",
  defaultMessage: "This setting will book appointments in close proximity to each other and along an existing route which includes traveling to and from your start or end of day location."
};


export const MSG_deleteAvailabilityConfirmationMessage = {
  id: "927c028e-8f26-41b2-9c51-364f12b342f9",
  description: "",
  defaultMessage: "Are you sure you want to permanently delete this availability entry?"
};

export const MSG_deleteServiceAreaConfirmationMessage = {
  id: "19299646-809f-4511-850c-19a8b3f438ae",
  description: "",
  defaultMessage: "Are you sure you want to permanently delete this service area and any availabilities that are using it?"
};

export const MSG_radialServiceAreaSummary = {
  id: "32903f1e-e859-447f-bee2-5bfea7321e0b",
  description: "The duration by a particular mode of transit to a particular address.  This will read like '30 minutes around 123 Main St.'.  This is used to describe the size of their service area.",
  defaultMessage: "{duration} around {address}"
};

export const MSG_longTermLimitLabel = {
  id: "3f7920fc-4c1f-4972-b009-6f2fbaa6aab2",
  description: "",
  defaultMessage: "Long-term scheduling limit"
};

export const MSG_longTermLimitTitle = {
  id: "4d658d67-147a-4f8c-91be-315d29dcfaed",
  description: "",
  defaultMessage: "Long-term Scheduling Limit"
};

export const MSG_longTermLimitForUserTitle = {
  id: "8edf50e9-ab10-41c7-a898-6703c31b29fc",
  description: "",
  defaultMessage: "Long-term Scheduling Limit for {name}"
};

export const MSG_longTermLimitExplanation = {
  id: "958cbb87-2bd9-4e57-89d4-e61f5e11d837",
  description: "A description of the 'Long Term Limit' scheduling preference",
  defaultMessage: "When your clients use the self scheduler, this is the maximum number of days into the future that Gazelle will schedule appointments for {name}. This applies to all service areas and availabilities for this user.\n\nWhen someone from your company performs a search, we will warn you if the search results fall outside this limit but still allow you to book the appointment."
};

export const MSG_shortTermLimitLabel = {
  id: "5775a00a-ba28-4b09-a3fe-96a7e5fdacd9",
  description: "",
  defaultMessage: "Short-term scheduling limit"
};

export const MSG_shortTermLimitInHoursLabel = {
  id: "48ee3f4d-a23b-4837-8557-730d48320a01",
  description: "",
  defaultMessage: "Short-term scheduling limit (in hours)"
};

export const MSG_shortTermLimitTitle = {
  id: "4cb09488-f12b-4d90-957f-664e815ac974",
  description: "",
  defaultMessage: "Short-term Scheduling Limit"
};

export const MSG_shortTermLimitForUserTitle = {
  id: "8f337610-22c3-4ce3-b15f-3621eeb8d99c",
  description: "",
  defaultMessage: "Short-term Scheduling Limit for {name}"
};

export const MSG_shortTermLimitHard = {
  id: "88ce7088-b3d6-4345-bb1c-a959a5a54c23",
  description: "This is the label for an option when clients try to schedule short-term appointments.  This option prevents clients from scheduling short-term appointments.",
  defaultMessage: "Hard Block"
};

export const MSG_shortTermLimitSoft = {
  id: "f81eec44-3235-4dc0-9ca2-35541f073f36",
  description: "This is the label for an option when clients try to schedule short-term appointments.  This option requires that the client contact the technician to schedule.",
  defaultMessage: "Require Contact"
};

export const MSG_shortTermLimitExplanation = {
  id: "04d2adf9-4147-49a2-af9e-4303d2f85a7c",
  description: "",
  defaultMessage: "This setting provides {name} a way to prevent clients from booking appointments without enough advance notice. You have the option of hiding any available appointment slots within this short-term limit, or tentatively showing them to clients who are self-scheduling, but instructing them to contact you directly if they select this open slot."
};

export const MSG_shortTermLimitTypeHard = {
  id: "d914bb07-4857-42e5-9f45-5d4d1295d096",
  description: "",
  defaultMessage: "Do not show any options within the short-term limit when clients are trying to self-schedule."
};

export const MSG_shortTermLimitTypeSoft = {
  id: "356fd162-b6d1-44b0-b9c0-d634974a7571",
  description: "",
  defaultMessage: "Show short-term options to clients, but prevent them from self-scheduling and require that they contact you to schedule."
};

export const MSG_shortTermLimitMessageDisabled = {
  id: "30b60e35-d0d3-4085-89ac-b957b5797331",
  description: "",
  defaultMessage: "This message won't be displayed because your short-term scheduling limit is set to 'Hard Block'."
};

export const MSG_shortTermLimitMessageLabel = {
  id: "c42ac745-1681-4f87-aea3-da3ecfdf293e",
  description: "This is a label for the section where they can change the message that is displayed to their clients if they try to schedule too close to today.",
  defaultMessage: "Short-term scheduling limit message"
};

export const MSG_shortTermLimitMessageTitle = {
  id: "c2c32f02-193f-4e85-af0e-e6ed1cd1fa40",
  description: "This is the title for the section where they can change the message that is displayed to their clients if they try to schedule too close to today.",
  defaultMessage: "Short-term Scheduling Limit Message"
};

export const MSG_shortTermLimitMessageExplanation = {
  id: "dbac9c2c-d47e-462c-931c-10c1607d7de2",
  description: "A description for the user defined Short Term Limit message which is a subset of 'Scheduling > Preferences'",
  defaultMessage: "This is the message that will be displayed to clients who are self-scheduling if they select one of the time slots inside your short term window. This will only apply if your short term limit is set to 'Require Contact'. Gazelle will never display routes or this message if your short term limit is set to 'Hard Block'."
};

export const MSG_serviceAreaTravelModeExplanation = {
  id: "ade76ada-8e9f-4e6a-8a6e-27babfa5c44d",
  description: "",
  defaultMessage: "Choose the travel mode you would like Gazelle to use when calculating travel times between appointments that are scheduled in this service area. 'Transit' will include a hybrid of public transit available inside the service area (bus, train) and walking to and from the event location from the transit station."
};

export const MSG_serviceAreaEstimatedTrafficExplanation = {
  id: "ba67867b-6903-4e7c-beb3-39ee8f2e0e75",
  description: "",
  defaultMessage: "Add extra time to account for estimated traffic along your routes when Gazelle calculates drive times between events."
};

export const MSG_serviceAreaIncludeExcludeShapeExplanation = {
  id: "d94f13e7-1ef1-41bf-b169-0c4de1ace6a1",
  description: "",
  defaultMessage: "If you 'Include' an area for scheduling, every address inside that area will be allowed unless you add or overlap an 'excluded' area. If you 'exclude' any area nobody in this area will be considered to be in the service area."
};

export const MSG_schedulerSendFeedbackTitle = {
  id: "231f0380-a17c-409c-ab64-6815bcd0a6ef",
  description: "The title for a window that allows the user to send feedback to the Gazelle team about how good the search results are.",
  defaultMessage: "Send Feedback"
};

export const MSG_schedulerSendRoutesFeedbackExplanation = {
  id: "a32b4b90-895a-4fb2-9586-b583a6760461",
  description: "",
  defaultMessage: "Provide feedback about this set of routes! Tell us which dates, routes, and times you think Gazelle should have treated differently and our team will target this scenario for improvement."
};

export const MSG_schedulerSendReservationFeedbackExplanation = {
  id: "b41fa660-2318-4867-b25f-7ce80fa02827",
  description: "",
  defaultMessage: "Provide feedback about this reservation! Tell us which dates, routes, and times you think Gazelle should have treated differently and our team will target this scenario for improvement."
};

export const MSG_schedulerFeedbackRoutesPackageName = {
  id: "3ae01476-6d6f-434f-b79d-a493b75d1e5f",
  description: "This is the file name for a file that contains the routes that the user is sending feedback about.",
  defaultMessage: "Gazelle Routes Package"
};

export const MSG_schedulerFeedbackRoutesPackageExplanation = {
  id: "f538e817-b420-402a-85e4-511c04a53763",
  description: "",
  defaultMessage: "Sending this feedback will also attach the full set of routes that were generated for this search."
};

export const MSG_schedulerFeedbackPlaceholder = {
  id: "e618555b-9fbe-4e3b-b702-e3dc2097af09",
  description: "",
  defaultMessage: "Ask us a question about the search results or tell us what you think..."
};

export const MSG_defaultTravelModeLabel = {
  id: "a62aa51c-01e9-4583-8882-648c6006ab23",
  description: "This is the label for an input field where the user can change the default travel mode for their account.  Valid travel modes are: driving, walking, bicycling, and transit.",
  defaultMessage: "Default travel mode"
};

export const MSG_defaultTravelModeTitle = {
  id: "a9c47cf9-a499-4ab1-9de8-a8b7dba768ec",
  description: "This is the title for a dialog box where the user can change the default travel mode for their account.  Valid travel modes are: driving, walking, bicycling, and transit.",
  defaultMessage: "Default Travel Mode"
};

export const MSG_defaultTravelModeForUserTitle = {
  id: "f65262d5-12d9-433d-bbba-c917bd012573",
  description: "This is the title for a dialog box where the user can change the default travel mode for someone else's account.  Valid travel modes are: driving, walking, bicycling, and transit.",
  defaultMessage: "Default Travel Mode for {name}"
};

export const MSG_defaultTravelModeExplanation = {
  id: "cc2c78a8-c617-4b39-951d-6fbc377f9098",
  description: "A description of the 'Default travel mode' scheduling preference",
  defaultMessage: "This is the default travel mode to assign when manually creating a new appointment or personal event.  You will be able to change the travel mode for each appointment individually, this is simply a default."
};

export const MSG_defaultTravelModeDrivingLabel = {
  id: "60d5791c-4d11-42d5-ba83-5aa5a1c9cff7",
  description: "This is the label for an option when the user is changing the default travel mode for an account to be 'driving'.  Valid travel modes are: driving, walking, bicycling, and transit.",
  defaultMessage: "Driving"
};

export const MSG_defaultTravelModeWalkingLabel = {
  id: "faf5e46d-e6f1-4302-9e03-ef991724e59f",
  description: "This is the label for an option when the user is changing the default travel mode for an account to be 'walking'.  Valid travel modes are: driving, walking, bicycling, and transit.",
  defaultMessage: "Walking"
};

export const MSG_defaultTravelModeBicyclingLabel = {
  id: "cc118fb1-f911-4e49-ad98-862d49d1bcd4",
  description: "This is the label for an option when the user is changing the default travel mode for an account to be 'bicycling'.  Valid travel modes are: driving, walking, bicycling, and transit.",
  defaultMessage: "Bicycling"
};

export const MSG_defaultTravelModeTransitLabel = {
  id: "030f547a-4321-4d7d-9fb0-f25c133d810b",
  description: "This is the label for an option when the user is changing the default travel mode for an account to be 'transit' (meaning public transit such as bus, metro, etc).  Valid travel modes are: driving, walking, bicycling, and transit.",
  defaultMessage: "Transit"
};

export const MSG_newFeatureSchedulingUpdatesTitle = {
  id: "678d26bc-c9fc-4396-8405-76ade9dcac60",
  description: "",
  defaultMessage: "New Scheduling Updates"
};

export const MSG_newFeatureSchedulingUpdatesBlurb1 = {
  id: "77e7dcd1-9c68-4e3c-b181-c116ade957b8",
  description: "",
  defaultMessage: "Gazelle’s new scheduling configurations are here and we are excited to share these updates with you."
};

export const MSG_newFeatureSchedulingUpdatesWhatsNew = {
  id: "0bf272ff-d349-4cf5-b502-9030f774674b",
  description: "",
  defaultMessage: "What’s New?"
};

export const MSG_newFeatureSchedulingUpdatesBlurb2 = {
  id: "12b38002-ecf9-40cc-a49e-59cc5ea75278",
  description: "",
  defaultMessage: "During the beta phase of this rollout you can expect any bugs you discover to be fixed quickly and if needed you can toggle back to the old scheduling parameters."
};

export const MSG_newFeatureSchedulingItem1 = {
  id: "2334371b-e5b1-42c5-8444-54c16e472acb",
  defaultMessage: "Draw service areas on a map in any shape."
};

export const MSG_newFeatureSchedulingItem2 = {
  id: "f49201c3-da8b-4be1-8ec5-b3f479108891",
  defaultMessage: "Select travel modes (driving, walking, bicycling, transit)"
};

export const MSG_newFeatureSchedulingItem3 = {
  id: "a8c6368d-f0c2-40f1-8455-763ed66c1b88",
  defaultMessage: "Floating downtime (e.g. flexible lunch break)"
};

export const MSG_newFeatureSchedulingItem4 = {
  id: "e1428b5a-2cc8-43ee-820e-6fd7d004d1b6",
  defaultMessage: "Better appointment start time options."
};

export const MSG_newFeatureSchedulingItem5 = {
  id: "b4111ca3-f174-484c-8637-e7b382d641e2",
  defaultMessage: "Improved repeating availability options."
};

export const MSG_newFeatureSchedulingItem6 = {
  id: "1c256ae1-f9a8-4882-b5eb-aa801b63c436",
  defaultMessage: "Better layering of service areas and availability rules."
};

export const MSG_newFeatureSchedulingItem7 = {
  id: "89837a31-3738-4a33-85f1-2dde6df9cd8b",
  defaultMessage: "Easier ways to adjust your availability from the calendar view and create exceptions to rules on specific days."
};

export const MSG_newFeatureSchedulingItem8 = {
  id: "f9ac2790-f7b2-426e-9da3-9071a3b7e642",
  defaultMessage: "Better admin controls for multi-tech companies."
};

export const MSG_newFeatureSchedulingItem9 = {
  id: "ea16d8f7-2987-4d08-8dee-e8d1187fe9ac",
  defaultMessage: "Huge scheduling algorithm improvements for better handling of challenging scheduling requirements we have encountered over the years. Especially for large teams."
};

export const MSG_newFeatureSchedulingItem10 = {
  id: "0d31d8d3-2bcb-4105-97b6-323a26a0c763",
  defaultMessage: "Much, much, more"
};

export const MSG_newFeatureSchedulingEnabled = {
  id: "bb4fff96-65d6-4dfe-b5b5-58db240f0675",
  description: "",
  defaultMessage: "The new scheduling updates are enabled for your company."
};

export const MSG_newFeatureSchedulingDisabled = {
  id: "895fbfa1-f7d4-4c8a-b97d-77cefd3e569a",
  description: "",
  defaultMessage: "The new scheduling updates have not yet been enabled for your company."
};

export const MSG_newFeatureSchedulingRevertTitle = {
  id: "895cd1ad-ad90-46a3-9b74-de3d01b825cc",
  description: "",
  defaultMessage: "Revert to Legacy Scheduling"
};

export const MSG_newFeatureSchedulingRevertExplanation = {
  id: "7a5fa072-7c4a-4702-b82d-c291e8b52531",
  description: "",
  defaultMessage: "Reverting to the legacy scheduling will restore your previous scheduling settings."
};

export const MSG_newFeatureSchedulingEnableTitle = {
  id: "c25beaae-1414-4713-a7a5-49584f7c8ab9",
  description: "",
  defaultMessage: "Enable New Scheduling"
};

export const MSG_newFeaturesSchedulingSettingsAlreadyExist = {
  id: "cbe0a8d8-2dc3-4571-9f3d-dde6d506d01d",
  description: "",
  defaultMessage: "It looks like some existing settings already exist for the new scheduling.  What would you like to do?"
};

export const MSG_newFeatureSchedulingEnableAndMigrateLabel1 = {
  id: "ee05592f-40cc-42b8-973e-9709ca7bc78f",
  description: "",
  defaultMessage: "Migrate my existing scheduling settings"
};

export const MSG_newFeatureSchedulingEnableAndMigrateLabel2 = {
  id: "c5592143-c2fd-4e5c-a478-8369c4f90380",
  description: "",
  defaultMessage: "Migrate my legacy scheduling settings"
};

export const MSG_newFeatureSchedulingEnableAndMigrateExplanation = {
  id: "3e4cd72a-8946-4413-85f9-e4d1b11f17c3",
  description: "",
  defaultMessage: "This will replace your new scheduling settings with your current legacy scheduling settings."
};

export const MSG_newFeatureSchedulingEnableAndKeepLabel = {
  id: "5448cdc7-b7c4-478e-ba9e-d7dc17252d06",
  description: "",
  defaultMessage: "Keep existing settings"
};

export const MSG_newFeatureSchedulingEnableAndKeepExplanation = {
  id: "9ed088fd-d4e9-4085-8d92-1aad2c6edeae",
  description: "",
  defaultMessage: "This will keep your new scheduling settings as they are."
};

export const MSG_newFeatureSchedulingScratchLabel = {
  id: "7650c1eb-13fd-4f2f-853a-2524326e19f3",
  description: "",
  defaultMessage: "Start from scratch"
};

export const MSG_newFeatureSchedulingScratchExplanation = {
  id: "57ea0e50-dfac-4211-b672-4a0b128a011c",
  description: "",
  defaultMessage: "This will reset your new scheduling settings allowing you to start from scratch."
};

export const MSG_newFeatureSchedulingDraw = {
  id: "23d77ccd-2eb4-4e1e-968e-d5ea1cdf7323",
  description: "",
  defaultMessage: "Draw service areas in any shape!"
};

export const MSG_newFeatureSchedulingRoutes = {
  id: "02a3a9ca-6a24-4a01-92dc-e7bb9674c1b6",
  description: "",
  defaultMessage: "Get more info and better results when scheduling routes!"
};

export const MSG_availabilityServiceAreaExplanation = {
  id: "9d4a138c-01b0-4af6-82da-81e55e47cdcc",
  description: "Configuring which service area will be used by this availability",
  defaultMessage: "This is the service area this availability applies to.  You can think of a Service Area as a geography and an Availability as the rules for how you want to be scheduled within a specific date range while inside the geography of this Service Area."
};

export const MSG_fallbackTravelTimeVsMaxTravelTimeCaution = {
  id: "bcd90677-fd56-49ec-8fa8-9dfaae5daf2f",
  defaultMessage: "Caution: Setting a Fallback Travel Time shorter than your Max Travel Time may allow clients with invalid or unrouteable addresses to self-schedule appointments."
};

export const MSG_fallbackTravelTimeVsExtendedServiceAreaCaution = {
  id: "ea7894c0-383f-4702-ba69-2e01d3485813",
  defaultMessage: "Caution: We recommend your Extended Service Area time be at least 1 minute less than your Fallback Travel Time. The current setting will likely cause undesired scheduling issues."
};

export const MSG_serviceAreaIdDisplay = {
  id: "55d42762-9be2-42c6-966a-46d6cc449562",
  description: "",
  defaultMessage: "Service Area ID: {id}"
};

export const MSG_availabilityIdDisplay = {
  id: "8a9a9df2-e536-4c8a-9cef-81dba410ba1a",
  description: "",
  defaultMessage: "Availability ID: {id}"
};

export const MSG_serviceAreaIsSelfSchedulableLabel = {
  id: "096b7ea3-e191-4be0-bc60-887a2d1bc4d9",
  description: "",
  defaultMessage: "Show openings in this service area to clients who are using the self-scheduler."
};

export const MSG_serviceAreaIsSelfSchedulableExplanation = {
  id: "b2ed3333-f515-4add-9a55-8e90c48ccfdb",
  description: "",
  defaultMessage: "Disabling this will prevent openings in this service area from being shown when your clients are placing reservations via the Self-Scheduler."
};

export const MSG_tooManyPreferredTimeSlotWarningLabel = {
  id: "c5c19910-6a1e-47cb-ba1c-2741b1867abb",
  description: "",
  defaultMessage: "Giving clients too many options to choose from isn't recommended. Configuring 3-5 preferred time slots on an open day will return routes faster and improve Gazelle's ability to find the best route."
};

export const MSG_schedulingUpsell1 = {
  id: "59a8bced-f086-4f13-be13-df1f8fa70122",
  description: "",
  defaultMessage: "You shouldn’t have to lose business because you didn’t pick up the phone after business hours, return an email over the weekend, or interrupt an appointment with another customer."
};

export const MSG_schedulingUpsell2 = {
  id: "1a701f80-71ff-4c8c-91df-b6ead3a2987f",
  description: "",
  defaultMessage: "Gazelle scheduling significantly improves your route planning and online self-scheduling. It's easy!"
};

export const MSG_schedulingUpsellStep1 = {
  id: "85b51dd8-775a-491e-b84e-9e6c65d15977",
  description: "",
  defaultMessage: "Step 1: Draw your service area on a map."
};

export const MSG_schedulingUpsellStep2 = {
  id: "fc75d275-36d1-4ab1-b391-c3a2bd13b60b",
  description: "",
  defaultMessage: "Step 2: Define your availability."
};

export const MSG_schedulingUpsellStep3 = {
  id: "9356fbe9-b717-4f22-a1e6-c3b1b6227fc8",
  description: "",
  defaultMessage: "Step 3: Easily find potential appointment slots complete with actual travel time between appointments."
};

export const MSG_schedulingUpsellStep4 = {
  id: "35ca234b-6a17-471d-9365-50960344368d",
  description: "",
  defaultMessage: "Step 4: (Optional) Give your customers the option of scheduling online without waiting on you to return their call."
};

export const MSG_schedulingUpsell3 = {
  id: "42891200-0938-4bf6-a200-fd6ad4536051",
  description: "",
  defaultMessage: "Take back your nights and weekends! Spend more time building relationships and getting paid, less time planning for it."
};

export const MSG_coordinatesTitle = {
  id: "462169e4-1657-4f9d-8a27-cdece6e8eb34",
  description: "A type of location that is defined by a pair of numbers that represent a point on the Earth's surface.  Sometimes referred to as 'latitude,longitude'.",
  defaultMessage: "Coordinates"
};

export const MSG_selectAnAddressLabel = {
  id: "b2f1c623-44b7-4ea0-91d0-b53e7427069d",
  description: "",
  defaultMessage: "Start typing an address and select from the list"
};

export const MSG_enterAnAddressLabel = {
  id: "ce777590-434c-4159-9a5a-00d05389142f",
  defaultMessage: "Enter an address:"
};

export const MSG_startDayAtClientsHouseLabel = {
  id: "41c9bfb7-3ca1-49fa-b6e3-b056b1c0af31",
  defaultMessage: "Start day at the client's location"
};

export const MSG_startDayAtStartOfDayLocationLabel = {
  id: "26b56ba1-483f-4de2-8d5c-5fdecadd5dd9",
  defaultMessage: "Start day at the start-of-day location"
};

export const MSG_endDayAtClientsHouseLabel = {
  id: "e7f43859-e1e8-4e72-97d4-3d1f80ea3767",
  defaultMessage: "End day at the client's location"
};

export const MSG_endDayAtEndOfDayLocationLabel = {
  id: "ae6216bd-c14b-4a49-a670-1d2f210b3c28",
  defaultMessage: "End day at the end-of-day location"
};

export const MSG_definedBoundariesMapOnMobileExplanation = {
  id: "b1fe5c11-5628-4ef0-a153-76af5f318591",
  defaultMessage: "You must use a computer with a web browser to draw the boundaries of your service area on a map."
};
