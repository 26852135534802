import { IMappingTypeahead, IMappingTypeaheadPrediction } from "../typeahead";
import axios from 'axios';

interface IAzureMappingTypeaheadConfig {
  azk: string;
  countryCode: string;
  locationBiasLat?: number;
  locationBiasLng?: number;
  locationBiasRadius?: number;
  companyLat: number;
  companyLng: number;
}

class AzureMappingTypeahead implements IMappingTypeahead {
  config: IAzureMappingTypeaheadConfig;

  constructor(config: IAzureMappingTypeaheadConfig) {
    this.config = config;
  }

  async getPredictions(queryStr: string): Promise<IMappingTypeaheadPrediction[]> {
    const config = this.config;
    const baseUrl = 'https://atlas.microsoft.com/search/address/json';
    let params: any = {
      'api-version': '1.0',
      'subscription-key': config.azk,
      typeahead: true,
      countrySet: config.countryCode,
      query: queryStr
    };
    if (config.locationBiasLat && config.locationBiasLng && config.locationBiasRadius) {
      params.lat = config.locationBiasLat;
      params.lon = config.locationBiasLng;
      // With Azure, radius actually RESTRICTS results.  lat and lon bias the results.
      params.radius = config.locationBiasRadius * 1.60934 * 1000; // convert miles to meters;
    } else {
      // Azure's search does not bias the results near the location of the requester like Google's does,
      // so bias the results to the company's lat/lon even if they don't explicitly have that in their settings.
      params.lat = config.companyLat;
      params.lon = config.companyLng;
    }

    let response = (await axios.get(baseUrl, {params: params}));
    let options: IMappingTypeaheadPrediction[] = [];
    response.data.results.forEach((result: any) => {
      options.push({
        id: result.id,
        label: result.address.freeformAddress,
        highlights: [{length: 0, offset: 0}]
      });
    });
    return options;
  }
}

export { AzureMappingTypeahead };
