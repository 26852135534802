class SlotMapSizer {
  get mapWidth(): number {
    return 170;
  }

  get mapHeight(): number {
    return 150;
  }

  get pageSize(): number {
    return 6;
  }
}

export { SlotMapSizer };
