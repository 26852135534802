import * as React from 'react';
import { Col, Row } from 'reactstrap';
import { IGeocode } from '@core/models';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShieldCheck } from "@fortawesome/pro-solid-svg-icons";
import {
  MSG_cityWithColonLabel,
  MSG_gpsCoordinatesLabel,
  MSG_postalCodeWithColonLabel,
  MSG_stateProvinceWithColonLabel,
  MSG_street2WithColonLabel,
  MSG_streetWithColonLabel
} from "@gazelle/shared/strings";
import { formatMessage } from "@gazelle/shared/utils";
import { IAddressParts } from "@gazelle/shared/interfaces";
import { IMappingInteractiveMap } from 'shared-web/mapping';

interface IStreetAddressWithMapProps {
  address: IAddressParts,
  geocode: IGeocode,
  wide?: boolean,
  interactiveMapProvider: IMappingInteractiveMap
}

class StreetAddressWithMap extends React.Component<IStreetAddressWithMapProps> {
  render() {
    const { address, geocode } = this.props;
    const MapContainer = this.props.interactiveMapProvider.ContainerClass;
    return (
      <Row className="street-address-with-map">
        <Col xs={12} md={this.props.wide ? 6 : 12} lg={6}>
          <div className="icon-container">
            <FontAwesomeIcon icon={faShieldCheck} />
          </div>
          <div className="address-details">
            <div>
              {formatMessage(MSG_streetWithColonLabel)}
              {' '}<b>{address.address1}</b></div>

            {address.address2 &&
            <div>
              {formatMessage(MSG_street2WithColonLabel)}
              {' '}<b>{address.address2}</b></div>}

            <div>
              {formatMessage(MSG_cityWithColonLabel)}
              {' '}<b>{address.city}</b></div>

            <div>
              {formatMessage(MSG_stateProvinceWithColonLabel)}
              {' '}<b>{address.state}</b></div>

            <div>
              {formatMessage(MSG_postalCodeWithColonLabel)}
              {' '}<b>{address.zip}</b></div>

            <br/>
            {geocode && (
              <div className="text-small text-muted">
                {formatMessage(MSG_gpsCoordinatesLabel)}
                :<br/>
                {Math.round(geocode.lat * 100000000) / 100000000}, {Math.round(geocode.lng * 100000000) / 100000000}
              </div>
            )}
          </div>
        </Col>
        <Col xs={12} md={this.props.wide ? 6 : 12} lg={6} className={'mt-4 mt-lg-0' + (this.props.wide ? ' mt-md-0' : '')}>
          <div style={{border: '3px solid #fff', borderRadius: '3px'}}>
            <MapContainer lat={geocode.lat} lng={geocode.lng} height={200} width="100%" />
          </div>
        </Col>
      </Row>
    );
  }
}

export { StreetAddressWithMap };
