import * as React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import { faCheckCircle, faCircle, faEllipsisH } from "@fortawesome/pro-solid-svg-icons";
import { formatMessage, PianoTypeInfo, SortedPianoTypes } from "@gazelle/shared/utils";
import { MSG_optionalTitle, MSG_pianoTypeLabel } from "@gazelle/shared/strings";
import { PIANO_TYPE } from "@gazelle/shared/enums";

interface IProps {
  type: string,
  onChange: (type: string) => void;
}

interface IState {
  selectedType: string;
}

const grandImg = require('@gazelle/shared/images/piano-grand.jpg');
const uprightImg = require('@gazelle/shared/images/piano-upright.jpg');

class PianoTypeInput extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {selectedType: props.type};
  }

  setType = (type: string) => {
    this.setState({selectedType: type});
    this.props.onChange(type);
  };

  render() {
    const { selectedType } = this.state;
    let otherSelectedType = selectedType;
    if (otherSelectedType === 'GRAND' || otherSelectedType === 'UPRIGHT') {
      otherSelectedType = 'UNKNOWN';
    }

    return (
      <Row className="piano-type-input">
        <Col xs={12}>
          <Label>
            {formatMessage(MSG_pianoTypeLabel)}
            <span className="ms-3 text-muted text-uppercase text-small">{formatMessage(MSG_optionalTitle)}</span>
          </Label>
        </Col>

        <Col xs={6} sm={4} className="d-none d-sm-block">
          <div className={`option ${selectedType === 'GRAND' && 'active'}`}
               onClick={() => this.setType('GRAND')}>
            <img alt='Image of grand piano' src={grandImg} width={75} height={75}/>
            <div className="label">{PianoTypeInfo[PIANO_TYPE.GRAND].getLabel()}</div>
            {selectedType === 'GRAND' && (
              <span className="fa-layers fa-fw check">
                <FontAwesomeIcon icon={faCircle}/>
                <FontAwesomeIcon icon={faCheckCircle}/>
              </span>
            )}
          </div>
        </Col>

        <Col xs={6} sm={4} className="d-none d-sm-block">
          <div className={`option ${selectedType === 'UPRIGHT' && 'active'}`}
               onClick={() => this.setType('UPRIGHT')}>
            <img alt='Image of upright piano' src={uprightImg} width={75} height={75}/>
            <div className="label">{PianoTypeInfo[PIANO_TYPE.UPRIGHT].getLabel()}</div>
            {selectedType === 'UPRIGHT' && (
              <span className="fa-layers fa-fw check">
                <FontAwesomeIcon icon={faCircle}/>
                <FontAwesomeIcon icon={faCheckCircle}/>
              </span>
            )}
          </div>
        </Col>

        <Col xs={{size: 6, offset: 3}} sm={{size: 4, offset: 0}} className="d-none d-sm-block">
          <div className={`option ${selectedType !== 'GRAND' && selectedType !== 'UPRIGHT' && 'active'}`}
               onClick={() => {
                 if (selectedType === 'GRAND' || selectedType === 'UPRIGHT') {
                   this.setType('UNKNOWN');
                 }
               }}>
            <div style={{height: 67, width: 75, display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 'auto'}}>
              <FontAwesomeIcon icon={faEllipsisH}/>
            </div>
            <div className="label">
              <Input type="select"
                     bsSize="sm"
                     style={{fontWeight: 'normal'}}
                     defaultValue={otherSelectedType}
                     onChange={e => this.setType(e.currentTarget.value)}>
                {SortedPianoTypes
                  .filter(t => [PIANO_TYPE.GRAND, PIANO_TYPE.UPRIGHT].indexOf(t) < 0)
                  .map(t => <option key={t} value={t}>{PianoTypeInfo[t].getLabel()}</option>)}
              </Input>
            </div>
            {selectedType !== 'GRAND' && selectedType !== 'UPRIGHT' && (
              <span className="fa-layers fa-fw check">
                <FontAwesomeIcon icon={faCircle}/>
                <FontAwesomeIcon icon={faCheckCircle}/>
              </span>
            )}
          </div>
        </Col>

        <Col xs={12} className="d-block d-sm-none">
          <FormGroup>
            <Input type="select"
                   style={{fontWeight: 'normal'}}
                   defaultValue={selectedType}
                   onChange={e => this.setType(e.currentTarget.value)}>
              {SortedPianoTypes.map(t => <option key={t} value={t}>{PianoTypeInfo[t].getLabel()}</option>)}
            </Input>
          </FormGroup>
        </Col>
      </Row>
    );
  }
}

export { PianoTypeInput };
