import { RootStore } from "./root_store";
import { action, makeObservable, observable } from "mobx";
import {
  PrivateDashboardMetricsAppointmentsTimeframeDataQuery,
  PrivateDashboardMetricsClientsCreatedTimeframeDataQuery,
  PrivateDashboardMetricsEstimatesCreatedTimeframeDataQuery,
  PrivateDashboardMetricsInvoicesCreatedTimeframeDataQuery,
  PrivateDashboardMetricsPianosCreatedTimeframeDataQuery,
  PrivateDashboardMetricsRemindersTimeframeDataQuery
} from "../graphql/private/dashboard";
import { Moment } from 'moment-timezone';
import { IDashboardMetricsData, IDashboardTimeframeData } from "../interfaces";
import { TIMEFRAME_DATA_TYPE } from "../enums";
import { BLUE_COLOR, GREEN_COLOR, ORANGE_COLOR, RED_COLOR, TEAL_COLOR } from "../colors";

const moment = require('moment-timezone');

// This fetches timeframeData from one some of the historicalTimeframe
// sections in the dashboardMetrics GraphQL query.
export class DashboardMetricsTimeframeDataStore {
  rootStore: RootStore;
  type: TIMEFRAME_DATA_TYPE;
  startOn: Moment;
  endOn: Moment;
  userId?: string;
  color?: string;

  isDataLoaded: boolean = false;
  isFetching: boolean = false;
  fetchedData: IDashboardMetricsData;

  constructor(rootStore: RootStore, type: TIMEFRAME_DATA_TYPE, startOn: string, endOn: string, userId?: string) {
    this.rootStore = rootStore;
    this.setTimeframe(startOn, endOn, type, userId);
    this.fetchedData = {};
    makeObservable(this, {
      isDataLoaded: observable,
      isFetching: observable,
      fetchedData: observable,
      startOn: observable,
      endOn: observable,
      userId: observable,
      type: observable,
      color: observable,
      setTimeframe: action,
    });
  }

  setTimeframe(startOn: string, endOn: string, type: TIMEFRAME_DATA_TYPE, userId: string) {
    this.startOn = moment(startOn);
    this.endOn = moment(endOn);
    this.userId = userId;
    this.type = type;
    switch (type) {
      case TIMEFRAME_DATA_TYPE.APPOINTMENTS:
        this.color = RED_COLOR;
        break;
      case TIMEFRAME_DATA_TYPE.CLIENTS_CREATED:
        this.color = GREEN_COLOR;
        break;
      case TIMEFRAME_DATA_TYPE.ESTIMATES_CREATED:
        this.color = ORANGE_COLOR;
        break;
      case TIMEFRAME_DATA_TYPE.INVOICES_CREATED:
        this.color = TEAL_COLOR;
        break;
      case TIMEFRAME_DATA_TYPE.PIANOS_CREATED:
        this.color = BLUE_COLOR;
        break;
      case TIMEFRAME_DATA_TYPE.REMINDERS:
        this.color = RED_COLOR;
        break;
    }
  }

  async fetch() {
    this.isFetching = true;

    let query: any = null;
    switch (this.type) {
      case TIMEFRAME_DATA_TYPE.APPOINTMENTS:
        query = PrivateDashboardMetricsAppointmentsTimeframeDataQuery;
        break;
      case TIMEFRAME_DATA_TYPE.CLIENTS_CREATED:
        query = PrivateDashboardMetricsClientsCreatedTimeframeDataQuery;
        break;
      case TIMEFRAME_DATA_TYPE.ESTIMATES_CREATED:
        query = PrivateDashboardMetricsEstimatesCreatedTimeframeDataQuery;
        break;
      case TIMEFRAME_DATA_TYPE.INVOICES_CREATED:
        query = PrivateDashboardMetricsInvoicesCreatedTimeframeDataQuery;
        break;
      case TIMEFRAME_DATA_TYPE.PIANOS_CREATED:
        query = PrivateDashboardMetricsPianosCreatedTimeframeDataQuery;
        break;
      case TIMEFRAME_DATA_TYPE.REMINDERS:
        query = PrivateDashboardMetricsRemindersTimeframeDataQuery;
        break;
    }

    const response = await this.rootStore.apolloClient.query({
      query: query,
      fetchPolicy: 'network-only',
      variables: {
        startOn: this.startOn.format('YYYY-MM-DD'),
        endOn: this.endOn.format('YYYY-MM-DD'),
        userId: this.userId
      }
    });

    const data = response.data?.dashboardMetrics;
    let newFetchedData: IDashboardMetricsData = {};

    // HISTORICAL TIMEFRAME
    if (data.historicalTimeframe) {
      newFetchedData.historicalTimeframe = {};
      if (data.historicalTimeframe.appointments?.timeframeData) {
        newFetchedData.historicalTimeframe.appointments = {
          timeframeData: data.historicalTimeframe.appointments.timeframeData
            .map((d: any) => ({date: d.date, count: d.count}))
            .sort((a: any, b: any) => a.date < b.date ? -1 : 1),
        };
      }
      if (data.historicalTimeframe.clientsCreated?.timeframeData) {
        newFetchedData.historicalTimeframe.clientsCreated = {
          timeframeData: data.historicalTimeframe.clientsCreated.timeframeData
            .map((d: any) => ({date: d.date, count: d.count}))
            .sort((a: any, b: any) => a.date < b.date ? -1 : 1),
        };
      }
      if (data.historicalTimeframe.estimatesCreated?.timeframeData) {
        newFetchedData.historicalTimeframe.estimatesCreated = {
          timeframeData: data.historicalTimeframe.estimatesCreated.timeframeData
            .map((d: any) => ({date: d.date, count: d.count}))
            .sort((a: any, b: any) => a.date < b.date ? -1 : 1),
        };
      }
      if (data.historicalTimeframe.invoicesCreated?.timeframeData) {
        newFetchedData.historicalTimeframe.invoicesCreated = {
          timeframeData: data.historicalTimeframe.invoicesCreated.timeframeData
            .map((d: any) => ({date: d.date, count: d.count}))
            .sort((a: any, b: any) => a.date < b.date ? -1 : 1),
        };
      }
      if (data.historicalTimeframe.pianosCreated?.timeframeData) {
        newFetchedData.historicalTimeframe.pianosCreated = {
          timeframeData: data.historicalTimeframe.pianosCreated.timeframeData
            .map((d: any) => ({date: d.date, count: d.count}))
            .sort((a: any, b: any) => a.date < b.date ? -1 : 1),
        };
      }
      if (data.historicalTimeframe.reminders?.timeframeData) {
        newFetchedData.historicalTimeframe.reminders = {
          timeframeData: data.historicalTimeframe.reminders.timeframeData
            .map((d: any) => ({date: d.date, count: d.count}))
            .sort((a: any, b: any) => a.date < b.date ? -1 : 1),
        };
      }
    }

    this.fetchedData = newFetchedData;
    this.isFetching = false;
    this.isDataLoaded = true;
  }

  get fetchedTimeframeData(): IDashboardTimeframeData[] {
    let data: IDashboardTimeframeData[];
    switch (this.type) {
      case TIMEFRAME_DATA_TYPE.APPOINTMENTS:
        data = this.fetchedData.historicalTimeframe.appointments?.timeframeData;
        break;
      case TIMEFRAME_DATA_TYPE.CLIENTS_CREATED:
        data = this.fetchedData.historicalTimeframe.clientsCreated?.timeframeData;
        break;
      case TIMEFRAME_DATA_TYPE.ESTIMATES_CREATED:
        data = this.fetchedData.historicalTimeframe.estimatesCreated?.timeframeData;
        break;
      case TIMEFRAME_DATA_TYPE.INVOICES_CREATED:
        data = this.fetchedData.historicalTimeframe.invoicesCreated?.timeframeData;
        break;
      case TIMEFRAME_DATA_TYPE.PIANOS_CREATED:
        data = this.fetchedData.historicalTimeframe.pianosCreated?.timeframeData;
        break;
      case TIMEFRAME_DATA_TYPE.REMINDERS:
        data = this.fetchedData.historicalTimeframe.reminders?.timeframeData;
        break;
    }
    return data;
  }
}
