export const MSG_contactTitle = {
  id: "43da0c68-c38a-4ddc-a776-598d4519a2fe",
  description: "A contact is a record of a person with all their contact information.  A client record in Gazelle can have multiple contact records (e.g. husband, wife, etc)",
  defaultMessage: "Contact"
};

export const MSG_contactsTitle = {
  id: "416adac3-5eb9-4a63-9b96-1baa69f2dfcb",
  description: "Plural of 'contact'. A contact is a record of a person with all their contact information.  A client record in Gazelle can have multiple contact records (e.g. husband, wife, etc)",
  defaultMessage: "Contacts"
};

export const MSG_changeContactTitle = {
  id: "416aee76-796b-4708-a7d3-4ec0fd061157",
  description: "The label for the button that lets them change which contact is selected.",
  defaultMessage: "Change Contact"
};

export const MSG_primaryContactTitle = {
  id: "19e044ef-7d90-4e9c-b9ad-af09017b809c",
  description: "The contact record for a client that is the one we should use primarily.",
  defaultMessage: "Primary Contact",
};

export const MSG_primaryContactLabel = {
  id: "fcf687e8-f94f-4ba4-b9bd-a60b6f8e369e",
  description: "The contact record for a client that is the one we should use primarily.",
  defaultMessage: "Primary contact",
};

export const MSG_billingContactTitle = {
  id: "21f2c568-0603-47a5-ad4b-2554303eb5b5",
  description: "Short text that is displayed when a contact is the billing contact for a client.",
  defaultMessage: "Billing Contact",
};

export const MSG_billingContactLabel = {
  id: "93ebd016-0a37-4ddd-b21f-dc104e986990",
  description: "Short text that is displayed when a contact is the billing contact for a client.",
  defaultMessage: "Billing contact",
};

export const MSG_primaryPhoneTitle = {
  id: "a66756cd-071b-4181-b694-d707b3e24f1f",
  description: "A label we display on the primary phone record -- that is, the one that should be used by default",
  defaultMessage: "Primary Phone"
};

export const MSG_primaryPhoneLabel = {
  id: "9bad9d73-ccfe-41b7-be16-b9a379b76990",
  description: "This is the label for the toggle switch that makes this the primary phone number for a contact.",
  defaultMessage: "Primary phone",
};

export const MSG_primaryEmailTitle = {
  id: "f08ad83e-550f-492e-a916-af94d77a3601",
  description: "A label we display on the primary email record -- that is, the one that should be used by default",
  defaultMessage: "Primary Email"
};

export const MSG_clientTitle = {
  id: "e30e8dcc-3015-4215-ba64-3758a9ea84fc",
  description: "The label for a person or company tracked in our CRM system.  This is in title case",
  defaultMessage: "Client",
};

export const MSG_clientLabel = {
  id: "ea725fc9-59e8-487d-854b-276bd755a15f",
  description: "The label for a person or company tracked in our CRM system.  This is in label case",
  defaultMessage: "Client",
};

export const MSG_newClientTitle = {
  id: "a0899741-a1ad-468e-8fd8-1fd7d71070b7",
  description: "The label for a button when creating a new client record.",
  defaultMessage: "New Client",
};

export const MSG_longNewClient = {
  id: "29edbbee-a8fd-46e0-8e19-b753de11256d",
  description: "The longer label for the action that lets them create a new client record in their database.",
  defaultMessage: "Create a new client record."
};

export const MSG_newContactTitle = {
  id: "c3fb4c54-d1d0-4bc4-8d17-12c3df1e657d",
  description: "The label for a button when they are creating a new contact record.",
  defaultMessage: "New Contact",
};

export const MSG_clientAndPianoTitle = {
  id: "f1b95c97-02ae-44d9-9585-32f5c4c6bbf1",
  description: "The label for display both a client and a piano.",
  defaultMessage: "Client & Piano",
};

export const MSG_unnamedClientTitle = {
  id: "04aba062-1cbf-4f95-8425-354d4abf5130",
  description: "What to display for the client name when no name has been specified.",
  defaultMessage: "Unnamed Client",
};

export const MSG_noClientNotesWillBeAdded = {
  id: "2a01d263-6bc3-4cb0-ae64-0bb385d2678d",
  defaultMessage: "No client notes will be added."
};

export const MSG_addClientNotesTitle = {
  id: "d23186a1-44cb-40cb-bc78-541467cf30ed",
  defaultMessage: "Add Client Notes"
};

export const MSG_addSecondaryContactTitle = {
  id: "fb30eae3-9924-4fc9-895a-5d623eef2944",
  defaultMessage: "Add Secondary Contact",
};

export const MSG_removeSecondaryContactLabel = {
  id: "ce5e11ac-8f76-4827-b944-533606d07655",
  defaultMessage: "Remove Secondary Contact",
};

export const MSG_addressTitle = {
  id: "46fa33f2-dac1-4eb6-9333-afd61e67f108",
  description: "The word 'address' referring to the street address of a client's home.",
  defaultMessage: "Address"
};

export const MSG_addressesTitle = {
  id: "a1a34f4c-d841-49a1-8a3a-24d913d8c655",
  description: "The word 'addresses' (plural of address) referring to multiple mailing, street, or billing addresses for a client.",
  defaultMessage: "Addresses"
};

export const MSG_addressTypeLabel = {
  id: "14a370e2-5b90-4610-91d7-f1d6e47acedf",
  description: "The type of address (e.g. street, mailing, or billing).",
  defaultMessage: "Address type",
};

export const MSG_streetAddressLabel = {
  id: "32a56ef7-ad4d-40a0-bc3c-b5f651f71e76",
  description: "The term 'street address' indicates the location of their house on a map, not just a mailing address (i.e. not a post office box).",
  defaultMessage: "Street address"
};

export const MSG_mailingAddressLabel = {
  id: "cbeb8a7c-3011-4032-9ddd-d2efdc76a6ac",
  description: "The term 'mailing address' indicates where mail should be sent.  This is often the same as the street address, but it could be a PO Box.",
  defaultMessage: "Mailing address"
};

export const MSG_addressLine1ShortLabel = {
  id: "bec11b86-66a3-44f9-a84a-48c9b7500897",
  description: "A short label for the first line of an address.  This could be a street address or a post office box, etc.",
  defaultMessage: "Line 1",
};

export const MSG_addressLine2ShortLabel = {
  id: "d1292d55-fe14-4e86-9cf2-7ba35dbb6c2e",
  description: "A short label for the second line of an address.  This might be an apartment number, a suite number, etc.",
  defaultMessage: "Line 2",
};

export const MSG_addressLine1Label = {
  id: "d877a68e-9663-442b-bd42-5a1e088d7215",
  description: "A label for the first line of an address.  This could be a street address or a post office box, etc.",
  defaultMessage: "Address (line 1)"
};

export const MSG_addressLine2Label = {
  id: "709f2976-c53c-4ce7-b973-8cd3dce34256",
  description: "A label for the second line of an address.  This could be a street address or a post office box, etc.",
  defaultMessage: "Address (line 2)"
};

export const MSG_addressLine2Explanation = {
  id: "480a4781-b8f5-4228-bc54-d64d66cd82c7",
  defaultMessage: "This is often used for apartment numbers.",
};

export const MSG_addressNotFoundByOurMappingProvider = {
  id: "7bc3b6c1-15e2-436b-aeab-1df080b9123f",
  description: "",
  defaultMessage: "The precise location of this address could not be found with our mapping provider."
};

export const MSG_fullAddressForThisClientLabel = {
  id: "dced338e-8264-40d0-8806-2182a938dbb8",
  description: " This will be followed by a formatted address.",
  defaultMessage: "The full address for this client is:"
};


export const MSG_dateTitle = {
  id: "8a2aafd9-6b58-4690-969f-8e642a4dfe71",
  description: "A label for the date of an event.",
  defaultMessage: "Date",
};

export const MSG_addressLocationTitle = {
  id: "629bff91-eda1-4b2d-bf87-8f3740ab10c9",
  description: "The general word 'location' referring to the city, state, province, or general region that a client is located.",
  defaultMessage: "Location",
};

export const MSG_cityLabel = {
  id: "8787078f-0496-40fb-9b22-094cee2c4a81",
  description: "The word 'city' referring to the city, town, or village of a client's home.  This is in label case.",
  defaultMessage: "City",
};

export const MSG_stateProvinceLabel = {
  id: "f1272a66-38ea-41aa-b860-da939e8a2b0d",
  description: "The word referring to the state or province of a client's home.",
  defaultMessage: "State/province",
};

export const MSG_postCodeLabel = {
  id: "eeb863fc-4cb4-4064-b609-bc62c8df58d7",
  defaultMessage: "Postal code",
};

export const MSG_postCodeTitle = {
  id: "54941ddd-d60e-4bd8-b751-391b5f5e908b",
  defaultMessage: "Postal Code",
};

export const MSG_streetWithColonLabel = {
  id: "d407d74c-4bec-4f7f-9825-f6b795fe0dd3",
  description: "The 'street' portion of an address.  Usually with a house number and street name.",
  defaultMessage: "Street:",
};

export const MSG_street2WithColonLabel = {
  id: "ffc2ec1c-1277-4899-bca8-59cc6c53cdda",
  description: "The optional second line of a street address.  It is uncommon that this would be used, but it might be used for a subpremise such as an apartment number.",
  defaultMessage: "Street (continued):",
};

export const MSG_cityWithColonLabel = {
  id: "a43a4329-a7d3-4591-b0fb-684c39f054d2",
  description: "The 'city' portion of an address.",
  defaultMessage: "City:",
};

export const MSG_stateProvinceWithColonLabel = {
  id: "3d67dae3-4830-4154-84cb-1558d96e7700",
  description: "The 'province' or 'region' portion of an address.  This should be a generic term.  In the US it is called a State, but other countries call it a county, region, or province.  Be as generic as you can.",
  defaultMessage: "State/province:",
};

export const MSG_postalCodeWithColonLabel = {
  id: "27b2467e-52d3-4f3a-a436-0c40218b6c5c",
  description: "The 'postal code' portion of an address.  In the US, this would be the zip code like 63722.  This should be a generic term because different countries call this different things.",
  defaultMessage: "Postal code:",
};

export const MSG_gpsCoordinatesLabel = {
  id: "08c5cdd4-e9c2-4991-9183-764ec35929ac",
  description: "The term for lattitude/longitude used to find an exact location on the globe.  In the US we call these GPS Coordinates.",
  defaultMessage: "GPS coordinates",
};

export const MSG_removeAddressConfirmation = {
  id: "c194f71b-b35c-4c14-a389-3a47665d584d",
  description: "",
  defaultMessage: "Are you sure you want to remove this address?"
};


export const MSG_phoneTypeLabel = {
  id: "462ee6c9-17f3-41dd-834d-a5ce9f3ea3f2",
  description: "The type of phone that a number is for.  For example: mobile, home, work, etc",
  defaultMessage: "Phone type"
};

export const MSG_phoneNumberLabel = {
  id: "8adf9ae3-5f83-438e-be67-4768115e1d46",
  description: "A label for a phone number input field.",
  defaultMessage: "Phone number"
};

export const MSG_phoneLabel = {
  id: "6fe66941-98eb-4d62-9e30-67a334edd8ea",
  description: "A short label for phone number.",
  defaultMessage: "Phone",
};

export const MSG_phoneTitle = {
  id: "353a198f-03d3-425c-afb0-5f6e2dc05e1b",
  description: "A short label for a button that accesses the phone number for a client.",
  defaultMessage: "Phone",
};

export const MSG_extensionLabel = {
  id: "ea806140-d957-430c-9c03-d8ec8fe7c943",
  description: "An extension for a phone number.  For example in '555-555-5555 x123', x123 is the extension.",
  defaultMessage: "Extension",
};

export const MSG_extensionShortLabel = {
  id: "3c07ea5c-dfa1-4143-b739-a5e26a5b793a",
  description: "An extension for a phone number.  This is a short label.",
  defaultMessage: "Ext."
};

export const MSG_phoneNumbersTitle = {
  id: "0744be20-0aff-4093-84d3-71850b4561ec",
  description: "The title over a list of phone numbers",
  defaultMessage: "Phone Numbers"
};

export const MSG_phoneNumbersLabel = {
  id: "7caebefb-4de6-4d4a-84ca-b9a05ddbbc3a",
  description: "The title over a list of phone numbers",
  defaultMessage: "Phone numbers"
};

export const MSG_removePhoneConfirmation = {
  id: "13a3c21f-d0db-45a4-946c-55269957e1ea",
  description: "",
  defaultMessage: "Are you sure you want to remove this phone number?"
};

export const MSG_textablePhoneNumbersTitle = {
  id: "cd8fd859-2ae8-4a4a-8ef2-f6471a26078d",
  description: "The title of a section that lists all the phone numbers that can receive a text message",
  defaultMessage: "Textable Phone Numbers"
};

export const MSG_textablePhoneNumbersLabel = {
  id: "477811ce-26f7-48f8-8a38-14a8b7c29360",
  description: "The title of a section that lists all the phone numbers that can receive a text message",
  defaultMessage: "Textable phone numbers"
};


export const MSG_emailAddressLabel = {
  id: "d6fc7455-06e6-4f83-97b1-1cb2329e66b6",
  description: "A label for an email input field",
  defaultMessage: "Email address"
};

export const MSG_emailLabel = {
  id: "cdf91862-ce3a-4ff9-8b4f-6c4fac973057",
  description: "A short label for email address.",
  defaultMessage: "Email"
};

export const MSG_emailTitle = {
  id: "6d226457-2f76-4ede-b2d2-30eba94082c2",
  description: "A short label for a button that accesses the email address for a client or sends an email to a client.",
  defaultMessage: "Email"
};

export const MSG_emailAddressesTitle = {
  id: "b035a71e-41f4-430d-9f91-2c1793ce823b",
  description: "The title over a list of email addresses",
  defaultMessage: "Email Addresses"
};

export const MSG_emailAddressesLabel = {
  id: "82b69f09-1c0d-44a5-807a-9bfca410a710",
  description: "The label over a list of email addresses",
  defaultMessage: "Email addresses"
};

export const MSG_removeEmailConfirmation = {
  id: "2176f100-a70b-43cc-8db0-d76c928b9ccc",
  description: "",
  defaultMessage: "Are you sure you want to remove this email address?"
};

export const MSG_createAsNewClientExplanationWithName = {
  id: "720e2e09-385a-45d6-8ca6-37f980e1168d",
  defaultMessage: "Add {newName} as a new client record NOT associated with {existingClientName}.  This is a useful option if {newName} made this reservation using the private scheduling link for {existingClientName}."
};

export const MSG_addAsNewClientExplanationWithName = {
  id: "176cb7d1-920a-43d7-90df-e601513fcc61",
  defaultMessage: "This will create a new client record that is not associated with {otherClientName}.  This is useful if this reservation was made using another client's private scheduling link.  Are you sure you want to do this?"
};

export const MSG_addAsNewClientExplanationWithoutName = {
  id: "b7d12c78-2bc7-4f6d-9f2f-56583beec6de",
  defaultMessage: "This will create a new client record that is not associated with the client on the reservation.  This is useful if this reservation was made using another client's private scheduling link.  Are you sure you want to do this?"
};

export const MSG_findAddressPlaceholderLabel = {
  id: '9c7d09aa-b2bc-4c70-8682-501cbbc08207',
  description: 'The placeholder for an input field where they can search for addresses for a new client.',
  defaultMessage: "Find an address..."
};

export const MSG_findExistingClientLabel = {
  id: "e714cd2a-57a6-4f04-8530-edb0f1901f6c",
  description: "This is displayed on a button a user taps to pull up a client search screen.",
  defaultMessage: "Find an existing client",
};

export const MSG_givenNameLabel = {
  id: "48be21dd-a4bc-4a1a-b87c-d71c174f9016",
  description: "The label for the given name (first name) of a person.",
  defaultMessage: "First name",
};

export const MSG_familyNameLabel = {
  id: "e4ff860b-50d0-45ac-8001-272f5a6015c4",
  description: "The label for the family name (last name) of a person.",
  defaultMessage: "Last name",
};

export const MSG_referenceIdTitle = {
  id: "3d6231cc-f72e-4278-9cdc-32c53738dc9c",
  description: "This is an ID that a user can add to a client or piano record that refers back to some other system.  For example, often this is used to refer back to the data record in another system (like QuickBooks, etc) that was used to create the record in Gazelle.",
  defaultMessage: "Import Reference ID",
};

export const MSG_emailAddressBounced = {
  id: "b79f7a96-85b6-4e94-b88a-8835da364663",
  defaultMessage: "The email address this client entered has bounced past email messages.  Because of this, your client will likely not receive the reservation approval email when you send it.  Consider reaching out to your client directly.",
};

export const MSG_noClientsFound = {
  id: "2d74df6f-318c-466c-9298-2458a756a2db",
  description: "A message that is displayed when a client is searched for but none were found.",
  defaultMessage: "No clients found"
};

export const MSG_noClientsFoundMatching = {
  id: "06743198-2c59-4bc0-9404-e28d6aa0d9f6",
  description: "A message that is displayed when a client is searched for but none were found for a search.",
  defaultMessage: "No clients found matching: {searchString}"
};

export const MSG_clientNotFound = {
  id: "bc0d9612-6a63-4c03-9634-616a429b2386",
  defaultMessage: "Client Not Found",
};

export const MSG_clientNotFoundDetails = {
  id: "78b5509d-2f00-4868-a69f-69de36207447",
  defaultMessage: "The client record does not exist.",
};

export const MSG_timelineTitle = {
  id: "a7f59efe-d6c0-4dab-85f9-f52a54c1163f",
  description: "The 'timeline' is a feature of Gazelle that lets the user see all the past interactions they've had with a client in a time sequence.  This is the name of that feature.",
  defaultMessage: "Timeline",
};

export const MSG_noTimelineEntriesMatchingFilter = {
  id: "096343f6-adae-42f2-8987-9918cca406d3",
  defaultMessage: "There are no timeline entries matching this filter."
};

export const MSG_showClientTitle = {
  id: "d65246f6-6552-46d3-82d9-4c08e73e3e24",
  description: "A button that will display information about a specific client.",
  defaultMessage: "Show Client"
};

export const MSG_wantsEmailLabel = {
  id: 'f8be9411-18b2-4a1b-a157-ff24e18498f7',
  description: 'This is a text label for a client when they are OK with receiving email reminders.',
  defaultMessage: 'Wants emails'
};

export const MSG_wantsPhoneCallLabel = {
  id: 'aa7dca3f-b36d-4a7a-8757-f6795afb3707',
  description: 'This is a text label for a client when they are OK with receiving phone call reminders.',
  defaultMessage: 'Wants phone calls'
};

export const MSG_wantsTextLabel = {
  id: '74bf395a-b4dc-4fcb-8df6-ddf04596a57b',
  description: 'This is a text label for a client when they are OK with receiving text message reminders.',
  defaultMessage: 'Wants text messages'
};

export const MSG_deleteClientTitle = {
  id: "a87195b2-7ea3-43e9-beba-f384191fd86c",
  description: "A label for a button that deletes a client",
  defaultMessage: "Delete Client",
};

export const MSG_deleteClientConfirmation = {
  id: "7a091f18-0359-4cea-907b-63c80bd8609d",
  defaultMessage: "Are you sure you want to delete {name}?"
};

export const MSG_contactCouldNotBeDeleted = {
  id: "6f6c83f7-125a-4cc3-90b3-8965d2c07484",
  description: "The message that is displayed when there was a problem deleting a contact record.",
  defaultMessage: "The contact could not be deleted."
};

export const MSG_deleteClientConfirmationQuestion = {
  id: "c4778dd2-df0b-4c53-8ec3-bdb323c438fd",
  description: "A confirmation message that is shown prior to deleting a client.",
  defaultMessage: "It is usually better to just mark a client as inactive so the information stays around in case they call back in a few years.\n\nAre you sure you want to permanently delete all information (including invoices) about {clientName}?",
};

export const MSG_deleteClientErrorMessage = {
  id: "b18be5a1-c311-4a4f-b1df-7767b5d6e443",
  description: "An error message that is displayed if something went wrong while trying to delete a client.",
  defaultMessage: "The client could not be deleted.",
};

export const MSG_getDrivingDirectionsLabel = {
  id: "4eed3cce-1c39-46de-9e3c-7c0051aeb818",
  defaultMessage: "Get driving directions",
};

export const MSG_callWithNumberLabel = {
  id: "9544bed4-eb3d-4336-b3ef-08b7529c06df",
  description: "The button label that lets them call a phone number.  The variable 'phoneType' is the type of phone like 'home', 'work', 'mobile', etc.",
  defaultMessage: "Call {phoneNumber} ({phoneType})",
};

export const MSG_dialTitle = {
  id: "0ecef4ab-c113-4cf6-9dbf-8ba69230b150",
  description: "As in dial a phone number",
  defaultMessage: "Dial",
};

export const MSG_smsWithNumberLabel = {
  id: "b9312660-1fcd-4ae4-9e13-a307327d1eb9",
  description: "The button label that lets them send a text message to a phone number.",
  defaultMessage: "Text {phoneNumber} ({phoneType})",
};

export const MSG_emailWithAddressLabel = {
  id: "a7699729-4e8b-42b6-be06-c9bd996fc003",
  description: "The button label that lets them email a customer.",
  defaultMessage: "Email {emailAddress}",
};

export const MSG_changeClientTitle = {
  id: "5b6a8fb7-9ea1-401b-a8d3-de4b9d8b3be4",
  description: "An action to change the client associated with a piano.",
  defaultMessage: "Change Client",
};

export const MSG_clientInformationTitle = {
  id: "1e02af2a-8573-4ac5-8d3b-d61bf2b8cbf2",
  defaultMessage: "Client Information",
};

export const MSG_scheduledPianosTitle = {
  id: "19b02f0f-4a41-4c52-97d5-37b61726eb6d",
  description: "A label for the pianos scheduled for a specific appointment.",
  defaultMessage: "Scheduled Pianos",
};

export const MSG_editCommentTitle = {
  id: "7d683e0e-c04f-4490-b304-97b4f000bb78",
  description: "A label for editing a comment (or note).",
  defaultMessage: "Edit Comment",
};

export const MSG_newCommentTitle = {
  id: "cdfec20b-87c7-4a35-8694-b69735f40d5f",
  description: "A label for creating a new comment (or note)",
  defaultMessage: "New Comment"
};

export const MSG_commentDateTitle = {
  id: "ac153682-6811-49f7-b892-61a29a1f295f",
  description: "The date of a comment that is being added or changed.",
  defaultMessage: "Comment Date",
};

export const MSG_deleteCommentTitle = {
  id: "0353cc97-27e8-4413-8fb2-c69103a406c6",
  description: "A label for removing a comment (or note).",
  defaultMessage: "Delete Comment",
};

export const MSG_permanentlyDeleteCommentQuestion = {
  id: "8d012927-dff1-4717-abad-3c804637e739",
  defaultMessage: "Permanently delete this comment?",
};

export const MSG_personalNotesTitle = {
  id: "808bc3f1-3246-46e3-a75e-15150f3fc384",
  description: "Personal notes about the client.  For example, 'The dog bites', 'daughter named Jane', etc.",
  defaultMessage: "Personal Notes",
};

export const MSG_personalNotesLabel = {
  id: "705448c7-f9c1-4650-9cdf-5435b887af1d",
  description: "Personal notes about the client.  For example, 'The dog bites', 'daughter named Jane', etc.",
  defaultMessage: "Personal notes",
};

export const MSG_preferenceNotesTitle = {
  id: "c76338d6-b44e-4d3b-8682-33f8c2b635b6",
  description: "Notes about a client's preferences.  For example, 'Use the back door', etc.",
  defaultMessage: "Preference Notes",
};

export const MSG_preferenceNotesLabel = {
  id: "6b2882ad-f5f8-4d29-ae6e-338c2011ad9c",
  description: "Notes about a client's preferences.  For example, 'Use the back door', etc.",
  defaultMessage: "Preference notes",
};

export const MSG_companyNameTitle = {
  id: "b28141fc-052a-4ff8-b418-bc81a2e676b0",
  description: "The label for where they enter the name of a company, if a client is actually a company and not an individual person.",
  defaultMessage: "Company Name"
};

export const MSG_companyNameLabel = {
  id: "B28141FC-052A-4FF8-B418-BC81A2E676B0",
  description: "The label for where they enter the name of a company, if a client is actually a company and not an individual person.",
  defaultMessage: "Company name"
};

export const MSG_contactTitleLabel = {
  id: "069b55ea-e21c-4c53-82eb-9505ad209a5c",
  description: "The title is the prefix before someone's name, like 'Mr.' or 'Dr.', etc.",
  defaultMessage: "Title",
};

export const MSG_contactTitleExample = {
  id: "d5e5988f-83a7-4812-9e6f-3033f6f2a90e",
  description: "An example of a title",
  defaultMessage: "Example: Dr. or Prof.",
};

export const MSG_contactFirstNameLabel = {
  id: "f7a32852-1b29-46de-ab25-906cae7dfd4f",
  description: "The client's first name, like 'Bob' or 'Sally'",
  defaultMessage: "First name",
};

export const MSG_contactMiddleNameLabel = {
  id: "48a420ac-7c7d-4a98-a721-04aaa0db1be1",
  description: "The client's middle name",
  defaultMessage: "Middle name",
};

export const MSG_contactLastNameLabel = {
  id: "cb012f16-d5f2-4f62-85da-3fb263fb6312",
  description: "The client's last name, like 'Smith' or 'Jones'",
  defaultMessage: "Last name",
};

export const MSG_contactSuffixLabel = {
  id: "ee97dab5-2c56-477d-a512-71e0fd9d9050",
  description: "The suffix is something that goes after their name, like 'Jr.', or 'III', etc.",
  defaultMessage: "Suffix",
};

export const MSG_contactSuffixExample = {
  id: "37a34651-1020-4ffa-8eb0-eec8c0349910",
  description: "An example of a name suffix.",
  defaultMessage: "Example: Jr. or III",
};

export const MSG_contactRoleLabel = {
  id: "b8f45c6c-b1e9-49b1-9b84-d4ce7daf2eda",
  description: "The role is what the person does for the organization.  For example 'Music Director' or 'Husband'.",
  defaultMessage: "Role",
};

export const MSG_contactRoleExample = {
  id: "445d91e7-75da-427f-bc91-ba135bd9f28d",
  description: "An example of a role (should be short, to fit into the available space).",
  defaultMessage: "Example: Director",
};

export const MSG_hideInactiveTitle = {
  id: "5d981245-fcab-4aca-87fd-a349d3d0d7f3",
  description: "This is a short phrase that is used as a button to hide things that can be inactivated such as clients, pianos, etc.",
  defaultMessage: "Hide Inactive",
};

export const MSG_showInactiveTitle = {
  id: "bdcb72df-add3-499d-b938-4c3237a8ad67",
  description: "This is a short phrase that is used as a button to hide things that can be inactivated such as clients, pianos, etc.",
  defaultMessage: "Show Inactive",
};

export const MSG_clientDetailsTitle = {
  id: "5733115f-0c71-4193-a759-f5b49237c5f4",
  description: "A label for a screen that displays detailed data about a client.",
  defaultMessage: "Client Details",
};

export const MSG_noPrimaryContact = {
  id: "de74b404-586c-45c2-96ca-baac10708b7d",
  description: "What to show when no primary contact person has been entered.",
  defaultMessage: "No primary contact specified.",
};

export const MSG_noAddress = {
  id: "db05c13f-86aa-43e1-aa66-d3500ac441a5",
  defaultMessage: "No address specified.",
};

export const MSG_noPhoneNumber = {
  id: "be42ef41-6e05-45c0-9031-cc276b2eae97",
  defaultMessage: "No phone number specified.",
};

export const MSG_noEmail = {
  id: "9baf946f-e80b-41df-a0a8-b148e9328790",
  defaultMessage: "No email specified.",
};

export const MSG_referralsTitle = {
  id: "f9b134a5-be8e-454d-961b-b296516cdab8",
  description: "The title of the section that lists all the people this client has referred.",
  defaultMessage: "Referrals"
};

export const MSG_referredClientsTitle = {
  id: "1845d6d9-821d-4c71-83f1-1a4f21ab84c3",
  description: "The label to show for the section where we list all the other clients that this client has referred them to.",
  defaultMessage: "Referred Clients"
};

export const MSG_addReferredClientTitle = {
  id: "407aea41-69e9-4d28-b2dc-c7fe3407d8a5",
  description: "The button where they can add a new client to the list of clients that this person has referred to them.",
  defaultMessage: "Add Referred Client"
};

export const MSG_referredByTitle = {
  id: "25093974-175c-4587-9b24-ed20f690f3e4",
  description: "The label to show for the section where they can tell us how the client was referred to them.",
  defaultMessage: "Referred By"
};

export const MSG_referredByMethodLabel = {
  id: "b4c5c2e5-f45c-4ec1-a70b-b4c20eb02977",
  description: "The label to show for the section where they can tell us what was used to referr the client (e.g. Facebook, Google Ads, etc)",
  defaultMessage: "Referred by method"
};

export const MSG_referredByClientLabel = {
  id: "9f6e0864-7e17-4f5e-9541-c531cabb4ed5",
  description: "The label to show for the section where they can tell us that the client was referred by another client",
  defaultMessage: "Referred by client"
};

export const MSG_referredLabel = {
  id: "8ced0a74-122a-4910-92c9-07167a018d46",
  description: "The label to show for when a client refers another client.  It will look like 'Referred: John Doe'.",
  defaultMessage: "Referred"
};

export const MSG_removeReferralTitle = {
  id: "013209cf-e962-4c17-a9ec-87f8d0afb351",
  description: "The label for the button that lets them remove someone that this client referred.",
  defaultMessage: "Remove Referral"
};

export const MSG_clientTypeTitle = {
  id: "e2db022b-2eb3-48ea-b95c-bd4513e8a2b1",
  description: "The type of client (e.g. residential, school, church, etc)",
  defaultMessage: "Client Type"
};

export const MSG_clientTypeLabel = {
  id: "ef68ff51-efe9-4d7c-b8a0-2c58143bef8d",
  description: "The type of client (e.g. residential, school, church, etc)",
  defaultMessage: "Client type"
};

export const MSG_clientNotesTitle = {
  id: "9812921e-642d-4b05-8b72-ff67f82f1b80",
  description: "Notes that the technician makes about the client so they'll remember next time",
  defaultMessage: "Client Notes"
};

export const MSG_clientNotesLabel = {
  id: "8f89df6e-032e-4482-87ae-a6c24661ab55",
  description: "Notes that the technician makes about the client so they'll remember next time",
  defaultMessage: "Client notes"
};

export const MSG_deleteContactTitle = {
  id: "00ef2c29-c725-4f71-bd36-48a64f186102",
  description: "A client can have multiple people associated with it called contacts.  This is a label for a button to delete one of those contacts.",
  defaultMessage: "Delete Contact",
};

export const MSG_deleteContactConfirmation = {
  id: "dbc78e58-293f-40cc-87d9-14eaa3a2a217",
  description: "",
  defaultMessage: "Are you sure you want to delete this contact including all their addresses, phone numbers, and emails?"
};


export const MSG_residentialClientTitle = {
  id: "fd1a850c-a637-4372-a278-68bdba9b80ac",
  description: "A residential client is a person or family that has a piano in their home.  This is the opposite of a commercial client.",
  defaultMessage: "Residential"
};

export const MSG_commercialClientTitle = {
  id: "1e97f206-d3bc-40fa-84ff-8d894bbf378a",
  description: "A commercial client is a company, school, or institution.  This is the opposite of a residential client.",
  defaultMessage: "Commercial"
};

export const MSG_noTimelineEntriesForClient = {
  id: "e4a9e781-c2c1-49e1-9498-b30706593b52",
  defaultMessage: "There are no timeline entries for this client yet.",
};

export const MSG_selectAClientTitle = {
  id: "68b8e974-beb0-4a44-a966-78badcc89624",
  defaultMessage: "Select a Client",
};

export const MSG_pausedUntilDateLabel = {
  id: "09e6773c-7c00-4d9a-b298-95bcbe2de420",
  description: "A label for a date that reminders for a given client are paused until.",
  defaultMessage: "Paused until {date}",
};

export const MSG_reasonInactiveWithColonLabel = {
  id: "5abe38e4-7314-489c-b383-8517922efa14",
  description: "A label for the reason that a record is inactive",
  defaultMessage: "Reason inactive:",
};

export const MSG_reasonLabel = {
  id: "563469ec-a7ed-4936-a844-4688a7720d43",
  description: "The label for where they choose a reason why they are marking a client as being inactive.",
  defaultMessage: "Reason",
};

export const MSG_chooseAReasonTitle = {
  id: "4769b1a6-3f79-402f-8cb0-4a8cbe93774d",
  description: "The label for where they choose a reason why they are marking a client as being inactive.",
  defaultMessage: "Choose a Reason",
};

export const MSG_showMoreContactsPluralizedLabel = {
  id: "56d2aee7-a35f-4bfb-ab0e-fb3290c04829",
  defaultMessage: "{numContacts, plural, one {Show 1 more contact} other {Show # more contacts}}",
};

export const MSG_hideExtraContactsLabel = {
  id: "10b56923-f072-4aae-97ae-9e5585be5df6",
  defaultMessage: "Hide extra contacts",
};

export const MSG_noContactRecordsCreated = {
  id: "eb0b3fc8-c78c-423d-a163-8742fc80107f",
  defaultMessage: "No contact records have been created.",
};

export const MSG_seeAllNPianosLabel = {
  id: "1c9e957c-711e-43cd-b2ee-c2ddb4e70b47",
  description: "A button that shows up when more than 3 piano records exist for this client.  They tap on this and they can view all the pianos.",
  defaultMessage: "See all {count} pianos",
};

export const MSG_noPianoRecordsCreated = {
  id: "aa9a3776-212b-4462-8f49-0a9a0e1a009f",
  defaultMessage: "No piano records have been created.",
};

export const MSG_leaveBlankForResidentialClientsLabel = {
  id: "42449860-3352-4887-9cc7-de5aa77eb46c",
  description: "This is instructions on the Company Name field.  If they leave the company name blank, the client record will be considered a residential client (individual person) instead of a company.",
  defaultMessage: "Leave blank for residential clients",
};

export const MSG_thisClientWasNotReferred = {
  id: "43bd0d4b-cf3d-4125-82b5-38f2df8cdc7f",
  description: "",
  defaultMessage: "This client was not referred by anybody."
};

export const MSG_thisClientHasNotReferredAnybody = {
  id: "977c0710-7565-43a7-a2a5-6872b8c3848c",
  defaultMessage: "This client has not referred anybody.",
};

export const MSG_createYourFirstClient = {
  id: "2c1a6550-d4d4-4e0c-867b-bbfcaded7974",
  defaultMessage: "Create your first client.",
};

export const MSG_outboundMessagesPausedWarning = {
  id: "6ddc433e-7f79-4119-b8ec-667c0ea09002",
  description: "Customers can request that we pause all outbound emails and text messages.  This is an error message that tells the user that future scheduled messages may not be sent if they haven't requested that we remove the pause at the time a given message is supposed to be sent.",
  defaultMessage: "You have requested that we pause all outbound messages.  These messages will not be sent if the pause is still in place when each message is due to be sent.",
};

export const MSG_clientIsPausedMessagesWontBeSentWarning = {
  id: "bc06ef22-8651-4030-9659-fad71587211b",
  description: "Clients can be paused until a particular date and time.  This is an error message that tells the user that future scheduled messages may not be sent if the client is paused at the time a given message is supposed to be sent.",
  defaultMessage: "This client is paused.  These messages will not be sent if the pause is still in place when each message is due to be sent.",
};

export const MSG_clientIsInactiveMessagesWontBeSentWarning = {
  id: "2602eaec-a636-4e9a-92b3-85baa1c37095",
  defaultMessage: "This client has a status set to 'Inactive'.  These messages will not be sent.",
};

export const MSG_clientDoesNotHaveDefaultContactWarning = {
  id: "c17d64d0-02ed-487a-83da-cd018d1182a9",
  defaultMessage: "This client does not have a default contact.  These messages will not be sent unless you add a default contact to the client.",
};

export const MSG_defaultContactDoesntWantEmailWarning = {
  id: "f90c8a01-1e4e-4989-b88b-f85b64cd98bc",
  defaultMessage: "The default contact does not want email messages so these emails will not be sent.",
};

export const MSG_defaultContactHasNoEmailAddressWarning = {
  id: "b3514bdd-2257-4135-9517-220a62d05197",
  defaultMessage: "The default contact does not have an email address so these emails will not be sent.",
};

export const MSG_defaultContactDoesntWantTextsWarning = {
  id: "8e77e6c0-adee-47c8-b35d-d663b92a118a",
  defaultMessage: "The default contact does not want text messages so these texts will not be sent.",
};

export const MSG_defaultContactHasNoConfirmedMobileWarning = {
  id: "b3c5d218-8a99-4460-951e-69d75844c87a",
  defaultMessage: "The default contact does not have a confirmed mobile phone so these text messages will not be sent.",
};

export const MSG_noMessagesScheduledForClient = {
  id: "12123822-e360-483d-aae9-bf1192761221",
  description: "When no messages are scheduled to be sent..",
  defaultMessage: "No messages are scheduled to be sent to this client.",
};

export const MSG_confirmDeleteScheduledMessage = {
  id: "162b026a-18b5-44d6-8213-78f197c64657",
  defaultMessage: "Are you sure you want to delete the message scheduled for {dateTime}?"
};

export const MSG_confirmDeleteComment = {
  id: "7db098b6-68bf-4c4d-a832-c915629963c4",
  defaultMessage: "Are you sure you want to permanently delete this comment?"
};

export const MSG_noAddressesHaveBeenAddedLabel = {
  id: "be85906a-d91e-40ea-94e9-ba604888ce15",
  defaultMessage: "No addresses have been added.",
};

export const MSG_newPhoneAbbreviatedTitle = {
  id: "d0c3356e-8fa6-4c37-90a6-348e227d53a9",
  description: "The button for creating a new phone number.  This would normally say 'New Phone Number', but there isn't enough space for that much text, so in English it has been shortened to 'New Phone'.",
  defaultMessage: "New Phone",
};

export const MSG_newPhoneNumberTitle = {
  id: "3eefce46-d673-4a8d-b3ef-dce5b5fc3a83",
  defaultMessage: "New Phone Number",
};

export const MSG_editPhoneNumberTitle = {
  id: "58556acc-8c6c-4c09-86d7-87c11b5dd158",
  defaultMessage: "Edit Phone Number",
};

export const MSG_noPhoneNumbersHaveBeenAddedLabel = {
  id: "72a64960-f9cd-4ff0-a903-cb2427ccf18b",
  defaultMessage: "No phone numbers have been added.",
};

export const MSG_newEmailAbbreviatedTitle = {
  id: "43830c67-31c1-4bd2-84d5-4f3ab5053520",
  description: "The button for creating a new email address.  This would normally say 'New Email Address' but there isn't enough room for that long of a text.",
  defaultMessage: "New Email",
};

export const MSG_noEmailAddressesAdded = {
  id: "6bfe1bef-a13d-4e1d-95b4-5e2ab09a1154",
  defaultMessage: "No email addresses have been added.",
};

export const MSG_pauseContactTitle = {
  id: "69fa1851-2f7d-4077-b5df-b4cc6edb6ddf",
  description: "A title for a dialog box where they can pause all contact (both email and SMS) to a particular client.",
  defaultMessage: "Pause Contact",
};

export const MSG_pauseAllContactQuestionLabel = {
  id: "89dc8190-ee1b-4313-badb-c9dcf5099093",
  description: "This is a toggle button that lets them pause all email/text communication with a client.",
  defaultMessage: "Pause all contact?",
};

export const MSG_pauseUntilLabel = {
  id: "70b6fd27-f7bb-4648-8a82-4fecc71988e2",
  description: "The label for when they choose a date.  This will pause all emails to a client until this date.",
  defaultMessage: "Pause until",
};

export const MSG_thisWillStopAutomatedMessagesExplanation = {
  id: "badd1d3a-0f7e-4fac-9db6-79bf87a8ea43",
  defaultMessage: "When enabled, this will stop all automated messages from being sent to the client until a specific date.",
};

export const MSG_setReferringClientTitle = {
  id: "54c278d8-972e-45fd-849f-b083c3ac2dbd",
  description: "This button lets them choose which other client referred the current client to them.",
  defaultMessage: "Set Referring Client",
};

export const MSG_removeAllFutureAppointmentsLabel = {
  id: "9f977a07-be73-44ed-829f-bf94bb443463",
  defaultMessage: "Remove all future appointments?",
};

export const MSG_allPianosWillBeInactivatedNotice = {
  id: "8320fe35-48d1-4bbe-865f-8b9e2a6d6273",
  description: "This notice tells them that marking a client (or multiple clients) as inactive will also mark their pianos as inactive.",
  defaultMessage: "Please note: All of their pianos will be marked inactive as well."
};


export const MSG_whyInactiveTitle = {
  id: "3f5cdeb6-1780-4ad5-87ee-a8729cd5848d",
  description: "A very short title for a dialog box for where they enter notes about why they are marking a client as being inactive",
  defaultMessage: "Why Inactive?",
};

export const MSG_clientProspectStatusProgressionExplanation = {
  id: "92c90f4b-6e83-489b-8079-cce04c315233",
  description: "This is a description of the 'prospect' client status.",
  defaultMessage: "When a new client record is created, they are automatically assumed to be a prospect client.  As soon as the first appointment is scheduled, the client status will be changed to 'new'.",
};

export const MSG_clientNewStatusProgressionExplanation = {
  id: "22f584a2-dd39-43c9-a5fc-d137ec8bb0e8",
  description: "This is a description of the 'new' client status.",
  defaultMessage: "A client with a 'new' status has never had a completed appointment.  As soon as the first appointment is completed the status will automatically change to 'active'.",
};

export const MSG_clientActiveStatusExplanation = {
  id: "79d0b939-60b2-4e73-8936-a67fdfdeb353",
  description: "This is a description of the 'active' client status.",
  defaultMessage: "Most clients will be active most of the time.  This means they are a normal client who can get reminders and actively wants your services.",
};

export const MSG_clientInactiveStatusExplanation = {
  id: "53eabab1-92f6-4859-bc13-89076a3c740e",
  description: "This is a description of the 'inactive' client status.",
  defaultMessage: "You may mark a client as inactive to retain record of them, but no longer service their pianos.  No contact will be made with this client.",
};

export const MSG_addressTypeAlreadyExistsExplanation = {
  id: "483ce076-cb6a-4a3d-bb08-79a18afcb194",
  description: "Gazelle allows only one of each type of address (i.e. street, mailing, or billing).  This will read 'A street address has already been added...'",
  defaultMessage: "A {addressType} has already been added for this contact.  This will replace the existing {addressType}.",
};

export const MSG_notAValidEmailAddressLabel = {
  id: "e4f67d9b-66df-478d-902a-ac1cb051811c",
  defaultMessage: "This is not a valid email address.",
};

export const MSG_primaryEmailAddressLabel = {
  id: "a1057770-c342-45e8-b946-5d5d48d04696",
  description: "This is the label for the toggle switch that makes this the primary email address for a contact.",
  defaultMessage: "Primary Email Address",
};

export const MSG_problemCreatingContactRecord = {
  id: "ad72b04f-41c4-43ea-991b-7236bf429343",
  defaultMessage: "There was a problem creating this contact record:",
};

export const MSG_selectAContactTitle = {
  id: "e5cfdab2-7db0-44de-8d23-4ee516e432e8",
  defaultMessage: "Select a Contact",
};

export const MSG_noContactsFoundLabel = {
  id: "43415364-6f35-4a42-8a17-a23de289b5d4",
  description: "When no contacts exist",
  defaultMessage: "No contacts found",
};

export const MSG_viewClientInNewWindow = {
  id: "0ec1f2e6-1835-4f70-80df-3cb0ce583a0b",
  defaultMessage: "View client in a new window",
};

export const MSG_problemSavingTheClient = {
  id: "58835f00-2f24-4376-984c-67c01ed5b92d",
  defaultMessage: "There was a problem saving the client.",
};

export const MSG_unexpectedProblemSavingClientInformation = {
  id: "f1bf55dc-3b7d-4c7e-b0be-14c6ccbc223e",
  defaultMessage: "There was an unexpected problem saving the client information.  Please try again.",
};

export const MSG_clientHasNoContactsWithEmail = {
  id: "b4df9bcb-5793-4ec3-84bc-30e8a44d8b81",
  defaultMessage: "This client has no contacts with email addresses or the contact is currently set to not want emails."
};

export const MSG_clientSearchPlaceholder = {
  id: "6b143d64-665f-45c9-b12a-84c223756869",
  description: "The label on the form element that lets them search for an existing client.",
  defaultMessage: "Find a client by name, phone, email, etc."
};

export const MSG_numClientsMatchFilters = {
  id: "d7dd6582-42d8-443e-acaa-a921971e941b",
  description: "",
  defaultMessage: "{num, plural, one {1 client matches these filters.} other {# clients match these filters.}}"
};

export const MSG_numClientsFoundWithoutFilters = {
  id: "fa2b2d23-433b-40d3-9e7a-5af1005669a7",
  description: "",
  defaultMessage: "{num, plural, one {1 client found.} other {# clients found.}}"
};

export const MSG_showNMoreContacts = {
  id: "adb99cbe-5378-492d-b70e-6fab8441ddfc",
  description: "This is the label that expands the list of contacts if there are more than one.  This shows how many more contacts exist.",
  defaultMessage: "{num, plural, one {Show 1 more contact} other {Show # more contacts}}"
};

export const MSG_showFewerContacts = {
  id: "c3be2d7c-8744-4cf6-ad99-416b0876bf9a",
  description: "The button that hides many of the secondary contacts for a client.",
  defaultMessage: "Show fewer contacts"
};

export const MSG_showCustomFields = {
  id: "fdbd3869-9264-42ce-9484-c48f51e9252e",
  description: "This is the label for the button where they can show undefined custom fields for the client record that they can use to store any information they want.",
  defaultMessage: "Show all custom fields"
};

export const MSG_hideCustomFields = {
  id: "5b7bdb95-47b2-422e-8ef9-b27c3a47000e",
  description: "This is the label for the button where they can hide the custom fields if they don't want to see them.",
  defaultMessage: "Hide custom fields"
};

export const MSG_wantsNoEmail = {
  id: "b0c935cb-bfdf-4d18-b146-feff285d7e6b",
  description: "This is a short indication that the client does not want to receive emails.",
  defaultMessage: "Do Not Email"
};

export const MSG_wantsNoCalls = {
  id: "73c26583-ba67-4ca5-844a-0af62464265d",
  description: "This is a short indication that the client does not want to receive phone calls",
  defaultMessage: "Do Not Call"
};

export const MSG_wantsNoTexts = {
  id: "9b555768-f629-489e-86a8-c3144e47a8ee",
  description: "This is a short indication that the client does not want to receive SMS text messages",
  defaultMessage: "Do Not Text"
};

export const MSG_additionalInfo = {
  id: "7646862d-b8aa-48a5-a642-02ced73b2299",
  description: "The title for a section where we display additional information about the client.  This is for stuff that doesn't really fit in any other section.",
  defaultMessage: "Additional Info"
};

export const MSG_gazelleClientIdLabel = {
  id: "0726c2f3-bd61-4edc-935f-de7eae9d74cb",
  description: "This is used to display the id number for the client.",
  defaultMessage: "Gazelle client ID: {id}"
};

export const MSG_gazellePianoIdLabel = {
  id: "a21a56d7-3661-4018-b7a6-0f282657c36e",
  description: "This is used to display the id number for the piano.",
  defaultMessage: "Gazelle piano ID: {id}"
};

export const MSG_gazelleReferenceIdLabel = {
  id: "24d2c174-2dcd-41ff-8ed0-46bba4a1fe6d",
  description: "This is used to display the reference ID number for the client.  A reference id is used when importing a client from another system, we retain the client ID from that previous system for reference.",
  defaultMessage: "Import reference ID: {id}"
};

export const MSG_createdColonLabel = {
  id: "6fb0068f-1392-47bb-acdd-815879b54867",
  description: "The date a record was created.",
  defaultMessage: "Created: {date}"
};

export const MSG_automatedRemindersExplanation = {
  id: "20ab68f0-237d-417c-8d0d-131b07e4e900",
  defaultMessage: "If you assign this client to an automated reminder Gazelle will send messages depending on whether they have an appointment on the calendar, an unscheduled piano due for service, or other criteria that you can customize in Settings > Reminders."
};

export const MSG_referredByExplanation = {
  id: "7115e889-7ae6-4b66-888b-d84d781aa8f1",
  defaultMessage: "Track your client acquisition by recording how a client found your business and who referred them in the case of word-of-mouth referrals."
};

export const MSG_referredClientsExplanation = {
  id: "8a3d55df-ba81-4722-9c5f-dce3318b23c7",
  defaultMessage: "Track who this client has referred to your business. This field automatically populates when this client is added to the 'Referred By' field on another client's profile."
};

export const MSG_clientNotesExplanation = {
  id: "08c0e294-9452-453b-8e84-f4fe94f91d34",
  defaultMessage: "Track notes about this client in Gazelle. Personal notes can be things like the dogs name, kids names, etc.; client preferences can be anything you want to remember about this client every time you see their client record."
};

export const MSG_clientTypeExplanation = {
  id: "b27d8ef0-6cbd-4abf-8367-91e8def964f0",
  defaultMessage: "Track whether this client is a residential customer, a religious institution, a business, a school or university, etc."
};

export const MSG_preferredTechnicianExplanation = {
  id: "be171ac0-225b-4bbf-81a9-4df3bf48f744",
  defaultMessage: "If you have multiple technicians working in your company you can assign one of them to be the 'Preferred Technician' for this client. Additionally when this client self-schedules you can set your scheduling preferences either use (or ignore) this preferred technician setting."
};

export const MSG_customFieldExplanation = {
  id: "fab698df-eaf2-4eb8-9003-b837506bc09f",
  defaultMessage: "This custom field can be used to track anything relevant to this client record. You can also edit each custom field name in Settings > Company Profile."
};

export const MSG_gazelleIdExplanation = {
  id: "bce65514-6944-4aa1-87e0-e3e3a021386e",
  defaultMessage: "This Gazelle ID is used internally to identify each unique client in your system. You will need this ID to merge duplicate clients, create private scheduling links, and identify customers when you are exporting your data."
};

export const MSG_referenceIdExplanation = {
  id: "b00ade47-9998-4958-8b21-14e5c5319f59",
  defaultMessage: "This Gazelle Reference ID is used by the Gazelle team when importing data to your account from a 3rd party system. If needed, this helps you cross reference pre-existing clients with their corresponding client record in your old system."
};

export const MSG_clientCreateDateExplanation = {
  id: "e53c7059-006e-4a59-bb15-e61ab6f9a09d",
  defaultMessage: "This is the date this client record was first added to your Gazelle account. This could be either the date this client was imported or the date this client was created in Gazelle. In rare cases this could be the create date of an imported client from a previous system (if that data was available during the import process)."
};

export const MSG_clientLocalizationExplanation = {
  id: "5baad885-2152-4c82-a80b-589853857f35",
  defaultMessage: "Use this to specify the language and localization to be used for this client. If this is not set, then your company default localization settings will be used."
};

export const MSG_hasBadAddressLabel = {
  id: "fab1fb26-d557-4ca1-9a8b-0e468024ada5",
  description: "This is the title for a filter where the user can choose to filter for client records with bad addresses.",
  defaultMessage: "Has bad address"
};

export const MSG_doesNotHaveBadAddressLabel = {
  id: "48cf3ea8-9f68-48a9-aece-7b44484572ab",
  description: "This is the title for a filter where the user can choose to filter for client records that do not have bad addresses.",
  defaultMessage: "Does not have bad address"
};

export const MSG_hasTimelineActivityLabel = {
  id: "b8f9a3c6-04af-4f59-84ef-b476517ff56c",
  description: "",
  defaultMessage: "Timeline activity"
};

export const MSG_timelineActivityBeforeLabel = {
  id: "53a925e1-4356-4818-8a98-b499afcf5c8d",
  description: "This is the label for a date selector where they choose a date for a filter.  The filter shows clients who had activity on their timeline before or on this date.",
  defaultMessage: "Timeline activity before (or on)"
};

export const MSG_timelineActivityAfterLabel = {
  id: "046b82a8-5ca8-4a39-9a32-602fcef142ec",
  description: "This is the label for a date selector where they choose a date for a filter.  The filter shows clients who had activity on their timeline after or on this date.",
  defaultMessage: "Timeline activity after (or on)"
};

export const MSG_hasNoTimelineActivityLabel = {
  id: "5d60cf22-6780-4d4e-a8eb-bd9e9abee194",
  description: "",
  defaultMessage: "No timeline activity"
};

export const MSG_noTimelineActivityBeforeLabel = {
  id: "21fe3f58-5775-4401-94fa-2bd75aad6317",
  description: "This is the label for a date selector where they choose a date for a filter.  The filter shows clients who had no activity on their timeline before or on this date.",
  defaultMessage: "No timeline activity before (or on)"
};

export const MSG_noTimelineActivityAfterLabel = {
  id: "17876264-9d37-4fd2-84ba-e99e2134780c",
  description: "This is the label for a date selector where they choose a date for a filter.  The filter shows clients who had no activity on their timeline after or on this date.",
  defaultMessage: "No timeline activity after (or on)"
};

export const MSG_hasAnyAppointmentLabel = {
  id: "7aba19ce-579e-43a3-8e6a-16476dad9690",
  description: "A filter for whether or not a client has any type of appointment within a date range",
  defaultMessage: "Any appointment"
};

export const MSG_hasAnyAppointmentBeforeLabel = {
  id: "e152a34f-6719-41a4-99a0-04c156c19520",
  description: "This is the label for a filter where they can select a date to see if a client has any type of appointment before or on this date.",
  defaultMessage: "Any appointment before (or on)"
};

export const MSG_hasAnyAppointmentAfterLabel = {
  id: "8af1d7fc-46d5-444f-828e-175d664240ef",
  description: "This is the label for a filter where they can select a date to see if a client has any type of appointment after or on this date.",
  defaultMessage: "Appointment after (or on)"
};

export const MSG_hasTuningAppointmentLabel = {
  id: "7d3c3ade-4f7b-4ccb-8f59-649ec8f0dd78",
  description: "A filter for whether or not a client has a tuning appointment within a date range (specifically a tuning appointment, not just any appointment)",
  defaultMessage: "Tuning appointment"
};

export const MSG_hasTuningAppointmentBeforeLabel = {
  id: "ef5f2bf8-7039-492d-b14f-98870c710cd3",
  description: "This is the label for a filter where they can select a date to see if a client has a tuning appointment before or on this date.",
  defaultMessage: "Tuning appointment before (or on)"
};

export const MSG_hasTuningAppointmentAfterLabel = {
  id: "42b170fc-8b5e-482e-b7c5-30f0a25d5848",
  description: "This is the label for a filter where they can select a date to see if a client has a tuning appointment after or on this date.",
  defaultMessage: "Tuning appointment after (or on)"
};

export const MSG_hasPastAppointmentScheduledLabel = {
  id: "7458f793-c491-4c9f-a8e3-cd8e3009e41b",
  description: "",
  defaultMessage: "Has past appointment scheduled"
};

export const MSG_hasNoPastAppointmentScheduledLabel = {
  id: "fbe339f2-007c-452a-827c-bdb5d34f3e29",
  description: "",
  defaultMessage: "Has no past appointment scheduled"
};

export const MSG_hasFutureAppointmentScheduledLabel = {
  id: "39523832-e660-4269-9d0f-32bbc5a0a696",
  description: "",
  defaultMessage: "Has future appointment scheduled"
};

export const MSG_hasNoFutureAppointmentScheduledLabel = {
  id: "4650d689-ab39-4e90-a335-0ed0d0dd36ef",
  description: "",
  defaultMessage: "Has no future appointment scheduled"
};

export const MSG_anyActivePianoLastTuned = {
  id: "88515042-1902-479e-a891-c5ed1ba51a7e",
  description: "A filter for whether or not a client has an active piano that was last tuned within a date range.",
  defaultMessage: "Any active piano last tuned"
};

export const MSG_anyActivePianoLastTunedBeforeLabel = {
  id: "a0050f35-c3df-4aa3-88c0-a15bd390b10d",
  description: "This is the label for a filter where they can select a date to see if a client has a piano that was last tuned before (or on) this date.",
  defaultMessage: "Any active piano last tuned before (or on)"
};

export const MSG_anyActivePianoLastTunedAfterLabel = {
  id: "5a7b83b5-45a6-4d79-972f-6dc87d12db41",
  description: "This is the label for a filter where they can select a date to see if a client has a piano that was last tuned after (or on) this date.",
  defaultMessage: "Any active piano last tuned after (or on)"
};

export const MSG_anyActivePianoNextTuningDueLabel = {
  id: "fa733794-35bf-4239-aaef-c946b33a2f14",
  description: "",
  defaultMessage: "Any active piano next tuning due"
};

export const MSG_anyActivePianoNextTuningDueAfterLabel = {
  id: "840649b4-47ad-43f2-9618-334e18bd8383",
  description: "",
  defaultMessage: "Any active piano next tuning due after (or on)"
};

export const MSG_anyActivePianoNextTuningDueBeforeLabel = {
  id: "bca36ba9-e708-4251-96cc-bc77626e0378",
  description: "",
  defaultMessage: "Any active piano next tuning due before (or on)"
};


export const MSG_allActivePianosLastTunedLabel = {
  id: "d6badd35-a9f5-47e9-bad1-d8c31524a20c",
  description: "A filter for whether or not a client has all active pianos that were last tuned within a date range.",
  defaultMessage: "All active pianos last tuned"
};

export const MSG_allActivePianosLastTunedBeforeLabel = {
  id: "ccd46bb3-0fd4-4824-b113-c60b9f216f7a",
  description: "This is the label for a filter where they can select a date to see if a client has all active pianos that were last tuned before (or on) this date.",
  defaultMessage: "All active pianos last tuned before (or on)"
};

export const MSG_allActivePianosLastTunedAfterLabel = {
  id: "766a3a0b-270b-485a-a080-d20426c11e1d",
  description: "This is the label for a filter where they can select a date to see if a client has all active pianos that were last tuned after (or on) this date.",
  defaultMessage: "All active pianos last tuned after (or on)"
};

export const MSG_allActivePianosNextTuningDueLabel = {
  id: "84b20b24-1f88-42ad-884d-0c126130702c",
  description: "A filter for whether or not a client has all active pianos that have a next tuning due within a date range.",
  defaultMessage: "All active pianos next tuning due"
};

export const MSG_allActivePianosNextTuningDueBeforeLabel = {
  id: "9d9b6046-2089-49f4-be61-cba62a9c66d2",
  description: "This is the label for a filter where they can select a date to see if a client has all active pianos with a next tuning due before (or on) this date.",
  defaultMessage: "All active pianos next tuning due before (or on)"
};

export const MSG_allActivePianosNextTuningDueAfterLabel = {
  id: "bcece344-c720-49d4-ab75-ec845acbee69",
  description: "This is the label for a filter where they can select a date to see if a client has all active pianos with a next tuning due after (or on) this date.",
  defaultMessage: "All active pianos next tuning due after (or on)"
};

export const MSG_activePianoCountLabel = {
  id: "82b7f51b-ff76-401d-b126-1fd48b8d34e1",
  description: "A filter for whether or not a client has a specific number of active pianos.",
  defaultMessage: "Number of active pianos"
};

export const MSG_noContactsHaveEmail = {
  id: "f710d61a-65a3-49f0-935f-ac87e1cc2161",
  description: "The label for a filter where they can find clients where none of the contact records have email addresses.",
  defaultMessage: "No contacts have emails"
};

export const MSG_allContactsHaveEmails = {
  id: "d40416a6-d336-4e30-bf31-de2c19802173",
  description: "The label for a filter where they can find clients where all the contact records have email addresses.",
  defaultMessage: "All contacts have emails"
};

export const MSG_primaryContactHasEmail = {
  id: "db759d96-d20a-4817-8dc1-6b204d471c8c",
  description: "The label for a filter where they can find clients where the primary contact record has an email address.",
  defaultMessage: "Primary contact has an email address"
};

export const MSG_primaryContactHasNoEmail = {
  id: "71cea006-b632-4e14-a43c-c2b9c1c2b092",
  description: "The label for a filter where they can find clients where the primary contact record does not have an email address.",
  defaultMessage: "Primary contact has no email address"
};

export const MSG_billingContactHasEmail = {
  id: "3afa828a-75fd-4043-8ace-602b89e62bca",
  description: "The label for a filter where they can find clients where the billing contact record has an email address.",
  defaultMessage: "Billing contact has an email address"
};

export const MSG_billingContactHasNoEmail = {
  id: "4ceb4cf7-1a8a-4369-b41b-aa80354f5bbd",
  description: "The label for a filter where they can find clients where the billing contact record has an email address.",
  defaultMessage: "Billing contact has no email address"
};

export const MSG_noContactsHavePhone = {
  id: "73935f57-9b3a-4dcd-9e1e-bb1545bc9ed5",
  description: "The label for a filter where they can find clients where none of the contact records have phone number.",
  defaultMessage: "No contacts have a phone number"
};

export const MSG_allContactsHavePhones = {
  id: "e7622369-b4ab-4132-9949-9745316bc659",
  description: "The label for a filter where they can find clients where all the contact records have phone numbers.",
  defaultMessage: "All contacts have a phone number"
};

export const MSG_primaryContactHasPhone = {
  id: "5627a7b3-98d2-4522-b112-71c8ff93f863",
  description: "The label for a filter where they can find clients where the primary contact record has a phone number.",
  defaultMessage: "Primary contact has a phone number"
};

export const MSG_primaryContactHasNoPhone = {
  id: "a3cd4b45-b357-4e25-b5ca-0cc655656196",
  description: "The label for a filter where they can find clients where the primary contact record does not have a phone number.",
  defaultMessage: "Primary contact has no phone number"
};

export const MSG_billingContactHasPhone = {
  id: "67dbfbed-7926-4a84-bc99-2d7324e94881",
  description: "The label for a filter where they can find clients where the billing contact record has a phone number.",
  defaultMessage: "Billing contact has a phone number"
};

export const MSG_billingContactHasNoPhone = {
  id: "c7093698-75d7-4ee6-90c5-274bc6580c28",
  description: "The label for a filter where they can find clients where the billing contact record does not have a phone number.",
  defaultMessage: "Billing contact has no phone number"
};

export const MSG_noContactsHaveTextablePhone = {
  id: "f197fc44-2121-43a5-8df5-3df3291e6220",
  description: "The label for a filter where they can find clients where none of the contact records have phone numbers capable of receiving a text message.",
  defaultMessage: "No contacts have a textable phone number"
};

export const MSG_allContactsHaveTextablePhones = {
  id: "7495e09c-e1ee-4f94-ab6b-5abb5fa9e4a5",
  description: "The label for a filter where they can find clients where all the contact records have phone numbers capable of receiving a text message.",
  defaultMessage: "All contacts have a textable phone number"
};

export const MSG_primaryContactHasTextablePhone = {
  id: "ad31025e-bfe4-41d1-8991-f873f9655345",
  description: "The label for a filter where they can find clients where the primary contact record has a phone number capable of receiving a text message.",
  defaultMessage: "Primary contact has a textable phone number"
};

export const MSG_primaryContactHasNoTextablePhone = {
  id: "23efef40-a55f-4ccf-a59a-15fc7d4d2d35",
  description: "The label for a filter where they can find clients where the primary contact record does not have a phone number capable of receiving a text message.",
  defaultMessage: "Primary contact has no textable phone number"
};

export const MSG_billingContactHasTextablePhone = {
  id: "3cacb42b-3ee4-45f5-8abf-25aca8191ebf",
  description: "The label for a filter where they can find clients where the billing contact record has a phone number capable of receiving a text message.",
  defaultMessage: "Billing contact has a textable phone number"
};

export const MSG_billingContactHasNoTextablePhone = {
  id: "b3f4317a-7ec5-47c0-b546-1a82fb2846b1",
  description: "The label for a filter where they can find clients where the billing contact record does not have a phone number capable of receiving a text message.",
  defaultMessage: "Billing contact has no textable phone number"
};

export const MSG_anyPianoStatusShortLabel = {
  id: "90358a8f-2024-4f30-a1e3-ea334021c465",
  description: "A short label where they can choose to filter clients to those that have at least 1 piano with a status in the selected list.",
  defaultMessage: "Any piano status"
};

export const MSG_anyPianoStatusLabel = {
  id: "27a782d3-78ab-46fc-9590-4605e8a445a5",
  description: "A longer label where they can choose to filter clients to those that have at least 1 piano with a status in the selected list.",
  defaultMessage: "Has at least 1 piano with status"
};

export const MSG_allPianoStatusShortLabel = {
  id: "f78a2da7-bc59-48e3-8c44-d8c80e5dae1e",
  description: "A short label where they can choose to filter clients to those where all the pianos have a status in the selected list.",
  defaultMessage: "All piano statuses"
};

export const MSG_allPianoStatusLabel = {
  id: "b39f17c7-dadb-4111-b274-961c7764f75d",
  description: "A longer label where they can choose to filter clients to those where all the pianos have a status in the selected list.",
  defaultMessage: "All pianos must have these statuses"
};

export const MSG_noMatchingClientsFoundLabel = {
  id: "ea62c284-dd61-4627-8242-12f98386a8b3",
  description: "A message that is displayed when a search for clients that match a string of text has returned no results.",
  defaultMessage: "No matching clients found."
};

export const MSG_noMatchingAddressesFoundLabel = {
  id: "8ce8dd96-47bf-452c-9e95-b7450a1da554",
  description: "A message that is displayed when a search for addresses that match a string of text has returned no results.",
  defaultMessage: "No matching addresses found."
};

export const MSG_clientSelfSchedulerLinkTitle = {
  id: "f2ce74fb-0cc0-4865-ad19-3b62e3234801",
  description: "The label for an action that will copy the client's self scheduler link into the user's clipboard.",
  defaultMessage: "Copy Self Scheduler Link"
};

export const MSG_copiedLinkExplanationClientSelfScheduler = {
  id: "eea5f6f2-b458-47b9-97d2-3be387c43c96",
  defaultMessage: "A link to this client's self scheduler has been copied.  You can share it with your client."
};

export const MSG_clientsNotTuned24Months = {
  id: "c48df3a5-cc67-42e9-813d-0030ad2d6124",
  description: "A short sentence that indicates clicking on it will show you clients that have not had pianos tuned within the last 24 months.  This sentence should be as short as possible.",
  defaultMessage: "Clients not tuned for 24 months."
};

export const MSG_clientsNotTuned36Months = {
  id: "473b5bc4-78da-41c9-823b-a77418905413",
  description: "A short sentence that indicates clicking on it will show you clients that have not had pianos tuned within the last 36 months.  This sentence should be as short as possible.",
  defaultMessage: "Clients not tuned for 36 months."
};

export const MSG_clientsNotTuned60Months = {
  id: "5acef603-f740-4f4b-a391-8c4b8beb0ea3",
  description: "A short sentence that indicates clicking on it will show you clients that have not had pianos tuned within the last 60 months.  This sentence should be as short as possible.",
  defaultMessage: "Clients not tuned for 60 months."
};

export const MSG_addATagToClientLabel = {
  id: "91741366-b148-47a7-a9a7-4b9f03eadda8",
  description: "A button that allows the user to add a tag to a client record.",
  defaultMessage: "Enter a tag for this client"
};

export const MSG_anyOfTheseReferralTypesLabel = {
  id: "f0c1f3d9-7a6f-4f2f-8a6a-3e2f5b7f5c4d",
  description: "This is a filter that lets the user filter clients by any of a set of referral types (website, internet search, etc).",
  defaultMessage: "Any of these referral types"
};

export const MSG_primaryContactsWantsEmail = {
  id: "16eda8c3-c935-4422-9b78-6cd2bf2beb1d",
  description: "This is a filter that lets the user filter clients by whether the primary contact wants to receive emails.",
  defaultMessage: "Primary contact wants email"
};

export const MSG_primaryContactsDoesntWantEmail = {
  id: "c28574d8-72b0-4710-b9bb-a5250580a259",
  description: "This is a filter that lets the user filter clients by whether the primary contact does not want to receive emails.",
  defaultMessage: "Primary contact does not want email"
};

export const MSG_primaryContactsWantsSMS = {
  id: "fd378e90-83e0-4433-91b1-f0e8824045e6",
  description: "This is a filter that lets the user filter clients by whether the primary contact wants to receive SMS.",
  defaultMessage: "Primary contact wants SMS"
};

export const MSG_primaryContactsDoesntWantSMS = {
  id: "111d4a43-b98e-4220-a0c8-ad2998d2ca9b",
  description: "This is a filter that lets the user filter clients by whether the primary contact does not want to receive SMS.",
  defaultMessage: "Primary contact does not want SMS"
};

export const MSG_primaryContactsWantsPhoneCalls = {
  id: "bd79795e-6e83-4dac-817b-400335d7d43c",
  description: "This is a filter that lets the user filter clients by whether the primary contact wants to receive phone calls.",
  defaultMessage: "Primary contact wants phone calls"
};

export const MSG_primaryContactsDoesntWantPhoneCalls = {
  id: "05604b73-604a-44b5-9dc2-4db0d28d7d9c",
  description: "This is a filter that lets the user filter clients by whether the primary contact does not want to receive phone calls.",
  defaultMessage: "Primary contact does not want phone calls"
};

export const MSG_noPreferredTechnicianLabel = {
  id: "0fee5887-87ec-48c7-bafc-9194bf15df99",
  description: "This is a filter that lets the user filter clients by whether they have a preferred technician set.",
  defaultMessage: "No preferred technician"
};
