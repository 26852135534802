import * as React from 'react';
import { Badge, Button } from 'reactstrap';
import { observer } from 'mobx-react';
import { scrollTop, SimpleFormat, StepContent, StepFooter, StickyNote } from '@core';

import { rootStoreInstance } from "@client_scheduler/modules/root";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from "@fortawesome/pro-solid-svg-icons";
import { formatMessage } from "@gazelle/shared/utils";
import {
  MSG_dateWithColonLabel,
  MSG_durationWithColonLabel,
  MSG_pendingApprovalTitle,
  MSG_returnToWebsiteTitle,
  MSG_reviewingYourReservation,
  MSG_statusWithColonLabel,
  MSG_thankYou,
  MSG_timeWithColonLabel,
  MSG_withWithColonLabel,
  MSG_yourReservationDetailsTitle
} from "@gazelle/shared/strings";
import { GazelleDate, GazelleDuration, GazelleTime } from "@gazelle/shared/components/GazelleDateTime";

interface IStep6FinishedProps {}

const Step6Finished = observer(class Step6Finished extends React.Component<IStep6FinishedProps> {
  componentDidMount() {
    scrollTop();
  }

  render() {
    return (
      <div>
        <StepContent className="step6-finished-container" minHeight={rootStoreInstance.minHeight}>
          <h4 className="mt-3">{formatMessage(MSG_thankYou)}</h4>
          <div className="message">{formatMessage(MSG_reviewingYourReservation)}</div>

          <div className="appointment">
            <div className="left-icon">
              <FontAwesomeIcon icon={faCalendarAlt}/>
            </div>
            <div className="right-content">
              <h5>{formatMessage(MSG_yourReservationDetailsTitle)}</h5>
              <div>
                <table><tbody>
                  <tr>
                    <td>{formatMessage(MSG_dateWithColonLabel)}</td>
                    <td><GazelleDate moment={rootStoreInstance.searchStore.selectedSlot.startsAt} weekday /></td>
                  </tr>
                  <tr>
                    <td>{formatMessage(MSG_timeWithColonLabel)}</td>
                    <td><GazelleTime moment={rootStoreInstance.searchStore.selectedSlot.startsAt} /></td>
                  </tr>
                  <tr>
                    <td>{formatMessage(MSG_durationWithColonLabel)}</td>
                    <td><GazelleDuration minutes={rootStoreInstance.pianoStore.totalDuration} /></td>
                  </tr>
                  <tr>
                    <td>{formatMessage(MSG_withWithColonLabel)}</td>
                    <td>{rootStoreInstance.searchStore.selectedSlot.user.fullName}</td>
                  </tr>
                  <tr>
                    <td>{formatMessage(MSG_statusWithColonLabel)}</td>
                    <td>
                      <Badge pill color="warning">{formatMessage(MSG_pendingApprovalTitle)}</Badge>
                    </td>
                  </tr>
                </tbody></table>
              </div>
            </div>
          </div>

          {rootStoreInstance.companySettings.completionMessage &&
            <StickyNote className="mt-5">
              <SimpleFormat text={rootStoreInstance.companySettings.completionMessage}/>
            </StickyNote>}


          {rootStoreInstance.companyWebsite &&
            <div className="mt-5 text-center">
              <Button onClick={() => location.href = rootStoreInstance.companyWebsite} color="success">
                {formatMessage(MSG_returnToWebsiteTitle)}
              </Button>
            </div>}

        </StepContent>
        <StepFooter rootStore={rootStoreInstance}/>
      </div>
    );
  }
});

export { Step6Finished };
