import { MASTER_SERVICE_ITEM_TYPE } from "../enums";
import { formatMessage } from "./intl";
import {
  MSG_mslTypeExpense,
  MSG_mslTypeLaborFixedRate,
  MSG_mslTypeLaborHourly,
  MSG_mslTypeMileage,
  MSG_otherTitle
} from "../strings";

// Use this if you want to iterate over all the types (e.g. for a <select>)
const SortedMasterServiceItemTypes = [
  MASTER_SERVICE_ITEM_TYPE.LABOR_FIXED_RATE,
  MASTER_SERVICE_ITEM_TYPE.LABOR_HOURLY,
  MASTER_SERVICE_ITEM_TYPE.EXPENSE,
  MASTER_SERVICE_ITEM_TYPE.MILEAGE,
  MASTER_SERVICE_ITEM_TYPE.OTHER,

];

const MasterServiceItemTypeInfo: {[key: string]: {type: MASTER_SERVICE_ITEM_TYPE, getLabel: () => string}} = {
  [MASTER_SERVICE_ITEM_TYPE.LABOR_FIXED_RATE]: {
    type: MASTER_SERVICE_ITEM_TYPE.LABOR_FIXED_RATE,
    getLabel: () => formatMessage(MSG_mslTypeLaborFixedRate),
  },
  [MASTER_SERVICE_ITEM_TYPE.LABOR_HOURLY]: {
    type: MASTER_SERVICE_ITEM_TYPE.LABOR_HOURLY,
    getLabel: () => formatMessage(MSG_mslTypeLaborHourly),
  },
  [MASTER_SERVICE_ITEM_TYPE.EXPENSE]: {
    type: MASTER_SERVICE_ITEM_TYPE.EXPENSE,
    getLabel: () => formatMessage(MSG_mslTypeExpense),
  },
  [MASTER_SERVICE_ITEM_TYPE.MILEAGE]: {
    type: MASTER_SERVICE_ITEM_TYPE.MILEAGE,
    getLabel: () => formatMessage(MSG_mslTypeMileage),
  },
  [MASTER_SERVICE_ITEM_TYPE.OTHER]: {
    type: MASTER_SERVICE_ITEM_TYPE.OTHER,
    getLabel: () => formatMessage(MSG_otherTitle),
  },
};

export { MasterServiceItemTypeInfo, SortedMasterServiceItemTypes };
