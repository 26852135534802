function nullIfUndefined(val: any) {
  if (typeof val === 'undefined') return null;
  return val;
}

function isNullOrUndefined(val: any) {
  return val === null || typeof val === 'undefined';
}

export { nullIfUndefined, isNullOrUndefined };
