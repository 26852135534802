/**
 * Ideally this would be in a RootStore, but public has a different structure
 * for RootStore that is not compatible.  So in order for the code to be compatible
 * with public, we globally register currency separately.
 */
import { Currency } from "../models";

let currency: Currency = null;
function getGlobalCurrency(): Currency {
  // For some yet to be determined reason, sometimes the globalCurrency isn't set when this function gets called.  Rather than
  // outright failing, use USD for now knowing that the globalCurrency will eventually get set.
  if (!currency) {
    return {
      code: "USD",
      decimalDigits: 2,
      divisor: 100,
      label: "USD - $",
      symbol: "$",
    };
  }
  return currency;
}
function registerGlobalCurrency(l: Currency) { currency = l; }

export { getGlobalCurrency, registerGlobalCurrency };
