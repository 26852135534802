import { action, autorun, computed, makeObservable, observable } from 'mobx';
import * as moment from 'moment-timezone';
import { filter as _filter } from 'lodash';
import { RootStore } from "@client_scheduler/modules/root";
import { User } from '@core';

class PrefStore {
  private rootStore: RootStore;
  targetDate: moment.Moment = null;
  isAnyUserSelected: boolean = null;
  selectedUsers: User[] = null;
  clientPreferredTechnicianId: string = null;

  constructor() {
    this.selectedUsers = [];
    makeObservable(this, {
      targetDate: observable,
      isAnyUserSelected: observable,
      selectedUsers: observable,
      clientPreferredTechnicianId: observable,
      initializeData: action,
      setTargetDate: action,
      availableUsers: computed,
      setPreferredUser: action,
      isComplete: computed,
      computedUserIds: computed
    });
  }

  initializeData(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.targetDate = moment();

    autorun(() => {
      // This conditional is kind of bogus.  I'm just including all the
      // observables that we want to monitor.  If any of those values change,
      // then this autorun will be executed.
      if (this.rootStore.pianoStore.numServices ||
          this.rootStore.pianoStore.totalDuration ||
          this.availableUsers)
      {
        /* no-op */
      }

      this.rootStore.lockStep(3);
    });
  }

  setTargetDate(date: moment.Moment) {
    if (this.rootStore) {
      this.rootStore.lockStep(4);
    }

    this.targetDate = date;
  }

  get availableUsers(): User[] {
    let users: User[] = this.rootStore.users.slice();
    this.rootStore.pianoStore.selectedPianoWrappers.forEach(pianoWrapper => {
      pianoWrapper.services.forEach(serviceId => {
        let service = this.rootStore.masterServiceItems.get(serviceId);
        if (!service.isAnyUser) {
          let userIds = service.allUsers.map(user => user.id);
          users = _filter(users, user => userIds.indexOf(user.id) >= 0);
        }
      });
    });
    return users;
  }

  setPreferredUser(user: User|null, isChecked: boolean) {
    if (this.rootStore) {
      this.rootStore.lockStep(4);
    }

    if (user) {
      this.isAnyUserSelected = false;
      let idx = this.selectedUsers.indexOf(user);
      if (isChecked) {
        if (idx < 0) {
          this.selectedUsers.push(user);
        }
      } else {
        if (idx >= 0) {
          this.selectedUsers.splice(idx, 1);
        }
      }
    } else {
      this.isAnyUserSelected = isChecked;
      this.selectedUsers.length = 0;
    }

    if (!this.isAnyUserSelected && !this.selectedUsers.length) {
      this.isAnyUserSelected = true;
    }
  }

  setClientPreferredTechnicianId(id: string) {
    this.clientPreferredTechnicianId = id;
  }

  get isComplete(): boolean {
    return this.targetDate && (this.isAnyUserSelected || this.selectedUsers.length > 0);
  }

  get computedUserIds(): string[] {
    if (this.isAnyUserSelected) {
      return this.availableUsers.map(user => user.id);
    } else {
      return this.selectedUsers.map(user => user.id);
    }
  }
}

export { PrefStore };
