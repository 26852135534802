import * as React from 'react';
import { Privacy } from '@core';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretLeft } from "@fortawesome/pro-solid-svg-icons";
import {
  MSG_contactDetailsTitle,
  MSG_durationWithValueLabel,
  MSG_finishedTitle,
  MSG_numPianosValues,
  MSG_numServicesValues,
  MSG_pianosAndServicesTitle,
  MSG_preferencesTitle,
  MSG_selectAppointmentTitle,
  MSG_targetWithValueLabel,
  MSG_withAnyTechnicianLabel,
  MSG_withSpecificTechniciansLabel,
  MSG_withTechnicianLabel,
  MSG_yourStreetAddressTitle
} from "@gazelle/shared/strings";
import { formatDate, formatDuration, formatMessage } from "@gazelle/shared/utils";
import { rootStoreInstance } from "@client_scheduler/modules/root";
import { GazelleDateTime } from "@gazelle/shared/components/GazelleDateTime";

/************************************************************************************************
 * NavLink
 ************************************************************************************************/
interface INavLinkProps {
  step: number,
  onClick?: (event?: any) => void,
  children: any,
}

const NavLink = observer(class NavLink extends React.Component<INavLinkProps> {
  handleClick = (event: any) => {
    const { step, onClick} = this.props;

    if (rootStoreInstance.isComplete) return;
    if (!rootStoreInstance.isStepUnlocked[step]) return;

    if (rootStoreInstance.setCurrentStep(step)) {
      if (onClick) {
        onClick(event);
      }
    }
  };

  render() {
    return (
      <div onClick={this.handleClick}>
        {this.props.children}
      </div>
    );
  }
});

/************************************************************************************************
 * NavigationSteps
 ************************************************************************************************/
interface INavigationStepsProps {
  onClick?: (event?: any) => any,
}
interface INavigationStepsState {}

const NavigationSteps = observer(
  class NavigationSteps extends React.Component<INavigationStepsProps, INavigationStepsState> {
    navRef: Element;

    componentDidMount() {
      rootStoreInstance.setMinHeight(this.navRef.clientHeight);
    }

    componentDidUpdate() {
      rootStoreInstance.setMinHeight(this.navRef.clientHeight);
    }

    render() {
      const { onClick } = this.props;
      const rootStore = rootStoreInstance;
      const { addressComponents } = rootStoreInstance.addressStore;
      const { selectedPianoWrappers, totalDuration, numServices } = rootStoreInstance.pianoStore;

      return (
        <div className="navigation-steps" ref={ref => this.navRef = ref}>

          <NavLink step={1} onClick={onClick}>
            <div className={`step-wrapper ${(rootStore.isComplete || !rootStore.isStepUnlocked[1]) && 'disabled'} ${rootStore.currentStep === 1 && 'active'}`}>
              <div className="number">1</div>
              <div className="arrow"><FontAwesomeIcon icon={faCaretLeft} /></div>
              <div className="label">
                {formatMessage(MSG_yourStreetAddressTitle)}
              </div>

              {rootStore.isStepUnlocked[2] && addressComponents && (
                <div className="content">
                  {addressComponents.address1}<br/>
                  {addressComponents.city}, {addressComponents.state} {addressComponents.zip}
                </div>
              )}
            </div>
          </NavLink>

          <NavLink step={2} onClick={onClick}>
            <div className={`step-wrapper ${(rootStore.isComplete || !rootStore.isStepUnlocked[2]) && 'disabled'} ${rootStore.currentStep === 2 && 'active'}`}>
              <div className="number">2</div>
              <div className="arrow"><FontAwesomeIcon icon={faCaretLeft} /></div>
              <div className="label">{formatMessage(MSG_pianosAndServicesTitle)}</div>

              {(rootStore.isStepUnlocked[3] || numServices > 0) && (
                <div className="content">
                  {formatMessage(MSG_numPianosValues, {numPianos: selectedPianoWrappers.length})},{' '}
                  {formatMessage(MSG_numServicesValues, {numServices: numServices})}
                  <br/>
                  {formatMessage(MSG_durationWithValueLabel, {duration: formatDuration(totalDuration)})}
                </div>
              )}
            </div>
          </NavLink>

          <NavLink step={3} onClick={onClick}>
            <div className={`step-wrapper ${(rootStore.isComplete || !rootStore.isStepUnlocked[3]) && 'disabled'} ${rootStore.currentStep === 3 && 'active'}`}>
              <div className="number">3</div>
              <div className="arrow"><FontAwesomeIcon icon={faCaretLeft} /></div>
              <div className="label">{formatMessage(MSG_preferencesTitle)}</div>

              {rootStore.isStepUnlocked[4] &&
                <div className="content">
                  {formatMessage(MSG_targetWithValueLabel, {date: formatDate(rootStore.prefStore.targetDate)})}
                  {rootStore.users.length > 1 && (
                    <React.Fragment>
                      <br/>
                      {rootStore.prefStore.isAnyUserSelected
                        ? formatMessage(MSG_withAnyTechnicianLabel)
                        : formatMessage(MSG_withTechnicianLabel, {name: rootStore.prefStore.selectedUsers[0].firstName})
                          + (rootStore.prefStore.selectedUsers.length > 1 ? ' ' + formatMessage(MSG_withSpecificTechniciansLabel, {numOtherTechs: rootStore.prefStore.selectedUsers.length - 1}) : '')}
                    </React.Fragment>
                  )}
                </div>}
            </div>
          </NavLink>

          <NavLink step={4} onClick={onClick}>
            <div className={`step-wrapper ${(rootStore.isComplete || !rootStore.isStepUnlocked[4]) && 'disabled'} ${rootStore.currentStep === 4 && 'active'}`}>
              <div className="number">4</div>
              <div className="arrow"><FontAwesomeIcon icon={faCaretLeft} /></div>
              <div className="label">{formatMessage(MSG_selectAppointmentTitle)}</div>

              {rootStore.isStepUnlocked[5] &&
                <div className="content">
                  <GazelleDateTime moment={rootStore.searchStore.selectedSlot.startsAt} />
                  <br/>
                  {formatMessage(MSG_withTechnicianLabel, {name: rootStore.searchStore.selectedSlot.user.fullName})}
                </div>}
            </div>
          </NavLink>

          <NavLink step={5} onClick={onClick}>
            <div className={`step-wrapper ${(rootStore.isComplete || !rootStore.isStepUnlocked[5]) && 'disabled'} ${rootStore.currentStep === 5 && 'active'}`}>
              <div className="number">5</div>
              <div className="arrow"><FontAwesomeIcon icon={faCaretLeft} /></div>
              <div className="label">{formatMessage(MSG_contactDetailsTitle)}</div>
            </div>
          </NavLink>

          <NavLink step={6} onClick={onClick}>
            <div className={`step-wrapper ${!rootStore.isStepUnlocked[6] && 'disabled'} ${rootStore.currentStep === 6 && 'active'}`}>
              <div className="number">6</div>
              <div className="arrow"><FontAwesomeIcon icon={faCaretLeft} /></div>
              <div className="label">{formatMessage(MSG_finishedTitle)}</div>
            </div>
          </NavLink>


          <Privacy gdpr={rootStore.isGdprRequired} style={{marginTop: "2rem"}}/>
        </div>
      );
    }
  }
);

export { NavigationSteps };
