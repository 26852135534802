import * as React from 'react';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import {
  actionLogger,
  FormGroupWithErrors,
  GenericFormErrors,
  MutationFieldError,
  scrollTop,
  Spinner,
  StepContent,
  StepFooter
} from "@core";

import {
  MSG_contactFirstNameLabel,
  MSG_contactLastNameLabel,
  MSG_emailAddressLabel,
  MSG_iWouldLikeSmsReminders,
  MSG_notesOrSpecialRequestsLabel,
  MSG_phoneNumberLabel,
  MSG_phoneTypeLabel,
  MSG_primaryContactInfoTitle,
  MSG_privacyPolicyTitle,
  MSG_reserveMyAppointmentTitle,
  MSG_smsOptInFinePrint,
  MSG_termsTitle
} from "@gazelle/shared/strings";
import { formatMessage, PhoneTypeInfo, SortedPhoneTypes } from "@gazelle/shared/utils";
import { rootStoreInstance } from '@client_scheduler/modules/root';

interface IStep5ContactProps {}
interface IState {
  isForwarding: boolean;
}

const Step5Contact = observer(class Step5Contact extends React.Component<IStep5ContactProps, IState> {
  mutationErrors: MutationFieldError[] = null;

  constructor(props: IStep5ContactProps) {
    super(props);

    makeObservable(this, {
      mutationErrors: observable
    });

    this.state = {isForwarding: false};
  }

  componentDidMount() {
    scrollTop();
  }

  handleReserveButtonClicked = () => {
    void rootStoreInstance.createReservation()
      .then(errors => {
        if (errors) {
          scrollTop();
          this.mutationErrors = errors;
        } else {
          if (rootStoreInstance.companySettings.completionRedirectUrl) {
            this.setState({isForwarding: true}, () => {
              void actionLogger.debug('success:redirecting', {redirectUrl: rootStoreInstance.companySettings.completionRedirectUrl});
              setTimeout(() => {
                this.setState({isForwarding: false},
                  () => window.location.href = rootStoreInstance.companySettings.completionRedirectUrl
                );
              }, 1000);
            });
          } else {
            scrollTop();
            rootStoreInstance.isComplete = true;
            rootStoreInstance.unlockStep(6);
            rootStoreInstance.setCurrentStep(6);
          }
        }
      });
  };

  render() {
    const { contactStore, contactStore: { contactInfo }} = rootStoreInstance;

    return (
      <div>
        <StepContent className="step5-contact-container" minHeight={rootStoreInstance.minHeight}>
          <h4>{formatMessage(MSG_primaryContactInfoTitle)}</h4>

          <GenericFormErrors
            mutationErrors={this.mutationErrors}
            showUnhandledKeys={true}
            handledKeys={['firstName', 'lastName', 'phoneNumber', 'phoneType', 'email', 'notes']} />

          <Row>
            <Col xs={12} md={6}>
              <FormGroupWithErrors
                mutationErrors={this.mutationErrors}
                mutationKey="firstName">
                <Label>{formatMessage(MSG_contactFirstNameLabel)}</Label>
                <Input
                  name="firstName"
                  autoFocus
                  disabled={rootStoreInstance.isReserving}
                  value={contactInfo.firstName}
                  onChange={e => contactInfo.firstName = e.target.value} />
              </FormGroupWithErrors>
            </Col>
            <Col xs={12} md={6}>
              <FormGroupWithErrors
                mutationErrors={this.mutationErrors}
                mutationKey="lastName">
                <Label>{formatMessage(MSG_contactLastNameLabel)}</Label>
                <Input
                  name="lastName"
                  value={contactInfo.lastName}
                  disabled={rootStoreInstance.isReserving}
                  onChange={e => contactInfo.lastName = e.target.value} />
              </FormGroupWithErrors>
            </Col>
          </Row>

          <Row>
            <Col xs={7} lg={5} xl={4}>
              <FormGroupWithErrors
                mutationErrors={this.mutationErrors}
                mutationKey="phoneNumber">
                <Label>{formatMessage(MSG_phoneNumberLabel)}</Label>
                <Input
                  name="phoneNumber"
                  value={contactInfo.phoneNumber}
                  disabled={rootStoreInstance.isReserving}
                  onChange={e => contactInfo.phoneNumber = e.target.value} />
              </FormGroupWithErrors>
            </Col>
            <Col xs={5} lg={3}>
              <FormGroupWithErrors
                mutationErrors={this.mutationErrors}
                mutationKey="phoneType">
                <Label>{formatMessage(MSG_phoneTypeLabel)}</Label>
                <Input
                  name="phoneType"
                  type="select"
                  disabled={rootStoreInstance.isReserving}
                  value={contactInfo.phoneType}
                  onChange={e => contactInfo.phoneType = e.target.value}>
                  {SortedPhoneTypes.map(type =>
                    <option key={type} value={type}>{PhoneTypeInfo[type].getLabel()}</option>
                  )}
                </Input>
              </FormGroupWithErrors>
            </Col>
          </Row>
          {rootStoreInstance.companySettings.smsEnabled && (
            <React.Fragment>
              <Row>
                <Col>
                  <FormGroup check>
                    <Label check for={"smsOptIn"}>
                      {formatMessage(MSG_iWouldLikeSmsReminders)} &dagger;
                    </Label>
                    <Input
                      id={"smsOptIn"}
                      name={"smsOptIn"}
                      disabled={rootStoreInstance.isReserving}
                      checked={!!contactInfo.smsOptIn}
                      onChange={() => {
                        contactInfo.smsOptIn = !contactInfo.smsOptIn;
                      }}
                      type={"checkbox"}/>
                  </FormGroup>
                </Col>
              </Row>
              <div className="mt-4" />
            </React.Fragment>
          )}

          <Row>
            <Col xs={12} lg={9} xl={8}>
              <FormGroupWithErrors
                mutationErrors={this.mutationErrors}
                mutationKey="email">
                <Label>{formatMessage(MSG_emailAddressLabel)}</Label>
                <Input
                  name="email"
                  value={contactInfo.email}
                  disabled={rootStoreInstance.isReserving}
                  onChange={e => contactInfo.email = e.target.value} />
              </FormGroupWithErrors>
            </Col>
          </Row>

          <div className="mt-4" />

          <FormGroupWithErrors
            mutationErrors={this.mutationErrors}
            mutationKey="notes">
            <Label>{formatMessage(MSG_notesOrSpecialRequestsLabel)}</Label>
            <Input
              name="notes"
              type="textarea"
              disabled={rootStoreInstance.isReserving}
              style={{minHeight: '8rem'}}
              value={contactStore.notes}
              onChange={e => contactStore.notes = e.target.value} />
          </FormGroupWithErrors>

          <div className="mt-4 text-small text-muted">
            {rootStoreInstance.companySettings.smsEnabled &&
              <div className="me-2">
                &dagger; {formatMessage(MSG_smsOptInFinePrint, {phoneNumber: rootStoreInstance.companyPhoneNumber})}
              </div>}
            {rootStoreInstance.privacyPolicy.defaultValue &&
              <span className="me-2"><a href="#" onClick={() => window.alert(rootStoreInstance.privacyPolicy.defaultValue)}>{formatMessage(MSG_privacyPolicyTitle)}</a></span>}
            {rootStoreInstance.termsOfService.defaultValue &&
              <span className="me-2"><a href="#" onClick={() => window.alert(rootStoreInstance.termsOfService.defaultValue)}>{formatMessage(MSG_termsTitle)}</a></span>}
          </div>
        </StepContent>
        <StepFooter
          rootStore={rootStoreInstance}
          rightSide={
            <div>
              {(rootStoreInstance.isReserving || this.state.isForwarding) && <Spinner/>}
              {' '}
              <Button size="lg"
                      id="reserve-button"
                      className="d-none d-md-inline-block"
                      color="primary"
                      disabled={rootStoreInstance.isReserving || !rootStoreInstance.isStepComplete[5] || this.state.isForwarding}
                      onClick={this.handleReserveButtonClicked}>
                {formatMessage(MSG_reserveMyAppointmentTitle)}
              </Button>
              <Button className="d-md-none"
                      id="reserve-button"
                      color="primary"
                      disabled={rootStoreInstance.isReserving || !rootStoreInstance.isStepComplete[5] || this.state.isForwarding}
                      onClick={this.handleReserveButtonClicked}>
                {formatMessage(MSG_reserveMyAppointmentTitle)}
              </Button>
            </div>
          }/>
      </div>
    );
  }
});

export { Step5Contact };
