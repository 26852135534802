import { PIANO_STATUS } from "../enums";
import { formatMessage } from "./intl";
import { MSG_activeTitle, MSG_inactiveTitle, MSG_temporaryStorageTitle, MSG_underRestorationTitle } from "../strings";

// Use this if you want to iterate over all the status (e.g. for a <select>)
const SortedPianoStatuses = [
  PIANO_STATUS.ACTIVE,
  PIANO_STATUS.INACTIVE,
  PIANO_STATUS.TEMPORARY_STORAGE,
  PIANO_STATUS.UNDER_RESTORATION,
];

const PianoStatusInfo: {[key: string]: {type: PIANO_STATUS, getLabel: () => string, badgeColor: string}} = {
  [PIANO_STATUS.ACTIVE]: {
    type: PIANO_STATUS.ACTIVE,
    getLabel: () => formatMessage(MSG_activeTitle),
    badgeColor: 'success',
  },
  [PIANO_STATUS.INACTIVE]: {
    type: PIANO_STATUS.INACTIVE,
    getLabel: () => formatMessage(MSG_inactiveTitle),
    badgeColor: 'danger',
  },
  [PIANO_STATUS.TEMPORARY_STORAGE]: {
    type: PIANO_STATUS.TEMPORARY_STORAGE,
    getLabel: () => formatMessage(MSG_temporaryStorageTitle),
    badgeColor: 'warning',
  },
  [PIANO_STATUS.UNDER_RESTORATION]: {
    type: PIANO_STATUS.UNDER_RESTORATION,
    getLabel: () => formatMessage(MSG_underRestorationTitle),
    badgeColor: 'warning',
  },
};

export { SortedPianoStatuses, PianoStatusInfo };
