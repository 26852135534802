import gql from "graphql-tag";
import { CoreCurrencyFragment } from "../core/currency";

export const PrivateDashboardMetricsLiveDataQuery = gql`
  query ($startOn: CoreDate!, $endOn: CoreDate!, $userId: String) {
    dashboardMetrics {
      id live(userId: $userId) {
        id
        invoicedToday {id currency {...CoreCurrencyFragment} value}
        invoicesDue {id currency {...CoreCurrencyFragment} value}
        paymentsToday {id currency {...CoreCurrencyFragment} value}
      }
      historicalAllTime {
        id clientLifetimeValue {id currency {...CoreCurrencyFragment} value}
      }
      historicalTimeframe(startOn: $startOn, endOn: $endOn, userId: $userId) {
        id pianosDue {
          id date count scheduledCount
        }
      }
    }
  }
  ${CoreCurrencyFragment}
`;

export const PrivateDashboardMetricsTimeframeSummaryQuery = gql`
  query ($startOn: CoreDate!, $endOn: CoreDate!, $prevStartOn: CoreDate!, $prevEndOn: CoreDate!, $userId: String) {
    dashboardMetrics {
      id
      previousTimeframe: historicalTimeframe(startOn: $prevStartOn, endOn: $prevEndOn, userId: $userId) {
        id
        clientsCreated {
          id totalCount
        }
        appointments {
          id totalCount
        }
      }
      historicalTimeframe(startOn: $startOn, endOn: $endOn, userId: $userId) {
        id
        appointments {
          id clientsScheduledCount totalCount noShowCount pianosServicedCount
          pianoTypesServicedCounts {id type count}
        }
        clientsCreated {
          id totalCount currentlyActive currentlyInactive currentlyNew currentlyProspect
        }
        estimatesCreated {
          id totalCount convertedCount currentlyUnexpiredCount
          convertedInvoiceTotal {id currency {...CoreCurrencyFragment} value}
          estimatedTotal {id currency {...CoreCurrencyFragment} value}
        }
        invoicesCreated {
          id totalCount invoicesQuickbooksSyncedCount paymentsCount
          currentlyDueTotal {id currency {...CoreCurrencyFragment} value}
          invoicedTotal {id currency {...CoreCurrencyFragment} value}
          paymentsTotal {id currency {...CoreCurrencyFragment} value}
        }
        pianosCreated {
          id averageAge totalCount currentlyActive currentlyInTemporaryStorage currentlyInactive currentlyUnderRestoration
          types {id type count}
        }
        reminders {
          id emailCount phoneCount smsCount
        }
      }
    }
  }
  ${CoreCurrencyFragment}
`;

export const PrivateDashboardMetricsAppointmentsTimeframeDataQuery = gql`
  query ($startOn: CoreDate!, $endOn: CoreDate!, $userId: String) {
    dashboardMetrics {
      id historicalTimeframe(startOn: $startOn, endOn: $endOn, userId: $userId) {
        id appointments {
          id timeframeData {id date count}
        }
      }
    }
  }
`;

export const PrivateDashboardMetricsClientsCreatedTimeframeDataQuery = gql`
  query ($startOn: CoreDate!, $endOn: CoreDate!, $userId: String) {
    dashboardMetrics {
      id historicalTimeframe(startOn: $startOn, endOn: $endOn, userId: $userId) {
        id clientsCreated {
          id timeframeData {id date count}
        }
      }
    }
  }
`;

export const PrivateDashboardMetricsEstimatesCreatedTimeframeDataQuery = gql`
  query ($startOn: CoreDate!, $endOn: CoreDate!, $userId: String) {
    dashboardMetrics {
      id historicalTimeframe(startOn: $startOn, endOn: $endOn, userId: $userId) {
        id estimatesCreated {
          id timeframeData {id date count}
        }
      }
    }
  }
`;

export const PrivateDashboardMetricsInvoicesCreatedTimeframeDataQuery = gql`
  query ($startOn: CoreDate!, $endOn: CoreDate!, $userId: String) {
    dashboardMetrics {
      id historicalTimeframe(startOn: $startOn, endOn: $endOn, userId: $userId) {
        id invoicesCreated {
          id timeframeData {id date count}
        }
      }
    }
  }
`;

export const PrivateDashboardMetricsPianosCreatedTimeframeDataQuery = gql`
  query ($startOn: CoreDate!, $endOn: CoreDate!, $userId: String) {
    dashboardMetrics {
      id historicalTimeframe(startOn: $startOn, endOn: $endOn, userId: $userId) {
        id pianosCreated {
          id timeframeData {id date count}
        }
      }
    }
  }
`;

export const PrivateDashboardMetricsRemindersTimeframeDataQuery = gql`
  query ($startOn: CoreDate!, $endOn: CoreDate!, $userId: String) {
    dashboardMetrics {
      id historicalTimeframe(startOn: $startOn, endOn: $endOn, userId: $userId) {
        id reminders {
          id timeframeData {id date count}
        }
      }
    }
  }
`;

