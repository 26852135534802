import { action, computed, makeObservable, observable } from 'mobx';
import { IStoreOptions, RootStore } from '../root/root_store';
import { Address, Geocode } from '@core';

class AddressStore {
  private rootStore: RootStore = null;

  isInitialized: boolean = false;

  addressString: string = '';
  verifyingAddress: boolean = null;
  addressComponents: Address = new Address({});
  geocode: Geocode = new Geocode({});
  addressErrorMessage: string = null;

  constructor() {
    makeObservable<AddressStore, "rootStore">(this, {
      rootStore: observable,
      isInitialized: observable,
      addressString: observable,
      verifyingAddress: observable,
      addressComponents: observable,
      geocode: observable,
      addressErrorMessage: observable,
      initializeData: action,
      computedAddressLine: computed,
      verifiedAddress: computed,
      invalidAddress: computed,
      isComplete: computed
    });
  }

  initializeData(rootStore: RootStore, options: IStoreOptions) {
    this.rootStore = rootStore;

    if (options.useTestData) {
      this.addressString = '1295 Amber Ridge Rd NW, Concord, NC, United States';
      this.verifyingAddress = false;
      this.addressComponents = new Address({address1: '1295 Amber Ridge Rd NW', city: 'Concord', state: 'NC', zip: '28027'});
      this.geocode = new Geocode({lat: 35.4298145, lng: -80.737666});
    }
  }

  get computedAddressLine(): string {
    if (!this.addressComponents) return '';
    let parts: string[] = [];
    if (this.addressComponents.address1) parts.push(this.addressComponents.address1);
    if (this.addressComponents.address2) parts.push(this.addressComponents.address2);
    if (this.addressComponents.city) parts.push(this.addressComponents.city);
    if (this.addressComponents.state) parts.push(this.addressComponents.state);
    if (this.addressComponents.zip) parts.push(this.addressComponents.zip);
    return parts.join(", ");
  }

  get verifiedAddress(): boolean {
    return !!(!this.verifyingAddress
      && this.rootStore
      && this.addressComponents
      && !this.invalidAddress
      && this.geocode.lat
      && this.geocode.lng);
  }

  get invalidAddress(): boolean {
    if (!this.rootStore || !this.rootStore.companySettings) {
      return false;
    }

    if (this.rootStore.companySettings.relaxedAddressValidation) {
      return false;
    }

    if (this.addressComponents && this.addressComponents.singleLine) {
      return !(this.addressComponents.address1 && this.addressComponents.city);
    }

    return false;
  }

  get isComplete(): boolean {
    return this.verifiedAddress;
  }
}

export { AddressStore };
