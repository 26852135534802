import * as React from 'react';
import * as moment from 'moment-timezone';
import { Button, Popover, PopoverBody } from 'reactstrap';
import { find as _find, values as _values } from 'lodash';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { DateSlots, Slot } from '@core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/pro-solid-svg-icons";
import { formatMessage, formatTime } from "@gazelle/shared/utils";
import { MSG_numOptionsForHour, MSG_plusSlotOptions, MSG_withTechnicianLabel } from "@gazelle/shared/strings";
import { GazelleDate, GazelleTime } from "@gazelle/shared/components/GazelleDateTime";

let idCounter = 1;

interface IDateSlotsButtonProps {
  slot: Slot;
  onClick: (slot: Slot) => void,
}
class DateSlotsButton extends React.Component<IDateSlotsButtonProps> {
  render() {
    return (
      <Button color="primary"
              block
              onClick={() => this.props.onClick(this.props.slot)}>
        {this.props.slot.isPreferred &&
        <span className="preferred text-color-alert-yellow">
                      <FontAwesomeIcon icon={faStar} />
                    </span>}

        <GazelleTime moment={this.props.slot.startsAt} />
        <div className="tech-name text-small">
          {formatMessage(MSG_withTechnicianLabel, {name: this.props.slot.user.fullName})}
        </div>
      </Button>
    );
  }
}


interface IDateSlotsBucketButtonProps {
  slots: Slot[],
  onClick: (slot: Slot) => void,
}

const DateSlotsBucketButton = observer(
  class DateSlotsBucketButton extends React.Component<IDateSlotsBucketButtonProps> {
    private readonly buttonId: string = null;
    showOptions: boolean = null;

    constructor(props: IDateSlotsBucketButtonProps) {
      super(props);

      makeObservable(this, {
        showOptions: observable
      });

      this.buttonId = 'date-slots-button-' + (idCounter++);
    }

    toggle = () => {
      this.showOptions = !this.showOptions;
    };

    render() {
      const firstSlot = this.props.slots[0];
      const hasPreferred = _find(this.props.slots, slot => slot.isPreferred);

      if (firstSlot.startsAt.format('YYYY-MM-DD') === '2018-07-26') {
        console.log(this.props.slots);
        this.props.slots.forEach(slot => console.log(slot.startsAt.format('hh:mm:ss')));
      }

      return (
        <div>
          <Button color="primary"
                  block
                  id={this.buttonId}
                  onClick={this.toggle}>
            {hasPreferred &&
              <span className="preferred text-color-alert-yellow">
                <FontAwesomeIcon icon={faStar} />
              </span>}

            <GazelleTime moment={firstSlot.startsAt} />
            <div className="tech-name text-small">
              {formatMessage(MSG_plusSlotOptions, {numOptions: this.props.slots.length})}
            </div>
          </Button>

          <Popover fade={false} trigger="legacy" placement="bottom" isOpen={this.showOptions} target={this.buttonId} toggle={this.toggle}>
            <PopoverBody className="date-slots-container wider me-0">
              <div className="time-list">
                <p className="text-center">
                  {formatMessage(MSG_numOptionsForHour, {
                    numOptions: this.props.slots.length,
                    time: formatTime(moment(firstSlot.startsAt).minute(0))
                  })}
                </p>
                {this.props.slots.map((slot: Slot, i: number) =>
                  <DateSlotsButton key={i} slot={slot} onClick={(s) => { this.toggle(); this.props.onClick(s); }} />)}
              </div>
            </PopoverBody>
          </Popover>
        </div>
      );
    }
  }
);

interface IDateSlotsContainerProps {
  dateSlots: DateSlots,
  onClick: (slot: Slot) => void,
}

const DateSlotsContainer = observer(
  class DateSlotsContainer extends React.Component<IDateSlotsContainerProps> {
    render() {
      return (
        <div className="date-slots-container">
          <div className="date">
            <GazelleDate moment={this.props.dateSlots.date} weekday />
          </div>
          <div className="time-list">
            {_values(this.props.dateSlots.buckets).map((slots, i) => {
              if (slots.length > 1) {
                return <DateSlotsBucketButton key={i} slots={slots} onClick={this.props.onClick} />;
              } else {
                return <DateSlotsButton key={i} slot={slots[0]} onClick={this.props.onClick} />;
              }
            })}
          </div>
        </div>
      );
    }
  }
);

export { DateSlotsContainer };
