import { IMappingTypeahead, IMappingTypeaheadPrediction } from "../typeahead";

interface IGoogleMappingTypeaheadConfig {
  countryCode: string;
  locationBiasLat?: number;
  locationBiasLng?: number;
  locationBiasRadius?: number;

  // Passing in these values rather than accessing google.maps.* directly
  // because I couldn't figure out a way to mock the global google.maps.*
  // objects for tests.  This is kinda janky, but works great.
  autocompleteService: any; // essentially new google.maps.places.AutocompleteService()
  PlacesServiceStatus: any; // essentially google.maps.places.PlacesServiceStatus
  LatLng: any; // essentially google.maps.LatLng
}

class GoogleMappingTypeahead implements IMappingTypeahead {
  config: IGoogleMappingTypeaheadConfig;

  constructor(config: IGoogleMappingTypeaheadConfig) {
    this.config = config;
  }

  // https://developers.google.com/maps/documentation/javascript/reference/places-autocomplete-service#AutocompletionRequest
  //
  // Resolve to a list of IMappingTypeaheadPrediction items.
  // If zero results are found, it resolves to an empty array.
  // If there was an error, it rejects.
  getPredictions(queryStr: string): Promise<IMappingTypeaheadPrediction[]> {
    let params: any = {
      input: queryStr,
    };

    if (this.config.locationBiasLat && this.config.locationBiasLng && this.config.locationBiasRadius) {
      params.location = new this.config.LatLng(this.config.locationBiasLat, this.config.locationBiasLng);
      params.radius = this.config.locationBiasRadius * 1.60934 * 1000; // convert miles to meters;
    }

    return new Promise<IMappingTypeaheadPrediction[]>((resolve, reject) => {
      this.config.autocompleteService.getPlacePredictions(params, (predictions: any, status: any) => {
        if (status === this.config.PlacesServiceStatus.ZERO_RESULTS) {
          resolve([]);
          return;
        }

        if (status !== this.config.PlacesServiceStatus.OK) {
          reject(new Error("Error fetching predictions.  Status: " + status));
          return;
        }

        let options: IMappingTypeaheadPrediction[] = [];
        predictions.forEach((prediction: any) => {
          options.push({
            id: prediction.place_id,
            label: prediction.description,
            highlights: prediction.matched_substrings,
          });
        });
        resolve(options);
      });
    });
  }
}

export { GoogleMappingTypeahead };
