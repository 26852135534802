import { EVENT_RESERVATION_STATUS } from "../enums";
import { formatMessage } from "./intl";
import { ALERT_GREEN_COLOR, ALERT_RED_COLOR, ALERT_YELLOW_COLOR } from "../colors";
import {
  MSG_eventReservationStatusApproved,
  MSG_eventReservationStatusDeclined,
  MSG_eventReservationStatusPending
} from "../strings";

// Use this if you want to iterate over all the types (e.g. for a <select>)
const SortedEventReservationStatusTypes = [
  EVENT_RESERVATION_STATUS.PENDING,
  EVENT_RESERVATION_STATUS.APPROVED,
  EVENT_RESERVATION_STATUS.DECLINED,
];

const EventReservationStatusTypeInfo: {[key: string]: {type: EVENT_RESERVATION_STATUS, color: string, mobileTagColor: 'green'|'yellow'|'red', bootstrapColor: string, getLabel: () => string}} = {
  [EVENT_RESERVATION_STATUS.PENDING]: {
    type: EVENT_RESERVATION_STATUS.PENDING,
    color: ALERT_YELLOW_COLOR,
    bootstrapColor: 'warning',
    mobileTagColor: 'yellow',
    getLabel: () => formatMessage(MSG_eventReservationStatusPending),
  },
  [EVENT_RESERVATION_STATUS.APPROVED]: {
    type: EVENT_RESERVATION_STATUS.APPROVED,
    color: ALERT_GREEN_COLOR,
    bootstrapColor: 'success',
    mobileTagColor: 'green',
    getLabel: () => formatMessage(MSG_eventReservationStatusApproved),
  },
  [EVENT_RESERVATION_STATUS.DECLINED]: {
    type: EVENT_RESERVATION_STATUS.DECLINED,
    color: ALERT_RED_COLOR,
    bootstrapColor: 'danger',
    mobileTagColor: 'red',
    getLabel: () => formatMessage(MSG_eventReservationStatusDeclined),
  },
};

export { EventReservationStatusTypeInfo, SortedEventReservationStatusTypes };
