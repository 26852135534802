import * as React from 'react';
import { DateSlots, Slot } from '@core';

import { DateSlotsContainer } from "./DateSlotsContainer";

interface ITargetDateSlotsProps {
  dateSlots: DateSlots,
  onClick: (slot: Slot) => void,
}

class TargetDateSlots extends React.Component<ITargetDateSlotsProps> {
  render() {
    return (
      <div className="target-date-slots-container">
        <DateSlotsContainer onClick={this.props.onClick}
                            dateSlots={this.props.dateSlots} />
      </div>
    );
  }
}

export { TargetDateSlots };