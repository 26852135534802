import { LIFECYCLE_STATE } from "../enums";
import { formatMessage } from "./intl";
import {
  MSG_clientStatusInactive,
  MSG_lifecycleStateActiveClient,
  MSG_lifecycleStateCanceled,
  MSG_lifecycleStateComingDue,
  MSG_lifecycleStateComplete,
  MSG_lifecycleStateConfirmed,
  MSG_lifecycleStateDue,
  MSG_lifecycleStateFuture,
  MSG_lifecycleStateNewClient,
  MSG_lifecycleStateNoLifecycle,
  MSG_lifecycleStateNoShow,
  MSG_lifecycleStateNotDue,
  MSG_lifecycleStateOverdue1,
  MSG_lifecycleStateOverdue2,
  MSG_lifecycleStateOverdue3,
  MSG_lifecycleStatePast,
  MSG_lifecycleStateProspectClient,
  MSG_lifecycleStateToday,
  MSG_lifecycleStateTuningScheduled,
  MSG_lifecycleStateUnconfirmed,
  MSG_lifecycleStateUnconfirmedCritical,
  MSG_lifecycleStateWithoutSchedulingDetails
} from "../strings";

const LifecycleStateInfo: {[key: string]: {state: LIFECYCLE_STATE, getLabel: () => string}} = {
  [LIFECYCLE_STATE.NO_LIFECYCLE]: {
    state: LIFECYCLE_STATE.NO_LIFECYCLE,
    getLabel: () => formatMessage(MSG_lifecycleStateNoLifecycle)
  },

  [LIFECYCLE_STATE.PROSPECT]: {
    state: LIFECYCLE_STATE.PROSPECT,
    getLabel: () => formatMessage(MSG_lifecycleStateProspectClient)
  },
  [LIFECYCLE_STATE.NEW]: {
    state: LIFECYCLE_STATE.NEW,
    getLabel: () => formatMessage(MSG_lifecycleStateNewClient)
  },
  [LIFECYCLE_STATE.INACTIVE]: {
    state: LIFECYCLE_STATE.INACTIVE,
    getLabel: () => formatMessage(MSG_clientStatusInactive) // this is shared with client and piano states
  },
  [LIFECYCLE_STATE.ACTIVE]: {
    state: LIFECYCLE_STATE.ACTIVE,
    getLabel: () => formatMessage(MSG_lifecycleStateActiveClient)
  },

  [LIFECYCLE_STATE.WITHOUT_SCHEDULING_DETAILS]: {
    state: LIFECYCLE_STATE.WITHOUT_SCHEDULING_DETAILS,
    getLabel: () => formatMessage(MSG_lifecycleStateWithoutSchedulingDetails)
  },
  [LIFECYCLE_STATE.TUNING_SCHEDULED]: {
    state: LIFECYCLE_STATE.TUNING_SCHEDULED,
    getLabel: () => formatMessage(MSG_lifecycleStateTuningScheduled)
  },
  [LIFECYCLE_STATE.NOT_DUE]: {
    state: LIFECYCLE_STATE.NOT_DUE,
    getLabel: () => formatMessage(MSG_lifecycleStateNotDue)
  },
  [LIFECYCLE_STATE.COMING_DUE]: {
    state: LIFECYCLE_STATE.COMING_DUE,
    getLabel: () => formatMessage(MSG_lifecycleStateComingDue)
  },
  [LIFECYCLE_STATE.DUE]: {
    state: LIFECYCLE_STATE.DUE,
    getLabel: () => formatMessage(MSG_lifecycleStateDue)
  },
  [LIFECYCLE_STATE.OVERDUE_STAGE_1]: {
    state: LIFECYCLE_STATE.OVERDUE_STAGE_1,
    getLabel: () => formatMessage(MSG_lifecycleStateOverdue1)
  },
  [LIFECYCLE_STATE.OVERDUE_STAGE_2]: {
    state: LIFECYCLE_STATE.OVERDUE_STAGE_2,
    getLabel: () => formatMessage(MSG_lifecycleStateOverdue2)
  },
  [LIFECYCLE_STATE.OVERDUE_STAGE_3]: {
    state: LIFECYCLE_STATE.OVERDUE_STAGE_3,
    getLabel: () => formatMessage(MSG_lifecycleStateOverdue3)
  },

  [LIFECYCLE_STATE.COMPLETE]: {
    state: LIFECYCLE_STATE.COMPLETE,
    getLabel: () => formatMessage(MSG_lifecycleStateComplete)
  },
  [LIFECYCLE_STATE.NO_SHOW]: {
    state: LIFECYCLE_STATE.NO_SHOW,
    getLabel: () => formatMessage(MSG_lifecycleStateNoShow)
  },
  [LIFECYCLE_STATE.CANCELED]: {
    state: LIFECYCLE_STATE.CANCELED,
    getLabel: () => formatMessage(MSG_lifecycleStateCanceled)
  },
  [LIFECYCLE_STATE.PAST]: {
    state: LIFECYCLE_STATE.PAST,
    getLabel: () => formatMessage(MSG_lifecycleStatePast)
  },
  [LIFECYCLE_STATE.TODAY]: {
    state: LIFECYCLE_STATE.TODAY,
    getLabel: () => formatMessage(MSG_lifecycleStateToday)
  },
  [LIFECYCLE_STATE.CONFIRMED]: {
    state: LIFECYCLE_STATE.CONFIRMED,
    getLabel: () => formatMessage(MSG_lifecycleStateConfirmed)
  },
  [LIFECYCLE_STATE.UNCONFIRMED]: {
    state: LIFECYCLE_STATE.UNCONFIRMED,
    getLabel: () => formatMessage(MSG_lifecycleStateUnconfirmed)
  },
  [LIFECYCLE_STATE.UNCONFIRMED_CRITICAL]: {
    state: LIFECYCLE_STATE.UNCONFIRMED_CRITICAL,
    getLabel: () => formatMessage(MSG_lifecycleStateUnconfirmedCritical)
  },
  [LIFECYCLE_STATE.FUTURE]: {
    state: LIFECYCLE_STATE.FUTURE,
    getLabel: () => formatMessage(MSG_lifecycleStateFuture)
  },
};

export { LifecycleStateInfo };
