import { IAddressParts } from "../interfaces";
import { compact } from 'lodash';

function cityStateZipLine(parts: IAddressParts) {
  let cityStateZip = compact([parts.city, parts.state]).join(', ');
  if (parts.zip) {
    if (cityStateZip) {
      cityStateZip += ' ' + parts.zip;
    } else {
      cityStateZip = parts.zip;
    }
  }
  return cityStateZip;
}

function addressPartsToLine(parts: IAddressParts) {
  return compact([parts.address1, parts.address2, cityStateZipLine(parts)]).join(', ');
}

function addressPartsToLines(parts: IAddressParts): string[] {
  return compact([parts.address1, parts.address2, cityStateZipLine(parts)]) || [];
}

export { addressPartsToLine, addressPartsToLines };
