import { AnyFilter, FilterType, IFilterSet, ISelectFilter } from "../filters";
import { formatMessage } from "../intl";
import {
  MSG_allOfTheseTags,
  MSG_anyOfTheseTags,
  MSG_anyPianoNextTuningDueLabel,
  MSG_caseColorLabel,
  MSG_caseFinishLabel,
  MSG_createdAfterOrOnLabel,
  MSG_createdBeforeOrOnLabel,
  MSG_createdTitle,
  MSG_dateCreatedLabel,
  MSG_doesNotHaveManuallyEnteredServiceHistory,
  MSG_doesNotNeedRepairOrRebuildingLabel,
  MSG_dueNearTodayLabel,
  MSG_hasAnyAppointmentAfterLabel,
  MSG_hasAnyAppointmentBeforeLabel,
  MSG_hasAnyAppointmentLabel,
  MSG_hasBeenTuned,
  MSG_hasManuallyEnteredServiceHistory,
  MSG_hasNeverBeenTuned,
  MSG_hasNoTimelineActivityLabel,
  MSG_hasTimelineActivityLabel,
  MSG_invoicedAfterOrOnLabel,
  MSG_invoiceDateLabel,
  MSG_invoicedBeforeOrOnLabel,
  MSG_lastTunedAfterOrOnLabel,
  MSG_lastTunedBeforeOrOnLabel,
  MSG_lastTunedLabel,
  MSG_makeAndModelLabel,
  MSG_makeLabel,
  MSG_mostRecentPastAppointmentWasNotTuning,
  MSG_mostRecentPastAppointmentWasTuning,
  MSG_needsRepairOrRebuildingLabel,
  MSG_nextTuningDueAfterOrOnLabel,
  MSG_nextTuningDueBeforeOrOnLabel,
  MSG_nextTuningDueLabel,
  MSG_noneOfTheseTags,
  MSG_noPianoLifeSaverInstalledLabel,
  MSG_noPlayerInstalledLabel,
  MSG_noReminderLabel,
  MSG_noTimelineActivityAfterLabel,
  MSG_noTimelineActivityBeforeLabel,
  MSG_notTotalLossLabel,
  MSG_onConsignmentLabel,
  MSG_pianoHasFutureAppointment,
  MSG_pianoHasFutureTuning,
  MSG_pianoHasIvoryLabel,
  MSG_pianoHasNoFutureAppointment,
  MSG_pianoHasNoFutureTuning,
  MSG_pianoHasNoIvoryLabel,
  MSG_pianoHasNoPastAppointment,
  MSG_pianoHasNoPastTuning,
  MSG_pianoHasNoServiceInterval,
  MSG_pianoHasPastAppointment,
  MSG_pianoHasPastTuning,
  MSG_pianoHasServiceInterval,
  MSG_pianoLifeSaverInstalledLabel,
  MSG_pianoNextTuningScheduledAfterOrOnLabel,
  MSG_pianoNextTuningScheduledBeforeOrOnLabel,
  MSG_pianoNextTuningScheduledLabel,
  MSG_pianosNeverTuned,
  MSG_pianosNotTuned24Months,
  MSG_pianosNotTuned36Months,
  MSG_pianosNotTuned60Months,
  MSG_pianoTypeLabel,
  MSG_playerInstalledLabel,
  MSG_remindersLabel,
  MSG_rentalPianoContractDateAfterOrOnLabel,
  MSG_rentalPianoContractDateBeforeOrOnLabel,
  MSG_rentalPianoContractDateFilterLabel,
  MSG_rentalPianoLabel,
  MSG_sizeLabel,
  MSG_statusLabel,
  MSG_timelineActivityAfterLabel,
  MSG_timelineActivityBeforeLabel,
  MSG_totalLossLabel,
  MSG_tuningIntervalMonthsLabel,
  MSG_useTypeLabel,
  MSG_yearLabel
} from "../../strings";
import { PianoStatusInfo, SortedPianoStatuses } from "../piano_status_info";
import { PianoTypeInfo, SortedPianoTypes } from "../piano_type_info";
import { faRepeatAlt, faTag } from "@fortawesome/pro-solid-svg-icons";
import { RootStore } from "../../stores";
import { PIANO_STATUS } from "../../enums";

const moment = require('moment-timezone');

export function getPianoFiltersConfig(rootStore: RootStore, options: {tags?: string[]} = {}) {
  return [{
    key: 'status',
    type: FilterType.SELECT,
    buttonLabel: formatMessage(MSG_statusLabel),
    selectorLabel: formatMessage(MSG_statusLabel),
    queryArgumentKey: 'status',
    options: SortedPianoStatuses.map(s => ({
      label: PianoStatusInfo[s].getLabel(),
      value: s
    }))
  }, {
    key: 'type',
    type: FilterType.SELECT,
    buttonLabel: formatMessage(MSG_pianoTypeLabel),
    selectorLabel: formatMessage(MSG_pianoTypeLabel),
    queryArgumentKey: 'type',
    options: SortedPianoTypes.map(t => ({
      label: PianoTypeInfo[t].getLabel(),
      value: t
    }))
  }, {
    key: 'rental',
    type: FilterType.BOOLEAN,
    buttonLabel: formatMessage(MSG_rentalPianoLabel),
    selectorLabel: formatMessage(MSG_rentalPianoLabel),
    queryArgumentKey: 'rental'
  }, {
    key: 'rentalContractEnds',
    type: FilterType.DATE,
    buttonLabel: formatMessage(MSG_rentalPianoContractDateFilterLabel),
    selectorLabel: formatMessage(MSG_rentalPianoContractDateFilterLabel),
    dateAfterOrOnLabel: formatMessage(MSG_rentalPianoContractDateAfterOrOnLabel),
    dateBeforeOrOnLabel: formatMessage(MSG_rentalPianoContractDateBeforeOrOnLabel),
    queryArgumentKey: 'rentalContractEnds'
  }, {
    key: 'consignment',
    type: FilterType.BOOLEAN,
    buttonLabel: formatMessage(MSG_onConsignmentLabel),
    selectorLabel: formatMessage(MSG_onConsignmentLabel),
    queryArgumentKey: 'consignment'
  }, {
    key: 'lastTuned',
    type: FilterType.DATE,
    buttonLabel: formatMessage(MSG_lastTunedLabel),
    selectorLabel: formatMessage(MSG_lastTunedLabel),
    dateAfterOrOnLabel: formatMessage(MSG_lastTunedAfterOrOnLabel),
    dateBeforeOrOnLabel: formatMessage(MSG_lastTunedBeforeOrOnLabel),
    queryArgumentKey: 'lastTuned',
  }, {
    key: 'nextTuningDue',
    type: FilterType.DATE,
    buttonLabel: formatMessage(MSG_nextTuningDueLabel),
    selectorLabel: formatMessage(MSG_nextTuningDueLabel),
    dateAfterOrOnLabel: formatMessage(MSG_nextTuningDueAfterOrOnLabel),
    dateBeforeOrOnLabel: formatMessage(MSG_nextTuningDueBeforeOrOnLabel),
    queryArgumentKey: 'nextTuningDue',
  }, {
    key: 'nextTuningScheduled',
    type: FilterType.DATE,
    buttonLabel: formatMessage(MSG_pianoNextTuningScheduledLabel),
    selectorLabel: formatMessage(MSG_pianoNextTuningScheduledLabel),
    dateAfterOrOnLabel: formatMessage(MSG_pianoNextTuningScheduledAfterOrOnLabel),
    dateBeforeOrOnLabel: formatMessage(MSG_pianoNextTuningScheduledBeforeOrOnLabel),
    queryArgumentKey: 'nextTuningScheduled',
  }, {
    key: 'hasPastTuningScheduled',
    type: FilterType.BOOLEAN,
    buttonLabel: formatMessage(MSG_pianoHasPastTuning),
    selectorLabel: formatMessage(MSG_pianoHasPastTuning),
    negativeButtonLabel: formatMessage(MSG_pianoHasNoPastTuning),
    queryArgumentKey: 'hasPastTuningScheduled',
  }, {
    key: 'hasPastAppointmentScheduled',
    type: FilterType.BOOLEAN,
    buttonLabel: formatMessage(MSG_pianoHasPastAppointment),
    selectorLabel: formatMessage(MSG_pianoHasPastAppointment),
    negativeButtonLabel: formatMessage(MSG_pianoHasNoPastAppointment),
    queryArgumentKey: 'hasPastAppointmentScheduled',
  }, {
    key: 'hasFutureTuningScheduled',
    type: FilterType.BOOLEAN,
    buttonLabel: formatMessage(MSG_pianoHasFutureTuning),
    selectorLabel: formatMessage(MSG_pianoHasFutureTuning),
    negativeButtonLabel: formatMessage(MSG_pianoHasNoFutureTuning),
    queryArgumentKey: 'hasFutureTuningScheduled',
  }, {
    key: 'hasFutureAppointmentScheduled',
    type: FilterType.BOOLEAN,
    buttonLabel: formatMessage(MSG_pianoHasFutureAppointment),
    selectorLabel: formatMessage(MSG_pianoHasFutureAppointment),
    negativeButtonLabel: formatMessage(MSG_pianoHasNoFutureAppointment),
    queryArgumentKey: 'hasFutureAppointmentScheduled',
  }, {
    key: 'hasBeenTuned',
    type: FilterType.BOOLEAN,
    buttonLabel: formatMessage(MSG_hasBeenTuned),
    selectorLabel: formatMessage(MSG_hasBeenTuned),
    negativeButtonLabel: formatMessage(MSG_hasNeverBeenTuned),
    queryArgumentKey: 'hasBeenTuned',
  }, {
    key: 'mostRecentPastAppointmentWasTuning',
    type: FilterType.BOOLEAN,
    buttonLabel: formatMessage(MSG_mostRecentPastAppointmentWasTuning),
    selectorLabel: formatMessage(MSG_mostRecentPastAppointmentWasTuning),
    negativeButtonLabel: formatMessage(MSG_mostRecentPastAppointmentWasNotTuning),
    queryArgumentKey: 'mostRecentPastAppointmentWasTuning',
    defaultValue: false,
  }, {
    key: 'hasServiceInterval',
    type: FilterType.BOOLEAN,
    buttonLabel: formatMessage(MSG_pianoHasServiceInterval),
    selectorLabel: formatMessage(MSG_pianoHasServiceInterval),
    negativeButtonLabel: formatMessage(MSG_pianoHasNoServiceInterval),
    queryArgumentKey: 'hasServiceInterval',
  }, {
    key: 'lifecycleIds',
    type: FilterType.SELECT,
    buttonLabel: formatMessage(MSG_remindersLabel),
    selectorLabel: formatMessage(MSG_remindersLabel),
    icon: faRepeatAlt,
    queryArgumentKey: 'lifecycleIds',
    getQueryVariables: (filter: ISelectFilter) => {
      if (filter.value && filter.value.length > 0) {
        return {lifecycleIds: filter.value.map(v => ({value: v}))};
      } else {
        return {lifecycleIds: []};
      }
    },
    options: [
      {label: formatMessage(MSG_noReminderLabel), value: null},
      ...rootStore.allLifecycles.map(l => ({
        label: l.name,
        value: l.id
      }))
    ]
  }, {
    key: 'make',
    type: FilterType.EXACT_STRING,
    buttonLabel: formatMessage(MSG_makeLabel),
    selectorLabel: formatMessage(MSG_makeLabel),
    queryArgumentKey: 'make'
  }, {
    key: 'hasTimelineActivity',
    type: FilterType.DATE,
    buttonLabel: formatMessage(MSG_hasTimelineActivityLabel),
    selectorLabel: formatMessage(MSG_hasTimelineActivityLabel),
    dateAfterOrOnLabel: formatMessage(MSG_timelineActivityAfterLabel),
    dateBeforeOrOnLabel: formatMessage(MSG_timelineActivityBeforeLabel),
    queryArgumentKey: 'hasTimelineActivity'
  }, {
    key: 'hasNoTimelineActivity',
    type: FilterType.DATE,
    buttonLabel: formatMessage(MSG_hasNoTimelineActivityLabel),
    selectorLabel: formatMessage(MSG_hasNoTimelineActivityLabel),
    dateAfterOrOnLabel: formatMessage(MSG_noTimelineActivityAfterLabel),
    dateBeforeOrOnLabel: formatMessage(MSG_noTimelineActivityBeforeLabel),
    queryArgumentKey: 'hasNoTimelineActivity'
  }, {
    key: 'serviceIntervalMonths',
    type: FilterType.NUMBER,
    buttonLabel: formatMessage(MSG_tuningIntervalMonthsLabel),
    selectorLabel: formatMessage(MSG_tuningIntervalMonthsLabel),
    queryArgumentKey: 'serviceIntervalMonths'
  }, {
    key: 'year',
    type: FilterType.NUMBER,
    noFormatting: true,
    buttonLabel: formatMessage(MSG_yearLabel),
    selectorLabel: formatMessage(MSG_yearLabel),
    queryArgumentKey: 'year'
  }, {
    key: 'needsRepairOrRebuilding',
    type: FilterType.BOOLEAN,
    buttonLabel: formatMessage(MSG_needsRepairOrRebuildingLabel),
    negativeButtonLabel: formatMessage(MSG_doesNotNeedRepairOrRebuildingLabel),
    selectorLabel: formatMessage(MSG_needsRepairOrRebuildingLabel),
    queryArgumentKey: 'needsRepairOrRebuilding',
  }, {
    key: 'isTotalLoss',
    type: FilterType.BOOLEAN,
    buttonLabel: formatMessage(MSG_totalLossLabel),
    negativeButtonLabel: formatMessage(MSG_notTotalLossLabel),
    selectorLabel: formatMessage(MSG_totalLossLabel),
    queryArgumentKey: 'isTotalLoss',
  }, {
    key: 'useType',
    type: FilterType.EXACT_STRING,
    buttonLabel: formatMessage(MSG_useTypeLabel),
    selectorLabel: formatMessage(MSG_useTypeLabel),
    queryArgumentKey: 'useType'
  }, {
    key: 'created',
    type: FilterType.DATE,
    buttonLabel: formatMessage(MSG_createdTitle),
    selectorLabel: formatMessage(MSG_createdTitle),
    dateAfterOrOnLabel: formatMessage(MSG_createdAfterOrOnLabel),
    dateBeforeOrOnLabel: formatMessage(MSG_createdBeforeOrOnLabel),
    queryArgumentKey: 'created'
  }, {
    key: 'hasManuallyEnteredServiceHistory',
    type: FilterType.BOOLEAN,
    buttonLabel: formatMessage(MSG_hasManuallyEnteredServiceHistory),
    negativeButtonLabel: formatMessage(MSG_doesNotHaveManuallyEnteredServiceHistory),
    selectorLabel: formatMessage(MSG_hasManuallyEnteredServiceHistory),
    queryArgumentKey: 'hasManuallyEnteredServiceHistory',
  }, {
    key: 'hasInvoice',
    type: FilterType.DATE,
    buttonLabel: formatMessage(MSG_invoiceDateLabel),
    selectorLabel: formatMessage(MSG_invoiceDateLabel),
    dateAfterOrOnLabel: formatMessage(MSG_invoicedAfterOrOnLabel),
    dateBeforeOrOnLabel: formatMessage(MSG_invoicedBeforeOrOnLabel),
    queryArgumentKey: 'hasInvoice'
  }, {
    key: 'hasAppointment',
    type: FilterType.DATE,
    buttonLabel: formatMessage(MSG_hasAnyAppointmentLabel),
    selectorLabel: formatMessage(MSG_hasAnyAppointmentLabel),
    dateAfterOrOnLabel: formatMessage(MSG_hasAnyAppointmentAfterLabel),
    dateBeforeOrOnLabel: formatMessage(MSG_hasAnyAppointmentBeforeLabel),
    queryArgumentKey: 'hasAppointment'
  }, {
    key: 'hasPlayerInstalled',
    type: FilterType.BOOLEAN,
    buttonLabel: formatMessage(MSG_playerInstalledLabel),
    negativeButtonLabel: formatMessage(MSG_noPlayerInstalledLabel),
    selectorLabel: formatMessage(MSG_playerInstalledLabel),
    queryArgumentKey: 'hasPlayerInstalled',
  }, {
    key: 'hasPianoLifeSaverInstalled',
    type: FilterType.BOOLEAN,
    buttonLabel: formatMessage(MSG_pianoLifeSaverInstalledLabel),
    negativeButtonLabel: formatMessage(MSG_noPianoLifeSaverInstalledLabel),
    selectorLabel: formatMessage(MSG_pianoLifeSaverInstalledLabel),
    queryArgumentKey: 'hasPianoLifeSaverInstalled',
  }, {
    key: 'hasIvory',
    type: FilterType.BOOLEAN,
    buttonLabel: formatMessage(MSG_pianoHasIvoryLabel),
    negativeButtonLabel: formatMessage(MSG_pianoHasNoIvoryLabel),
    selectorLabel: formatMessage(MSG_pianoHasIvoryLabel),
    queryArgumentKey: 'hasIvory',
  }, {
    key: 'caseColor',
    type: FilterType.EXACT_STRING,
    buttonLabel: formatMessage(MSG_caseColorLabel),
    selectorLabel: formatMessage(MSG_caseColorLabel),
    queryArgumentKey: 'caseColor'
  }, {
    key: 'caseFinish',
    type: FilterType.EXACT_STRING,
    buttonLabel: formatMessage(MSG_caseFinishLabel),
    selectorLabel: formatMessage(MSG_caseFinishLabel),
    queryArgumentKey: 'caseFinish'
  }, {
    key: 'size',
    type: FilterType.EXACT_STRING,
    buttonLabel: formatMessage(MSG_sizeLabel),
    selectorLabel: formatMessage(MSG_sizeLabel),
    queryArgumentKey: 'size'
  }, {
    key: 'anyTags',
    icon: faTag,
    type: FilterType.SELECT,
    buttonLabel: formatMessage(MSG_anyOfTheseTags),
    selectorLabel: formatMessage(MSG_anyOfTheseTags),
    queryArgumentKey: 'anyTags',
    options: (options.tags || []).map(t => ({label: t.toLocaleUpperCase(), value: t, specialStyle: 'tag'}))
  }, {
    key: 'allTags',
    icon: faTag,
    type: FilterType.SELECT,
    buttonLabel: formatMessage(MSG_allOfTheseTags),
    selectorLabel: formatMessage(MSG_allOfTheseTags),
    queryArgumentKey: 'allTags',
    options: (options.tags || []).map(t => ({label: t.toLocaleUpperCase(), value: t, specialStyle: 'tag'}))
  }, {
    key: 'excludeTags',
    icon: faTag,
    type: FilterType.SELECT,
    buttonLabel: formatMessage(MSG_noneOfTheseTags),
    selectorLabel: formatMessage(MSG_noneOfTheseTags),
    queryArgumentKey: 'excludeTags',
    options: (options.tags || []).map(t => ({label: t.toLocaleUpperCase(), value: t, specialStyle: 'tag'}))
  }];
}

export function getPianoFilterSets(rootStore: RootStore): IFilterSet[] {
  return [{
    label: formatMessage(MSG_pianosNotTuned24Months),
    filterState: {
      filters: unservicedPianoFilters24Months,
      searchString: '',
    }
  }, {
    label: formatMessage(MSG_pianosNotTuned36Months),
    filterState: {
      filters: unservicedPianoFilters36Months,
      searchString: '',
    }
  }, {
    label: formatMessage(MSG_pianosNotTuned60Months),
    filterState: {
      filters: unservicedPianoFilters60Months,
      searchString: '',
    }
  }, {
    label: formatMessage(MSG_pianosNeverTuned),
    filterState: {
      filters: unservicedPianoFiltersNeverTuned,
      searchString: '',
    }
  }];
}

export function getPianoSortConfig() {
  return [{
    label: formatMessage(MSG_lastTunedLabel),
    value: ['LAST_SERVICE_ASC', 'CREATED_AT_ASC'],
    reverseValue: ['LAST_SERVICE_DESC', 'CREATED_AT_DESC'],
    reverseDefault: true,
  }, {
    label: formatMessage(MSG_anyPianoNextTuningDueLabel),
    value: ['NEXT_SERVICE_ASC', 'CREATED_AT_ASC'],
    reverseValue: ['NEXT_SERVICE_DESC', 'CREATED_AT_DESC'],
    reverseDefault: true,
  }, {
    label: formatMessage(MSG_pianoNextTuningScheduledLabel),
    value: ['NEXT_SCHEDULED_TUNING_ASC', 'CREATED_AT_ASC'],
    reverseValue: ['NEXT_SCHEDULED_TUNING_DESC', 'CREATED_AT_DESC'],
    reverseDefault: true,
  }, {
    label: formatMessage(MSG_dueNearTodayLabel),
    value: ['DUE_NEAR_TODAY_ASC', 'LAST_SERVICE_ASC', 'CREATED_AT_ASC'],
    reverseValue: ['DUE_NEAR_TODAY_DESC', 'LAST_SERVICE_DESC', 'CREATED_AT_DESC'],
    reverseDefault: true,
  }, {
    label: formatMessage(MSG_dateCreatedLabel),
    value: 'CREATED_AT_ASC',
    reverseValue: 'CREATED_AT_DESC',
    reverseDefault: true,
  }, {
    label: formatMessage(MSG_statusLabel),
    value: ['STATUS_ASC', 'LAST_SERVICE_DESC', 'CREATED_AT_ASC'],
    reverseValue: ['STATUS_DESC', 'LAST_SERVICE_ASC', 'CREATED_AT_DESC'],
  }, {
    label: formatMessage(MSG_makeAndModelLabel),
    value: ['MAKE_ASC', 'MODEL_ASC', 'CREATED_AT_ASC'],
    reverseValue: ['MAKE_DESC', 'MODEL_DESC', 'CREATED_AT_DESC'],
  }];
}

export function getPianoDefaultSort() {
  return ['STATUS_ASC', 'LAST_SERVICE_DESC', 'CREATED_AT_ASC'];
}

export const unservicedPianoFilters24Months: AnyFilter[] = [{
  key: 'status',
  type: FilterType.SELECT,
  value: [PIANO_STATUS.ACTIVE]
},{
  key: 'lastTuned',
  type: FilterType.DATE,
  value: {lte: moment().subtract(24, 'months').format('YYYY-MM-DD')}
}, {
  key: 'hasFutureAppointmentScheduled',
  type: FilterType.BOOLEAN,
  value: false
}];

export const unservicedPianoFilters36Months: AnyFilter[] = [{
  key: 'status',
  type: FilterType.SELECT,
  value: [PIANO_STATUS.ACTIVE]
},{
  key: 'lastTuned',
  type: FilterType.DATE,
  value: {lte: moment().subtract(36, 'months').format('YYYY-MM-DD')}
}, {
  key: 'hasFutureAppointmentScheduled',
  type: FilterType.BOOLEAN,
  value: false
}];

export const unservicedPianoFilters60Months: AnyFilter[] = [{
  key: 'status',
  type: FilterType.SELECT,
  value: [PIANO_STATUS.ACTIVE]
},{
  key: 'lastTuned',
  type: FilterType.DATE,
  value: {lte: moment().subtract(60, 'months').format('YYYY-MM-DD')}
}, {
  key: 'hasFutureAppointmentScheduled',
  type: FilterType.BOOLEAN,
  value: false
}];

export const unservicedPianoFiltersNeverTuned: AnyFilter[] = [{
  key: 'status',
  type: FilterType.SELECT,
  value: [PIANO_STATUS.ACTIVE]
},{
  key: 'hasBeenTuned',
  type: FilterType.BOOLEAN,
  value: false
}, {
  key: 'hasFutureAppointmentScheduled',
  type: FilterType.BOOLEAN,
  value: false
}];
