import {
  FilterType,
  IFilterConfigProps,
  IFilterHandler,
  IFilterLabelProps,
  IFilterTypeConfig,
  INumberFilter
} from "./types";
import { faCalculatorAlt } from "@fortawesome/pro-solid-svg-icons";
import { isNullOrUndefined } from "../values";

export const GenericNumberFilterHandler: IFilterHandler = {
  type: FilterType.NUMBER,

  getIcon: () => faCalculatorAlt,

  getQueryVariables: (filter: INumberFilter, config: IFilterTypeConfig) => {
    const {gte, lte} = filter.value;
    if (!isNullOrUndefined(gte) && !isNullOrUndefined(lte)) {
      return {[config.queryArgumentKey + 'Let']: lte, [config.queryArgumentKey + 'Get']: gte};
    } else if (!isNullOrUndefined(gte)) {
      return {[config.queryArgumentKey + 'Get']: gte};
    } else if (!isNullOrUndefined(lte)) {
      return {[config.queryArgumentKey + 'Let']: lte};
    } else {
      return {};
    }
  },

  isValid: (filter: INumberFilter) => {
    const {gte, lte} = filter.value;
    if (typeof gte !== 'number' && typeof lte !== 'number') return false;
    if (typeof gte === 'number' && typeof lte === 'number' && lte < gte) return false;
    return true;
  },

  defaultValue: (config: IFilterTypeConfig) => {
    return {
      key: config.key,
      type: FilterType.NUMBER,
      value: {lte: null, gte: null}
    };
  },

  LabelComponent: (props: IFilterLabelProps) => {
    throw "Expected NumberFilterHandler to implement LabelConfig.";
  },

  ConfigComponent: (props: IFilterConfigProps) => {
    throw "Expected NumberFilterHandler to implement ComponentConfig.";
  }
};
