import * as React from 'react';
import { getUrlToken } from "@core/utils/url";
import { MSG_privacyPolicyTitle } from "@gazelle/shared/strings";
import { formatMessage } from "@gazelle/shared/utils";

const gdprImg = require('@gazelle/shared/images/gdpr_compliant.png');


interface IPrivacyProps {
  style?: any;
  gdpr?: boolean;
  forceHorizontal?: boolean;
}

class Privacy extends React.Component<IPrivacyProps> {
  render() {
    let { gdpr, style, forceHorizontal } = this.props;

    // For now, we only show the privacy policy for GDPR customers because
    // we have a signed contract with them saying they will uphold the privacy
    // policy.  Eventually it would be nice to show a privacy policy for all
    // our customers, but we can't put words in their mouth and that's a bigger
    // problem to solve, so for now -- nothing.
    if (!gdpr) return null;

    let containerClassNames = ['privacy-info-container'];
    if (forceHorizontal) containerClassNames.push('force-horizontal');

    return (
      <div className={containerClassNames.join(" ")} style={style}>
        <div className="gdpr-container">
          <div className="image-container">
            <a target="_blank" href={`/legal/${getUrlToken()}/privacy_policy`}>
              <img alt='GDPR compliant image' src={gdprImg} width="125" id="gdpr-compliant"/>
            </a>
          </div>
          <div className="links-container">
            <a target="_blank" href={`/legal/${getUrlToken()}/privacy_policy`}>{formatMessage(MSG_privacyPolicyTitle)}</a>
          </div>
        </div>
      </div>
    );
  }
}

export { Privacy };
