import { computed, makeObservable, observable } from 'mobx';
import { IAddressParts } from "@gazelle/shared/interfaces";

class Address implements IAddressParts {
  address1: string = null;
  address2: string = null;
  city: string = null;
  state: string = null;
  zip: string = null;

  constructor(attrs: any) {
    makeObservable(this, {
      address1: observable,
      address2: observable,
      city: observable,
      state: observable,
      zip: observable,
      singleLine: computed,
      multiLine: computed
    });

    this.address1 = attrs.address1;
    this.address2 = attrs.address2;
    this.city = attrs.city;
    this.state = attrs.state;
    this.zip = attrs.zip;
  }

  get singleLine(): string {
    const {address1, address2, city, state, zip} = this;

    let parts: string[] = [];
    if (address1) parts.push(address1);
    if (address2) parts.push(address2);
    if (city) parts.push(city);
    if (state) parts.push(state);
    if (zip) parts.push(zip);

    return parts.join(', ');
  }

  get multiLine(): string {
    const {address1, address2, city, state, zip} = this;

    let parts: string[] = [];
    if (address1) parts.push(address1);
    if (address2) parts.push(address2);
    let lastLine = [];
    if (city) lastLine.push(city);
    if (state) lastLine.push(state);
    if (zip) lastLine.push(zip);
    if (lastLine.length > 0) parts.push(lastLine.join(', '));

    return parts.join("\n");
  }
}

interface IGeocode {
  lat: number;
  lng: number;
}
class Geocode implements IGeocode {
  lat: number = null;
  lng: number = null;

  constructor(attrs: any) {
    makeObservable(this, {
      lat: observable,
      lng: observable
    });

    this.lat = attrs?.lat;
    this.lng = attrs?.lng;
  }
}

export { Address, Geocode, IGeocode };
