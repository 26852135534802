import {
  FilterType,
  ICurrencyFilter,
  IFilterConfigProps,
  IFilterHandler,
  IFilterLabelProps,
  IFilterTypeConfig
} from "./types";
import { getGlobalCurrency } from "../../stores/global_currency_store";

export const GenericCurrencyFilterHandler: IFilterHandler = {
  type: FilterType.CURRENCY,

  getIcon: () => getGlobalCurrency().symbol,

  getQueryVariables: (filter: ICurrencyFilter, config: IFilterTypeConfig) => {
    const {gte, lte} = filter.value;
    if (gte && lte) {
      return {[config.queryArgumentKey + 'Let']: lte, [config.queryArgumentKey + 'Get']: gte};
    } else if (gte) {
      return {[config.queryArgumentKey + 'Get']: gte};
    } else if (lte) {
      return {[config.queryArgumentKey + 'Let']: lte};
    } else {
      return {};
    }
  },

  isValid: (filter: ICurrencyFilter) => {
    const {gte, lte} = filter.value;
    if (typeof gte !== 'number' && typeof lte !== 'number') return false;
    if (typeof gte === 'number' && typeof lte === 'number' && lte < gte) return false;
    return true;
  },

  defaultValue: (config: IFilterTypeConfig) => {
    return {
      key: config.key,
      type: FilterType.CURRENCY,
      value: {lte: null, gte: null}
    };
  },

  LabelComponent: (props: IFilterLabelProps) => {
    throw "Expected CurrencyFilterHandler to implement LabelConfig.";
  },

  ConfigComponent: (props: IFilterConfigProps) => {
    throw "Expected CurrencyFilterHandler to implement ComponentConfig.";
  }
};
