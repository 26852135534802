import * as React from 'react';
import { Observer, observer } from 'mobx-react';

import { NavigationSteps } from './NavigationSteps';
import { StepSelector } from './StepSelector';
import { DesktopClientHeader, ErrorBoundary } from '@core';
import { makeObservable, observable } from "mobx";
import { GdprCompliance } from "@client_scheduler/modules/root/components/GdprCompliance";
import { formatMessage } from "@gazelle/shared/utils";
import {
  MSG_onlineSchedulingTitle,
  MSG_onlineSchedulingWithCompanyNameTitle,
  MSG_schedulerGenericError
} from "@gazelle/shared/strings";
import { rootStoreInstance } from "@client_scheduler/modules/root";

interface IDesktopLayoutProps {}

const DesktopLayout = observer(class DesktopLayout extends React.Component<IDesktopLayoutProps> {
  languageMenuOpen: boolean = null;

  constructor(props: IDesktopLayoutProps) {
    super(props);

    makeObservable(this, {
      languageMenuOpen: observable
    });

    rootStoreInstance.isMobile = false;
  }

  render() {
    const {companyName, companyEmail, companyPhoneNumber,
      isGdprRequired, isGdprConsentAgreed,
      companyLogo: { exists: companyLogoExists, url: companyLogoUrl },
      companySettings: { headerLayout }} = rootStoreInstance;

    let showGdprBlurb = isGdprRequired && !isGdprConsentAgreed;

    document.title = formatMessage(MSG_onlineSchedulingWithCompanyNameTitle, {companyName: companyName});
    return (
      <div className="desktop-container">
        {/* DESKTOP HEADER */}
        <DesktopClientHeader
          hasSidebar={!showGdprBlurb}
          companyLogoExists={companyLogoExists}
          companyLogoUrl={companyLogoUrl}
          companyName={companyName}
          companyPhoneNumber={companyPhoneNumber}
          companyEmail={companyEmail}
          companyHeaderLayout={headerLayout}
          appName={formatMessage(MSG_onlineSchedulingTitle)}
        />

        <ErrorBoundary extraMessage={formatMessage(MSG_schedulerGenericError, {phone: companyPhoneNumber})}>
          <Observer>
            {() => {
              if (showGdprBlurb) {
                return <GdprCompliance/>;
              } else {
                return (
                  <div className="container">
                    <div className="desktop-main-container">
                      {/* DESKTOP SIDEBAR */}
                      <div className="desktop-sidebar">
                        <NavigationSteps />
                      </div>

                      {/* BODY */}

                      <div className="desktop-step-container">
                        <StepSelector />
                      </div>
                    </div>
                  </div>
                );
              }
            }}
          </Observer>
        </ErrorBoundary>
      </div>
    );
  }
});

export { DesktopLayout };
