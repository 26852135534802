export const MSG_yourReservationDetailsTitle = {
  id: "6f69dd8d-adba-4ea0-b9c1-6af863e2f36e",
  description: "A header on top of the details of their appointment request",
  defaultMessage: "Appointment Reservation",
};

export const MSG_reservationTitle = {
  id: "6a501f4e-7bb5-4e64-97e2-000d55528b3a",
  description: "The label for an appointment reservation type.  This is when someone reserves an appointment slot.",
  defaultMessage: "Reservation"
};

export const MSG_reservationForTitle = {
  id: "29890e8f-977a-44c9-ba0d-d556d34f89f9",
  description: "",
  defaultMessage: "Reservation for {name}",
};

export const MSG_reservationForWithoutName = {
  id: "0233f436-64b4-44c7-919d-2d16b0650e67",
  description: "This is a heading that will appear over top of a name.  In two lines, it will read like:  Reservation for <new line> John Doe",
  defaultMessage: "Reservation for"
};

export const MSG_reservationOriginPrivateLink = {
  id: "4428d4e8-0f87-4553-b6d7-b7c6509c26cb",
  description: "This is a message that shows the origin of the reservation as which client it is associated with.",
  defaultMessage: "This reservation came from the private booking link for {clientName}"
};

export const MSG_reservationOriginPublicLink = {
  id: "35f07074-dc58-4482-b70c-eba134541f9f",
  description: "This is a message that explains that the reservation was made from a public reservation link when there is no client associated with it.",
  defaultMessage: "This reservation came from the public booking link."
};

export const MSG_reservationClientNotFoundExplanation = {
  id: "c4822075-f34f-4420-b36c-33e11c44cffa",
  defaultMessage: "We were unable to match this reservation with an existing client record.  This may be a new client who is not in your database, or it may be an existing client that we were unable to match for some reason."
};

export const MSG_reservationAssignedToName = {
  id: "81900cc4-f4a5-44a3-af06-cf98dfb30661",
  description: "",
  defaultMessage: "This reservation is currently assigned to {name}."
};


export const MSG_reservationConflictsExplanation = {
  id: "bfcb75d3-852c-4277-9a08-0da9ba5d24c9",
  defaultMessage: "There are some conflicts with this data that should be resolved before the reservation can be approved."
};

export const MSG_reservationApprovedByLabel = {
  id: "ec32afa7-c255-497f-b43f-2d9716236fd9",
  defaultMessage: "Approved by: {name}"
};

export const MSG_reservationDeclinedByLabel = {
  id: "edf3fc0c-4bd9-42fa-801a-894088b57e65",
  defaultMessage: "Declined by: {name}"
};

export const MSG_approveAndDeleteEventFromReservationConfirmation = {
  id: "bd6c7304-2715-4ba2-a802-9b85542f7217",
  defaultMessage: "{numEvents, plural, =1 {Approve this reservation and delete 1 event?} other {Approve this reservation and delete # events?}}"
};

export const MSG_declineAndDeleteEventFromReservationConfirmation = {
  id: "2613e3e9-0026-485f-82bf-019a2a098611",
  defaultMessage: "{numEvents, plural, =1 {Decline this reservation and delete 1 event?} other {Decline this reservation and delete # events?}}"
};

export const MSG_declineAppointmentTimeTitle = {
  id: "d9017222-c09f-484c-ba58-f9ca06cfbdd2",
  defaultMessage: "Declined Appointment Time",
};


export const MSG_reservationAlreadyHasAppointment = {
  id: "94316f69-60eb-4e73-8666-e50c27f097c7",
  defaultMessage: "The piano on this reservation is already booked for a future appointment."
};

export const MSG_receivedAtLabel = {
  id: "bca0c9c9-9b0c-482e-b9d5-eaebd3787906",
  description: "A date and time something (such as a reservation) was received.",
  defaultMessage: "Received: {datetime}",
};

export const MSG_receivedDateLabel = {
  id: "d0b622cf-a466-4496-a71a-f5b9d18ac452",
  description: "A date and time something (such as a reservation) was received.",
  defaultMessage: "Received Date",
};

export const MSG_approvedAtLabel = {
  id: "a0b874d6-3414-455b-a2e4-f86c93693df4",
  description: "A date and time that a reservation was approved.",
  defaultMessage: "Approved: {datetime}",
};

export const MSG_noPendingReservations = {
  id: "fb7c64fe-1fc7-47ce-88e6-e9d3326c7f24",
  defaultMessage: "No pending reservations",
};

export const MSG_noPendingReservationsShort = {
  id: "e72fd528-c68a-4a94-8397-f97716708207",
  description: "",
  defaultMessage: "No pending reservations.",
};

export const MSG_reservationDetailsTitle = {
  id: "074f13a8-f1cb-4320-a9fa-17e4f2a71adc",
  description: "A short title for the screen where a user can view the details about a reservation.",
  defaultMessage: "Reservation Details",
};

export const MSG_reservationDetailsExplanation = {
  id: "7b274f9f-ac68-4972-a018-48f0bf7f28ec",
  description: "",
  defaultMessage: "This is the information that the client entered when making the reservation."
};

export const MSG_approveTitle = {
  id: "8ac2367f-7aa9-477f-9e80-0c2312806b01",
  description: "A short action word for approving a reservation request.",
  defaultMessage: "Approve",
};

export const MSG_declineTitle = {
  id: "510f212b-3a65-40fa-90b3-108e81b50d6a",
  description: "A short action word for declining a reservation request.",
  defaultMessage: "Decline",
};

export const MSG_notesFromClientTitle = {
  id: "d2568ad4-a0b4-4b38-ba7c-e0deb723bd52",
  description: "A label for any notes that a client may have added to a reservation request.",
  defaultMessage: "Notes From Client",
};

export const MSG_itineraryForDateLabel = {
  id: "8304aeaf-8c35-48fb-855a-144125a58442",
  description: "A label for the user's itinerary for a particular date.",
  defaultMessage: "Show itinerary for {date}",
};

export const MSG_contactInfoTitle = {
  id: "a633879c-011e-42d3-985f-9b7ef4c66384",
  description: "A short heading for contact information",
  defaultMessage: "Contact Info",
};

export const MSG_contactInfoChanged = {
  id: "7743d666-a8ff-4118-8aec-fc2d3117661a",
  description: "",
  defaultMessage: "The client for this reservation was changed to {name} from the original reservation."
};

export const MSG_originalContactInfoTitle = {
  id: "cd267798-8b4a-4452-a613-10b2c661ce70",
  description: "A short heading for contact information.  This is shown when the contact information has changed, this will show the original data before the change.",
  defaultMessage: "Original Contact Info"
};

export const MSG_unrecognizedClientTitle = {
  id: "e05b3be6-07a2-4408-9522-37e6462d2c26",
  description: "This is in title case",
  defaultMessage: "Unrecognized Client",
};

export const MSG_unrecognizedClientLabel = {
  id: "5059c98f-1ff3-49c4-987e-16268c2ab3ee",
  description: "This is in label case",
  defaultMessage: "Unrecognized client",
};

export const MSG_nameConflictTitle = {
  id: "ff2c21e2-6cb7-4514-9ee0-7697bcbd402d",
  description: "A short phrase describing a situation where the name that was entered does not match the name on a client record.",
  defaultMessage: "Name Conflict",
};

export const MSG_notDefaultTitle = {
  id: "9115f98d-6fa9-49ee-a2e9-4022ca1f3ef0",
  description: "A short phrase for a situation where something that was selected is not the current default that is stored on a record.",
  defaultMessage: "Not Default",
};

export const MSG_noMatchFoundTitle = {
  id: "a2b1a554-86aa-442c-957f-5a9ff1bd76a9",
  description: "A short phrase for when there is no match between two sets of data.",
  defaultMessage: "No Match Found",
};

export const MSG_createNewClientRecordLabel = {
  id: "ba9b8bb0-6912-43fd-9045-2029d6488743",
  defaultMessage: "Create a new client record",
};

export const MSG_theyEnteredLabel = {
  id: "32d594ae-980f-42f6-9dc6-df69d423c161",
  description: "A short label for data that a customer entered on a form.",
  defaultMessage: "They entered",
};

export const MSG_whichContactForClient = {
  id: "fbae1859-24f7-48f2-b092-236766d289e9",
  defaultMessage: "Which contact for this client?",
};

export const MSG_whichContactForClientExplanation = {
  id: "a822f0e1-ce95-4770-b768-0282e5bdc03e",
  defaultMessage: "A contact matching that name was not found.  What would you like to do?",
};

export const MSG_allCommunicationThroughPrimaryContactNotice = {
  id: "77e736db-e358-4de1-9762-0b32f9071885",
  defaultMessage: "All automated communication through Gazelle goes to the primary contact."
};

export const MSG_existingContactIsNamed = {
  id: "ebab96a4-fa9d-48ca-98c4-037dbfc23e77",
  defaultMessage: "The existing contact record is named {name}",
};

export const MSG_renameToName = {
  id: "ded40423-4865-4a40-a840-f585c4bdfb18",
  description: "A short sentence for renaming a contact.",
  defaultMessage: "Rename to {name}",
};

export const MSG_keepName = {
  id: "6ad91c6c-6ac3-4b22-9fc9-04f8ed677956",
  description: "A short sentence for keeping the current name on a contact record.",
  defaultMessage: "Keep {name}",
};

export const MSG_useExistingContactLabel = {
  id: "81e19a35-2496-4b95-aff1-c6e4badf93b6",
  defaultMessage: "Use this existing contact",
};

export const MSG_addAsNewContactLabel = {
  id: "001de4b7-da4e-4b52-85a0-c9a19bab78c3",
  defaultMessage: "Add as a new contact",
};

export const MSG_makePrimaryContactLabel = {
  id: "a7281384-4653-479d-b919-bff9954de576",
  defaultMessage: "Make this the primary contact",
};

export const MSG_makeBillingContactLabel = {
  id: "8d7d60de-7b2a-4c14-98e2-e14c8c47f4f1",
  defaultMessage: "Make this the billing contact"
};

export const MSG_phoneConflictTitle = {
  id: "eaa02468-e3ce-49d3-8642-3aa0989868bc",
  description: "A short phrase describing a situation where the phone number that was entered does not match the phone number on a contact's record.",
  defaultMessage: "Phone Conflict",
};

export const MSG_discardPhoneEntryExplanation = {
  id: "47417fde-1ea4-4511-8e8a-f688b9d53a62",
  description: "",
  defaultMessage: "Ignore their entry and make no changes to the phone numbers already on file."
};

export const MSG_discardEmailEntryExplanation = {
  id: "9580f0be-f9c0-4635-b499-d929d90cb1e9",
  description: "",
  defaultMessage: "Ignore their entry and make no changes to the email addresses already on file."
};

export const MSG_whichPhoneQuestion = {
  id: "2a95ce73-61f1-4b5a-a439-67deb92512fc",
  defaultMessage: "A matching phone entry could not be found. What would you like to do?"
};

export const MSG_matchingPhoneButNotDefaultExplanation = {
  id: "a91d006d-513a-47e7-af4f-13f2e53d4a22",
  defaultMessage: "We found a matching phone number on the client's record but it's not the default number."
};

export const MSG_currentDefaultPhoneOnRecord = {
  id: "3ab6ebae-65db-4ea6-95b9-381ba50ed087",
  defaultMessage: "The current default phone number for this client is: {phoneNumber}."
};

export const MSG_whichEmailExplanation = {
  id: "249f8beb-467b-4d33-819f-0caa7fcf587d",
  defaultMessage: "A matching email entry could not be found. What would you like to do?"
};

export const MSG_matchingEmailButNotDefaultExplanation = {
  id: "28d5c5d4-014d-4cc4-b44e-7a91187fa0fe",
  defaultMessage: "We found a matching email address on the client's record but it is not marked as the default email address."
};

export const MSG_currentDefaultEmailOnRecord = {
  id: "b04f6cba-98bc-4b6b-8107-fa7eb4c526c0",
  defaultMessage: "The current default email address for this client is: {email}."
};

export const MSG_whichAddressExplanation = {
  id: "5a9d7182-6454-47cb-a695-16f3bfc2e673",
  description: "",
  defaultMessage: "There is already a street address on file for this contact that does not match what they entered.  What would you like to do?"
};

export const MSG_replaceExistingPhoneLabel = {
  id: "160da274-d675-4c6f-ab99-094c5e7e79fb",
  defaultMessage: "Replace existing phone",
};

export const MSG_addAsNewPhoneLabel = {
  id: "989eaef2-21c2-4155-a0e6-6a560bc7a9c0",
  defaultMessage: "Add as a new phone",
};

export const MSG_makePrimaryPhoneLabel = {
  id: "7a96683f-620f-4a32-94ca-5f59f362994f",
  defaultMessage: "Make this the primary phone",
};

export const MSG_makeNewPrimaryPhone = {
  id: "cce36c9d-29ed-4c19-9b61-d1cde162f64f",
  defaultMessage: "Make {phoneNumber} the new default phone number for this client."
};

export const MSG_leavePrimaryPhone = {
  id: "8467be57-ea6e-4c47-b35e-3c1ba1d1291f",
  defaultMessage: "Leave {phoneNumber} as the default phone number for this client."
};

export const MSG_discardTheirEntryLabel = {
  id: "2bfbe29d-d25d-43dd-95e9-3cfb1a9fca21",
  description: "A short phrase describing an action where the user does not want to accept data that a customer has entered.",
  defaultMessage: "Discard their entry",
};

export const MSG_emailConflictTitle = {
  id: "23656a0d-f571-4645-b677-a501f07cd3cc",
  description: "A short phrase describing a situation where the email address that was entered does not match the email address on a contact's record.",
  defaultMessage: "Email Conflict",
};

export const MSG_replaceExistingEmailLabel = {
  id: "91661c9c-cc2e-4717-9e58-7984e6a96624",
  description: "A short action phrase to change an existing email address to a different one.",
  defaultMessage: "Replace existing email",
};

export const MSG_addAsNewEmailLabel = {
  id: "4cfe4d3a-3285-4e0f-8d07-6c4fcff72623",
  description: "A short action phrase for adding a new email address to a record.",
  defaultMessage: "Add as a new email",
};

export const MSG_makePrimaryEmailLabel = {
  id: "db1ff87c-40ae-404c-862a-c9be449ffc53",
  defaultMessage: "Make this the primary email",
};

export const MSG_makeNewPrimaryEmail = {
  id: "8228b5cb-3b09-4adc-a3f8-9bd3ffe0313b",
  defaultMessage: "Make {email} the new default email address for this client."
};

export const MSG_leavePrimaryEmail = {
  id: "e382b65b-3996-47b7-91b3-9424cdd6269c",
  defaultMessage: "Leave {email} as the default email for this client."
};

export const MSG_addressConflictTitle = {
  id: "d1fd806f-b1af-421c-9264-021ca6e3318b",
  description: "A short phrase describing a situation where the address that was entered does not match the address on a contact's record.",
  defaultMessage: "Address Conflict",
};

export const MSG_keepExistingAddressLabel = {
  id: "4ccc3542-9f6e-4919-9d83-899e16261e32",
  description: "A short action phrase to keep the existing street address already on record.",
  defaultMessage: "Keep existing address",
};

export const MSG_useWhatTheyEnteredLabel = {
  id: "95a80b86-19af-47fd-b46e-a3da28b3feb1",
  description: "A short action phrase to use confirm the use of data that a customer has entered.",
  defaultMessage: "Use what they entered",
};

export const MSG_unrecognizedPianoTitle = {
  id: "4f320622-6397-43e8-9c1f-fc3c6e86aacb",
  defaultMessage: "Unrecognized Piano",
};

export const MSG_updatePianoData = {
  id: "ae9e26ca-2788-44de-b176-442d5643a3cd",
  description: "",
  defaultMessage: "The piano on the reservation has different data than the piano record you selected.",
};

export const MSG_keepExistingData = {
  id: "24820fd7-dca5-4f53-9af7-7db65dfec088",
  description: "",
  defaultMessage: "Keep existing piano's data.",
};

export const MSG_useExistingPianoTitle = {
  id: "0e09672b-cfbd-4ab2-abaf-4733257be7a6",
  description: "This is the header on top of a piano item when they are choosing whether to use new piano data entered by the client, or use existing piano information that already exists in the database.",
  defaultMessage: "Use This Existing Piano"
};

export const MSG_noMatchingPianoFoundQuestion = {
  id: "7970e84c-dcee-4e23-9890-859087b0df4e",
  description: "",
  defaultMessage: "A matching piano could not be found on file with this client.  What would you like to do?"
};

export const MSG_requestedServicesTitle = {
  id: "72ccae89-506f-47de-8a23-adedc411a6be",
  description: "This is a short label for the services that a customer has requested when they fill out the self scheduler.",
  defaultMessage: "Requested Services",
};

export const MSG_createNewPianoRecordLabel = {
  id: "6d89d108-405e-49f4-84a0-f71c790b1171",
  description: "A button label to create a new piano record.",
  defaultMessage: "Create a new piano record",
};

export const MSG_sendEmailResponseQuestion = {
  id: "df47a14a-6955-4ad6-9a88-e4ca02fc3cc8",
  description: "A label to trigger sending a response to a client over email.  This should be phrased as a question.",
  defaultMessage: "Send email response?",
};

export const MSG_sendApprovalEmailResponseNotice = {
  id: "00afc9c0-ae01-4a95-ac08-f75c06764502",
  description: "",
  defaultMessage: "Without customizing, the default approval message will be sent."
};

export const MSG_sendDeclineEmailResponseNotice = {
  id: "6f3f580e-5302-4342-89e1-7ea250b7e2a8",
  description: "",
  defaultMessage: "Without customizing, the default declining message will be sent."
};

export const MSG_customizeEmailMessageLabel = {
  id: "802332d4-b029-49f8-b08a-70ad33753be9",
  description: "A short label for a button to customize an email message (as opposed to using a template).",
  defaultMessage: "Customize email message",
};

export const MSG_emailMessageTitle = {
  id: "44601b01-10c0-4db1-a52d-2953a3d85faf",
  description: "A label for a screen showing an email message.",
  defaultMessage: "Email Message",
};

export const MSG_deleteEventFromReservationLabel = {
  id: "44f77125-0327-496b-9427-792c7fa101b3",
  defaultMessage: "Delete appointment on {date}"
};

export const MSG_notesFromClientLabel = {
  id: "8793c3fa-1665-426a-900b-2a195aad9b9f",
  description: "",
  defaultMessage: "Notes from client"
};

export const MSG_showOnMapTitle = {
  id: "4769daf7-7931-4e89-a8c6-c923095e84cf",
  description: "",
  defaultMessage: "Show On Map"
};

export const MSG_reservationTimeTitle = {
  id: "39ac902e-4563-430e-b9e9-fc56bbac8bfa",
  description: "A label for the time that an appointment is reserved.",
  defaultMessage: "Reservation Time",
};

export const MSG_editCreatedAppointmentTitle = {
  id: "fd5c1a5b-2db9-42cc-8ee0-ec11ae2f6535",
  defaultMessage: "Edit Created Appointment",
};

export const MSG_pendingReservationsLabel = {
  id: "bcab0254-e453-48ee-a20b-9f40b75040bc",
  defaultMessage: "Pending reservations",
};

export const MSG_alreadyApproved = {
  id: '7fd51fac-c82f-40a9-9903-76b823ac8991',
  defaultMessage: 'This reservation has already been approved.'
};

export const MSG_alreadyDeclined = {
  id: 'dccf5083-088e-4c91-9280-0d189f0a1b01',
  defaultMessage: 'This reservation has already been declined.'
};

export const MSG_clientCurrentlyInactiveLabel = {
  id: "1009865c-63a2-43d3-9de2-32dbb4fc613e",
  defaultMessage: "This client is currently inactive.  Approving this reservation will change the client's status back to active."
};

export const MSG_anotherReservationAlreadyExistsLabel = {
  id: "9cea6350-4394-4ac5-b597-8b2c1d596431",
  defaultMessage: "Another reservation exists for this client on {date} at {time}."
};

export const MSG_otherReservationsExistLabel = {
  id: "f269d9e7-257f-4de2-9b2f-584847618fdd",
  defaultMessage: "Other reservations exist for this client."
};

export const MSG_approvalOptInWillChangeContactWantsText = {
  id: "20cfd918-9970-422f-8264-510948638a45",
  defaultMessage: "While placing this reservation the client chose to opt out of SMS reminders.  Approving the reservation will modify the record to no longer want SMS communication."
};

export const MSG_editReservationTitle = {
  id: "12a4f621-64cc-422b-8305-61045058319c",
  description: "",
  defaultMessage: "Edit Reservation"
};

export const MSG_editReservationWarning = {
  id: "29b66809-cc10-4c26-88ee-1de43cb014bf",
  description: "",
  defaultMessage: "This time slot was optimized for client preferences, traffic, drive time, and proximity to other appointments on your calendar. Manually changing this reservation will change its proximity to surrounding appointments."
};

export const MSG_removeBufferTitle = {
  id: "89929f14-8b52-43ea-82c8-d868f164dddc",
  description: "",
  defaultMessage: "Remove Buffer"
};

export const MSG_reservationDateLabel = {
  id: "21b5240a-4b40-4580-b19d-553922f32388",
  description: "The label for the date at which a reservation was made.",
  defaultMessage: "Reservation date"
};

export const MSG_reservationDateAfterOrOnLabel = {
  id: "4fb89897-38fc-4972-a40c-4d489546e950",
  description: "The label for filtering reservations based on the date for which the reservation was made.",
  defaultMessage: "Reservation date after (or on)"
};

export const MSG_reservationDateBeforeOrOnLabel = {
  id: "43b51ac4-4673-4b67-8ce8-e5fe95a6f1b2",
  description: "The label for filtering reservations based on the date for which the reservation was made.",
  defaultMessage: "Reservation date before (or on)"
};
