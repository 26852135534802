import * as React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface ILargeErrorMessageProps {
  icon?: IconProp,
  title: React.ReactElement | string,
  message: React.ReactElement | string,
}
class LargeErrorMessage extends React.Component<ILargeErrorMessageProps> {
  render() {
    return (
      <div className="large-error-message">
        <div className="error-icon">
          <FontAwesomeIcon icon={faExclamationTriangle}/>
        </div>
        <h4>{this.props.title}</h4>
        <div className="mt-3">
          {this.props.message}
        </div>
      </div>
    );
  }
}

export { LargeErrorMessage };
