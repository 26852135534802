export const MSG_userTypeAdmin = {
  id: 'ab78c328-120c-4c61-a8dc-c90bc734abe1',
  description: 'The access level named "admin".  These are admin users who have full site access.',
  defaultMessage: 'Admin',
};

export const MSG_userTypeTechnician = {
  id: 'c8343636-c275-4fe6-bb30-bc513eb35896',
  description: 'The access level named "technician".  These are users who can be scheduled for appointments.',
  defaultMessage: 'Technician',
};

export const MSG_userTypeAssistant = {
  id: 'a1a84f7a-8aee-4ad3-9800-b8d60832a1b5',
  description: 'The access level named "assistant".  These are users who help out but do not necessarily have full access and cannot be scheduled.',
  defaultMessage: 'Assistant',
};

export const MSG_addressTypeStreet = {
  id: 'b03791f4-363d-474d-9df9-1c2321c0d7aa',
  description: 'The type of address known as a "Street" address (i.e. the address that gets you to their home/building)',
  defaultMessage: 'Street Address',
};

export const MSG_addressTypeMailing = {
  id: '2a69ca07-b452-4674-b88c-74eb244861c6',
  description: 'The type of address known as a "Mailing" address (i.e. the address where mail is sent, could be a P.O. Box, or a street address, etc)',
  defaultMessage: 'Mailing Address',
};

export const MSG_addressTypeBilling = {
  id: '9f83450e-24d8-46f1-98ec-2fbfbe404b4e',
  description: 'The type of address known as a "Billing" address (i.e. where invoices are mailed to)',
  defaultMessage: 'Billing Address',
};

export const MSG_calendarTitleTypeTitle = {
  id: "89d245e3-a375-44de-8833-7d466272ed3c",
  description: "This is one of the options they can select for what to display for appointments on their calendar.",
  defaultMessage: "Show title (default)",
};

export const MSG_calendarTitleTypeTitleCity = {
  id: "ec7fc41e-8002-4306-a74b-51a80c6c01ef",
  description: "This is one of the options they can select for what to display for appointments on their calendar.",
  defaultMessage: "Show title & city"
};

export const MSG_calendarTitleTypeTitlePostalCode = {
  id: "6c8cd9d3-4396-4ec1-aebc-74105826133d",
  description: "This is one of the options they can select for what to display for appointments on their calendar.",
  defaultMessage: "Show title & postal code"
};

export const MSG_calendarTitleTypeName = {
  id: "43b2de59-f03f-4e78-9377-e888f695234d",
  description: "This is one of the options they can select for what to display for appointments on their calendar.",
  defaultMessage: "Show client name",
};

export const MSG_calendarTitleTypeNamePostalCode = {
  id: "9f4bfe96-52d2-49ad-88ac-32690df3445b",
  description: "This is one of the options they can select for what to display for appointments on their calendar.",
  defaultMessage: "Show client name & postal code",
};

export const MSG_calendarTitleTypeNameCity = {
  id: "117dacde-3120-4e14-8a18-3cf8885128d1",
  description: "This is one of the options they can select for what to display for appointments on their calendar.",
  defaultMessage: "Show client name & city",
};

export const MSG_calendarTitleTypePostalCode = {
  id: "411099cf-3dc2-45ed-805d-a5a4d3cf63a5",
  description: "This is one of the options they can select for what to display for appointments on their calendar.",
  defaultMessage: "Show postal code",
};

export const MSG_calendarTitleTypeCity = {
  id: "a9bbd1c4-fdb6-4911-9bbf-e0d5634df344",
  description: "This is one of the options they can select for what to display for appointments on their calendar.",
  defaultMessage: "Show city",
};

export const MSG_calendarTitleTypePianos = {
  id: "33745a20-863c-46ea-81a5-f77f2e931d91",
  description: "This is one of the options they can select for what to display for appointments on their calendar.",
  defaultMessage: "Show piano(s)",
};

export const MSG_clientStatusProspect = {
  id: '395bbd67-5c83-40c4-8663-ea682a1b3d18',
  description: 'The client status of "prospect".  A prospect client is one that is not actually a client yet, but you are trying to get them to become a client.  They have not scheduled any appointments yet.',
  defaultMessage: 'Prospect',
};

export const MSG_clientStatusNew = {
  id: '23582943-53bb-4749-9c14-8ae43aa369a0',
  description: 'The client status of "new".  A new client is one that has scheduled an appointment in the future, but has not had any past appointments yet.  This would be their first appointment.',
  defaultMessage: 'New',
};

export const MSG_clientStatusActive = {
  id: '82efa5db-81ca-49b5-8bf5-11bdd4ed4c04',
  description: 'The client status of "active".  An active client is one who has had appointments, may have invoices, estimates, and may be receiving reminders.  Most clients are in this state.',
  defaultMessage: 'Active',
};

export const MSG_clientStatusInactive = {
  id: '32de2b90-f2fd-4a04-ac6d-18bfde00c632',
  description: 'The client status of "inactive".  An inactive client does not have any appointments, and does not receive reminders.  They exist in the database only to retain history, or in case the client comes back and wants to schedule again.',
  defaultMessage: 'Inactive',
};

export const MSG_eventRecurrenceEndingTypeOccurrences = {
  id: "d2b59d08-f3f0-4d79-a15b-72866afc43fb",
  description: "The label for ending an repeating event after a certain number of times (e.g. it ends after 10 occurrences).",
  defaultMessage: "After a number of times",
};

export const MSG_eventRecurrenceEndingTypeDate = {
  id: "70b1d145-ee0c-4c30-9c5a-4f37b18758f7",
  description: "The label for ending a repeating event on a specific date",
  defaultMessage: "On a specific date",
};

export const MSG_eventRecurrenceTypeWeekly = {
  id: '71bdd373-3666-4290-a63c-e1b1b6b66e45',
  description: 'The type of recurring event when it occurs every week.',
  defaultMessage: 'Weekly',
};

export const MSG_eventRecurrenceTypeMonthly = {
  id: '15d30093-1229-446b-a0f6-7084406b0bed',
  description: 'The type of recurring event when it occurs every month.',
  defaultMessage: 'Monthly',
};

export const MSG_eventReservationStatusPending = {
  id: '97589ae5-146b-4c76-8e12-baa79f7d1308',
  description: 'When a reservation is pending approval (e.g. waiting for it to be approved).',
  defaultMessage: 'Pending',
};

export const MSG_eventReservationStatusApproved = {
  id: '2061cd7e-4524-4716-98ef-ca4aeba082a8',
  description: 'When a reservation has been approved',
  defaultMessage: 'Approved',
};

export const MSG_eventReservationStatusDeclined = {
  id: '3e152c44-0121-48fe-a6f8-dd41c8f86551',
  description: 'When a reservation has been declined',
  defaultMessage: 'Declined',
};

export const MSG_eventTypeAppointment = {
  id: "93465b9b-8fd8-4f99-abe4-f385d425a7e3",
  description: "A label for the type of event called an 'appointment'.  In Gazelle, an 'appointment' event is an event on the calendar that is scheduled with a client.",
  defaultMessage: "Appointment"
};

export const MSG_eventTypePersonalEvent = {
  id: "f03e6847-3a4d-4a16-89a6-40f3fef7e23d",
  description: "A label for the type of event called 'personal'.  In Gazelle, a 'personal' event is time blocked off their calendar so it cannot be scheduled for any other purpose.  It is generally used to reserve time for personal events like a doctor's appointment, or a vacation day, etc.",
  defaultMessage: "Personal Event"
};

export const MSG_eventTypeMemo = {
  id: "965dadff-9915-47cc-baaa-0d5b3357b62d",
  description: "A label for the type of event called 'memo'.  In Gazelle, a 'memo' is an event on their calendar, but it does NOT block off that time slot from being used for something else.  Clients can still schedule an appointment over top of a 'memo' event.  It is generally used for reminders like birthdays, etc.",
  defaultMessage: "Memo"
};

export const MSG_eventTypeSynced = {
  id: "72539f52-3704-40c6-b60d-8791808f034d",
  description: "A label for the type of event called 'synced'.  In Gazelle, a 'synced' event is an event that is imported and kept up to date (aka 'synced') from a third party calendar.  For example, someone might want to import their family's shared calendar to their Gazelle account.",
  defaultMessage: "Remote Calendar Event"
};

export const MSG_reasonInactiveCategoryOther = {
  id: "8ea2f8bb-a522-4b7b-8148-903ec84bc011",
  description: "This is a category when selecting a reason why a client was marked inactive.",
  defaultMessage: "Other"
};

export const MSG_reasonInactiveCategoryFutureWork = {
  id: "079d8d8f-5450-4df6-8dae-3504c8fe8df1",
  description: "This is a category when selecting a reason why a client was marked inactive.",
  defaultMessage: "Possible future work"
};

export const MSG_reasonInactiveCategoryBadPiano = {
  id: "24df32b5-abaa-4e69-9cc3-2e9ee6300e2b",
  description: "This is a category when selecting a reason why a client was marked inactive.",
  defaultMessage: "Client is willing, but piano is not"
};

export const MSG_reasonInactiveCategoryNoContact = {
  id: "d066334c-bc09-4335-a61e-119f10ba165c",
  description: "This is a category when selecting a reason why a client was marked inactive.",
  defaultMessage: "Never contact this client again"
};

export const MSG_reasonInactiveCodeNone = {
  id: "9f2c319b-e477-44e4-a1f7-b9f53e078491",
  description: "This is a reason they can select for why a client was marked 'inactive'.",
  defaultMessage: "None"
};

export const MSG_reasonInactiveCodeO1 = {
  id: "af6626d4-fc59-40cf-a3eb-03cb7c9d0e36",
  description: "This is a reason they can select for why a client was marked 'inactive'.",
  defaultMessage: "Other reason"
};


export const MSG_reasonInactiveCodeA1 = {
  id: "17b7f517-796d-4bb2-9c4e-caaab4e429b8",
  description: "This is a reason they can select for why a client was marked 'inactive'.",
  defaultMessage: "Call in distant future"
};

export const MSG_reasonInactiveCodeA2 = {
  id: "8f204199-dd5b-4d2b-a3e6-76fe76299c89",
  description: "This is a reason they can select for why a client was marked 'inactive'.",
  defaultMessage: "Christmas last minute clients"
};

export const MSG_reasonInactiveCodeA3 = {
  id: "cfae4c1e-f8d4-45ba-88f5-2ff4c8c353ac",
  description: "This is a reason they can select for why a client was marked 'inactive'.",
  defaultMessage: "Fire piano inquiry"
};

export const MSG_reasonInactiveCodeA4 = {
  id: "3a2d725a-152a-4a1c-9818-9d2d6662444d",
  description: "This is a reason they can select for why a client was marked 'inactive'.",
  defaultMessage: "Gave up calling/reminding"
};

export const MSG_reasonInactiveCodeA5 = {
  id: "56e114ea-4c73-469c-b0b4-5b9ff946c683",
  description: "This is a reason they can select for why a client was marked 'inactive'.",
  defaultMessage: "Hopes to use piano in the future"
};

export const MSG_reasonInactiveCodeA6 = {
  id: "31f582d8-55ad-4787-a385-763b31b60d25",
  description: "This is a reason they can select for why a client was marked 'inactive'.",
  defaultMessage: "Is considering buying a piano"
};

export const MSG_reasonInactiveCodeA7 = {
  id: "2acc9989-a23f-4170-b0a5-2bf54f998c2e",
  description: "This is a reason they can select for why a client was marked 'inactive'.",
  defaultMessage: "Money is tight"
};

export const MSG_reasonInactiveCodeA8 = {
  id: "3dd4f682-8a50-447e-bba7-1685d9ced98c",
  description: "This is a reason they can select for why a client was marked 'inactive'.",
  defaultMessage: "Moving damage inquiry"
};

export const MSG_reasonInactiveCodeA9 = {
  id: "b22e62f7-99b3-407a-b84a-a2ad5380a9ce",
  description: "This is a reason they can select for why a client was marked 'inactive'.",
  defaultMessage: "Need something sooner"
};

export const MSG_reasonInactiveCodeA10 = {
  id: "7b2948a7-1053-4709-95b7-2e3645f3d541",
  description: "This is a reason they can select for why a client was marked 'inactive'.",
  defaultMessage: "New client inquiry"
};

export const MSG_reasonInactiveCodeA11 = {
  id: "6d1b7be5-2ebc-405a-8f3e-87658dad0f0b",
  description: "This is a reason they can select for why a client was marked 'inactive'.",
  defaultMessage: "Other - but still try"
};

export const MSG_reasonInactiveCodeA12 = {
  id: "c33215de-e93d-47b5-97a1-ca0463b6f520",
  description: "This is a reason they can select for why a client was marked 'inactive'.",
  defaultMessage: "Sold piano, looking for another"
};

export const MSG_reasonInactiveCodeA13 = {
  id: "0c2376bc-bfa0-48ec-9e3b-04a9c9f34571",
  description: "This is a reason they can select for why a client was marked 'inactive'.",
  defaultMessage: "Used on special occasions only"
};

export const MSG_reasonInactiveCodeA14 = {
  id: "f01dc9e6-f2f4-4078-bbe8-43282ca04c10",
  description: "This is a reason they can select for why a client was marked 'inactive'.",
  defaultMessage: "Will call me"
};

export const MSG_reasonInactiveCodeA15 = {
  id: "c0c0a76f-8eb8-4627-bf06-3f06a5c2d1c3",
  description: "This is a reason they can select for why a client was marked 'inactive'.",
  defaultMessage: "Will tune if discounted"
};


export const MSG_reasonInactiveCodeB1 = {
  id: "c6bda3bc-db62-49dc-a72b-a9e056824416",
  description: "This is a reason they can select for why a client was marked 'inactive'.",
  defaultMessage: "Appraisal client (keeping piano)"
};

export const MSG_reasonInactiveCodeB2 = {
  id: "c08a1318-c176-45e5-a553-020bc44677a8",
  description: "This is a reason they can select for why a client was marked 'inactive'.",
  defaultMessage: "Looked at a new piano for them, they are still looking"
};

export const MSG_reasonInactiveCodeB3 = {
  id: "5c4d0aba-e0b4-436c-9ee4-cf97a2708216",
  description: "This is a reason they can select for why a client was marked 'inactive'.",
  defaultMessage: "Piano is junk, not looking for another"
};

export const MSG_reasonInactiveCodeB5 = {
  id: "febb7357-8ff3-472a-bbca-e0274d46389a",
  description: "This is a reason they can select for why a client was marked 'inactive'.",
  defaultMessage: "Piano is junk, they are looking for another"
};

export const MSG_reasonInactiveCodeB4 = {
  id: "906b9f42-4fea-41fb-acef-ae2450261310",
  description: "This is a reason they can select for why a client was marked 'inactive'.",
  defaultMessage: "Piano needs to be rebuilt"
};

export const MSG_reasonInactiveCodeB6 = {
  id: "bfd8fc31-47fc-4a0d-a159-558ffcaeb5bd",
  description: "This is a reason they can select for why a client was marked 'inactive'.",
  defaultMessage: "Saving for major repairs"
};

export const MSG_reasonInactiveCodeB7 = {
  id: "ae4aeabd-2761-43e6-af4a-4eda08b3714c",
  description: "This is a reason they can select for why a client was marked 'inactive'.",
  defaultMessage: "Saving for minor repairs"
};

export const MSG_reasonInactiveCodeB9 = {
  id: "f7bcf409-a6d5-4c70-8ed0-6399e59a415b",
  description: "This is a reason they can select for why a client was marked 'inactive'.",
  defaultMessage: "Special occasion tuning client"
};


export const MSG_reasonInactiveCodeC1 = {
  id: "5b791e38-18e7-4288-9bec-7960c1907736",
  description: "This is a reason they can select for why a client was marked 'inactive'.",
  defaultMessage: "Bad client - I don't like them"
};

export const MSG_reasonInactiveCodeC2 = {
  id: "d278aaf5-8b78-45da-bf12-0a85ba8e06c3",
  description: "This is a reason they can select for why a client was marked 'inactive'.",
  defaultMessage: "Bad review"
};

export const MSG_reasonInactiveCodeC3 = {
  id: "f482d896-9090-4c78-a38e-e6df759039e1",
  description: "This is a reason they can select for why a client was marked 'inactive'.",
  defaultMessage: "Can't sync schedules"
};

export const MSG_reasonInactiveCodeC4 = {
  id: "b5b2abce-73ba-42d0-94c2-4d2d86dcba21",
  description: "This is a reason they can select for why a client was marked 'inactive'.",
  defaultMessage: "Cold referral"
};

export const MSG_reasonInactiveCodeC5 = {
  id: "71cede13-ee61-4974-9618-717699559c3c",
  description: "This is a reason they can select for why a client was marked 'inactive'.",
  defaultMessage: "Contact info is incorrect"
};

export const MSG_reasonInactiveCodeC6 = {
  id: "1f5f6cb0-0e98-4f25-8ec0-106c23ed2bdb",
  description: "This is a reason they can select for why a client was marked 'inactive'.",
  defaultMessage: "Contact info is missing"
};

export const MSG_reasonInactiveCodeC7 = {
  id: "e4ee3645-1c29-40d5-9945-5c561db63df0",
  description: "This is a reason they can select for why a client was marked 'inactive'.",
  defaultMessage: "Deceased"
};

export const MSG_reasonInactiveCodeC8 = {
  id: "9ed0ce7a-47f6-4a42-9f87-3043eb482acf",
  description: "This is a reason they can select for why a client was marked 'inactive'.",
  defaultMessage: "Decided not to purchase a piano"
};

export const MSG_reasonInactiveCodeC9 = {
  id: "759bcf14-99f8-42d7-950b-81496ceb1459",
  description: "This is a reason they can select for why a client was marked 'inactive'.",
  defaultMessage: "Digital piano repair client"
};

export const MSG_reasonInactiveCodeC10 = {
  id: "88040fd6-49ba-49e1-9601-02205f2b8e4b",
  description: "This is a reason they can select for why a client was marked 'inactive'.",
  defaultMessage: "Digital piano repair inquiry"
};

export const MSG_reasonInactiveCodeC11 = {
  id: "feb647c8-b602-4242-948c-1564a44bf506",
  description: "This is a reason they can select for why a client was marked 'inactive'.",
  defaultMessage: "Does not need/want our services any more"
};

export const MSG_reasonInactiveCodeC12 = {
  id: "ea1ba963-4377-4b60-a59c-83522f13af33",
  description: "This is a reason they can select for why a client was marked 'inactive'.",
  defaultMessage: "Duplicate record"
};

export const MSG_reasonInactiveCodeC13 = {
  id: "c0ea617a-17a2-4d17-9ae8-d2fd4025c8bf",
  description: "This is a reason they can select for why a client was marked 'inactive'.",
  defaultMessage: "Moved out of service area"
};

export const MSG_reasonInactiveCodeC14 = {
  id: "9520af02-3382-43ba-a064-8f1e3d4827be",
  description: "This is a reason they can select for why a client was marked 'inactive'.",
  defaultMessage: "Moving client inquiry"
};

export const MSG_reasonInactiveCodeC15 = {
  id: "2b6c9030-f336-43f9-a719-2f5e3558a1db",
  description: "This is a reason they can select for why a client was marked 'inactive'.",
  defaultMessage: "Outside service area"
};

export const MSG_reasonInactiveCodeC16 = {
  id: "a8fded96-ee0a-4a5c-a460-8909160560d6",
  description: "This is a reason they can select for why a client was marked 'inactive'.",
  defaultMessage: "Piano is not being used"
};

export const MSG_reasonInactiveCodeC17 = {
  id: "3b794a26-06eb-45a1-bc25-551b673e33ca",
  description: "This is a reason they can select for why a client was marked 'inactive'.",
  defaultMessage: "Potential repairs are too expensive"
};

export const MSG_reasonInactiveCodeC18 = {
  id: "552127b5-32ae-4628-b858-dbeffec09b76",
  description: "This is a reason they can select for why a client was marked 'inactive'.",
  defaultMessage: "Price increase rebellion"
};

export const MSG_reasonInactiveCodeC19 = {
  id: "8feebf5e-83a3-466d-b015-5f4fa02cf083",
  description: "This is a reason they can select for why a client was marked 'inactive'.",
  defaultMessage: "Price shopper"
};

export const MSG_reasonInactiveCodeC20 = {
  id: "7994d77e-d623-4f47-aceb-afd6649dc1b3",
  description: "This is a reason they can select for why a client was marked 'inactive'.",
  defaultMessage: "Remove from mailing list request"
};

export const MSG_reasonInactiveCodeC21 = {
  id: "b3156b14-3c02-48fe-9039-8c0ad37b2a11",
  description: "This is a reason they can select for why a client was marked 'inactive'.",
  defaultMessage: "Sold piano"
};

export const MSG_reasonInactiveCodeC22 = {
  id: "e98616c8-e86f-4047-bb33-cf156a14527d",
  description: "This is a reason they can select for why a client was marked 'inactive'.",
  defaultMessage: "Uses another technician"
};

export const MSG_reasonInactiveCodeC23 = {
  id: "7f086484-89af-47cd-a18f-360c5746a889",
  description: "This is a reason they can select for why a client was marked 'inactive'.",
  defaultMessage: "Wants to sell piano"
};

export const MSG_mslTypeLaborFixedRate = {
  id: '1ceba1fb-3c18-460a-8542-897f91d405ce',
  description: 'A type of invoice item where the cost is the same regardless of how long the service takes to perform.',
  defaultMessage: 'Fixed Rate Labor'
};

export const MSG_mslTypeLaborHourly = {
  id: 'fc2c4a0d-f61a-46ad-bb76-ea2219d1cb28',
  description: 'A type of invoice item where the cost is dependent upon how long the service takes to perform.',
  defaultMessage: 'Hourly Labor'
};

export const MSG_mslTypeExpense = {
  id: 'd2260e20-7059-4ad3-b859-0425143d7ef6',
  description: 'A type of invoice item where the cost includes parts.',
  defaultMessage: 'Parts'
};

export const MSG_mslTypeMileage = {
  id: '3960021e-93f8-4635-9248-2c6d80831dfb',
  description: 'A type of invoice item that covers transportation costs.',
  defaultMessage: 'Mileage'
};

export const MSG_invoicePaymentStatusCanceled = {
  id: "b6e011ae-09bb-4b0f-a34c-121b7ab65e78",
  description: "A message that is displayed when an invoice payment has been canceled.",
  defaultMessage: "Canceled"
};

export const MSG_invoicePaymentStatusDisputed = {
  id: "c38e4254-55b9-4d4d-81b2-936166cee8c3",
  description: "A message that is displayed when an invoice payment has been disputed.",
  defaultMessage: "Disputed"
};

export const MSG_invoicePaymentStatusFailed = {
  id: "717f00a2-15d8-4fa7-a261-13cb017c997f",
  description: "A message that is displayed when an invoice payment has failed.",
  defaultMessage: "Failed"
};

export const MSG_invoicePaymentStatusProcessing = {
  id: "e4fcc482-9d7f-4469-ad40-5ddd9608fa2f",
  description: "A message that is displayed when an invoice payment is being processed.",
  defaultMessage: "Processing"
};

export const MSG_invoicePaymentStatusRefunded = {
  id: "dc3bf3e4-e3dd-40cc-bd90-7b48f320513f",
  description: "A message that is displayed when an invoice payment has been refunded.",
  defaultMessage: "Refunded"
};

export const MSG_invoicePaymentStatusSucceeded = {
  id: "ad2cc6a6-65ac-40de-88fc-4289bd8d47ca",
  description: "A message that is displayed when an invoice payment has succeeded.",
  defaultMessage: "Succeeded"
};

export const MSG_invoicePaymentStatusRequiresPaymentMethodTitle = {
  id: "b8bdefe5-f2f1-4408-a59c-2497a6cd7593",
  description: "A message that is displayed when an invoice payment has needs a payment method.",
  defaultMessage: "Requires Payment Method"
};

export const MSG_invoicePaymentStatusRequiresConfirmationTitle = {
  id: "57acd9db-65af-4f9b-a934-346a5502c57a",
  description: "A message that is displayed when an invoice payment has needs confirmation.",
  defaultMessage: "Requires Confirmation"
};

export const MSG_invoicePaymentStatusRequiresActionTitle = {
  id: "245f7d1a-17b9-4ca3-be99-bfbfa1a3f9f6",
  description: "A message that is displayed when an invoice payment has needs further action.",
  defaultMessage: "Requires Action"
};

export const MSG_invoicePaymentStatusRequiresCaptureTitle = {
  id: "2d22db08-fe20-4e99-858b-215f5c7ec397",
  description: "A message that is displayed when an invoice payment has needs to be captured.",
  defaultMessage: "Requires Capture"
};

export const MSG_invoicePaymentTypeCash = {
  id: 'a4d11fbd-7a7f-479d-818d-b9843d19973e',
  description: 'The type of payment on an invoice that is made with cash.',
  defaultMessage: 'Cash'
};

export const MSG_invoicePaymentTypeCheck = {
  id: 'b3a5eb4d-1129-4fbd-bbbc-dfb317f2b06a',
  description: 'The type of payment on an invoice that is made by check.',
  defaultMessage: 'Check'
};

export const MSG_invoicePaymentTypeCreditCard = {
  id: '1c7e5161-ff88-4f1d-8329-fdbfaf40d927',
  description: 'The type of payment on an invoice that is made by credit card.',
  defaultMessage: 'Credit Card'
};

export const MSG_invoicePaymentTypeDebitCard = {
  id: '99f9217c-11f1-4724-a887-c17ec37e6649',
  description: 'The type of payment on an invoice that is made by debit card.',
  defaultMessage: 'Debit Card'
};

export const MSG_invoicePaymentTypeAch = {
  id: 'b185b2b7-1611-4e18-83a8-9216b82cebcd',
  description: 'The type of payment on an invoice that is made by an ACH direct debit, a payment method in the US.',
  defaultMessage: 'ACH Direct Debit'
};

export const MSG_invoicePaymentTypeEtf = {
  id: 'd04444ac-e2ca-49d0-be38-6c0c2e6c2984',
  description: 'The type of payment on an invoice that is made by electronic funds transfer from a banking institution.',
  defaultMessage: 'Electronic Funds Transfer'
};

export const MSG_invoicePaymentTypeOther = {
  id: '584da6f1-2fb4-42e2-9601-a7d94eec348a',
  description: 'Some other type of payment on an invoice (not cash, check, credit card, or electronic fund transfer).',
  defaultMessage: 'Other Payment Method'
};

export const MSG_invoicePaymentTypePaypal = {
  id: "d85c7873-2db4-4805-b240-9707dba8de35",
  description: "The type of payment on an invoice that is made with Paypal (https://www.paypal.com).",
  defaultMessage: "Paypal",
};

export const MSG_invoicePaymentTypeSepa = {
  id: "134e189d-c567-4e90-ab04-93d28e735e7b",
  description: "The type of payment on an invoice that is Single Euro Payments Area (SEPA) Direct Debit, a common payment method in the European Union.",
  defaultMessage: "SEPA Debit",
};

export const MSG_invoicePaymentTypeBancontact = {
  id: "8cb68dc0-9cc0-4769-b1a5-64aa6ffb7e0c",
  description: "The type of payment on an invoice that is Bancontact, a common payment method in Belgium.",
  defaultMessage: "Bancontact",
};

export const MSG_invoicePaymentTypeEps = {
  id: "8679c714-59a9-4312-9534-f226bcb93aa3",
  description: "The type of payment on an invoice that is EPS, a common payment method in Austria.",
  defaultMessage: "EPS",
};

export const MSG_invoicePaymentTypeGiropay = {
  id: "97eab070-2f1f-43c6-865c-1254823bfd97",
  description: "The type of payment on an invoice that is giropay, a common payment method in Germany.",
  defaultMessage: "giropay",
};

export const MSG_invoicePaymentTypeIdeal = {
  id: "cd382479-c24a-44c4-ab70-1d5016c0c566",
  description: "The type of payment on an invoice that is iDEAL, a common payment method in the Netherlands.",
  defaultMessage: "iDEAL",
};

export const MSG_invoicePaymentTypeSofort = {
  id: "18f3dc33-7ce1-45bb-906b-2fdce63b3d00",
  description: "The type of payment on an invoice that is Sofort, a common payment method in Europe.",
  defaultMessage: "Sofort",
};

export const MSG_invoicePaymentTypePrzelewy24 = {
  id: "01ccd49c-b0b8-4564-ada7-ea526677add7",
  description: "The type of payment on an invoice that is Przelewy24, a common payment method in Poland.",
  defaultMessage: "Przelewy24",
};

export const MSG_invoicePaymentTypeVenmo = {
  id: "8fa10fb9-8eac-4738-8f9b-121bb0cc09d5",
  description: "The type of payment on an invoice that is made with Venmo (https://www.venmo.com)",
  defaultMessage: "Venmo",
};

export const MSG_invoicePaymentTypeZelle = {
  id: "e4536903-3e1b-442f-b943-f82484541872",
  description: "The type of payment on an invoice that is made with Zelle (https://www.zellepay.com)",
  defaultMessage: "Zelle",
};

export const MSG_invoiceStatusDraft = {
  id: '5f29513b-fe4e-4c37-98c4-921910142c7f',
  description: 'The status of invoice called a draft.',
  defaultMessage: 'Draft'
};

export const MSG_invoiceStatusOpen = {
  id: '780c36e6-9ed8-429e-bfcd-4a9ad20892fe',
  description: 'The status for an invoice that has been finalized but is not yet paid.',
  defaultMessage: 'Open'
};

export const MSG_invoiceStatusOverdue = {
  id: 'c3785424-ff47-444e-9bca-65536a003338',
  description: 'The status for an invoice that is over due for payment.',
  defaultMessage: 'Overdue'
};

export const MSG_invoiceStatusPaid = {
  id: 'acffd8eb-efc8-4bc0-85bf-08d10bcbe500',
  description: 'The status for an invoice that has been paid by the customer.',
  defaultMessage: 'Paid'
};

export const MSG_invoiceStatusDeleted = {
  id: 'e31e2608-7c88-4e63-baf8-f70a558b25dc',
  description: 'The status for an invoice that has been deleted.',
  defaultMessage: 'Deleted'
};

export const MSG_invoiceStatusCanceled = {
  id: '6a0367cf-5682-4087-a1f3-7e49a003b846',
  description: 'The status for an invoice that has been canceled.',
  defaultMessage: 'Canceled'
};

export const MSG_phoneTypePrimary = {
  id: '8ec66b72-5799-4951-8010-54fe416a36df',
  description: "A label for the phone type 'primary' (i.e. someone's main phone).",
  defaultMessage: "Primary"
};

export const MSG_phoneTypeSecondary = {
  id: '6cadebbb-9595-4ce0-a0be-6466f1f4477f',
  description: "A label for the phone type 'secondary' (i.e. someone's secondary phone).",
  defaultMessage: "Secondary"
};

export const MSG_phoneTypeMobile = {
  id: 'c5e5685b-8f89-4056-818c-bd3fd1c41908',
  description: "A label for the phone type 'mobile' (i.e. someone's mobile phone).",
  defaultMessage: "Mobile"
};

export const MSG_phoneTypeHome = {
  id: 'ae50933e-3f06-4e9e-a80b-c13130a1a0f5',
  description: "A label for the phone type 'home' (i.e. someone's home landline phone).",
  defaultMessage: "Home"
};

export const MSG_phoneTypeWork = {
  id: '0a62fcf5-b033-499f-9c09-38bffd1bb3eb',
  description: "A label for the phone type 'work' (i.e. someone's phone number at their work).",
  defaultMessage: "Work"
};

export const MSG_phoneTypeFax = {
  id: '28316e76-e677-41a8-8933-9beafdb7176f',
  description: "A label for the phone type 'fax' (i.e. someone's fax machine number).",
  defaultMessage: "Fax"
};

export const MSG_reminderTypeEmail = {
  id: '46a434a5-e3a5-4b51-895c-dd3ad0a84592',
  description: 'Email message type.',
  defaultMessage: 'Email',
};

export const MSG_reminderTypeSms = {
  id: '4028b4bf-6f07-4181-b79b-d18dba0815b4',
  description: 'Text message type (sometimes known as SMS in the US).',
  defaultMessage: 'Text Message',
};

export const MSG_reminderTypeCall = {
  id: 'f21cdc9e-e6c7-4a6d-a603-db421eae566d',
  description: 'Phone call message type',
  defaultMessage: 'Phone Call',
};

export const MSG_reminderTypeEmailShort = {
  id: 'f39c749b-94eb-4a2b-8bab-6f95402f73d2',
  description: 'Very short word for email message type.',
  defaultMessage: 'Email',
};

export const MSG_reminderTypeSmsShort = {
  id: 'f3748111-5b7b-4d1e-bfc1-dcb89be10b09',
  description: 'Very short word for text message type (sometimes known as SMS in the US).',
  defaultMessage: 'Text',
};

export const MSG_reminderTypeCallShort = {
  id: 'c47567c5-0e6c-4dc4-b25f-fb87e877b014',
  description: 'Very short word for phone call message type',
  defaultMessage: 'Phone',
};

