import * as React from 'react';
import { Alert, Button, Col, Form, FormGroup, Row } from 'reactstrap';
import { observer } from 'mobx-react';

import {
  AddressTypeahead,
  LoadingIndicator,
  Privacy,
  scrollTop,
  SimpleFormat,
  StepContent,
  StepFooter,
  StickyNote,
  StreetAddressWithMap
} from '@core';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/pro-solid-svg-icons";
import { formatMessage } from "@gazelle/shared/utils";
import {
  MSG_findYourStreetAddressTitle,
  MSG_missingAddressHelp,
  MSG_nextTitle,
  MSG_unableToFindAddressError,
  MSG_verifyYourStreetAddressTitle
} from "@gazelle/shared/strings";
import { rootStoreInstance } from "@client_scheduler/modules/root";

interface IStep1AddressProps {}

const Step1Address = (observer(class Step1Address extends React.Component<IStep1AddressProps> {
  componentDidMount() {
    scrollTop();
  }

  handleAddressSelected = (addressString: any) => {
    const store = rootStoreInstance.addressStore;
    store.addressString = addressString;
    store.verifyingAddress = true;
    store.addressErrorMessage = null;
  };

  handleAddressVerified = (response: any) => {
    const store = rootStoreInstance.addressStore;
    store.verifyingAddress = false;
    store.addressComponents = response.addressComponents.addressComponents;
    store.geocode = response.geocode.geocodedAddress;
  };

  handleError = (errorMessage: string) => {
    const store = rootStoreInstance.addressStore;
    store.verifyingAddress = false;
    store.addressComponents = null;
    store.addressErrorMessage = errorMessage;
  };

  handleNextButtonClicked = () => {
    if (rootStoreInstance.isStepComplete[1]) {
      rootStoreInstance.unlockStep(2);
      rootStoreInstance.setCurrentStep(2);
    }
  };

  render() {
    const rootStore = rootStoreInstance;
    const { companyPhoneNumber, addressStore,
              addressStore: { verifiedAddress, invalidAddress, verifyingAddress,
                addressComponents, geocode, addressErrorMessage }} = rootStoreInstance;

    return (
      <div>
        <StepContent className="step1-address-container" minHeight={rootStoreInstance.minHeight}>
          <h4 className="first-header">{formatMessage(MSG_findYourStreetAddressTitle)}</h4>
          <Form>
            <Row>
              <Col>
                <FormGroup>
                  <AddressTypeahead
                    addressString={addressStore.addressString}
                    typeaheadProvider={rootStore.typeaheadProvider}
                    onChange={address => addressStore.addressString = address}
                    onSelect={this.handleAddressSelected}
                    onVerify={this.handleAddressVerified}
                    onError={this.handleError}
                    autoFocus />
                </FormGroup>

                {addressErrorMessage && <Alert color="danger">{addressErrorMessage}</Alert>}

                {!addressErrorMessage && invalidAddress && (
                  <Alert color="danger">{formatMessage(MSG_unableToFindAddressError)}</Alert>
                )}


                <p className="text-center text-muted">
                  <FontAwesomeIcon icon={faMapMarkerAlt} />{' '}
                  {formatMessage(MSG_missingAddressHelp, {phone: companyPhoneNumber})}
                </p>
              </Col>
            </Row>

            {rootStore.isMobile && rootStore.isGdprRequired && <Privacy gdpr={rootStore.isGdprRequired}/>}

            {!verifyingAddress && !verifiedAddress && rootStore.companySettings.welcomeMessage &&
              <StickyNote className="mt-3">
                <SimpleFormat text={rootStore.companySettings.welcomeMessage}/>
              </StickyNote>
            }
            {verifyingAddress && !verifiedAddress && (
              <div className="verifying-container">
                <LoadingIndicator verifying />
              </div>
            )}

            {verifiedAddress && (
              <div className="overflowing-footer mt-4">
                <h4>{formatMessage(MSG_verifyYourStreetAddressTitle)}{' '}</h4>
                <StreetAddressWithMap address={addressComponents} geocode={geocode}
                                      interactiveMapProvider={rootStoreInstance.interactiveMapProvider}/>
              </div>
            )}
          </Form>
        </StepContent>
        <StepFooter
          rootStore={rootStore}
          rightSide={
            <div>
              <Button size="lg"
                      id="next-button"
                      className="d-none d-md-inline-block"
                      color="primary"
                      disabled={!rootStore.isStepComplete[1]}
                      onClick={this.handleNextButtonClicked}>
                {formatMessage(MSG_nextTitle)}
              </Button>
              <Button className="d-md-none"
                      id="next-button"
                      color="primary"
                      disabled={!rootStore.isStepComplete[1]}
                      onClick={this.handleNextButtonClicked}>
                {formatMessage(MSG_nextTitle)}
              </Button>
            </div>
          }/>
      </div>
    );
  }
}));

export { Step1Address };
