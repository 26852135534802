import * as React from 'react';
import { observer } from 'mobx-react';
import { ClientRootLayout } from '@core';

import { MobileLayout } from './MobileLayout';
import { DesktopLayout } from './DesktopLayout';
import { MSG_noSelfSchedulingServicesError } from "@gazelle/shared/strings";
import { formatMessage } from "@gazelle/shared/utils";
import { rootStoreInstance as rootStore, rootStoreInstance } from "@client_scheduler/modules/root";

interface IRootLayoutProps {
}

const RootLayout = observer(class RootLayout extends React.Component<IRootLayoutProps> {
  render() {
    return (
      <ClientRootLayout
        rootStore={rootStore}
        isInitializing={rootStore.isInitializing}
        displayGenericError={
          rootStoreInstance.masterServiceGroups.length === 0 &&
            formatMessage(MSG_noSelfSchedulingServicesError, {companyName: rootStoreInstance.companyName})
        }
        MobileLayout={MobileLayout}
        DesktopLayout={DesktopLayout}/>
    );
  }
});

export { RootLayout };
