import { makeObservable, observable } from 'mobx';
import { I18nString, MasterServiceGroup, User } from "@core";

interface IMasterServiceItem {
  id: string;
  name: I18nString;
  description: I18nString;
  duration: number;
  amount: number;
  type: string;
  externalUrl: string;
  order: number;
  isDefault: boolean;
  isTuning: boolean;
  isTaxable: boolean;
  isAnyUser: boolean;
  allUsers: User[];
}

class MasterServiceItem implements IMasterServiceItem {
  id: string = null;
  name: I18nString = null;
  description: I18nString = null;
  educationDescription: I18nString = null;
  duration: number = null;
  amount: number = null;
  type: string = null;
  externalUrl: string = null;
  order: number = null;
  isDefault: boolean = null;
  isTuning: boolean = null;
  isTaxable: boolean = null;
  isAnyUser: boolean = null;
  allUsers: User[] = null;
  masterServiceGroup: MasterServiceGroup = null;

  constructor(attrs: any = {}) {
    makeObservable(this, {
      id: observable,
      name: observable,
      description: observable,
      educationDescription: observable,
      duration: observable,
      amount: observable,
      type: observable,
      externalUrl: observable,
      order: observable,
      isDefault: observable,
      isTuning: observable,
      isTaxable: observable,
      isAnyUser: observable,
      allUsers: observable,
      masterServiceGroup: observable
    });

    this.id = attrs.id;
    this.name = new I18nString(attrs.name);
    this.description = new I18nString(attrs.description);
    this.educationDescription = new I18nString(attrs.educationDescription);
    this.duration = attrs.duration || 0;
    this.amount = attrs.amount || 0;
    this.type = attrs.type || 'LABOR_FIXED_RATE';
    this.externalUrl = attrs.externalUrl;
    this.order = attrs.order || 999;
    this.isDefault = attrs.isDefault || false;
    this.isTuning = attrs.isTuning || false;
    this.isTaxable = attrs.isTaxable || false;
    this.isAnyUser = typeof attrs.isAnyUser === 'undefined' ? true : attrs.isAnyUser;

    if (attrs.allUsers) {
      this.allUsers = attrs.allUsers.map((u: any) => new User(u));
    } else {
      this.allUsers = [];
    }

    if (attrs.masterServiceGroup) {
      this.masterServiceGroup = new MasterServiceGroup(attrs.masterServiceGroup);
    }
  }
}

export { MasterServiceItem };
