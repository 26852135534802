import * as React from 'react';
import { Tooltip } from 'reactstrap';
import { makeObservable, observable } from 'mobx';
import { formatMessage } from "../../utils/intl";
import { observer } from "mobx-react";
import { MSG_whatIsThis } from "../../strings";
import { faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropsWithChildren } from "react";

let idCounter = 0;

interface IContextHelpTextProps {
  narrow?: boolean;
  noPadding?: boolean;
  icon?: boolean;
}

const ContextHelpText = observer(class ContextHelpText extends React.Component<PropsWithChildren<IContextHelpTextProps>> {
  id: string;
  isOpen = false;

  constructor(props: IContextHelpTextProps) {
    super(props);

    makeObservable(this, {
      isOpen: observable
    });

    this.id = `context-help-${idCounter++}`;
  }

  render() {
    let className = 'context-help-text';
    if (this.props.narrow) className += ' narrow';
    if (this.props.noPadding) className += ' no-padding';

    return (
      <span>
        <span className={className} id={this.id}>
          {this.props.icon ? <FontAwesomeIcon icon={faQuestionCircle}/> : formatMessage(MSG_whatIsThis)}
        </span>
        <Tooltip target={this.id}
                 isOpen={this.isOpen}
                 delay={150}
                 toggle={() => this.isOpen = !this.isOpen}>
          {this.props.children}
        </Tooltip>
      </span>
    );
  }
});

export { ContextHelpText };
