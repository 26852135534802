import { IMappingInteractiveMap } from "../interactive_map";
import { getGoogleInteractiveMapContainer } from "./google_interactive_map_container";
import { getGoogleInteractiveMapContainerByAddress } from "./google_interactive_map_container_by_address";
import { getGoogleInteractiveItineraryMapContainer } from "./google_interactive_itinerary_map_container";

interface IGoogleMappingInteractiveMapConfig {
  ContainerClass: any;
  ContainerByAddressClass: any;
  ItineraryMapClass: any;
}

class GoogleMappingInteractiveMap implements IMappingInteractiveMap {
  MAP_BACKGROUND_COLOR: string;
  ContainerClass: any;
  ContainerByAddressClass: any;
  ItineraryMapClass: any;

  constructor(config: IGoogleMappingInteractiveMapConfig) {
    this.MAP_BACKGROUND_COLOR = "#f4f4f4";
    this.ContainerClass = config.ContainerClass;
    this.ContainerByAddressClass = config.ContainerByAddressClass;
    this.ItineraryMapClass = config.ItineraryMapClass;
  }
}

export { GoogleMappingInteractiveMap, getGoogleInteractiveMapContainer, getGoogleInteractiveMapContainerByAddress, getGoogleInteractiveItineraryMapContainer };
