import * as React from "react";
import { PropsWithChildren } from "react";
import { observer } from 'mobx-react';

interface IStepContentProps extends PropsWithChildren {
  className?: string;
  minHeight?: number
}

const StepContent = (observer(class StepContent extends React.Component<IStepContentProps> {
  render() {
    let style = {};
    if (this.props.minHeight) {
      style = {minHeight: this.props.minHeight + 25};
    }

    return (
      <div className={`step-content ${this.props.className}`} style={style} >
        {this.props.children}
      </div>
    );
  }
}));

export { StepContent };
