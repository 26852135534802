import * as React from 'react';
import { Button, ListGroup, ListGroupItem, Modal } from "reactstrap";
import { getLocaleStoreInstance } from "@gazelle/shared/utils";
import * as Cookies from "js-cookie";
import { faLanguage } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface IProps {
}

interface IState {
  visible: boolean;
}

function getFlagEmoji(countryCode: string) {
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map(char =>  127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
}

function getLanguageIcon(locale: string, size: number = 24) {
  let countryCode = locale.split("_").map(l => l.toLowerCase())[1];
  return (
    <div className="d-flex flex-row align-content-center justify-content-center">
      {/*<img alt='flag' src={`https://www.countryflags.io/${countryCode}/flat/${size}.png`} height={size} width={size}/>*/}
      <span style={{height: size}}>{getFlagEmoji(countryCode)}</span>
    </div>
  );
}

class LanguageSelector extends React.PureComponent<IProps, IState> {
  selectRef: any;

  constructor(props: IProps) {
    super(props);
    this.state = {visible: false};
  }

  handleLanguageSelected = (locale: string) => {
    this.setState({visible: false}, () => {
      // doing a timeout so the language change doesn't happen until the modal
      // closing animation is complete.
      setTimeout(() => {
        getLocaleStoreInstance().setLocale(locale);
        Cookies.set('locale', locale);
      }, 350);
    });
  };

  render() {
    return (
      <div className="language-selector">
        <Button outline color="primary" onClick={() => {
          this.setState({visible: true});
        }}>
          <FontAwesomeIcon icon={faLanguage}/>
        </Button>
        <Modal isOpen={this.state.visible} toggle={() => this.setState({visible: !this.state.visible})}>
          <ListGroup>
            {getLocaleStoreInstance().getCombinedLocalizations().map(loc => {
              return (
                <ListGroupItem
                  key={loc.locale}
                  className="d-flex flex-row align-content-center"
                  style={{
                    cursor: 'pointer',
                    fontWeight: loc.locale === getLocaleStoreInstance().localization.locale ? 'bold' : null
                  }}
                  onClick={() => this.handleLanguageSelected(loc.locale)}>
                  {getLanguageIcon(loc.locale)}
                  <div style={{marginRight: 15}}/>
                  {getLocaleStoreInstance().getLabelFor(loc.locale)}
                </ListGroupItem>
              );
            })}
          </ListGroup>
        </Modal>
      </div>
    );
  }
}

export { LanguageSelector };
