import * as React from 'react';
import { PropsWithChildren } from 'react';

interface IStickyNoteProps extends PropsWithChildren {
  style?: any,
  className?: string,
}
class StickyNote extends React.Component<IStickyNoteProps> {
  render() {
    return (
      <div
        className={`sticky-note ${this.props.className || ''}`}
        style={this.props.style || {}}>
        {this.props.children}
      </div>
    );
  }
}

export { StickyNote };
