import * as React from 'react';
import { User } from '@core';
import { MSG_anyTechnicianLabel } from "@gazelle/shared/strings";
import { formatMessage } from "@gazelle/shared/utils";

interface IUserSelectorProps {
  user?: User,
  anyUser?: boolean,
  onChange: (user: User|null, isChecked: boolean) => void,
  isDefaultChecked?: boolean,
  isDisabled?: boolean,
}
class UserSelector extends React.Component<IUserSelectorProps> {
  handleChange = (e: any) => {
    if (this.props.anyUser) {
      this.props.onChange(null, e.target.checked);
    } else if (this.props.user) {
      this.props.onChange(this.props.user, e.target.checked);
    }
  };

  render() {
    const { user, isDisabled } = this.props;

    let classNames = ['user-selector'];
    if (isDisabled) {
      classNames.push('disabled');
    }

    let label;
    if (this.props.anyUser) {
      classNames.push('any');
      label = formatMessage(MSG_anyTechnicianLabel);
    } else if (user) {
      label = user.fullName;
    }

    return (
      <label className={classNames.join(" ")}>
        <input type="checkbox" checked={this.props.isDefaultChecked} onChange={this.handleChange}/>
        <span>{label}</span>
      </label>
    );
  }
}

export { UserSelector };
