import * as React from 'react';
import { PropsWithChildren } from 'react';
import { formatMessage } from "@gazelle/shared/utils";
import { MSG_unexpectedError } from "@gazelle/shared/strings";
import * as Sentry from "@sentry/react";

interface IErrorBoundaryProps  extends PropsWithChildren{
  extraMessage?: React.ReactElement | string;
}

class ErrorBoundary extends React.Component<IErrorBoundaryProps> {
  constructor(props: IErrorBoundaryProps) {
    super(props);
  }

  render() {
    return (
      <Sentry.ErrorBoundary fallback={({ error }) => (
        <div style={{padding: 30}}>
          <div className="error-boundary container">
            <div className="error-boundary-wrapper">
              <h1>
                {formatMessage(MSG_unexpectedError)}
              </h1>
              <p>{error.message}</p>
              {this.props.extraMessage && <p>{this.props.extraMessage}</p>}
            </div>
          </div>
        </div>
      )}>
        {this.props.children}
      </Sentry.ErrorBoundary>
    );
  }
}

export { ErrorBoundary };
