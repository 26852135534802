export const CLIENT_TYPES = [
  'Airport',
  'Community Center',
  'Conservatory',
  'Government Agency',
  'Embassy or Foreign Government',
  'Foundation',
  'Funeral Home',
  'Hospital',
  'Hotel',
  'Senior Care Facility',
  'Residential Customer',
  'Museums',
  'Library',
  'Moving Company',
  'Music Association',
  'Music Store',
  'Music Studio',
  'Independent Piano Teacher',
  'Performance Venue',
  'Piano Studio',
  'Recording Studio',
  'Religious Institution',
  'School or University',
  'Other Piano Technician',
  'Other',
  'Restaurant',
  'Country Club',
  'Camp or Retreat Center',
  'Professional Musician',
  'Manufacturer',
].sort();
