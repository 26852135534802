import { computed, makeObservable, observable } from 'mobx';
import * as moment from 'moment-timezone';
import { formatMessage, PianoTypeInfo } from "@gazelle/shared/utils";
import {
  MSG_pianoNameMake,
  MSG_pianoNameMakeModel,
  MSG_pianoNameMakeModelType,
  MSG_pianoNameMakeType,
  MSG_pianoNameWithLocation,
  MSG_pianoNameYearMake,
  MSG_pianoNameYearMakeModel,
  MSG_pianoNameYearMakeModelType,
  MSG_pianoNameYearMakeType,
  MSG_unknownPianoTitle,
  MSG_unknownPianoType
} from "@gazelle/shared/strings";

class Piano {
  id?: string = null;
  type: string = null;
  make: string = null;
  model?: string = null;
  location?: string = null;
  year?: string = null;
  serialNumber?: string = null;
  calculatedLastService?: moment.Moment = null;
  calculatedNextService?: moment.Moment = null;
  potentialPerformanceLevel?: number = null;

  constructor(attrs: any = {}) {
    makeObservable(this, {
      id: observable,
      type: observable,
      make: observable,
      model: observable,
      location: observable,
      year: observable,
      serialNumber: observable,
      calculatedLastService: observable,
      calculatedNextService: observable,
      potentialPerformanceLevel: observable,
      name: computed
    });

    this.id = attrs.id;
    this.type = attrs.type || 'UNKNOWN';
    this.make = attrs.make || '';
    this.model = attrs.model || '';
    this.location = attrs.location || '';
    this.year = attrs.year || '';
    this.serialNumber = attrs.serialNumber || '';
    this.potentialPerformanceLevel = attrs.potentialPerformanceLevel || null;
    if (attrs.calculatedLastService) {
      this.calculatedLastService = moment(attrs.calculatedLastService);
    }
    if (attrs.calculatedNextService) {
      this.calculatedNextService = moment(attrs.calculatedNextService);
    }
  }

  get name(): string {
    const variables = {
      make: this.make,
      model: this.model,
      year: this.year,
      type: PianoTypeInfo[this.type].getLabel()
    };

    let name = null;
    if (this.type === 'UNKNOWN') {
      if (this.make && !this.model && !this.year) {
        name = formatMessage(MSG_pianoNameMake, variables);
      } else if (this.make && this.model && !this.year) {
        name = formatMessage(MSG_pianoNameMakeModel, variables);
      } else if (this.make && !this.model && this.year) {
        name = formatMessage(MSG_pianoNameYearMake, variables);
      } else if (this.make && this.model && this.year) {
        name = formatMessage(MSG_pianoNameYearMakeModel, variables);
      }
    } else {
      if (this.make && !this.model && !this.year) {
        name = formatMessage(MSG_pianoNameMakeType, variables);
      } else if (this.make && this.model && !this.year) {
        name = formatMessage(MSG_pianoNameMakeModelType, variables);
      } else if (this.make && !this.model && this.year) {
        name = formatMessage(MSG_pianoNameYearMakeType, variables);
      } else if (this.make && this.model && this.year) {
        name = formatMessage(MSG_pianoNameYearMakeModelType, variables);
      } else {
        name = formatMessage(MSG_unknownPianoType, variables);
      }
    }
    if (!name) {
      name = formatMessage(MSG_unknownPianoTitle, variables);
    }

    if (this.location) {
      name = formatMessage(MSG_pianoNameWithLocation, {
        pianoName: name,
        location: this.location
      });
    }

    return name;
  }
}

const PIANO_ALL_GQL_QUERY_FIELDS = `
  id type make model location serialNumber year
  calculatedLastService
  calculatedNextService
`;

const PIANO_IDENTITY_GQL_QUERY_FIELDS = `
  id type make model location year serialNumber
`;

export { Piano, PIANO_ALL_GQL_QUERY_FIELDS, PIANO_IDENTITY_GQL_QUERY_FIELDS };
