import * as React from "react";
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/pro-solid-svg-icons";
import { formatMessage } from "@gazelle/shared/utils";
import { MSG_callUsTitle, MSG_contactUsTitle, MSG_emailTitle } from "@gazelle/shared/strings";

interface IStepFooterProps {
  center?: React.ReactElement,
  rightSide?: React.ReactElement,
  leftSide?: React.ReactElement,
  rootStore: {isMobile: boolean, companyPhoneNumber: string, companyEmail: string},
}

const StepFooter = observer(class StepFooter extends React.Component<IStepFooterProps> {
  contactMenuOpen: boolean = false;

  constructor(props: IStepFooterProps) {
    super(props);

    makeObservable(this, {
      contactMenuOpen: observable
    });
  }

  render() {
    let leftSide;

    if (this.props.leftSide) {
      leftSide = this.props.leftSide;
    } else if (this.props.rootStore.isMobile) {
      leftSide = (
        <div>
          <ButtonDropdown direction="up"
            isOpen={this.contactMenuOpen}
            toggle={() => this.contactMenuOpen = !this.contactMenuOpen}>
            <DropdownToggle caret color="outline-primary">
              {formatMessage(MSG_contactUsTitle)}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem href={`tel:${this.props.rootStore.companyPhoneNumber}`}>
                <FontAwesomeIcon icon={faPhone}/>&nbsp;{formatMessage(MSG_callUsTitle)}
              </DropdownItem>
              {' '}
              <DropdownItem href={`mailto:${this.props.rootStore.companyEmail}`}>
                <FontAwesomeIcon icon={faEnvelope}/>&nbsp;{formatMessage(MSG_emailTitle)}
              </DropdownItem>
            </DropdownMenu>
          </ButtonDropdown>
        </div>
      );
    }

    return (
      <div className="step-footer">
        <div className="step-footer-content">
          <div className="float-start">
            {leftSide}
          </div>

          <div className="text-center">
            {this.props.center}
          </div>

          <div className="text-end">
            {this.props.rightSide}
          </div>
        </div>
      </div>
    );
  }
});

export { StepFooter };
