export const MSG_estimateNumberTitle = {
  id: "43f51b9b-a000-46f8-9a00-49fea7c167e2",
  description: "This is the title for an estimate.  It will read like 'Estimate #123' in English",
  defaultMessage: "Estimate #{estimateNumber}"
};

export const MSG_estimateNumberWithCompanyNameTitle = {
  id: 'fadf5f3e-addb-488f-a426-9c98f834cd6b',
  description: 'The page title displayed in the browser tab or window header.  This should have the company name first, followed by "Estimate 123"',
  defaultMessage: '{companyName} - Estimate {num}'
};

export const MSG_editEstimateDetails = {
  id: "6bda57ea-aa40-463c-a153-6d24bf136bed",
  description: "",
  defaultMessage: "Edit Estimate Details",
};

export const MSG_bookNowTitle = {
  id: "270a3f45-2f3e-4f7f-ae30-26888f59afb2",
  description: "The label for the button where they can book a new appointment.",
  defaultMessage: "Book Now"
};

export const MSG_beginnerTargetTitle = {
  id: "a8e69ba4-fe51-41ad-9711-472701115744",
  description: "The level of 'beginner' on the scale of how proficient the player of a piano is.  The scale is: beginner < advanced < professional < concert",
  defaultMessage: "Beginner"
};

export const MSG_advancedTargetTitle = {
  id: "ab7923d0-05a2-46e7-8bc3-760ddeef17f1",
  description: "The level of 'advanced' on the scale of how proficient the player of a piano is.  The scale is: beginner < advanced < professional < concert",
  defaultMessage: "Advanced"
};

export const MSG_professionalTargetTitle = {
  id: "d5c6b515-e51d-4a05-b522-2257e8d790c1",
  description: "The level of 'professional' on the scale of how proficient the player of a piano is.  The scale is: beginner < advanced < professional < concert",
  defaultMessage: "Professional"
};

export const MSG_concertTargetTitle = {
  id: "f456a565-3de8-42d9-94f1-61b0496ce9c5",
  description: "The level of 'concert' on the scale of how proficient the player of a piano is.  The scale is: beginner < advanced < professional < concert",
  defaultMessage: "Concert"
};

export const MSG_currentPerformanceLevelGraphicLine1Title = {
  id: "af2369b0-b54d-49c9-b62a-63b6a072d4d3",
  description: "The first line for the label of the current level of performance for a piano.  The graphic we use does not have word wrapping, so we split the translation into two strings.  This is the first line.  If you need two lines, use the second one.  These should be very short.  In English the whole string will read 'Your Piano's Condition'",
  defaultMessage: "Your Piano's"
};

export const MSG_currentPerformanceLevelGraphicLine2Title = {
  id: "4e6dcad6-4e1f-4630-adc7-d45cbf63bca6",
  description: "The second line (optional) for the label of the current level of performance for a piano.  The graphic we use does not have word wrapping, so we split the translation into two strings.  This is the second line.  If you need two lines, use the second one.  These should be very short.  In English the whole string will read 'Your Piano's Condition'",
  defaultMessage: "Condition"
};

export const MSG_potentialPerformanceLevelGraphicLine1Title = {
  id: "6ad0d0e5-f7ab-49bb-8739-9564ea0e76d7",
  description: "The first line for the label of the potential level of performance for a piano (e.g. if it were brand new).  The graphic we use does not have word wrapping, so we split the translation into two strings.  This is the first line.  If you need two lines, use the second one.  These should be very short.  In English the whole string will read 'Your Piano's Potential'",
  defaultMessage: "Your Piano's"
};

export const MSG_potentialPerformanceLevelGraphicLine2Title = {
  id: "2c14faad-9564-41f4-b1ad-67c34deec6b1",
  description: "The second line (optional) for the label of the potential level of performance for a piano (e.g. if it were brand new).  The graphic we use does not have word wrapping, so we split the translation into two strings.  This is the second line.  If you need two lines, use the second one.  These should be very short.  In English the whole string will read 'Your Piano's Potential'",
  defaultMessage: "Potential"
};

export const MSG_plusTaxLabel = {
  id: "2e98aaa6-df4f-4936-80e6-13b88675dd0e",
  description: "Text that is displayed underneath of a subtotal.",
  defaultMessage: "Plus tax: {amount}"
};

export const MSG_estimatedTotalLabel = {
  id: "347a65f5-f2bd-40cf-9e70-47104f5e447b",
  description: "the total for an estimate.  We say it is an estimated amount because the actual cost may be more or less.",
  defaultMessage: "Estimated total"
};

export const MSG_optionTotalTitle = {
  id: "92339372-4514-4d89-9dae-b426f750b4d5",
  description: "This will read like 'Option #1 Total",
  defaultMessage: "Option #{optionNumber} Total"
};

export const MSG_optionNumberTitle = {
  id: "b6b961b5-c210-4fcb-a48f-388784640b5c",
  description: "This will read like 'Option #1'",
  defaultMessage: "Option #{optionNumber}",
};

export const MSG_deleteEstimateOptionConfirmation = {
  id: "a226c51c-d391-4eab-98b4-ff5a3ececd8b",
  description: "",
  defaultMessage: "Are you sure you want to delete this option from this estimate?"
};

export const MSG_noTimeAdded = {
  id: "d9ecb3c9-7c8b-43c2-9619-8fbc01f40bf9",
  description: "This is used to indicate that no time is added to the total duration of the appointment by this item on an estimate",
  defaultMessage: "No time added for this item"
};

export const MSG_expiredDateWithColon = {
  id: "8e120be0-3889-4dd4-9add-db3abc6bb70e",
  description: "A label for when an estimate expired in the past.",
  defaultMessage: "Expired: {date}"
};

export const MSG_removeConditionReportTitle = {
  id: "c496cbb4-bf12-47c6-9e1f-c29cef3eb6a9",
  description: "This is the label for a button that lets them remove a condition report from an estimate.",
  defaultMessage: "Remove Condition Report"
};

export const MSG_setConditionReportValuesTitle = {
  id: "e23cd33d-986d-4337-8061-7f09dff2dd9f",
  description: "",
  defaultMessage: "Set Condition Report Values",
};

export const MSG_createYourFirstEstimate = {
  id: "2c6ff9b9-06ed-449f-8bf9-ab32739aa5d9",
  defaultMessage: "Create your first estimate.",
};

export const MSG_estimatePreparedInLocale = {
  id: "49e2eaa0-af95-49fe-a2df-fe87580b6f0f",
  defaultMessage: "This Estimate is prepared with the localization: {locale}"
};

export const MSG_changeEstimateLocaleWarning = {
  id: "0451db29-093c-464b-a56e-69350cfb26b4",
  defaultMessage: "Items have already been added to this Estimate in the {localeName} localization.  Changing the localization here will NOT update those items.  You may need to re-add them."
};

export const MSG_sortingInstructionsOptions = {
  id: "caa39c86-c019-46e3-a7a9-c851b1926871",
  defaultMessage: "Tap and hold to change the order of these estimate options."
};

export const MSG_removePhotoFromEstimateTitle = {
  id: "ccc97578-1b4c-45ae-9a15-35ff8f21c2ee",
  defaultMessage: "Remove Photo From Estimate"
};

export const MSG_setPhotoTitle = {
  id: "69bb5e5c-dda3-4781-a1e6-da7f998698fc",
  description: "A short button label to set a photo (on an estimate for example).",
  defaultMessage: "Set Photo"
};

export const MSG_estimateScheduleWhichOption = {
  id: "e2a29d86-6476-47fd-843e-aa5e7e7bc58e",
  defaultMessage: "Which option do you want to schedule an appointment for?"
};

export const MSG_scheduleThisEstimateTitle = {
  id: "b5974465-2b1a-4c8a-8365-a07281b7b160",
  defaultMessage: "Schedule This Estimate",
};

export const MSG_questionsNotSentToAnyone = {
  id: "932fcb26-a3b3-41e0-96e0-4f61ed5950fc",
  description: "This is a message that is show when they have not told Gazelle where customer questions should be sent to.",
  defaultMessage: "Questions will not be sent to anyone.",
};

export const MSG_defaultNotesLabel = {
  id: "4b2c287b-4f83-4c9a-8287-6b3b19e6e052",
  defaultMessage: "Default notes",
};

export const MSG_defaultNotesExplanation = {
  id: "f585e52c-58d5-4c89-83e5-44f6fd77fbb6",
  defaultMessage: "Estimate can have notes on them to provide additional information to your customers. This is the default that will show up on each new estimate. It can be customized when creating an estimate.",
};

export const MSG_createPianoForClientBeforeEstimate = {
  id: "e61de51a-56fe-45fa-a8c7-5e51d23123bc",
  defaultMessage: "This client has no pianos. You must create a piano before starting an estimate."
};

export const MSG_conditionReportTitle = {
  id: "e7520011-2427-44e9-89bb-617b6d763c6d",
  description: "A condition report is simply a report on the condition of a piano.",
  defaultMessage: "Condition Report",
};

export const MSG_estimateOptionsExplanation = {
  id: "5e8b0cab-489c-42cb-b116-3bf556e55ea1",
  defaultMessage: "Estimate options are different groups of services designed to achieve a certain performance outcome for the piano. Adding options to your estimate helps you to provide your customer a recommended, not recommended, and conditionally recommended course of action."
};

export const MSG_estimateExpirationExplanation = {
  id: "8b340838-b23f-410b-bcc8-45630acb2383",
  defaultMessage: "The estimate expiration date is the date by which you will no longer honor the price of the services recommended. The customer will receive a warning to contact you for updated pricing when they view the estimate."
};

export const MSG_nextEstimateNumberExplanation = {
  id: "22033d85-5319-44d0-8d41-aa3998dcd935",
  defaultMessage: "Easily change your estimate numbering system by starting on a different number. Note: you cannot repeat an estimate number that has already been used."
};

export const MSG_mslItemNameExplanation = {
  id: "9fe23126-5689-4dad-bdc2-7496e9bc09b7",
  defaultMessage: "Your service item names will appear on all your customer facing invoices, estimates, and self-scheduling workflow."
};

export const MSG_mslItemDescriptionExplanation = {
  id: "f5a347b9-3a69-4cf1-934e-2cbf932147b2",
  defaultMessage: 'Your service item descriptions will appear on the main customer facing page of your estimates (including printed estimates) . These should be short 10 word / one sentence descriptions that answer the question "What is this item and what problem does my customer solve by scheduling this item?"'
};

export const MSG_mslItemEducationalTextExplanation = {
  id: "aa83b1f3-cc16-4dda-8ca6-f0f14820d29b",
  defaultMessage: 'Your educational text is an optional long-form explanation displayed to your customers via a "What is this?" button on your estimate items. This text should explain the problem you are trying to solve, the reason this item is important, and the risk your customer faces if they choose to ignore your advice to schedule this work.'
};

export const MSG_permanentlyDeleteEstimateConfirmation = {
  id: "de4004f8-b678-4af9-bc70-1794fc41a2fc",
  defaultMessage: "This will permanently delete this estimate along with all of its options. If it has been emailed to your client and the client clicks to view the estimate the client will receive an error.",
};

export const MSG_sendEstimateTitle = {
  id: "7a381a2c-a10c-4cc2-8710-06504c493d9b",
  description: "A short title for the screen where the user can send an estimate to someone.",
  defaultMessage: "Send Estimate",
};

export const MSG_recommendedOptionTitle = {
  id: "385deb10-3b44-48e9-8776-debc903e05f9",
  description: "An estimate can have many options, this is a short label for an option that is recommended.",
  defaultMessage: "Recommended Option",
};

export const MSG_recommendedOptionTotalLabel = {
  id: "d56ee324-c434-4000-9853-76077cd25d6c",
  description: "An estimate can have many options, this is a short label for the total monetary amount on an option that is recommended.",
  defaultMessage: "Recommended option total",
};

export const MSG_primaryOptionTotalLabel = {
  id: "92d1e772-e81d-4f44-816f-51c19531e651",
  description: "An estimate can have many options, this is a short label for the total monetary amount on the option that is set as the estimate's primary option.",
  defaultMessage: "Primary option total",
};

export const MSG_plusNArchived = {
  id: "292d8837-4c7b-460f-9abb-b07bfa9445cb",
  description: "This is a short message that is shown at the bottom of a list of unarchived recommendations.  It gives them an indication of how many archived recommendations exist.",
  defaultMessage: "+{count} archived recommendations",
};

export const MSG_addRecommendationTitle = {
  id: "c1475c0b-161e-4cea-aad3-13b1081d4512",
  defaultMessage: "Add Recommendation",
};

export const MSG_addItemsToEstimate = {
  id: "6249017b-0365-403c-b17b-4a9c36aa6d6b",
  defaultMessage: "Add items to your estimate.",
};

export const MSG_noEstimateRecipientsSelect = {
  id: "39140cf8-daea-46f3-9d27-27c0ae873c72",
  defaultMessage: "First you must select someone to send the estimate to.",
};

export const MSG_checklistItemsHelpText = {
  id: "5bbd46e1-9d49-482c-b521-11ec4af7a85d",
  defaultMessage: "Checklist items are a quick way to add items from your Master Service List into an estimate.  They are just links directly to items on your Master Service List.\n\nTo edit the details of this item, you will need to edit the item on your Master Service List itself."
};

export const MSG_estimateExpiresOnRequired = {
  id: "c389932c-4136-4424-b2ae-82535679ca88",
  defaultMessage: "An estimate must have an expiration date.  You must enter a date before continuing.",
};

export const MSG_thisGroupHasNoItems = {
  id: "91f23ca7-7d0a-497e-9254-ce4678ed587d",
  defaultMessage: "This group has no items."
};

export const MSG_removeGroupQuestion = {
  id: "9fe64b55-287c-458f-80e5-b39f3e0022ab",
  description: "",
  defaultMessage: "Are you sure you want to remove this group?",
};

export const MSG_removeOptionQuestion = {
  id: "56acb6e0-1883-4b74-bf73-fe9379f8a0e0",
  defaultMessage: "Are you sure you want to remove this option, its groups and all its items?"
};


export const MSG_deleteRecommendationErrorMessage = {
  id: "9c2246e9-796c-4fb1-b1d2-debcd180ad3e",
  description: "An error message that is displayed if something went wrong while trying to delete a recommendation.",
  defaultMessage: "The recommendation could not be deleted",
};

export const MSG_confirmRecommendationDelete = {
  id: "ddb934ff-1f4c-4b32-8149-0b7c1c326903",
  defaultMessage: "{estimateTierCount, plural, =1 {There is 1 estimate option using this recommendation.  Deleting it will remove this recommendation from this 1 estimate option.  Consider archiving it instead of deleting.} other {There are # estimate options using this recommendation.  Deleting it will remove this recommendation from these # estimate options.  Consider archiving it instead of deleting.}}"
};

export const MSG_permanentlyDeleteRecommendation = {
  id: "c3799d60-7576-4e67-a876-929cb258dcf6",
  defaultMessage: "Permanently delete the recommendation?",
};

export const MSG_noRecommendationSelectedLabel = {
  id: "42735ded-8e35-4afd-93b7-e3645afc993c",
  description: "When setting up an estimate, they can choose from one of their pre-defined recommendations.  This text indicates that they have not yet chosen a recommendation for this estimate.",
  defaultMessage: "No recommendation has been selected."
};

export const MSG_addConditionReportTitle = {
  id: "1deb5443-66fb-4a0b-83a7-b7cee23320c7",
  description: "This is the button that lets them add a chart that shows a report on the condition of the piano.",
  defaultMessage: "Add Condition Report"
};

export const MSG_addConditionReportLabel = {
  id: "a0f889c5-b3fa-418f-b846-4f37b2ced337",
  description: "This is a label for a list item that lets them add a chart that shows a report on the condition of the piano.",
  defaultMessage: "Add condition report"
};

export const MSG_conditionReportExplanation = {
  id: "6b3e0b90-67a2-4d88-88a3-884f180b7bb7",
  defaultMessage: "A condition report provides a visual aid to educate customers about the current condition and full potential of their piano including options for repair."
};

export const MSG_conditionReportEditorExplanation = {
  id: "0b81f473-df4b-4be8-8835-efbc918d2a05",
  defaultMessage: "The condition report editor allows you to easily display to your customer a piano’s full potential, current condition, and options for repair by sliding the markers into position. "
};

export const MSG_recommendationExplanation = {
  id: "a652d05c-5148-4e11-93cc-02925f707373",
  defaultMessage: "Recommendations help guide your customer to the best option to select from their estimate based on their piano and their musical needs."
};

export const MSG_noEstimatesFound = {
  id: "ee9d2015-d187-4923-83cf-9942cff91c8c",
  defaultMessage: "No Estimates Found",
};

export const MSG_recommendationsTitle = {
  id: "c33e0017-49f4-4d2e-ba42-eea6bdb65fb6",
  defaultMessage: "Recommendations",
};

export const MSG_archivedRecommendationsTitle = {
  id: "6e787b5a-191a-4f34-96ba-cda20cb12cd5",
  defaultMessage: "Archived Recommendations",
};

export const MSG_noRecommendationsFoundLabel = {
  id: "9c463814-0d0c-4691-80fb-5327b488c96b",
  description: "Recommendations are used when creating an estimate and are basically 'Yes', 'No', 'Maybe'.  Users can pre-configure a set of recommendations and then choose from that list when creating an estimate.  If no recommendations have been created yet, this message is shown.",
  defaultMessage: "No recommendations found",
};

export const MSG_newRecommendationTitle = {
  id: "4d01291a-91a4-4e3e-963a-c5f3f9f0b65c",
  defaultMessage: "New Recommendation",
};

export const MSG_editRecommendationTitle = {
  id: "768ef786-12d8-44e3-bc25-282db8a64a12",
  defaultMessage: "Edit Recommendation",
};

export const MSG_getStartedChecklistMessage = {
  id: "f2a063c2-edb7-4f41-aefa-5ec54453bb0f",
  description: "",
  defaultMessage: "Get started by adding items to this checklist from your Master Service List.",
};

export const MSG_thisChecklistIsEmpty = {
  id: "ffe3ce0e-ac8c-41cc-99eb-d0986285049f",
  defaultMessage: "This checklist is empty.",
};

export const MSG_noChecklistsFound = {
  id: "48f72cac-31e6-4f51-a146-8901dfb82227",
  description: "This message is shown when no estimate checklists have been created yet.",
  defaultMessage: "No checklists have been created yet.  Checklists are a way to group your services together for different scenarios and make it easier to quickly create estimates.",
};

export const MSG_noChecklistsFoundShorterLabel = {
  id: "bf2f9e02-18da-432c-9516-cc10fc8f792d",
  defaultMessage: "No checklists have been created yet."
};

export const MSG_checklistExplanation = {
  id: "e30f23c7-c72a-4dc4-ac78-79a125ece0c8",
  defaultMessage: "Checklists help you quickly create estimates by organizing the services you provide. For example, you can group services into a \"Grand Piano Checklist\", \"Upright Piano Checklist\", and \"Institutional Services Checklist\"."
};

export const MSG_checklistWhereToEdit = {
  id: "8bf562e9-858b-4720-9c76-30d2b934d6f3",
  defaultMessage: "Checklists can be created and updated under the Estimates section in Settings."
};

export const MSG_checklistGroupCount = {
  id: "ee9776f6-507d-467f-bf95-f89bbe8e463c",
  description: "This is the number of checklist groups that will be deleted or updated.",
  defaultMessage: "{groupCount, plural, =1 {1 group} other {# groups}}"
};

export const MSG_checklistItemCount = {
  id: "2487de3d-e58b-4e25-a964-223ea5a90b42",
  description: "This is the number of checklist items that will be deleted or updated.",
  defaultMessage: "{itemCount, plural, =1 {1 item} other {# items}}"
};

export const MSG_checklistDeleteConfirmationGroupsAndItems = {
  id: "3e1eaf6a-2a98-4591-8ed3-c46705c19f61",
  defaultMessage: "Are you sure you want to permanently delete this checklist along with its {numGroupsLabel} and {numItemsLabel}?  Items will be removed from this checklist only, but will remain in your Master Service List.  Any estimates that have been created from this checklist will be unaffected.",
};

export const MSG_DeletingEntireEstimate = {
  id: "f2e4b0a5-d1b9-4a5e-bb73-15d3a5d1f673",
  defaultMessage: "Are you sure you want to delete this entire estimate?",
};

export const MSG_checklistDeleteConfirmationGroups = {
  id: "b8124ece-9128-44e8-9459-4eda1c6d9465",
  defaultMessage: "Are you sure you want to permanently delete this checklist along with it's {numGroupsLabel}?  Items will be removed from this checklist only, but will remain in your Master Service List.  Any estimates that have been created from this checklist will be unaffected.",
};

export const MSG_checklistDeleteConfirmationItems = {
  id: "dd0738f4-887a-4749-96b0-3511873e66a4",
  defaultMessage: "Are you sure you want to permanently delete this checklist along with it's {numItemsLabel}?  Items will be removed from this checklist only, but will remain in your Master Service List.  Any estimates that have been created from this checklist will be unaffected.",
};

export const MSG_checklistDeleteConfirmation = {
  id: "6646ce59-398f-41a7-8bc5-ec1231bfbfa4",
  defaultMessage: "Are you sure you want to permanently delete this checklist?  Items will be removed from this checklist only, but will remain in your Master Service List.  Any estimates that have been created from this checklist will be unaffected.",
};

export const MSG_addItemsFromChecklistTitle = {
  id: "f6b0d6a3-247c-4bde-ac7f-246b388c1c37",
  defaultMessage: "Add Items From a Checklist",
};

export const MSG_selectChecklistToGetStarted = {
  id: "b3413057-db46-43b9-b217-cfbe5c661383",
  defaultMessage: "Select a checklist to get started.",
};

export const MSG_estimateQuestionsHeading = {
  id: "6f0ddb49-8d86-49eb-9959-158e83a634b2",
  defaultMessage: "When a client submits a question about an estimate, send the questions to:"
};

export const MSG_allActiveAdminsLabel = {
  id: "a38ca09d-312f-4a57-bd20-c305a51b697f",
  description: "This will be displayed when the user has chosen to send a question about an estimate to all the active user accounts with admin privileges.",
  defaultMessage: "All active admin users",
};

export const MSG_estimateCreatorLabel = {
  id: "80f04c20-de21-4a57-b420-08607e5c07e4",
  description: "This will be displayed when the user has chosen to send a question about an estimate to the person who created the estimate.",
  defaultMessage: "The person who created the estimate"
};

export const MSG_nextEstimateNumberLabel = {
  id: "8b033bda-8827-4c85-a8d3-51a324ab016c",
  description: "Label for an input field where they change whta the next estimate number should be.",
  defaultMessage: "Next estimate number",
};

export const MSG_defaultMonthsNewEstimatesExpireOnLabel = {
  id: "b87f9b46-f5e6-4880-9630-d0ee6c677c97",
  defaultMessage: "Default expiration",
};

export const MSG_sendQuestionsToActiveAdminsLabel = {
  id: "9edd0770-8dee-4a25-817a-aeda24106d26",
  defaultMessage: "Send questions to all active admin users",
};

export const MSG_sendQuestionsToEstimateCreatorLabel = {
  id: "93222b6e-32be-461f-ac20-73b8d9d5b93c",
  defaultMessage: "Send questions to the person that created the estimate",
};

export const MSG_sendQuestionsToLabel = {
  id: "be27e500-a3cf-4a8c-9678-403fd9a74de3",
  description: "This is the heading on top of a list of places that customers questions about estimates will be sent to.",
  defaultMessage: "Send client questions to",
};

export const MSG_sendQuestionsToExplanation = {
  id: "f02474d1-9da3-438f-93ed-bb4e123ed21a",
  defaultMessage: 'When customers click the "Ask a Question" button while viewing your estimate, their message(s) will be sent to this list of users and outside email addresses.',
};


export const MSG_editChecklistTitle = {
  id: "09d39670-c10c-4dc8-bfe2-f36e7ee47a67",
  defaultMessage: "Edit Checklist",
};

export const MSG_newChecklistTitle = {
  id: "97061efb-b73e-41fe-82fe-44de80cce5fc",
  defaultMessage: "New Checklist",
};

export const MSG_checklistNamePlaceholderLabel = {
  id: "e68b4f3e-7169-41c3-8474-7c3f1de2c116",
  description: "This is placeholder text for the checklist name field.  It should hold a very short example name.",
  defaultMessage: "e.g. Grand Piano Checklist",
};

export const MSG_checklistItemNameLabel = {
  id: "542f2c9c-2907-46d9-8bab-d139e1fd81fa",
  defaultMessage: "Checklist item name",
};

export const MSG_checklistGroupName = {
  id: "f7877a46-97cc-4380-93be-e76db58d5060",
  defaultMessage: "Checklist Group name",
};

export const MSG_checklistTitle = {
  id: "dedcf862-c54d-49ef-9f60-aa5ee95b3a8c",
  description: "This is a short title for an Estimate Checklist, which is a list of items that can be added in bulk to an estimate.",
  defaultMessage: "Checklist",
};

export const MSG_deleteChecklistTitle = {
  id: "a84fe3a8-7920-44b8-b13b-d37ca98711ed",
  description: "This is a short title for an action to delete an Estimate Checklist.",
  defaultMessage: "Delete Checklist",
};

export const MSG_checklistsTitle = {
  id: "6f90b6b2-c25b-4a1f-a03a-e4897edaee07",
  description: "This is a short title for Estimate Checklists, which are a list of items that can be added in bulk to an estimate.",
  defaultMessage: "Checklists",
};

export const MSG_defaultChecklistLabel = {
  id: "c395ea67-6399-4ff5-80d1-e203278db242",
  defaultMessage: "Select this checklist by default."
};

export const MSG_expiresOnLabel = {
  id: "9d906a30-5a4a-4d8a-9da2-52574882c1eb",
  description: "A label for the date an estimate expires.",
  defaultMessage: "Expires on",
};

export const MSG_estimateNotesLabel = {
  id: "c2f81cd4-6ddd-4b8b-9fe9-30143508e93b",
  description: "The label for the form field where they can add notes about an estimate.",
  defaultMessage: "Estimate notes",
};

export const MSG_selectARecommendationTitle = {
  id: "882daee3-1037-41e2-ab04-9eabbebf87e3",
  defaultMessage: "Select a Recommendation",
};

export const MSG_deleteEstimateTitle = {
  id: "d0054155-abaa-4d0e-859b-34ac7cdb3b1c",
  defaultMessage: "Delete Estimate",
};

export const MSG_archiveEstimateTitle = {
  id: "731250f9-32df-452c-be90-f79e95c3111b",
  defaultMessage: "Archive Estimate",
};

export const MSG_unarchiveEstimateTitle = {
  id: "207a7501-8107-4cb5-b52a-abe42594acdf",
  defaultMessage: "Unarchive Estimate",
};

export const MSG_permanentlyDeleteEstimate = {
  id: "bc37c0c0-5a04-4b52-9ef3-b7a8a1b79319",
  description: "Asks a question to the customer to see if they want to permanently delete an estimate",
  defaultMessage: "Are you sure you want to permanently delete this estimate along with all of its options? If it has been emailed to your client and the client clicks to view the estimate the client will receive an error.",
};

export const MSG_deleteEstimateErrorMessage = {
  id: "d280be78-4201-4292-bd3d-4760a19ab0d9",
  description: "An error message that is displayed if something went wrong while trying to delete an estimate.",
  defaultMessage: "The estimate could not be deleted",
};

export const MSG_permanentlyDeleteEstimateMobile = {
  id: "d3e9f8b1-3b2f-4f2b-8b9a-26e4a5b8c7d2",
  description: "Asks a question to the customer to see if they want to permanently delete an estimate",
  defaultMessage: "Are you sure you want to delete this entire estimate?",
};

export const MSG_estimateOptionOfNumberLabel = {
  id: "b60ad1df-cb1f-4602-911d-ef440626edae",
  description: "The label for each of the estimate options.  In English this would read like 'Repair Option #1', or 'Repair Option #2', etc.",
  defaultMessage: "Estimate option {optionNumber} of {numOptions}"
};

export const MSG_estimateNumOptionsLabel = {
  id: "3cf41355-43ed-472f-a6c8-0af13bb7c985",
  description: "A label that shows the number of tiers (options) in an estimate.",
  defaultMessage: "{numOptions} options"
};

export const MSG_newOptionTitle = {
  id: "6c81be1d-acbc-4f37-855b-ad6938b181d9",
  description: "The label on the button that lets them create a new option for an estimate.",
  defaultMessage: "New Option",
};

export const MSG_newEstimateTitle = {
  id: "38496338-9fcf-4f2c-be64-83e57361fbfb",
  description: "The title when creating a new estimate.",
  defaultMessage: "New Estimate"
};

export const MSG_estimateAbbreviatedTitle = {
  id: "1ad4c768-2553-4eeb-89b5-ddcdfb343832",
  description: "This should be a non plural word for a feature we call Estimates.  The Estimates feature lets you create estimates of work that is needed on an instrument.",
  defaultMessage: "Estimate"
};

export const MSG_currentPerformanceLevelShortTitle = {
  id: "4e3a1b4a-aa2d-4e45-b495-d65ec88b8d1c",
  description: "This is a shortened version of the 'Current Performance Level' label",
  defaultMessage: "Current"
};

export const MSG_potentialPerformanceLevelShortTitle = {
  id: "ce8cb434-f5c2-40f9-a575-d4a54daa46fc",
  description: "This is a shortened version of the 'Potential Performance Level' label",
  defaultMessage: "Potential"
};

export const MSG_recommendationTitle = {
  id: "a17b581c-8cfc-4e66-b843-7328af4a9e8f",
  description: "The label for the section where they can choose whether they do or do not recommend that a service be performed on a piano.",
  defaultMessage: "Recommendation"
};

export const MSG_recommendationTypeLabel = {
  id: "1a48f7a1-3797-4b0e-a802-b92361358f2a",
  description: "The label above where they select what type of recommendation this is.",
  defaultMessage: "Recommendation type"
};


export const MSG_showOptionFirstLabel = {
  id: "f64bd0d7-2c17-465d-808c-b9d1e94fca60",
  defaultMessage: "Show this option to your client first?"
};

export const MSG_showOptionFirstExplanation = {
  id: "b912fc1c-da5d-41d6-b45e-847005d4479d",
  defaultMessage: "For multi-level estimates this will create a default option to show to your customer."
};

export const MSG_notApplicableLabel = {
  id: "28fcc110-b3a6-4005-863e-dd2fa08a1809",
  description: "A very short notation or abbreviation to mean 'not applicable'.  This is used when an option does not apply to a certain situation.",
  defaultMessage: "N/A"
};

export const MSG_noNotesHaveBeenAddedLabel = {
  id: "e7d299d6-096f-4633-b930-5a661773725f",
  description: "A label that is displayed when something (such as an estimate) does not yet have any notes.",
  defaultMessage: "No notes have been added.",
};

export const MSG_noItemsHaveBeenAddedLabel = {
  id: "0aecd1ca-95b1-4eb5-9a59-1f32e7d72397",
  description: "This is a label that is shown when something (such as an estimate) does not have any items in it yet",
  defaultMessage: "No items have been added",
};

export const MSG_noItemsHaveBeenAddedToGroupLabel = {
  id: "668ffecb-3796-45f5-8bc3-5933c7f8eaac",
  defaultMessage: "No items have been added to this group"
};

export const MSG_addItemForThisGroupTitle = {
  id: "62a2b8a7-6902-4fad-a35b-0a611ba3dffc",
  description: "A label for a menu item that will let you add something to a group of items on an estimate.",
  defaultMessage: "Add Item For This Group",
};

export const MSG_educationDescriptionLabel = {
  id: "b81052a8-92f4-4148-923f-7d15a7870a31",
  description: "The label for a field on an estimate that is used to provide educational text about a particular estimate item to a client.",
  defaultMessage: "Educational text",
};

export const MSG_externalUrlLabel = {
  id: "bd1828cc-3208-4879-867d-d207e3e12bd3",
  description: "A URL to a resource that explains more about this estimate item.",
  defaultMessage: "External URL",
};

export const MSG_miscellaneousItemsTitle = {
  id: "c9f720a2-1e7a-4856-a8a2-936a7832565b",
  description: "A label for items on an estimate that are not grouped into a category.",
  defaultMessage: "Miscellaneous Items",
};

export const MSG_editOptionsTitle = {
  id: "756af170-076a-4a72-bfc9-e7afb1bcf695",
  description: "A button label that lets the user edit the options on an estimate.",
  defaultMessage: "Edit Options",
};

export const MSG_addOptionNumberTitle = {
  id: "c2e0f8f2-898b-4f2a-868b-b8c658845abe",
  description: "This will read like 'Add Option #1'",
  defaultMessage: "Add Option #{optionNumber}"
};

export const MSG_cloneOptionNumberTitle = {
  id: "b5d6933d-0406-4fb4-a458-7045fc8b39a3",
  description: "Will read like: 'Clone Option #1'",
  defaultMessage: "Clone Option #{optionNumber}",
};

export const MSG_createBlankOptionTitle = {
  id: "0203214a-6274-4735-b522-8e94a9867ed9",
  description: "This is a label for an action to add a new, empty option to an estimate.",
  defaultMessage: "Create Blank Option",
};

export const MSG_removeThisOptionTitle = {
  id: "14e50dfd-835c-4dad-b526-2d5dcad98a3c",
  defaultMessage: "Remove This Option",
};

export const MSG_seeOptionNumberTitle = {
  id: "c57c0b6e-fe40-4ccd-8eb5-6daa94a5ff4e",
  defaultMessage: "See Option {num}",
};

export const MSG_preparedForLabel = {
  id: "f750033f-eb2e-431c-b169-ca83096f0434",
  description: "A label above the client's name which is an indication of who this estimate was prepared for.",
  defaultMessage: "Prepared for",
};

export const MSG_pianoInformationTitle = {
  id: "998709ff-9e71-49b1-9a1b-7e955280cd06",
  defaultMessage: "Piano Information",
};

export const MSG_pianoInformationLabel = {
  id: "979c032b-929f-4e1c-b486-6fe50457c40a",
  defaultMessage: "Piano information",
};

export const MSG_repairOptionsLabel = {
  id: "aa1075c6-390f-40eb-8f29-e410321b0735",
  description: "A header above the different options a customer can choose from on an estimate.",
  defaultMessage: "Repair options",
};

export const MSG_fromAChecklistTitle = {
  id: "6daaf721-7386-4187-a023-fa3f6e0723bb",
  defaultMessage: "From a Checklist",
};

export const MSG_estimatesTitle = {
  id: "81360bbf-f924-4718-850f-a132aff89d84",
  description: "The label for the list of estimates.",
  defaultMessage: "Estimates"
};

export const MSG_estimateSettingsTitle = {
  id: "b02dcb10-a80e-492c-a95c-4b6cd2fda131",
  defaultMessage: "Estimate Settings"
};

export const MSG_estimateNumberHeadingTitle = {
  id: "18e0181b-fe9f-45cd-b4d2-b050bf1d974d",
  description: "The title for the list of estimate numbers.",
  defaultMessage: "Estimate Number"
};

export const MSG_estimateNumberHeadingLabel = {
  id: "0d66eaa4-9b62-4497-a78b-2d8bf4c725f7",
  description: "The label for the list of estimate numbers.",
  defaultMessage: "Estimate number"
};

export const MSG_estimatedDateTitle = {
  id: "791c72ad-60c5-4f1c-bf0c-8e72ccdfa602",
  description: "The label for the date an estimate was created",
  defaultMessage: "Estimated"
};

export const MSG_expiresDateTitle = {
  id: "ebdbe71b-1a99-4a5f-85d0-9463ec1c3af1",
  description: "The label for the date an item expires, and is no longer valid.",
  defaultMessage: "Expires"
};

export const MSG_expiredOnDateTitle = {
  id: "34308442-abce-4332-8bfb-3c4297ea27de",
  description: "When an estimate expired in the past",
  defaultMessage: "Expired: {date}"
};

export const MSG_expiresOnDateTitle = {
  id: "9e84aa4e-d4c9-4e23-99e0-17e3228d5b54",
  description: "When an estimate expires in the future",
  defaultMessage: "Expires: {date}"
};

export const MSG_estimateNumberAndExpirationTitle = {
  id: "613063be-acc9-406e-a06e-4bc9c24ab713",
  description: "The header on top of the list of service items.  This is when the list of services comes from an estimate that they are trying to schedule.",
  defaultMessage: "Estimate #{estimateNumber} (Expires {date})",
};

export const MSG_expiredOnDateExplanation = {
  id: "65f1c2be-000b-41d9-8f7e-e22586c33f0c",
  description: "",
  defaultMessage: "This estimate expired on {date}.  Please contact us at {phone} if you would like an updated estimate.",
};

export const MSG_pleaseCallForEstimateError = {
  id: "d41ad6fc-b374-45ed-bce5-fc95cd1e047b",
  defaultMessage: "Please call us at {phone} to get a copy of your estimate.  We apologize for the inconvenience.",
};

export const MSG_howShouldWeContactYouQuestion = {
  id: "76cfa8f7-7a35-4996-93c5-030ff21310f0",
  description: "This is the question we ask where they can choose which email address or phone number we should use when responding to a question they submit.",
  defaultMessage: "How should we contact you?",
};

export const MSG_howCanWeHelpYouQuestion = {
  id: "85eab619-464c-4ec9-901d-a6703bb0dcf8",
  description: "This is the label over the text field where they can ask their question to us.",
  defaultMessage: "How can we help you?",
};

export const MSG_contactMethodOther = {
  id: "2cba304f-4e5a-497f-92d3-d0850a52f039",
  description: "The last option they can choose for how we should contact them.  In this case, they will tell us how to contact them in the message they write, rather than specify one in this selection list.",
  defaultMessage: "Other - Please specify in your message"
};

export const MSG_requestToScheduleServicesForTier = {
  id: "2ac13f07-3fb9-4f28-b1b8-608b10a93757",
  description: "",
  defaultMessage: "I would like to schedule the services for option #{optionNumber}."
};

export const MSG_messageMissingError = {
  id: "9862bd08-4e31-4875-814b-52c215a81c0b",
  defaultMessage: "You must first enter a message before sending.",
};

export const MSG_callUsDirectly = {
  id: "c14231cc-cce4-4ba4-bb9b-f4c885271b73",
  description: "",
  defaultMessage: "You can also call us directly at {phoneNumber}.",
};

export const MSG_messageSentThanks = {
  id: "599e8e46-b8c3-4623-aa7a-78cad704221c",
  defaultMessage: "Your message has been sent. Thanks for contacting us!",
};

export const MSG_questionsFromClientsTitle = {
  id: "b466ce0d-ae3d-47c3-8357-74dd55fbf394",
  defaultMessage: "Questions From Clients",
};

export const MSG_questionsFromClientsLabel = {
  id: "a7ae7745-a873-438f-af5e-5474b7312e29",
  defaultMessage: "Also send questions to these email addresses:",
};

export const MSG_otherEmailAddressesTitle = {
  id: "9bf104ed-f1c1-4177-9162-19890766f782",
  defaultMessage: "Other Email Addresses",
};

export const MSG_noAdditionalEmailAddressesHaveBeenAdded = {
  id: "d9ad12eb-091a-4877-bcff-3fa6f57f45ad",
  defaultMessage: "No additional email addresses have been added.",
};

export const MSG_questionsFromClientsSentDirectly = {
  id: "0ab41a93-8e48-4345-bc18-e1549e1ff4bc",
  defaultMessage: "Questions from clients will also be sent directly to the email addresses in this list.",
};

export const MSG_sortingGroupsTitle = {
  id: "5aa7dfce-ab92-413d-aa1e-f1ef7cff9a14",
  defaultMessage: "Sorting Groups",
};

export const MSG_editEstimateNumberTitle = {
  id: "69f19178-5b0c-4124-8e96-7978b9fedbbe",
  description: "This is a short title for editing an estimate.  In English we are using the # symbol to mean number.  It would look like 'Edit Estimate #123'.",
  defaultMessage: "Edit Estimate #{estimateNumber}",
};

export const MSG_problemSavingEstimateLabel = {
  id: "9d9132f2-c149-4115-afcc-7ce08a3bb8a8",
  defaultMessage: "There was a problem saving this estimate:",
};

export const MSG_followingAdditionalOptionsHaveErrorsLabel = {
  id: "f151be71-9357-46bd-9e9d-9b72b664aa9d",
  defaultMessage: "The following additional options have errors: {options}",
};

export const MSG_optionNotesLabel = {
  id: "3fc87de1-b665-4ce5-b494-3850d48c26b6",
  description: "This is a label for a field containing notes about one of the options on an estimate.",
  defaultMessage: "Option notes",
};

export const MSG_nameCannotBeBlank = {
  id: "24caf191-ae40-44cb-91c2-04061013e54d",
  description: "",
  defaultMessage: "The name cannot be blank.",
};

export const MSG_estimateSearchPlaceholder = {
  id: "fb4966d7-90f3-4020-bfbb-b2a0c81f2c8e",
  description: "",
  defaultMessage: "Search for name, estimate number, piano, etc."
};

export const MSG_forEstimateNumber = {
  id: "afd7f1c2-6ed4-42ad-b5c4-83206f3868c3",
  description: "A label to say what estimate this item is related to.  Reads as: 'For estimate #123'",
  defaultMessage: "For estimate #{number}"
};

export const MSG_expiredTitle = {
  id: "a05b4b36-41db-46ad-9eeb-4935ddd729c8",
  description: "The title of a button for when an estimate is expired.  This is in title case.",
  defaultMessage: "Expired"
};

export const MSG_expirationDateTitle = {
  id: "464553a8-db23-48a0-9d21-9b9f7fe151cd",
  description: "The date that something (such as an estimate) expires.  This is in title case.",
  defaultMessage: "Expiration Date"
};

export const MSG_expirationStatusTitle = {
  id: "ae89c096-8f89-4035-841b-f473711931a8",
  description: "The status of the expiration on an estimate (either expired or not expired).  This is in title case.",
  defaultMessage: "Expiration Status"
};

export const MSG_recommendedOptionTotalTitle = {
  id: "dd9d2b7e-2639-44f2-8547-158720249243",
  description: "The total currency amount of the recommended option on an estimate.  This is in title case.",
  defaultMessage: "Recommended Option Total"
};

export const MSG_anyOptionTotalTitle = {
  id: "643085e9-8fc0-4c1e-ad43-1d860e342985",
  description: "The currency amount any option on an estimate.  This is in title case.",
  defaultMessage: "Any Option Total"
};

export const MSG_anyTotalTitle = {
  id: "e9af9b82-2d66-4bb7-9c9e-c24c4dc84300",
  description: "The currency amount any option on an estimate.  This is in title case.",
  defaultMessage: "Any Total"
};

export const MSG_recommendedTotalTitle = {
  id: "61972a1f-8187-44ca-84dd-6949d28a54cb",
  description: "The total currency amount of the recommendation on an estimate.  This is in title case.",
  defaultMessage: "Recommended Total"
};

export const MSG_addATagToEstimateLabel = {
  id: "4cc2ea38-f713-4e15-b40b-a526eeef424b",
  description: "A button that allows the user to add a tag to an estimate.",
  defaultMessage: "Enter a tag for this estimate"
};
