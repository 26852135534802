import { Piano } from "../models";
import { PIANO_TYPE } from "../enums";
import { PianoTypeInfo } from "./piano_type_info";
import { formatMessage } from "./intl";
import {
  MSG_pianoNameMake,
  MSG_pianoNameMakeModel,
  MSG_pianoNameMakeModelType,
  MSG_pianoNameMakeType,
  MSG_pianoNameWithLocation,
  MSG_pianoNameWithSerialNumber,
  MSG_pianoNameWithSerialNumberAndLocation,
  MSG_pianoNameYearMake,
  MSG_pianoNameYearMakeModel,
  MSG_pianoNameYearMakeModelType,
  MSG_pianoNameYearMakeType,
  MSG_unknownPianoTitle,
  MSG_unknownPianoType
} from "../strings";

function getPianoDisplayName(piano: Piano, locale?: string): string {
  const variables = {
    make: piano.make?.trim(),
    model: piano.model?.trim(),
    year: piano.year,
    type: PianoTypeInfo[piano.type].getLabel(locale)
  };

  let name = null;

  // A special case where the make is "Unknown" or null, and we know nothing else about
  // this piano.  Make the name be "Unknown Piano" in this case.  It makes it more
  // readable.  When we import clients, we often set the make to 'Unknown'.  Yes this
  // only works in English, but so far we've only done this for English customers when
  // importing them.  Eventually it might be nice to make this multi-language.
  if (piano.make === 'Unknown' && !piano.model && !piano.year && piano.type === PIANO_TYPE.UNKNOWN) {
    return formatMessage(MSG_unknownPianoTitle, variables, locale);
  }

  if (piano.type === PIANO_TYPE.UNKNOWN) {
    if (piano.make && !piano.model && !piano.year) {
      name = formatMessage(MSG_pianoNameMake, variables, locale);
    } else if (piano.make && piano.model && !piano.year) {
      name = formatMessage(MSG_pianoNameMakeModel, variables, locale);
    } else if (piano.make && !piano.model && piano.year) {
      name = formatMessage(MSG_pianoNameYearMake, variables, locale);
    } else if (piano.make && piano.model && piano.year) {
      name = formatMessage(MSG_pianoNameYearMakeModel, variables, locale);
    }
  } else {
    if (piano.make && !piano.model && !piano.year) {
      name = formatMessage(MSG_pianoNameMakeType, variables, locale);
    } else if (piano.make && piano.model && !piano.year) {
      name = formatMessage(MSG_pianoNameMakeModelType, variables, locale);
    } else if (piano.make && !piano.model && piano.year) {
      name = formatMessage(MSG_pianoNameYearMakeType, variables, locale);
    } else if (piano.make && piano.model && piano.year) {
      name = formatMessage(MSG_pianoNameYearMakeModelType, variables, locale);
    } else {
      name = formatMessage(MSG_unknownPianoType, variables, locale);
    }
  }
  if (!name) {
    name = formatMessage(MSG_unknownPianoTitle, variables, locale);
  }

  return name;
}

function getPianoDisplayNameWithLocation(piano: Piano, locale?: string): string {
  if (piano.location) {
    return formatMessage(MSG_pianoNameWithLocation, {
      pianoName: getPianoDisplayName(piano, locale),
      location: piano.location.trim()
    }, locale);
  } else {
    return getPianoDisplayName(piano, locale);
  }
}

function getPianoDisplayNameWithSerialNumber(piano: Piano, locale?: string): string {
  if (piano.serialNumber) {
    return formatMessage(MSG_pianoNameWithSerialNumber, {
      pianoName: getPianoDisplayName(piano, locale),
      serialNumber: piano.serialNumber.trim()
    }, locale);
  } else {
    return getPianoDisplayName(piano, locale);
  }
}

function getPianoDisplayNameWithEverything(piano: Piano, locale?: string): string {
  if (piano.location && piano.serialNumber) {
    return formatMessage(MSG_pianoNameWithSerialNumberAndLocation, {
      pianoName: getPianoDisplayName(piano, locale),
      location: piano.location.trim(),
      serialNumber: piano.serialNumber.trim()
    }, locale);
  } else if (piano.location) {
    return getPianoDisplayNameWithLocation(piano, locale);
  } else if (piano.serialNumber) {
    return getPianoDisplayNameWithSerialNumber(piano, locale);
  } else {
    return getPianoDisplayName(piano, locale);
  }
}

function getPianoDisplaySerialNumberAndLocation(piano: Piano): string {
  let parts = [];
  if (piano.serialNumber) parts.push(piano.serialNumber.trim());
  if (piano.location) parts.push(piano.location.trim());
  return parts.join(', ');
}

export {
  getPianoDisplayName,
  getPianoDisplayNameWithLocation,
  getPianoDisplayNameWithSerialNumber,
  getPianoDisplayNameWithEverything,
  getPianoDisplaySerialNumberAndLocation,
};
