import { Localization, SupportedLocaleInfo } from "../models";
import { createIntl, IntlShape, MessageDescriptor } from 'react-intl';
import { CURRENCY_FORMAT, NUMBER_FORMAT, WEEKDAYS } from "../enums";
import { values } from "lodash";
import { toMomentLocale } from "../utils";
import { makeObservable, observable } from "mobx";

const moment = require('moment-timezone');

type ITranslatedMessages = {
  [locale: string]: { [key: string]: string }
};

// The localizations normally are pushed from the server.  But just in case,
// this one is hard coded as a default to use initially.  It should get overwritten
// by the ones from the server after the script is initialized.
const DEFAULT_SUPPORTED_LOCALE_INFO: SupportedLocaleInfo = {
  locale: 'en_US',
  label: 'English - United States',
  isLocaleDefault: true,
  defaultNumberFormat: NUMBER_FORMAT.COMMA_DECIMAL_THREE,
  defaultCurrencyFormat: CURRENCY_FORMAT.BEFORE,
  defaultFirstDayOfWeek: WEEKDAYS.SUN,
  monthYearFormat: {ruby: '%b %Y', moment: 'MMM YYYY'},
  dateFormat: {ruby: '%-d %b %Y', moment: 'D MMM YYYY'},
  weekdayDateFormat: {ruby: '%a, %-d %b %Y', moment: 'ddd, D MMM YYYY'},
  timeFormat: {ruby: '%-I:%M %p', moment: 'h:mm A'},
  timezoneTimeFormat: {ruby: '%-I:%M %p %Z', moment: 'h:mm A zz'},
  dateTimeSeparator: ' '
};

/**
 * This store is used to track the currently selected localization and all the settings
 * that go along with it.  It has convenience methods for accessing and manipulating
 * the localizations.  A global singleton for this is kept by registering an instance
 * with registerLocaleStoreInstance().
 */
class CurrentLocalizationStore {
  localization: Localization = null;
  intl: IntlShape = null;
  private textComponent: any;
  private blockComponent: any;
  private changeCallbacks: ((localization: Localization) => any)[];
  private messages: ITranslatedMessages;
  private supportedLocaleInfoList: SupportedLocaleInfo[];
  private supportedLocaleInfoLookup: {[key: string]: SupportedLocaleInfo};
  private combinedLocalizationList: Localization[];
  private combinedLocalizationLookup: {[key: string]: Localization};
  public clientDefaultLocale: string;
  public userDefaultLocale: string;

  constructor(options: {messages?: ITranslatedMessages, textComponent?: any, blockComponent?: any} = {}) {
    this.messages = {};
    if (options.messages) this.registerMessages(options.messages);
    if (options.textComponent) this.registerTextComponent(options.textComponent);
    if (options.blockComponent) this.registerBlockComponent(options.blockComponent);
    this.changeCallbacks = [];
    this.registerLocalizations([DEFAULT_SUPPORTED_LOCALE_INFO]);
    this.setLocale('en_US');

    makeObservable(this, {
      localization: observable,
      intl: observable
    });
  }

  /**
   * After the data is bootstrapped from the server, this method should be called to
   * populate the list of locales.
   *
   * @param supportedLocaleInfoList - The list of all default supported locales pulled
   *            from the server.
   * @param localizationList - A list of Localization models that override certain
   *            locales and are company-wide settings.
   */
  registerLocalizations(supportedLocaleInfoList: SupportedLocaleInfo[], localizationList: Localization[] = []) {
    this.supportedLocaleInfoLookup = {};
    this.supportedLocaleInfoList = [];
    supportedLocaleInfoList.forEach((loc) => {
      this.supportedLocaleInfoList.push(loc);
      this.supportedLocaleInfoLookup[loc.locale] = loc;
    });

    this.combinedLocalizationLookup = {};
    localizationList.forEach(loc => {
      this.combinedLocalizationLookup[loc.locale] = loc;
      if (loc.isClientDefault) this.clientDefaultLocale = loc.locale;
      if (loc.isUserDefault) this.userDefaultLocale = loc.locale;
      loc.isLocaleDefault = true;
    });

    this.supportedLocaleInfoList.forEach(loc => {
      if (!this.combinedLocalizationLookup[loc.locale]) {
        let newLoc = new Localization({
          locale: loc.locale,
          isLocaleDefault: loc.isLocaleDefault,
          dateFormatLocale: loc.locale,
          timeFormatLocale: loc.locale,
          numberFormat: loc.defaultNumberFormat,
          currencyFormat: loc.defaultCurrencyFormat,
          firstDayOfWeek: loc.defaultFirstDayOfWeek,
          dateFormat: {
            date: loc.dateFormat,
            weekdayDate: loc.weekdayDateFormat,
            monthYear: loc.monthYearFormat,
          },
          timeFormat: {
            time: loc.timeFormat,
            timezoneTime: loc.timezoneTimeFormat,
          },
          dateTimeSeparator: loc.dateTimeSeparator,
        });
        this.combinedLocalizationLookup[newLoc.locale] = newLoc;
      }
    });

    this.combinedLocalizationList = values(this.combinedLocalizationLookup)
      .sort((a: Localization, b: Localization) => {
        let aLabel = this.getLabelFor(a.locale);
        let bLabel = this.getLabelFor(b.locale);
        if (aLabel < bLabel) return -1;
        if (aLabel > bLabel) return 1;
        return 0;
      });
  }

  /**
   * Set the JS object that contains all the translated messages for all locales.
   * This should be set after initializing the store.
   *
   * @param messages - a JS object indexed by locale, that contains all of
   *            the translation strings for all locales.
   */
  registerMessages(messages: ITranslatedMessages) {
    this.messages = messages;
  }

  /**
   * This is useful for mobile to say that <Text> should be used instead of
   * <span>.  This is really just a convenience method so we have a place to store
   * this outside of the shared repo.  It is used in the date/time formatters.
   * If nothing is set then <span> will be assumed.
   *
   * @param textComponent
   */
  registerTextComponent(textComponent: any) {
    this.textComponent = textComponent;
  }

  /**
   * This is useful for mobile to say that <View> should be used instead of
   * <div>.  This is really just a convenience method so we have a place to store
   * this outside of the shared repo.  It is used in the duration formatters.
   * If nothing is set then <div> will be assumed.
   *
   * @param blockComponent
   */
  registerBlockComponent(blockComponent: any) {
    this.blockComponent = blockComponent;
  }

  /**
   * You can register callback functions that are executed any time the locale
   * is changed.  This is useful if you need to re-render some stuff.
   *
   * @param fn
   */
  registerChangeCallback(fn: (localization: Localization) => any) {
    this.changeCallbacks.push(fn);
  }

  /**
   * Change the current locale.  This should be one of the known locales.  We will
   * attempt to fall back to a known default if possible.  For example, if we don't
   * have a locale for "en_XY", then we'll search for a default "en" locale which
   * happens to be "en_US" and use that.  If all else fails, "en_US" will be used.
   *
   * @param locale
   */
  setLocale(locale: string) {
    let newLocalization = this.localization;

    if (this.combinedLocalizationLookup[locale]) {
      newLocalization = this.combinedLocalizationLookup[locale];
    } else {
      let isSet = false;
      this.combinedLocalizationList.forEach(loc => {
        if (isSet) return;
        if (!loc.isLocaleDefault) return;
        if (loc.locale && loc.locale.substring && locale && locale.substring && loc.locale.substring(0, 2) === locale.substring(0, 2)) {
          newLocalization = loc;
          isSet = true;
        }
      });
    }
    if (!newLocalization) {
      newLocalization = this.combinedLocalizationLookup['en_US'];
    }

    this.intl = createIntl({
      locale: 'en', // we are using our own locale formatting, so just set this to 'en'
      messages: this.messages[newLocalization.locale]
    });

    moment.locale(toMomentLocale(newLocalization.locale));

    this.localization = newLocalization;
    this.changeCallbacks.forEach(fn => fn(this.localization));
  }

  /**
   * Returns the Localization object for the currently-set locale.
   */
  getLocalization() {
    return this.localization;
  }

  /**
   * Returns the Localization for a specific locale.
   */
  getLocalizationFor(locale: string) {
    return this.combinedLocalizationLookup[locale] || this.getLocalization();
  }

  /**
   * Returns the locale string for the currently-set locale.
   */
  getLocale() {
    return this.localization.locale;
  }

  /**
   * Returns all the translated messages for the currently-set locale.
   */
  getMessages() {
    return this.messages[this.localization.locale];
  }

  /**
   * Returns all the translated messages for a specific locale.
   */
  getMessagesFor(locale: string) {
    return this.messages[locale];
  }

  /**
   * Returns the current text component to be used.  If none is set, assume <span>.
   * This is really only used in the date/time formatters in shared.
   */
  getTextComponent() {
    return this.textComponent;
  }

  /**
   * Human-readable labels for locales are not stored on the Localization object.
   * Instead, you'll need to call this to get it.
   *
   * @param locale
   */
  getLabelFor(locale: string) {
    return this.supportedLocaleInfoLookup[locale]?.label || 'Unknown';
  }

  /**
   * This returns a list of all supported Localization objects.  It starts with the
   * default list of SupportedLocalInfo objects (converted to Localization objects),
   * and then adds any Localizations that the company has set.  These override the
   * standard defaults based on the locale string.  What you're left with is a
   * combined list of all the supported Localization objects.
   */
  getCombinedLocalizations() {
    return this.combinedLocalizationList;
  }

  /**
   * Simply returns the original, unmodified set of supported locales that we got from
   * the server initially.
   */
  getSupportedLocaleInfoList() {
    return this.supportedLocaleInfoList;
  }

  /**
   * Returns the original supported locales that we got from the server initially, but
   * returns them in indexed form for easier lookup.
   */
  getSupportedLocaleInfoLookup() {
    return this.supportedLocaleInfoLookup;
  }

  /**
   * This is a helper method to run a string of text through our translation system.
   * When using this method, it will already know the current locale, so you don't
   * need to deail with it.  An even more convenient formatMessage() method is exported
   * from intl.js, which isn't even attached to this store.  That is the one you should
   * use instead of this one.
   *
   * @param messageDescriptor
   * @param variables
   */
  formatMessage(messageDescriptor: MessageDescriptor, variables?: {[key: string]: any}): string {
    return this.intl.formatMessage(messageDescriptor, variables);
  }

  formatMessageInLocale(locale: string, messageDescriptor: MessageDescriptor, variables?: {[key: string]: any}): string {
    const intl = createIntl({
      locale: 'en', // we are using our own locale formatting, so just set this to 'en'
      messages: this.messages[locale]
    });

    return intl.formatMessage(messageDescriptor, variables);
  }
}

export { CurrentLocalizationStore };
