import * as moment from 'moment';
import { RRule, RRuleSet, rrulestr } from "rrule";
import { SchedulerV2Availability } from "../models";

// Modifies the rrule to exclude a date (YYYY-MM-DD string or Moment).
function excludeDateInRRule(rrule: string, date: string | moment.Moment) {
  const m = typeof date === 'string' ? moment(date) : date;
  const rruleObj = RRule.fromString(rrule);
  const rruleSet = new RRuleSet();
  rruleSet.rrule(rruleObj);
  rruleSet.exdate(m.toDate());
  return rruleSet.toString();
}

// Modifies the rrule to include a date (YYYY-MM-DD string or Moment).
function includeDateInRRule(rrule: string, date: string | moment.Moment) {
  const m = typeof date === 'string' ? moment(date) : date;
  const rruleObj = RRule.fromString(rrule);
  const rruleSet = new RRuleSet();
  rruleSet.rrule(rruleObj);
  rruleSet.rdate(m.toDate());
  return rruleSet.toString();
}

// The rrule library perceives all time as UTC and is irresponsive to distinctions between time zones.
// To work with it correctly, we need to represent local time in terms of UTC. For instance, if it's 10:00 AM
// in the local time zone, we must portray it as 10:00 AM UTC when passing it to the library. Returned
// occurrences will also be designated as UTC, but should be interpreted as if they were in the local time zone.
// Also, we need to add the DTSTART parameter to the recurrence rule to the start of day, otherwise the library
// will assume the start time is the current time.  UNTIL doesn't matter because we are only checking if it
// is valid for the single day.
function buildAvailabilityRRule(a: SchedulerV2Availability) {
  // NOTE: We need to add WKST because the JS rrule library makes assumptions about when
  // the week starts, and this causes some issues (especially with interval=2).  By setting
  // it explicitly here, it sets it in sync with what the Ruby IceCube library does.  It
  // doesn't really matter what we set it to, as long as it's consistent.  Thus, both here
  // and in the Ruby code, we set it WKST=SU.
  return rrulestr(a.recurrenceRule + ";WKST=SU"
    + ";DTSTART=" + moment.tz(a.startDate, 'UTC').startOf('day').format("YYYYMMDDTHHmmss") + "Z"
    + (a.endDate ? ";UNTIL=" + moment.tz(a.endDate, 'UTC').endOf('day').format("YYYYMMDDTHHmmss") + "Z" : ""));
}

export { includeDateInRRule, excludeDateInRRule, buildAvailabilityRRule };
