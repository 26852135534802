import { formatMessage } from "../utils/intl";
import { PREFERRED_SLOT_POLICIES } from "../enums";
import {
  MSG_combinedExplanation,
  MSG_combinedLabel,
  MSG_onlyOpenDayExplanation,
  MSG_onlyOpenDaysLabel,
  MSG_onlyPreferredSlotsExplanation,
  MSG_onlyPreferredSlotsLabel
} from "../strings";

const SortedPreferredSlotPolicies = [
  PREFERRED_SLOT_POLICIES.ONLY_OPEN_DAY,
  PREFERRED_SLOT_POLICIES.ONLY_PREFERRED_SLOTS,
  PREFERRED_SLOT_POLICIES.COMBINED,
];

const PreferredSlotPolicyInfo: {[key: string]: {
  type: PREFERRED_SLOT_POLICIES,
  getLabel: () => string,
  getExplanation: () => string,
}} = {
  [PREFERRED_SLOT_POLICIES.ONLY_OPEN_DAY]: {
    type: PREFERRED_SLOT_POLICIES.ONLY_OPEN_DAY,
    getLabel: () => formatMessage(MSG_onlyOpenDaysLabel),
    getExplanation: () => formatMessage(MSG_onlyOpenDayExplanation),
  },
  [PREFERRED_SLOT_POLICIES.ONLY_PREFERRED_SLOTS]: {
    type: PREFERRED_SLOT_POLICIES.ONLY_PREFERRED_SLOTS,
    getLabel: () => formatMessage(MSG_onlyPreferredSlotsLabel),
    getExplanation: () => formatMessage(MSG_onlyPreferredSlotsExplanation),
  },
  [PREFERRED_SLOT_POLICIES.COMBINED]: {
    type: PREFERRED_SLOT_POLICIES.COMBINED,
    getLabel: () => formatMessage(MSG_combinedLabel),
    getExplanation: () => formatMessage(MSG_combinedExplanation),
  },
};


export { SortedPreferredSlotPolicies, PreferredSlotPolicyInfo };
