import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/pro-solid-svg-icons";

/**
 * Spinner
 *
 * Used to display a "working" indicator.
 */
const Spinner = function () {
  return (
    <span className="spinner">
      <FontAwesomeIcon icon={faCog} spin />
    </span>
  );
};

export { Spinner };
