import * as React from "react";
import { IMappingInteractiveMapProps } from "../interactive_map";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faMapMarkerSlash,
} from "@fortawesome/pro-solid-svg-icons";
import { MAPPING_LOCATION_TYPE } from "@gazelle/shared/enums";
import {
  MSG_approximateLocationLabel,
  MSG_exactLocationNotFoundShortError,
  MSG_approximateLocationDescription,
} from "@gazelle/shared/strings";
import { formatMessage } from "@gazelle/shared/utils";
import { GoogleMap, Marker } from "@react-google-maps/api";

function getGoogleInteractiveMapContainer() {
  interface IGoogleMapProps {
    center: { lat: number; lng: number };
    isMarkerShown: boolean;
    loadingElement: React.ReactElement;
    containerElement: React.ReactElement;
    mapElement: React.ReactElement;
  }

  const GoogleMapContainerInternal = (props: IGoogleMapProps) => {
    return (
      <GoogleMap
        mapContainerStyle={{ width: "100%", height: "100%" }}
        zoom={10}
        heading={2}
        options={{
          disableDefaultUI: true,
          zoomControl: true,
        }}
        center={props.center}
      >
        {props.isMarkerShown && <Marker position={props.center} />}
      </GoogleMap>
    );
  };

  return (props: IMappingInteractiveMapProps) => {
    if (!props.lat || !props.lng) {
      return (
        <div
          className="interactive-maps-container d-flex flex-column align-items-center justify-content-center"
          style={{
            height: props.height,
            width: props.width,
            backgroundColor: "#f4f4f4",
          }}
        >
          <h2>
            <FontAwesomeIcon icon={faMapMarkerSlash} />
          </h2>
          <div className="text-small text-muted">
            {formatMessage(MSG_exactLocationNotFoundShortError)}
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <div
            className="d-flex"
            style={{
              height: props.height,
              width: props.width,
              backgroundColor: "#f4f4f4",
            }}
          >
            <GoogleMapContainerInternal
              center={{ lat: props.lat, lng: props.lng }}
              isMarkerShown={true}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={<div style={{ height: `100%` }} />}
              mapElement={<div style={{ height: `100%` }} />}
            />
          </div>
          {props.locationType &&
            props.locationType !== MAPPING_LOCATION_TYPE.ROOFTOP && (
              <div className="pt-2 text-color-alert-red text-left">
                <div className="d-flex flex-row align-items-center">
                  <FontAwesomeIcon icon={faExclamationTriangle} />
                  <div style={{ marginLeft: "5px" }}>
                    <strong>
                      {formatMessage(MSG_approximateLocationLabel)}
                    </strong>
                  </div>
                </div>
                <p style={{ fontWeight: "normal", margin: 0 }}>
                  {formatMessage(MSG_approximateLocationDescription)}
                </p>
              </div>
            )}
        </div>
      );
    }
  };
}

export { getGoogleInteractiveMapContainer };
