import * as React from "react";
import { observer } from 'mobx-react';

import { Step1Address } from '../../step1_address';
import { Step2Pianos } from '../../step2_pianos';
import { Step3Prefs } from '../../step3_prefs';
import { Step4Search } from '../../step4_search';
import { Step5Contact } from '../../step5_contact';
import { Step6Finished } from '../../step6_finished';
import { rootStoreInstance } from "@client_scheduler/modules/root";

const StepSelector = observer(class StepSelector extends React.Component {
  render() {
    switch (rootStoreInstance.currentStep) {
      case 1: return <Step1Address />;
      case 2: return <Step2Pianos />;
      case 3: return <Step3Prefs />;
      case 4: return <Step4Search />;
      case 5: return <Step5Contact />;
      case 6: return <Step6Finished />;
    }
  }
});

export { StepSelector };
