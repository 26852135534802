import { formatMessage } from "./intl";
import { USER_ACCESS_LEVEL } from "../enums";
import { MSG_userTypeAdmin, MSG_userTypeAssistant, MSG_userTypeTechnician } from "../strings";

const SortedAccessLevels = [
  USER_ACCESS_LEVEL.ADMIN,
  USER_ACCESS_LEVEL.TECHNICIAN,
  USER_ACCESS_LEVEL.ASSISTANT,
];

const AccessLevelInfo: {[key: string]: {type: USER_ACCESS_LEVEL, getLabel: () => string}} = {
  [USER_ACCESS_LEVEL.ADMIN]: {type: USER_ACCESS_LEVEL.ADMIN, getLabel: () => formatMessage(MSG_userTypeAdmin)},
  [USER_ACCESS_LEVEL.TECHNICIAN]: {type: USER_ACCESS_LEVEL.TECHNICIAN, getLabel: () => formatMessage(MSG_userTypeTechnician)},
  [USER_ACCESS_LEVEL.ASSISTANT]: {type: USER_ACCESS_LEVEL.ASSISTANT, getLabel: () => formatMessage(MSG_userTypeAssistant)},
};

export { AccessLevelInfo, SortedAccessLevels };
