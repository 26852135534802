const jsSHA = require('jssha');

function googleUrlEncode(str: string) {
  if (!str) return str;
  return encodeURIComponent(str);
  //
  // return str.replace(/ /g, '%20')
  //   .replace(/"/g, '%22')
  //   .replace(/</g, '%3C')
  //   .replace(/>/g, '%3E')
  //   .replace(/#/g, '%23')
  //   .replace(/%/g, '%25')
  //   .replace(/\|/g, '%7C');
}


function googleSignUrl(url: string, key: string) {
  const shaObj = new jsSHA('SHA-1', 'TEXT');
  shaObj.setHMACKey(key, 'B64');
  shaObj.update(url);
  return shaObj.getHMAC('B64').replace(/[+/]/g, function (c: any) {
    let map: any = {'+': '-', '/': '_'};
    return map[c];
  });
}


export { googleUrlEncode, googleSignUrl };
