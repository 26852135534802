export const MSG_completionTitle = {
  id: "5b26a8f3-8a26-4413-a82c-1fef66799591",
  defaultMessage: "Complete Appointment",
};

export const MSG_completionWithClientTitle = {
  id: "8f35b26a-8a26-4413-a82c-67995911fef6",
  description: "The title to display when marking an appointment with a client as completed.",
  defaultMessage: "Complete Appointment For {name}",
};

export const MSG_addMessageTitle = {
  id: "036602fd-ebb2-4215-8ef1-eff1aacb37f0",
  description: "The button to add a message to the list of messages that will be sent.",
  defaultMessage: "Add Message"
};

export const MSG_noMessagesWillBeScheduledOrSent = {
  id: "8c138298-e212-448d-87f5-157c35cd3963",
  defaultMessage: "No messages will be scheduled or sent."
};

export const MSG_noInvoiceWillBeCreated = {
  id: "c28add63-7b7c-46c1-9a52-f5848fd5651e",
  defaultMessage: "No invoice will be created."
};

export const MSG_noEmailWillBeSent = {
  id: "baf9946f-e80b-41df-a0a8-48e93b128790",
  defaultMessage: "No email will be sent.",
};

export const MSG_tapToSendInvoiceEmail = {
  id: "be672340-2027-4895-b2d8-838b5bfb55e8",
  defaultMessage: "Tap to send an invoice email."
};

export const MSG_clickToSendInvoiceEmail = {
  id: "2322ce55-5c42-4a95-a4be-e5f88c49c924",
  defaultMessage: "Click to send an invoice email."
};

export const MSG_noPaymentWillBeMade = {
  id: "46fbaf99-e80b-41df-a0a8-1248e93b8790",
  defaultMessage: "No payment will be made.",
};

export const MSG_tapToAddPayment = {
  id: "de7bfd95-c1bd-4c5c-b285-9088b73863bb",
  defaultMessage: "Tap to add a payment."
};

export const MSG_clickToAddPayment = {
  id: "b9c6d15b-221e-4528-844c-a8505ea148d4",
  defaultMessage: "Click to add a payment."
};

export const MSG_noServiceHistoryNotesAdded = {
  id: "18afc691-8ffe-45b5-80fa-5e7dc7bb6aad",
  defaultMessage: "No service history notes added."
};

export const MSG_tapToAddServiceHistoryNotes = {
  id: "dcf315e3-0dca-4317-aa51-568e62db4d24",
  defaultMessage: "Tap to add service history notes."
};

export const MSG_clickToAddServiceHistoryNotes = {
  id: "81abf212-9dd4-49c2-aaa7-91aafdbd640e",
  defaultMessage: "Click to add service history notes."
};

export const MSG_noMeasurementsWillBeAdded = {
  id: "f82fc15a-ffab-4b8c-b494-71c94782d3a3",
  defaultMessage: "No measurements will be added."
};

export const MSG_noTempHumidityMeasurementsHaveBeenAdded = {
  id: "5e203602-441d-489c-8f56-3af1015ea44c",
  defaultMessage: "No temperature or humidity measurements have been added."
};

export const MSG_tapToAddMeasurements = {
  id: "1532ad37-337a-4e2c-923c-7e6b4068b3af",
  defaultMessage: "Tap to add measurements."
};

export const MSG_clickToAddMeasurements = {
  id: "450f9cdb-7c8f-4053-adad-ccc9d90925d1",
  description: "",
  defaultMessage: "Click to add measurements."
};

export const MSG_uncompletedAppointmentsLabel = {
  id: "7a462071-ace9-4452-98bf-6b292cd90acb",
  description: "This is the label for a button that takes them to the uncompleted appointment list.",
  defaultMessage: "Uncompleted appointments"
};

export const MSG_noUncompletedAppointmentsLabel = {
  id: "4604c77c-099f-416b-885a-e6968485d0a6",
  description: "This is a label for the button that takes them to the uncompleted appointment list.  But this label also tells them there are no uncompleted appointments in that list.",
  defaultMessage: "No uncompleted appointments"
};

export const MSG_completionNoShow = {
  id: "4af0f1f8-7993-4446-9e8d-4144ff9b8b03",
  description: "A no-show appointment is one where the client does not show up.",
  defaultMessage: "This appointment has been marked as a no-show.",
};

export const MSG_completionCanceled = {
  id: "ef951278-6365-438a-9346-5be350230c58",
  defaultMessage: "This appointment has been marked as canceled.",
};

export const MSG_completionComplete = {
  id: "02407e0e-050c-4162-a297-19cc1a9bf3ea",
  defaultMessage: "This appointment has been marked as complete.",
};

export const MSG_resultTitle = {
  id: "2c2b10e6-e35b-452f-8d87-3b6cef6aeac5",
  description: "The label for where they can select what happened at an appointment like saying they completed it successfully, the client didn't show up, etc.",
  defaultMessage: "Result"
};

export const MSG_uncompletedTitle = {
  id: "96f12ea4-64f9-4c5e-adfa-0732652e4f9d",
  description: "When an event has NOT been marked as completed (e.g. it is uncomplete, or not finished)",
  defaultMessage: "Uncompleted"
};
export const MSG_completedTitle = {
  id: "b6141b8e-27e4-441a-b4fa-a694fd976d8a",
  description: "This is one of the options for how an appointment was completed.  This is the option for the appointment being completed successfully (i.e. all the services were performed as expected)",
  defaultMessage: "Completed"
};
export const MSG_completedExplanation = {
  id: "6c796977-bf50-4986-886e-6fc43af4e1c5",
  description: "This is the explanation of what 'Appointment Completed' appointment completion type means",
  defaultMessage: "The appointment was completed successfully."
};
export const MSG_rescheduledTitle = {
  id: "184f887f-534e-4f77-8202-14d3d9b4ab0c",
  description: "This is one of the options for how an appointment was completed.  This is the option for when they scheduled the appointment.",
  defaultMessage: "Rescheduled"
};
export const MSG_rescheduledExplanation = {
  id: "925c0d19-b052-4d3d-9bfb-7d7350a467f0",
  description: "This is the explanation of what 'Appointment Rescheduled' appointment completion type means",
  defaultMessage: "No work was performed for this appointment, but the appointment was rescheduled."
};
export const MSG_noShowTitle = {
  id: "039418a0-1d5a-4913-b9d5-6cf95ba11d7a",
  description: "This is one of the options for how an appointment was completed.  This is the option for when the client didn't show up for the appointment.",
  defaultMessage: "Client No-show"
};
export const MSG_noShowExplanation = {
  id: "4c1df77b-3f13-4763-84ed-f5aa840e5042",
  description: "This is the explanation of what 'Client No-show' appointment completion type means",
  defaultMessage: "The client did not show up.  No work was performed."
};
export const MSG_canceledTitle = {
  id: "af70961c-0516-47f6-a0d3-13db3e9de829",
  description: "This is one of the options for how an appointment was completed.  This is the option for when the appointment was canceled.",
  defaultMessage: "Canceled"
};
export const MSG_canceledExplanation = {
  id: "d2a33a82-3af0-4654-9c54-3f1bd8055d42",
  description: "This is the explanation of what 'Appointment Canceled' appointment completion type means.",
  defaultMessage: "The appointment was canceled for any reason.  No work was performed."
};
export const MSG_otherResultTitle = {
  id: "133f9f5c-5f79-440f-bd82-e2ba84ec3dfa",
  description: "This is one of the options for how an appointment was completed.  This is the option for any other completion option that is not covered by our pre-defined options.  It means that the appointment was completed, but it should not reset the tuning reminders for the piano.",
  defaultMessage: "Completed, But Not Tuned"
};
export const MSG_otherResultExplanation = {
  id: "5e17a244-ffaf-472e-a403-6cf7d28453a3",
  description: "This is the explanation of what 'Other' appointment completion type means.",
  defaultMessage: "The appointment was completed, but do not mark any of the pianos as having been tuned."
};

export const MSG_scheduleTitle = {
  id: "a17ae8aa-20dc-45e8-b15f-5f6e39228861",
  description: "A short action word for scheduling the next appointment.",
  defaultMessage: "Schedule",
};

export const MSG_scheduleNextTitle = {
  id: "e12925ac-ca82-4a44-a216-f83389e6e1d8",
  description: "As in 'Schedule Next Appointment'.  This should be a short phrase.",
  defaultMessage: "Schedule Next",
};

export const MSG_wouldYouLikeToScheduleNextAppt = {
  id: "95b488a2-38e0-4e74-86ac-e90f4d693ab2",
  defaultMessage: "Would you like to schedule the next appointment?",
};

export const MSG_addNotesTitle = {
  id: "ced3c975-bf3d-467c-ae53-647002a92862",
  description: "A button that lets them add notes about the client to the client record.",
  defaultMessage: "Add Notes",
};

export const MSG_whichTypeOfClientNotes = {
  id: "d69ec78c-4e90-4640-90de-30c130df8f23",
  description: "The title for the popup window that lets them select a type of note to add to the client record.",
  defaultMessage: "Which type of client note?",
};

export const MSG_whichTypeOfPianoNotes = {
  id: "a392306d-2d9f-4ae1-b47e-176135422512",
  description: "The title for the popup window that lets them select a type of note to add to a piano record.",
  defaultMessage: "Which type of piano note?",
};

export const MSG_addServiceHistoryNoteTitle = {
  id: "6a8c0772-a4c4-40f7-a2d1-d30fb5cc3933",
  description: "The label for the button that lets them add a comment to the client's timeline.",
  defaultMessage: "Add a Service History Note"
};

export const MSG_timelineNoteTitle = {
  id: "e65d09f1-872e-49c2-9356-37d7caee4103",
  description: "A short label for a button to add a comment to the client's timeline.",
  defaultMessage: "Timeline Comment"
};

export const MSG_addTimelineNoteTitle = {
  id: "11710ab1-989b-4f4d-8cb3-4f0437b8615b",
  description: "The label for the button that lets them add a comment to the client's timeline.",
  defaultMessage: "Add a Timeline Comment"
};

export const MSG_timelineNotesTitle = {
  id: "3681fc0f-d212-4d6c-bb5e-52a00e49be73",
  description: "The label for the button that lets them add a comment to the client's timeline.",
  defaultMessage: "Timeline Comment"
};

export const MSG_timelineNotesLabel = {
  id: "ab2528da-8998-415f-984b-6af047969af9",
  description: "The label for the button that lets them add a comment to the client's timeline.",
  defaultMessage: "Timeline Comment"
};

export const MSG_timelineNotesForClientTitle = {
  id: "17110ab1-989b-4f4d-8cb3-4f0437b8615b",
  description: "The label for the button that lets them add a comment to the client's timeline.",
  defaultMessage: "Timeline Comment For {name}"
};

export const MSG_personalNotesForClientTitle = {
  id: "088bc3f1-3246-46e3-a75e-15150f3fc384",
  description: "Personal notes about the client.  For example, 'The dog bites', 'daughter named Jane', etc.",
  defaultMessage: "Personal Notes For {name}",
};

export const MSG_preferenceNotesForClientTitle = {
  id: "7c6338d6-b44e-4d3b-8682-33f8c2b635b6",
  description: "Notes about a client's preferences.  For example, 'Use the back door', etc.",
  defaultMessage: "Preference Notes For {name}",
};

export const MSG_addPersonalNoteTitle = {
  id: "38a84ff8-d995-482f-9366-c4c828fe3f37",
  description: "The label for the button that lets them edit personal notes for the client.  Personal notes are notes they add about the client that are for them to remind them about personal things about the client (like their dog's name, kids names, etc).",
  defaultMessage: "Add Personal Notes"
};

export const MSG_addPreferenceNoteTitle = {
  id: "16be67d4-0092-409c-b5be-1778079ecfa1",
  description: "The label for the button that lets them edit preference notes for the client.  This helps them remember preferences that the client has about how their piano should be turned.",
  defaultMessage: "Add Preference Notes"
};

export const MSG_addGeneralNoteTitle = {
  id: "57051784-7347-4eaf-84cc-baa12f7c6141",
  defaultMessage: "Add General Notes"
};

export const MSG_serviceHistoryNoteExplanation = {
  id: "012961b3-51c7-4d33-b7e1-c409e5bf1bf2",
  description: "An explanation of what a service history comment is used for.",
  defaultMessage: "A service history note is added to the piano timeline.  It is a good way to make notes about services you performed that you want to remember as you look back over the history of this piano."
};

export const MSG_timelineNoteExplanation = {
  id: "a08e4e0f-c662-4108-848b-9c1d09ecbf46",
  description: "An explanation of what a timeline comment is used for.",
  defaultMessage: "A timeline comment gets added to the client's timeline with today's date.  It is a good way to make notes about today's service call that you may want to remember with the history of this client."
};

export const MSG_personalNoteExplanation = {
  id: "d22d487d-6102-4dc6-b423-95e11541be69",
  description: "An explanation of what the personal notes are used for.",
  defaultMessage: "Personal notes are used to help you make a personal connection with the client.  Use it to jog your memory about their children's names, pet names, etc."
};

export const MSG_preferenceNoteExplanation = {
  id: "5dbe9b82-a1a5-41fb-994b-e0e66e0a6dea",
  description: "An explanation of what the preference notes are used for.",
  defaultMessage: "Preference notes help you remember preferences the client may have about how their piano is tuned."
};

export const MSG_generalNoteExplanation = {
  id: "e11d3a16-1fc5-4222-9397-7d4f49c0640b",
  description: "An explanation of what the general notes are used for.",
  defaultMessage: "General notes help you remember information about this piano.  These do not appear on the timeline, but show up whenever you view a piano record."
};

export const MSG_invoiceEmailsToBeSentLabel = {
  id: "74c68384-5f9e-4fe9-a4f9-c6612069dabf",
  defaultMessage: "{number, plural, =0 {No invoice emails will be sent} =1 {1 invoice email will be sent} other {# invoice emails will be sent}}",
};

export const MSG_timelineNotesRemoveTitle = {
  id: "22fa8f58-123a-424c-90de-3f0658260683",
  description: "The label for the button that lets them remove a comment to the client's timeline.",
  defaultMessage: "Remove Timeline Comment",
};

export const MSG_personalNotesRemoveTitle = {
  id: "40015a2c-bde2-4a0a-99f8-5ef942375e19",
  description: "The label for the button that lets them remove any changes they made to the personal notes for the client.  Personal notes are notes they add about the client that are for them to remind them about personal things about the client (like their dog's name, kids names, etc).",
  defaultMessage: "Remove Changes to Personal Notes",
};

export const MSG_preferenceNotesRemoveTitle = {
  id: "7089397d-a826-467d-b77c-01f00a0e2718",
  description: "The label for the button that lets them remove any changes they made to the preference notes for the client.  This helps them remember preferences that the client has about how their piano should be turned.",
  defaultMessage: "Remove Changes to Preference Notes",
};

export const MSG_problemCompletingEventLabel = {
  id: "f3ccec79-d720-4628-bb42-1202b6d656d2",
  defaultMessage: "There was a problem completing this event.",
};

export const MSG_chooseAResultTitle = {
  id: "99478ecf-ca25-4303-ad42-9cd1ec78693a",
  description: "The title for the popup window that lets them select a result for the appointment completion",
  defaultMessage: "Choose a Result",
};

export const MSG_noUncompletedApointmentsFoundLabel = {
  id: "8c015e82-0edf-42f2-b643-4e7a2d12450e",
  description: "When no uncompleted appointments exist",
  defaultMessage: "No uncompleted appointments found.",
};

export const MSG_completionCancellationConfirmationExplanation = {
  id: "755f9fec-1f53-45a8-9fbb-b534ac54ace9",
  description: "The question in the confirmation popup that asks them if they are sure they want to cancel the completion of the appointment.",
  defaultMessage: "Are you sure you want to cancel?  Nothing you have entered here will be saved including invoices, messages, notes, measurements, etc.",
};
