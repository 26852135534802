import * as React from 'react';

interface ICompanyNameHeaderProps {
  layout: string;
  appName: string;
  companyName: string;
  logoExists: boolean;
  logoUrl: string;
}
class CompanyNameHeader extends React.Component<ICompanyNameHeaderProps> {
  render() {
    const { layout, appName, companyName, logoExists, logoUrl } = this.props;

    if (layout === 'FULL_LOGO_WITHOUT_NAME' && logoExists) {
      return (
        <>
          <div className="logo-container">
            <img src={logoUrl}/>
          </div>
          {/* keeping this here for padding & spacing */}
          <div className="name-container"/>
        </>
      );
    } else {
      return (
        <>
          {logoExists &&
            <div className="logo-container">
              <img src={logoUrl}/>
            </div>}

          <div className="name-container">
            <h1>{companyName}</h1>
            <p>{appName}</p>
          </div>
        </>
      );
    }
  }
}

export { CompanyNameHeader };
