import * as React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";

interface IErrorContainerProps {
  title?: any,
  message: any
}
class ErrorContainer extends React.Component<IErrorContainerProps> {
  render() {
    return (
      <div className="error-container container">
        <div className="error-icon">
          <FontAwesomeIcon icon={faExclamationTriangle} />
        </div>

        <h1>{this.props.title || 'Error'}</h1>
        <p>{this.props.message}</p>
      </div>
    );
  }
}

export { ErrorContainer };
