import { datetime } from 'rrule';
import {
  ACTIVE_OR_HISTORICAL,
  ADDRESS_TYPE,
  BIG_BOY_PANTS_ROLES,
  BULK_ACTION_STATUS,
  BULK_ACTION_TYPE,
  CALENDAR_FONT_SIZE,
  CALENDAR_INTEGRATION_TYPE,
  CALENDAR_TITLE_MODE,
  CALENDAR_VIEW_TYPE,
  CLIENT_LOG_STATUS,
  CLIENT_LOG_TYPE,
  CLIENT_STATUS_TYPE,
  COMPANY_HEADER_LAYOUT_TYPE,
  COMPANY_PARKED_REASON,
  CURRENCY_FORMAT,
  DEFAULT_USER_PAYMENT_OPTION,
  DRIVE_TIME_ERROR_TYPE,
  EMAIL_STATUS,
  EMAIL_SUBSCRIPTION_TYPE,
  END_OF_DAY_TYPE,
  ERROR_LOG_TYPE,
  EVENT_CANCEL_NOTICE_STATUS,
  EVENT_CONFIRMATION_WARNING,
  EVENT_RECURRENCE_CHANGE_TYPE,
  EVENT_RECURRENCE_ENDING_TYPE,
  EVENT_RECURRENCE_MONTHLY_TYPE,
  EVENT_RECURRENCE_TYPE,
  EVENT_RESERVATION_STATUS,
  EVENT_STATUS,
  EVENT_TYPE,
  GAZELLE_REFERRAL_STATUS,
  INVOICE_ITEM_TYPE,
  INVOICE_PAYMENT_SOURCE,
  INVOICE_PAYMENT_STATUS,
  INVOICE_PAYMENT_TYPE,
  INVOICE_STATUS,
  ITINERARY_ITEM_TYPE,
  JAKKLOPS_FEATURE,
  LEGAL_CONTRACT_TYPE,
  LIFECYCLE_STATE,
  LIFECYCLE_TYPE,
  LOCATION_TYPE,
  MAPPING_LOCATION_TYPE,
  MAPPING_PROVIDER_TYPE,
  MASTER_SERVICE_ITEM_TYPE,
  NUMBER_FORMAT,
  PHONE_CLASS,
  PHONE_TYPE,
  PIANO_STATUS,
  PIANO_TYPE,
  PREFERRED_SLOT_POLICIES,
  PRICING_INTERVAL,
  PRICING_MODEL,
  QUICKBOOKS_ACCOUNT_TYPE,
  QUICKBOOKS_BATCH_SYNC_STATUS,
  QUICKBOOKS_SYNC_ERROR_TYPE,
  QUICKBOOKS_SYNC_NOTICE_TYPE,
  QUICKBOOKS_SYNC_STATUS,
  RECOMMENDATION_TYPE,
  REMINDER_TYPE,
  REMOTE_ACCOUNTING_TAX_MAPPING_TYPE,
  RESERVATION_COMPLETE_BEHAVIOR,
  SCHEDULED_MESSAGE_TYPE,
  SCHEDULER_DISTANCE_UNIT,
  SCHEDULER_LOCATION_TYPE,
  SCHEDULER_ROUTING_PREFERENCE,
  SCHEDULER_SHAPE_INCLUSION_METHOD,
  SCHEDULER_SHAPE_TYPE,
  SCHEDULER_SHORT_TERM_LIMIT_HOURS_TYPE,
  SCHEDULER_SLOT_FILTER_REASON_TYPE,
  SCHEDULER_SLOT_FLAG_TYPE,
  SCHEDULER_TRAVEL_MODE,
  SERVICE_AREA_ALGORITHM,
  SLOT_WARNING,
  SMS_STATUS,
  START_OF_DAY_TYPE,
  STRIPE_PAYMENT_METHODS,
  SUBSCRIPTION_STATUS,
  SYSTEM_NOTIFICATION_ALERT_TYPE,
  SYSTEM_NOTIFICATION_SUB_TYPE,
  SYSTEM_NOTIFICATION_TYPE,
  TECHNICIAN_SELECTION_BEHAVIOR,
  TIMELINE_ENTRY_RELATED_TYPE,
  TIMELINE_ENTRY_TYPE,
  USER_ACCESS_LEVEL,
  USER_STATUS,
  WEEKDAYS
} from "./enums";
import { Moment } from 'moment-timezone';
import {
  IAddressParts,
  IAddressPartsWithLocation,
  ICalendarEvent,
  IDateFormat,
  ILatLng,
  IMappingItineraryItem,
  ITimeFormat
} from "./interfaces";

import { compact, each, sortBy, values, values as _values } from 'lodash';
import { ALERT_GREEN_COLOR, ALERT_RED_COLOR, ALERT_YELLOW_COLOR, GRAY_COLOR } from "./colors";
import * as md5 from "md5";
import { convertNumberTimeToStringTime, formatDuration, parseTime } from "./utils/time";
import { formatMessage, getLocaleStoreInstance } from "./utils/intl";
import { addressPartsToLine, addressPartsToLines } from "./utils/mapping";
import { formatCurrency, formatPercent, roundAwayFromZero } from "./utils/numbers";
import { getPianoDisplayName } from "./utils/piano_names";
import {
  MSG_availabilityInactiveReasonAfterEndDate,
  MSG_availabilityInactiveReasonBeforeStartDate,
  MSG_availabilityInactiveReasonExcludedDate,
  MSG_availabilityInactiveReasonNotInRecurrenceRule,
  MSG_availabilityInactiveReasonOtherExclusiveAvailability,
  MSG_couponDiscountDescription,
  MSG_unknownAddressLabel,
  MSG_unknownCityLabel,
  MSG_unknownPhoneLabel,
  MSG_unknownPostalCodeLabel,
  MSG_unnamedClientTitle
} from "./strings";
import { RootStore } from "./stores";
import { isNullOrUndefined } from "./utils/values";
import { MessageDescriptor } from 'react-intl';
import { buildAvailabilityRRule } from "./utils/rrule";
import { v4 as uuid } from 'uuid';

const moment = require('moment-timezone');

/********************************************************************************************/

export class Availability {
  startOn: string;
  startTime: number;
  startType: 'HOME' | 'CLIENT';
  startLocation: string;
  startLocationLat: number;
  startLocationLng: number;
  startLocationType: 'ROOFTOP' | 'RANGE_INTERPOLATED' | 'GEOMETRIC_CENTER' | 'APPROXIMATE';
  endOn: string;
  endTime: number;
  endType: 'HOME' | 'CLIENT';
  endLocation: string;
  endLocationLat: number;
  endLocationLng: number;
  endLocationType: 'ROOFTOP' | 'RANGE_INTERPOLATED' | 'GEOMETRIC_CENTER' | 'APPROXIMATE';
  serviceAreaLocation: string;
  serviceAreaLocationLat: number;
  serviceAreaLocationLng: number;
  serviceAreaLocationType: 'ROOFTOP' | 'RANGE_INTERPOLATED' | 'GEOMETRIC_CENTER' | 'APPROXIMATE';
  serviceAreaRadius: number;
  user: User;

  constructor(attrs: any = {}) {
    this.startOn = attrs.startOn;
    this.startTime = attrs.startTime;
    this.startType = attrs.startType;
    this.startLocation = attrs.startLocation;
    this.startLocationLat = attrs.startLocationLat;
    this.startLocationLng = attrs.startLocationLng;
    this.startLocationType = attrs.startLocationType;
    this.endOn = attrs.endOn;
    this.endTime = attrs.endTime;
    this.endType = attrs.endType;
    this.endLocation = attrs.endLocation;
    this.endLocationLat = attrs.endLocationLat;
    this.endLocationLng = attrs.endLocationLng;
    this.endLocationType = attrs.endLocationType;
    this.serviceAreaLocation = attrs.serviceAreaLocation;
    this.serviceAreaLocationLat = attrs.serviceAreaLocationLat;
    this.serviceAreaLocationLng = attrs.serviceAreaLocationLng;
    this.serviceAreaLocationType = attrs.serviceAreaLocationType;
    this.serviceAreaRadius = attrs.serviceAreaRadius;
    this.user = new User(attrs.user);
  }

  get start(): Moment {
    let s = parseTime(convertNumberTimeToStringTime(this.startTime));
    return moment.tz(this.startOn, this.user.timezone).hour(s.h).minute(s.m);
  }

  get end(): Moment {
    let e = parseTime(convertNumberTimeToStringTime(this.endTime));
    return moment.tz(this.endOn, this.user.timezone).hour(e.h).minute(e.m);
  }
}

type AvailabilityInfo = {
  startTime: string,
  startLocationLat?: string,
  startLocationLng?: string,
  endTime: string,
  endLocationLat?: string,
  endLocationLng?: string
};

export class CalendarAvailability {
  userId: string;
  times: AvailabilityInfo[];
  activeAvailabilities: SchedulerV2Availability[];
  inactiveAvailabilities: SchedulerV2Availability[];

  constructor(userId: string, times: AvailabilityInfo[], activeAvailabilities: SchedulerV2Availability[] = [], inactiveAvailabilities: SchedulerV2Availability[] = []) {
    this.userId = userId;
    this.times = times;
    this.activeAvailabilities = activeAvailabilities;
    this.inactiveAvailabilities = inactiveAvailabilities;
  }

  get earliestStart(): AvailabilityInfo {
    return sortBy(this.times, [(t) => t.startTime])[0];
  }

  get latestEnd(): AvailabilityInfo {
    return sortBy(this.times, [(t) => t.endTime]).reverse()[0];
  }

  static fromAvailability(availability: Availability): CalendarAvailability {
    if (availability?.startTime && availability?.endTime) {
      return new CalendarAvailability(availability.user.id, [{
        startTime: convertNumberTimeToStringTime(availability.startTime),
        startLocationLat: `${availability.startLocationLat}`,
        startLocationLng: `${availability.startLocationLng}`,
        endTime: convertNumberTimeToStringTime(availability.endTime),
        endLocationLat: `${availability.endLocationLat}`,
        endLocationLng: `${availability.endLocationLng}`
      }]);
    } else {
      return null;
    }
  }

  static fromSchedulerV2Availabilities(userId: string, activeAvailabilities: SchedulerV2Availability[], inactiveAvailabilities: SchedulerV2Availability[]): CalendarAvailability {
    // availabilities startTime and endTime are in strings formatted as "HH:MM".
    // Convert the list of availabilities to a list of {startTime, endTime} objects,
    // but combine them to remove any overlaps.
    let times: AvailabilityInfo[] = [];
    sortBy(inactiveAvailabilities, [(a) => a.startTime]);
    sortBy(activeAvailabilities, [(a) => a.startTime]).forEach((availability: SchedulerV2Availability) => {
      let startTime = availability.startTime;
      let endTime = availability.endTime;
      if (times.length > 0) {
        let lastTime = times[times.length - 1];
        if (lastTime.startTime <= startTime && lastTime.endTime >= startTime) {
          // The start time is within the last time.
          if (lastTime.endTime < endTime) {
            // The end time is after the last time.
            lastTime.endTime = endTime;
          }
          return;
        }
      }
      times.push({
        startTime,
        startLocationLat: availability.startOfDayLocation.manualLat,
        startLocationLng: availability.startOfDayLocation.manualLng,
        endTime,
        endLocationLat: availability.endOfDayLocation.manualLat,
        endLocationLng: availability.endOfDayLocation.manualLng
      });
    });

    return new CalendarAvailability(userId, times, activeAvailabilities, inactiveAvailabilities);
  }
}

export type CalendarAvailabilityMap = {
  [date: string]: {[userId: string]: CalendarAvailability};
};

export function toAvailabilityMap(allAvailability: Availability[]): CalendarAvailabilityMap {
  let map: CalendarAvailabilityMap = {};
  const today = moment().startOf('day');

  allAvailability.forEach(avail => {
    let date = moment(avail.startOn);
    while (date.isSameOrBefore(avail.endOn)) {
      if (date.isSameOrAfter(today)) {
        let a = CalendarAvailability.fromAvailability(avail);
        if (a) {
          const dateStr = date.format('YYYY-MM-DD');
          if (!map[dateStr]) map[dateStr] = {};
          map[dateStr][avail.user.id] = a;
        }
      }
      date.add(1, 'day');
    }
  });

  return map;
}

// Note that for backwards compatibility reasons, AvailabilityMap is indexed by userId.  However, when
// we reworked this for Scheduler V2, we only ever use availability of a single user.  So this will
// always be a map of a single user's availability.
export function toAvailabilityMapV2(userId: string, startDate: string, endDate: string, allAvailability: SchedulerV2Availability[]): CalendarAvailabilityMap {
  const map: CalendarAvailabilityMap = {};

  let startDateMoment = moment(startDate);
  let endDateMoment = moment(endDate);
  for (let date = moment(startDateMoment); date.isSameOrBefore(endDate); date.add(1, 'days')) {
    const dateStr = date.format('YYYY-MM-DD');

    let activeAvailabilities: SchedulerV2Availability[] = [];
    let inactiveAvailabilities: SchedulerV2Availability[] = [];

    let hasExclusive = false;
    (allAvailability || []).forEach(a => {
      if (moment(a.startDate).isSameOrBefore(dateStr) && (!a.endDate || moment(a.endDate).isSameOrAfter(dateStr))) {
        if (a.isExclusive && !hasExclusive) {
          hasExclusive = true;
          inactiveAvailabilities = [...inactiveAvailabilities, ...activeAvailabilities];
          activeAvailabilities = [];
        }

        if (a.isExclusive || !hasExclusive) {
          if (a.includeDates.includes(dateStr)) {
            activeAvailabilities.push(a);
          } else if (a.excludeDates.includes(dateStr)) {
            inactiveAvailabilities.push(a);
          } else if (a.recurrenceRule) {
            const rule = buildAvailabilityRRule(a);
            let startDateObj = datetime(startDateMoment.year(), startDateMoment.month() + 1, startDateMoment.date());
            let today = datetime(new Date().getFullYear(), new Date().getMonth() + 1, new Date().getDate());
            if (startDateObj < today) {
              startDateObj = today;
            }
            let endDateObj = datetime(endDateMoment.year(), endDateMoment.month() + 1, endDateMoment.date(), 23, 59, 59);
            const occurrences = rule.between(startDateObj, endDateObj, true);
            const matches = occurrences.filter((occurrence: Date) => moment(occurrence).utc().format('YYYY-MM-DD') === dateStr);
            if (matches.length > 0) {
              activeAvailabilities.push(a);
            } else {
              inactiveAvailabilities.push(a);
            }
          } else {
            inactiveAvailabilities.push(a);
          }
        } else {
          inactiveAvailabilities.push(a);
        }
      }
    });

    if (!map[dateStr]) map[dateStr] = {};
    map[dateStr][userId] = CalendarAvailability.fromSchedulerV2Availabilities(userId, activeAvailabilities, inactiveAvailabilities);
  }

  return map;
}

/********************************************************************************************/

export class Client {
  id: string;
  status: CLIENT_STATUS_TYPE;
  companyName: string;
  clientType: string;
  referenceId: string;
  custom1: string;
  custom2: string;
  custom3: string;
  custom4: string;
  custom5: string;
  custom6: string;
  custom7: string;
  custom8: string;
  custom9: string;
  custom10: string;
  reasonInactiveCode: string;
  reasonInactiveDetails: string;
  preferredTechnicianId: string;
  lifecycle: Lifecycle;
  defaultContact: Contact;
  defaultBillingContact: Contact;
  allPianos: Piano[];
  allInactivePianos: Piano[];
  pianosTotalCount: number;
  inactivePianosTotalCount: number;
  allContacts: Contact[];
  contactsTotalCount: number;
  lastInvoice?: Invoice;
  noContactUntil?: Moment;
  noContactReason?: string;
  personalNotes?: string;
  preferenceNotes?: string;
  referredBy?: string;
  referredByNotes?: string;
  referralClient?: Client;
  allClientsReferred: Client[];
  localization: Localization;
  defaultClientLocalization: Localization;
  createdAt: Moment;
  updatedAt: Moment;
  tags: string[];

  constructor(attrs: any = {}) {
    if (!attrs) attrs = {};
    this.id = attrs.id;
    this.status = attrs.status;
    this.companyName = attrs.companyName;
    this.clientType = attrs.clientType;
    this.referenceId = attrs.referenceId;
    this.custom1 = attrs.custom1;
    this.custom2 = attrs.custom2;
    this.custom3 = attrs.custom3;
    this.custom4 = attrs.custom4;
    this.custom5 = attrs.custom5;
    this.custom6 = attrs.custom6;
    this.custom7 = attrs.custom7;
    this.custom8 = attrs.custom8;
    this.custom9 = attrs.custom9;
    this.custom10 = attrs.custom10;
    this.preferredTechnicianId = attrs.preferredTechnicianId;
    this.reasonInactiveCode = attrs.reasonInactiveCode;
    this.reasonInactiveDetails = attrs.reasonInactiveDetails;
    this.localization = attrs.localization;
    this.defaultClientLocalization = attrs.defaultClientLocalization ? new Localization(attrs.defaultClientLocalization) : null;

    this.noContactReason = attrs.noContactReason;
    this.noContactUntil = null;
    if (attrs.noContactUntil) this.noContactUntil = moment(attrs.noContactUntil);

    if (attrs.lifecycle) {
      this.lifecycle = new Lifecycle(attrs.lifecycle);
    }
    if (attrs.defaultContact) {
      this.defaultContact = new Contact(attrs.defaultContact);
    }
    if (attrs.defaultBillingContact) {
      this.defaultBillingContact = new Contact(attrs.defaultBillingContact);
    }
    this.allPianos = [];
    this.pianosTotalCount = 0;
    if (attrs.allPianos) {
      if (attrs.allPianos.nodes) {
        this.allPianos = attrs.allPianos.nodes.map((node: any) => new Piano(node));
        this.pianosTotalCount = attrs.allPianos.totalCount;
      } else {
        this.allPianos = attrs.allPianos.map((p: Piano) => new Piano(p));
        this.pianosTotalCount = this.allPianos.length;
      }
    }
    this.allInactivePianos = [];
    this.inactivePianosTotalCount = 0;
    if (attrs.allInactivePianos) {
      if (attrs.allInactivePianos.nodes) {
        this.allInactivePianos = attrs.allInactivePianos.nodes.map((node: any) => new Piano(node));
        this.inactivePianosTotalCount = attrs.allInactivePianos.totalCount;
      } else {
        this.allInactivePianos = attrs.allInactivePianos.map((p: Piano) => new Piano(p));
        this.inactivePianosTotalCount = this.allInactivePianos.length;
      }
    }
    this.allContacts = [];
    this.contactsTotalCount = 0;
    if (attrs.allContacts) {
      if (attrs.allContacts.nodes) {
        this.allContacts = attrs.allContacts.nodes.map((node: any) => new Contact(node));
        this.contactsTotalCount = attrs.allContacts.totalCount;
      } else {
        this.allContacts = attrs.allContacts.map((c: Contact) => new Contact(c));
        this.contactsTotalCount = this.allContacts.length;
      }
    }
    this.lastInvoice = attrs.lastInvoice ? new Invoice(attrs.lastInvoice) : null;
    this.personalNotes = attrs.personalNotes ? attrs.personalNotes : null;
    this.preferenceNotes = attrs.preferenceNotes ? attrs.preferenceNotes : null;

    this.referredBy = attrs.referredBy;
    this.referredByNotes = attrs.referredByNotes;
    if (attrs.referralClient) this.referralClient = new Client(attrs.referralClient);
    if (attrs.allClientsReferred) {
      this.allClientsReferred = attrs.allClientsReferred.map((data: any) => new Client(data));
    } else {
      this.allClientsReferred = [];
    }

    this.createdAt = attrs.createdAt ? moment(attrs.createdAt) : null;
    this.updatedAt = attrs.updatedAt ? moment(attrs.updatedAt) : null;
    this.tags = attrs.tags || [];
  }

  get allContactsDefaultFirst(): Contact[] {
    return compact([this.defaultContact, ...this.nonDefaultContacts]);
  }

  get nonDefaultContacts(): Contact[] {
    if (this.allContacts && this.allContacts.length > 0) {
      return this.allContacts.filter(c => !c.isDefault);
    } else {
      return [];
    }
  }

  get displayName() {
    if (this.companyName && this.companyName.trim()) return this.companyName;
    return this.contactDisplayName;
  }

  get contactDisplayName() {
    if (this.defaultContact) return this.defaultContact.displayName;
    return formatMessage(MSG_unnamedClientTitle);
  }

  get defaultPosition() {
    if (this.defaultContact) return this.defaultContact.defaultPosition;
    return null;
  }

  get defaultAddressLine() {
    if (this.defaultContact) return this.defaultContact.defaultAddressLine;
    return null;
  }

  get defaultAddressLines() {
    if (this.defaultContact) return this.defaultContact.defaultAddressLines;
    return [formatMessage(MSG_unknownAddressLabel)];
  }

  get displayAddress() {
    if (this.defaultContact) return this.defaultContact.displayAddress;
    return formatMessage(MSG_unknownAddressLabel);
  }

  get displayCity() {
    if (this.defaultContact) return this.defaultContact.displayCity;
    return formatMessage(MSG_unknownCityLabel);
  }

  get displayZip() {
    if (this.defaultContact) return this.defaultContact.displayZip;
    return formatMessage(MSG_unknownPostalCodeLabel);
  }

  get displayExtendedCity() {
    if (this.defaultContact) return this.defaultContact.displayExtendedCity;
    return formatMessage(MSG_unknownCityLabel);
  }

  get displayPhone() {
    if (this.defaultContact) return this.defaultContact.displayPhone;
    return formatMessage(MSG_unknownPhoneLabel);
  }

  get displayEmail() {
    if (this.defaultContact) return this.defaultContact.displayEmail;
    return formatMessage(MSG_unknownAddressLabel);
  }

  get isPaused() {
    return this.noContactUntil && this.noContactUntil.isAfter();
  }

  get canSendReminderEmail() {
    if (!this.defaultContact) return false;
    if (!this.defaultContact.defaultEmail) return false;
    if (!this.defaultContact.wantsEmail) return false;
    return true;
  }

  get canSendReminderSms() {
    let hasTextableNumber = false;
    this.allContacts.forEach(contact => {
      if (contact.wantsText && contact.defaultConfirmedMobilePhone) hasTextableNumber = true;
    });
    return hasTextableNumber;
  }
}

/********************************************************************************************/

export class ClientLog {
  id?: string;
  client?: Client;
  type?: CLIENT_LOG_TYPE;
  piano?: Piano;
  systemMessage?: string;
  comment?: string;
  createdBy?: User;
  createdAt?: Moment;
  status?: CLIENT_LOG_STATUS;
  automated?: boolean;
  invoice?: Invoice;
  verbose?: boolean;

  constructor(attrs: any = {}) {
    this.id = attrs.id ? attrs.id : null;
    this.type = attrs.type ? attrs.type : null;
    this.systemMessage = attrs.systemMessage ? attrs.systemMessage : null;
    this.comment = attrs.comment ? attrs.comment : null;
    this.createdAt = attrs.createdAt ? moment(attrs.createdAt) : null;
    this.status = attrs.status ? attrs.status : null;
    this.automated = attrs.automated ? attrs.automated : null;
    this.verbose = attrs.verbose ? attrs.verbose : null;

    if (attrs.client) {
      this.client = new Client(attrs.client);
    }
    if (attrs.piano) {
      this.piano = new Piano(attrs.piano);
    }
    if (attrs.invoice) {
      this.invoice  = new Invoice(attrs.invoice);
    }
    if (attrs.createdBy) {
      this.createdBy = new User(attrs.createdBy);
    }
  }

}

/********************************************************************************************/

export class CompanyStripeCoupon {
  couponId: string;
  percentOff: number;
  amountOff: number;
  endsAt: Moment;

  constructor(attrs: any = {}) {
    Object.assign(this, attrs);
    if (attrs.endsAt) this.endsAt = moment(attrs.endsAt);
  }

  getDescription() {
    if (this.percentOff) {
      return formatMessage(MSG_couponDiscountDescription, {amount: formatPercent(this.percentOff, 1, 0)});
    } else if (this.amountOff) {
      return formatMessage(MSG_couponDiscountDescription, {amount: formatCurrency(this.amountOff)});
    } else {
      return null;
    }
  }
}

/********************************************************************************************/

export class UpcomingMaintenanceNotice {
  message: string;
  startsAt: Moment;

  constructor(attrs: any = {}) {
    this.message = attrs.message;
    this.startsAt = moment(attrs.startsAt);
  }
}

/********************************************************************************************/

export class Company {
  id: string;
  name: string;
  phoneNumber: string;
  email: string;
  website: string;
  urlToken: string;
  logoExists: boolean;
  logoUrl: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  lat: number;
  lng: number;
  countryCode: string;
  countryName: string;
  isSmartRoutesEnabled: boolean;
  areAllRemindersPaused: boolean;
  upcomingMaintenanceNotice: UpcomingMaintenanceNotice;
  isParked: boolean;
  parkedReason: COMPANY_PARKED_REASON;
  billablePianoLimit: number;
  billablePianoCount: number;
  stripeTrialEndsAt: Moment;
  stripeCoupon: CompanyStripeCoupon;
  isGdprRequired: boolean;
  gazelleReferral?: GazelleReferral;
  createdAt: Moment;

  hourlyRate: number;
  headerLayout: COMPANY_HEADER_LAYOUT_TYPE;

  settingsLocationBiasLat: number;
  settingsLocationBiasLng: number;
  settingsLocationBiasRadius: number;

  settingsGeneralReceiptEmail: string;
  settingsGeneralHourlyRate: number;
  settingsGeneralClientCustom1Label: string;
  settingsGeneralClientCustom2Label: string;
  settingsGeneralClientCustom3Label: string;
  settingsGeneralClientCustom4Label: string;
  settingsGeneralClientCustom5Label: string;
  settingsGeneralClientCustom6Label: string;
  settingsGeneralClientCustom7Label: string;
  settingsGeneralClientCustom8Label: string;
  settingsGeneralClientCustom9Label: string;
  settingsGeneralClientCustom10Label: string;
  settingsClientDefaultWantsText: boolean;
  settingsEstimatesNextEstimateNumber: number;
  settingsEstimatesDefaultMonthsExpiresOn: number;
  settingsEstimatesDefaultNotes: I18nString;
  settingsEstimatesSendQuestionsToAllActiveAdmins: boolean;
  settingsEstimatesSendQuestionsToCreator: boolean;
  settingsEstimatesSendQuestionsToEmails: string[];

  mappingTypeaheadProvider: MAPPING_PROVIDER_TYPE;
  mappingInteractiveMapProvider: MAPPING_PROVIDER_TYPE;
  mappingStaticMapProvider: MAPPING_PROVIDER_TYPE;
  mappingRoutePolylineProvider: MAPPING_PROVIDER_TYPE;

  invoicesNextNumber: number;
  invoicesDefaultNetDays: number;
  invoicesDefaultNoteHeader: string;
  invoicesDefaultNote: string;
  invoicesDefaultTopNoteHeader: string;
  invoicesDefaultTopNote: string;
  invoicesDefaultPaymentType: INVOICE_PAYMENT_TYPE;
  invoicesDefaultUserPaymentOption: DEFAULT_USER_PAYMENT_OPTION;

  defaultCurrency: Currency;
  defaultUserLocalization: Localization;
  defaultClientLocalization: Localization;

  smsIsEnabled: boolean;

  settingsStripePaymentsAccountId: string | null;
  settingsStripePaymentsAcceptedPaymentMethods: STRIPE_PAYMENT_METHODS[];
  settingsStripePublishableKey: string;
  settingsStripePaymentsAvailable: boolean;
  settingsStripePaymentsDefaultAcceptElectronicPayments: boolean;
  settingsTipsEnabled: boolean;
  settingsTipsPublicGuiAutoselect: boolean;

  settingsQuickbooksSyncGazelleUsersAsQboClasses: boolean;
  settingsQuickbooksSyncStripePayouts: boolean;
  settingsQuickbooksPullPayments: boolean;
  settingsQuickbooksSyncStartDate: Moment;
  settingsQuickbooksAllowOnlineCreditCardPayment: boolean;
  settingsQuickbooksAllowOnlineAchPayment: boolean;

  settingsSchedulerInvalidAddressDefaultDriveTime: number;

  // self-scheduler settings
  settingsSelfSchedulerWelcomeMessage: I18nString;
  settingsSelfSchedulerNoAvailabilityMessage: I18nString;
  settingsSelfSchedulerOutsideServiceAreaMessage: I18nString;
  settingsSelfSchedulerReservationCompleteMessage: I18nString;
  settingsSelfSchedulerReservationCompleteBehavior: RESERVATION_COMPLETE_BEHAVIOR;
  settingsSelfSchedulerTechnicianSelectionBehavior: TECHNICIAN_SELECTION_BEHAVIOR;
  settingsSelfSchedulerShowCosts: boolean;
  settingsSelfSchedulerEnabled: boolean;

  // legacy self-scheduler settings (to go away when we migrate to public v2 ui
  settingsLegacySelfSchedulerOutsideServiceAreaMessage: string;
  settingsLegacySelfSchedulerSpecialInstructions: string;
  settingsLegacySelfSchedulerCompletionMessage: string;
  settingsLegacySelfSchedulerCompletionRedirect: string;

  // The supported payment methods for the company's country+currency.
  supportedStripePaymentMethods: STRIPE_PAYMENT_METHODS[];
  gazelleStripeTransactionFee: number;

  isQuickbooksConnectedPassive: boolean;

  // billing stuff
  billingCurrency: Currency;
  defaultPaymentMethodSummary: string;
  currentBalanceAmount: number;
  currentBalanceCurrency: Currency;
  subscriptionStatus: SUBSCRIPTION_STATUS;
  pricingModel: PRICING_MODEL;
  discounts: JakklopsDiscount[];
  currentPlan: JakklopsPricingPlan;
  currentPlanWillCancel: boolean;
  upcomingPlan: JakklopsPricingPlan;
  upcomingInvoice: JakklopsBillingInvoice;
  billingCycleEndDate: Moment;
  freeTrialEndsOn: Moment;

  // Feature toggles
  featureToggleSchedulerV2Allowed: boolean;
  featureToggleSchedulerV2Enabled: boolean;
  featureToggleSchedulerV2CanDowngrade: boolean;
  featureTogglePublicV2Allowed: boolean;
  featureTogglePublicV2Enabled: boolean;

  // Branding
  brandingHeaderLayout: COMPANY_HEADER_LAYOUT_TYPE;
  brandingMaxLogoPx: number;
  brandingPrimaryColor: string;
  brandingShowCompanyAddress: boolean;
  brandingShowCompanyEmail: boolean;
  brandingShowCompanyPhone: boolean;
  brandingPrivacyPolicy: I18nString;
  brandingTermsOfService: I18nString;

  constructor(attrs: Company | any = {}) {
    this.id = attrs.id;
    this.name = attrs.name;
    this.phoneNumber = attrs.phoneNumber;
    this.email = attrs.email;
    this.website = attrs.website;
    this.urlToken = attrs.urlToken;
    this.address1 = attrs.address1;
    this.address2 = attrs.address2;
    this.city = attrs.city;
    this.state = attrs.state;
    this.zip = attrs.zip;
    this.lat = attrs.lat;
    this.lng = attrs.lng;
    this.countryCode = attrs.countryCode;
    this.countryName = attrs.countryName;
    this.isSmartRoutesEnabled = attrs.isSmartRoutesEnabled;
    this.isParked = attrs.isParked;
    this.parkedReason = attrs.parkedReason;
    this.billablePianoCount = attrs.billablePianoCount;
    this.billablePianoLimit = attrs.billablePianoLimit;

    this.hourlyRate = (attrs?.settings?.general?.hourlyRate === undefined || attrs?.settings?.general?.hourlyRate === null) ? null : parseInt(attrs.settings.general.hourlyRate);
    this.headerLayout = (attrs?.settings?.general?.headerLayout || COMPANY_HEADER_LAYOUT_TYPE.FULL_LOGO_WITHOUT_NAME) as COMPANY_HEADER_LAYOUT_TYPE;

    this.settingsGeneralReceiptEmail = attrs?.settings?.general?.receiptEmail || '';
    this.settingsGeneralHourlyRate = (attrs?.settings?.general?.hourlyRate === undefined || attrs?.settings?.general?.hourlyRate === null) ? null : parseInt(attrs.settings.general.hourlyRate);
    this.settingsGeneralClientCustom1Label = (attrs?.settings?.general?.clientCustom1Label === undefined || attrs?.settings?.general?.clientCustom1Label === null) ? null : attrs.settings.general.clientCustom1Label;
    this.settingsGeneralClientCustom2Label = (attrs?.settings?.general?.clientCustom2Label === undefined || attrs?.settings?.general?.clientCustom2Label === null) ? null : attrs.settings.general.clientCustom2Label;
    this.settingsGeneralClientCustom3Label = (attrs?.settings?.general?.clientCustom3Label === undefined || attrs?.settings?.general?.clientCustom3Label === null) ? null : attrs.settings.general.clientCustom3Label;
    this.settingsGeneralClientCustom4Label = (attrs?.settings?.general?.clientCustom4Label === undefined || attrs?.settings?.general?.clientCustom4Label === null) ? null : attrs.settings.general.clientCustom4Label;
    this.settingsGeneralClientCustom5Label = (attrs?.settings?.general?.clientCustom5Label === undefined || attrs?.settings?.general?.clientCustom5Label === null) ? null : attrs.settings.general.clientCustom5Label;
    this.settingsGeneralClientCustom6Label = (attrs?.settings?.general?.clientCustom6Label === undefined || attrs?.settings?.general?.clientCustom6Label === null) ? null : attrs.settings.general.clientCustom6Label;
    this.settingsGeneralClientCustom7Label = (attrs?.settings?.general?.clientCustom7Label === undefined || attrs?.settings?.general?.clientCustom7Label === null) ? null : attrs.settings.general.clientCustom7Label;
    this.settingsGeneralClientCustom8Label = (attrs?.settings?.general?.clientCustom8Label === undefined || attrs?.settings?.general?.clientCustom8Label === null) ? null : attrs.settings.general.clientCustom8Label;
    this.settingsGeneralClientCustom9Label = (attrs?.settings?.general?.clientCustom9Label === undefined || attrs?.settings?.general?.clientCustom9Label === null) ? null : attrs.settings.general.clientCustom9Label;
    this.settingsGeneralClientCustom10Label = (attrs?.settings?.general?.clientCustom10Label === undefined || attrs?.settings?.general?.clientCustom10Label === null) ? null : attrs.settings.general.clientCustom10Label;

    this.settingsClientDefaultWantsText = (attrs?.settings?.client?.defaultWantsText === undefined || attrs?.settings?.client?.defaultWantsText === null) ? null : attrs.settings.client.defaultWantsText;

    this.settingsLocationBiasLat = attrs?.settings?.locationBias?.lat;
    this.settingsLocationBiasLng = attrs?.settings?.locationBias?.lng;
    this.settingsLocationBiasRadius = attrs?.settings?.locationBias?.radius;

    this.settingsEstimatesNextEstimateNumber = attrs?.settings?.estimates?.nextEstimateNumber;
    this.settingsEstimatesDefaultMonthsExpiresOn = attrs?.settings?.estimates?.defaultMonthsExpiresOn;
    this.settingsEstimatesDefaultNotes = attrs?.settings?.estimates?.defaultNotes ? new I18nString(attrs.settings.estimates.defaultNotes) : new I18nString({});
    this.settingsEstimatesSendQuestionsToAllActiveAdmins = attrs?.settings?.estimates?.sendQuestionsToAllActiveAdmins;
    this.settingsEstimatesSendQuestionsToCreator = attrs?.settings?.estimates?.sendQuestionsToCreator;
    this.settingsEstimatesSendQuestionsToEmails = attrs?.settings?.estimates?.sendQuestionsToEmails;

    this.supportedStripePaymentMethods = attrs?.supportedStripePaymentMethods || [];

    this.mappingTypeaheadProvider = attrs?.settings?.mapping?.typeaheadProvider;
    this.mappingInteractiveMapProvider = attrs?.settings?.mapping?.interactiveMapProvider;
    this.mappingStaticMapProvider = attrs?.settings?.mapping?.staticMapProvider;
    this.mappingRoutePolylineProvider = attrs?.settings?.mapping?.routePolylineProvider;

    this.invoicesNextNumber = attrs?.settings?.invoices?.nextInvoiceNumber;
    this.invoicesDefaultNetDays = attrs?.settings?.invoices?.defaultInvoiceNetDays;
    this.invoicesDefaultNoteHeader = attrs?.settings?.invoices?.defaultInvoiceNotesHeader;
    this.invoicesDefaultNote = attrs?.settings?.invoices?.defaultInvoiceNotes;
    this.invoicesDefaultTopNoteHeader = attrs?.settings?.invoices?.defaultInvoiceTopNotesHeader;
    this.invoicesDefaultTopNote = attrs?.settings?.invoices?.defaultInvoiceTopNotes;
    this.invoicesDefaultPaymentType = attrs?.settings?.invoices?.defaultInvoicePaymentType;
    this.invoicesDefaultUserPaymentOption = attrs?.settings?.invoices?.defaultUserPaymentOption;

    this.defaultCurrency = attrs?.settings?.localization?.defaultCurrency || {
      code: "USD",
      decimalDigits: 2,
      divisor: 100,
      label: "USD - $",
      symbol: "$",
    };
    this.defaultUserLocalization = attrs?.settings?.localization?.defaultUserLocalization;
    this.defaultClientLocalization = attrs?.settings?.localization?.defaultClientLocalization;

    this.smsIsEnabled = attrs?.settings?.sms?.isEnabled;

    if (attrs.logo) {
      this.logoExists = attrs.logo.exists;
      this.logoUrl = attrs.logo.url || null;
    }

    Object.assign(this, attrs);

    this.stripeTrialEndsAt = attrs.stripeTrialEndsAt ? moment(attrs.stripeTrialEndsAt) : null;
    this.stripeCoupon = attrs.stripeCoupon ? new CompanyStripeCoupon(attrs.stripeCoupon) : null;
    this.upcomingMaintenanceNotice = attrs.upcomingMaintenanceNotice ? new UpcomingMaintenanceNotice(attrs.upcomingMaintenanceNotice) : null;
    this.areAllRemindersPaused = attrs?.areAllRemindersPaused;

    this.settingsTipsEnabled = !!attrs.settings?.invoices?.tipsEnabled;
    this.settingsTipsPublicGuiAutoselect = !!attrs.settings?.invoices?.tipsPublicGuiAutoselect;
    this.settingsStripePaymentsAccountId = attrs.settings?.stripePayments?.accountId;
    this.settingsStripePaymentsAcceptedPaymentMethods = attrs?.settings?.stripePayments?.acceptedPaymentMethods || [];
    this.settingsStripePublishableKey = attrs.settings?.stripePayments?.stripePublishableKey;
    this.settingsStripePaymentsAvailable = attrs.settings?.stripePayments?.availableForCountry;
    if (typeof attrs.settings?.stripePayments?.defaultAcceptElectronicPayments === 'undefined') { // default this to true
      this.settingsStripePaymentsDefaultAcceptElectronicPayments = true;
    } else {
      this.settingsStripePaymentsDefaultAcceptElectronicPayments = attrs.settings?.stripePayments?.defaultAcceptElectronicPayments;
    }

    this.settingsQuickbooksSyncGazelleUsersAsQboClasses = attrs.settings?.quickbooksOnline?.syncGazelleUsersAsQboClasses;
    this.settingsQuickbooksSyncStripePayouts = attrs.settings?.quickbooksOnline?.syncStripePayouts;
    this.settingsQuickbooksPullPayments = attrs.settings?.quickbooksOnline?.pullPayments;
    this.settingsQuickbooksSyncStartDate = moment(attrs.settings?.quickbooksOnline?.syncStartDate || moment());
    this.settingsQuickbooksAllowOnlineCreditCardPayment = attrs.settings?.quickbooksOnline?.allowOnlineCreditCardPayment;
    this.settingsQuickbooksAllowOnlineAchPayment = attrs.settings?.quickbooksOnline?.allowOnlineAchPayment;

    this.gazelleStripeTransactionFee = attrs.gazelleStripeTransactionFee;
    this.settingsSchedulerInvalidAddressDefaultDriveTime = attrs.settings?.scheduler?.invalidAddressDefaultDriveTime || 0;

    this.gazelleReferral = attrs.gazelleReferral ? new GazelleReferral(attrs.gazelleReferral) : null;

    this.billingCurrency = attrs.billing?.currency;
    this.defaultPaymentMethodSummary = attrs.billing?.defaultPaymentMethodSummary;
    this.currentBalanceAmount = attrs.billing?.currentBalance?.amount;
    this.currentBalanceCurrency = attrs.billing?.currentBalance?.currency;
    this.pricingModel = attrs.billing?.pricingModel || PRICING_MODEL.JAKKLOPS;
    this.discounts = attrs.billing?.jakklops?.discounts?.map((c: any) => new JakklopsDiscount(c)) || [];
    this.currentPlan = attrs.billing?.jakklops?.currentPlan ? new JakklopsPricingPlan(attrs.billing.jakklops.currentPlan) : null;
    this.currentPlanWillCancel = attrs.billing?.jakklops?.currentPlanWillCancel;
    this.upcomingPlan = attrs.billing?.jakklops?.upcomingPlan ? new JakklopsPricingPlan(attrs.billing.jakklops.upcomingPlan) : null;
    this.upcomingInvoice = attrs.billing?.jakklops?.upcomingInvoice ? new JakklopsBillingInvoice(attrs.billing.jakklops.upcomingInvoice) : null;
    this.freeTrialEndsOn = attrs.billing?.jakklops?.freeTrialEndsOn ? moment(attrs.billing.jakklops.freeTrialEndsOn) : null;
    this.billingCycleEndDate = attrs.billing?.jakklops?.billingCycleEndDate ? moment(attrs.billing.jakklops.billingCycleEndDate) : null;
    this.subscriptionStatus = attrs.billing?.subscriptionStatus;

    this.featureToggleSchedulerV2Allowed = attrs.settings?.featureToggles?.schedulerV2Allowed || false;
    this.featureToggleSchedulerV2Enabled = attrs.settings?.featureToggles?.schedulerV2Enabled || false;
    this.featureToggleSchedulerV2CanDowngrade = isNullOrUndefined(attrs.settings?.featureToggles?.schedulerV2CanDowngrade) ? true : attrs.settings?.featureToggles?.schedulerV2CanDowngrade;
    this.featureTogglePublicV2Allowed = attrs.settings?.featureToggles?.publicV2Allowed || false;
    this.featureTogglePublicV2Enabled = attrs.settings?.featureToggles?.publicV2Enabled || false;

    this.brandingHeaderLayout = attrs.settings?.branding?.headerLayout;
    this.brandingMaxLogoPx = attrs.settings?.branding?.maxLogoPx;
    this.brandingPrimaryColor = attrs.settings?.branding?.primaryColor;
    this.brandingShowCompanyAddress = attrs.settings?.branding?.showCompanyAddress;
    this.brandingShowCompanyEmail = attrs.settings?.branding?.showCompanyEmail;
    this.brandingShowCompanyPhone = attrs.settings?.branding?.showCompanyPhone;
    this.brandingPrivacyPolicy = attrs.settings?.branding?.privacyPolicy ? new I18nString(attrs.settings?.branding?.privacyPolicy) : null;
    this.brandingTermsOfService = attrs.settings?.branding?.termsOfService ? new I18nString(attrs.settings?.branding?.termsOfService) : null;

    this.settingsSelfSchedulerWelcomeMessage = new I18nString(attrs.settings?.selfScheduler?.welcomeMessage || attrs.settingsSelfSchedulerWelcomeMessage || {});
    this.settingsSelfSchedulerNoAvailabilityMessage = new I18nString(attrs.settings?.selfScheduler?.noAvailabilityMessage || attrs.settingsSelfSchedulerNoAvailabilityMessage || {});
    this.settingsSelfSchedulerOutsideServiceAreaMessage = new I18nString(attrs.settings?.selfScheduler?.outsideServiceAreaMessage || attrs.settingsSelfSchedulerOutsideServiceAreaMessage || {});
    this.settingsSelfSchedulerReservationCompleteMessage = new I18nString(attrs.settings?.selfScheduler?.reservationCompleteMessage || attrs.settingsSelfSchedulerReservationCompleteMessage || {});
    this.settingsSelfSchedulerReservationCompleteBehavior = attrs.settings?.selfScheduler?.reservationCompleteBehavior || attrs.settingsSelfSchedulerReservationCompleteBehavior || RESERVATION_COMPLETE_BEHAVIOR.SHOW_LINK;
    this.settingsSelfSchedulerTechnicianSelectionBehavior = attrs.settings?.selfScheduler?.technicianSelectionBehavior || attrs.settingsSelfSchedulerTechnicianSelectionBehavior || TECHNICIAN_SELECTION_BEHAVIOR.PREFERRED_TECHNICIAN;
    if (!isNullOrUndefined(attrs.settings?.selfScheduler?.showCosts)) {
      this.settingsSelfSchedulerShowCosts = attrs.settings?.selfScheduler?.showCosts;
    } else if (!isNullOrUndefined(attrs.settingsSelfSchedulerShowCosts)) {
      this.settingsSelfSchedulerShowCosts = attrs.settingsSelfSchedulerShowCosts;
    } else {
      this.settingsSelfSchedulerShowCosts = false;
    }
    if (!isNullOrUndefined(attrs.settings?.selfScheduler?.selfSchedulerEnabled)) {
      this.settingsSelfSchedulerEnabled = attrs.settings?.selfScheduler?.selfSchedulerEnabled;
    } else if (!isNullOrUndefined(attrs.settingsSelfSchedulerEnabled)) {
      this.settingsSelfSchedulerEnabled = attrs.settingsSelfSchedulerEnabled;
    } else {
      this.settingsSelfSchedulerEnabled = false;
    }

    this.settingsLegacySelfSchedulerOutsideServiceAreaMessage = attrs.settings?.legacySelfScheduler?.outsideServiceAreaMessage || attrs.settingsLegacySelfSchedulerOutsideServiceAreaMessage || '';
    this.settingsLegacySelfSchedulerSpecialInstructions = attrs.settings?.legacySelfScheduler?.selfScheduleSpecialInstructions || attrs.settingsLegacySelfSchedulerSpecialInstructions || '';
    this.settingsLegacySelfSchedulerCompletionMessage = attrs.settings?.legacySelfScheduler?.selfScheduleCompletionMessage || attrs.settingsLegacySelfSchedulerCompletionMessage || '';
    this.settingsLegacySelfSchedulerCompletionRedirect = attrs.settings?.legacySelfScheduler?.selfScheduleCompletionRedirect || attrs.settingsLegacySelfSchedulerCompletionRedirect || '';

    if (attrs?.createdAt) {
      this.createdAt = moment(attrs.createdAt);
    }
  }

  // The public API is slightly different than the private API.  Originally we did not share
  // models between public and private/mobile.  However, we started sharing components later
  // and thus needed to share models.  This static function translates the public API response
  // for company to something that resembles the private API response to be passed into the
  // constructor of this model.
  static fromPublicApiResponse(attrs: any): Company {
    return new Company({
      id: attrs.id,
      name: attrs.name,
      phoneNumber: attrs.phoneNumber,
      email: attrs.email,
      website: attrs.website,
      logoExists: attrs.logo?.exists,
      logoUrl: attrs.logo?.url,
      address1: attrs.address1,
      address2: attrs.address2,
      city: attrs.city,
      state: attrs.state,
      zip: attrs.zip,
      countryCode: attrs.settings?.phoneCountryCode,
      isGdprRequired: attrs.isGdprRequired,
      headerLayout: attrs.settings?.publicStyle?.headerLayout,
      settings: {
        invoices: {
          tipsEnabled: attrs.settings?.invoices?.tipsEnabled,
          tipsPublicGuiAutoselect: attrs.settings?.invoices?.tipsPublicGuiAutoselect,
        },
        stripePayments: {
          accountId: attrs.settings?.stripePayments?.accountId,
          stripePublishableKey: attrs.settings?.stripePayments?.stripePublishableKey,
        }
      }
    });
  }

  get addressLines() {
    return addressPartsToLines(this);
  }

  get addressLine() {
    return addressPartsToLine(this) || null;
  }

  get displayAddress() {
    return this.addressLine || 'Unknown Address';
  }

  get stripeIntegrationEnabled(): boolean {
    return !!this.settingsStripePaymentsAccountId;
  }

  quickbooksBeforeStartDate(invoice: Invoice): boolean {
    return this.settingsQuickbooksSyncStartDate && invoice.dueOn.format('YYYY-MM-DD') < this.settingsQuickbooksSyncStartDate.format('YYYY-MM-DD');
  }
}

/********************************************************************************************/

export class Contact {
  id: string;
  clientId: string;
  isDefault: boolean;
  isBillingDefault: boolean;
  role: string;
  title: string;
  firstName: string;
  middleName: string;
  lastName: string;
  suffix: string;
  defaultAddress: ContactAddress;
  defaultBillingAddress: ContactAddress;
  defaultPhone: ContactPhone;
  defaultConfirmedMobilePhone: ContactPhone;
  defaultEmail: ContactEmail;
  allAddresses: ContactAddress[];
  allPhones: ContactPhone[];
  allEmails: ContactEmail[];
  wantsEmail: boolean;
  wantsText: boolean;
  wantsPhone: boolean;

  constructor(attrs: any = {}) {
    this.id = attrs.id;
    this.clientId = attrs.clientId || (attrs.client && attrs.client.id) || null;
    this.isDefault = attrs.isDefault;
    this.isBillingDefault = attrs.isBillingDefault;
    this.role = attrs.role;
    this.title = attrs.title;
    this.firstName = attrs.firstName;
    this.middleName = attrs.middleName;
    this.lastName = attrs.lastName;
    this.suffix = attrs.suffix;

    this.wantsEmail = attrs.wantsEmail;
    this.wantsText = attrs.wantsText;
    this.wantsPhone = attrs.wantsPhone;

    this.defaultAddress = null;
    if (attrs.defaultAddress) {
      this.defaultAddress = new ContactAddress(attrs.defaultAddress);
    }
    this.defaultBillingAddress = null;
    if (attrs.defaultBillingAddress) {
      this.defaultBillingAddress = new ContactAddress(attrs.defaultBillingAddress);
    }
    this.defaultPhone = null;
    if (attrs.defaultPhone) {
      this.defaultPhone = new ContactPhone(attrs.defaultPhone);
    }
    this.defaultConfirmedMobilePhone = null;
    if (attrs.defaultConfirmedMobilePhone) {
      this.defaultConfirmedMobilePhone = new ContactPhone(attrs.defaultConfirmedMobilePhone);
    }
    this.defaultEmail = null;
    if (attrs.defaultEmail) {
      this.defaultEmail = new ContactEmail(attrs.defaultEmail);
    }
    this.allAddresses = [];
    if (attrs.allAddresses) {
      if (attrs.allAddresses.nodes) {
        this.allAddresses = attrs.allAddresses.nodes.map((node: any) => new ContactAddress(node));
      } else {
        this.allAddresses = attrs.allAddresses.map((a: ContactAddress) => new ContactAddress(a));
      }
    }
    this.allEmails = [];
    if (attrs.allEmails) {
      if (attrs.allEmails.nodes) {
        this.allEmails = attrs.allEmails.nodes.map((node: any) => new ContactEmail(node));
      } else {
        this.allEmails = attrs.allEmails.map((e: ContactEmail) => new ContactEmail(e));
      }
    }
    this.allPhones = [];
    if (attrs.allPhones) {
      if (attrs.allPhones.nodes) {
        this.allPhones = attrs.allPhones.nodes.map((node: any) => new ContactPhone(node));
      } else {
        this.allPhones = attrs.allPhones.map((a: ContactPhone) => new ContactPhone(a));
      }
    }
  }

  setAddress(address: ContactAddress) {
    let replaced: boolean;
    this.allAddresses.forEach((a, i) => {
      if (a.type === address.type) {
        this.allAddresses.splice(i, 1, address);
        replaced = true;
      }
    });
    if (!replaced) {
      this.allAddresses.push(address);
    }
  }

  get displayFormalName() {
    return compact([
      this.title,
      this.firstName,
      this.middleName,
      this.lastName,
      this.suffix
    ]).join(' ');
  }

  get displayName() {
    return compact([this.firstName, this.lastName]).join(' ');
  }

  get defaultPosition() {
    if (this.defaultAddress) return this.defaultAddress.position;
    return null;
  }

  get defaultAddressLine() {
    if (this.defaultAddress) return this.defaultAddress.addressLine;
    return null;
  }

  get defaultAddressLines() {
    if (this.defaultAddress) return this.defaultAddress.addressLines;
    return ['Unknown Address'];
  }

  get displayAddress() {
    if (this.defaultAddress) return this.defaultAddress.displayAddress;
    return 'Unknown Address';
  }

  get displayCity() {
    if (this.defaultAddress) return this.defaultAddress.displayCity;
    return 'Unknown City';
  }

  get displayZip() {
    if (this.defaultAddress) return this.defaultAddress.displayZip;
    return 'Unknown Zip';
  }

  get displayExtendedCity() {
    if (this.defaultAddress) return this.defaultAddress.displayExtendedCity;
    return 'Unknown City';
  }

  get displayPhone() {
    if (this.defaultPhone) return this.defaultPhone.displayPhone;
    return 'Unknown Phone Number';
  }

  get displayEmail() {
    if (this.defaultEmail) return this.defaultEmail.displayEmail;
    return 'Unknown Email';
  }

  get billingAddress() {
    for (let address of this.allAddresses) {
      if (address.type === 'BILLING') return address;
    }
    for (let address of this.allAddresses) {
      if (address.type === 'MAILING') return address;
    }
    return this.allAddresses[0];
  }
}

/********************************************************************************************/

export class ContactAddress implements IAddressParts {
  id: string;
  type: ADDRESS_TYPE;
  isDefault: boolean;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  position: ILatLng;
  isBadAddress: boolean;

  constructor(attrs: any = {}) {
    this.id = attrs.id;
    this.type = attrs.type || ADDRESS_TYPE.STREET;
    this.isDefault = attrs.isDefault;
    this.address1 = attrs.address1;
    this.address2 = attrs.address2;
    this.city = attrs.city;
    this.state = attrs.state;
    this.zip = attrs.zip;
    this.isBadAddress = attrs.isBadAddress;
    if (attrs.lat || attrs.lng) {
      this.position = {lat: attrs.lat, lng: attrs.lng};
      if (attrs.locationType) {
        this.position.locationType = attrs.locationType;
      }
    } else if (attrs.position) {
      this.position = {lat: attrs.position.lat, lng: attrs.position.lng};
      if (attrs.position.locationType) {
        this.position.locationType = attrs.position.locationType;
      }
    }
    if (this.position && attrs.geocodeType) {
      this.position.locationType = attrs.geocodeType;
    }
  }

  get addressLines() {
    return addressPartsToLines(this);
  }

  get addressLine() {
    return addressPartsToLine(this) || null;
  }

  get cityStatePostCodeLine() {
    const csp: IAddressParts = {
      address1: null,
      address2: null,
      city: this.city,
      state: this.state,
      zip: this.zip
    };
    return addressPartsToLine(csp) || null;
  }

  get displayAddress() {
    return this.addressLine || 'Unknown Address';
  }

  get displayCity() {
    let parts = [this.city || 'Unknown City', this.state];
    return compact(parts).join(', ');
  }

  get displayZip() {
    return this.zip || 'Unknown Postal Code';
  }

  get displayExtendedCity() {
    let parts = compact([this.city, this.state, this.zip]);
    if (parts.length > 0) {
      return parts.join(', ');
    } else {
      return 'Unknown City';
    }
  }
}

/********************************************************************************************/

export class ContactEmail {
  id: string;
  isDefault: boolean;
  email: string;
  emailMd5: string;

  constructor(attrs: any = {}) {
    this.id = attrs.id;
    this.isDefault = attrs.isDefault;
    this.email = attrs.email;
    this.emailMd5 = attrs.emailMd5;
  }

  get displayEmail() {
    if (this.email) {
      return this.email;
    } else {
      return 'Unknown Email';
    }
  }

  get truncatedDisplayEmail() {
    const maxLen = 26;
    let str = this.displayEmail;
    if (str.length <= maxLen) return str;
    let parts = str.split('@');
    if (!parts[1]) return parts[0];

    if (parts[1].length < maxLen / 2) {
      return `${parts[0].substring(0, maxLen - parts[1].length - 4)}...@${parts[1]}`;
    } else {
      return `${parts[0].substring(0, (maxLen / 2) - 4)}...@${parts[1].substring(0, (maxLen / 2) - 3)}...`;
    }
  }
}

/********************************************************************************************/

export class ContactLocation {
  id: string;
  locationType: LOCATION_TYPE;
  usageType: ADDRESS_TYPE;
  street1: string;
  street2: string;
  municipality: string;
  region: string;
  postalCode: string;
  countryCode: string;
  latitude: string;
  longitude: string;
  geocodeType: MAPPING_LOCATION_TYPE;
  isBadAddress: boolean;

  constructor(attrs: any = {}) {
    this.id = attrs.id;
    this.locationType = attrs.locationType;
    this.usageType = attrs.usageType;
    this.street1 = attrs.street1;
    this.street2 = attrs.street2;
    this.municipality = attrs.municipality;
    this.region = attrs.region;
    this.postalCode = attrs.postalCode;
    this.countryCode = attrs.countryCode;
    this.isBadAddress = attrs.isBadAddress;
    this.latitude = attrs.latitude;
    this.longitude = attrs.longitude;
    this.geocodeType = attrs.geocodeType;
  }

  get iLatLng(): ILatLng {
    if (this.latitude && this.longitude) {
      const tmp: ILatLng = {
        lat: parseFloat(this.latitude),
        lng: parseFloat(this.longitude)
      };
      if (this.geocodeType) {
        tmp.locationType = this.geocodeType;
      }
      return tmp;
    } else {
      return null;
    }
  }

  get addressLines() {
    return addressPartsToLines({
      address1: this.street1,
      address2: this.street2,
      city: this.municipality,
      state: this.region,
      zip: this.postalCode,
      isBadAddress: this.isBadAddress,
    });
  }

  get addressLine() {
    return addressPartsToLine({
      address1: this.street1,
      address2: this.street2,
      city: this.municipality,
      state: this.region,
      zip: this.postalCode,
      isBadAddress: this.isBadAddress,
    }) || null;
  }

  get cityStatePostCodeLine() {
    const csp: IAddressParts = {
      address1: null,
      address2: null,
      city: this.municipality,
      state: this.region,
      zip: this.postalCode
    };
    return addressPartsToLine(csp) || null;
  }

  get displayAddress() {
    // TODO: this should be translated
    return this.addressLine || 'Unknown Address';
  }

  get displayCity() {
    // TODO: this should be translated
    let parts = [this.municipality || 'Unknown City', this.region];
    return compact(parts).join(', ');
  }

  get displayZip() {
    // TODO: this should be translated
    return this.postalCode || 'Unknown Postal Code';
  }

  get displayExtendedCity() {
    let parts = compact([this.municipality, this.region, this.postalCode]);
    if (parts.length > 0) {
      return parts.join(', ');
    } else {
      // TODO: this should be translated
      return 'Unknown City';
    }
  }
}

/********************************************************************************************/

export class ContactPhone {
  id: string;
  isDefault: boolean;
  phoneNumber: string;
  e164: string;

  extension: string;
  type: PHONE_TYPE;
  confirmedClass: string;
  confirmedCarrier: string;
  confirmedAt: Moment;

  constructor(attrs: any = {}) {
    this.id = attrs.id;
    this.isDefault = attrs.isDefault;
    this.phoneNumber = attrs.phoneNumber;
    this.e164 = attrs.e164;
    this.extension = attrs.extension;
    this.type = attrs.type || PHONE_TYPE.MOBILE;
    this.confirmedClass = attrs.confirmedClass;
    this.confirmedCarrier = attrs.confirmedCarrier;
    if (attrs.confirmedAt) {
      this.confirmedAt = moment(attrs.confirmedAt);
    }
  }

  get displayPhone() {
    let str = this.phoneNumber;
    if (this.extension) {
      str += `x${this.extension}`;
    }
    if (str) {
      return str;
    } else {
      return 'Unknown Phone Number';
    }
  }

  get isTextable() {
    return this.confirmedClass === PHONE_CLASS.MOBILE || this.type === PHONE_TYPE.MOBILE;
  }
}

/********************************************************************************************/

export class Email {
  id: string;
  status: EMAIL_STATUS;
  sentAt: Moment;
  to: string;
  recipientName: string;
  from: string;
  subject: string;
  htmlBody: string;
  textBody: string;
  updatedAt: Moment;
  createdAt: Moment;

  client: Client;
  invoice: Invoice;
  eventReservation: EventReservation;

  constructor(attrs: any = {}) {
    this.id = attrs.id;
    this.status = attrs.status;
    this.sentAt = attrs.sentAt ? moment(attrs.sentAt) : null;

    this.to = attrs.to;
    this.recipientName = attrs.recipientName;
    this.from = attrs.from;
    this.subject = attrs.subject;
    this.htmlBody = attrs.htmlBody;
    this.textBody = attrs.textBody;

    this.updatedAt = attrs.updatedAt ? moment(attrs.updatedAt) : null;
    this.createdAt = attrs.createdAt ? moment(attrs.createdAt) : null;

    this.client = attrs.client ? new Client(attrs.client) : null;
    this.invoice = attrs.invoice ? new Invoice(attrs.invoice) : null;
    this.eventReservation = attrs.eventReservation ? new EventReservation(attrs.eventReservation) : null;
  }
}

/********************************************************************************************/

export class Event implements ICalendarEvent {
  id: string;
  status: EVENT_STATUS;
  start: Moment;
  end: Moment;
  timezone: string;
  duration: number;
  buffer: number;
  addressLine: string;
  position: ILatLng;
  isAllDay: boolean;
  isTypeChangeable: boolean;
  title: string;
  notes: string;
  type: EVENT_TYPE;
  client: Client;
  user: User;
  confirmedAt: Moment;
  confirmedByClient: boolean;
  confirmationWarning: EVENT_CONFIRMATION_WARNING;
  pianos: {[pianoId: string]: EventPiano};
  isConfirmationEmailWanted: boolean;
  lifecycleState: string;

  isRepeating: boolean;
  isOriginallyRepeating: boolean;
  recurrenceId: string;
  recurrenceType: EVENT_RECURRENCE_TYPE;
  recurrenceMonthlyType: EVENT_RECURRENCE_MONTHLY_TYPE;
  recurrenceWeekdays: {[key: number]: boolean};
  recurrenceWeeks: {[key: number]: boolean};
  recurrenceDates: {[key: number]: boolean};
  recurrenceInterval: number;
  recurrenceEndingType: EVENT_RECURRENCE_ENDING_TYPE;
  recurrenceEndingOccurrences: number;
  recurrenceEndingDate: Moment;
  recurrenceChangeType: EVENT_RECURRENCE_CHANGE_TYPE;

  createdViaSchedulerVersion: number;

  // This is a flag used when showing the current, unsaved, event on the itinerary
  // or on the day view.  It is transient, not persisted.
  isCurrent: boolean;

  remoteCalendar: RemoteCalendar;
  remoteType: EVENT_TYPE;

  travelMode: SCHEDULER_TRAVEL_MODE;

  schedulerAvailability: SchedulerV2Availability;

  // Attrs can be either a hash coming from the API, or another instance of Event.
  // If it's another instance of Event, we are basically just cloning it.  Otherwise
  // we are mapping the API response to this event's attrs.
  constructor(attrs: any = {}) {
    if (!attrs) attrs = {}; // in case null is passed in from a not-found response
    this.id = attrs.id;
    this.status = attrs.status || EVENT_STATUS.ACTIVE;
    this.isAllDay = attrs.isAllDay || false;
    this.duration = attrs.duration || 60;
    this.timezone = attrs.timezone;
    this.buffer = attrs.buffer || 0;
    this.isTypeChangeable = attrs.isTypeChangeable || false;
    this.type = attrs.type || null;
    this.title = attrs.title || '';
    this.notes = attrs.notes || '';
    this.addressLine = attrs.addressLine || '';
    this.position = null;
    if (attrs.lat || attrs.lng) {
      this.position = {lat: attrs.lat, lng: attrs.lng};
      if (attrs.geocodeType) {
        this.position.locationType = attrs.geocodeType;
      }
    } else if (attrs.position) {
      this.position = {lat: attrs.position.lat, lng: attrs.position.lng};
      if (attrs.position.locationType) {
        this.position.locationType = attrs.position.locationType;
      }
    }
    this.lifecycleState = attrs.lifecycleState || null;

    if (attrs.user) {
      this.user = new User(attrs.user);
      if (!this.timezone) this.timezone = this.user.timezone;
    }
    if (attrs.client) this.client = new Client(attrs.client);
    if (attrs.schedulerAvailability) this.schedulerAvailability = new SchedulerV2Availability(attrs.schedulerAvailability);

    if (attrs.start) {
      this.start = moment.tz(attrs.start, this.timezone);
      this.calculateEnd();
    }

    this.confirmedByClient = attrs.confirmedByClient;
    this.confirmationWarning = attrs.confirmationWarning;
    if (attrs.confirmedAt) {
      this.confirmedAt = moment.tz(attrs.confirmedAt, this.timezone);
    } else {
      this.confirmedAt = null;
    }


    this.pianos = {};
    if (attrs.allEventPianos && attrs.allEventPianos.nodes) {
      attrs.allEventPianos.nodes.forEach((node: any) => {
        this.pianos[node.piano.id] = new EventPiano({
          isSelected: true,
          isTuning: node.isTuning,
          pianoId: node.piano.id,
          piano: new Piano(node.piano)
        });
      });
    } else if (attrs.pianos) {
      each(attrs.pianos, (ep: any) => {
        this.pianos[ep.pianoId] = new EventPiano({
          isSelected: typeof ep.isSelected === 'undefined' ? true : ep.isSelected,
          isTuning: ep.isTuning,
          pianoId: ep.pianoId,
          piano: new Piano(ep.piano)
        });
      });
    }

    this.isRepeating = (typeof attrs.isRepeating !== 'undefined' ? !!attrs.isRepeating : !!attrs.recurrenceId);
    this.isOriginallyRepeating = !!attrs.recurrenceId;
    this.isConfirmationEmailWanted = !!attrs.isConfirmationEmailWanted;

    this.recurrenceId = attrs.recurrenceId;

    if (typeof attrs.recurrenceInterval === 'undefined') {
      this.recurrenceInterval = 1;
    } else {
      this.recurrenceInterval = attrs.recurrenceInterval;
    }
    this.recurrenceType = attrs.recurrenceType || EVENT_RECURRENCE_TYPE.WEEKLY;

    this.recurrenceWeekdays = {};
    // eslint-disable-next-line prefer-spread
    Array.apply(null, {length: 7}).map(Number.call, Number).map((i: number) => this.recurrenceWeekdays[i] = false);
    if (Array.isArray(attrs.recurrenceWeekdays) || typeof attrs.recurrenceWeekdays === 'undefined') {
      (attrs.recurrenceWeekdays || []).forEach((i: number) => { this.recurrenceWeekdays[i] = true; });
    } else if (typeof attrs.recurrenceWeekdays === 'object') {
      each(attrs.recurrenceWeekdays, (val: boolean, key: number) => { this.recurrenceWeekdays[key] = val; });
    }

    this.recurrenceWeeks = {};
    [1, 2, 3, 4, -1].map((i: number) => this.recurrenceWeeks[i] = false);
    if (Array.isArray(attrs.recurrenceWeeks) || typeof attrs.recurrenceWeeks === 'undefined') {
      (attrs.recurrenceWeeks || []).forEach((i: number) => { this.recurrenceWeeks[i] = true; });
    } else if (typeof attrs.recurrenceWeeks === 'object') {
      each(attrs.recurrenceWeeks, (val: boolean, key: number) => { this.recurrenceWeeks[key] = val; });
    }

    this.recurrenceDates = {};
    // eslint-disable-next-line prefer-spread
    Array.apply(null, {length: 31}).map(Number.call, Number).map((i: number) => this.recurrenceDates[i] = false);
    if (Array.isArray(attrs.recurrenceDates) || typeof attrs.recurrenceDates === 'undefined') {
      (attrs.recurrenceDates || []).forEach((i: number) => { this.recurrenceDates[i - 1] = true; });
    } else if (typeof attrs.recurrenceDates === 'object') {
      each(attrs.recurrenceDates, (val: boolean, key: number) => { this.recurrenceDates[key] = val; });
    }

    this.recurrenceEndingType = attrs.recurrenceEndingType || EVENT_RECURRENCE_ENDING_TYPE.OCCURRENCES;
    this.recurrenceEndingOccurrences = attrs.recurrenceEndingOccurrences || 10;
    this.recurrenceEndingDate = attrs.recurrenceEndingDate || moment(moment().format('YYYY-MM-DD'));
    this.recurrenceChangeType = attrs.recurrenceChangeType || EVENT_RECURRENCE_CHANGE_TYPE.SELF;

    if (attrs.recurrenceMonthlyType) {
      this.recurrenceMonthlyType = attrs.recurrenceMonthlyType;
    } else {
      if (this.recurrenceType === EVENT_RECURRENCE_TYPE.MONTHLY && (attrs.recurrenceWeekdays || []).length > 0) {
        this.recurrenceMonthlyType = EVENT_RECURRENCE_MONTHLY_TYPE.WEEKDAYS;
      } else {
        this.recurrenceMonthlyType = EVENT_RECURRENCE_MONTHLY_TYPE.DATES;
      }
    }

    this.remoteCalendar = attrs.remoteCalendar ? new RemoteCalendar(attrs.remoteCalendar) : null;
    this.remoteType = attrs.remoteType;

    this.createdViaSchedulerVersion = attrs.createdViaSchedulerVersion;

    this.isCurrent = !!attrs.isCurrent;

    this.travelMode = attrs.travelMode || SCHEDULER_TRAVEL_MODE.DRIVING;
  }

  changeStartDate(date: Moment) {
    this.start = moment.tz(this.start, this.timezone).year(date.year()).month(date.month()).date(date.date());
    this.calculateEnd();
  }

  changeEndDate(date: Moment) {
    this.isAllDay = true;
    this.start.startOf('day');
    let days = date.diff(this.start, 'day') + 1;
    this.duration = days * 60 * 24;
    this.calculateEnd();
  }

  get isComplete(): boolean {
    return [EVENT_STATUS.CANCELED, EVENT_STATUS.COMPLETE, EVENT_STATUS.NO_SHOW].indexOf(this.status) >= 0;
  }

  // An event is completable if the client is on a LifeCycle.  You also need to check event.isComplete.
  get isCompletable(): boolean {
    if (this.lifecycleState) {
      if (this.lifecycleState === 'today') return true;
      if (this.lifecycleState === 'past') return true;
      if (this.lifecycleState === 'no-lifecycle' && this.start.isBefore(moment.tz(this.timezone).endOf('day'))) return true;
    }
    return false;
  }

  get allEventPianos(): EventPiano[] {
    return values(this.pianos);
  }

  private calculateEnd() {
    if (this.isAllDay) {
      // When dealing with all-day events, we really should just track start and end dates.
      // But for historic reasons, we are dealing with start + duration.  This causes a problem
      // for all-day events that span a time zone change because it might be +/- 1 hour which
      // causes the all day event to look like it spans an extra day.  The workaround here is
      // to round the end date to the nearest midnight.  This will work as long as there is
      // not a +/- 12 hour shift in time, which probably won't ever happen.
      let tmpEndDate = moment.tz(this.start, this.timezone).add(this.duration, 'minutes');
      if (tmpEndDate.get('hour') > 12) {
        this.end = tmpEndDate.add(1, 'day').endOf('day');
      } else {
        this.end = tmpEndDate.startOf('day').subtract(1, 'second');
      }
    } else {
      this.end = moment.tz(this.start, this.timezone).add(this.duration + this.buffer, 'minutes');
    }
  }

  // Ideally we would pass in the schedulerStore here, but since that would cause a require
  // cycle, I'm basically making an interface here that includes the properties I need.
  static fromSlot(slot: Slot | SlotV2, store?: {fetchedModel?: Client, displayName?: string, addressLine?: string, position?: any}): Event {
    let event = new Event({
      type: EVENT_TYPE.APPOINTMENT,
      start: slot.startsAt,
      duration: slot.duration,
      buffer: slot.buffer,
      user: slot.user,
      isCurrent: true,
    });

    if (slot instanceof SlotV2) {
      event.travelMode = slot.travelMode;
      if (slot.availabilityId) {
        event.schedulerAvailability = new SchedulerV2Availability({id: slot.availabilityId});
      }
    }

    if (store) {
      if (store.fetchedModel) {
        event.client = store.fetchedModel;
        event.addressLine = event.client.defaultAddressLine;
        event.position = event.client.defaultPosition;
      } else {
        event.title = store.displayName;
        event.addressLine = store.addressLine;
        event.position = store.position;
      }
    }
    return event;
  }
}
/********************************************************************************************/

export class EventPiano {
  isTuning: boolean;
  isSelected: boolean;
  pianoId?: string;
  piano?: Piano;

  constructor(attrs: any) {
    this.isTuning = attrs.isTuning;
    this.isSelected = attrs.isSelected;
    this.pianoId = attrs.pianoId;
    this.piano = attrs.piano;
  }
}

/********************************************************************************************/

export class EventCancelNotice {
  id: string;
  status: EVENT_CANCEL_NOTICE_STATUS;
  event: Event;
  createdAt: Moment;
  updatedAt: Moment;

  constructor(attrs: any = {}) {
    this.id = attrs.id;
    this.status = attrs.status;
    if (attrs.createdAt) this.createdAt = moment(attrs.createdAt);
    if (attrs.updatedAt) this.updatedAt = moment(attrs.updatedAt);
    if (attrs.event) {
      this.event = new Event(attrs.event);
    }
  }
}

/********************************************************************************************/

export class EventReservation implements ICalendarEvent {
  id: string;
  start: Moment;
  end: Moment;
  status: EVENT_RESERVATION_STATUS;
  timezone: string;
  duration: number;
  buffer: number;
  user: User;
  client: Client;
  clientData: EventReservationClientData;
  pianoServices: EventReservationPiano[];
  createdAt: Moment;
  notes: string;
  approvedAt: Moment; // Not tracked in the database.  This is used only by the mobile app at the moment.
  estimateTier: EstimateTier;
  event: Event;
  statusChangedBy: User;
  statusChangedAt: Moment;
  emailSubscription: EmailSubscription;
  changes: string[];
  schedulerV2SearchId: string;
  travelMode: SCHEDULER_TRAVEL_MODE;
  schedulerAvailability: SchedulerV2Availability;

  constructor(attrs: any = {}) {
    this.id = attrs.id;
    this.duration = attrs.duration;
    this.buffer = attrs.buffer || 0;
    this.timezone = attrs.timezone;
    this.status = attrs.status;
    this.changes = attrs.changes || [];
    this.schedulerV2SearchId = attrs.schedulerV2SearchId || null;

    if (attrs.statusChangedBy) {
      this.statusChangedBy = new User(attrs.statusChangedBy);
    }
    if (attrs.statusChangedAt) {
      this.statusChangedAt = moment.tz(attrs.statusChangedAt, this.timezone);
    }

    if (attrs.user) {
      this.user = new User(attrs.user);
      if (!this.timezone) this.timezone = this.user.timezone;
    } else {
      this.user = null;
    }
    if (attrs.estimateTier) {
      this.estimateTier = new EstimateTier(attrs.estimateTier);
    } else {
      this.estimateTier = null;
    }
    if (attrs.schedulerAvailability) {
      this.schedulerAvailability = new SchedulerV2Availability(attrs.schedulerAvailability);
    }

    this.client = (attrs.client) ? new Client(attrs.client) : null;

    this.start = moment.tz(attrs.start || attrs.startsAt, this.timezone);
    this.createdAt = moment.tz(attrs.createdAt, this.timezone);
    this.calculateEnd();

    this.clientData = new EventReservationClientData(attrs.clientData);
    this.emailSubscription = (attrs?.clientData?.emailSubscription) ? new EmailSubscription(attrs.clientData.emailSubscription) : null;
    // If we create a new EventReservation from an existing EventReservation instance, emailSubscription will be
    // directly on the reservation, not on the clientData underneath it.
    if (this.emailSubscription === null && attrs?.emailSubscription) {
      this.emailSubscription = new EmailSubscription(attrs.emailSubscription);
    }
    this.pianoServices = [];
    if (attrs.pianoServices) {
      this.pianoServices = attrs.pianoServices.map((p: any) => new EventReservationPiano(p));
    }
    this.notes = attrs.notes;
    this.approvedAt = null;
    this.travelMode = attrs.travelMode;

    if (attrs.event) {
      this.event = new Event(attrs.event);
    }
  }

  get displayTitle(): string {
    if (!this.clientData) return 'Unknown Client';
    return this.clientData.displayName;
  }

  get addressLine(): string {
    let parts = [];
    if (this.clientData) {
      if (this.clientData.address1) parts.push(this.clientData.address1);
      if (this.clientData.address2) parts.push(this.clientData.address2);
      if (this.clientData.city) parts.push(this.clientData.city);
      if (this.clientData.state) parts.push(this.clientData.state);
      if (this.clientData.zip) parts.push(this.clientData.zip);
    }
    return parts.join(', ');
  }

  get position(): ILatLng {
    if (this.clientData) {
      let tmp: ILatLng = {lat: this.clientData.lat, lng: this.clientData.lng};
      if (this.clientData.geocodeType) {
        tmp.locationType = this.clientData.geocodeType;
      }
      return tmp;
    }
    return null;
  }

  generateNotes(): string {
    let note = 'Requested Services:\n';

    this.pianoServices.forEach( (reservationPiano: EventReservationPiano) => {
      note += reservationPiano.displayName + '\n';
      reservationPiano.services.forEach((service: EventReservationPianoService) => {
        note += '  * ';
        note += service.name + ' - ' + formatDuration(service.duration);
        if (service.amount) {
          note += ' (' + formatCurrency(service.amount) + ')';
        }
        note += '\n';
      });
    });
    if (this.notes) {
      note += '\nNotes and Special Requests:\n' + this.notes;
    }

    return note;
  }

  private calculateEnd() {
    this.end = moment.tz(this.start, this.timezone).add(this.duration + (this.buffer || 0), 'minutes');
  }
}

export class EventReservationClientData {
  client: Client;
  clientId: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumberE164: string;
  phoneNumber: string;
  phoneType: PHONE_TYPE;
  lat: number;
  lng: number;
  geocodeType: MAPPING_LOCATION_TYPE;
  smsOptIn: boolean;

  constructor(attrs: any = {}) {
    this.client = (attrs.client) ? new Client(attrs.client) : null;
    this.clientId = attrs.clientId;
    this.address1 = attrs.address1;
    this.address2 = attrs.address2;
    this.city = attrs.city;
    this.state = attrs.state;
    this.zip = attrs.zip;
    this.firstName = attrs.firstName;
    this.lastName = attrs.lastName;
    this.email = attrs.email;
    this.phoneNumberE164 = attrs.phoneNumberE164;
    this.phoneNumber = attrs.phoneNumber;
    this.phoneType = attrs.phoneType;
    this.lat = attrs.lat;
    this.lng = attrs.lng;
    this.geocodeType = attrs.geocodeType;
    this.smsOptIn = attrs.smsOptIn;
  }

  get displayName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  get displayCity() {
    if (this.city) {
      let parts = [this.city || 'Unknown City', this.state];
      return compact(parts).join(', ');
    }
    return formatMessage(MSG_unknownCityLabel);
  }

  get displayZip() {
    if (this.zip) return this.zip;
    return formatMessage(MSG_unknownPostalCodeLabel);
  }

  get addressLines() {
    return addressPartsToLines(this);
  }
}

export class EventReservationPiano {
  isTuning: boolean;
  type: PIANO_TYPE;
  make: string;
  model: string;
  location: string;
  services: EventReservationPianoService[];
  piano: Piano;
  pianoId: string;

  constructor(attrs: any = {}) {
    this.isTuning = attrs.isTuning;
    this.type = attrs.type;
    this.make = attrs.make;
    this.model = attrs.model;
    this.location = attrs.location;
    this.services = [];
    if (attrs.services) {
      this.services = attrs.services.map((s: any) => new EventReservationPianoService(s));
    }
    this.piano = null;
    if (attrs.piano) {
      this.piano = new Piano(attrs.piano);
    }
    this.pianoId = attrs.pianoId;
  }

  get displayName(): string {
    return getPianoDisplayName(new Piano(this));
  }
}

export class EventReservationPianoService {
  amount: number;
  duration: number;
  name: string;

  constructor(attrs: any = {}) {
    this.amount = attrs.amount;
    this.duration = attrs.duration;
    this.name = attrs.name;
  }
}

/********************************************************************************************/

export class GazelleReferral {
  id: string;
  name: string;
  email: string;
  status: GAZELLE_REFERRAL_STATUS;
  referredOn: Moment;
  user: User;
  hasBeenPaidOut: boolean;

  constructor(attrs: any = {}) {
    this.id = attrs.id;
    this.name = attrs.name;
    this.email = attrs.email;
    this.status = attrs.status;
    this.referredOn = moment(attrs.referredOn);
    this.user = new User(attrs.user);
    this.hasBeenPaidOut = !!attrs.hasBeenPaidOut;
  }
}

/********************************************************************************************/

export class I18nString {
  values: {[key: string]: string};

  constructor(attrs: {[key: string]: string} | I18nString) {
    this.values = {};
    if (attrs instanceof I18nString) {
      this.values = attrs.toObject();
    } else {
      this.values = attrs || {};
    }
  }

  getForSimilarLocale(desiredLocale: string): string | null {
    if (!desiredLocale) return null;
    const desiredLanguage = desiredLocale.split(/_/)[0];
    const localeMatchesForLanguage = Object.keys(this.values).filter(k => {
      let lang = k.split(/_/)[0];
      if (lang) return lang === desiredLanguage && this.values[k];
      return false;
    });
    if (localeMatchesForLanguage.length > 0) return this.get(localeMatchesForLanguage[0]);
    return null;
  }

  get defaultValue() {
    let locale = getLocaleStoreInstance().localization.locale;
    // If there is a string match for the current locale, return it.
    let str = this.get(locale);
    if (str) return str;

    // If there is a non empty string match for the same language as the current locale, return it.
    str = this.getForSimilarLocale(locale);
    if (str) return str;

    return this.get('en_US');
  }

  get(locale: string, forceExactLocaleMatch: boolean = false) {
    return this.values[locale] || (forceExactLocaleMatch ? null : this.getForSimilarLocale(locale)) || '';
  }

  // same as get() except it returns the defaultValue if there is no value set
  // for the particular locale.
  getOrDefault(locale: string): string {
    return this.get(locale) || this.defaultValue;
  }

  toObject() {
    let obj: {[key: string]: string} = {};
    Object.keys(this.values).forEach(key => {
      if (this.values[key]) {
        obj[key] = this.values[key];
      }
    });
    return obj;
  }
}

/********************************************************************************************/

export class InvoiceGroup {
  id: string;
  piano: Piano;
  invoiceItems: InvoiceItem[];

  constructor(attrs?: any) {
    this.piano = attrs?.piano || null;
    this.invoiceItems = attrs?.invoiceItems || [];
    this.id = this.piano?.id || 'misc';
  }
}

/********************************************************************************************/

export class InvoicePayment {
  id: string;
  type: INVOICE_PAYMENT_TYPE;
  status: INVOICE_PAYMENT_STATUS;
  failureReason: string;
  amount: number;
  currency: Currency;
  notes: string;
  tipTotal: number;
  paidAt: Moment;
  createdAt: Moment;
  paymentDetails: string;
  isStripePayment: boolean;
  isSyncedToQuickbooksOnline: boolean;
  invoice: Invoice;
  paymentSource: INVOICE_PAYMENT_SOURCE;

  constructor(attrs: any) {
    this.id = attrs.id;
    this.type = attrs.type;
    this.status = attrs.status;
    this.failureReason = attrs.failureReason;
    this.amount = attrs.amount;
    this.currency = attrs.currency as Currency;
    this.notes = attrs.notes;
    this.tipTotal = attrs.tipTotal;
    this.paidAt = moment(attrs.paidAt);
    this.createdAt = moment(attrs.createdAt);
    this.paymentDetails = attrs.paymentDetails;
    this.isStripePayment = !!attrs.isStripePayment;
    this.isSyncedToQuickbooksOnline = !!attrs.isSyncedToQuickbooksOnline;
    this.paymentSource = attrs.paymentSource;
    this.invoice = attrs.invoice ? new Invoice(attrs.invoice) : null;
  }
}

/********************************************************************************************/

export class Invoice {
  id: string;
  client?: Client;
  contact?: Contact;
  // eslint-disable-next-line id-blacklist
  number: number;
  dueOn?: Moment;
  status: INVOICE_STATUS;
  notesHeader?: string;
  notes?: string;
  topNotesHeader?: string;
  topNotes?: string;
  createdAt: Moment;
  createdBy: User;
  netDays: number;
  altBillingFirstName?: string;
  altBillingLastName?: string;
  altBillingCompanyName?: string;
  altBillingAddress1?: string;
  altBillingAddress2?: string;
  altBillingCity?: string;
  altBillingState?: string;
  altBillingZip?: string;
  altBillingEmail?: string;
  altBillingPhone?: string;
  summarize?: boolean;
  subTotal: number;
  tipTotal: number;
  taxTotal: number;
  total: number;
  paidTotal: number;
  dueTotal: number;
  archived?: boolean;
  allInvoiceItems: InvoiceItem[];
  allClientLogs: ClientLog[];
  estimateTier: EstimateTier;
  allInvoicePayments: InvoicePayment[];
  acceptElectronicPayment: boolean;
  acceptedElectronicPaymentMethods: STRIPE_PAYMENT_METHODS[];
  hasPaymentPending: boolean;
  currency: Currency;
  clientUrl: string;
  needsQuickbooksSync: boolean;
  lastQuickbooksSyncAt: Moment;
  hasQuickbooksSyncNotices?: boolean;
  quickbooksSyncNotices?: QuickbooksSyncNotice[];
  tags: string[];

  private appliedPayment: number;


  constructor(attrs: any = {}) {
    this.id = attrs.id ? attrs.id : null;
    // eslint-disable-next-line id-blacklist
    this.number = attrs.number ? attrs.number : null;
    this.dueOn = attrs.dueOn ? moment(attrs.dueOn) : null;
    this.status = attrs.status ? attrs.status : INVOICE_STATUS.DRAFT;
    this.notesHeader = attrs.notesHeader ? attrs.notesHeader : null;
    this.notes = attrs.notes ? attrs.notes : null;
    this.topNotesHeader = attrs.topNotesHeader ? attrs.topNotesHeader : null;
    this.topNotes = attrs.topNotes ? attrs.topNotes : null;
    this.createdAt = attrs.createdAt ? moment(attrs.createdAt) : null;
    this.netDays = attrs.netDays;
    this.altBillingFirstName = attrs.altBillingFirstName ? attrs.altBillingFirstName : null;
    this.altBillingLastName = attrs.altBillingLastName ? attrs.altBillingLastName : null;
    this.altBillingCompanyName = attrs.altBillingCompanyName ? attrs.altBillingCompanyName : null;
    this.altBillingAddress1 = attrs.altBillingAddress1 ? attrs.altBillingAddress1 : null;
    this.altBillingAddress2 = attrs.altBillingAddress2 ? attrs.altBillingAddress2 : null;
    this.altBillingCity = attrs.altBillingCity ? attrs.altBillingCity : null;
    this.altBillingState = attrs.altBillingState ? attrs.altBillingState : null;
    this.altBillingZip = attrs.altBillingZip ? attrs.altBillingZip : null;
    this.altBillingEmail = attrs.altBillingEmail ? attrs.altBillingEmail : null;
    this.altBillingPhone = attrs.altBillingPhone ? attrs.altBillingPhone : null;
    this.summarize = attrs.summarize;
    this.subTotal = attrs.subTotal;
    this.tipTotal = attrs.tipTotal;
    this.taxTotal = attrs.taxTotal;
    this.total = attrs.total;
    this.paidTotal = attrs.paidTotal;
    this.dueTotal = attrs.dueTotal;
    this.archived = attrs.archived;
    this.hasPaymentPending = attrs.hasPaymentPending;
    this.clientUrl = attrs.clientUrl;
    this.tags = attrs.tags || [];

    if (attrs.client) {
      this.client = new Client(attrs.client);
    } else {
      this.client = null;
    }
    if (attrs.contact) {
      this.contact = new Contact(attrs.contact);
    } else {
      this.contact = null;
    }
    if (attrs.createdBy) {
      this.createdBy = new User(attrs.createdBy);
    } else {
      this.createdBy = null;
    }
    if (attrs.estimateTier) {
      this.estimateTier = new EstimateTier(attrs.estimateTier);
    } else {
      this.estimateTier = null;
    }
    this.currency = attrs.currency ? attrs.currency : null;

    this.allInvoiceItems = [];
    if (attrs.allInvoiceItems) {
      if (attrs.allInvoiceItems.edges) {
        this.allInvoiceItems = attrs.allInvoiceItems.edges.map((edge: any) => new InvoiceItem(edge.node));
      } else {
        this.allInvoiceItems = attrs.allInvoiceItems.map((i: InvoiceItem) => new InvoiceItem(i));
      }
    }

    this.allClientLogs = [];
    if (attrs.allClientLogs) {
      if (attrs.allClientLogs.nodes) {
        this.allClientLogs = attrs.allClientLogs.nodes.map((node: any) => new ClientLog(node));
      } else {
        this.allClientLogs = attrs.allClientLogs.map((cl: ClientLog) => new ClientLog(cl));
      }
    }

    this.appliedPayment = 0;

    this.allInvoicePayments = [];
    if (attrs.allInvoicePayments) {
      if (attrs.allInvoicePayments.nodes) {
        for (let payment of attrs.allInvoicePayments.nodes) {
          this.allInvoicePayments.push(new InvoicePayment(payment));
        }
      } else {
        for (let payment of attrs.allInvoicePayments) {
          this.allInvoicePayments.push(new InvoicePayment(payment));
        }
      }
    }

    this.acceptElectronicPayment = attrs.acceptElectronicPayment;
    this.acceptedElectronicPaymentMethods = attrs.acceptedElectronicPaymentMethods;

    if (typeof attrs.needsQuickbooksSync !== 'undefined') this.needsQuickbooksSync = attrs.needsQuickbooksSync;
    if (attrs.lastQuickbooksSyncAt) this.lastQuickbooksSyncAt = moment(attrs.lastQuickbooksSyncAt);
    this.hasQuickbooksSyncNotices = attrs.hasQuickbooksSyncNotices;
    if (attrs.quickbooksSyncNotices) {
      this.quickbooksSyncNotices = attrs.quickbooksSyncNotices.map((notice: any) => new QuickbooksSyncNotice(notice));
    }
  }

  // The public API is slightly different than the private API.  Originally we did not share
  // models between public and private/mobile.  However, we started sharing components later
  // and thus needed to share models.  This static function translates the public API response
  // for company to something that resembles the private API response to be passed into the
  // constructor of this model.
  static fromPublicApiResponse(attrs: any): Invoice {
    return new Invoice({
      id: attrs.id,
      client: attrs.client,
      contact: attrs.contact,
      // eslint-disable-next-line id-blacklist
      number: attrs.number,
      dueOn: attrs.dueOn,
      status: attrs.status,
      notesHeader: attrs.notesHeader,
      notes: attrs.notes,
      topNotesHeader: attrs.topNotesHeader,
      topNotes: attrs.topNotes,
      // createdAt: Moment;
      // createdBy: User;
      netDays: attrs.netDays,
      altBillingFirstName: attrs.altBilling?.firstName,
      altBillingLastName: attrs.altBilling?.lastName,
      altBillingCompanyName: attrs.altBilling?.companyName,
      altBillingAddress1: attrs.altBilling?.address1,
      altBillingAddress2: attrs.altBilling?.address2,
      altBillingCity: attrs.altBilling?.city,
      altBillingState: attrs.altBilling?.state,
      altBillingZip: attrs.altBilling?.zip,
      altBillingEmail: attrs.altBilling?.email,
      altBillingPhone: attrs.altBilling?.phoneNumber,
      summarize: attrs.summarize,
      subTotal: attrs.subTotal,
      tipTotal: attrs.tipTotal,
      taxTotal: attrs.taxTotal,
      total: attrs.total,
      paidTotal: attrs.paidTotal,
      dueTotal: attrs.dueTotal,
      // archived?: boolean;
      allInvoiceItems: attrs.allInvoiceItems.map((i: any) => InvoiceItem.fromPublicApiResponse(i)),
      // allClientLogs: ClientLog[];
      // estimateTier: EstimateTier;
      acceptElectronicPayment: attrs.acceptElectronicPayment,
      allInvoicePayments: attrs.allInvoicePayments,
      hasPaymentPending: attrs.hasPaymentPending,
    });
  }

  get totalWithTip(): number {
    return this.total + this.tipTotal;
  }

  get overdueOn(): Moment {
    if (this.netDays) {
      return moment(this.dueOn).add(this.netDays, 'days');
    } else {
      return this.dueOn;
    }
  }

  canEdit(user: User): boolean {
    if (
      user.accessLevel === USER_ACCESS_LEVEL.TECHNICIAN &&
      this.createdBy &&
      this.createdBy?.id !== user.id
    ) {
      return false;
    }

    return true;
  }

  get hasTopNotes(): boolean {
    return !!this.topNotesHeader || !!this.topNotes;
  }

  get hasAlternateBilling(): boolean {
    return !!(
      this.altBillingCompanyName ||
      this.altBillingFirstName ||
      this.altBillingLastName ||
      this.altBillingAddress1 ||
      this.altBillingAddress2 ||
      this.altBillingCity ||
      this.altBillingState ||
      this.altBillingZip ||
      this.altBillingPhone ||
      this.altBillingEmail
    );
  }

  get altBillingAddress(): IAddressParts {
    return {
      address1: this.altBillingAddress1,
      address2: this.altBillingAddress2,
      city: this.altBillingCity,
      state: this.altBillingState,
      zip: this.altBillingZip,
    };
  }

  get altBillingFullName(): string {
    let parts = [];
    if (this.altBillingFirstName) parts.push(this.altBillingFirstName);
    if (this.altBillingLastName) parts.push(this.altBillingLastName);
    return parts.join(' ');
  }

  get statusColor(): string {
    if (this.status === INVOICE_STATUS.PAID) return ALERT_GREEN_COLOR;
    if (this.status === INVOICE_STATUS.DRAFT || this.status === INVOICE_STATUS.CANCELED) return ALERT_YELLOW_COLOR;
    if (this.status === INVOICE_STATUS.OVERDUE || this.status === INVOICE_STATUS.DELETED) return ALERT_RED_COLOR;
    return GRAY_COLOR;
  }

  get statusTagColor(): 'green' | 'yellow' | 'red' | 'gray' {
    switch (this.statusColor) {
      case ALERT_GREEN_COLOR: return 'green';
      case ALERT_YELLOW_COLOR: return 'yellow';
      case ALERT_RED_COLOR: return 'red';
      default: return 'gray';
    }
  }

  get statusBadgeColor(): 'success' | 'warning' | 'danger' | 'dark' {
    switch (this.statusColor) {
      case ALERT_GREEN_COLOR: return 'success';
      case ALERT_YELLOW_COLOR: return 'warning';
      case ALERT_RED_COLOR: return 'danger';
      default: return 'dark';
    }
  }

  get pianoGroups(): InvoiceGroup[] {
    let groups: {[key: string]: InvoiceGroup} = {};
    for (let item of this.allInvoiceItems) {
      if (item.piano) {
        if (!groups[item.piano.id]) {
          groups[item.piano.id] = new InvoiceGroup();
          groups[item.piano.id].piano = item.piano;
        }
        groups[item.piano.id].invoiceItems.push(item);
      }
    }
    return _values(groups);
  }

  get miscGroup(): InvoiceGroup {
    let group = new InvoiceGroup();
    for (let item of this.allInvoiceItems) {
      if (!item.piano) {
        group.invoiceItems.push(item);
      }
    }
    return group;
  }

  get allGroups(): InvoiceGroup[] {
    let groups: InvoiceGroup[] = this.pianoGroups;
    if (this.miscGroup.invoiceItems.length > 0) {
      groups = groups.concat(this.miscGroup);
    }
    return groups;
  }

  get allTaxes(): {name: string, total: number}[] {
    let taxCache: {[key: string]: {name: string, total: number}} = {};
    this.allInvoiceItems.forEach((item: InvoiceItem) => {
      item.taxes.forEach((tax: ItemTax) => {
        if (!taxCache[tax.taxId]) {
          taxCache[tax.taxId] = {name: tax.name, total: 0};
        }
        taxCache[tax.taxId].total += tax.total;
      });
    });
    return Object.values(taxCache);
  }

  get allItemTaxes(): ItemTax[] {
    const itemTaxes: {[key: string]: ItemTax} = {};
    this.allInvoiceItems.forEach(item => {
      item.taxes.forEach(itemTax => {
        itemTaxes[itemTax.getCombinedId()] = itemTax;
      });
    });
    return Object.values(itemTaxes);
  }
}



/********************************************************************************************/

export class ItemTax {
  id: string;
  taxId: string;
  name: string;
  rate: number;
  total: number;

  constructor(attrs: any = {}) {
    Object.assign(this, attrs);
  }

  // This is used to detect when a tax that is on an old invoice has changed
  // in the current list of taxes.
  getCombinedId() {
    return `${this.taxId}::${this.name}::${this.rate}`;
  }

  static createFromTax(tax: Tax) {
    return new ItemTax({
      taxId: tax.id,
      name: tax.name,
      rate: tax.rate,
    });
  }
}

/********************************************************************************************/

export class InvoiceItem {
  id?: string;
  description?: string;
  type?: INVOICE_ITEM_TYPE;
  billable?: boolean;
  amount?: number;
  quantity?: number;
  taxable?: boolean;
  subTotal?: number;
  taxTotal?: number;
  total?: number;
  piano?: Piano;
  sequenceNumber?: number;
  taxes: ItemTax[];
  invoiceIndex?: number;
  masterServiceItem?: MasterServiceItem;

  constructor(attrs: any = {}) {
    this.id = attrs.id ? attrs.id : null;
    this.description = attrs.description !== undefined ? attrs.description : null;
    this.type = attrs.type ? attrs.type : null;
    this.billable = attrs.billable !== undefined ? attrs.billable : true;
    this.amount = attrs.amount !== undefined ? attrs.amount : null;
    this.quantity = attrs.quantity !== undefined ? attrs.quantity : null;
    this.taxable = attrs.taxable !== undefined ? attrs.taxable : null;
    this.subTotal = attrs.subTotal !== undefined ? attrs.subTotal : null;
    this.taxTotal = attrs.taxTotal !== undefined ? attrs.taxTotal : null;
    this.total = attrs.total !== undefined ? attrs.total : null;
    this.sequenceNumber = attrs.sequenceNumber !== undefined ? attrs.sequenceNumber : null;

    if (attrs.piano) {
      this.piano = new Piano(attrs.piano);
    }
    if (attrs.masterServiceItem) {
      this.masterServiceItem = new MasterServiceItem(attrs.masterServiceItem);
    }
    if (attrs.taxes) {
      this.taxes = attrs.taxes.map((tax: any) => new ItemTax(tax));
    } else {
      this.taxes = [];
    }
  }

  // The public API is slightly different than the private API.  Originally we did not share
  // models between public and private/mobile.  However, we started sharing components later
  // and thus needed to share models.  This static function translates the public API response
  // for company to something that resembles the private API response to be passed into the
  // constructor of this model.
  static fromPublicApiResponse(attrs: any): InvoiceItem {
    return new InvoiceItem({
      id: attrs.id,
      description: attrs.description,
      type: attrs.type,
      amount: attrs.amount,
      quantity: attrs.quantity,
      taxable: attrs.taxable,
      subTotal: attrs.subTotal,
      taxTotal: attrs.taxTotal,
      total: attrs.total,
      piano: attrs.piano,
      sequenceNumber: attrs.sequenceNumber,
      taxes: attrs.allTaxes,
    });
  }


  // IMPORTANT NOTE:
  // These calculations are ONLY used for display purposes.  The API does not accept totals, and calculates
  // the real totals on the server.   To prevent slight errors, be sure this code stays in sync with the
  // way that the API calculates totals.
  calculateTotals() {
    this.subTotal = 0;
    this.taxTotal = 0;
    this.total = 0;

    if (!this.taxes || Object.keys(this.taxes).length === 0) {
      this.taxable = false;
    }

    this.subTotal = roundAwayFromZero((this.amount || 0) * (this.quantity || 0) / 100, 0);
    if (this.taxable) {
      this.taxes.forEach((tax: ItemTax) => {
        const itemTaxTotal = roundAwayFromZero(this.subTotal * tax.rate / 100000, 0);
        tax.total = itemTaxTotal;
        this.taxTotal += itemTaxTotal;
      });
    }
    this.total += this.subTotal + this.taxTotal;
  }
}

/********************************************************************************************/

export class ItineraryItem implements IMappingItineraryItem {
  start: Moment;
  duration: number;
  buffer: number;
  position: ILatLng;
  type: ITINERARY_ITEM_TYPE;
  pinLetter: string;
  samePositionLinks: ItineraryItem[];
  samePositionLink: ItineraryItem;
  error: {type: DRIVE_TIME_ERROR_TYPE, message: string};
  travelMode: SCHEDULER_TRAVEL_MODE;
  availabilityId: string;

  constructor(attrs: any) {
    this.start = attrs.start || null;
    this.duration = attrs.duration === void 0 ? null : attrs.duration;
    this.buffer = attrs.buffer === void 0 ? null : attrs.buffer;
    this.position = attrs.position || null;
    this.type = attrs.type || null;
    this.pinLetter = attrs.pinLetter || null;
    this.samePositionLinks = [];
    this.error = null;
    this.travelMode = attrs.travelMode || SCHEDULER_TRAVEL_MODE.DRIVING;
    this.availabilityId = attrs.availabilityId || null;
  }

  get end(): Moment {
    if (!this.start) return null;
    if (this.duration) {
      return moment(this.start).add(this.duration + this.buffer, 'minutes');
    } else {
      return moment(this.start);
    }
  }

  get pinLabel(): string {
    let str = this.pinLetter;
    this.samePositionLinks.forEach(item => str += `,${item.pinLetter}`);
    return str;
  }

  get pinColor(): string {
    switch (this.type) {
      case ITINERARY_ITEM_TYPE.START:
      case ITINERARY_ITEM_TYPE.END:
        return GRAY_COLOR;
      default:
        if (this instanceof ItineraryItemEvent) {
          if (this.isCurrent) {
            return ALERT_YELLOW_COLOR;
          } else {
            return this.event?.user?.color || ALERT_GREEN_COLOR;
          }
        } else {
          return ALERT_GREEN_COLOR;
        }
    }
  }

  get pinFgColor(): string {
    return '#ffffff';
    // TODO -- refactor tinycolor out
    // return tinycolor(this.pinColor).getLuminance() === 1 ? '#000000' : '#ffffff';
  }

  hasSameAddress(item: ItineraryItem) {
    if (!item.position || !this.position) return false;
    return item.position.lat === this.position.lat && item.position.lng === this.position.lng;
  }
}


export class ItineraryItemPoint extends ItineraryItem {
  constructor(attrs: any) {
    super(attrs);
    this.duration = null; // ItineraryPoint objects do not have a duration.
  }
}

export class ItineraryItemEvent extends ItineraryItem {
  event: Event | EventReservation;
  isCurrent: boolean;

  constructor(attrs: any) {
    super(attrs);
    this.event = attrs.event || null;
    this.isCurrent = attrs.isCurrent;
  }
}

export class Itinerary {
  date: Moment;
  allDayEvents: Event[];
  items: ItineraryItem[];
  // mappableItems should be consider as a set of items that will be pins on a map.  They are a subset of items.
  mappableItems: ItineraryItem[];
  user: User;

  constructor(date: Moment, user: User) {
    this.date = moment(date);
    this.user = user;
    this.allDayEvents = [];
    this.items = [];
    this.mappableItems = [];
  }

  get memoItems(): ItineraryItem[] {
    return this.items.filter((i: ItineraryItem) => { return i.type === ITINERARY_ITEM_TYPE.MEMO; });
  }

  get syncedNotImpactingAvailabilityItems(): ItineraryItem[] {
    return this.items.filter((i: ItineraryItem) => {
      return (
        i.type === ITINERARY_ITEM_TYPE.SYNCED &&
        i instanceof ItineraryItemEvent &&
        (i as ItineraryItemEvent).event instanceof Event &&
        ((i as ItineraryItemEvent).event as Event).remoteCalendar?.impactsAvailability === false
      );
    });
  }
}

/********************************************************************************************/

export class Lifecycle {
  id: string;
  name: string;
  confWindowCode: string;

  constructor(attrs: any = {}) {
    this.id = attrs.id;
    this.name = attrs.name;
    this.confWindowCode = attrs.confWindowCode;
  }

  requiresConfirmation() {
    return !!this.confWindowCode;
  }
}

/********************************************************************************************/

export class MasterServiceGroup {
  id: string;
  name: I18nString;
  order: number;
  isMultiChoice: boolean;
  isArchived: boolean;
  allMasterServiceItems: MasterServiceItem[];

  constructor(attrs: any = {}) {
    this.allMasterServiceItems = [];
    if (attrs.allMasterServiceItems) {
      attrs.allMasterServiceItems.forEach((item: any) =>
        this.allMasterServiceItems.push(new MasterServiceItem(item)));
    }
    this.allMasterServiceItems.sort((a, b) => {
      if (a.order < b.order) return -1;
      if (a.order > b.order) return 1;
      return 0;
    });

    this.id = attrs.id;
    this.name = new I18nString(attrs.name);
    this.order = attrs.order;
    if (typeof attrs.isMultiChoice === 'undefined') {
      this.isMultiChoice = true;
    } else {
      this.isMultiChoice = !!attrs.isMultiChoice;
    }
    this.isArchived = attrs.isArchived;
  }
}

export class MasterServiceItem {
  id: string;
  name: I18nString;
  description: I18nString;
  educationDescription: I18nString;
  duration: number;
  amount: number;
  type: MASTER_SERVICE_ITEM_TYPE;
  externalUrl: string;
  order: number;
  isDefault: boolean;
  isTaxable: boolean;
  isSelfSchedulable: boolean;
  isAnyUser: boolean;
  isArchived: boolean;
  isTuning: boolean;
  masterServiceGroup: MasterServiceGroup;
  allUsers: User[];
  allEstimateChecklistItems: EstimateChecklistItem[];

  constructor(attrs: any) {
    if (attrs.masterServiceGroup) {
      this.masterServiceGroup = new MasterServiceGroup(attrs.masterServiceGroup);
    }
    if (attrs.allUsers) {
      this.allUsers = attrs.allUsers.map((user: any) => new User(user));
    } else {
      this.allUsers = [];
    }
    if (attrs.allEstimateChecklistItems) {
      this.allEstimateChecklistItems = attrs.allEstimateChecklistItems.map((i: any) => new EstimateChecklistItem(i));
    } else {
      this.allEstimateChecklistItems = [];
    }
    this.id = attrs.id;
    this.name = new I18nString(attrs.name);
    this.description = new I18nString(attrs.description);
    this.educationDescription = new I18nString(attrs.educationDescription);
    this.duration = attrs.duration || 0;
    this.amount = attrs.amount || 0;
    this.type = attrs.type || 'LABOR_FIXED_RATE';
    this.externalUrl = attrs.externalUrl || '';
    this.order = (typeof attrs.order === 'number' ? attrs.order : 999);
    this.isDefault = !!attrs.isDefault;
    this.isTaxable = !!attrs.isTaxable;
    this.isArchived = !!attrs.isArchived;
    this.isTuning = !!attrs.isTuning;
    this.isSelfSchedulable = !!attrs.isSelfSchedulable;
    if (typeof attrs.isAnyUser === 'undefined') {
      this.isAnyUser = true;
    } else {
      this.isAnyUser = !!attrs.isAnyUser;
    }
  }

  // Since hourly labor is calculated dynamically, we can't just use the amount field to know
  // the actual amount of this item.  In those cases, amount will be the hourly rate.  This
  // method will return the actual amount of the item.
  get calculatedAmount() {
    if (this.type === MASTER_SERVICE_ITEM_TYPE.LABOR_HOURLY) {
      return this.amount * (this.duration / 60);
    } else {
      return this.amount;
    }
  }

  canPerform(user: User): boolean {
    if (this.isAnyUser) return true;
    for (let i = 0; i < this.allUsers.length; i++) {
      if (this.allUsers[i].id === user.id) return true;
    }
    return false;
  }

  createEstimateTierItem(clientLocale: string, taxes: ItemTax[]): EstimateTierItem {
    let duration: number;
    let quantity: number;

    if (this.type === MASTER_SERVICE_ITEM_TYPE.LABOR_FIXED_RATE) {
      duration = this.duration;
      quantity = 1 * 100;
    } else if (this.type === MASTER_SERVICE_ITEM_TYPE.LABOR_HOURLY) {
      duration = this.duration;
      quantity = 1 * 100;
    } else if (this.type === MASTER_SERVICE_ITEM_TYPE.OTHER) {
      // For "Other" items the default quantity should be 1 and we should retain whatever they have entered
      // as a duration.
      duration = this.duration;
      quantity = 1 * 100;
    } else {
      duration = 0;
      quantity = this.duration;
    }
    let i = new EstimateTierItem({
      name: this.name.getOrDefault(clientLocale),
      description: this.description.getOrDefault(clientLocale),
      educationDescription: this.educationDescription ? this.educationDescription.getOrDefault(clientLocale) : null,
      externalUrl: this.externalUrl,
      quantity: quantity,
      duration: duration,
      amount: this.amount || 0,
      type: this.type,
      isTaxable: this.isTaxable,
      isTuning: this.isTuning,
      taxes: this.isTaxable ? [...taxes] : null
    });
    i.masterServiceItem = this;
    i.calculateTotals();

    // calculateTotals will reset isTaxable
    if (this.isTaxable) {
      i.isTaxable = true;
    }
    return i;
  }
}

/********************************************************************************************/

export class MiscServiceItem {
  duration: number;
  amount: number;
  name: string;
  isTuning: boolean;

  constructor(attrs: any = {}) {
    this.duration = attrs.duration || 0;
    this.amount = attrs.amount || 0;
    this.name = attrs.name || '';
    this.isTuning = attrs.isTuning || false;
  }

  // this is here to maintain the same interface as MasterServiceItem
  get calculatedAmount() {
    return this.amount;
  }
}

/********************************************************************************************/

export class MutationError {
  key: string;
  type: string;
  messages: string[];

  constructor(attrs: any) {
    Object.assign(this, attrs);
  }
}

export class MutationErrorList {
  all: MutationError[] = [];

  constructor(mutationErrors: any[]) {
    mutationErrors.forEach(err => this.all.push(new MutationError(err)));
  }

  toObject(): MutationErrorObject {
    let obj: MutationErrorObject = {};
    this.all.forEach(err => {
      if (!err.key) {
        obj['__generic'] = err;
      } else {
        obj[err.key] = err;
      }
    });
    return obj;
  }
}

export type MutationErrorObject = {
  [key: string]: MutationError;
};

/********************************************************************************************/

export class NewClientInfo implements IAddressPartsWithLocation {
  companyName: string;
  clientType: string;

  title?: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  suffix?: string;

  phoneNumber: string;
  phoneExtension: string;
  phoneType: PHONE_TYPE;
  email: string;
  role: string;

  secondaryTitle?: string;
  secondaryFirstName: string;
  secondaryMiddleName?: string;
  secondaryLastName: string;
  secondarySuffix?: string;
  secondaryPhoneNumber: string;
  secondaryPhoneExtension: string;
  secondaryPhoneType: PHONE_TYPE;
  secondaryEmail: string;
  secondaryRole: string;

  addressLine: string;
  addressType: ADDRESS_TYPE;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  position?: {
    lat: number;
    lng: number;
    locationType: MAPPING_LOCATION_TYPE;
  };

  constructor(attrs: any = {}) {
    if (!attrs) attrs = {};

    this.companyName = attrs.companyName;
    this.clientType = attrs.clientType || null;

    this.title = attrs.title;
    this.firstName = attrs.firstName;
    this.middleName = attrs.middleName;
    this.lastName = attrs.lastName;
    this.suffix = attrs.suffix;

    this.phoneNumber = attrs.phoneNumber;
    this.phoneExtension = attrs.phoneExtension;
    this.phoneType = (attrs.phoneType as PHONE_TYPE) || PHONE_TYPE.MOBILE;
    this.email = attrs.email;
    this.role = attrs.role;

    this.secondaryTitle = attrs.secondaryTitle;
    this.secondaryFirstName = attrs.secondaryFirstName;
    this.secondaryMiddleName = attrs.secondaryMiddleName;
    this.secondaryLastName = attrs.secondaryLastName;
    this.secondarySuffix = attrs.secondarySuffix;
    this.secondaryPhoneNumber = attrs.secondaryPhoneNumber;
    this.secondaryPhoneExtension = attrs.secondaryPhoneExtension;
    this.secondaryPhoneType = (attrs.secondaryPhoneType as PHONE_TYPE) || PHONE_TYPE.MOBILE;
    this.secondaryEmail = attrs.secondaryEmail;
    this.secondaryRole = attrs.secondaryRole;

    this.addressLine = attrs.addressLine;
    this.addressType = attrs.addressType ? (attrs.addressType as ADDRESS_TYPE) : ADDRESS_TYPE.STREET;
    this.address1 = attrs.address1;
    this.address2 = attrs.address2;
    this.city = attrs.city;
    this.state = attrs.state;
    this.zip = attrs.zip;
    if (attrs.position) {
      this.position = {
        lat: attrs.position?.lat,
        lng: attrs.position?.lng,
        locationType: attrs.position?.locationType,
      };
    }
  }

  get displayName(): string {
    if (this.companyName) {
      return this.companyName;
    } else if (this.primaryContactName) {
      return this.primaryContactName;
    } else {
      return null;
    }
  }

  get displayPhone(): string {
    let parts = [];
    if (this.phoneNumber) parts.push(this.phoneNumber);
    if (this.phoneExtension) parts.push(`x${this.phoneExtension}`);
    return parts.join(' ');
  }

  get primaryContactName(): string {
    if (this.firstName || this.lastName) {
      return `${this.firstName || ''} ${this.lastName || ''}`;
    } else {
      return null;
    }
  }

  get secondaryContactName(): string {
    if (this.secondaryFirstName || this.secondaryLastName) {
      return `${this.secondaryFirstName || ''} ${this.secondaryLastName || ''}`;
    } else {
      return null;
    }
  }

  get addressLines(): string[] {
    let addressLines: string[] = [];
    if (this.address1) addressLines.push(this.address1);
    if (this.address2) addressLines.push(this.address2);
    let csz = compact([this.city, this.state, this.zip]).join(', ');
    if (csz) addressLines.push(csz);
    return addressLines;
  }

  md5() {
    return md5([
      this.companyName,
      this.firstName,
      this.lastName,
      this.phoneNumber,
      this.phoneExtension,
      this.phoneType,
      this.email,
      this.role,
      this.secondaryFirstName,
      this.secondaryLastName,
      this.secondaryPhoneNumber,
      this.secondaryPhoneExtension,
      this.secondaryPhoneType,
      this.secondaryEmail,
      this.secondaryRole,
      this.addressLine,
      this.addressType,
      this.address1,
      this.address2,
      this.city,
      this.state,
      this.zip,
      this.position?.lat,
      this.position?.lng,
    ].join(' *--ATTR-SEPARATOR--* '));
  }

  toInput() {
    let input: any = {
      errorReferenceId: 'CLIENT0',
      companyName: this.companyName,
      contacts: [{
        errorReferenceId: 'CONTACT0',
        firstName: this.firstName,
        lastName: this.lastName,
        role: this.role,
      }],
    };

    if (this.phoneNumber) {
      input.contacts[0].phones = [{
        errorReferenceId: 'PHONE0',
        type: this.phoneType,
        phoneNumber: this.phoneNumber,
        extension: this.phoneExtension,
      }];
    }

    if (this.email) {
      input.contacts[0].emails = [{
        errorReferenceId: 'EMAIL0',
        email: this.email,
      }];
    }

    if (this.address1 || this.address2 ||
      this.city || this.state || this.zip)
    {
      input.contacts[0].addresses = [{
        errorReferenceId: 'ADDRESS0',
        type: this.addressType,
        address1: this.address1,
        address2: this.address2,
        city: this.city,
        state: this.state,
        zip: this.zip,
      }];
    }

    if (this.hasSecondaryContact) {
      input.contacts.push({
        errorReferenceId: 'CONTACT1',
        firstName: this.secondaryFirstName,
        lastName: this.secondaryLastName,
        role: this.secondaryRole,
      });

      if (this.secondaryPhoneNumber) {
        input.contacts[1].phones = [{
          errorReferenceId: 'PHONE1',
          type: this.secondaryPhoneType,
          phoneNumber: this.secondaryPhoneNumber,
          extension: this.secondaryPhoneExtension,
        }];
      }

      if (this.secondaryEmail) {
        input.contacts[1].emails = [{
          errorReferenceId: 'EMAIL1',
          email: this.secondaryEmail,
        }];
      }
    }

    return input;
  }

  resetAddress() {
    this.addressLine = null;
    this.addressType = null;
    this.address1 = null;
    this.address2 = null;
    this.city = null;
    this.state = null;
    this.zip = null;
    this.position = {
      lat: null,
      lng: null,
      locationType: null,
    };
  }

  get hasSecondaryContact(): boolean {
    return !!(
      this.secondaryFirstName ||
      this.secondaryLastName ||
      this.secondaryEmail ||
      this.secondaryPhoneNumber
    );
  }
}

/********************************************************************************************/

export class Piano {
  id: string;
  status: PIANO_STATUS;
  type: PIANO_TYPE;
  make: string;
  model?: string;
  location?: string;
  year?: number;
  serialNumber?: string;
  serviceIntervalMonths?: number;
  damppChaserInstalled?: boolean;
  damppChaserHumidistatModel?: string;
  damppChaserMfgDate?: Moment;
  playerInstalled?: boolean;
  playerMake?: string;
  playerModel?: string;
  playerSerialNumber?: string;
  caseColor?: string;
  caseFinish?: string;
  size?: string;
  useType?: string;
  totalLoss?: boolean;
  consignment?: boolean;
  rental?: boolean;
  rentalContractEndsOn?: Moment;
  needsRepairOrRebuilding?: boolean;
  hasIvory?: boolean;
  manualLastService?: Moment;
  eventLastService?: Moment;
  nextServiceOverride?: Moment;
  calculatedLastService?: Moment;
  calculatedNextService?: Moment;
  nextTuningScheduled?: Event;
  client?: Client;
  notes?: string;
  primaryPianoPhoto?: Photo;
  allPianoPhotosTotalCount?: number;
  somePianoPhotos?: Photo[];
  potentialPerformanceLevel: number;
  createdAt?: Moment;
  pianoMeasurements?: PianoMeasurement[];
  referenceId: string;
  lifecycleState: LIFECYCLE_STATE;
  tags: string[];

  constructor(attrs: any = {}) {
    this.id = attrs.id;
    this.status = attrs.status || PIANO_STATUS.ACTIVE;
    this.type = attrs.type || PIANO_TYPE.UNKNOWN;
    this.make = attrs.make || '';
    this.model = attrs.model || null;
    this.location = attrs.location || null;
    this.year = attrs.year || null;
    this.serialNumber = attrs.serialNumber || null;
    this.notes = attrs.notes || null;
    this.serviceIntervalMonths = attrs.serviceIntervalMonths || null;
    this.damppChaserInstalled = !!attrs.damppChaserInstalled;
    this.damppChaserHumidistatModel = attrs.damppChaserHumidistatModel || null;
    if (attrs.damppChaserMfgDate) {
      this.damppChaserMfgDate = moment(attrs.damppChaserMfgDate);
    }
    this.playerInstalled = !!attrs.playerInstalled;
    this.playerMake = attrs.playerMake || null;
    this.playerModel = attrs.playerModel || null;
    this.playerSerialNumber = attrs.playerSerialNumber || null;
    this.caseColor = attrs.caseColor || null;
    this.caseFinish = attrs.caseFinish || null;
    this.size = attrs.size || null;
    this.useType = attrs.useType || null;
    this.totalLoss = !!attrs.totalLoss;
    this.consignment = !!attrs.consignment;
    this.rental = !!attrs.rental;
    this.rentalContractEndsOn = null;
    if (attrs.rentalContractEndsOn) {
      this.rentalContractEndsOn = moment(attrs.rentalContractEndsOn);
    }
    this.needsRepairOrRebuilding = !!attrs.needsRepairOrRebuilding;
    this.hasIvory = !!attrs.hasIvory;
    this.potentialPerformanceLevel = attrs.potentialPerformanceLevel;
    this.referenceId = attrs.referenceId;

    this.manualLastService = null;
    if (attrs.manualLastService) {
      this.manualLastService = moment(attrs.manualLastService);
    }
    this.eventLastService = null;
    if (attrs.eventLastService) {
      this.eventLastService = moment(attrs.eventLastService);
    }
    this.nextServiceOverride = null;
    if (attrs.nextServiceOverride) {
      this.nextServiceOverride = moment(attrs.nextServiceOverride);
    }
    if (attrs.calculatedLastService) {
      this.calculatedLastService = moment(attrs.calculatedLastService);
    }
    if (attrs.calculatedNextService) {
      this.calculatedNextService = moment(attrs.calculatedNextService);
    }
    this.nextTuningScheduled = null;
    if (attrs.nextTuningScheduled) {
      this.nextTuningScheduled = new Event(attrs.nextTuningScheduled);
    }
    if (attrs.client) {
      this.client = new Client(attrs.client);
    }
    if (attrs.primaryPianoPhoto) {
      this.primaryPianoPhoto = new Photo(attrs.primaryPianoPhoto);
    }
    if (attrs.allPianoPhotos) {
      this.allPianoPhotosTotalCount = attrs.allPianoPhotos.totalCount;
      this.somePianoPhotos = attrs.allPianoPhotos.nodes.map((ph: any) => new Photo(ph));
    }
    if (attrs.createdAt) {
      this.createdAt = moment(attrs.createdAt);
    }
    if (attrs.allPianoMeasurements) {
      this.pianoMeasurements = attrs.allPianoMeasurements.map((pm: any) => new PianoMeasurement(pm));
    } else {
      this.pianoMeasurements = [];
    }
    this.lifecycleState = attrs.lifecycleState;
    this.tags = attrs.tags || [];
  }

  get displayName(): string {
    return getPianoDisplayName(this);
  }

  get areAdvancedOptionsSet(): boolean {
    return !!(
      this.damppChaserInstalled ||
      this.damppChaserHumidistatModel ||
      this.damppChaserMfgDate ||
      this.playerInstalled ||
      this.playerMake ||
      this.playerModel ||
      this.playerSerialNumber ||
      this.caseColor ||
      this.caseFinish ||
      this.size ||
      this.useType ||
      this.totalLoss ||
      this.consignment ||
      this.rental ||
      this.rentalContractEndsOn ||
      this.needsRepairOrRebuilding ||
      this.hasIvory
    );
  }
}

/********************************************************************************************/

export class PianoMeasurement {
  id: string;
  pianoId: string;
  takenOn: Moment;
  temperature: number;
  humidity: number;
  a0Pitch: number;
  a1Pitch: number;
  a2Pitch: number;
  a3Pitch: number;
  a4Pitch: number;
  a5Pitch: number;
  a6Pitch: number;
  a7Pitch: number;
  a0Dip: number;
  a1Dip: number;
  a2Dip: number;
  a3Dip: number;
  a4Dip: number;
  a5Dip: number;
  a6Dip: number;
  a7Dip: number;
  d6SustainPlucked: number;
  g6SustainPlucked: number;
  c7SustainPlucked: number;
  d6SustainPlayed: number;
  g6SustainPlayed: number;
  c7SustainPlayed: number;

  constructor(attrs: any = {}) {
    this.id = attrs.id;
    this.pianoId = attrs.pianoId || null;
    this.takenOn = attrs.takenOn ? moment(attrs.takenOn) : null;
    this.temperature = attrs.temperature !== undefined && attrs.temperature !== null ? attrs.temperature : null;
    this.humidity = attrs.humidity !== undefined && attrs.humidity !== null ? attrs.humidity : null;
    this.a0Pitch = attrs.a0Pitch !== undefined && attrs.a0Pitch !== null ? attrs.a0Pitch : null;
    this.a1Pitch = attrs.a1Pitch !== undefined && attrs.a1Pitch !== null ? attrs.a1Pitch : null;
    this.a2Pitch = attrs.a2Pitch !== undefined && attrs.a2Pitch !== null ? attrs.a2Pitch : null;
    this.a3Pitch = attrs.a3Pitch !== undefined && attrs.a3Pitch !== null ? attrs.a3Pitch : null;
    this.a4Pitch = attrs.a4Pitch !== undefined && attrs.a4Pitch !== null ? attrs.a4Pitch : null;
    this.a5Pitch = attrs.a5Pitch !== undefined && attrs.a5Pitch !== null ? attrs.a5Pitch : null;
    this.a6Pitch = attrs.a6Pitch !== undefined && attrs.a6Pitch !== null ? attrs.a6Pitch : null;
    this.a7Pitch = attrs.a7Pitch !== undefined && attrs.a7Pitch !== null ? attrs.a7Pitch : null;
    this.a0Dip = attrs.a0Dip !== undefined && attrs.a0Dip !== null ? attrs.a0Dip : null;
    this.a1Dip = attrs.a1Dip !== undefined && attrs.a1Dip !== null ? attrs.a1Dip : null;
    this.a2Dip = attrs.a2Dip !== undefined && attrs.a2Dip !== null ? attrs.a2Dip : null;
    this.a3Dip = attrs.a3Dip !== undefined && attrs.a3Dip !== null ? attrs.a3Dip : null;
    this.a4Dip = attrs.a4Dip !== undefined && attrs.a4Dip !== null ? attrs.a4Dip : null;
    this.a5Dip = attrs.a5Dip !== undefined && attrs.a5Dip !== null ? attrs.a5Dip : null;
    this.a6Dip = attrs.a6Dip !== undefined && attrs.a6Dip !== null ? attrs.a6Dip : null;
    this.a7Dip = attrs.a7Dip !== undefined && attrs.a7Dip !== null ? attrs.a7Dip : null;
    this.d6SustainPlucked = attrs.d6SustainPlucked !== undefined && attrs.d6SustainPlucked !== null ? attrs.d6SustainPlucked : null;
    this.g6SustainPlucked = attrs.g6SustainPlucked !== undefined && attrs.g6SustainPlucked !== null ? attrs.g6SustainPlucked : null;
    this.c7SustainPlucked = attrs.c7SustainPlucked !== undefined && attrs.c7SustainPlucked !== null ? attrs.c7SustainPlucked : null;
    this.d6SustainPlayed = attrs.d6SustainPlayed !== undefined && attrs.d6SustainPlayed !== null ? attrs.d6SustainPlayed : null;
    this.g6SustainPlayed = attrs.g6SustainPlayed !== undefined && attrs.g6SustainPlayed !== null ? attrs.g6SustainPlayed : null;
    this.c7SustainPlayed = attrs.c7SustainPlayed !== undefined && attrs.c7SustainPlayed !== null ? attrs.c7SustainPlayed : null;
  }
}

/********************************************************************************************/

export class PianoServices {
  piano: Piano;
  services: (MasterServiceItem | MiscServiceItem)[];
  language?: string;

  constructor(attrs: any = {}) {
    if (attrs.piano) {
      this.piano = new Piano(attrs.piano);
    } else {
      this.piano = new Piano();
    }
    if (attrs.services) {
      this.services = attrs.services.slice();
    } else {
      this.services = [];
    }
    this.language = attrs.language;
  }

  get totalDuration() {
    return this.services.reduce((n, s) => n + s.duration, 0);
  }

  get totalAmount() {
    return this.services.reduce((n, s) => n + s.calculatedAmount, 0);
  }

  get isTuning(): boolean {
    return this.services.reduce((n, s) => n || s.isTuning, false);
  }
}

/********************************************************************************************/

export class ScheduledMessage {
  id: string;
  scheduledMessageTemplateId: string;
  client: Client;
  clientId: string;
  type: SCHEDULED_MESSAGE_TYPE;
  language: string;
  subject: string;
  template: string;
  sendAt: Moment;

  constructor(attrs: any) {
    this.id = attrs.id;
    this.type = attrs.type;
    this.language = attrs.language;
    this.subject = attrs.subject;
    this.template = attrs.template;
    this.sendAt = attrs.sendAt ? moment(attrs.sendAt) : null;
    if (attrs.scheduledMessageTemplate) {
      this.scheduledMessageTemplateId = attrs.scheduledMessageTemplate.id;
    } else if (attrs.scheduledMessageTemplateId) {
      this.scheduledMessageTemplateId = attrs.scheduledMessageTemplateId;
    }
    this.client = null;
    if (attrs.client) {
      this.clientId = attrs.client.id;
      this.client = new Client(attrs.client);
    } else if (attrs.clientId) {
      this.clientId = attrs.clientId;
    }
  }

  get shouldSendImmediately(): boolean {
    return !this.sendAt || this.sendAt.isBefore();
  }
}

/********************************************************************************************/

export class ScheduledMessageTemplate {
  id: string;
  type: SCHEDULED_MESSAGE_TYPE;
  name: I18nString;
  subject: I18nString;
  template: I18nString;
  order: number;

  constructor(attrs: any) {
    this.id = attrs.id;
    this.type = attrs.type;
    this.name = new I18nString(attrs.name);
    this.subject = new I18nString(attrs.subject);
    this.template = new I18nString(attrs.template);
    this.order = attrs.order;
  }
}

/********************************************************************************************/

export class ServiceLibraryGroup {
  id: string;
  name: I18nString;
  sequenceNumber: number;
  isMultiChoice: boolean;
  allServiceLibraryItems: ServiceLibraryItem[];

  constructor(attrs: any) {
    this.id = attrs.id;
    this.name = new I18nString(attrs.name);
    this.sequenceNumber = attrs.sequenceNumber;
    this.isMultiChoice = attrs.isMultiChoice;
    this.allServiceLibraryItems = [];
    if (attrs.allServiceLibraryItems) {
      this.allServiceLibraryItems = attrs.allServiceLibraryItems
        .map((i: any) => new ServiceLibraryItem(i))
        .sort((a: ServiceLibraryItem, b: ServiceLibraryItem) => {
          if (a.sequenceNumber < b.sequenceNumber) return -1;
          if (b.sequenceNumber < a.sequenceNumber) return 1;
          return 0;
        });
    }
  }
}

/********************************************************************************************/

export class ServiceLibraryItem {
  id: string;
  sequenceNumber: number;
  name: I18nString;
  description: I18nString;
  educationDescription: I18nString;
  type: MASTER_SERVICE_ITEM_TYPE;
  duration: number;
  isTuning: boolean;
  serviceLibraryGroupId?: string;

  constructor(attrs: any) {
    this.id = attrs.id;
    this.sequenceNumber = attrs.sequenceNumber;
    this.name = new I18nString(attrs.name);
    this.description = new I18nString(attrs.description);
    this.educationDescription = new I18nString(attrs.educationDescription);
    this.type = attrs.type;
    this.duration = attrs.duration;
    this.isTuning = attrs.isTuning;
    this.serviceLibraryGroupId = attrs.serviceLibraryGroup?.id;
  }
}

/********************************************************************************************/

export type SlotCalculations = {
  relWeight: number;
  relWeightRatio: number;
  relWeightLabel: 'good' | 'poor' | 'awful';
  hasBetterFilledDays: boolean;
};

export class Slot {
  id: string;
  startsAt: Moment;
  timezone: string;
  duration: number;
  buffer: number;
  addressLine: string;
  order: number;
  weight: number;
  isOpenDay: boolean;
  outsideAreaDrive: boolean;
  beforeDrive: number;
  beforeTraffic: number;
  afterDrive: number;
  afterTraffic: number;
  user: User;
  calculations: SlotCalculations;
  warnings: SLOT_WARNING[];

  constructor(attrs: any = {}) {
    Object.assign(this, attrs);
    this.id = attrs.id || uuid();
    this.startsAt = moment.tz(attrs.startsAt, attrs.timezone);
    this.user = new User(attrs.user);
    this.calculations = {
      relWeight: null,
      relWeightRatio: 1,
      relWeightLabel: 'good',
      hasBetterFilledDays: false,
    };
  }

  get allTraffic(): number {
    return (this.beforeTraffic || 0) + (this.afterTraffic || 0);
  }
}

/********************************************************************************************/

export class SlotV2 {
  startsAt: Moment;
  timezone: string;
  duration: number;
  buffer: number;
  technicianId: string;
  weight: number;
  afterTrafficMinutes: number;
  afterTravelMinutes: number;
  beforeTrafficMinutes: number;
  beforeTravelMinutes: number;
  outsideServiceAreaMinutes: number;
  outsideServiceAreaMiles: number;
  filteredReasons: SCHEDULER_SLOT_FILTER_REASON_TYPE[];
  flags: SCHEDULER_SLOT_FLAG_TYPE[];
  user: User;
  latitude: string;
  longitude: string;
  travelMode: SCHEDULER_TRAVEL_MODE;
  availabilityId: string;

  constructor(attrs: any = {}) {
    Object.assign(this, attrs);
    this.startsAt = moment.tz(attrs.startsAt, attrs.timezone);
    this.user = new User(attrs.user);
  }

  get allTraffic(): number {
    return (this.beforeTrafficMinutes || 0) + (this.afterTrafficMinutes || 0);
  }

  get addressLine(): string {
    return `${this.latitude},${this.longitude}`;
  }
}

/********************************************************************************************/

export class SlotDate {
  id: string;
  date: Moment;
  user: User;
  position: number;
  slots: Slot[];

  constructor() {
    this.id = uuid();
    this.slots = [];
  }
}

/********************************************************************************************/

export class SlotV2Date {
  id: string;
  date: Moment;
  user: User;
  position: number;
  slots: SlotV2[];

  constructor() {
    this.id = uuid();
    this.slots = [];
  }
}

/********************************************************************************************/

export class SmsMessage {
  id: string;
  message: string;
  phoneNumber: string;
  status: SMS_STATUS;
  createdAt: Moment;
  updatedAt: Moment;

  client: Client;
  contact: Contact;

  constructor(attrs: any = {}) {
    this.id = attrs.id;
    this.message = attrs.message;
    this.phoneNumber = attrs.phoneNumber;
    this.status = attrs.status;
    this.createdAt = attrs.createdAt ? moment(attrs.createdAt) : null;
    this.updatedAt = attrs.updatedAt ? moment(attrs.updatedAt) : null;

    this.client = attrs.client ? new Client(attrs.client) : null;
    this.contact = attrs.contact ? new Contact(attrs.contact) : null;
  }
}

/********************************************************************************************/

export class Tax {
  id: string;
  name: string;
  rate: number;
  default: boolean;
  activeOrHistorical: ACTIVE_OR_HISTORICAL;

  constructor(attrs: any = {}) {
    Object.assign(this, attrs);
  }

  getLabel() {
    return `${this.name} (${formatPercent(this.rate, 1000, null)})`;
  }

  // This is used to detect when a tax that is on an old invoice has changed
  // in the current list of taxes.
  getCombinedId() {
    return `${this.id}::${this.name}::${this.rate}`;
  }
}

/********************************************************************************************/

export class User {
  id: string;
  status: USER_STATUS;
  color: string;
  name: string;
  firstName: string;
  lastName: string;
  email: string;
  timezone: string;
  accessLevel: USER_ACCESS_LEVEL;
  region: string;
  intercomUserHash: string;
  isSchedulable: boolean;
  isTrafficEnabled: boolean;
  hasLimitedAccess: boolean;
  localization: Localization;
  defaultUserLocalization: Localization;
  makeMePreferredTechForNewClients: boolean;
  hideDashboardReferralNotice: boolean;
  canViewCompanyMetrics: boolean;

  sharedCalendarToken: string;
  calendarDefaultViewType: CALENDAR_VIEW_TYPE;
  calendarDefaultTitleMode: CALENDAR_TITLE_MODE;
  calendarFontSize: CALENDAR_FONT_SIZE;
  calendarDefaultShowAvailability: boolean;
  calendarDefaultShowConfirmationWarnings: boolean;
  calendarDefaultSendAppointmentConfirmation: boolean;
  calendarShowNonschedulableUsers: boolean;
  calendarPromptToScheduleAfterCompletion: boolean;
  calendarMakeCompletedAndPastEventsDimmer: boolean;
  calendarShowDetailsOnIcsExport: boolean;
  calendarIcsExportEventTypes: EVENT_TYPE[];
  calendarDefaultUserId: string;
  uiExpandedTimeline: boolean;
  defaultBuffer: number;
  genericReferralUrl: string;

  reservationNotificationsForAllUsers: boolean;
  reservationNotificationsForSpecificUsers: User[];
  wantsReservationNotifications: boolean;

  schedulerLongTermLimitDays: number;
  schedulerLongTermLimitMessage: I18nString;
  schedulerShortTermLimitHours: number;
  schedulerShortTermLimitHoursType: SCHEDULER_SHORT_TERM_LIMIT_HOURS_TYPE;
  schedulerShortTermLimitMessage: I18nString;
  schedulerDefaultTravelMode: SCHEDULER_TRAVEL_MODE;

  updatedAt: Moment;
  createdAt: Moment;

  constructor(attrs: any = {}) {
    Object.assign(this, attrs);
    this.createdAt = attrs.createdAt ? moment(attrs.createdAt) : null;
    this.updatedAt = attrs.updatedAt ? moment(attrs.updatedAt) : null;
    if (attrs.reservationNotificationsForSpecificUsers) {
      if (attrs.reservationNotificationsForSpecificUsers.nodes) {
        this.reservationNotificationsForSpecificUsers = attrs.reservationNotificationsForSpecificUsers.nodes.map((node: any) => new User(node));
      } else {
        this.reservationNotificationsForSpecificUsers = attrs.reservationNotificationsForSpecificUsers.map((u: User) => new User(u));
      }
    } else {
      this.reservationNotificationsForSpecificUsers = [];
    }
    this.reservationNotificationsForAllUsers = !!attrs.reservationNotificationsForAllUsers;

    if (attrs.localization) {
      this.localization = new Localization(attrs.localization);
    } else {
      this.localization = null;
    }

    if (attrs.flags) {
      if (attrs.flags.makeMePreferredTechForNewClients !== undefined) {
        this.makeMePreferredTechForNewClients = attrs.flags.makeMePreferredTechForNewClients;
      }
      this.hideDashboardReferralNotice = attrs.flags.hideDashboardReferralNotice;
    }

    if (attrs.schedulerSettings) {
      this.schedulerLongTermLimitDays = attrs.schedulerSettings.longTermLimitDays;
      this.schedulerShortTermLimitHours = attrs.schedulerSettings.shortTermLimitHours;
      this.schedulerShortTermLimitHoursType = attrs.schedulerSettings.shortTermLimitHoursType;
      this.schedulerShortTermLimitMessage = new I18nString(attrs.schedulerSettings.shortTermLimitMessage);
      this.schedulerDefaultTravelMode = attrs.schedulerSettings.defaultTravelMode;
    } else {
      this.schedulerLongTermLimitDays = attrs.schedulerLongTermLimitDays;
      this.schedulerLongTermLimitMessage = new I18nString(attrs.schedulerLongTermLimitMessage);
      this.schedulerShortTermLimitHours = attrs.schedulerShortTermLimitHours;
      this.schedulerShortTermLimitHoursType = attrs.schedulerShortTermLimitHoursType;
      this.schedulerShortTermLimitMessage = new I18nString(attrs.schedulerShortTermLimitMessage);
      this.schedulerDefaultTravelMode = attrs.schedulerDefaultTravelMode;
    }
    if (!this.schedulerDefaultTravelMode) {
      this.schedulerDefaultTravelMode = SCHEDULER_TRAVEL_MODE.DRIVING;
    }

    this.defaultUserLocalization = new Localization(attrs.defaultUserLocalization);
  }

  get fullName() {
    if (this.firstName && this.lastName) {
      return `${this.firstName} ${this.lastName}`;
    } else if (this.firstName) {
      return this.firstName;
    } else if (this.lastName) {
      return this.lastName;
    } else {
      return 'Unnamed';
    }
  }

  get fgColor(): string {
    // return tinycolor(this.color).getLuminance() === 1 ? '#000' : '#fff';
    return '#ffffff';
  }

  get isFullAdmin(): boolean {
    return this.accessLevel === USER_ACCESS_LEVEL.ADMIN && !this.hasLimitedAccess;
  }
}

/********************************************************************************************/

export class TimelineEntry {
  id: string;
  type: TIMELINE_ENTRY_TYPE;
  client: Client;
  piano: Piano;
  invoice: Invoice;
  estimate: Estimate;
  appointment: Event;
  summary: string;
  comment: string;
  user: User;
  occurredAt: Moment;
  duration: number;
  relatedType: TIMELINE_ENTRY_RELATED_TYPE;
  relatedId: string;
  emailStatus: EMAIL_STATUS;
  emailRecipientName: string;
  emailFailureReason: string;
  smsStatus: SMS_STATUS;
  alertType: SYSTEM_NOTIFICATION_ALERT_TYPE;
  invoicePayment: InvoicePayment;

  constructor(attrs: any = {}) {
    Object.assign(this, attrs);
    if (attrs.client) this.client = new Client(attrs.client);
    if (attrs.piano) this.piano = new Piano(attrs.piano);
    if (attrs.invoice) this.invoice = new Invoice(attrs.invoice);
    if (attrs.estimate) this.estimate = new Estimate(attrs.estimate);
    if (attrs.appointment) this.appointment = new Event(attrs.appointment);
    if (attrs.user) this.user = new User(attrs.user);
    if (attrs.occurredAt) this.occurredAt = moment(attrs.occurredAt);
    if (attrs.invoicePayment) this.invoicePayment = new InvoicePayment(attrs.invoicePayment);
  }
}

/********************************************************************************************/

export class TimelineDay {
  date: Moment;
  entries: TimelineEntry[];
}

/********************************************************************************************/

export class NextLifecycleMessage {
  message: string;
  dueAt: Moment;
  reminderType: REMINDER_TYPE;
  lifecycleType: LIFECYCLE_TYPE;
  lifecycleState: LIFECYCLE_STATE;
  isMissingContactInfo: boolean;
  isRecentlyChanged: boolean;
  client: Client;
  piano: Piano;
  event: Event;

  constructor(attrs: any = {}) {
    Object.assign(this, attrs);
    if (attrs.client) this.client = new Client(attrs.client);
    if (attrs.piano) this.piano = new Piano(attrs.piano);
    if (attrs.event) this.event = new Event(attrs.event);
    if (attrs.dueAt) this.dueAt = moment(attrs.dueAt);
  }
}

/********************************************************************************************/

export class Photo {
  id: string;
  createdAt: Moment;
  notes: string;
  original: PhotoDetails;
  thumbnail: PhotoDetails;
  isUsedByEstimates: boolean;
  takenAt: Moment;

  constructor(attrs: any = {}) {
    this.id = attrs.id;
    if (attrs.createdAt) this.createdAt = moment(attrs.createdAt);
    this.notes = attrs.notes;
    if (attrs.original) this.original = new PhotoDetails(attrs.original);
    if (attrs.thumbnail) this.thumbnail = new PhotoDetails(attrs.thumbnail);
    if (attrs.isUsedByEstimates) this.isUsedByEstimates = attrs.isUsedByEstimates;
    if (attrs.takenAt) this.takenAt = moment(attrs.takenAt);
  }
}

/********************************************************************************************/

export class PhotoDetails {
  url: string;
  mimeType: string;
  size: number;
  dimensions: {height: number, width: number};

  constructor(attrs: any = {}) {
    this.url = attrs.url;
    this.mimeType = attrs.mimeType;
    this.size = attrs.size;
    if (attrs.dimensions) this.dimensions = {height: attrs.dimensions.height, width: attrs.dimensions.width};
  }
}

/********************************************************************************************/

export class Localization {
  id: string;
  isLocaleDefault: boolean;
  isUserDefault: boolean;
  isClientDefault: boolean;
  locale: string;
  dateFormatLocale: string;
  timeFormatLocale: string;
  numberFormat: NUMBER_FORMAT;
  currencyFormat: CURRENCY_FORMAT;
  firstDayOfWeek: WEEKDAYS;

  // These are read-only properties that are set from the server.
  // These are not set here and saved to the API.
  dateTimeSeparator: string;
  dateFormat: IDateFormat;
  unicodeDateFormat: IDateFormat;
  timeFormat: ITimeFormat;
  unicodeTimeFormat: ITimeFormat;

  constructor(attrs: any = {}) {
    this.id = attrs.id;
    this.isLocaleDefault = attrs.isLocaleDefault;
    this.isUserDefault = attrs.isUserDefault;
    this.isClientDefault = attrs.isClientDefault;
    this.locale = attrs.locale;
    this.dateFormatLocale = attrs.dateFormatLocale;
    this.timeFormatLocale = attrs.timeFormatLocale;
    this.numberFormat = attrs.numberFormat as NUMBER_FORMAT;
    this.currencyFormat = attrs.currencyFormat as CURRENCY_FORMAT;
    this.firstDayOfWeek = attrs.firstDayOfWeek as WEEKDAYS;

    this.dateFormat = {
      date: attrs.dateFormat?.date?.moment || attrs.dateFormat?.date || 'MMM D, YYYY',
      weekdayDate: attrs.dateFormat?.weekdayDate?.moment || attrs.dateFormat?.weekdayDate || 'ddd, MMM D, YYYY',
      monthYear: attrs.dateFormat?.monthYear?.moment || attrs.dateFormat?.monthYear || 'MMM, YYYY',
    };
    this.unicodeDateFormat = {
      date: attrs.dateFormat?.date?.unicode || attrs.unicodeDateFormat?.date || 'MMM d, y',
      weekdayDate: attrs.dateFormat?.weekdayDate?.unicode || attrs.unicodeDateFormat?.weekdayDate || 'EEE, MMM d, Y',
      monthYear: attrs.dateFormat?.monthYear?.unicode || attrs.unicodeDateFormat?.monthYear || 'MMM, y',
    };
    this.timeFormat = {
      time: attrs.timeFormat?.time?.moment || attrs.timeFormat?.time || 'h:mm A',
      timezoneTime: attrs.timeFormat?.timezoneTime?.moment || attrs.timeFormat?.timezoneTime || 'h:mm A zz',
    };
    this.unicodeTimeFormat = {
      time: attrs.timeFormat?.time?.unicode || attrs.unicodeTimeFormat?.time || 'h:mm a',
      timezoneTime: attrs.timeFormat?.timezoneTime?.unicode || attrs.unicodeTimeFormat?.timezoneTime || 'h:mm a zzz',
    };
    this.dateTimeSeparator = attrs.dateFormat?.dateTimeSeparator || attrs.timeFormat?.dateTimeSeparator || ' ';
  }
}

/********************************************************************************************/

export type Currency = {
  symbol: string;
  code: string;
  label: string;
  divisor: number;
  decimalDigits: number;
};

/********************************************************************************************/

export type SupportedLocaleInfo = {
  locale: string;
  isLocaleDefault: boolean;
  label: string;
  defaultNumberFormat: NUMBER_FORMAT;
  defaultCurrencyFormat: CURRENCY_FORMAT;
  defaultFirstDayOfWeek: WEEKDAYS;
  monthYearFormat: {moment: string, ruby: string};
  dateFormat: {moment: string, ruby: string};
  weekdayDateFormat: {moment: string, ruby: string};
  timeFormat: {moment: string, ruby: string};
  timezoneTimeFormat: {moment: string, ruby: string};
  dateTimeSeparator: string;
};

/********************************************************************************************/

export class EstimateChecklist {
  id: string;
  name: I18nString;
  isDefault: boolean;
  allEstimateChecklistGroups: EstimateChecklistGroup[];
  allUngroupedEstimateChecklistItems: EstimateChecklistItem[];

  constructor(attrs: any = {}) {
    this.id = attrs.id;
    this.name = new I18nString(attrs.name);
    this.isDefault = !!attrs.isDefault;

    if (attrs.allEstimateChecklistGroups) {
      this.allEstimateChecklistGroups = attrs.allEstimateChecklistGroups.map((g: any) => new EstimateChecklistGroup(g));
    } else {
      this.allEstimateChecklistGroups = [];
    }
    if (attrs.allUngroupedEstimateChecklistItems) {
      this.allUngroupedEstimateChecklistItems = attrs.allUngroupedEstimateChecklistItems.map((i: any) => new EstimateChecklistItem(i));
    } else {
      this.allUngroupedEstimateChecklistItems = [];
    }
  }

  get itemCount(): number {
    return this.allUngroupedEstimateChecklistItems.length +
      this.allEstimateChecklistGroups
        .map(g => g.allEstimateChecklistItems.length)
        .reduce((a, v) => a + v, 0);
  }
}

/********************************************************************************************/

export class EstimateChecklistGroup {
  id: string;
  name: I18nString;
  sequenceNumber: number;
  allEstimateChecklistItems: EstimateChecklistItem[];

  constructor(attrs: any = {}) {
    this.id = attrs.id;
    this.name = new I18nString(attrs.name);
    this.sequenceNumber = attrs.sequenceNumber;

    if (attrs.allEstimateChecklistItems) {
      this.allEstimateChecklistItems = attrs.allEstimateChecklistItems.map((i: any) => new EstimateChecklistItem(i));
    } else {
      this.allEstimateChecklistItems = [];
    }
  }
}

/********************************************************************************************/

export class EstimateChecklistItem {
  id: string;
  name: I18nString;
  sequenceNumber: number;
  masterServiceItem: MasterServiceItem;
  estimateChecklistGroup: EstimateChecklistGroup;

  constructor(attrs: any = {}) {
    this.id = attrs.id;
    this.name = new I18nString(attrs.name);
    this.sequenceNumber = attrs.sequenceNumber;
    this.masterServiceItem = attrs.masterServiceItem ? new MasterServiceItem(attrs.masterServiceItem) : null;
    this.estimateChecklistGroup = attrs.estimateChecklistGroup ? new EstimateChecklistGroup(attrs.estimateChecklistGroup) : null;
  }
}

/********************************************************************************************/

export class Estimate {
  id: string;
  locale: string;
  // eslint-disable-next-line id-blacklist
  number: number;
  notes: string;
  currentPerformanceLevel: number;
  allEstimateTiers: EstimateTier[];
  estimatedOn: Moment;
  expiresOn: Moment;
  piano: Piano;
  client: Client;
  contact: Contact;
  pianoPhoto: Photo;
  createdBy: User;
  clientUrl: string;
  isArchived: boolean;
  tags: string[];
  recommendedTierTotal: number;

  constructor(attrs: any = {}) {
    this.id = attrs.id;
    // eslint-disable-next-line id-blacklist
    this.number = attrs.number;
    this.clientUrl = attrs.clientUrl;
    this.notes = attrs.notes || null;
    this.allEstimateTiers = [];
    this.isArchived = !!attrs.isArchived;
    this.tags = attrs.tags || [];

    this.locale = attrs.locale;
    if (!this.locale && attrs.client?.defaultClientLocalization) {
      this.locale = attrs.client.defaultClientLocalization.locale;
    }

    this.currentPerformanceLevel = null;
    if (typeof attrs.currentPerformanceLevel === 'number') this.currentPerformanceLevel = attrs.currentPerformanceLevel;

    if (attrs.estimatedOn) this.estimatedOn = moment(attrs.estimatedOn);
    if (attrs.expiresOn) this.expiresOn = moment(attrs.expiresOn);
    if (attrs.piano) this.piano = new Piano(attrs.piano);
    if (attrs.client) this.client = new Client(attrs.client);
    if (attrs.contact) this.contact = new Contact(attrs.contact);
    if (attrs.pianoPhoto) this.pianoPhoto = new Photo(attrs.pianoPhoto);
    if (attrs.createdBy) this.createdBy = new User(attrs.createdBy);
    if (attrs.recommendedTierTotal) this.recommendedTierTotal = attrs.recommendedTierTotal;

    if (attrs.allEstimateTiers) {
      this.allEstimateTiers = attrs.allEstimateTiers.map((t: any) => new EstimateTier(t));
      if (!this.primaryTier && this.allEstimateTiers.length > 0) {
        this.allEstimateTiers[0].isPrimary = true;
      }
    } else {
      this.allEstimateTiers = [];
    }
  }

  get hasConditionReport(): boolean {
    return typeof this.currentPerformanceLevel === 'number';
  }

  get primaryTier(): EstimateTier {
    if (this.allEstimateTiers.length === 0) return null;
    const primaryTiers = this.allEstimateTiers.filter(t => t.isPrimary);
    if (primaryTiers.length === 0) return null;
    return primaryTiers[0];
  }

  get primaryTotal(): number {
    if (this.primaryTier) return this.primaryTier.total;
    return 0;
  }

  get clientDisplayName(): string {
    if (this.client?.companyName) {
      return this.client.companyName;
    } else if (this.contact) {
      return this.contact.displayName;
    } else if (this.client) {
      return this.client.displayName;
    } else {
      return '';
    }
  }

  get isExpired(): boolean {
    if (this.expiresOn && this.expiresOn.isBefore(moment().startOf('day'))) {
      return true;
    }
    return false;
  }

  get allItemTaxes(): ItemTax[] {
    const itemTaxes: {[key: string]: ItemTax} = {};
    this.allEstimateTiers.forEach(tier => {
      tier.allEstimateTierGroups.forEach(group => {
        group.allEstimateTierItems.forEach(item => {
          item.taxes?.forEach(itemTax => itemTaxes[itemTax.getCombinedId()] = itemTax);
        });
      });
      tier.allUngroupedEstimateTierItems.forEach(item => {
        item.taxes?.forEach(itemTax => itemTaxes[itemTax.getCombinedId()] = itemTax);
      });
    });
    return Object.values(itemTaxes);
  }
}

/********************************************************************************************/

export class EstimateTier {
  id: string;
  isPrimary: boolean;
  allEstimateTierGroups: EstimateTierGroup[];
  allUngroupedEstimateTierItems: EstimateTierItem[];
  targetPerformanceLevel: number;
  allowSelfSchedule: boolean;
  sequenceNumber: number;
  notes: string;
  recommendation: Recommendation;
  apiTotal?: number;

  constructor(attrs: any = {}) {
    this.id = attrs.id;
    this.isPrimary = attrs.isPrimary;
    this.sequenceNumber = attrs.sequenceNumber;
    this.notes = attrs.notes;
    this.targetPerformanceLevel = attrs.targetPerformanceLevel;
    this.allowSelfSchedule = attrs.allowSelfSchedule;
    if (attrs.recommendation) this.recommendation = new Recommendation(attrs.recommendation);

    this.allEstimateTierGroups = [];
    if (attrs.allEstimateTierGroups) {
      this.allEstimateTierGroups = attrs.allEstimateTierGroups.map((g: any) => new EstimateTierGroup(g));
    }
    this.allUngroupedEstimateTierItems = [];
    if (attrs.allUngroupedEstimateTierItems) {
      this.allUngroupedEstimateTierItems = attrs.allUngroupedEstimateTierItems.map((i: any) => new EstimateTierItem(i));
    }

    this.apiTotal = attrs.apiTotal || attrs.total;
  }

  get subtotal(): number {
    let total = this.allUngroupedEstimateTierItems.reduce((acc, item) => acc + item.subtotal, 0);
    total += this.allEstimateTierGroups.reduce((acc, group) => acc + group.subtotal, 0);
    return total;
  }

  get taxTotal(): number {
    let total = this.allUngroupedEstimateTierItems.reduce((acc, item) => acc + item.taxTotal, 0);
    total += this.allEstimateTierGroups.reduce((acc, group) => acc + group.taxTotal, 0);
    return total;
  }

  get taxes(): {[name: string]: number} {
    let taxTotals: {[name: string]: number} = {};
    const sumItem = (item: EstimateTierItem) => {
      (item.taxes || []).forEach(tax => {
        if (taxTotals[tax.name] === undefined) {
          taxTotals[tax.name] = 0;
        }
        taxTotals[tax.name] += tax.total;
      });
    };

    this.allUngroupedEstimateTierItems.forEach(sumItem);
    this.allEstimateTierGroups.forEach(group => group.allEstimateTierItems.forEach(sumItem));
    return taxTotals;
  }

  get total(): number {
    let total = this.allUngroupedEstimateTierItems.reduce((acc, item) => acc + item.total, 0);
    total += this.allEstimateTierGroups.reduce((acc, group) => acc + group.total, 0);
    return total;
  }

  get isEmpty(): boolean {
    return (
      this.allUngroupedEstimateTierItems.length +
      this.allEstimateTierGroups.reduce((acc, group) => acc + group.allEstimateTierItems.length, 0)
    ) === 0;
  }

  get duration(): number {
    let totalDuration: number = 0;
    this.allEstimateTierGroups.forEach(group => group.allEstimateTierItems.forEach(item => {
      if (item.type === MASTER_SERVICE_ITEM_TYPE.LABOR_HOURLY) {
        return totalDuration += item.duration * (item.quantity / 100);
      } else {
        return totalDuration += item.duration;
      }
    }));
    this.allUngroupedEstimateTierItems.forEach(item => {
      if (item.type === MASTER_SERVICE_ITEM_TYPE.LABOR_HOURLY) {
        return totalDuration += item.duration * (item.quantity / 100);
      } else {
        return totalDuration += item.duration;
      }
    });
    return totalDuration;
  }
}

/********************************************************************************************/

export class EstimateTierGroup {
  id: string;
  allEstimateTierItems: EstimateTierItem[];
  sequenceNumber: number;
  name: string;

  constructor(attrs: any) {
    this.id = attrs.id;
    this.allEstimateTierItems = [];
    this.sequenceNumber = attrs.sequenceNumber;
    this.name = attrs.name || null;
    if (attrs.allEstimateTierItems) {
      this.allEstimateTierItems = attrs.allEstimateTierItems.map((i: any) => new EstimateTierItem(i));
    } else {
      this.allEstimateTierItems = [];
    }
  }

  get subtotal(): number {
    return this.allEstimateTierItems.reduce((acc, item) => acc + item.subtotal, 0);
  }

  get taxTotal(): number {
    return this.allEstimateTierItems.reduce((acc, item) => acc + item.taxTotal, 0);
  }

  get total(): number {
    return this.allEstimateTierItems.reduce((acc, item) => acc + item.total, 0);
  }
}

/********************************************************************************************/

export class EstimateTierItem {
  id: string;
  name: string;
  sequenceNumber: number;
  description: string;
  educationDescription: string;
  quantity: number;
  amount: number;
  duration: number;
  type: MASTER_SERVICE_ITEM_TYPE;
  externalUrl: string;
  isTaxable: boolean;
  isTuning: boolean;
  taxes: ItemTax[];
  allEstimateTierItemPhotos: EstimateTierItemPhoto[];
  subtotal: number;
  taxTotal: number;
  total: number;
  masterServiceItem?: MasterServiceItem;
  estimateTierGroup?: EstimateTierGroup;

  constructor(attrs: any = {}) {
    this.id = attrs.id;
    this.name = attrs.name;
    this.description = attrs.description;
    this.educationDescription = attrs.educationDescription;
    this.sequenceNumber = attrs.sequenceNumber;
    this.quantity = attrs.quantity || 100;
    this.amount = attrs.amount || 0;
    this.duration = attrs.duration || 0;
    this.type = attrs.type || MASTER_SERVICE_ITEM_TYPE.LABOR_FIXED_RATE;
    this.externalUrl = attrs.externalUrl;
    this.isTaxable = attrs.isTaxable;
    this.isTuning = attrs.isTuning;
    this.taxes = null;
    if (attrs.taxes) {
      this.taxes = attrs.taxes.map((t: any) => new ItemTax(t));
    }
    this.subtotal = attrs.subtotal;
    this.taxTotal = attrs.taxTotal;
    this.total = attrs.total;
    if (attrs.allEstimateTierItemPhotos) {
      this.allEstimateTierItemPhotos = attrs.allEstimateTierItemPhotos.map((p: any) => new EstimateTierItemPhoto(p));
    } else {
      this.allEstimateTierItemPhotos = [];
    }
    this.masterServiceItem = attrs.masterServiceItem ? new MasterServiceItem(attrs.masterServiceItem) : null;
    this.estimateTierGroup = attrs.estimateTierGroup ? new EstimateTierGroup(attrs.estimateTierGroup) : null;
  }

  // IMPORTANT NOTE:
  // These calculations are ONLY used for display purposes.  The mutation API does not accept totals, and calculates
  // the real totals on the server.   To prevent slight errors, be sure this code stays in sync with the
  // way that the mutation API calculates totals.
  calculateTotals() {
    this.subtotal = 0;
    this.taxTotal = 0;
    this.total = 0;

    if (!this.taxes || Object.keys(this.taxes).length === 0) {
      this.isTaxable = false;
    }

    if (this.type === MASTER_SERVICE_ITEM_TYPE.LABOR_HOURLY) {
      this.subtotal = roundAwayFromZero((this.amount || 0) * ((this.duration || 0) / 60) * (this.quantity || 0) / 100, 0);
    } else {
      this.subtotal = roundAwayFromZero((this.amount || 0) * (this.quantity || 0) / 100, 0);
    }
    if (this.isTaxable) {
      this.taxes.forEach((tax: ItemTax) => {
        const itemTaxTotal = roundAwayFromZero(this.subtotal * tax.rate / 100000, 0);
        tax.total = itemTaxTotal;
        this.taxTotal += itemTaxTotal;
      });
    }
    this.total += this.subtotal + this.taxTotal;
  }

  get hasEducation(): boolean {
    return !!this.educationDescription || !!this.externalUrl;
  }
}

/********************************************************************************************/

export class EstimateTierItemPhoto {
  id: string;
  sequenceNumber: number;
  pianoPhoto: Photo;

  constructor(attrs: any = {}) {
    this.id = attrs.id;
    this.sequenceNumber = attrs.sequenceNumber;
    this.pianoPhoto = new Photo(attrs.pianoPhoto);
  }
}
/********************************************************************************************/

export class Recommendation {
  id: string;
  type: RECOMMENDATION_TYPE;
  name: I18nString;
  explanation: I18nString;
  dependentEstimateTierCount: number;
  isArchived: boolean;

  constructor(attrs: any = {}) {
    this.id = attrs.id;
    this.type = attrs.type as RECOMMENDATION_TYPE;
    if (attrs.name) this.name = new I18nString(attrs.name);
    if (attrs.explanation) this.explanation = new I18nString(attrs.explanation);
    this.isArchived = attrs.isArchived;
    this.dependentEstimateTierCount = attrs.dependentEstimateTierCount;
  }
}

/********************************************************************************************/

export class SystemNotification {
  id: string;
  alertType: SYSTEM_NOTIFICATION_ALERT_TYPE;
  type: SYSTEM_NOTIFICATION_TYPE;
  subType: SYSTEM_NOTIFICATION_SUB_TYPE;
  groupingToken: string;
  message: string;
  client: Client;
  piano: Piano;
  invoice: Invoice;
  estimate: Estimate;
  isDismissable: boolean;

  constructor(attrs: any = {}) {
    this.id = attrs.id;
    this.groupingToken = attrs.groupingToken;
    this.alertType = attrs.alertType as SYSTEM_NOTIFICATION_ALERT_TYPE;
    this.type = attrs.type as SYSTEM_NOTIFICATION_TYPE;
    this.subType = attrs.subType as SYSTEM_NOTIFICATION_SUB_TYPE;
    this.message = attrs.message;
    this.isDismissable = attrs.isDismissable;

    this.client = (attrs.client) ? new Client(attrs.client) : null;
    this.piano = (attrs.piano) ? new Piano(attrs.piano) : null;
    this.invoice = (attrs.invoice) ? new Invoice(attrs.invoice) : null;
    this.estimate = (attrs.estimate) ? new Estimate(attrs.estimate) : null;
  }
}

/********************************************************************************************/

export class RemoteCalendar {
  id: string;
  uniqueCalendarId: string;
  sourceUrl: string;
  lastPolledAt: Moment | null;
  nextSyncAfter: Moment | null;
  isSyncInProgress: boolean;
  importAsBusy: boolean;
  impactsAvailability: boolean;
  calendarDisplayName: string;
  remoteCalendarName: string;
  type: CALENDAR_INTEGRATION_TYPE;
  remoteCalendarIntegration?: RemoteCalendarIntegration;

  constructor(attrs: any = {}) {
    Object.assign(this, attrs);
    this.lastPolledAt = attrs.lastPolledAt ? moment(attrs.lastPolledAt) : null;
    this.nextSyncAfter = attrs.nextSyncAfter ? moment(attrs.nextSyncAfter) : null;
  }
}

/********************************************************************************************/

export class GoogleCalendar {
  id: string;
  name: string;

  constructor(attrs: any = {}) {
    Object.assign(this, attrs);
  }
}

/********************************************************************************************/

export class RemoteCalendarIntegration {
  id: string;
  name: string;
  type: CALENDAR_INTEGRATION_TYPE;
  isLinked: boolean;
  linkedAccountEmail: string;
  allRemoteCalendars: RemoteCalendar[];
  allGoogleCalendars: GoogleCalendar[];

  constructor(attrs: any = {}) {
    Object.assign(this, attrs);
    this.allRemoteCalendars = (attrs.allRemoteCalendars || []).map((rc: any) => new RemoteCalendar(rc));
    this.allGoogleCalendars = (attrs.allGoogleCalendars || []).map((rc: any) => new GoogleCalendar(rc));
  }
}

/********************************************************************************************/

export class RemoteQuickbooksOnlineIntegration {
  isConnected: boolean;

  constructor(attrs: any = {}) {
    this.isConnected = attrs?.isConnected;
  }
}

/********************************************************************************************/

export class RemoteAccountingIntegrations {
  quickbooksOnline: RemoteQuickbooksOnlineIntegration;

  constructor(attrs: any = {}) {
    this.quickbooksOnline = new RemoteQuickbooksOnlineIntegration(attrs.quickbooksOnline || {});
  }
}

/********************************************************************************************/

export class TemplatePreview {
  message: string;
  subject: string;
  error: string;

  constructor(attrs: any = {}) {
    this.message = attrs.message;
    this.subject = attrs.subject;
    this.error = attrs.error;
  }
}

/********************************************************************************************/

export class EmailSubscription {
  tags: string[];
  type: EMAIL_SUBSCRIPTION_TYPE;
  emailAddress: string;
  createdAt?: Moment;
  error: string;

  constructor(attrs: any = {}) {
    Object.assign(this, attrs);
    if (attrs?.createdAt) {
      this.createdAt = moment(attrs.createdAt);
    }
  }
}

/********************************************************************************************/

export class SharedCalendar {
  id: string;
  showEventDetails: boolean;
  includeClientPianoDetails: boolean;
  eventTypesToShare: EVENT_TYPE[];
  sharedToEmailAddress: string;
  hasBeenAccepted: boolean;
  createdAt?: Moment;
  remoteCalendarUserName: string;
  remoteCalendarCompanyName: string;

  constructor(attrs: any = {}) {
    Object.assign(this, attrs);
    if (attrs?.createdAt) {
      this.createdAt = moment(attrs.createdAt);
    }
  }
}

/********************************************************************************************/

export class QuickbooksSyncInvoice {
  invoice: Invoice;
  status: QUICKBOOKS_SYNC_STATUS;
  errors: QuickbooksSyncError[];

  constructor(attrs: any = {}) {
    this.invoice = attrs.invoice ? new Invoice(attrs.invoice) : null;
    this.status = attrs.status;
    this.errors = attrs.errors ? attrs.errors.map((e: any) => new QuickbooksSyncError(e)) : null;
  }
}

/********************************************************************************************/

export class QuickbooksSyncError {
  title: string;
  description: string;
  url?: string;
  type?: QUICKBOOKS_SYNC_ERROR_TYPE;

  constructor(attrs: any = {}) {
    this.title = attrs.title;
    this.description = attrs.description;
    this.url = attrs.url;
    this.type = attrs.type;
  }
}

/********************************************************************************************/

export class QuickbooksSyncNotice {
  description: string;
  type?: QUICKBOOKS_SYNC_NOTICE_TYPE;

  constructor(attrs: any = {}) {
    this.description = attrs.description;
    this.type = attrs.type;
  }
}

/********************************************************************************************/

export class QuickbooksSyncStripePayout {
  id: string;
  status: QUICKBOOKS_SYNC_STATUS;
  stripePayoutDate: Moment;
  stripePayoutAmount: number;
  stripePayoutCurrency: Currency;
  errors: QuickbooksSyncError[];
  refundNotices: string[];

  constructor(attrs: any = {}) {
    this.id = attrs.id;
    this.status = attrs.status;
    this.stripePayoutDate = attrs.stripePayoutDate ? moment(attrs.stripePayoutDate) : null;
    this.stripePayoutCurrency = attrs.stripePayoutCurrency || null;
    this.stripePayoutAmount = attrs.stripePayoutAmount;
    this.errors = attrs.errors ? attrs.errors.map((e: any) => new QuickbooksSyncError(e)) : [];
    this.refundNotices = attrs.refundNotices || [];
  }
}

/********************************************************************************************/

export class QuickbooksSyncQuickbooksPayment {
  id: string;
  status: QUICKBOOKS_SYNC_STATUS;
  errors: QuickbooksSyncError[];

  constructor(attrs: any = {}) {
    this.id = attrs.id;
    this.status = attrs.status;
    this.errors = attrs.errors ? attrs.errors.map((e: any) => new QuickbooksSyncError(e)) : [];
  }
}

/********************************************************************************************/

export class QuickbooksSyncBatch {
  id: string;
  initiatedBy: User;
  initiatedAt: Moment;
  completedAt?: Moment;
  status: QUICKBOOKS_BATCH_SYNC_STATUS;
  batchLevelErrors: QuickbooksSyncError[];
  batchLevelNotices: QuickbooksSyncNotice[];
  syncInvoices: QuickbooksSyncInvoice[];
  syncStripePayouts: QuickbooksSyncStripePayout[];
  syncQuickbooksPayments: QuickbooksSyncQuickbooksPayment[];

  constructor(attrs: any = {}) {
    this.id = attrs.id;
    this.initiatedBy = attrs.initiatedBy ? new User(attrs.initiatedBy) : null;
    this.initiatedAt = attrs.initiatedAt ? moment(attrs.initiatedAt) : null;
    this.completedAt = attrs.completedAt ? moment(attrs.completedAt) : null;
    this.status = attrs.status;
    this.batchLevelErrors = attrs.batchLevelErrors ? attrs.batchLevelErrors.map((e: any) => new QuickbooksSyncError(e)) : [];
    this.batchLevelNotices = attrs.batchLevelNotices ? attrs.batchLevelNotices.map((e: any) => new QuickbooksSyncNotice(e)) : [];
    if (attrs.syncInvoices) {
      if (attrs.syncInvoices.nodes) {
        this.syncInvoices = attrs.syncInvoices.nodes.map((i: any) => new QuickbooksSyncInvoice(i));
      } else {
        this.syncInvoices = attrs.syncInvoices.map((i: any) => new QuickbooksSyncInvoice(i));
      }
    } else {
      this.syncInvoices = [];
    }
    if (attrs.syncStripePayouts) {
      if (attrs.syncStripePayouts.nodes) {
        this.syncStripePayouts = attrs.syncStripePayouts.nodes.map((i: any) => new QuickbooksSyncStripePayout(i));
      } else {
        this.syncStripePayouts = attrs.syncStripePayouts.map((i: any) => new QuickbooksSyncStripePayout(i));
      }
    } else {
      this.syncStripePayouts = [];
    }
    if (attrs.syncQuickbooksPayments) {
      if (attrs.syncQuickbooksPayments.nodes) {
        this.syncQuickbooksPayments = attrs.syncQuickbooksPayments.nodes.map((i: any) => new QuickbooksSyncQuickbooksPayment(i));
      } else {
        this.syncQuickbooksPayments = attrs.syncQuickbooksPayments.map((i: any) => new QuickbooksSyncQuickbooksPayment(i));
      }
    } else {
      this.syncQuickbooksPayments = [];
    }
  }

  progress(rootStore: RootStore): number {
    if (this.status === QUICKBOOKS_BATCH_SYNC_STATUS.NOT_STARTED) {
      console.log(`progress: [NOT STARTED]: 5`);
      return 5;
    }
    if (this.status === QUICKBOOKS_BATCH_SYNC_STATUS.RUNNING_INVOICE_SYNC) {
      let count = 0;
      this.syncInvoices.forEach(invoice => {
        if (![QUICKBOOKS_SYNC_STATUS.NOT_STARTED, QUICKBOOKS_SYNC_STATUS.RUNNING].includes(invoice.status)) {
          count += 1;
        }
      });
      // If stripe is enabled, we may be syncing payouts as well, so leave 10% of the
      // progress indicator for syncing payouts.  Otherwise, we'll never have that step
      // so don't leave room for it in the progress indicator.
      const maxPercent = rootStore.company.stripeIntegrationEnabled ? 65 : 75;
      let progress = 15;
      if (this.syncInvoices.length > 0) {
        progress = 15 + Math.ceil(count / this.syncInvoices.length * maxPercent);
        console.log(`progress [INVOICE SYNC]: 15 + Math.ceil(${count} / ${this.syncInvoices.length} * ${maxPercent}) = ${progress}`);
      } else {
        console.log("progress [INVOICE SYNC]: NO INVOICES = 15");
      }
      return progress;
    }
    if (this.status === QUICKBOOKS_BATCH_SYNC_STATUS.RUNNING_STRIPE_PAYOUT_SYNC) {
      let count = 0;
      this.syncStripePayouts.forEach(payout => {
        if (![QUICKBOOKS_SYNC_STATUS.NOT_STARTED, QUICKBOOKS_SYNC_STATUS.RUNNING].includes(payout.status)) {
          count += 1;
        }
      });
      let progress = 80;
      if (this.syncStripePayouts.length > 0) {
        progress = 80 + Math.ceil(count / this.syncStripePayouts.length * 10);
        console.log(`progress [PAYOUTS]: 80 + Math.ceil(${count} / ${this.syncStripePayouts.length} * 10) = ${progress}`);
      } else {
        console.log("progress [PAYOUTS]: NO PAYOUTS = 80");
      }
      return progress;
    }
    if (this.status === QUICKBOOKS_BATCH_SYNC_STATUS.RUNNING_QBO_PAYMENT_SYNC) {
      let count = 0;
      this.syncQuickbooksPayments.forEach(payment => {
        if (![QUICKBOOKS_SYNC_STATUS.NOT_STARTED, QUICKBOOKS_SYNC_STATUS.RUNNING].includes(payment.status)) {
          count += 1;
        }
      });
      // If stripe is enabled, we may be syncing invoices as well, so leave room for that
      // in the percentage progress calculation.
      const maxPercent = rootStore.company.stripeIntegrationEnabled ? 90 : 80;
      let progress = maxPercent;
      if (this.syncQuickbooksPayments.length > 0) {
        progress = maxPercent + Math.ceil(count / this.syncQuickbooksPayments.length * 10);
        console.log(`progress [PAYMENTS]: ${maxPercent} + Math.ceil(${count} / ${this.syncQuickbooksPayments.length} * 10) = ${progress}`);
      } else {
        console.log(`progress [PAYMENTS]: NO PAYMENTS = ${progress}`);
      }
      return progress;
    }
    if (this.status === QUICKBOOKS_BATCH_SYNC_STATUS.COMPLETE || this.status === QUICKBOOKS_BATCH_SYNC_STATUS.ERROR) {
      console.log("progress [DONE]: 100%");
      return 100;
    }
    console.log("progress [ERROR]: 0%");
    return 0;
  }
}

/********************************************************************************************/

export class RemoteTax {
  id: string;
  name: string;
  rate: number;
  description: boolean;

  constructor(attrs: any = {}) {
    this.id = attrs.id;
    this.name = attrs.name;
    this.rate = attrs.rate;
    this.description = attrs.description;
  }

  getLabel() {
    return `${this.name} (${formatPercent(this.rate, 1000, null)})`;
  }
}

/********************************************************************************************/

export class RemoteAccountingTaxMapping {
  id: string;
  type: REMOTE_ACCOUNTING_TAX_MAPPING_TYPE;
  externalTaxId: string;
  taxes: Tax[];

  constructor(attrs: any = {}) {
    this.id = attrs.id;
    this.type = attrs.type;
    this.externalTaxId = attrs.externalTaxId;
    this.taxes = attrs.taxes?.map((t: any) => new Tax(t)) || [];
  }
}

/********************************************************************************************/

export class RemoteQuickbooksAccount {
  id: string;
  name: string;
  description: string;
  accountNum: string;
  accountType: string;
  accountSubType: string;

  constructor(attrs: any = {}) {
    this.id = attrs.id;
    this.name = attrs.name;
    this.description = attrs.description;
    this.accountNum = attrs.accountNum;
    this.accountType = attrs.accountType;
    this.accountSubType = attrs.accountSubType;
  }

  getLabel() {
    return `${this.accountNum ? `${this.accountNum} - ` : ''}${this.name}`;
  }
}

/********************************************************************************************/

export class QuickbooksAccountMapping {
  id: string;
  quickbooksAccountId: string;
  type: QUICKBOOKS_ACCOUNT_TYPE;

  constructor(attrs: any = {}) {
    this.id = attrs.id;
    this.quickbooksAccountId = attrs.quickbooksAccountId;
    this.type = attrs.type;
  }
}

/********************************************************************************************/

export class LegalContract {
  id: string;
  title: I18nString;
  type: LEGAL_CONTRACT_TYPE;
  version: string;
  explanation: I18nString;
  contractDate: Moment;
  contractHtml: string;
  signedAt: Moment;
  signedBy: User;

  constructor(attrs: any = {}) {
    this.id = attrs.id;
    this.title = attrs.title ? new I18nString(attrs.title) : null;
    this.type = attrs.type;
    this.version = attrs.version;
    this.explanation = attrs.explanation ? new I18nString(attrs.explanation) : null;
    this.contractDate = attrs.contractDate ? moment(attrs.contractDate) : null;
    this.contractHtml = attrs.contractHtml;
    this.signedBy = attrs.signedBy ? new User(attrs.signedBy) : null;
    this.signedAt = attrs.signedAt ? moment(attrs.signedAt) : null;
  }

  isSigned() {
    return !!this.signedBy && !!this.signedAt;
  }

  getNumericVersion() {
    return parseInt(this.version.split(".").map(n => `${parseInt(n) * 1000}`).join());
  }
}

/********************************************************************************************/

export class LegalContractGroup {
  type: LEGAL_CONTRACT_TYPE;
  versions: LegalContract[];

  constructor(type: LEGAL_CONTRACT_TYPE) {
    this.type = type;
    this.versions = [];
  }

  getLatest(): LegalContract {
    let versions = this.versions.slice();
    versions.sort((a, b) => {
      if (a.getNumericVersion() < b.getNumericVersion()) return -1;
      if (a.getNumericVersion() > b.getNumericVersion()) return 1;
      return 0;
    });
    return versions[0];
  }

  static createFromContracts(contracts: LegalContract[]): LegalContractGroup[] {
    let groups: {[key: string]: LegalContractGroup} = {};
    contracts.forEach(contract => {
      if (!groups[contract.type]) {
        groups[contract.type] = new LegalContractGroup(contract.type);
      }
      groups[contract.type].versions.push(new LegalContract(contract));
    });
    return Object.values(groups);
  }
}

/********************************************************************************************/

export class ErrorLog {
  id: string;
  type: ERROR_LOG_TYPE;
  client: Client;
  message: string;
  occurrenceCount: number;
  createdAt: Moment;
  updatedAt: Moment;

  constructor(attrs: any) {
    this.id = attrs.id;
    this.type = attrs.type;
    this.client = attrs.client ? new Client(attrs.client) : null;
    this.message = attrs.message;
    this.occurrenceCount = attrs.occurrenceCount;
    this.createdAt = attrs.createdAt ? moment(attrs.createdAt) : null;
    this.updatedAt = attrs.updatedAt ? moment(attrs.updatedAt) : null;
  }
}

/********************************************************************************************/

export class BigBoyPantsUser {
  id: string;
  username: string;
  apiKey: string;
  roles: BIG_BOY_PANTS_ROLES[];
  expiresAt: Moment;

  constructor(attrs: any) {
    this.id = attrs.id;
    this.username = attrs.username;
    this.apiKey = attrs.apiKey;
    this.roles = attrs.roles || [];
    this.expiresAt = moment(attrs.expiresAt);
  }

  hasAccess(perm: BIG_BOY_PANTS_ROLES): boolean {
    return this.roles.includes(perm);
  }
}

/********************************************************************************************/

export class BulkAction {
  id: string;
  type: BULK_ACTION_TYPE;
  description: string;
  totalCount: number;
  totalComplete: number;
  enqueuedBy: User;
  errorMessage: string;
  args: any;
  status: BULK_ACTION_STATUS;
  createdAt: Moment;

  constructor(attrs: any) {
    this.id = attrs.id;
    this.type = attrs.type;
    this.description = attrs.description;
    this.totalCount = attrs.totalCount;
    this.totalComplete = attrs.totalComplete;
    this.enqueuedBy = new User(attrs.enqueuedBy);
    this.errorMessage = attrs.errorMessage;
    this.status = attrs.status;

    try {
      this.args = JSON.parse(attrs.jsonArguments);
    } catch {
      this.args = {};
    }

    this.createdAt = moment(attrs.createdAt);
  }

  get percentComplete(): number {
    if (!this.totalCount || !this.totalComplete) return 0;
    return this.totalComplete / this.totalCount * 100;
  }

  get successMessage(): string | null {
    return null;
  }
}

/********************************************************************************************/

export class JakklopsPricingPlan {
  id: string;
  name: string;
  activePianoLimit: number;
  features: JAKKLOPS_FEATURE[];
  amount: number;
  currency: Currency;
  interval: PRICING_INTERVAL;

  constructor(attrs: any) {
    this.id = attrs.id;
    this.name = attrs.name;
    this.activePianoLimit = attrs.activePianoLimit;
    this.features = attrs.features.map((f: any) => {
      if (typeof f === 'object') {
        return f.type;
      } else {
        return f;
      }
    });
    this.amount = attrs.amount;
    this.currency = attrs.currency;
    this.interval = attrs.interval.toUpperCase();
  }
}

/********************************************************************************************/

export class JakklopsDiscount {
  id: string;
  description: string;
  endsOn: Moment;

  constructor(attrs: any) {
    this.id = attrs.id;
    this.description = attrs.description;
    this.endsOn = attrs.endsOn ? moment(attrs.endsOn) : null;
  }
}

/********************************************************************************************/

export class JakklopsInvoiceDiscountAmount {
  id: string;
  description: string;
  amount: number;

  constructor(attrs: any) {
    this.id = attrs.id;
    this.description = attrs.description;
    this.amount = attrs.amount;
  }
}

/********************************************************************************************/

export class JakklopsBillingInvoice {
  id: string;
  currency: Currency;
  lines: JakklopsBillingInvoiceLine[];
  discountAmounts: JakklopsInvoiceDiscountAmount[];
  nextPaymentAttempt: Moment;
  subtotal: number;
  total: number;
  amountDue: number;
  appliedBalance: number;

  constructor(attrs: any) {
    this.id = attrs.id;
    this.currency = attrs.currency;
    this.lines = attrs.lines.map((l: any) => new JakklopsBillingInvoiceLine(l));
    this.discountAmounts = attrs.discountAmounts.map((d: any) => new JakklopsInvoiceDiscountAmount(d));
    this.nextPaymentAttempt = attrs.nextPaymentAttempt ? moment(attrs.nextPaymentAttempt) : null;
    this.subtotal = attrs.subtotal;
    this.total = attrs.total;
    this.amountDue = attrs.amountDue;
    this.appliedBalance = attrs.appliedBalance;
  }
}

/********************************************************************************************/

export class JakklopsBillingInvoiceLine {
  id: string;
  description: string;
  amount: number;

  constructor(attrs: any) {
    this.id = attrs.id;
    this.description = attrs.description;
    this.amount = attrs.amount;
  }
}

/********************************************************************************************/

export class JakklopsPricingFeature {
  id: string;
  type: JAKKLOPS_FEATURE;
  name: I18nString;
  description: I18nString;
  requires: JAKKLOPS_FEATURE[];

  constructor(attrs: any) {
    this.id = attrs.id;
    this.type = attrs.type;
    this.name = new I18nString(attrs.name);
    this.description = new I18nString(attrs.description);
    this.requires = attrs.requires as JAKKLOPS_FEATURE[];
  }
}

/********************************************************************************************/

export class Affiliate {
  name: string;
  code: string;
  discountMultiplier: number;
  discountDuration: string;
  signupBlurb: string;

  constructor(attrs: any) {
    this.name = attrs.name;
    this.code = attrs.code;
    this.discountMultiplier = attrs.discountMultiplier;
    this.discountDuration = attrs.discountDuration;
    this.signupBlurb = attrs.signupBlurb;
  }
}

/********************************************************************************************/

export class AuthorizedIntegration {
  id: string;
  authorizedAt: Moment;
  name: string;
  user: User;

  constructor(attrs: any) {
    this.id = attrs.id;
    this.name = attrs.name;
    this.authorizedAt = moment(attrs.authorizedAt);
    this.user = new User(attrs.user);
  }
}

/********************************************************************************************/

export type ClientNoteType = 'timeline' | 'preference' | 'personal';

/********************************************************************************************/

export class MailchimpAudience {
  id: string;
  name: string;

  constructor(attrs: any) {
    this.id = attrs.id;
    this.name = attrs.name;
  }
}

/********************************************************************************************/

export class SchedulerV2Availability {
  id: string;
  userId: string;
  name: string;
  serviceAreaName: string;
  startDate: string;
  startTime: string;
  startOfDayType: START_OF_DAY_TYPE;
  startOfDayLocation: SchedulerV2Location;
  endDate: string;
  endTime: string;
  endOfDayType: END_OF_DAY_TYPE;
  endOfDayLocation: SchedulerV2Location;
  floatingDowntimeRules: SchedulerV2FloatingDowntimeRule[];
  recurrenceRule: string;
  includeDates: string[];
  excludeDates: string[];
  roundingMinutes: number;
  isExclusive: boolean;
  maxAppointmentsPerDay?: number;
  serviceAreaId: string;
  preferredSlotTimes: string[];
  preferredSlotPolicy: PREFERRED_SLOT_POLICIES;
  adjustPreferredSlots: boolean;
  serviceArea?: SchedulerV2ServiceArea;

  constructor(attrs: any) {
    this.id = attrs.id;
    this.userId = attrs.userId;
    this.name = attrs.name;
    this.serviceAreaName = attrs.serviceAreaName || attrs.serviceArea?.name;
    this.startDate = attrs.startDate;
    this.startTime = attrs.startTime;
    this.startOfDayType = attrs.startOfDayType;
    this.startOfDayLocation = attrs.startOfDayLocation ? new SchedulerV2Location(attrs.startOfDayLocation) : null;
    this.endDate = attrs.endDate;
    this.endTime = attrs.endTime;
    this.endOfDayType = attrs.endOfDayType;
    this.endOfDayLocation = attrs.endOfDayLocation ? new SchedulerV2Location(attrs.endOfDayLocation) : null;
    this.floatingDowntimeRules = attrs.floatingDowntimeRules?.map((r: any) => new SchedulerV2FloatingDowntimeRule(r)) || [];
    this.recurrenceRule = attrs.recurrenceRule;
    this.includeDates = attrs.includeDates ? [...attrs.includeDates] : [];
    this.excludeDates = attrs.excludeDates ? [...attrs.excludeDates] : [];
    this.roundingMinutes = attrs.roundingMinutes || 5;
    this.isExclusive = !!attrs.isExclusive;
    this.maxAppointmentsPerDay = attrs.maxAppointmentsPerDay;
    this.serviceAreaId = attrs.serviceArea?.id || attrs.serviceAreaId;
    this.preferredSlotTimes = attrs.preferredSlotTimes || [];
    this.preferredSlotPolicy = attrs.preferredSlotPolicy;
    this.adjustPreferredSlots = attrs.adjustPreferredSlots;
    this.serviceArea = attrs.serviceArea ? new SchedulerV2ServiceArea(attrs.serviceArea) : null;
  }

  get displayName(): string {
    if (this.serviceAreaName) {
      if (this.name) {
        return `${this.serviceAreaName}: ${this.name}`;
      } else {
        return this.serviceAreaName;
      }
    } else {
      return this.name;
    }
  }

  startMoment(timezone: string): Moment {
    return moment.tz(`${moment().format('YYYY-MM-DD')} ${this.startTime}`, 'YYYY-MM-DD HH:mm', timezone);
  }

  endMoment(timezone: string): Moment {
    return moment.tz(`${moment().format('YYYY-MM-DD')} ${this.endTime}`, 'YYYY-MM-DD HH:mm', timezone);
  }

  inactiveReasonOnDate(dateStr: string, allAvailabilities: SchedulerV2Availability[]): MessageDescriptor {
    // if the date is before the start date, it's not active
    if (dateStr < this.startDate) return /*ALLOW NAKED*/MSG_availabilityInactiveReasonBeforeStartDate;
    // if the date is after the end date, it's not active
    if (dateStr > this.endDate) return /*ALLOW NAKED*/MSG_availabilityInactiveReasonAfterEndDate;
    // if the date is in the exclude dates, it's not active
    if (this.excludeDates.includes(dateStr)) return /*ALLOW NAKED*/MSG_availabilityInactiveReasonExcludedDate;
    // if this is not exclusive, and there are other exclusive availabilities that span this date, it's not active
    if (!this.isExclusive) {
      const otherExclusiveAvailabilities = allAvailabilities.filter(a => a.isExclusive && a.id !== this.id);
      for (let i = 0; i < otherExclusiveAvailabilities.length; i++) {
        const otherAvailability = otherExclusiveAvailabilities[i];
        if (dateStr >= otherAvailability.startDate && (!otherAvailability.endDate || dateStr <= otherAvailability.endDate)) {
          return /*ALLOW NAKED*/MSG_availabilityInactiveReasonOtherExclusiveAvailability;
        }
      }
    }
    // if the date is in the include dates, it's active
    if (this.includeDates.includes(dateStr)) return null;
    // if the date is in the recurrence rule, it's active
    if (this.recurrenceRule) {
      const rule = buildAvailabilityRRule(this);
      const dates = rule.between(moment.utc(dateStr).startOf('day').subtract(1, 'day').toDate(), moment.utc(dateStr).endOf('day').toDate());
      if (dates.map(d => moment.utc(d).format('YYYY-MM-DD')).includes(dateStr)) {
        return null;
      }
    }
    // otherwise, it's not active
    return /*ALLOW NAKED*/MSG_availabilityInactiveReasonNotInRecurrenceRule;
  }
}

/********************************************************************************************/

export class SchedulerV2FloatingDowntimeRule {
  id: string;
  startTime: string;
  endTime: string;
  duration: number;

  constructor(attrs: any) {
    this.id = attrs.id;
    this.startTime = attrs.startTime;
    this.endTime = attrs.endTime;
    this.duration = attrs.duration;
  }
}

/********************************************************************************************/

export class SchedulerV2Location {
  id: string;
  type: SCHEDULER_LOCATION_TYPE;
  addressLine?: string;
  coordinates?: SchedulerV2Coordinates;
  manualLat?: string; // geocoded latitude
  manualLng?: string; // geocoded longitude

  constructor(attrs: any) {
    this.id = attrs.id;
    this.type = attrs.type;
    this.addressLine = attrs.addressLine;
    this.coordinates = attrs.coordinates ? new SchedulerV2Coordinates(attrs.coordinates) : null;
    this.manualLat = attrs.manualLat;
    this.manualLng = attrs.manualLng;
  }

  toString(): string {
    if (this.type === SCHEDULER_LOCATION_TYPE.COORDINATES) {
      return `${this.coordinates.latitude}, ${this.coordinates.longitude}`;
    } else {
      return this.addressLine;
    }
  }
}

/********************************************************************************************/

export class SchedulerV2Coordinates {
  latitude: number;
  longitude: number;

  constructor(attrs: any) {
    this.latitude = typeof attrs.longitude === 'string' ? parseFloat(attrs.latitude) : attrs.latitude;
    this.longitude = typeof attrs.longitude === 'string' ? parseFloat(attrs.longitude) : attrs.longitude;
  }

  toString(): string {
    return `${this.latitude}, ${this.longitude}`;
  }
}

/********************************************************************************************/

export class SchedulerV2ServiceArea {
  id: string;
  name: string;
  algorithm: SERVICE_AREA_ALGORITHM;
  buffer: number;
  includeTraffic: boolean;
  invalidAddressTravelTime: number;
  maxGoodTravelTimeMinutes: number;
  openDayWeight: number;
  outsideServiceAreaMinutes: number;
  polygonParameter: SchedulerV2PolygonParameter;
  radialParameter: SchedulerV2RadialParameter;
  selfScheduleMaxTravelTimes: SchedulerV2SelfScheduleMaxTravelTime[];
  travelMode: SCHEDULER_TRAVEL_MODE;
  routingPreference: SCHEDULER_ROUTING_PREFERENCE;
  isSelfSchedulable: boolean;
  user: User;

  constructor(attrs: any) {
    this.id = attrs.id;
    this.name = attrs.name || '';
    this.algorithm = attrs.algorithm || SERVICE_AREA_ALGORITHM.POLYGON;
    this.buffer = attrs.buffer || 0;
    this.includeTraffic = isNullOrUndefined(attrs.includeTraffic) ? true : !!attrs.includeTraffic;
    this.invalidAddressTravelTime = attrs.invalidAddressTravelTime ?? 30;
    this.maxGoodTravelTimeMinutes = typeof attrs.maxGoodTravelTimeMinutes === 'undefined' ? 15 : attrs.maxGoodTravelTimeMinutes;
    this.openDayWeight = attrs.openDayWeight || 0;
    this.outsideServiceAreaMinutes = attrs.outsideServiceAreaMinutes || 0;
    this.polygonParameter = attrs.polygonParameter ? new SchedulerV2PolygonParameter(attrs.polygonParameter) : null;
    this.radialParameter = attrs.radialParameter ? new SchedulerV2RadialParameter(attrs.radialParameter) : null;
    this.selfScheduleMaxTravelTimes = attrs.selfScheduleMaxTravelTimes?.map((t: any) => new SchedulerV2SelfScheduleMaxTravelTime(t)) || [{daysFromToday: 0, maxTravelTime: 25}];
    this.travelMode = attrs.travelMode || SCHEDULER_TRAVEL_MODE.DRIVING;
    this.routingPreference = attrs.routingPreference || SCHEDULER_ROUTING_PREFERENCE.ALONG_ROUTES;
    this.isSelfSchedulable = attrs.isSelfSchedulable;
    this.user = attrs.user ? new User(attrs.user) : null;
  }

  getMapUrl(rootStore: RootStore, options: {width?: number, height?: number} = {width: 100, height: 100}): string {
    let mapUrl: string = null;
    if (this.algorithm === SERVICE_AREA_ALGORITHM.POLYGON) {
      const shapes = this.polygonParameter.shapes.map(sas => {
        if (sas.type === SCHEDULER_SHAPE_TYPE.POLYGON) {
          return {
            points: sas.polygonPoints.map(p => ({lat: p.latitude, lng: p.longitude})),
            color: sas.inclusionMethod === SCHEDULER_SHAPE_INCLUSION_METHOD.EXCLUDE ? ALERT_RED_COLOR : ALERT_GREEN_COLOR
          };
        } else if (sas.type === SCHEDULER_SHAPE_TYPE.CIRCLE) {
          let radius: number = null;
          if (sas.circleRadiusUnit === SCHEDULER_DISTANCE_UNIT.MILES) {
            radius = sas.circleRadius * 1609.34;
          } else if (sas.circleRadiusUnit === SCHEDULER_DISTANCE_UNIT.KILOMETERS) {
            radius = sas.circleRadius * 1000;
          }
          return {
            lat: sas.circleCenter.latitude,
            lng: sas.circleCenter.longitude,
            radiusInMeters: radius,
            color: sas.inclusionMethod === SCHEDULER_SHAPE_INCLUSION_METHOD.EXCLUDE ? ALERT_RED_COLOR : ALERT_GREEN_COLOR
          };
        }
      });
      mapUrl = rootStore.mappingStaticMapProvider.getServiceAreaMapUrl(shapes, {width: options.width || 100, height: options.height || 100});
    } else if (this.algorithm === SERVICE_AREA_ALGORITHM.RADIAL) {
      if (this.radialParameter.center.type === SCHEDULER_LOCATION_TYPE.ADDRESS) {
        mapUrl = rootStore.mappingStaticMapProvider.getMarkerMapUrl({
          addressLine: this.radialParameter.center.addressLine,
          color: ALERT_GREEN_COLOR
        }, {width: options.width || 100, height: options.height || 100});
      } else if (this.radialParameter.center.type === SCHEDULER_LOCATION_TYPE.COORDINATES) {
        mapUrl = rootStore.mappingStaticMapProvider.getMarkerMapUrl({
          lat: this.radialParameter.center.coordinates.latitude,
          lng: this.radialParameter.center.coordinates.latitude,
          color: ALERT_GREEN_COLOR
        }, {width: options.width || 100, height: options.height || 100});
      }
    }
    return mapUrl;
  }

  // Making messages optional so it can also be used as a simple boolean check
  // for whether or not certain fields have a warning, when we don't care about
  // displaying the actual message.
  getAdditionalFieldWarnings(messages?: {fallbackVsMax: string, fallbackVsExtended: string}): Map<string, string[]> {
    let warnings = new Map();

    if (this.invalidAddressTravelTime !== undefined &&
      this.invalidAddressTravelTime !== null &&
      this.selfScheduleMaxTravelTimes &&
      this.selfScheduleMaxTravelTimes.length > 0
    ) {
      const minMaxTravelTime = Math.min(...this.selfScheduleMaxTravelTimes.map(t => t.maxTravelTime));

      if ((this.invalidAddressTravelTime || 0) <= minMaxTravelTime) {
        warnings.set('serviceArea.invalidAddressTravelTime', [messages?.fallbackVsMax || 'Pass a messages arg to specify warning messages.']);
      }
    }

    if (this.outsideServiceAreaMinutes !== undefined &&
      this.outsideServiceAreaMinutes !== null &&
      this.invalidAddressTravelTime !== undefined &&
      this.invalidAddressTravelTime !== null &&
      this.outsideServiceAreaMinutes > this.invalidAddressTravelTime
    ) {
      warnings.set('serviceArea.outsideServiceAreaMinutes', [messages?.fallbackVsExtended || 'Pass a messages arg to specify warning messages.']);
    }

    return warnings;
  }
}

/********************************************************************************************/

export class SchedulerV2PolygonParameter {
  id: string;
  shapes: SchedulerV2Shape[];

  constructor(attrs: any) {
    this.id = attrs.id;
    this.shapes = attrs.shapes?.map((s: any) => new SchedulerV2Shape(s)) || [];
  }
}

/********************************************************************************************/

export class SchedulerV2Shape {
  id: string;
  type: SCHEDULER_SHAPE_TYPE;
  name: string;
  circleCenter?: SchedulerV2Coordinates;
  circleRadius?: number;
  circleRadiusUnit?: SCHEDULER_DISTANCE_UNIT;
  polygonPoints?: SchedulerV2Coordinates[];
  inclusionMethod?: SCHEDULER_SHAPE_INCLUSION_METHOD;
  googleState?: {index: number, editable: boolean};

  constructor(attrs: any) {
    this.id = attrs.id;
    this.type = attrs.type;
    this.name = attrs.name;
    this.circleCenter = attrs.circleCenter ? new SchedulerV2Coordinates(attrs.circleCenter) : null;
    this.circleRadius = attrs.circleRadius;
    this.circleRadiusUnit = attrs.circleRadiusUnit;
    this.polygonPoints = attrs.polygonPoints ? attrs.polygonPoints.map((p: any) => new SchedulerV2Coordinates(p)) : null;
    this.inclusionMethod = attrs.inclusionMethod;

    // state for google maps
    this.googleState = {...attrs.googleState} || {editable: false, index: 0};
  }
}

/********************************************************************************************/

export class SchedulerV2RadialParameter {
  id: string;
  center: SchedulerV2Location;
  travelTime: number;

  constructor(attrs: any) {
    this.id = attrs.id;
    this.center = attrs.center ? new SchedulerV2Location(attrs.center) : null;
    this.travelTime = attrs.travelTime;
  }
}

/********************************************************************************************/

export class SchedulerV2SelfScheduleMaxTravelTime {
  daysFromToday: number;
  maxTravelTime: number;

  constructor(attrs: any) {
    this.daysFromToday = attrs.daysFromToday;
    this.maxTravelTime = attrs.maxTravelTime;
  }
}

