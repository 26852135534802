import { formatDate } from "./time";
import { formatMessage } from "./intl";
import { MSG_humidityLabel, MSG_temperatureLabel } from "../strings";
import { BRIGHT_BLUE_COLOR, RED_COLOR } from "../colors";
import { PianoMeasurement } from "../models";

const moment = require('moment-timezone');

export function getMeasurementsChartOptions(pianoMeasurements: PianoMeasurement[], options: any = {}): any {
  const temperatureData = pianoMeasurements
    .map(pm => ({x: pm.takenOn, y: pm.temperature, id: pm.id}))
    .filter(p => p.y !== null) || [];
  const humidityData = pianoMeasurements
    .map(pm => ({x: pm.takenOn, y: pm.humidity, id: pm.id}))
    .filter(p => p.y !== null) || [];

  // simple detection of fahrenheit or celsius scale
  const maxTemperature = Math.max(...temperatureData.map(d => d.y));
  const minYScale = maxTemperature >= 40 ? 10 : -10;
  const maxYScale = maxTemperature >= 40 ? 100 : 40;

  let temperaturePointRadius: number | number[] = temperatureData.length === 1 ? 5 : 0;
  let humidityPointRadius: number | number[] = humidityData.length === 1 ? 5 : 0;
  if (options.highlightId) {
    temperaturePointRadius = temperatureData.map(t => (temperaturePointRadius as number) + (t.id === options.highlightId ? 5 : 0));
    humidityPointRadius = humidityData.map(t => (humidityPointRadius as number) + (t.id === options.highlightId ? 5 : 0));
  }

  return {
    temperatureData: temperatureData,
    humidityData: humidityData,
    options: {
      responsive: true,
      animation: false,
      spanGaps: false,
      interaction: {
        intersect: false,
        mode: 'index',
      },
      scales: {
        x: {
          type: 'time',
          grid: {
            tickColor: null,
          },
          ticks: {
            autoSkip: false,
            autoSkipPadding: 20,
            callback: (value: any, index: any, ticks: any) => {
              return formatDate(moment(ticks[index].value));
            }
          }
        },
        yTemperature: {
          min: minYScale,
          max: maxYScale,
          position: 'left',
          title: {
            text: formatMessage(MSG_temperatureLabel),
            color: RED_COLOR,
            padding: 0,
            display: true
          },
          grid: {
            tickColor: null,
          },
          ticks: {
            color: RED_COLOR,
            autoSkip: true,
            autoSkipPadding: 10,
            callback: (label: any) => {
              return `${label}°`;
            }
          },
        },
        yHumidity: {
          min: 0,
          max: 100,
          position: 'right',
          title: {
            text: formatMessage(MSG_humidityLabel),
            color: BRIGHT_BLUE_COLOR,
            padding: 0,
            display: true
          },
          grid: {
            display: false,
          },
          ticks: {
            color: BRIGHT_BLUE_COLOR,
            autoSkip: true,
            autoSkipPadding: 10,
            callback: (label: any) => {
              return `${label}%`;
            }
          }
        }
      },
      plugins: {
        legend: {
          display: false
        },
        tooltip: {
          boxWidth: 12,
          boxHeight: 8,
          boxPadding: 8,
          caretPadding: 5,
          padding: 8,
          callbacks: {
            title: (tooltipItem: any) => {
              return formatDate(moment((tooltipItem[0].raw as any).x));
            },
            label: (context: any) => {
              if (context.dataset.yAxisID === 'yTemperature') {
                return `${context.dataset.label}: ${context.parsed.y}°`;
              } else {
                return `${context.dataset.label}: ${context.parsed.y}%`;
              }
            }
          }
        },
      }
    },
    data: {
      datasets: [
        {
          label: formatMessage(MSG_temperatureLabel),
          data: temperatureData,
          backgroundColor: RED_COLOR,
          borderColor: RED_COLOR,
          pointRadius: temperaturePointRadius,
          borderWidth: 4,
          cubicInterpolationMode: 'monotone',
          tension: 0.4,
          yAxisID: 'yTemperature',
        },
        {
          label: formatMessage(MSG_humidityLabel),
          data: humidityData,
          backgroundColor: BRIGHT_BLUE_COLOR,
          borderColor: BRIGHT_BLUE_COLOR,
          pointRadius: humidityPointRadius,
          borderWidth: 4,
          cubicInterpolationMode: 'monotone',
          tension: 0.4,
          yAxisID: 'yHumidity',
        },
      ],
    }
  };
}
