import { FilterType, IFilterHandler, IFilterState } from "./types";
import { GenericBooleanFilterHandler } from "./boolean_filter";
import { GenericDateFilterHandler } from "./date_filter";
import { GenericSelectFilterHandler } from "./select_filter";
import { GenericCurrencyFilterHandler } from "./currency_filter";
import { GenericExactStringFilterHandler } from "./exact_string_filter";
import { GenericRadioFilterHandler } from "./radio_filter";
import { GenericNumberFilterHandler } from "./number_filter";
import { decode, encode } from "js-base64";

export const GenericFilterHandlers: {[key: string]: IFilterHandler} = {
  [FilterType.DATE]: GenericDateFilterHandler,
  [FilterType.BOOLEAN]: GenericBooleanFilterHandler,
  [FilterType.SELECT]: GenericSelectFilterHandler,
  [FilterType.RADIO]: GenericRadioFilterHandler,
  [FilterType.CURRENCY]: GenericCurrencyFilterHandler,
  [FilterType.NUMBER]: GenericNumberFilterHandler,
  [FilterType.EXACT_STRING]: GenericExactStringFilterHandler,
};

export function serializeFilters(filterState: IFilterState) {
  try {
    return encode(JSON.stringify(filterState));
  } catch (e) {
    console.error(filterState);
    console.error(e);
    return '';
  }
}

export function unserializeFilters(str: string, defaultFilterState: IFilterState = EMPTY_FILTER_STATE): IFilterState {
  try {
    return JSON.parse(decode(str)) as IFilterState;
  } catch (e) {
    return defaultFilterState;
  }
}

export const EMPTY_FILTER_STATE: IFilterState = {searchString: '', filters: []};
