import {
  FilterType,
  ICurrencyFilter,
  IExactStringFilter,
  IFilterConfigProps,
  IFilterHandler,
  IFilterLabelProps,
  IFilterTypeConfig
} from "./types";
import { faSearch } from "@fortawesome/pro-solid-svg-icons";

export const GenericExactStringFilterHandler: IFilterHandler = {
  type: FilterType.EXACT_STRING,

  getIcon: () => faSearch,

  getQueryVariables: (filter: ICurrencyFilter, config: IFilterTypeConfig) => {
    return {[config.queryArgumentKey]: !filter.value ? '' : filter.value};
  },

  isValid: (filter: IExactStringFilter) => {
    if (!filter.value) return true;
    return typeof filter.value === 'string';
  },

  defaultValue: (config: IFilterTypeConfig) => {
    return {
      key: config.key,
      type: FilterType.EXACT_STRING,
      value: ''
    };
  },

  LabelComponent: (props: IFilterLabelProps) => {
    throw "Expected ExactStringFilterHandler to implement LabelConfig.";
  },

  ConfigComponent: (props: IFilterConfigProps) => {
    throw "Expected ExactStringFilterHandler to implement ComponentConfig.";
  }
};
