import { formatMessage } from "../utils/intl";
import { ADDRESS_TYPE } from "../enums";
import { MSG_addressTypeBilling, MSG_addressTypeMailing, MSG_addressTypeStreet } from "../strings";

const SortedAddressTypes = [
  ADDRESS_TYPE.STREET,
  ADDRESS_TYPE.MAILING,
  ADDRESS_TYPE.BILLING,
];

const AddressTypeInfo: {[key: string]: {type: ADDRESS_TYPE, getLabel: () => string}} = {
  [ADDRESS_TYPE.STREET]: {type: ADDRESS_TYPE.STREET, getLabel: () => formatMessage(MSG_addressTypeStreet)},
  [ADDRESS_TYPE.MAILING]: {type: ADDRESS_TYPE.MAILING, getLabel: () => formatMessage(MSG_addressTypeMailing)},
  [ADDRESS_TYPE.BILLING]: {type: ADDRESS_TYPE.BILLING, getLabel: () => formatMessage(MSG_addressTypeBilling)},
};


export { SortedAddressTypes, AddressTypeInfo };
