import * as React from 'react';
import { Alert } from 'reactstrap';
import { mutationErrorsExcluding, mutationErrorsFor, MutationFieldError } from '../graphql/mutation_errors';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";

/**
 * This will display generic error messages up at the top of a page.
 * By default, it will just show mutationErrors whose key is null.
 *
 * But if you set showUnhandledKeys=true and give it an array of keys
 * that you are already handling, it will also print out error messages
 * for any keys NOT in the handledKeys array.
 */

interface IGenericFormErrorsProps {
  mutationErrors?: MutationFieldError[],
  showUnhandledKeys?: boolean,
  handledKeys?: string[],
}

class GenericFormErrors extends React.Component<IGenericFormErrorsProps> {
  render() {
    let messages = mutationErrorsFor(null, this.props.mutationErrors);
    if (this.props.showUnhandledKeys) {
      messages = messages.concat(mutationErrorsExcluding(this.props.handledKeys.concat([null]), this.props.mutationErrors));
    }
    if (messages.length === 0) {
      return '';
    } else {
      return (
        <Alert color="danger" className="generic-errors">
          <div className="form-errors">
            {messages.map((msg, i) => (
              <div key={i} className="form-error-message">
                <FontAwesomeIcon icon={faExclamationTriangle}/>{' '}
                <span className="error-message">{msg}</span>
              </div>
            ))}
          </div>
        </Alert>
      );
    }
  }
}

export { GenericFormErrors };
