import * as React from 'react';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { Button } from 'reactstrap';
import { Spinner } from '@core';
import { CompanyNameHeader } from "@core/components/CompanyNameHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faPrint, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { LanguageSelector } from "@core/components/LanguageSelector";

interface IMobileClientHeaderProps {
  appName: string;
  SidebarComponent?: new (props: {onClick?: any}) => React.Component<{onClick?: any}>;
  companyLogoExists: boolean;
  companyLogoUrl: string;
  companyName: string;
  companyPhoneNumber: string;
  companyEmail: string;
  companyHeaderLayout?: string;
  hideLanguageSelector?: boolean;
  rightComponent?: React.ReactElement;
  printable?: boolean;
  printableUrl?: string;
}

const MobileClientHeader = observer(
  class MobileClientHeader extends React.Component<IMobileClientHeaderProps> {
    sidebarVisible: boolean = false;
    printing: boolean = false;

    hideSidebar = () => this.sidebarVisible = false;
    showSidebar = () => this.sidebarVisible = true;

    handlePrint = () => {
      if (this.props.printableUrl) {
        // Mobile browsers do not let me do the iframe trick for loading and printing
        // the contents of a PDF.  Best we can do here is load a new window with the PDF.
        window.open(this.props.printableUrl);
      } else {
        window.print();
      }
    };

    constructor(props: IMobileClientHeaderProps) {
      super(props);

      makeObservable(this, {
        sidebarVisible: observable,
        printing: observable
      });
    }

    render() {
      const SidebarComponent = this.props.SidebarComponent;
      const { appName, companyLogoExists, companyLogoUrl, companyName, companyHeaderLayout } = this.props;

      return (
        <>
          <div className="mobile-header d-print-none">
            {SidebarComponent &&
              <div className="nav-toggle-container" onClick={this.showSidebar}>
                <FontAwesomeIcon icon={faBars}/>{' '}
              </div>}

            <CompanyNameHeader
              layout={companyHeaderLayout}
              appName={appName}
              companyName={companyName}
              logoExists={companyLogoExists}
              logoUrl={companyLogoUrl}/>

            {!this.props.hideLanguageSelector && <LanguageSelector/>}

            {this.props.rightComponent}

            {this.props.printable && (
              <div className="print-button-container">
                <Button color="primary" onClick={this.handlePrint} disabled={this.printing}>
                  {this.printing
                    ? <Spinner/>
                    : <FontAwesomeIcon icon={faPrint}/>}
                </Button>
              </div>
            )}
          </div>

          {SidebarComponent &&
            <>
              <div className={['mobile-blocker', this.sidebarVisible ? 'visible transition' : null].join(' ')}
                   onClick={this.hideSidebar}/>
              <div className={['mobile-navigation', this.sidebarVisible ? 'visible' : null].join(' ')}>
                <div className="text-end header" onClick={this.hideSidebar}>
                  <FontAwesomeIcon icon={faTimes}/>
                </div>

                {<SidebarComponent onClick={this.hideSidebar} />}
              </div>
            </>}
        </>
      );
    }
  }
);

export { MobileClientHeader };
