export const MSG_durationHoursShort = {
  id: "79e385ab-3fd2-4a48-9a10-132dc7942433",
  description: `An abbreviation of time when ONLY hours are specified. In English this would be '2h' to represent 2 hours. This should be a VERY short abbreviation.`,
  defaultMessage: "{hours}h",
};

export const MSG_durationHoursLong = {
  id: "42bc9611-1d77-4d02-a9d9-99b15db4be5d",
  description: `An expanded version of time when ONLY hours are specified. In English this would be '2 hours' to represent 2 hours.`,
  defaultMessage: "{hours, plural, one {1 hour} other {# hours}}",
};

export const MSG_durationMinutesShort = {
  id: "561d6f74-e112-4549-901e-aef9fe48f8d1",
  description: `An abbreviation of time when ONLY minutes are specified. In English this would be '25m' to represent 25 minutes. This should be a VERY short abbreviation.`,
  defaultMessage: "{minutes}m",
};

export const MSG_durationMinutesLong = {
  id: "5decffe3-633e-418f-8213-6749792a1650",
  description: `An expanded version of time when ONLY minutes are specified. In English this would be '25 minutes' to represent 25 minutes.`,
  defaultMessage: "{minutes, plural, one {1 minute} other {# minutes}}",
};
