import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { EVENT_TYPE } from "../enums";
import { formatMessage } from "./intl";
import { faCircle, faCloudDownload, faStickyNote, faUser } from '@fortawesome/pro-solid-svg-icons';
import {
  MSG_eventTypeAppointment,
  MSG_eventTypeMemo,
  MSG_eventTypePersonalEvent,
  MSG_eventTypeSynced
} from "../strings";

// Use this if you want to iterate over all the types (e.g. for a <select>)
const SortedEventTypes = [
  EVENT_TYPE.APPOINTMENT,
  EVENT_TYPE.PERSONAL,
  EVENT_TYPE.MEMO,
  // Intentionally leave synced out here because we do not want it appearing in select lists
];

const EventTypeInfo: {[key: string]: {type: EVENT_TYPE, getLabel: () => string, icon: IconProp}} = {
  [EVENT_TYPE.APPOINTMENT]: {
    type: EVENT_TYPE.APPOINTMENT,
    getLabel: () => formatMessage(MSG_eventTypeAppointment),
    icon: faUser,
  },
  [EVENT_TYPE.PERSONAL]: {
    type: EVENT_TYPE.PERSONAL,
    getLabel: () => formatMessage(MSG_eventTypePersonalEvent),
    icon: faCircle,
  },
  [EVENT_TYPE.MEMO]: {
    type: EVENT_TYPE.MEMO,
    getLabel: () => formatMessage(MSG_eventTypeMemo),
    icon: faStickyNote,
  },
  [EVENT_TYPE.SYNCED]: {
    type: EVENT_TYPE.SYNCED,
    getLabel: () => formatMessage(MSG_eventTypeSynced),
    icon: faCloudDownload,
  },
};

export { EventTypeInfo, SortedEventTypes };
