import * as moment from 'moment-timezone';
import { maxBy as _maxBy, minBy as _minBy } from 'lodash';
import { action, computed, makeObservable } from 'mobx';
import { User } from './user';
import { GREEN_WEIGHT_THRESHOLD } from "@gazelle/shared/utils/weights";

class Slot {
  startsAt: moment.Moment;
  timezone: string;
  order: number;
  weight: number;
  openDay: boolean;
  outsideAreaDrive: number;
  restrictions: string[];
  user?: User;
  isPreferred?: boolean;
  audit: string[];
  travelMode?: string;
  availabilityId?: string;

  constructor(data: any, user?: User) {
    makeObservable(this, {
      calculateComparisonWith: action
    });

    this.startsAt = moment.tz(data.startsAt, data.timezone);
    this.timezone = data.timezone;
    this.weight = data.weight;

    // legacy values not in the V2 Search API
    this.order = data.order;
    this.openDay = data.openDay;
    this.outsideAreaDrive = data.outsideAreaDrive;
    this.restrictions = data.restrictions || [];
    this.user = user;
    this.audit = data.audit;
    this.travelMode = data.travelMode ? data.travelMode : null;
    this.availabilityId = data.availabilityId ? data.availabilityId : null;

    // we switched to using flags instead of restrictions.  this code back-ports the flags to restrictions
    if (data.flags !== undefined && data.flags !== null) {
      this.restrictions = [];
      if (data.flags.indexOf('OUTSIDE_SHORT_TERM_LIMIT') >= 0) {
        this.restrictions.push('short_term_limit');
      }
    }

    // If we are on scheduler v2, isPreferred is calculated based on a fixed threshold here.
    // If we are not on scheduler v2, isPreferred is recalculated later after the search and this
    // value is overwritten.
    this.isPreferred = this.weight < GREEN_WEIGHT_THRESHOLD;
  }

  // BITROT: this is only used before scheduler v2.
  calculateComparisonWith(preferredWeight: number) {
    this.isPreferred = this.weight < preferredWeight;
  }
}

class DateSlots {
  date: moment.Moment;
  slots: Slot[];
  buckets: {[key: number]: Slot[]};

  constructor(date: string) {
    makeObservable(this, {
      addSlot: action,
      bestSlot: computed,
      worstSlot: computed
    });

    this.date = moment(date);
    this.buckets = {};
    this.slots = [];
  }

  addSlot(slot: Slot) {
    this.slots.push(slot);
    this.slots.sort((a: Slot, b: Slot) => {
      if (a.startsAt.isBefore(b.startsAt)) return -1;
      if (a.startsAt.isAfter(b.startsAt)) return 1;
      if (a.weight < b.weight) return -1;
      if (a.weight > b.weight) return 1;
      return 0;
    });

    // We keep a cache of all the slots for each hour of the day.
    // This is used for multi-tech sites to make the UI more usable
    // instead of showing a massive list of every time slot.
    const hour = slot.startsAt.hour();
    if (!this.buckets[hour]) {
      this.buckets[hour] = [];
    }
    this.buckets[hour].push(slot);
    this.buckets[hour].sort((a: Slot, b: Slot) => {
      if (a.startsAt.isBefore(b.startsAt)) return -1;
      if (a.startsAt.isAfter(b.startsAt)) return 1;
      return 0;
    });
  }

  get bestSlot(): Slot {
    return _minBy(this.slots, slot => slot.weight);
  }

  get worstSlot(): Slot {
    return _maxBy(this.slots, slot => slot.weight);
  }
}

export { Slot, DateSlots };
