import * as React from 'react';
import { GraphQLClient } from 'graphql-request';
import { IntlProvider } from 'react-intl';

import { ErrorContainer } from "@core/components/ErrorContainer";
import { getUrlPart } from '../utils/url';
import { formatMessage, getLocaleStoreInstance } from "@gazelle/shared/utils";
import { Localization } from "@gazelle/shared/models";
import {
  MSG_companyNotFoundMessage,
  MSG_companyNotFoundTitle,
  MSG_scheduledMaintenanceMessage,
  MSG_scheduledMaintenanceTitle,
  MSG_temporarySystemErrorMessage,
  MSG_temporarySystemErrorTitle,
  MSG_unexpectedErrorMessage,
  MSG_unexpectedErrorTitle
} from "@gazelle/shared/strings";
import { createRoot } from "react-dom/client";

let graphqlClient: GraphQLClient = null;

const createGraphqlClient = (locale: string = 'en_US'): GraphQLClient => {
  return new GraphQLClient('/graphql/public', {
    headers: {
      'Accept-Language': [locale, 'en_US'].join(','),
      'X-GAZELLE-URL-TOKEN': getUrlPart(1)
    }
  });
};

const updateGraphqlLocale = (localization: Localization) => {
  graphqlClient = createGraphqlClient(localization.locale);
};

const initGraphql = () => {
  getLocaleStoreInstance().registerChangeCallback(updateGraphqlLocale);
  updateGraphqlLocale(getLocaleStoreInstance().localization);
};

const graphqlRequest = function (queryStr: string, variables: any) {
  return graphqlClient.request(queryStr, variables)
    .catch(function (err: any) {
      let title, message;
      if (err.response?.status >= 400 && err.response?.status <= 499) {
        title = formatMessage(MSG_companyNotFoundTitle);
        message = formatMessage(MSG_companyNotFoundMessage);
      } else if (err.response?.status === 503) {
        title = formatMessage(MSG_scheduledMaintenanceTitle);
        message = formatMessage(MSG_scheduledMaintenanceMessage);
      } else if (err.response?.status >= 500 && err.response?.status <= 599) {
        title = formatMessage(MSG_temporarySystemErrorTitle);
        message = formatMessage(MSG_temporarySystemErrorMessage);
      } else {
        title = formatMessage(MSG_unexpectedErrorTitle);
        message = formatMessage(MSG_unexpectedErrorMessage, {errorMessage: err.response?.message});
      }

      const container = document.getElementById('root');
      const root = createRoot(container);
      root.render((
        <IntlProvider locale="en">
          <ErrorContainer title={title} message={message} />
        </IntlProvider>
      ));
    });
};

export { createGraphqlClient, graphqlRequest, updateGraphqlLocale, initGraphql };
