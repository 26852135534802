import { CURRENCY_FORMAT, NUMBER_FORMAT } from "../enums";
import { getLocaleStoreInstance } from "./intl";
import { getGlobalCurrency } from "../stores/global_currency_store";
import { Currency } from "../models";

export interface ICurrency {
  label: string;
  abbr: string;
  symbol: string;
  symbolLocation: 'before' | 'after' | 'hidden';
  decimalDigits: number;
  divisor: number;
}

export function formatCurrency(value: number, numberFormatOverride: NUMBER_FORMAT = null, currencyFormatOverride: CURRENCY_FORMAT = null, currencyOverride: Currency = null) {
  let currency = currencyOverride || getGlobalCurrency();

  const currencyFormat = currencyFormatOverride || getLocaleStoreInstance().localization.currencyFormat || CURRENCY_FORMAT.BEFORE;
  const numStr = formatNumber(value, currency.divisor, currency.decimalDigits, numberFormatOverride);

  if (currencyFormat === CURRENCY_FORMAT.BEFORE) {
    return `${currency.symbol}${numStr}`;
  } else if (currencyFormat === CURRENCY_FORMAT.AFTER) {
    return `${numStr}${currency.symbol}`;
  } else {
    return numStr;
  }
}

// multiplier lets you clientStore numbers as power-10 integers but display them as
// decimals.  Useful for places were 1234 is the integer stored in the database
// but actually represents 1.234 (piano measurements, invoices, etc).
export function formatNumber(value: number, multiplier: number = 1, minimumFractionDigits: number = null, numberFormatOverride: NUMBER_FORMAT = null) {
  let newValue = value / (multiplier || 1);
  let valueStr: string;
  if (minimumFractionDigits !== null && minimumFractionDigits !== undefined) {
    valueStr = `${(Math.round(newValue * Math.pow(10, minimumFractionDigits)) / Math.pow(10, minimumFractionDigits)).toFixed(minimumFractionDigits)}`;
  } else {
    valueStr = `${newValue}`;
  }

  let sep = findSeparators(numberFormatOverride);
  let parts = valueStr.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, sep.comma);
  return parts.join(sep.decimal);
}

export function formatPercent(value: number, multiplier: number = 1, minimumFractionDigits: number = 2, numberFormatOverride: NUMBER_FORMAT = null) {
  return formatNumber(value, multiplier, minimumFractionDigits,  numberFormatOverride) + "%";
}

const findSeparators = (numberFormatOverride: NUMBER_FORMAT = null) => {
  let numberFormat = numberFormatOverride || getLocaleStoreInstance().localization.numberFormat;
  let commaStr = ",";
  let decimalStr = ".";
  switch (numberFormat) {
    case NUMBER_FORMAT.DECIMAL_COMMA_THREE:
      commaStr = ".";
      decimalStr = ",";
      break;
    case NUMBER_FORMAT.SPACE_COMMA_THREE:
      commaStr = " ";
      decimalStr = ",";
      break;
  }
  return {comma: commaStr, decimal: decimalStr};
};

const parseIntlNumberString = (str: string, isFloat: boolean = false, numberFormatOverride: NUMBER_FORMAT = null) => {
  const multiplier = str.match(/^\s*-/) ? -1 : 1;
  let newStr = str.replace(/[^\d,.\s]/g, '');

  const sep = findSeparators(numberFormatOverride);
  const parts = newStr.replace(sep.comma, '').split(sep.decimal);
  const num = `${parts[0] || 0}.${parts[1] || 0}`;

  if (num.length === 0) return 0;

  if (isFloat) {
    return parseFloat(num) * multiplier;
  } else {
    return Math.round(parseFloat(num)) * multiplier;
  }
};

export const parseIntlIntegerString = (str: string, numberFormatOverride: NUMBER_FORMAT = null) => {
  return parseIntlNumberString(str, false, numberFormatOverride);
};

export const parseIntlFloatString = (str: string, numberFormatOverride: NUMBER_FORMAT = null) => {
  return parseIntlNumberString(str, true, numberFormatOverride);
};

export const parseIntlCurrencyString = (str: string, numberFormatOverride: NUMBER_FORMAT = null) => {
  const multiplier = str.match(/^\s*-/) ? -1 : 1;
  let newStr = str.replace(/[^\d,.\s]/g, '');

  const sep = findSeparators(numberFormatOverride);
  const parts = newStr.replace(new RegExp(sep.comma.replace(/[.]/g, '\\$&'), 'g'), '').split(sep.decimal);
  const num = `${parts[0]}.${parts[1] || 0}`;

  return Math.round(parseFloat(num) * 100) * multiplier;
};

/**
 * MidpointRounding away from zero ('arithmetic' rounding).
 * We need to implement this ourselves to match Ruby's default rounding for half values which is "away from zero".
 * Implementation from: https://stackoverflow.com/a/48764884/2345115
 */
export const roundAwayFromZero = (num: number, precision: number): number => {
  let c = 0.5 * Number.EPSILON * num;
  let p = 1; while (precision-- > 0) p *= 10;
  if (num < 0) {
    p *= -1;
  }
  return Math.round((num + c) * p) / p;
};

export function removeInsignificantZeros(str: string) {
  return str.replace(/([.,]00)(\D*)$/, '$2');
}
