export const MSG_policiesTitle = {
  id: "06c618b9-fd1f-46d3-b382-2a51ddfdab3e",
  description: "",
  defaultMessage: "Policies"
};

export const MSG_privacyPolicyWithCompanyNameTitle = {
  id: 'ddbf55dd-9152-4957-850a-d4181ccee842',
  description: 'The page title displayed in the browser tab or window header.  This should have the company name first, followed by "Privacy Policy"',
  defaultMessage: '{companyName} - Privacy Policy'
};

export const MSG_termsTitle = {
  id: "a4a62218-be2b-4fd1-87e0-31d24c3bf9bb",
  description: "The title for our terms of use policy.",
  defaultMessage: "Terms of Service"
};

export const MSG_privacyPolicyTitle = {
  id: "73263fba-a497-4215-8f5e-c19ce05bfe2a",
  defaultMessage: "Privacy Policy"
};

export const MSG_policyViewingError = {
  id: "ab6f9945-7bf7-40d8-a7a6-403734674bcc",
  defaultMessage: "Please call us at {phone} if you have problems viewing this policy and we will be sure to get you a copy.  We apologize for the inconvenience.",
};

export const MSG_documentNotFoundTitle = {
  id: "292d77ad-5f7d-4729-90e3-42aac91a1b66",
  defaultMessage: "Document Not Found",
};

export const MSG_documentNotFoundError = {
  id: "353415cb-79b7-4ed3-a68b-319ab1c79d29",
  defaultMessage: "The document you were trying to view does not exist.  If you need assistance, please contacts us at {phone}.",
};

export const MSG_gdprAgreementParagraph1 = {
  id: "9eb934c1-9c2d-4972-8075-a4462f8b7bd6",
  defaultMessage: `By clicking "I agree" below, you consent to allow {name} and Gazelle, a trusted 3rd party service provider for {name}, to store and use your personal data to schedule an appointment and contact you about servicing your piano.`,
};

export const MSG_gdprAgreementParagraph2 = {
  id: "00fc91b4-90cf-4baf-b995-5134f7850c7c",
  defaultMessage: "You may withdraw consent at any time.  Just call us at {phone}, or email us at {email} and we will remove your data and no longer contact you.",
};

export const MSG_gdprAgreementParagraph3 = {
  id: "06f87f1e-e397-44d2-9b40-4b8c8466abef",
  defaultMessage: "Please review our {privacyPolicy} for more details.",
};

export const MSG_gdprDoNotAgree = {
  id: "dcb5ca22-af27-4680-ac9b-029077dc3497",
  defaultMessage: "I do not agree, go back.",
};

export const MSG_gdprAgree = {
  id: "449b217d-d365-4fbd-900d-d1242d90e419",
  defaultMessage: "I agree, continue",
};

export const MSG_goToLegalContractsTitle = {
  id: "6173e9d7-5987-4ae7-92c7-53c819e09bfd",
  description: "The title for a button that takes them to the legal contracts section.",
  defaultMessage: "Go To Legal Contracts"
};
