import { INVOICE_PAYMENT_TYPE } from "../enums";
import { formatMessage } from "./intl";
import {
  MSG_invoicePaymentTypeAch,
  MSG_invoicePaymentTypeBancontact,
  MSG_invoicePaymentTypeCash,
  MSG_invoicePaymentTypeCheck,
  MSG_invoicePaymentTypeCreditCard,
  MSG_invoicePaymentTypeDebitCard,
  MSG_invoicePaymentTypeEps,
  MSG_invoicePaymentTypeEtf,
  MSG_invoicePaymentTypeGiropay,
  MSG_invoicePaymentTypeIdeal,
  MSG_invoicePaymentTypePaypal,
  MSG_invoicePaymentTypePrzelewy24,
  MSG_invoicePaymentTypeSepa,
  MSG_invoicePaymentTypeSofort,
  MSG_invoicePaymentTypeVenmo,
  MSG_invoicePaymentTypeZelle,
  MSG_otherTitle,
  MSG_unknownTitle,
} from "../strings";

// Use this if you want to iterate over all the status (e.g. for a <select>)
const SortedInvoicePaymentType = [
  // Keep the generic payment methods up top
  INVOICE_PAYMENT_TYPE.CASH,
  INVOICE_PAYMENT_TYPE.CHECK,
  INVOICE_PAYMENT_TYPE.CREDIT_CARD,
  INVOICE_PAYMENT_TYPE.DEBIT_CARD,
  INVOICE_PAYMENT_TYPE.ELECTRONIC_FUNDS_TRANSFER,

  // alphabetize these specific payment methods (in English)
  INVOICE_PAYMENT_TYPE.ACH,
  INVOICE_PAYMENT_TYPE.BANCONTACT,
  INVOICE_PAYMENT_TYPE.EPS,
  INVOICE_PAYMENT_TYPE.GIROPAY,
  INVOICE_PAYMENT_TYPE.IDEAL,
  INVOICE_PAYMENT_TYPE.P24,
  INVOICE_PAYMENT_TYPE.PAYPAL,
  INVOICE_PAYMENT_TYPE.SEPA,
  INVOICE_PAYMENT_TYPE.SOFORT,
  INVOICE_PAYMENT_TYPE.VENMO,
  INVOICE_PAYMENT_TYPE.ZELLE,

  // Always last
  INVOICE_PAYMENT_TYPE.OTHER,
];

const InvoicePaymentTypeInfo: {[key: string]: {type: INVOICE_PAYMENT_TYPE, getLabel: () => string}} = {
  [INVOICE_PAYMENT_TYPE.CASH]: {
    type: INVOICE_PAYMENT_TYPE.CASH,
    getLabel: () => formatMessage(MSG_invoicePaymentTypeCash),
  },
  [INVOICE_PAYMENT_TYPE.CHECK]: {
    type: INVOICE_PAYMENT_TYPE.CHECK,
    getLabel: () => formatMessage(MSG_invoicePaymentTypeCheck),
  },
  [INVOICE_PAYMENT_TYPE.CREDIT_CARD]: {
    type: INVOICE_PAYMENT_TYPE.CREDIT_CARD,
    getLabel: () => formatMessage(MSG_invoicePaymentTypeCreditCard),
  },
  [INVOICE_PAYMENT_TYPE.DEBIT_CARD]: {
    type: INVOICE_PAYMENT_TYPE.DEBIT_CARD,
    getLabel: () => formatMessage(MSG_invoicePaymentTypeDebitCard),
  },
  [INVOICE_PAYMENT_TYPE.ACH]: {
    type: INVOICE_PAYMENT_TYPE.ACH,
    getLabel: () => formatMessage(MSG_invoicePaymentTypeAch),
  },
  [INVOICE_PAYMENT_TYPE.ELECTRONIC_FUNDS_TRANSFER]: {
    type: INVOICE_PAYMENT_TYPE.ELECTRONIC_FUNDS_TRANSFER,
    getLabel: () => formatMessage(MSG_invoicePaymentTypeEtf),
  },
  [INVOICE_PAYMENT_TYPE.PAYPAL]: {
    type: INVOICE_PAYMENT_TYPE.PAYPAL,
    getLabel: () => formatMessage(MSG_invoicePaymentTypePaypal),
  },
  [INVOICE_PAYMENT_TYPE.VENMO]: {
    type: INVOICE_PAYMENT_TYPE.VENMO,
    getLabel: () => formatMessage(MSG_invoicePaymentTypeVenmo),
  },
  [INVOICE_PAYMENT_TYPE.ZELLE]: {
    type: INVOICE_PAYMENT_TYPE.ZELLE,
    getLabel: () => formatMessage(MSG_invoicePaymentTypeZelle),
  },
  [INVOICE_PAYMENT_TYPE.SEPA]: {
    type: INVOICE_PAYMENT_TYPE.SEPA,
    getLabel: () => formatMessage(MSG_invoicePaymentTypeSepa),
  },
  [INVOICE_PAYMENT_TYPE.BANCONTACT]: {
    type: INVOICE_PAYMENT_TYPE.BANCONTACT,
    getLabel: () => formatMessage(MSG_invoicePaymentTypeBancontact),
  },
  [INVOICE_PAYMENT_TYPE.EPS]: {
    type: INVOICE_PAYMENT_TYPE.EPS,
    getLabel: () => formatMessage(MSG_invoicePaymentTypeEps),
  },
  [INVOICE_PAYMENT_TYPE.GIROPAY]: {
    type: INVOICE_PAYMENT_TYPE.GIROPAY,
    getLabel: () => formatMessage(MSG_invoicePaymentTypeGiropay),
  },
  [INVOICE_PAYMENT_TYPE.IDEAL]: {
    type: INVOICE_PAYMENT_TYPE.IDEAL,
    getLabel: () => formatMessage(MSG_invoicePaymentTypeIdeal),
  },
  [INVOICE_PAYMENT_TYPE.SOFORT]: {
    type: INVOICE_PAYMENT_TYPE.SOFORT,
    getLabel: () => formatMessage(MSG_invoicePaymentTypeSofort),
  },
  [INVOICE_PAYMENT_TYPE.P24]: {
    type: INVOICE_PAYMENT_TYPE.P24,
    getLabel: () => formatMessage(MSG_invoicePaymentTypePrzelewy24),
  },
  [INVOICE_PAYMENT_TYPE.OTHER]: {
    type: INVOICE_PAYMENT_TYPE.OTHER,
    getLabel: () => formatMessage(MSG_otherTitle),
  },
};

// We sometimes expand the payment type list.  This is a protection to ensure
// that old versions of the app don't crash when an unrecognized payment type
// comes through.
function safelyGetPaymentTypeLabel(type: INVOICE_PAYMENT_TYPE): string {
  if (InvoicePaymentTypeInfo[type]) {
    return InvoicePaymentTypeInfo[type].getLabel();
  } else {
    return formatMessage(MSG_unknownTitle);
  }
}

export { SortedInvoicePaymentType, InvoicePaymentTypeInfo, safelyGetPaymentTypeLabel };
