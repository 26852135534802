import { formatMessage } from "./intl";
import { CLIENT_STATUS_TYPE } from "../enums";
import {
  MSG_clientStatusActive,
  MSG_clientStatusInactive,
  MSG_clientStatusNew,
  MSG_clientStatusProspect
} from "../strings";
import { ALERT_GREEN_COLOR, ALERT_RED_COLOR, ALERT_YELLOW_COLOR } from "../colors";

const SortedClientStatusTypes = [
  CLIENT_STATUS_TYPE.PROSPECT,
  CLIENT_STATUS_TYPE.NEW,
  CLIENT_STATUS_TYPE.ACTIVE,
  CLIENT_STATUS_TYPE.INACTIVE,
];

const ClientStatusTypeInfo: {[key: string]: {type: CLIENT_STATUS_TYPE, getLabel: () => string, color: string, badgeColor: string}} = {
  [CLIENT_STATUS_TYPE.PROSPECT]: {type: CLIENT_STATUS_TYPE.PROSPECT, getLabel: () => formatMessage(MSG_clientStatusProspect), color: ALERT_YELLOW_COLOR, badgeColor: 'warning'},
  [CLIENT_STATUS_TYPE.NEW]: {type: CLIENT_STATUS_TYPE.NEW, getLabel: () => formatMessage(MSG_clientStatusNew), color: ALERT_YELLOW_COLOR, badgeColor: 'warning'},
  [CLIENT_STATUS_TYPE.ACTIVE]: {type: CLIENT_STATUS_TYPE.ACTIVE, getLabel: () => formatMessage(MSG_clientStatusActive), color: ALERT_GREEN_COLOR, badgeColor: 'success'},
  [CLIENT_STATUS_TYPE.INACTIVE]: {type: CLIENT_STATUS_TYPE.INACTIVE, getLabel: () => formatMessage(MSG_clientStatusInactive), color: ALERT_RED_COLOR, badgeColor: 'danger'},
};


export { SortedClientStatusTypes, ClientStatusTypeInfo };
