type MutationFieldError = {
  key: string;
  messages: string[];
};

function mutationErrorsFor(key: string, errors: MutationFieldError[]): string[] {
  if (!errors) return [];
  for (let err of errors) {
    if (err.key === key) {
      return err.messages;
    }
  }
  return [];
}

function mutationErrorsExcluding(keys: string[], errors: MutationFieldError[]): string[] {
  if (!errors) return [];
  let messages: string[] = [];
  for (let err of errors) {
    if (keys && keys.indexOf(err.key) < 0) {
      messages = messages.concat(err.messages);
    }
  }
  return messages;
}

export { mutationErrorsFor, mutationErrorsExcluding, MutationFieldError };
