import { PHONE_TYPE } from "../enums";
import { formatMessage } from "./intl";
import {
  MSG_phoneTypeFax,
  MSG_phoneTypeHome,
  MSG_phoneTypeMobile,
  MSG_phoneTypePrimary,
  MSG_phoneTypeSecondary,
  MSG_phoneTypeWork,
  MSG_unknownTitle
} from "../strings";

// Use this if you want to iterate over all the types (e.g. for a <select>)
const SortedPhoneTypes = [
  PHONE_TYPE.UNKNOWN,
  PHONE_TYPE.PRIMARY,
  PHONE_TYPE.SECONDARY,
  PHONE_TYPE.MOBILE,
  PHONE_TYPE.HOME,
  PHONE_TYPE.WORK,
  PHONE_TYPE.FAX,
];

const PhoneTypeInfo: {[key: string]: {type: PHONE_TYPE, getLabel: () => string}} = {
  [PHONE_TYPE.UNKNOWN]: {
    type: PHONE_TYPE.UNKNOWN,
    getLabel: () => formatMessage(MSG_unknownTitle),
  },
  [PHONE_TYPE.PRIMARY]: {
    type: PHONE_TYPE.PRIMARY,
    getLabel: () => formatMessage(MSG_phoneTypePrimary),
  },
  [PHONE_TYPE.SECONDARY]: {
    type: PHONE_TYPE.SECONDARY,
    getLabel: () => formatMessage(MSG_phoneTypeSecondary),
  },
  [PHONE_TYPE.MOBILE]: {
    type: PHONE_TYPE.MOBILE,
    getLabel: () => formatMessage(MSG_phoneTypeMobile),
  },
  [PHONE_TYPE.HOME]: {
    type: PHONE_TYPE.HOME,
    getLabel: () => formatMessage(MSG_phoneTypeHome),
  },
  [PHONE_TYPE.WORK]: {
    type: PHONE_TYPE.WORK,
    getLabel: () => formatMessage(MSG_phoneTypeWork),
  },
  [PHONE_TYPE.FAX]: {
    type: PHONE_TYPE.FAX,
    getLabel: () => formatMessage(MSG_phoneTypeFax),
  },
};

export { PhoneTypeInfo, SortedPhoneTypes };
